import { Moment } from 'moment';
import { Map, List, fromJS } from 'immutable';
import { Template } from '../components/Utilities/Templates/Template';
import { Period } from '../components/Tariffs/Create/TariffFactoryContext';

export type Maybe<T> = T | null;

export type LoadProfile = [string, string, number][];

export interface ResourceOverviewChartOption {
  fill: 'none' | 'tozeroy' | 'tozerox' | 'tonexty' | 'tonextx' | 'toself' | 'tonext' | null;
  name: ChartOptionName;
  label: string;
  color: string;
  fillcolor: string;
  include: boolean;
  opacity: number;
  stacked: boolean;
}

export type WizardChild<T extends {}> = T & { handleNext?: () => any };

export type ChartOptionName =
  | 'Current Demand'
  | 'Load Net Solar'
  | 'Net Demand'
  | 'Battery SOC'
  | 'Battery Power'
  | 'Genset Power'
  | 'Battery Charge'
  | 'Battery Discharge'
  | 'Load Curtailment'
  | 'PV Power'
  | 'PV Curtailed'
  | 'PV Used Power'
  | 'EV Power'
  | 'Net Export'
  | 'Islanded Load';
export interface Technologies {
  isPV: boolean;
  isBatt: boolean;
  isGenset: boolean;
  isEV: boolean;
  isIslanding: boolean;
}

export interface DataRequired {
  load: boolean;
  solar: boolean;
  lmp: boolean;
  mef: boolean;
  ev1: boolean;
  ev2: boolean;
}

// type of data that can be saved to proposals
export enum DataType {
  load = 'load',
  solar = 'prodPV',
  lmpDa = 'LMP',
  lmpRt = 'LMPRT',
  mef = 'MEF',
  ev1 = 'ev1',
  ev2 = 'ev2'
}

export interface DataFromS3 {
  type: DataType;
  start_date: string;
  end_date: string;
  scaler: number;
  data: { date: string; value: string }[];
}
interface Proposal {
  old_id?: any;
  updated_by: string;
  created_by: string;
  is_editable: boolean;
  proposal_config?: any;
  created_user: string;
  id: number;
  end_date?: any;
  is_ftm: boolean;
  notes: string;
  start_date?: any;
  enabled: boolean;
  name: string;
  created_on?: string;
  customer?: Customer;
  customer_id: string;
  description?: string;
  nonBypassableRate?: number;
  public_id: string;
  proposal_tariffs: ESAPTariff[];
  proposal_templates: Template[];
  last_run?: string;
  imported_on: string;
  status_quo_result?: any;
  status?: string;
  selected_scenarios: string[];
  sizing_recommendations: {
    [key: string]: [string, any][];
  };
  sizing_status: any;
  updated_on: string;
  loadData?: any[];
  audit_logs?: AuditLog[];
  is_archived?: boolean;
  archived_on: string | null;
  stagedTariff?: GenabilityTariff;
  tariffs?: GenabilityTariff[];
  assumptions: any; // Not sure this is used anywhere
  isCustomerCreated?: boolean;
  forecasting_status: ForecastStatus;
  forecasting_start: string | null;
  forecasting_end: string | null;
}

export type ForecastStatus = 'not run' | 'training' | 'error' | 'complete' | null;

export interface ProposalInternal extends Proposal {
  proposal_scenarios: ScenarioInternal[];
  isNewProposal?: boolean; // during proposal initial creation
}

export interface ProposalExternal extends Proposal {
  proposal_scenarios: ScenarioExternal[];
}

export interface Customer {
  public_id?: string;
  name?: string;
  description?: string;
  contact_name?: string;
  contact_email?: string;
  contact_phone?: string;
  website?: string;
  created_on?: string;
  created_by?: string;
  updated_on?: string;
  updated_by?: string;
  old_id?: string;
  address: {
    zip_code: string;
  };
}

export type Market = 'CAISO' | 'IESO' | 'NYISO' | 'PJM' | 'ISONE_MA' | 'ISONE_CT';

/****************************************************************************/
/* . Assumptions                                                            */
/****************************************************************************/

// define the assumptions for each EV tranche created in the EV assumptions tab
interface EVShift {
  id: number;
  Smax: number;
  gamma: number;
  muChargingPrd: number;
  muChargingPrdStdDeviation: number;
  evseUtilisationRate: number;
  isOvernight: boolean;
}

export interface EVShiftInternal extends EVShift {
  evArrivalRange: [number, number];
}

export interface EVShiftExternal extends EVShift {
  muArrival: string;
  muArrivalStdDeviation: number;
}

interface Assumptions {
  /** NOT SURE IF THESE ARE USED BY OPTIM BUT THEY ARE NOT IN THE FRONTEND **/
  timezone: string;
  isPerfectForecast: boolean;
  isOptim: boolean;
  isGurobi: boolean;
  techno: string;
  rendBatt: number;
  effBatt: number;
  isPDRBestCase: boolean;
  pdrResource: string;
  solverName: string;
  tolerance: number;
  stepOptim: number;
  horizon: number;
  isCtrl: boolean;
  stepCtrl: number;
  stepMesMeanPoc: number;
  kpPOC: number;
  kiPOC: number;
  marginPOC: number;
  kiSOC: number;
  kpSOC: number;
  socThreshold: number;
  isSmoothDisch: boolean;
  isSmoothCh: boolean;
  isSmoothPV: boolean;
  smoothWeight: number;
  PDRWeight: number;
  isPDRPool: boolean;
  isREC: boolean;
  isPrevPVJO: boolean;
  tariffsilename: string;
  priceCO2: number;
  priceREC: number;
  isPrevLMPJo: boolean;
  isPrevLoadJO: boolean;
  targetType: string;
  /** SCENARIO CONFIG **/
  hasExistingSolar: boolean;
  /** STARTEGIES TAB CONFIG **/
  tariffId: string;
  isDaily: boolean;
  isMonthly: boolean;
  isRealtime: boolean;
  isMultiyear: boolean;
  isBehindMeter: boolean;
  isPeakShaving: boolean;
  isOptionS: boolean;
  isArbitrage: boolean;
  isNetMetering: boolean;
  exportMax: number;
  localTax: number;
  selectedMarket: string;
  selectedHorizon: Horizon;
  selectedOptimizationInterval: '15min' | '60min';
  numberOfYears: number;
  energyEscalation: number;
  demandEscalation: number;
  isUnbundled: boolean;
  calculateGHGEmissions: boolean;
  batteryEnergyCapacityDegradation: number;
  batteryPowerCapacityDegradation: number;
  consumptionEscalation: number;
  useBatteryDegradation: boolean;
  calendarId: string;
  selectBillingCycle: boolean;
  isNEM3: boolean;
  isNetSurplusCompensation: boolean;
  netSurplusCompensationRate: number;
  isNetMeteringBalanceCalculation: boolean;
  isGHGReduction: boolean;
  kg_co2_reduction_per_kwh: number;
  selectedGHGRegion: string;
  exportCost: number;
  isSOCReserve: boolean;
  /** NYISO MARKET CONFIG **/
  isVDER: boolean;
  isCDG: boolean;
  isDRV: boolean;
  vderICAPYear: number;
  vderICAP: number;
  csrpHours: string;
  vderTotalTD: number;
  vderDRV: number;
  vderCDG: number;
  vderEnvironmentalBenefits: number;
  utility: string;
  zone: string;
  /** CAISO CONFIG **/
  isPDR: boolean;
  isPDP: boolean;
  isBIP: boolean;
  pdpFilename: string;
  pdpFilenameKey: string;
  enablePdpCustomEvents: boolean;
  pdpCustomEvents: string[]; // date strings MM/DD/YYYY
  bipFilename: string;
  pdrFilename: string;
  crl: number;
  fsl: number;
  /** PJM CONFIG **/
  isPjmITRAN: boolean;
  signalICAP: number;
  utilityITRAN: string;
  ITRANCost: number;
  ICAPCost: number;
  isPjmICAP: boolean;
  /** IESO CONFIG **/
  isGA: boolean;
  isGADR: boolean;
  gaFilename: string;
  gaArbitrageCycles: number;
  gaArbitrageThresholdSTD: string;
  /** BATTERY TAB CONFIG **/
  isBatt: boolean;
  isBattExport: boolean;
  isForceDailyCycles: boolean;
  isForceMonthlyCycles: boolean;
  isForceYearlyCycles: boolean;
  yearlyCycles: YearlyCycle[];
  percentFromPV: number;
  socReserve: number;
  chargeEfficiency: number;
  dischargeEfficiency: number;
  isDCCoupled: boolean;
  /** BATTERY SIZING **/
  power: number;
  capacity: number;
  pBattMaxCH: number;
  pBattMaxDisch: number;
  socMax: number;
  socMin: number;
  maxDailyCycles: number;
  cycleCost: number;
  selectedBidInput: string;
  yearlyBid: number;
  monthlyBids: MonthlyBids[];
  selectedRAWindow: string;
  minBidCapacity: number;
  raOnlyCalculatePotentials: boolean;
  calculateBatteryDegradation: boolean;
  batteryNameplateCapacity: number;
  /** PV TAB CONFIG **/
  isPV: boolean;
  pInstPVAutoUpdate: boolean;
  pInstPV: number;
  rendTot: number;
  pvInterpolationType: string;
  /** MICROGRID TAB CONFIG **/
  isIslanding: boolean;
  isGenset: boolean;
  outagesBattCyclesLimit: number;
  fuelCost: number;
  forceChargeBeforeOutages: boolean;
  pGenMin: number;
  pGenMax: number;
  simulationOutageOnly: boolean;
  outageStartSOC: number;
  isBESSRidethrough: boolean;
  hasGridOutages: boolean;
  gridOutageDates: any[];
  gridOutageIntervalData: GridOutageIntervalData;
  gensetEfficiencyItems: any[];
  isMaxGensetStartsInDay: boolean;
  minGensetDurationThreshold: number;
  isMinGensestDurationThreshold: boolean;
  maxGensetStarts: number;
  criticalLoad: number;
  hasCriticalLoadMaxValue: boolean;
  criticalLoadMaxValue: number;
  isOutageOnlyGenset: boolean;
  curtailCost: number;
  /**  EV TAB CONFIG **/
  isEVTariff: boolean;
  evTariffId: string;
  evNbSubscriptionTranches: number;
  setSubscriptionLevel: boolean;
  useLoadComparisonDemandChargeCalc: boolean;
  evTotalKWReserved: number;
  evLimitResaChange: string;
  isEV: boolean;
  eta: number;
  Rmax: number;
  useCustomEVLoadProfile: boolean;
  ignoreFacilityLoad: boolean;
  numEV: number;
  maxEVFleetDemand: number;
  evWeekendUtilization: number;
  useSmartCharging: boolean;
  pvACNameplateCapacity: number;
  evTemplate: TemplateEV;
  nbOfEVProfiles: string;
  coOptimEV: boolean;
  /** ISONE Market **/
  selectedNEISOUtility: 'EVERSOURCE_ENERGY' | 'NATIONAL_GRID' | 'UNITED_ILLUMINATING' | '';
  useConnectedSolutionsDR: boolean;
  useDailyDispatchSummer: boolean;
  useTargetedDispatchSummer: boolean;
  useTargetedDispatchWinter: boolean;
  useNEISOCustomEvents: boolean;
  neisoCustomEvents: string[]; // date strings MM/DD/YYYY
  useCleanPeakStandard: boolean;
  isWinterDispatchCleanPeak: boolean;
  isSpringDispatchCleanPeak: boolean;
  isSummerDispatchCleanPeak: boolean;
  isFallDispatchCleanPeak: boolean;
  useCustomCPSCredit: boolean;
  useAltBillCreditRateExport: boolean;
  altBillUtility: 'NATIONAL_GRID' | 'EVERSOURCE_EAST' | 'EVERSOURCE_WEST' | '';
  altBillLoadZone: 'NEMA' | 'SEMA' | 'WCMA' | '';
  exportValue: number;
  customCPSCredit: number;
  useExportCost: boolean;
  /** Real-time simulation **/
  realtimeSimStart: string | null;
  realtimeSimEnd: string | null;
  isMaximizeSOC: boolean;
  maximizeSOC_RT: number;
}

export type TemplateEV = 'hospital' | 'officeBuilding' | 'shippingWarehouse' | 'none';

export interface AssumptionsInternal extends Assumptions {
  /** SCENARIO CONFIG **/
  /** STARTEGIES TAB CONFIG **/
  /** NYISO MARKET CONFIG **/
  /** CAISO CONFIG **/
  /** PJM CONFIG **/
  /** IESO CONFIG **/
  /** BATTERY TAB CONFIG **/
  /** BATTERY SIZING **/
  /** PV TAB CONFIG **/
  /** MICROGRID TAB CONFIG **/
  /**  EV TAB CONFIG **/
  evShifts: EVShiftInternal[];
}

export interface AssumptionsExternal extends Assumptions {
  /** SCENARIO CONFIG **/
  /** STARTEGIES TAB CONFIG **/
  /** NYISO MARKET CONFIG **/
  /** CAISO CONFIG **/
  /** PJM CONFIG **/
  /** IESO CONFIG **/
  /** BATTERY TAB CONFIG **/
  /** BATTERY SIZING **/
  /** PV TAB CONFIG **/
  /** MICROGRID TAB CONFIG **/
  /**  EV TAB CONFIG **/
  evShifts: EVShiftExternal[];
}

export type Horizon = 'isDaily' | 'isMonthly' | 'isRealtime' | 'isMultiyear';

export interface MonthlyBids {
  month: Month;
  bids: number;
}

export interface GridOutageIntervalData {}

export interface YearlyCycle {
  month: Month;
  cycles: number;
}

export type Month =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

export type OperationsData = {
  vecLoad: number[];
  month: string;
  vecEnerBatt: number[];
  vecPBatt: number[];
  vecPInv: number[];
  vecPNetOut: number[];
  vecPNetIn: number[];
  vecPev: number[];
  mef: number[];
  vecPGen: number[];
  vecPCurtail: number[];
  pdr?: number[];
  pv_curtail?: number[];
  touPeriod: string[];
};

export type MonthlyOperationsData = Record<string, OperationsData>;
interface Scenario {
  order?: number;
  updated_on?: string;
  old_id?: any;
  run_queued?: Date;
  updated_by?: string;
  created_by?: string;
  updated_user?: any;
  id?: number;
  tariff_public_id?: any;
  notes: string | null;
  proposal_id: string;
  tariffId?: string;
  name: string;
  active: boolean;
  public_id: string;
  is_status_quo?: boolean;
  run_started?: string;
  created_user?: string;
  results: ScenarioResults;
  created_on?: string;
  is_template?: boolean;
  status?: string | null;
  error?: string | null;
  run_completed?: string;
}

export interface ScenarioInternal extends Scenario {
  assumptions: AssumptionsInternal;
}

export interface ScenarioExternal extends Scenario {
  assumptions: AssumptionsExternal;
}

export interface ProcessedScenarioToTable extends ScenarioInternal {
  energyCosts: number | string;
  demandCosts: number | string;
  totalCost: number | string;
  energySavings: number | string;
  demandSavings: number | string;
  totalSavings: number | string;
  blendedSavings: number | string;
}

/******************************** EXPLORE TAB  ********************************/

export type MonthlySolarSummary = {
  avgProductionPerInterval: number;
  endDataIndex: number;
  maxProductionPerInterval: number;
  startDataIndex: number;
  totalEnergy: number;
} & { [hour: string]: { value: number; key: string; count: number } };
export interface ExploreDetailsMeta {
  startDate: Moment;
  endDate: Moment;
  solarFrequency: 1 | 4; // 1 hour or 15 min
  loadFrequency: 1 | 4; // 1 hour or 15 min
  lmpRTFrequency: 1 | 4; // 1 hour or 15 min
  lmpDAFrequency: 1 | 4; // 1 hour or 15 min
  mefFrequency: 1 | 4; // 1 hour or 15 min
  ev1Frequency: 1 | 4; // 1 hour or 15 min
  ev2Frequency: 1 | 4; // 1 hour or 15 min
  solarScaleRating: number;
  solarLog: string;
  weekType: 'weekday';
  showMonthly: boolean;
  showSeasonal: boolean;
  showTOU: boolean;
  showPeak: boolean;
  selectedMonth: string;
  monthSummary: any;
  touMonthSummary: any;
  currentMonthlyData: any;
  summary: any;
  solarSummary: any;
  monthSolarSummary: any;
  touSummary: any;
  fromHour: number;
  toHour: number;
  months: any[];
  billingDisplay: {
    selectedTariff: any;
    selectedScenario: any; // ie 'Base Load'
  };
  unSavedChanges: boolean;
}

export type ScenarioResults = null | {
  'Average SOC': number[];
  'Average kWh Discharged CPS': number[];
  'Average kW Discharged CPS': number[];
  Cycles: number[];
  'Degradation Rate': number[];
  'Effective CP Rate': number[];
  'Charge From PV': number[];
  'Demand Cost MidPeak': number[];
  'Demand Cost NC': number[];
  'Energy Cost Gen': number[];
  'Load Kgs CO2 / kWh': number[];
  'Battery Kgs CO2': number[];
  'Demand Credit GA DR': number[];
  'Demand Cost OffPeak': number[];
  'Demand Cost PDP Reservation': number[];
  'Demand Credit PDP': number[];
  'Energy Cost PDP': number[];
  'Demand Cost Peak': number[];
  'Demand Cost Total': number[];
  'Demand Max MidPeak': number[];
  'Demand Max NC': number[];
  'Demand Max Peak': number[];
  'Demand Max NC Datetime': string[];
  'Demand Max Peak Datetime': string[];
  'Energy Cost Network': number[];
  'Energy Cost Total': number[];
  'VDER Revenues': number[];
  'VDER Energy Revenue': number[];
  'VDER ICAP Revenue': number[];
  'VDER DRV Revenue': number[];
  'VDER Environment Revenue': number[];
  'VDER Community Credit Revenue': number[];
  Months: string[];
  'Bill Range': string[];
  'Energy Cost Peak': number[];
  'Energy Cost MidPeak': number[];
  'Energy Cost OffPeak': number[];
  'Energy Use Peak': number[];
  'Energy Use MidPeak': number[];
  'Energy Use OffPeak': number[];
  'Energy Use Total': number[];
  'Demand Credit BIP': number[];
  'Energy Cost BIP': number[];
  'Total Kgs CO2': number[];
  'Battery Kgs CO2 / kWh': number[];
  'PDR Revenues': number[];
  'PDR Bids': number[];
  'PDR Hours': number[];
  'ICAP Cost': number[];
  'ITRAN Cost': number[];
  'Demand Cost GA': number[];
  'Demand Peak Factor GA': number[];
  'DR Reduction': number[];
  'DR Reduction Capacity': number[];
  'Smart Charging Cost': number[];
  'Dumb Charging Cost': number[];
  'Net Smart Charge Savings': number[];
  'EV Fleet Unmet kWh Percent': number[];
  'EV Base Subscription Cost': number[];
  'EV Fleet kWh charged': number[];
  'EV Overage Cost': number[];
  'Genset Runtime': number[];
  'Genset Average Efficiency': number[];
  'Genset Fuel Consumption': number[];
  'Load Curtailment': number[];
  'Genset Average Fuel Rate': number[];
  'Genset Starts': number[];
  'Genset Fuel Cost': number[];
  'Genset kWh': number[];
  'Islanded Battery Cycles': number[];
  'Exported Energy': number[];
  'Exported Energy On Peak': number[];
  'Exported Energy MidPeak': number[];
  'Exported Energy Off Peak': number[];
  'Energy Revenues Net Metering': number[];
  'Energy Revenues Net Metering On Peak': number[];
  'Energy Revenues Net Metering MidPeak': number[];
  'Energy Revenues Net Metering OffPeak': number[];
  'Net Metering Credit Balance': number[];
  'Net Metering Credit Change': number[];
  'Solar Curtailment': number[];
  'Solar Generation': number[];
  'Solar Curtailment During Outages': number[];
  RA_MEAN_ACTUAL: number[];
  RA_MIN_ACTUAL: number[];
  RA_MAX_ACTUAL: number[];
  RA_TOTAL_ACTUAL: number[];
  RA_MEAN_NORMAL: number[];
  RA_MAX_NORMAL: number[];
  RA_MIN_NORMAL: number[];
  RA_MEAN_OPTIM: number[];
  RA_MAX_OPTIM: number[];
  RA_MIN_OPTIM: number[];
  RA_MEAN_BEST: number[];
  RA_MAX_BEST: number[];
  RA_MIN_BEST: number[];
  NUM_WEEKDAY_DR_EVENTS: number[];
  NUM_WEEKEND_DR_EVENTS: number[];
  AVERAGE_WEEKDAY_DISCHARGE_KW: number[];
  AVERAGE_WEEKEND_DISCHARGE_KW: number[];
  'Clean Peak Standard': number[];
  CONNECTED_SOLUTIONS_DR_INCENTIVE: number[];
  CONNECTED_SOLUTIONS_DR_INCENTIVE_WEEKDAY: number[];
  CONNECTED_SOLUTIONS_DR_INCENTIVE_WEEKEND: number[];
  NUM_WEEKEND_DR_EVENTS_PARTICIPATED: number[];
  NUM_WEEKDAY_DR_EVENTS_PARTICIPATED: number[];
  'Num CPEC Produced': number[];
  CPS_CREDIT_VALUE: number[];
};

export type MonthlyTableKey =
  | keyof NonNullable<ScenarioResults>
  | `Before ${keyof NonNullable<ScenarioResults>}`
  | `After ${keyof NonNullable<ScenarioResults>}`
  | 'Total Savings'
  | 'Total Energy Savings'
  | 'Total Demand Savings'
  | 'Total GHG Reduction / kWh'
  | 'Demand Reduction NC'
  | 'Demand Reduction Peak'
  | `${Prefix} EV Total Demand Cost`
  | `${Prefix} EV Total Cost`
  | `${Prefix} EV Total Average Cost`
  | 'After EV Total Average Cost'
  | 'Months'
  | `${RealtimeResults} NC Demand Savings`
  | `${RealtimeResults} On-Peak Demand Savings`
  | `${RealtimeResults} Mid-Peak Demand Savings`
  | `${RealtimeResults} Energy Savings`
  | `${Prefix} Total GHG Emissions`
  | 'Total Part Peak GHG Reductions'
  | 'Total Off Peak GHG Reductions'
  | 'Total Peak GHG Reductions'
  | 'Total GHG Reductions'
  | 'Total GHG Reductions After Battery'
  | 'Solar Impact';

type Prefix = 'Before' | 'After';

type RealtimeResults = 'Perfect Knowledge' | 'Realtime' | '% from Perfect Knowledge -';

/****************************************************************************/
/* . Esap Tariff                                                            */
/****************************************************************************/

export interface TariffOverview {
  consumptionBaseAdjustment: number;
  consumptionBaseCharges: any[];
  demandBaseAdjustment: number;
  demandBaseCharges: any[];
  seasons: Seasons;
  nonBypassableRate?: number;
  nonBypassableCharges?: NBCharges[];
}

export interface NBCharges {
  name: string;
  amount: number;
  credit: boolean;
  type: string;
  calculationFactor?: any;
}

export enum TOUTypes {
  OFF_PEAK = 'OFF_PEAK',
  PARTIAL_PEAK = 'PARTIAL_PEAK',
  ON_PEAK = 'ON_PEAK',
  NON_COINCIDENTAL = 'NON_COINCIDENTAL',
  SUPER_OFF_PEAK = 'SUPER_OFF_PEAK',
  MID_PEAK = 'MID_PEAK',
  FLAT_RATE = 'FLAT_RATE'
}
export interface TOU {
  name: string;
  amount: number;
  credit: boolean;
  touType: keyof typeof TOUTypes;
  type: string;
  periods: [
    {
      fromDayOfWeek: number;
      fromHour: number;
      fromMinute: number;
      toDayOfWeek: number;
      toHour: number;
      toMinute: number;
    }
  ];
}

export type Seasons = Record<string, Season>;

export interface Season extends Partial<Record<keyof typeof TOUTypes, TOUPeriod[]>> {
  overview: SeasonOverview;
}

// export type Season = { overview: SeasonOverview } & Record<keyof typeof TOUTypes, TOUPeriod[]>;

export type SeasonOverview = {
  fromDay?: number;
  fromMonth?: number;
  name?: string;
  toDay?: number;
  toMonth?: number;
};

export type TOUPeriod = {
  name: string;
  amount: number;
  credit: boolean;
  touType: keyof typeof TOUTypes;
  type: 'CONSUMPTION_BASED' | 'DEMAND_BASED';
  periods: RateSchedule[];
  calculationFactor?: any;
};

export type RateSchedule = {
  fromDayOfWeek: number;
  fromHour: number;
  fromMinute: number;
  toDayOfWeek: number;
  toHour: number;
  toMinute: number;
  energyCharges?: number;
  demandCharges?: number;
};

export interface ESAPTariff {
  updated_on?: Date;
  old_id?: any;
  proposal?: number;
  id?: number;
  xml_file_name?: any;
  tariff_id?: number;
  tariffType?: string;
  created_user?: string;
  created_by?: string;
  created_on?: string;
  public_id: string;
  notes?: string;
  is_manual?: boolean;
  is_global?: boolean;
  type?: string;
  source_id: string;
  lse_name: string;
  all_rates?: any;
  lse_id: number;
  source_name?: 'Genability' | 'ESAP';
  code: string;
  name: string;
  description?: string;
  properties?: GenabilityProperty[] | null;
  effective_date: string;
  applicability_values?: Record<string, string>;
  updated_by?: string;
  updated_user?: string;
  demandCharges?: (string | number)[][];
  energyCharges?: (string | number)[][];
  overview: TariffOverview;
  ev_subscriptions?: Period[]; // EV
  overage_charges?: { charges: string; type: string }; // EV
  manualTariffId?: string | null;
  documentsUrl?: string[];
}

export interface FrequentUsedTariff {
  code: string;
  name: string;
  tariff_id: string | number;
  isFavorite?: boolean;
  effective_date: string;
}

export type FavoriteTariff = Partial<FrequentUsedTariff>;

/****************************************************************************/
/* . Genability tariff                                                      */
/****************************************************************************/

interface GeneabilityDocumentSection {
  documentSectionId: number;
  documentId: number;
  sectionHeading: string;
  sectionType: string;
  startPage: number;
  additionalNotes: string;
  customerClass: string;
  revised: boolean;
}

export interface GeneabilityDocument {
  documentId: number;
  documentTitle: string;
  archiveUrl: string;
  sourceUrl: string;
  sourceContentType: string;
  lseId: number;
  lseName: string;
  territoryId: number;
  territoryName: string;
  sequenceNumber: number;
  parentDocumentId: number;
  sections: GeneabilityDocumentSection[];
  priorDocumentId: number;
}

export interface GenabilityTariffDocument {
  documentId: number;
  documentSectionId: number;
  tariffId: number;
  document: GeneabilityDocument
}
export interface GenabilityTariff {
  tariffId: number;
  masterTariffId: number;
  tariffCode: string;
  tariffName: string;
  lseId: number;
  lseName: string;
  priorTariffId: number;
  tariffType: string;
  customerClass: string;
  customerCount: number;
  customerLikelihood?: any;
  territoryId: number;
  effectiveDate: string;
  endDate?: any;
  timeZone: string;
  billingPeriod: string;
  currency: string;
  chargeTypes: string;
  chargePeriod: string;
  hasTimeOfUseRates: boolean;
  hasTieredRates: boolean;
  hasContractedRates: boolean;
  hasRateApplicability: boolean;
  isActive: boolean;
  properties: GenabilityProperty[];
  rates: GenabilityRate[];
  documents: GenabilityTariffDocument[];
}

export interface GenabilityRate {
  tariffRateId: number;
  tariffId: number;
  riderId: number;
  tariffSequenceNumber: number;
  rateGroupName: string;
  rateName: string;
  fromDateTime: Date;
  toDateTime?: any;
  chargeType: 'CONSUMPTION_BASED' | 'DEMAND_BASED';
  chargePeriod: string;
  rateBands: GenabilityRateBand[];
  chargeClass: string;
  applicabilityKey: string;
  quantityKey: string;
  season: GenabilitySeason;
  timeOfUse: GenabilityTimeOfUse;
  variableRateKey: string;
  transactionType?: string;
}

export interface GenabilityRateBand {
  tariffRateBandId: number;
  tariffRateId: number;
  rateSequenceNumber: number;
  hasConsumptionLimit: boolean;
  hasDemandLimit: boolean;
  hasPropertyLimit: boolean;
  applicabilityValue: string;
  rateAmount: number;
  rateUnit: string;
  isCredit: boolean;
  prevUpperLimit?: any;
  calculationFactor: any;
}

export interface GenabilityTimeOfUse {
  touId: number;
  touGroupId: number;
  lseId: number;
  touName: string;
  calendarId?: number;
  isDynamic: boolean;
  season: GenabilitySeason;
  touType: string;
  touPeriods: GenabilityTouPeriod[];
}

export interface GenabilityTouPeriod {
  touPeriodId: number;
  touId: number;
  fromDayOfWeek: number;
  fromHour: number;
  fromMinute: number;
  toDayOfWeek: number;
  toHour: number;
  toMinute: number;
  calendarId?: number;
}

export interface GenabilitySeason {
  seasonId: number;
  lseId: number;
  seasonGroupId: number;
  seasonName: string;
  seasonFromMonth: number;
  seasonFromDay: number;
  seasonToMonth: number;
  seasonToDay: number;
}

export interface GenabilityProperty {
  keyName: string;
  quantityKey: string;
  displayName: string;
  family: string;
  keyspace: string;
  description: string;
  dataType: string;
  propertyTypes: string;
  operator: string;
  propertyValue: string;
  choices: GenabilityChoice[];
  isDefault: boolean;
  period: string;
  formulaDetail: string;
}

export interface GenabilityChoice {
  displayValue: string;
  value: string;
  dataValue: string;
  likelihood?: any;
}

export interface TariffFilters {
  zipCode: string;
  country: string;
  lseId: string;
  effectiveOn: string | null;
  search: string;
  monthlyConsumptionAverage: string;
  annualMaxDemand: string;
  timeOfUse: boolean;
  tiered: boolean;
  contracted: boolean;
  netMetering: boolean;
  residential: boolean;
  general: boolean;
  specialUse: boolean;
  tariffTypeTariff: boolean;
  tariffTypeRider: boolean;
  tariffTypeProposed: boolean;
  pageStart: number;
  pageCount: number;
  count: number;
}

// AUDIT LOG
export interface AuditLog {
  action_type: string;
  public_id: string;
  description: string;
  id: number;
  action_source: string;
  source_id: string;
  status: boolean;
  user_name: string;
  user_id: string;
  created_on: string;
  source_name: string;
}

export interface Favorite {
  public_id: string;
  name: string;
  description?: string;
}

/****************************************************************************/
/* . Portfolio                                                              */
/****************************************************************************/

export interface Portfolio {
  public_id: string;
  name: string;
  is_archived: boolean;
  updated_on: string;
  is_editable: boolean;
  created_user: string;
  updated_by?: string;
  description: string;
  old_id?: number;
  created_on?: string;
  id: number;
  created_by: string;
  portfolio_meters: PortfolioMeter[];
  updated_user?: string;
  results: PortfolioResults;
  status: string;
  notes: string;
  customer_id?: string;
  portfolio_type: PortfolioType;
}

export interface PortfolioMeter {
  scenario_id: string;
  scenario_name?: string;
  proposal_name?: string;
  is_primary: boolean;
  portfolio_id: string;
  status_quo_id?: string;
  proposal_id: string;
  scenarios: ScenarioInternal[];
}

export type PortfolioType = 'GENERAL' | 'NEMA';

export interface PortfolioResults {
  error: string;
  pv_export?: PVExport[];
  portfolio_meters?: ResultPortfolioMeter[];
  total_nem_revenue: number[];
}

export interface ResultPortfolioMeter {
  proposal_id: string;
  scenario_id: string;
  is_primary: boolean;
  pv_allocation: number;
  results: PortfolioMeterResults;
  nem_monthly: number[];
  scenario_name?: string;
  proposal_name?: string;
}

export interface PortfolioMeterResults {
  consumed_kwh_sum: number;
  total_demand_savings: number[];
  total_energy_savings: number[];
  nem_credit_revenue: number[];
  nem_credit_revenue_sum: number;
  total_energy_savings_sum: number;
  total_demand_savings_sum: number;
  solar_energy_savings?: number[];
  solar_energy_savings_sum?: number;
  solar_demand_savings?: number[];
  solar_demand_savings_sum?: number;
  tariff_demand_savings?: number[];
  tariff_demand_savings_sum?: number;
  tariff_energy_savings?: number[];
  tariff_energy_savings_sum: number;
  battery_energy_savings: number[];
  battery_energy_savings_sum: number;
  battery_demand_savings: number[];
  battery_demand_savings_sum: number;
  energy_cost_sum?: number;
  solar_nem_revenue: number[];
  solar_nem_revenue_sum: number;
  battery_nem_revenue: number[];
  battery_nem_revenue_sum: number;

  Months: string[];
}

export interface PVExport {
  value: number;
  datetime: string;
}

/************************************* LSE UTILITIES ********************************/
export interface GenabilityCalendar {
  status: string;
  count: number;
  type: string;
  results: CalendarResult[];
}

export interface CalendarResult {
  calendarId: number;
  calendarName: string;
  calendarType: 'BILLING' | 'PRICING_PERIOD' | 'HOLIDAY';
  lseId: number;
  events?: CalendarEvent[];
}

export interface CalendarEvent {
  calendarEventId: number;
  calendarEventName: string;
  lseId: null;
  calendarEventType: string;
  locale: string;
  seededUntil: Date;
}

export interface GenabilityLSEs {
  status: string;
  count: number;
  type: string;
  results: LSEResult[];
  pageCount: number;
  pageStart: number;
}

export interface LSEResult {
  lseId: number;
  name: string;
  lseCode: string;
  code: string;
  websiteHome: string;
  offeringType: LSEOfferingType;
  ownership: LSEOwnership;
  serviceTypes: LSEServiceTypes;
  totalRevenues: number | null;
  totalSales: number | null;
  totalCustomers: number;
  residentialServiceTypes: LSEServiceTypes;
  residentialRevenues: number | null;
  residentialSales: number | null;
  residentialCustomers: number | null;
  commercialServiceTypes: LSEServiceTypes;
  commercialRevenues: number | null;
  commercialSales: number | null;
  commercialCustomers: number | null;
  industrialServiceTypes: LSEServiceTypes | null;
  industrialRevenues: number | null;
  industrialSales: number | null;
  industrialCustomers: number | null;
  transportationServiceTypes: LSEServiceTypes | null;
  transportationRevenues: number | null;
  transportationSales: number | null;
  transportationCustomers: number | null;
  billingPeriodRepresentation: LSEBillingPeriodRepresentation;
}

export interface LSEBillingPeriodRepresentation {
  fromDateOffset: number;
  toDateOffset: number;
  style: LSEStyle;
}

export enum LSEStyle {
  GenabilityStyle = 'GenabilityStyle',
  InclusiveToDate = 'InclusiveToDate',
  Unknown = 'Unknown'
}

export enum LSEServiceTypes {
  Electricity = 'ELECTRICITY'
}

export enum LSEOfferingType {
  Bundle = 'Bundle',
  Delivery = 'Delivery',
  Energy = 'Energy'
}

export enum LSEOwnership {
  Investor = 'INVESTOR',
  RetailEnergyMarketer = 'RETAIL_ENERGY_MARKETER',
  State = 'STATE'
}

/****************************************************************************/
/* . UTILITY                                                               */
/****************************************************************************/

export interface UtilityInfo {
  created_user: null;
  public_id: string;
  updated_user: null;
  pdp_information: PDPInformation[];
  bip_information: BipInformation[];
  created_by: null;
  event_dates: string[];
  updated_by: null;
  lse_id: number;
  name: null;
  bip_event_dates: Array<string[]>;
  is_editable: boolean;
  id: number;
  updated_on: Date;
  created_on: Date;
  old_id: number;
}

export interface BipInformation {
  title?: string;
  maxDuration?: string;
  eventNotification?: string;
  summerPeakCredit?: string;
  summerMidPeakCredit?: string;
  summerPenalty?: string;
  summerStartMonth?: string;
  summerEndMonth?: string;
  winterCredit?: string;
  winterPenalty?: string;
  winterStartMonth?: string;
  winterEndMonth?: string;
}

export interface PDPInformation {
  title?: string;
  penalty?: string;
  reservationPrice?: string;
  peakCredit?: string;
  midPeakCredit?: string;
  eventStartHour?: string;
  eventEndHour?: string;
  peakMonthStart?: string;
  peakMonthEnd?: string;
  midPeakMonthStart?: string;
  midPeakMonthEnd?: string;
}

export interface UtilityForm {
  utilityDetails: UtilityDetails;
  calendars: CalendarResult[];
  utilityInfo: UtilityInfo;
}

export interface UtilityDetails {
  lseId: number;
  name: string;
  code: string;
  websiteHome: string;
}

/******************************** PRESENTATION TOOL  ********************************/

export interface ScenarioPresentation {
  statusQuo?: ScenarioInternal;
  scenario?: ScenarioInternal;
}

/************************************* IMMUTABLE JS  ********************************/

type AllowedValue = string | number | boolean | AllowedMap | AllowedList | TypedMap<any> | [] | {} | undefined;

interface AllowedList extends List<AllowedValue> {}

interface AllowedMap extends Map<string, AllowedValue> {}

export type MapTypeAllowedData<DataType> = {
  [K in keyof DataType]: AllowedValue;
};

export interface TypedMap<DataType extends MapTypeAllowedData<DataType>> extends Map<keyof DataType, AllowedValue> {
  // changed here

  toJS(): DataType;
  get<K extends keyof DataType>(key: K, notSetValue?: DataType[K]): DataType[K];
  set<K extends keyof DataType>(key: K, value: DataType[K]): this;
  setIn(keyPath: Iterable<any>, value: any): this;
  getIn(searchKeyPath: Iterable<any>, notSetValue?: any): any;
}

/****************************************************************************/
/* . JOBS QUEUE                                                             */
/****************************************************************************/

export interface Queues {
  optimization: Queue;
  no_optimization: Queue;
  forecast: Queue;
  portfolio: Queue;
  battery_sizing: Queue;
}

export interface Queue {
  num: number;
  started: number;
  deferred: number;
  finished: number;
  failed: number;
  scheduled: number;
  queued_jobs: Job[];
  running_jobs: Job[];
  finished_jobs: Job[];
}

export interface Job {
  job_id: string;
  position: number | null;
  created_at: string;
  ended_at: string | null;
  is_deferred: boolean;
  is_failed: boolean;
  is_finished: boolean;
  is_queued: boolean;
  is_scheduled: boolean;
  is_started: boolean;
  status: RedisJobStatus;
  proposal_id?: string;
  scenario_id?: string;
  proposal_name?: string;
  scenario_name?: string;
  portfolio_name?: string;
  portfolio_id?: string;
  created_user: null | string;
  run_status: string;
}
export type RedisJobStatus = 'queued' | 'started' | 'deferred' | 'finished' | 'stopped' | 'failed';

// take a JS object and convert to a Immutable Map while conserving types
export const createTypedMap = <DataType extends MapTypeAllowedData<DataType>>(data: DataType): TypedMap<DataType> =>
  fromJS(data) as any;

/****************************************************************************/
/* . LOAD SENSITIVITY ANALYSIS                                              */
/****************************************************************************/

export interface Lsa {
  data: LsaData;
  status: 'Processing' | 'Idle' | 'Complete' | 'Error' | 'Queued';
  detail: string;
  created_on: string;
}
export interface LsaData {
  peak_load_magnitude_change?: PeakLoadMagnitudeChange;
  peak_load_frequency_change?: PeakLoadFrequencyChange;
  peak_load_duration_change?: PeakLoadDurationChange;
}

export interface PeakLoadDurationChange {
  duration_15min: LsaSavings;
  duration_30min: LsaSavings;
}

export interface LsaSavings {
  energy_savings: number;
  demand_savings: number;
  total_savings: number;
  total_savings_percent: number;
}

export interface PeakLoadFrequencyChange {
  top_2_peaks: LsaSavings;
  top_5_peaks: LsaSavings;
  top_10_peaks: LsaSavings;
}

export interface PeakLoadMagnitudeChange {
  top_5: LsaCases;
  top_10: LsaCases;
  top_15: LsaCases;
}

export interface LsaCases {
  minus_20_percent: LsaSavings;
  minus_10_percent: LsaSavings;
  minus_5_percent: LsaSavings;
  minus_1_percent: LsaSavings;
  plus_1_percent: LsaSavings;
  plus_5_percent: LsaSavings;
  plus_10_percent: LsaSavings;
  plus_20_percent: LsaSavings;
}

export interface EsapTariffsFilters {
  effectiveOn?: string | null;
  zipCode?: string;
  lseId?: string;
  general?: boolean;
  residential?: boolean;
  specialUse?: boolean;
  tariffTypeTariff?: boolean;
  tariffTypeProposed?: boolean;
  tariffTypeRider?: boolean;
  pageStart?: number;
  pageCount?: number;
  search?: string;
  country?: string,
  monthlyConsumptionAverage?:string,
  annualMaxDemand?: string,
  timeOfUse?: boolean
  tiered?: boolean
  contracted?: boolean
  netMetering?: boolean
  count?: number
}
