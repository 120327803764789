import React, { useState } from 'react';
import { useCreateLSA } from '../../../../queries/lsa';

// MATERIAL UI IMPORTS
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Help from '@material-ui/icons/Help';
import { DURATION_TOOLTIP, FREQ_TOOLTIP, MAGNITUDE_TOOLTIP } from './LSAToolipsDef';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(3)
    },
    peakSelection: {
      width: 160,
      marginTop: 18,
      marginLeft: theme.spacing(3)
    },
    button: {
      margin: 30
    },
    questionMarkTopRight: {
      fontSize: 12,
      marginLeft: -12,
      marginRight: theme.spacing(3),
      color: '#ccc'
    }
  })
);

interface CreateLSAProps {
  proposalId: string;
  scenarioId: string;
}

const CreateLSA: React.FC<CreateLSAProps> = ({ proposalId, scenarioId }) => {
  const classes = useStyles();
  const [cases, setCases] = useState({ isPeakMagnitude: false, isPeakFrequency: false, isPeakDuration: false });

  const handleChangeCases = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCases({ ...cases, [event.target.name]: event.target.checked });
  };

  const { mutate } = useCreateLSA();

  const handleRequestLSA = () => {
    mutate({ proposalId, scenarioId, cases });
  };
  return (
    <div>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Apply changes to:</FormLabel>
        <FormGroup row>
          <>
            <FormControlLabel
              control={<Checkbox checked={cases.isPeakMagnitude} onChange={handleChangeCases} name="isPeakMagnitude" />}
              label="Peaks Magnitude"
            />
            <Tooltip title={MAGNITUDE_TOOLTIP}>
              <Help className={classes.questionMarkTopRight} />
            </Tooltip>
          </>
          <>
            <FormControlLabel
              control={<Checkbox checked={cases.isPeakFrequency} onChange={handleChangeCases} name="isPeakFrequency" />}
              label="Peaks Occurences"
            />
            <Tooltip title={FREQ_TOOLTIP}>
              <Help className={classes.questionMarkTopRight} />
            </Tooltip>
          </>
          <>
            <FormControlLabel
              control={<Checkbox checked={cases.isPeakDuration} onChange={handleChangeCases} name="isPeakDuration" />}
              label="Peaks Duration"
            />
            <Tooltip title={DURATION_TOOLTIP}>
              <Help className={classes.questionMarkTopRight} />
            </Tooltip>
          </>
        </FormGroup>
      </FormControl>
      <Button className={classes.button} variant="contained" color="primary" onClick={handleRequestLSA}>
        Request LSA
      </Button>
    </div>
  );
};

export default CreateLSA;
