import { Month } from '../../types';

// type guard to narrow down a type and assert it is defined
export function assertIsDefined<T>(
  arg: T | null | undefined,
  message: string = 'This value was promised to be there.'
): asserts arg is T {
  if (arg === undefined || arg === null) throw new TypeError(message);
}

// wrapper that passed the value over or throw an exception
export function ensure<T>(argument: T | undefined | null, message: string = 'This value was promised to be there.'): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

// asserts data is an array of values that pass a certain check
// Once the assertion passes (not throwing), TS will infer the proper type
export function assertIsTypedArray<T>(arg: any, check: (value: any) => value is T): asserts arg is T[] {
  if (!Array.isArray(arg)) throw new Error(`This is not an array: ${JSON.stringify(arg)}`);
  if (arg.some(el => !check(el)))
    throw new Error(`Some elements of the array are not the expected type:  ${JSON.stringify(arg)}`);
}

// helpers for the assertIsTypedArray function
export function isNumber(arg: any): arg is number {
  return typeof arg === 'number' && !isNaN(arg);
}

export function isNonEmptyString(arg: any): arg is string {
  return typeof arg === 'string' && arg.length > 0;
}

// check that a if or switch block is exhaustive
export function assertUnreachable(x: never): never {
  throw new Error(`Didn't expect to get here. Value: ${x}`);
}

export function assertIsMonth(value: unknown): asserts value is Month {
  if (
    value !== 'January' &&
    value !== 'February' &&
    value !== 'March' &&
    value !== 'April' &&
    value !== 'May' &&
    value !== 'June' &&
    value !== 'July' &&
    value !== 'August' &&
    value !== 'September' &&
    value !== 'October' &&
    value !== 'November' &&
    value !== 'December'
  ) {
    throw new TypeError('This value was expected to be a month string, got: ' + value);
  }
}
