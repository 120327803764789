import React from 'react';
import { MonthlySolarSummary } from '../../types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: 20
  },
  card: {
    marginBottom: 10
  }
});

interface SolarSummaryTableProps {
  monthlySolarSummary: MonthlySolarSummary;
  solarSummary: {
    avgAnnualProductionPerInterval: number;
    maxAnnualProductionPerInterval: number;
    totalEnergy: number;
  };
  annualTotalLoad: number;
  monthlyTotalLoad: number;
  solarFrequency: number;
}

const SolarSummaryTable: React.FC<SolarSummaryTableProps> = ({
  monthlySolarSummary,
  solarSummary,
  annualTotalLoad,
  monthlyTotalLoad,
  solarFrequency
}) => {
  const classes = useStyles();
  const isHourly = solarFrequency === 1;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <div>
            <CardContent>
              <Typography component="h5" variant="h5" style={{ marginBottom: 15, textAlign: 'center' }}>
                Annual Solar Summary
              </Typography>
              <Table padding="default">
                <TableBody>
                  <TableRow>
                    <TableCell>Max {isHourly ? 'Hourly' : '15min'} Production</TableCell>
                    <TableCell>
                      {Math.round(solarSummary.maxAnnualProductionPerInterval).toLocaleString()}{' '}
                      {isHourly ? ' kWh' : ' kW'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Average {isHourly ? 'Hourly' : '15min'} Production</TableCell>
                    <TableCell>
                      {solarSummary.avgAnnualProductionPerInterval.toFixed(2)} {isHourly ? ' kWh' : ' kW'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Production</TableCell>
                    <TableCell>{Math.round(solarSummary.totalEnergy).toLocaleString()} kWh</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Energy Offset</TableCell>
                    <TableCell>
                      {annualTotalLoad ? Math.round((solarSummary.totalEnergy / annualTotalLoad) * 100) : 0} %
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </div>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <div>
            <CardContent>
              <Typography component="h5" variant="h5" style={{ marginBottom: 15, textAlign: 'center' }}>
                Monthly Solar Summary
              </Typography>
              <Table padding="default">
                <TableBody>
                  <TableRow>
                    <TableCell>Max {isHourly ? 'Hourly' : '15min'} Production</TableCell>
                    <TableCell>
                      {Math.round(monthlySolarSummary.maxProductionPerInterval).toLocaleString()}{' '}
                      {isHourly ? ' kWh' : ' kW'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Average {isHourly ? 'Hourly' : '15min'} Production</TableCell>
                    <TableCell>
                      {monthlySolarSummary.avgProductionPerInterval.toFixed(2)} {isHourly ? ' kWh' : ' kW'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Production</TableCell>
                    <TableCell>{Math.round(monthlySolarSummary.totalEnergy).toLocaleString()} kWh</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Energy Offset</TableCell>
                    <TableCell>
                      {monthlyTotalLoad ? Math.round((monthlySolarSummary.totalEnergy / monthlyTotalLoad) * 100) : 0} %
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SolarSummaryTable;
