import { RUN_FORECASTING_SUCCESS, GET_FORECASTING_STATUS_SUCCESS, RESET_FORECASTING } from '../../actions/explore';

const INITIAL_STATE = { data: [], overall_summary: '', hourly_summary: {}, status: '' };

export default function forecastingAnalysisReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RUN_FORECASTING_SUCCESS:
    case GET_FORECASTING_STATUS_SUCCESS:
      return { ...state, ...action.payload };
    case RESET_FORECASTING:
      return INITIAL_STATE;
    default:
      return state;
  }
}
