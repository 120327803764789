import moment from 'moment';

export function getTOUBands(seasons, myStartDateTime, myEndDateTime, touName) {
  let startDateTime = moment(myStartDateTime);
  let endDateTime = moment(myEndDateTime);
  const bands = [];

  // handle startDateTime adjustments
  let startBands = getDayTOUBand(seasons, startDateTime, touName);
  let filteredStartBands = [];
  startBands.forEach(band => {
    // tou period happens before current hour
    if (moment(band.start).hour() >= startDateTime.hour()) {
      filteredStartBands.push(band);
    }
    // only include active peak period if midway through peak
    else if (moment(band.start).hour() <= startDateTime.hour() && startDateTime.hour() <= moment(band.end).hour()) {
      filteredStartBands.push({
        start: moment(startDateTime).valueOf(),
        end: band.end
      });
    }
  });
  if (filteredStartBands.length > 0) {
    bands.push(filteredStartBands);
  }

  // handle endDateTime adjustments
  let endBands = getDayTOUBand(seasons, endDateTime, touName);
  let filteredEndBands = [];
  endBands.forEach(band => {
    // tou period happens after current hour
    if (moment(band.end).hour() <= endDateTime.hour()) {
      filteredEndBands.push(band);
    }
    // only include active peak period if midway through peak
    else if (moment(band.start).hour() <= endDateTime.hour() && endDateTime.hour() <= moment(band.end).hour()) {
      filteredEndBands.push({
        start: band.start,
        end: moment(endDateTime).valueOf()
      });
    }
  });
  if (filteredEndBands.length > 0) {
    bands.push(filteredEndBands);
  }
  // truncate range to only include full days
  startDateTime.add(1, 'days');
  endDateTime.subtract(1, 'days');

  // for loop over all days in range
  for (let loopDate = moment(startDateTime); loopDate.isSameOrBefore(endDateTime); loopDate.add(1, 'days')) {
    const loopDateBands = getDayTOUBand(seasons, loopDate, touName);
    if (loopDateBands.length > 0) {
      bands.push(loopDateBands);
    }
  }

  return bands;
}

function getDayTOUBand(seasons, startDateTime, touName) {
  let dayBands = [];
  // find season for datetime
  let season = getActiveSeason(seasons, startDateTime, touName);
  if (!season || !season[touName] || season[touName].length === 0 || !season[touName][0].periods) {
    return dayBands;
  }
  let periods = season[touName][0].periods;

  periods.forEach(period => {
    let startDateDayOfWeek = startDateTime.isoWeekday() - 1;

    if (period.fromDayOfWeek <= startDateDayOfWeek && period.toDayOfWeek >= startDateDayOfWeek) {
      let periodStart = moment(startDateTime);
      periodStart.hour(period.fromHour);
      periodStart.minute(period.fromMinute);
      let periodEnd = moment(startDateTime);
      periodEnd.hour(period.toHour);
      periodEnd.minute(period.toMinute);
      // check if its an overnight period
      if (period.fromHour > period.toHour) {
        periodEnd = periodEnd.add(1, 'days');
      }
      dayBands.push({
        start: periodStart.valueOf(),
        end: periodEnd.valueOf()
      });
    }
  });
  return dayBands;
}

function getActiveSeason(seasons, dateTimeToCheck, touName) {
  let activeSeasonKey = Object.keys(seasons).find(seasonKey => {
    let season = seasons[seasonKey];
    if (!seasons || !season.overview) {
      return false;
    }
    if (season.overview.toMonth < season.overview.fromMonth) {
      // need to split cause it wraps from later months to beginning months
      let lateMonthStart = season.overview.fromMonth - 1;
      let lateMonthEnd = 11; // December

      let earlyMonthStart = 0; // January
      let earlyMonthEnd = season.overview.toMonth - 1;

      if (lateMonthStart <= dateTimeToCheck.month() && lateMonthEnd >= dateTimeToCheck.month()) {
        return season.hasOwnProperty(touName);
      } else if (earlyMonthStart <= dateTimeToCheck.month() && earlyMonthEnd >= dateTimeToCheck.month()) {
        return season.hasOwnProperty(touName);
      }
    } else {
      return (
        season.hasOwnProperty(touName) &&
        season.overview.fromMonth - 1 <= dateTimeToCheck.month() &&
        season.overview.toMonth - 1 >= dateTimeToCheck.month()
      );
    }
    return false;
  });
  return seasons[activeSeasonKey];
}
