import React from 'react';
import PropTypes from 'prop-types';
// import Plot from 'react-plotly.js';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
const Plot = createPlotlyComponent(Plotly);

var opData = [
  ['2017-06-19T00:00:00.000Z', '946.4', '988.691067645'],
  ['2017-06-19T00:15:00.000Z', '932.8', '975.091067645'],
  ['2017-06-19T00:30:00.000Z', '945.6', '987.891067645'],
  ['2017-06-19T00:45:00.000Z', '938.4', '980.691067645'],
  ['2017-06-19T01:00:00.000Z', '932.8', '975.091067645'],
  ['2017-06-19T01:15:00.000Z', '942.4', '984.691067645'],
  ['2017-06-19T01:30:00.000Z', '936', '978.291067645'],
  ['2017-06-19T01:45:00.000Z', '951.2', '993.491067645'],
  ['2017-06-19T02:00:00.000Z', '912', '954.291067645'],
  ['2017-06-19T02:15:00.000Z', '901.6', '943.891067645'],
  ['2017-06-19T02:30:00.000Z', '926.4', '968.691067645'],
  ['2017-06-19T02:45:00.000Z', '920.8', '963.091067645'],
  ['2017-06-19T03:00:00.000Z', '914.4', '956.691067645'],
  ['2017-06-19T03:15:00.000Z', '896', '938.291067645'],
  ['2017-06-19T03:30:00.000Z', '905.6', '947.891067645'],
  ['2017-06-19T03:45:00.000Z', '920', '962.291067645'],
  ['2017-06-19T04:00:00.000Z', '925.6', '967.891067645'],
  ['2017-06-19T04:15:00.000Z', '919.2', '961.491067645'],
  ['2017-06-19T04:30:00.000Z', '913.6', '955.891067645'],
  ['2017-06-19T04:45:00.000Z', '890.4', '932.691067645'],
  ['2017-06-19T05:00:00.000Z', '884', '926.291067645'],
  ['2017-06-19T05:15:00.000Z', '868.8', '911.091067645'],
  ['2017-06-19T05:30:00.000Z', '878.4', '920.691067645'],
  ['2017-06-19T05:45:00.000Z', '897.6', '939.891067645'],
  ['2017-06-19T06:00:00.000Z', '868', '910.291067645'],
  ['2017-06-19T06:15:00.000Z', '873.6', '915.891067645'],
  ['2017-06-19T06:30:00.000Z', '885.6', '927.891067645'],
  ['2017-06-19T06:45:00.000Z', '878.4', '920.691067645'],
  ['2017-06-19T07:00:00.000Z', '863.2', '905.491067645'],
  ['2017-06-19T07:15:00.000Z', '867.2', '909.491067645'],
  ['2017-06-19T07:30:00.000Z', '852', '894.291067645'],
  ['2017-06-19T07:45:00.000Z', '852.8', '895.091067645'],
  ['2017-06-19T08:00:00.000Z', '852.8', '895.091067645'],
  ['2017-06-19T08:15:00.000Z', '860.8', '903.091067645'],
  ['2017-06-19T08:30:00.000Z', '861.6', '903.891067645'],
  ['2017-06-19T08:45:00.000Z', '872', '914.291067645'],
  ['2017-06-19T09:00:00.000Z', '855.2', '897.491067645'],
  ['2017-06-19T09:15:00.000Z', '866.4', '908.691067645'],
  ['2017-06-19T09:30:00.000Z', '856', '898.291067645'],
  ['2017-06-19T09:45:00.000Z', '847.2', '889.491067645'],
  ['2017-06-19T10:00:00.000Z', '853.6', '895.891067645'],
  ['2017-06-19T10:15:00.000Z', '862.4', '904.691067645'],
  ['2017-06-19T10:30:00.000Z', '874.4', '916.691067645'],
  ['2017-06-19T10:45:00.000Z', '848.8', '891.091067645'],
  ['2017-06-19T11:00:00.000Z', '864', '906.291067645'],
  ['2017-06-19T11:15:00.000Z', '875.2', '917.491067645'],
  ['2017-06-19T11:30:00.000Z', '881.6', '923.891067645'],
  ['2017-06-19T11:45:00.000Z', '872', '914.291067645'],
  ['2017-06-19T12:00:00.000Z', '1110.4', '1152.69106765'],
  ['2017-06-19T12:15:00.000Z', '1232.8', '1275.09106765'],
  ['2017-06-19T12:30:00.000Z', '1204', '1246.29106765'],
  ['2017-06-19T12:45:00.000Z', '1230.4', '1272.69106765'],
  ['2017-06-19T13:00:00.000Z', '1610.4', '1652.69106765'],
  ['2017-06-19T13:15:00.000Z', '1991.2', '2033.49106765'],
  ['2017-06-19T13:30:00.000Z', '2014.4', '2056.69106765'],
  ['2017-06-19T13:45:00.000Z', '1949.6', '1991.89106765'],
  ['2017-06-19T14:00:00.000Z', '2002.4', '2044.69106765'],
  ['2017-06-19T14:15:00.000Z', '2000.8', '2043.09106765'],
  ['2017-06-19T14:30:00.000Z', '2010.4', '2052.69106765'],
  ['2017-06-19T14:45:00.000Z', '2107.2', '2149.49106765'],
  ['2017-06-19T15:00:00.000Z', '2136.8', '2136.8'],
  ['2017-06-19T15:15:00.000Z', '2171.2', '2171.2'],
  ['2017-06-19T15:30:00.000Z', '2208.8', '2208.8'],
  ['2017-06-19T15:45:00.000Z', '2244.8', '2244.8'],
  ['2017-06-19T16:00:00.000Z', '2286.4', '2286.4'],
  ['2017-06-19T16:15:00.000Z', '2307.2', '2307.2'],
  ['2017-06-19T16:30:00.000Z', '2320', '2313.6'],
  ['2017-06-19T16:45:00.000Z', '2315.2', '2313.6'],
  ['2017-06-19T17:00:00.000Z', '2300', '2308.36492347'],
  ['2017-06-19T17:15:00.000Z', '2312', '2313.6'],
  ['2017-06-19T17:30:00.000Z', '2313.6', '2313.6'],
  ['2017-06-19T17:45:00.000Z', '2385.6', '2313.6'],
  ['2017-06-19T18:00:00.000Z', '2544.8', '2313.6'],
  ['2017-06-19T18:15:00.000Z', '2567.2', '2313.6'],
  ['2017-06-19T18:30:00.000Z', '2500.8', '2313.6'],
  ['2017-06-19T18:45:00.000Z', '2477.6', '2313.6'],
  ['2017-06-19T19:00:00.000Z', '2524', '2105.18765714'],
  ['2017-06-19T19:15:00.000Z', '2482.4', '2105.18765714'],
  ['2017-06-19T19:30:00.000Z', '2488', '2105.18765714'],
  ['2017-06-19T19:45:00.000Z', '2456', '2105.18765714'],
  ['2017-06-19T20:00:00.000Z', '2447.2', '2105.18765714'],
  ['2017-06-19T20:15:00.000Z', '2434.4', '2105.18765714'],
  ['2017-06-19T20:30:00.000Z', '2479.2', '2105.18765714'],
  ['2017-06-19T20:45:00.000Z', '2489.6', '2105.18765714'],
  ['2017-06-19T21:00:00.000Z', '2483.2', '2105.18765714'],
  ['2017-06-19T21:15:00.000Z', '2591.2', '2105.18765714'],
  ['2017-06-19T21:30:00.000Z', '2584', '2105.18765714'],
  ['2017-06-19T21:45:00.000Z', '2544.8', '2105.18765714'],
  ['2017-06-19T22:00:00.000Z', '2486.4', '2105.18765714'],
  ['2017-06-19T22:15:00.000Z', '2404', '2105.18765714'],
  ['2017-06-19T22:30:00.000Z', '2403.2', '2105.18765714'],
  ['2017-06-19T22:45:00.000Z', '2417.6', '2105.18765714'],
  ['2017-06-19T23:00:00.000Z', '2412', '2105.18765714'],
  ['2017-06-19T23:15:00.000Z', '2371.2', '2105.18765714'],
  ['2017-06-19T23:30:00.000Z', '2323.2', '2105.18765714'],
  ['2017-06-19T23:45:00.000Z', '2280.8', '2105.18765714']
];

var trace1 = {
  type: 'scatter',
  mode: 'lines',
  name: 'Original Demand',
  fill: 'tozeroy',
  x: opData.map(x => x[0]),
  y: opData.map(x => x[1]),
  line: { color: 'rgb(55, 83, 109)' }
};

var trace2 = {
  type: 'scatter',
  mode: 'lines',
  name: 'New Demand',
  fill: 'tozeroy',
  x: opData.map(x => x[0]),
  y: opData.map(x => x[2]),
  line: { color: 'rgb(26, 118, 255)' }
};

var data = [trace1, trace2];

var layout = {
  title: 'Original vs New Non-Coincidence',
  yaxis: {
    title: 'kW'
  },
  legend: {
    x: 0.005,
    y: 1
  },
  shapes: [
    {
      type: 'rect',
      xref: 'x',
      yref: 'paper',
      x0: '2017-06-19T21:15:00.000Z',
      y0: 0,
      x1: '2017-06-19T21:30:00.000Z',
      y1: 1,
      fillcolor: 'red',
      opacity: 0.2,
      line: {
        width: 0
      }
    }
  ]
};

var config = {
  modeBarButtonsToRemove: [
    'sendDataToCloud',
    'zoom2d',
    'pan2d',
    'select2d',
    'lasso2d',
    'zoomIn2d',
    'zoomOut2d',
    'autoScale2d',
    'toggleSpikelines',
    'hoverClosestCartesian',
    'hoverCompareCartesian'
  ],
  displaylogo: false
};

export default class PeakEventChart extends React.Component {
  render() {
    return (
      <Paper>
        <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
          <Typography variant="title" color="inherit">
            Original Peak Day
          </Typography>
        </Toolbar>
        <Plot
          style={{ width: '100%', height: '300px' }}
          useResizeHandler={true}
          data={data}
          layout={layout}
          config={config}
        />
      </Paper>
    );
  }
}

PeakEventChart.propTypes = {
  classes: PropTypes.object.isRequired,
  scenarioData: PropTypes.object.isRequired,
  peakAmount: PropTypes.number.isRequired
};
