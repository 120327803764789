import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

// MATERIAL UI IMPORTS
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles, Theme } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Refresh from '@material-ui/icons/Refresh';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tooltip from '@material-ui/core/Tooltip';

// REACT IMPORTS
import { getProposal, trainForecasting, updateExplorePageInformation } from '../../../actions';
import { StoreState } from '../../../reducers';
import { AssumptionsInternal, ForecastStatus, ProposalInternal } from '../../../types';
import { assertUnreachable } from '../../../utils/assertions';
import VerticalWhiteSpace from '../../Common/VerticalWhiteSpace';

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  markLabel: {
    fontSize: theme.spacing(1.25)
  }
}));

interface RealtimeConfigProps {
  realtimeSimStart?: string | null;
  realtimeSimEnd?: string | null;
  isSOCReserve: boolean;
  isMaximizeSOC: boolean;
  socReserve: number;
  maximizeSOC_RT: number;
  handleToggle: any;
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
  handleGeneralAssumptionChange: (event: any) => void;
  proposal: ProposalInternal;
}

const RealtimeConfig: React.FC<RealtimeConfigProps> = ({
  isSOCReserve,
  realtimeSimStart,
  realtimeSimEnd,
  handleToggle,
  isMaximizeSOC,
  socReserve,
  maximizeSOC_RT,
  handleGeneralAssumptionChange,
  handleAssumptionUpdateByKey,
  proposal
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const exploreDetailsMetaInfo = useSelector((state: StoreState) => state.exploreDetailsMetaInfo);

  // const { startDate, endDate } = exploreDetailsMetaInfo;

  useEffect(() => {
    // update start and end dates from forecast if completed
    if (
      !proposal?.isNewProposal &&
      proposal?.forecasting_start != null &&
      proposal?.forecasting_end != null &&
      !realtimeSimStart &&
      !realtimeSimEnd
    ) {
      handleAssumptionUpdateByKey('realtimeSimStart')(moment(proposal.forecasting_start).format('MM/DD/YYYY'));
      handleAssumptionUpdateByKey('realtimeSimEnd')(moment(proposal.forecasting_end).format('MM/DD/YYYY'));

      dispatch(
        updateExplorePageInformation({
          startDate: moment(moment(proposal.forecasting_start).format('MM/DD/YYYY')),
          endDate: moment(moment(proposal.forecasting_end).format('MM/DD/YYYY'))
        })
      );
    }
  }, [
    dispatch,
    proposal.forecasting_end,
    proposal.forecasting_start,
    proposal?.isNewProposal,
    realtimeSimEnd,
    realtimeSimStart
  ]);

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    handleAssumptionUpdateByKey('realtimeSimStart')(date?.format('MM/DD/YYYY'));
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    handleAssumptionUpdateByKey('realtimeSimEnd')(date?.format('MM/DD/YYYY'));
  };

  const formatHours = (value: number): string => {
    return value.toString().length === 2 ? `${value.toString()}:00` : `0${value.toString()}:00`;
  };

  const handleChangeMaximSOC = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    if (typeof value !== 'number') throw new Error('I was expecting a number for maximizeSOC_RT');
    handleAssumptionUpdateByKey('maximizeSOC_RT')(value);
  };

  const marks = () =>
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(h => ({
      value: h,
      label: formatHours(h)
    }));

  const isSOCReserveError = (): boolean => isNaN(socReserve) || +socReserve < 0.0 || +socReserve >= 100.0;

  const refreshProposal = () => {
    dispatch(getProposal(proposal.public_id));
  };

  const trainAndFetchProposal = () => {
    dispatch(
      trainForecasting(proposal.public_id, {
        onSuccess: () => {
          dispatch({ type: 'UPDATE_PROPOSAL_FORECAST_STATUS', payload: 'training' });
        }
      })
    );
  };

  const handleToggleSOCReserve = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleToggle(event, checked);

    if (checked) {
      handleAssumptionUpdateByKey('socReserve')(10);
    } else {
      handleAssumptionUpdateByKey('socReserve')(0);
    }
  };

  const renderForecastButton = (status: ForecastStatus) => {
    switch (status) {
      case 'not run':
        return (
          <Alert severity="warning">
            <AlertTitle>Action needed</AlertTitle>A forecast needs to be generated first:
            <Button
              startIcon={<TimelineIcon />}
              variant="contained"
              style={{ marginLeft: 16, marginRight: 16 }}
              onClick={trainAndFetchProposal}
              data-testid="generate-forecast-button"
            >
              Generate Forecast
            </Button>
            <Tooltip title="Refresh Forecast Status">
              <Button
                startIcon={<Refresh />}
                size="small"
                color="primary"
                aria-label="Refresh"
                onClick={refreshProposal}
              >
                Refresh
              </Button>
            </Tooltip>
          </Alert>
        );
      case 'training':
        return (
          <Alert severity="info">
            <AlertTitle>Training</AlertTitle>
            The model is currently being trained, please come back later.
          </Alert>
        );
      case 'error':
        return (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Error while fetching the forecast —{' '}
            <strong>Please try again or reach out for technical support if the error persists.</strong>
            <Button
              data-testid="generate-forecast-button"
              variant="contained"
              style={{ marginLeft: 16 }}
              onClick={trainAndFetchProposal}
            >
              Generate Forecast
            </Button>
          </Alert>
        );
      case 'complete':
        return (
          <Alert severity="success">
            <AlertTitle>Forecasting completed</AlertTitle>
            Please check out the forecast data in <strong>Data/Run analysis</strong>
          </Alert>
        );
      case null:
        return (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Forcasting not available for this proposal.
          </Alert>
        );
      default:
        assertUnreachable(status);
    }
  };
  return (
    <div
      style={{
        paddingLeft: 25,
        marginTop: 16,
        borderLeft: '10px solid #eee'
      }}
    >
      {proposal?.isNewProposal ? (
        <div>
          <Alert severity="warning">
            <AlertTitle>Pre-requisite for a real-time simulation run</AlertTitle>
            Real-time scenario runs won't start automatically, you'll need to import data, save it to the proposal and
            then generate a forecast first.
          </Alert>
        </div>
      ) : (
        renderForecastButton(proposal.forecasting_status)
      )}
      <VerticalWhiteSpace />
      {proposal.forecasting_status === 'complete' && (
        <>
          <div>
            <Typography gutterBottom variant="body2" style={{ fontWeight: 700 }}>
              Simulation period:
            </Typography>
          </div>
          <div>
            <DatePicker
              onFocus={() => {}}
              onBlur={() => {}}
              autoOk
              label="Start Date"
              value={realtimeSimStart ?? exploreDetailsMetaInfo.startDate}
              maxDate={realtimeSimEnd ?? exploreDetailsMetaInfo.endDate}
              format="L"
              style={{ color: '#fff', marginRight: 12 }}
              onChange={handleStartDateChange}
              data-testid="realtime-start-date"
            />
            <DatePicker
              onFocus={() => {}}
              onBlur={() => {}}
              autoOk
              label="End Date"
              value={realtimeSimEnd ?? exploreDetailsMetaInfo.endDate}
              minDate={realtimeSimStart ?? exploreDetailsMetaInfo.startDate}
              format="L"
              style={{ color: '#fff', marginRight: 12 }}
              onChange={handleEndDateChange}
              data-testid="realtime-start-end"
            />
          </div>
          <div>
            <FormControlLabel
              className={classes.block}
              control={<Switch onChange={handleToggleSOCReserve} name="isSOCReserve" checked={isSOCReserve} />}
              label={
                <>
                  <span>Reserve SOC</span>
                  {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                </>
              }
            />
            {isSOCReserve && (
              <TextField
                label="SOC Reserve (%)"
                name="socReserve"
                value={socReserve}
                onChange={handleGeneralAssumptionChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="number"
                margin="normal"
                error={isSOCReserveError()}
                helperText={isSOCReserveError() ? 'Should be a valid, positive percentage between 0 and 100' : ''}
              />
            )}
          </div>
          <div>
            <FormControlLabel
              className={classes.block}
              control={<Switch onChange={handleToggle} name="isMaximizeSOC" checked={isMaximizeSOC} />}
              label={
                <>
                  <span>Maximize SOC</span>
                  {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                </>
              }
            />
            {isMaximizeSOC && (
              <Slider
                data-testid="maximize-slider"
                min={0}
                max={23}
                step={1}
                onChange={handleChangeMaximSOC}
                classes={{
                  markLabel: classes.markLabel
                }}
                value={maximizeSOC_RT}
                //     valueLabelDisplay="auto"
                //     getAriaValueText={formatHours}
                marks={marks()}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RealtimeConfig;
