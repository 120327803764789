import React, { useState, useEffect } from 'react';
// import Plot from 'react-plotly.js';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
const Plot = createPlotlyComponent(Plotly);

const styles = {
  block: {
    marginTop: 8,
    marginBottom: 8
  }
};

const ConsumptionGenerationChart = props => {
  const { monthlyOperationsData } = props;

  const [data, setData] = useState([]);

  const buildLayout = () => {
    const layout = {
      legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
      height: 280,
      margin: {
        t: 20,
        l: 60,
        r: 20,
        b: 40
      },
      xaxis: {
        // tickangle: -45
      },
      barmode: 'group'
    };

    return layout;
  };

  // build the data
  // TODO :fix []
  useEffect(() => {
    if (monthlyOperationsData && Object.keys(monthlyOperationsData).length > 0) {
      const x = Object.keys(monthlyOperationsData);
      const consumption = [];
      const generation = [];
      x.forEach(monthKey => {
        consumption.push(monthlyOperationsData[monthKey]['vecLoad'].reduce((a, b) => a + b, 0) / 4);
        generation.push(monthlyOperationsData[monthKey]['vecPInv'].reduce((a, b) => a + b, 0) / 4);
      });
      let updatedData = [];
      updatedData.push({
        type: 'bar',
        name: 'Consumption',
        x: x,
        y: consumption,
        marker: {
          color: 'rgb(0, 91, 187)'
        }
      });
      updatedData.push({
        type: 'bar',
        name: 'Generation',
        x: x,
        y: generation,
        marker: {
          color: 'rgb(255, 160, 47)'
        }
      });
      setData(updatedData);
    }
    // eslint-disable-next-line
  }, [monthlyOperationsData]);

  // Need to update for technologies
  const handleDownload = () => {
    // const headers = ['Datetime', 'Consumption', 'Generation'];
    // let downloadData = [];
    // data[0].x.forEach((date, index) => {
    //   const row = [date];
    //   data.forEach(item => {
    //     row.push(item.y[index]);
    //   });
    //   downloadData.push(row);
    // });
    // exportCSVFile(headers, downloadData, 'Consumption vs Generation Comparison ');
  };

  return (
    <Paper>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          Consumption vs Generation
        </Typography>
        <IconButton aria-label="Download" onClick={handleDownload}>
          <InsertDriveFile />
        </IconButton>
      </Toolbar>
      <div style={{ height: 300 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={data}
          layout={buildLayout()}
          config={{ displayModeBar: false }}
        />
      </div>
    </Paper>
  );
};

ConsumptionGenerationChart.propTypes = {
  monthlyOperationsData: PropTypes.object.isRequired
};

export default withStyles(styles)(ConsumptionGenerationChart);
