import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MATERIAL UI IMPORTS
import { makeStyles, Theme } from '@material-ui/core/styles';

// REACT IMPORTS
import { getAllPortfolios, deletePortfolio, RESET_PORTFOLIOS_LIST_FILTERS } from '../../actions/portfolios';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import { createLoadingSelector } from '../../selectors';
import PortfoliosList from '../../components/Portfolios/List/PortfoliosList';
import { StoreState } from '../../reducers';
import { RouteComponentProps } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: theme.spacing(3)
  }
}));

const PortfoliosListPage: React.FC<RouteComponentProps> = props => {
  const classes = useStyles();
  const portfolios = useSelector((state: StoreState) => state.portfolios);
  const portfoliosMeta = useSelector((state: StoreState) => state.portfoliosMeta);

  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector(['GET_ALL_PORTFOLIOS', 'COPY_PORTFOLIO']);
  const isLoading = useSelector(loadingSelector);

  useEffect(() => {
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Portfolios', '/portfolios'));
  }, []);

  useEffect(() => {
    if (!portfolios || portfolios.length === 0) {
      dispatch(getAllPortfolios({ page: 1, pageSize: portfoliosMeta.pageSize }));
    }
  }, []);

  const resetPortfoliosList = () => {
    dispatch({ type: RESET_PORTFOLIOS_LIST_FILTERS });
    dispatch(getAllPortfolios({ page: 1, pageSize: portfoliosMeta.pageSize }));
  };

  return (
    <div className={classes.padding}>
      <PortfoliosList
        portfolios={portfolios}
        portfoliosMeta={portfoliosMeta}
        title="Portfolios"
        history={props.history}
        deletePortfolio={deletePortfolio}
        resetPortfoliosList={resetPortfoliosList}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PortfoliosListPage;
