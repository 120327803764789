import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import TariffSelectListContainer from '../Tariffs/List/TariffSelectListContainer';
import TariffProperties from '../Tariffs/Detail/TariffProperties';
import { getTariffs, updateTariffFilters } from '../../actions/tariffs';
import { getAllManualTariffs } from '../../actions/manualtariffs';

const style = theme => ({
  root: {
    width: '100%'
  },
  sidenav: {
    backgroundColor: '#EEEEEE',
    width: `calc(100%)`,
    [theme.breakpoints.up('md')]: {
      width: `calc(50%)`
    }
  }
});

// Lots of duplication between this and ProposalTariffContainer
class ExploreTariffCreateContainer extends React.Component {
  state = {
    isOpen: false,
    currentTariff: null
  };

  componentDidMount() {
    this.props.getTariffs({});
    this.props.updateTariffFilters();
    this.props.getAllManualTariffs();
  }

  handleTariffFilterUpdate = filters => {
    this.props.updateTariffFilters(filters);
    this.props.getTariffs(filters);
  };

  handleTariffSearchByText = search => {
    this.handleTariffFilterUpdate({
      ...this.props.tariffFilters,
      search: search
    });
  };

  handleTariffSelect = (tariff, isManual) => {
    if (isManual) {
      // manual tariff and no need to select properties
      this.handleTariffAdd(tariff);
    } else if (tariff.properties && tariff.properties.findIndex(property => property.dataType === 'CHOICE') === -1) {
      // properties do not exist so you don't need to select them and can add safely
      this.handleTariffAdd(tariff);
    } else {
      this.setState({
        currentTariff: tariff
      });
      this.toggleDrawer();
    }
  };

  handleTariffAddFromProperties = tariff => {
    this.handleTariffAdd(tariff);
    this.toggleDrawer();
  };

  handleTariffAdd = tariff => {
    if (this.props.handleSave) {
      this.props.handleSave(tariff);
    }
  };

  toggleDrawer = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <TariffSelectListContainer
          selected={[]}
          tariffs={this.props.tariffs}
          manualTariffs={this.props.manualTariffs}
          filters={this.props.tariffFilters}
          updateTariffFilters={this.handleTariffFilterUpdate}
          handleTariffSelect={this.handleTariffSelect}
          handleTariffSearchByText={this.handleTariffSearchByText}
        />
        <Drawer
          classes={{ paper: classes.sidenav }}
          anchor="right"
          open={this.state.isOpen}
          onClose={this.toggleDrawer}
        >
          <TariffProperties
            currentTariff={this.state.currentTariff}
            handleCancel={this.toggleDrawer}
            handleSave={this.handleTariffAddFromProperties}
          />
        </Drawer>
      </div>
    );
  }
}

ExploreTariffCreateContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSave: PropTypes.func
};

const mapStateToProps = state => {
  return {
    tariffs: state.tariffs,
    manualTariffs: state.manualTariffs,
    tariffFilters: state.tariffFilters
  };
};

export default connect(mapStateToProps, { getTariffs, getAllManualTariffs, updateTariffFilters })(
  withStyles(style)(ExploreTariffCreateContainer)
);
