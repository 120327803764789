import React, { useState } from 'react';

// Material UI
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ShowChart } from '@material-ui/icons';
import HistoricalData from '../../../components/Explore/HistoricalData';
import AnalysisOverview from '../../../components/Explore/AnalysisOverview';
import { DataRequired, DataType, ProposalInternal } from '../../../types';
// import { exportCSVFile } from '../../../utility/General';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    flex: {
      flex: 1
    },
    button: {
      // color: '#fff',
      // border: '1px solid #fff',
      marginRight: 10
    },
    white: {
      color: '#fff',
      marginRight: 10
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    loadingRoot: {
      textAlign: 'center',
      position: 'fixed',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,.35)',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2000
    },
    loadingCenter: {
      top: '50%',
      left: '50%',
      position: 'absolute'
    }
  })
);

const views = {
  MANUAL: 'MANUAL'
};

interface ProposalDataProps {
  proposal: ProposalInternal;
  isRequired: DataRequired;
}

const ProposalData: React.FC<ProposalDataProps> = ({ proposal, isRequired }) => {
  const classes = useStyles();

  const [state, setState] = useState({
    value: DataType.load,
    view: views.MANUAL,
    isMeterSelected: false,
    showAnalysis: false
  });

  const handleAnalysisClick = () => {
    setState({ ...state, showAnalysis: !state.showAnalysis });
  };

  // handleDownload = () => {
  //   // TODO : finish this for other load types and solar
  //   const headers = ['Date', 'Time', 'Value'];
  //   let downloadData;
  //   if (state.value === 'load') {
  //     downloadData = loadData;
  //   } else if (state.value === 'solar') {
  //     downloadData = solarData;
  //   }

  //   exportCSVFile(headers, downloadData, 'Historical Data ');
  // };
  return (
    <div>
      {!state.showAnalysis && (
        <Paper className={classes.root}>
          <div>
            <Toolbar className={classes.toolbar}>
              <Typography color="inherit" variant="h6" className={classes.flex}>
                Historical Data
              </Typography>
              <Button variant="contained" onClick={handleAnalysisClick} size="small" className={classes.button}>
                <ShowChart />
                Run Analysis
              </Button>
            </Toolbar>
            <HistoricalData required={isRequired} canEdit={proposal && proposal.last_run ? false : true} />
          </div>
        </Paper>
      )}
      {state.showAnalysis && (
        <div>
          <Button variant="contained" onClick={handleAnalysisClick} size="small" className={classes.button}>
            Back to Table
          </Button>
          <AnalysisOverview />
        </div>
      )}
    </div>
  );
};

export default ProposalData;
