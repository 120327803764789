import React, { useState } from 'react';
import moment from 'moment';

// REACT IMPORTS
import ConfirmPopUp from '../../Common/ConfirmPopUp/ConfirmPopUp';
import AccessControl from '../../../utility/AccessControl';
import { ESAPTariff, ScenarioInternal, ProposalInternal, GenabilityProperty, GenabilityTariff } from '../../../types';

// MATERIAL UI IMPORTS
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Refresh from '@material-ui/icons/Refresh';
import Delete from '@material-ui/icons/Delete';
import { createProposalTariff, deleteProposalTariff, updateAllProposalTariffs } from '../../../actions/proposal_tariffs';
import { useDispatch, useSelector } from 'react-redux';
import FavoriteAndFrequentTariffs from '../../../pages/Tariffs/_components/FavoriteAndFrequentTariffs';
import TariffsGridList from '../../../pages/Tariffs/_components/TariffsGridList';
import { StoreState } from '../../../reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    table: {
      minWidth: 700
    },
    toolbar: {
      backgroundColor: (theme.palette as any).esap.blueDim,
      color: '#fff',
      borderBottom: '1px solid #e5e5e5'
    },
    favoritesContainer: {
      marginBottom: '20px',
    },
  })
);

interface Props {
  tariffs: ESAPTariff[];
  handleRowClick(tariff: ESAPTariff): any;
  proposalId: string;
  title?: string;
  proposalScenarios: ScenarioInternal[];
  proposal: ProposalInternal;
}
const initialConfirm = { type: '', message: '' };

const ProposalDetailTariffContainer: React.FC<Props> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const proposal = useSelector((state: StoreState) => state.proposal);
  const [createView, setCreateView] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState<boolean | null>(null);
  const [confirm, setConfirm] = useState(initialConfirm);
  const [snackMessage, setSnackMessage] = useState('');
  const [selectedTariff, setSelectedTariff] = useState<ESAPTariff | null>(null);
  const [currentTariff, setCurrentTariff] = useState<ESAPTariff | GenabilityTariff | undefined>(undefined);

  const { tariffs, handleRowClick, title, proposalScenarios } = props;

  const handleViewToggle = () => setCreateView((prevValue) => !prevValue);
  const handleSetToggleDrawer = () => setToggleDrawer((prevValue) => !prevValue);

  const handleDeleteAction = (tariff: ESAPTariff) => (event: any) => {
    event.stopPropagation();
    const isTariffUsed =
      proposalScenarios.filter(scenario => scenario.assumptions.tariffId === tariff.public_id).length > 0;
    if (isTariffUsed) {
      setSnackMessage('You are not allowed to delete a tariff currently used.');
    } else {
      setSelectedTariff(tariff);
      setConfirm({ type: 'DELETE', message: 'Are you sure that you want to delete this tariff?' });
    }
  };

  const handleConfirmClose = (success: boolean) => () => {
    if (success) {
      if (confirm.type === 'DELETE' && selectedTariff) {
        dispatch(deleteProposalTariff(selectedTariff, props.proposalId));
      }
      if (confirm.type === 'UPDATE') {
        dispatch(updateAllProposalTariffs(props.proposal));
      }
    }
    setConfirm(initialConfirm);
    setSelectedTariff(null);
  };

  const hideMessage = () => {
    setSnackMessage('');
  };

  const handleConfirmRefreshTariffs = () => {
    setConfirm({
      type: 'UPDATE',
      message: 'WARNING! This will update each tariff to its newest version and reset all scenarios results.'
    });
  };

  const buildBaseTariffObject = (tariff: ESAPTariff): Partial<ESAPTariff> => {
    const esapTariff: Partial<ESAPTariff> = {
      source_id: tariff.source_id || tariff.public_id || tariff.tariff_id?.toString(),
      lse_name: tariff.lse_name,
      lse_id: tariff.lse_id ? tariff.lse_id : 0,
      code: tariff.code,
      effective_date: tariff.effective_date,
      type: tariff.type,
      name: tariff.name,
      applicability_values: tariff.applicability_values,
      ev_subscriptions: tariff.ev_subscriptions,
      overage_charges: tariff.overage_charges,
      overview: tariff?.overview
    };

    if (tariff.is_manual) {
      esapTariff.is_manual = true;
    }

    return esapTariff;
  };

  const handleTariffSave = (tariff: ESAPTariff) => {
    const tariffData = buildBaseTariffObject(tariff);

    dispatch(createProposalTariff(tariffData, proposal.public_id));
    handleViewToggle();
    setToggleDrawer(null);
  }

  const handleTariffClick = (tariff: ESAPTariff, isManual?: boolean) => {
    if (isManual) {
      // manual tariff no need to select properties
      handleTariffSave({ ...tariff, is_manual: true });
    } else if (
      !tariff.source_id || // Means is a frequently used tariff
      tariff.properties &&
      tariff.properties.findIndex((property: GenabilityProperty) => property.dataType === 'CHOICE' && property.isDefault) === -1
    ) {
      // properties do not exist so you don't need to select them and can add safely
      handleTariffSave(tariff);
    } else {
      setCurrentTariff(tariff);
      handleSetToggleDrawer();
    }
  };

  return (
    <div>
      {!createView && (
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
              {title ? title : 'Tariffs'}
            </Typography>
            <div>
              <AccessControl requiredPermissions={['editor', 'admin']}>
                <>
                  <IconButton aria-label="Add" onClick={handleViewToggle} style={{ color: 'white' }}>
                    <AddIcon />
                  </IconButton>
                  <Tooltip title="Refresh tariffs with latest versions">
                    <IconButton
                      size="small"
                      color="primary"
                      aria-label="Refresh"
                      className="button-white"
                      onClick={handleConfirmRefreshTariffs}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                </>
              </AccessControl>
            </div>
          </Toolbar>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Utility</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Effective</TableCell>
                <TableCell>Created</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tariffs?.map((tariff, index) => {
                return (
                  <TableRow key={index} hover onClick={handleRowClick(tariff)}>
                    <TableCell scope="row">{tariff.lse_name}</TableCell>
                    <TableCell>{tariff.name}</TableCell>
                    <TableCell>{tariff.code}</TableCell>
                    <TableCell>{tariff.type}</TableCell>
                    <TableCell>{tariff.effective_date}</TableCell>
                    <TableCell>{moment(tariff.created_on).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>
                      <AccessControl requiredPermissions={['editor', 'admin']}>
                        <IconButton onClick={handleDeleteAction(tariff)}>
                          <Delete />
                        </IconButton>
                      </AccessControl>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <ConfirmPopUp
            open={confirm.message.length > 0}
            onConfirmClose={handleConfirmClose}
            message={confirm.message}
          />
          <Snackbar
            open={snackMessage.length > 0}
            autoHideDuration={5000}
            onClose={hideMessage}
            message={<span id="message-id">You can't delete a tariff currently used in a scenario.</span>}
            action={[
              <Button key="undo" color="secondary" size="small" onClick={hideMessage}>
                OK
              </Button>,
              <IconButton key="close" aria-label="Close" color="inherit" onClick={hideMessage}>
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Paper>
      )}
      {createView && (
        <>
          <div className={classes.favoritesContainer}>
            <FavoriteAndFrequentTariffs handleTariffOnClick={handleTariffClick} />
          </div>

          <TariffsGridList
            onTariffClick={handleTariffClick}
            openCloseDrawer={toggleDrawer}
            currentTariff={currentTariff}
            handleTariffSave={handleTariffSave}
          />
        </>
      )}
    </div>
  );
};

export default ProposalDetailTariffContainer;
