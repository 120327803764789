import axios from 'axios';
import { Dispatch } from 'redux';
import { ESAPTariff, ProposalInternal, ProposalExternal, GenabilityTariff, TariffOverview } from '../types';
import { parseTariffInformationUpdated } from '../utility/Tariff';
import { assertIsDefined } from '../utils/assertions';
import { resetProposalScenarioResults } from './scenarios';

export const STAGE_TARIFF = 'STAGE_TARIFF';
export const CLEAR_STAGED_TARIFF = 'CLEAR_STAGED_TARIFF';

export const CREATE_PROPOSAL_TARIFF_REQUEST = 'CREATE_PROPOSAL_TARIFF_REQUEST';
export const CREATE_PROPOSAL_TARIFF_SUCCESS = 'CREATE_PROPOSAL_TARIFF_SUCCESS';
export const CREATE_PROPOSAL_TARIFF_ERROR = 'CREATE_PROPOSAL_TARIFF_ERROR';

export const DELETE_PROPOSAL_TARIFF_REQUEST = 'DELETE_PROPOSAL_TARIFF_REQUEST';
export const DELETE_PROPOSAL_TARIFF_SUCCESS = 'DELETE_PROPOSAL_TARIFF_SUCCESS';
export const DELETE_PROPOSAL_TARIFF_ERROR = 'DELETE_PROPOSAL_TARIFF_ERROR';

export const UPDATE_ALL_PROPOSAL_TARIFF_REQUEST = 'UPDATE_ALL_PROPOSAL_TARIFF_REQUEST';
export const UPDATE_ALL_PROPOSAL_TARIFF_SUCCESS = 'UPDATE_ALL_PROPOSAL_TARIFF_SUCCESS';
export const UPDATE_ALL_PROPOSAL_TARIFF_ERROR = 'UPDATE_ALL_PROPOSAL_TARIFF_ERROR';

export const GET_PROPOSAL_TARIFF_REQUEST = 'GET_PROPOSAL_TARIFF_REQUEST';
export const GET_PROPOSAL_TARIFF_ERROR = 'GET_PROPOSAL_TARIFF_ERROR';
export const GET_PROPOSAL_TARIFF_SUCCESS = 'GET_PROPOSAL_TARIFF_SUCCESS';

const ESAP_API_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export function createProposalTariff(proposalTariff: Partial<ESAPTariff>, proposalId: string) {
  return (dispatch: Dispatch) => {
    assertIsDefined(
      proposalId,
      'Cant find the proposal public id, to save the tariff to the proposal, got: ' + proposalId
    );
    dispatch({ type: CREATE_PROPOSAL_TARIFF_REQUEST });
    axios
      .post(`${ESAP_API_URL}/proposal/${proposalId}/tariff`, proposalTariff)
      .then(res => {
        const tariff = res.data;
        tariff.effective_date = tariff.effective_date.replace('T00:00:00', '');
        dispatch({
          type: CREATE_PROPOSAL_TARIFF_SUCCESS,
          payload: tariff
        });
      })
      .catch(function (error) {
        console.error(CREATE_PROPOSAL_TARIFF_ERROR + ' error: ' + error);
      });
  };
}

export function deleteProposalTariff(proposalTariff: ESAPTariff, proposalId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: DELETE_PROPOSAL_TARIFF_REQUEST });
    axios
      .delete(`${ESAP_API_URL}/proposal/${proposalId}/tariff/${proposalTariff.public_id}`)
      .then(res => {
        dispatch({
          type: DELETE_PROPOSAL_TARIFF_SUCCESS,
          payload: proposalTariff.public_id
        });
      })
      .catch(function (error) {
        console.error(DELETE_PROPOSAL_TARIFF_ERROR + ' error: ' + error);
      });
  };
}

export function updateAllProposalTariffs(proposal: ProposalInternal) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_ALL_PROPOSAL_TARIFF_REQUEST });
    proposal.proposal_tariffs.forEach(proposal_tariff => {
      // for each tariff, get the latest version from Genability
      if (!proposal_tariff.is_manual && proposal_tariff?.source_id && proposal_tariff.source_id.length > 0) {
        axios
          .get<GenabilityTariff>(`${ESAP_API_URL}/genability/tariff/${proposal_tariff.source_id}`)
          .then(res => {
            const tariff = res.data;
            const {
              properties,
              applicability_values,
              overview,
              energyCharges,
              demandCharges,
              all_rates
            } = parseTariffInformationUpdated(tariff, proposal_tariff?.applicability_values);

            const tariffInfo = tariff;

            const processedTariff: Partial<ESAPTariff> = {
              tariff_id: tariffInfo.tariffId,
              source_id: tariffInfo.masterTariffId.toString(),
              properties,
              applicability_values: applicability_values as Record<string, string>,
              overview: overview as TariffOverview,
              energyCharges,
              demandCharges,
              all_rates,
              lse_name: tariffInfo.lseName,
              lse_id: tariffInfo.lseId,
              code: tariffInfo.tariffCode,
              name: tariffInfo.tariffName,
              effective_date: tariffInfo.effectiveDate
            };
            // update the corresponding proposal tariff with the latest version
            return processedTariff;
          })
          .then(processedTariff => {
            return axios.put(
              `${ESAP_API_URL}/proposal/${proposal.public_id}/tariff/${proposal_tariff.public_id}`,
              processedTariff
            );
          })
          .then(resp => {
            dispatch({ type: UPDATE_ALL_PROPOSAL_TARIFF_SUCCESS, payload: resp.data });
            const scenarios = proposal.proposal_scenarios.filter(
              scenario => scenario.assumptions.tariffId === resp.data.public_id
            );
            scenarios.forEach(s => {
              dispatch(resetProposalScenarioResults(s, proposal.public_id) as any);
            });
          })
          .catch(error => {
            dispatch({ type: UPDATE_ALL_PROPOSAL_TARIFF_ERROR });
            console.error(error);
          });
      }
    });
  };
}

export function stageTariff(tariff: ESAPTariff) {
  return (dispatch: Dispatch) => {
    dispatch({ type: STAGE_TARIFF, payload: tariff });
  };
}

export function clearStagedTariff() {
  return (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_STAGED_TARIFF });
  };
}

export function getProposalTariff(proposalId: string, tariffId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_PROPOSAL_TARIFF_REQUEST });
    axios
      .get<ProposalExternal>(
        `${ESAP_API_URL}/proposal/${proposalId}?include_audit_logs=true&include_scenario_summary=True&`
      )
      .then(res => {
        const proposal = res.data;
        const tariff = proposal.proposal_tariffs.find(t => t.public_id === tariffId);
        dispatch({ type: GET_PROPOSAL_TARIFF_SUCCESS, payload: tariff });
      })
      .catch(function (error) {
        console.log(GET_PROPOSAL_TARIFF_ERROR + ' error: ' + error);
        dispatch({ type: GET_PROPOSAL_TARIFF_ERROR, payload: error });
      });
  };
}
