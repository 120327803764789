import {
  CREATE_GLOBAL_SETTINGS_SUCCESS,
  UPDATE_GLOBAL_SETTINGS_SUCCESS,
  DELETE_GLOBAL_SETTINGS_SUCCESS,
  GET_GLOBAL_SETTING_BY_KEY_SUCCESS
} from '../../actions/globalsettings';

const INITIAL_STATE = {};
export default function globalSettingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_GLOBAL_SETTING_BY_KEY_SUCCESS:
    case CREATE_GLOBAL_SETTINGS_SUCCESS:
    case UPDATE_GLOBAL_SETTINGS_SUCCESS:
      return action.payload;
    case DELETE_GLOBAL_SETTINGS_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
