import { GET_RECENT_PROPOSALS_SUCCESS } from '../../actions/proposals';
import { ProposalInternal } from '../../types/index.js';

const INITIAL_STATE: ProposalInternal[] = [];

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_RECENT_PROPOSALS_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}
