import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../reducers';

type CurrencyDisplayProps = {
  value: number;
  locale?: string;
  currency?: string;
};

const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({ value, locale, currency }) => {
  const globalocale = useSelector((state: StoreState) => state.locale);

  locale = locale ? locale : globalocale;
  currency = currency ? currency : 'USD';
  //NOTE: minimumFractionDigits: 0  - for now we only use for none decimal display, should add into props eventually
  return (
    <span>
      {!isNaN(value) &&
        new Intl.NumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 0 }).format(
          value
        )}
    </span>
  );
};

export default CurrencyDisplay;
