import { useState } from 'react';
import { ESAPTariff, FavoriteTariff } from '../types';
import { getSavedItem, setSavedItem } from '../utility/localStorage';

type Tariff = ESAPTariff | FavoriteTariff;

export function useFavoritesTariffs () {
  const TARIFF_STORAGE_KEY = 'FAVORITES-TARIFF';
  const [favoritesTariff, setFavoriteTariff] = useState<FavoriteTariff[]>(getSavedItem<FavoriteTariff[]>(TARIFF_STORAGE_KEY) || []);

  const onFavoriteClick = (tariff: Tariff): boolean => {
    const { code, effective_date } = tariff;
    const favoritesList = getSavedItem<Tariff[]>(TARIFF_STORAGE_KEY) || [];
    const isFavorite = favoritesList.some((item) => item.code === code && item.effective_date === effective_date);
    const updatedItems = isFavorite
      ? favoritesList.filter((item: Tariff) => !(item.code === code && item.effective_date === effective_date))
      : [...favoritesList, { ...tariff, isFavorite: true }];

    setSavedItem(TARIFF_STORAGE_KEY, updatedItems);
    setFavoriteTariff(updatedItems);

    dispatchEvent(new Event(`${TARIFF_STORAGE_KEY}-UPDATED`));

    return isFavorite;
  }

  return [favoritesTariff, onFavoriteClick, TARIFF_STORAGE_KEY] as const;
}
