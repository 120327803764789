import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  }
});

class UtilityDetailTariffContainer extends React.Component {
  render() {
    const { tariffs, handleRowClick, title, classes } = this.props;
    return (
      <Paper>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            {title ? title : 'Tariffs'}
          </Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Utility</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Effective</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tariffs.map(tariff => {
              return (
                <TableRow key={tariff.tariff_id + tariff.effective_date} hover onClick={handleRowClick(tariff)}>
                  <TableCell component="th" scope="row">
                    {tariff.lseName}
                  </TableCell>
                  <TableCell>{tariff.name}</TableCell>
                  <TableCell>{tariff.code}</TableCell>
                  <TableCell>{tariff.type}</TableCell>
                  <TableCell>{tariff.effective_date}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

UtilityDetailTariffContainer.propTypes = {
  tariffs: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func.isRequired
};

export default withStyles(styles)(UtilityDetailTariffContainer);
