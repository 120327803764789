import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Save from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core';
import { getGlobalSettingByKey, updateGlobalSetting } from '../../actions/globalsettings';
import { createLoadingSelector } from '../../selectors';
import { popBreadcrumb, pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import AccessControl, { isAdmin } from '../../utility/AccessControl';

const style = theme => ({
  root: {
    width: '100%'
  },
  container: {
    padding: 20,
    maxWidth: 650,
    margin: '0 auto'
  }
});

const NetBenefitsTest = props => {
  const {
    location,
    classes,
    handleDataSave,
    setting,
    isLoading,
    updateGlobalSetting,
    getGlobalSettingByKey,
    resetBreadcrumb,
    pushBreadcrumb
  } = props;

  const [data, setData] = useState([]);
  const [settings, setSettings] = useState({});
  const [message, setMessage] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const tableRef = useRef(null);

  // get the proper setting
  useLayoutEffect(() => {
    getGlobalSettingByKey('Net Benefits Test');
  }, [getGlobalSettingByKey]);

  // breadcrumbs
  useLayoutEffect(() => {
    resetBreadcrumb();
    pushBreadcrumb('Settings', '/settings');
    pushBreadcrumb('Net Benefits Test', location.pathname);
  }, [location.pathname, pushBreadcrumb, resetBreadcrumb]);

  //update settings and data
  useEffect(() => {
    const generateVerticalSettings = () => {
      return {
        colHeaders: ['Year', 'Month', 'On-Peak', 'Off-Peak'],
        stretchH: 'all',
        beforeValidate: value => {
          let newValue = parseFloat(value.toString().replace(',', '').trim());
          if (isNaN(newValue)) {
            return '';
          } else {
            return newValue;
          }
        },
        beforeChange: (changes, source) => {
          for (let i = 0; i < changes.length; i++) {
            const newValue = parseFloat(changes[i][3].toString().replace(',', '').trim());
            if (isNaN(newValue)) {
              changes[i][3] = '';
            } else {
              changes[i][3] = newValue;
            }
          }
        },
        className: 'htCenter htMiddle',
        rowHeights: 15,
        columns: [
          {
            type: 'text',
            allowEmpty: false,
            readOnly: true,
            colWidths: 50,
            readOnly: !isAdmin()
          },
          {
            type: 'text',
            allowEmpty: false,
            readOnly: true,
            readOnly: !isAdmin(),
            colWidths: 50
          },
          {
            type: 'numeric',
            colWidths: 50,
            readOnly: !isAdmin(),
            allowEmpty: false
          },
          {
            type: 'numeric',
            colWidths: 50,
            readOnly: !isAdmin(),
            allowEmpty: false
          }
        ]
      };
    };

    const generateVerticalGrid = (startYear, startMonth, settings) => {
      const grid = [];
      startMonth = startMonth.toString();
      startYear = startYear.toString();

      if (startMonth.length === 1) {
        startMonth = '0' + startMonth;
      }
      const start = new moment(startYear + '-' + startMonth + '-01');
      const end = new moment();
      const duration = moment.duration(end.diff(start));
      const numMonths = Math.floor(duration.asMonths());

      for (let i = 0; i <= numMonths; i++) {
        if (settings && settings.value && settings.value.length > 0 && settings.value[i]) {
          grid.push([start.year(), start.month() + 1, settings.value[i][2], settings.value[i][3]]);
        } else {
          grid.push([start.year(), start.month() + 1, 0, 0]);
        }
        start.add(1, 'months');
      }
      return grid;
    };

    const generateGrid = () => {
      const grid = generateVerticalGrid(2016, 7, setting);
      if (handleDataSave) {
        handleDataSave(grid);
      }
      return grid;
    };

    const settings = generateVerticalSettings();
    const data = generateGrid();
    setSettings(settings);
    setData(data);
  }, [handleDataSave, setting]);

  const handleSave = () => {
    updateGlobalSetting(
      {
        ...setting,
        value: data
      },
      {
        onSuccess: () => {
          setMessage('Data Saved Successfully!');
          setHasChanged(false);
        },
        onError: () => {
          setMessage('Error!');
        }
      }
    );
  };

  const handleModifyData = (index, amount) => {
    setData(data);
    setHasChanged(true);
  };
  return (
    <Paper className={classes.root}>
      <AccessControl requiredPermissions={['editor', 'admin']}>
        <div className="button-container">
          <Fab size="small" disabled={!hasChanged} color="primary" aria-label="Save" onClick={handleSave}>
            <Save />
          </Fab>
        </div>
      </AccessControl>
      <div className={classes.container}>
        {isLoading && (
          <div style={{ textAlign: 'center', padding: 25 }}>
            <CircularProgress color="secondary" size={50} />
          </div>
        )}
        {!isLoading && (
          <div id="hot-app">
            <HotTable
              ref={tableRef}
              data={data}
              settings={settings}
              height="800"
              stretchH="all"
              afterRemoveRow={handleModifyData}
              afterCreateRow={handleModifyData}
              afterSetDataAtCell={handleModifyData}
            />
          </div>
        )}
      </div>

      <Snackbar
        open={message.length > 0}
        autoHideDuration={5000}
        onClose={() => {
          setMessage('');
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <Button
            key="undo"
            color="secondary"
            size="small"
            onClick={() => {
              setMessage('');
            }}
          >
            OK
          </Button>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={() => {
              setMessage('');
            }}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Paper>
  );
};

NetBenefitsTest.propTypes = {
  classes: PropTypes.object.isRequired
};

const loadingSelector = createLoadingSelector(['GET_GLOBAL_SETTING_BY_KEY']);

const mapStateToProps = state => {
  return {
    setting: state.setting,
    isLoading: loadingSelector(state),
    breadcrumbsTrail: state.breadcrumbsTrail
  };
};

export default connect(mapStateToProps, {
  getGlobalSettingByKey,
  updateGlobalSetting,
  popBreadcrumb,
  pushBreadcrumb,
  resetBreadcrumb
})(withStyles(style)(NetBenefitsTest));
