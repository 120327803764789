import { RUN_GENABILITY_BILLING_SUCCESS } from '../../actions/tariffs';

const INITIAL_STATE = {};

export default function exploreCalculationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RUN_GENABILITY_BILLING_SUCCESS:
      const results = action.payload;
      const key = results.scenarioName + '_' + results.tariffId;
      return { ...state, [key]: results };
    default:
      return state;
  }
}
