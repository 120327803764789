import axios from 'axios';
import { Dispatch } from 'redux';

export const GET_GA_EVENTS_REQUEST = 'GET_GA_EVENTS_REQUEST';
export const GET_GA_EVENTS_SUCCESS = 'GET_GA_EVENTS_SUCCESS';
export const GET_GA_EVENTS_ERROR = 'GET_GA_EVENTS_ERROR';

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export const getGAEventsDropdown = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_GA_EVENTS_REQUEST });
    axios
      .all([
        axios.get(`${ROOT_URL}/settings/global?key=GA%20Events`),
        axios.get(`${ROOT_URL}/settings/global?key=GA%20Events%20Predicted`)
      ])
      .then(
        axios.spread((GAEvents, GAEventsPredicted) => {
          return { GAEvents: GAEvents.data.value, GAEventsPredicted: GAEventsPredicted.data.value };
        })
      )
      .then(payload =>
        dispatch({
          type: GET_GA_EVENTS_SUCCESS,
          payload
        })
      )
      .catch(error => {
        dispatch({
          type: GET_GA_EVENTS_ERROR,
          payload: error
        });
      });
  };
};
