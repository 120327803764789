import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS } from 'immutable';

// MATERIAL UI IMPORTS
import Tab from '@material-ui/core/Tab';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker } from '@material-ui/pickers';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// REACT IMPORTS
import { generateProfileGrid } from '../../utility/Explore';
import {
  updateSolarProfile,
  updateLoadProfile,
  updateLMPDAProfile,
  updateLMPRTProfile,
  storeLoadProfile,
  storeSolarProfile,
  storeLMPDAProfile,
  storeLMPRTProfile,
  updateMEFProfile,
  storeMEFProfile,
  storeEV1Profile,
  updateEV1Profile,
  storeEV2Profile,
  updateEV2Profile
} from '../../actions/profiledata';
import ProfileTable from './ProfileTable';
import { uploadDataFile, uploadHelioscopeDataFile } from '../../actions/uploadDataFile';
import { populateFacilityLoad } from '../../actions/facility_loads';
import { profileFrequencyChoices } from '../../utility/General';
import UtilityAPIMeters from '../UtilityAPI/UtilityAPIMeters';
import { getMeterInterval, getMeters } from '../../actions/utilityapi';
import { updateExplorePageInformation } from '../../actions/explore';
import DataFileImportDialog from './DataFileDialog';
import LMPZemaImportDialog from './LMPZemaImportDialog';
import { createLoadingSelector } from '../../selectors';
import { exportCSVFile } from '../../utility/General';
import { StoreState } from '../../reducers';
import AccessControl from '../../utility/AccessControl';
import FacilityPresetDialog from './FacilityPresetDialog';
import { useLocation } from 'react-router';
import { updateProposalScenario } from '../../actions';
import { DataRequired, DataType } from '../../types';
import { assertUnreachable } from '../../utils/assertions';

const useStyles = makeStyles(
  createStyles({
    button: {
      margin: 10
    },
    loadingContainer: {
      textAlign: 'center',
      padding: 25
    },
    white: {
      color: '#fff',
      marginRight: 10
    }
  })
);

const utilityAPIAccounts: string[] = ['Ethan', 'Sean', 'Powerflex', 'BDA'].sort((a, b) => {
  // sort the accounts alphabetically
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
});

interface HistoricalDataProps {
  required?: DataRequired;
  canEdit?: boolean;
}

const HistoricalData: React.FC<HistoricalDataProps> = ({ required, canEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  // LOCAL STATE
  const [value, setValue] = useState<DataType>(DataType.load);
  const [isUtilityAPI, setIsUtilityAPI] = useState(false);
  const [isFacilityPreset, setIsFacilityPreset] = useState(false);
  const [utilityAPIAccount, setUtilityAPIAccount] = useState('Ethan');
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [dialogLMPZemaOpen, setDialogLMPZemaOpen] = useState(false);
  const [dataType, setDataType] = useState<DataType>(DataType.load);
  const [openLog, setOpenLog] = useState(false);
  const [openSolarLog, setOpenSolarLog] = useState(false);
  const [fixedEnergyCost, setFixedEnergyCost] = useState('');
  const [confirmApply, setConfirmApply] = useState(false);
  const [confirmPVMax, setConfirmPVMax] = useState(false);
  const [pvMaxLookup, setPVMaxLookup] = useState(0);

  // GET DATA FROM REDUX STORE
  const loadProfile = useSelector((state: StoreState) => state.loadProfile);
  const solarProfile = useSelector((state: StoreState) => state.solarProfile);
  const lmpDAProfile = useSelector((state: StoreState) => state.lmpDAProfile);
  const lmpRTProfile = useSelector((state: StoreState) => state.lmpRTProfile);
  const mefProfile = useSelector((state: StoreState) => state.mefProfile);
  const ev1Profile = useSelector((state: StoreState) => state.ev1Profile);
  const ev2Profile = useSelector((state: StoreState) => state.ev2Profile);
  const exploreDetailsMetaInfo = useSelector((state: StoreState) => state.exploreDetailsMetaInfo);
  const loadingSelector = createLoadingSelector([
    'GET_ALL_PROPOSAL_DATA',
    'GET_PROPOSAL_DATA',
    'UPLOAD_ALL_DATA_FILES',
    'UPLOAD_LOAD_DATA_FILE',
    'UPLOAD_SOLAR_DATA_FILE',
    'UPLOAD_HELIOSCOPE_DATA_FILE',
    'GET_METER_INTERVAL'
  ]);
  const isLoading = useSelector(loadingSelector);
  const cleanData = useSelector((state: StoreState) => state.cleanData);
  const proposal = useSelector((state: StoreState) => state.proposal);

  const {
    startDate,
    endDate,
    loadFrequency,
    solarFrequency,
    lmpDAFrequency,
    lmpRTFrequency,
    mefFrequency,
    ev1Frequency,
    ev2Frequency
  } = exploreDetailsMetaInfo;

  useEffect(() => {
    dispatch(
      storeLoadProfile(
        generateProfileGrid(
          loadFrequency,
          startDate,
          endDate,
          loadProfile.map(item => item[2])
        )
      )
    );
  }, [loadFrequency, startDate, endDate, dispatch]);

  useEffect(() => {
    dispatch(
      storeSolarProfile(
        generateProfileGrid(
          solarFrequency,
          startDate,
          endDate,
          solarProfile.map(item => item[2])
        )
      )
    );
  }, [solarFrequency, startDate, endDate, dispatch]);

  useEffect(() => {
    dispatch(
      storeLMPDAProfile(
        generateProfileGrid(
          lmpDAFrequency,
          startDate,
          endDate,
          lmpDAProfile.map(item => item[2])
        )
      )
    );
  }, [lmpDAFrequency, startDate, endDate, dispatch]);

  useEffect(() => {
    dispatch(
      storeLMPRTProfile(
        generateProfileGrid(
          lmpRTFrequency,
          startDate,
          endDate,
          lmpRTProfile.map(item => item[2])
        )
      )
    );
  }, [lmpRTFrequency, startDate, endDate, dispatch]);

  useEffect(() => {
    dispatch(
      storeMEFProfile(
        generateProfileGrid(
          mefFrequency,
          startDate,
          endDate,
          mefProfile.map(item => item[2])
        )
      )
    );
  }, [mefFrequency, startDate, endDate, dispatch]);

  useEffect(() => {
    dispatch(
      storeEV1Profile(
        generateProfileGrid(
          ev1Frequency,
          startDate,
          endDate,
          ev1Profile.map(item => item[2])
        )
      )
    );
  }, [ev1Frequency, startDate, endDate, dispatch]);

  useEffect(() => {
    dispatch(
      storeEV2Profile(
        generateProfileGrid(
          ev2Frequency,
          startDate,
          endDate,
          ev2Profile.map(item => item[2])
        )
      )
    );
  }, [ev2Frequency, startDate, endDate, dispatch]);

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    if (date && moment(date).format('MM/DD/YYYY') !== startDate.format('MM/DD/YYYY')) {
      dispatch(
        updateExplorePageInformation({
          startDate: moment(date),
          endDate: moment(date).clone().add(1, 'y').subtract(1, 'd').endOf('day')
        })
      );
    }
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    if (date && moment(date).format('MM/DD/YYYY') !== endDate.format('MM/DD/YYYY')) {
      dispatch(
        updateExplorePageInformation({
          endDate: moment(date)
        })
      );
    }
  };

  const handleUpdateFrequency = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedFrequency = parseInt(event.target.value) as 1 | 4;
    if (value === DataType.load && updatedFrequency !== exploreDetailsMetaInfo.loadFrequency) {
      dispatch(storeLoadProfile(generateProfileGrid(updatedFrequency, exploreDetailsMetaInfo.startDate)));
      dispatch(
        updateExplorePageInformation({
          loadFrequency: updatedFrequency
        })
      );
    } else if (value === DataType.solar && updatedFrequency !== exploreDetailsMetaInfo.solarFrequency) {
      dispatch(storeSolarProfile(generateProfileGrid(updatedFrequency, exploreDetailsMetaInfo.startDate)));
      dispatch(
        updateExplorePageInformation({
          solarFrequency: updatedFrequency
        })
      );
    } else if (value === DataType.lmpDa && updatedFrequency !== exploreDetailsMetaInfo.lmpDAFrequency) {
      dispatch(storeLMPDAProfile(generateProfileGrid(updatedFrequency, exploreDetailsMetaInfo.startDate)));
      dispatch(
        updateExplorePageInformation({
          lmpDAFrequency: updatedFrequency
        })
      );
    } else if (value === DataType.lmpRt && updatedFrequency !== exploreDetailsMetaInfo.lmpRTFrequency) {
      dispatch(storeLMPRTProfile(generateProfileGrid(updatedFrequency, exploreDetailsMetaInfo.startDate)));
      dispatch(
        updateExplorePageInformation({
          lmpRTFrequency: updatedFrequency
        })
      );
    } else if (value === DataType.mef && updatedFrequency !== exploreDetailsMetaInfo.mefFrequency) {
      dispatch(storeMEFProfile(generateProfileGrid(updatedFrequency, exploreDetailsMetaInfo.startDate)));
      dispatch(
        updateExplorePageInformation({
          mefFrequency: updatedFrequency
        })
      );
    }
  };

  const getDataTabs = (required?: DataRequired) => {
    const tabs = [
      {
        type: DataType.load,
        display: 'Load'
      }
    ];
    if (required?.solar) {
      tabs.push({ type: DataType.solar, display: 'Solar' });
    }
    if (required?.lmp) {
      tabs.push({ type: DataType.lmpDa, display: 'LMP Day Ahead' });
      tabs.push({ type: DataType.lmpRt, display: 'LMP Realtime' });
    }
    if (required?.mef) {
      tabs.push({ type: DataType.mef, display: 'MEF' });
    }

    if (required?.ev1) {
      tabs.push({ type: DataType.ev1, display: 'EV1' });
    }
    if (required?.ev2) {
      tabs.push({ type: DataType.ev2, display: 'EV2' });
    }

    return tabs;
  };

  const handleUtilityAPIClick = () => {
    if (isUtilityAPI === false) {
      dispatch(getMeters(utilityAPIAccount));
    }
    setIsUtilityAPI(!isUtilityAPI);
  };

  const handleFacilityPresetsClick = () => {
    setIsFacilityPreset(!isFacilityPreset);
  };

  const handleMeterSelection = (meterId: string) => () => {
    dispatch(getMeterInterval(meterId, utilityAPIAccount));
    setIsUtilityAPI(false);
  };

  const handleUtilityAPIAccountUpdate = (accountName: string) => {
    setUtilityAPIAccount(accountName);
    dispatch(getMeters(accountName));
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: unknown) => {
    setValue(newValue as DataType);
  };

  const handleDownload = () => {
    // TODO : finish this for other load types and solar
    const headers = ['Date', 'Time', 'Value'];
    let downloadData;

    switch (value) {
      case DataType.load:
        downloadData = loadProfile;
        break;
      case DataType.solar:
        downloadData = solarProfile;
        break;
      case DataType.lmpDa:
        downloadData = lmpDAProfile;
        break;
      case DataType.lmpRt:
        downloadData = lmpRTProfile;
        break;
      case DataType.ev1:
        downloadData = ev1Profile;
        break;
      case DataType.ev2:
        downloadData = ev2Profile;
        break;
      case DataType.mef:
        downloadData = mefProfile;
        break;
      default:
        assertUnreachable(value);
    }

    exportCSVFile(headers, downloadData, `Historical data (${value}) ${proposal ? 'for ' + proposal?.name : ''}`);
  };

  const handleFileUpload = (file: File, columnDates: string, columnValues: string, dataType: DataType) => {
    setImportDialogOpen(false);
    if (dataType == DataType.solar) {
      dispatch(uploadHelioscopeDataFile(file, startDate.format('MM/DD/YYYY')));
    } else {
      dispatch(uploadDataFile(file, columnDates, columnValues, dataType));
    }
  };

  const handleFacilityPresetSuccess = (facilityType: string, facilitySize: number) => {
    setIsFacilityPreset(false);
    dispatch(populateFacilityLoad(facilityType, facilitySize));
  };

  const openImportDialog = (dataType: DataType) => {
    setImportDialogOpen(true);
    setDataType(dataType);
  };

  const closeImportDialog = () => {
    setImportDialogOpen(false);
  };

  const openLMPZemaImportDialog = (dataType: DataType) => {
    setDataType(dataType);
    setDialogLMPZemaOpen(true);
  };

  const closeLMPZemaImportDialog = () => {
    setDialogLMPZemaOpen(false);
  };

  const handleLMPFileUpload = () => {
    setDialogLMPZemaOpen(false);
    //TODO: Handle Upload
  };

  const lookupPVMax = (): void => {
    const kWs = solarProfile.map(el => el[2]);
    setPVMaxLookup(Math.max(...kWs));
    setConfirmPVMax(true);
  };

  const generateImportButton = (dataType: DataType) => {
    return (
      <AccessControl requiredPermissions={['admin', 'editor']}>
        <div style={{ float: 'right' }}>
          <Tooltip title="Export to CSV">
            <IconButton aria-label="Download" onClick={handleDownload} style={{ marginLeft: 8 }}>
              <InsertDriveFile />
            </IconButton>
          </Tooltip>
          {value === DataType.load && cleanData && cleanData.logs.length > 0 && (
            <Button variant="contained" size="small" className={classes.button} onClick={() => setOpenLog(true)}>
              View Log
            </Button>
          )}
          {value === DataType.solar && exploreDetailsMetaInfo.solarLog.length > 0 && (
            <Button variant="contained" size="small" className={classes.button} onClick={() => setOpenSolarLog(true)}>
              View Log
            </Button>
          )}
          <Button
            variant="contained"
            size="small"
            className={classes.button}
            onClick={() => openImportDialog(dataType)}
          >
            Import Data
          </Button>
        </div>
      </AccessControl>
    );
  };

  const handleFixedCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFixedEnergyCost(event.target.value);
  };

  const handleApplyFixedCost = () => {
    dispatch(
      storeLMPDAProfile(
        generateProfileGrid(
          lmpDAFrequency,
          startDate,
          endDate,
          lmpDAProfile.map(() => +fixedEnergyCost)
        )
      )
    );
    dispatch(
      storeLMPRTProfile(
        generateProfileGrid(
          lmpRTFrequency,
          startDate,
          endDate,
          lmpRTProfile.map(() => +fixedEnergyCost)
        )
      )
    );
    setConfirmApply(false);
  };

  const handleUpdatePVMax = () => {
    if (Array.isArray(proposal?.proposal_scenarios)) {
      proposal.proposal_scenarios.forEach(scenario => {
        if (
          scenario?.assumptions?.isPV &&
          scenario?.assumptions?.pInstPVAutoUpdate &&
          String(scenario?.assumptions?.pInstPV) != String(pvMaxLookup)
        ) {
          const updatedScenario = fromJS(scenario)
            .setIn(['assumptions', 'pInstPV'], pvMaxLookup)
            .set('results', null)
            .set('status', '');

          dispatch(updateProposalScenario(updatedScenario.toJS(), proposal.public_id));
        }
      });
    }
    setConfirmPVMax(false);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleTabChange}>
        {getDataTabs(required).map((tab, i) => {
          return <Tab label={tab.display} value={tab.type} key={i} />;
        })}
      </Tabs>

      {isLoading && (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {!isLoading && (
        <div>
          {value === DataType.load && (
            <React.Fragment>
              <div style={{ paddingTop: 10, paddingRight: 24, paddingLeft: 24 }}>
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="Start Date"
                  value={exploreDetailsMetaInfo.startDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleStartDateChange}
                />
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="End Date"
                  value={exploreDetailsMetaInfo.endDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleEndDateChange}
                />
                {canEdit && (
                  <React.Fragment>
                    <Button variant="contained" onClick={handleUtilityAPIClick} size="small" className={classes.button}>
                      Utility API
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleFacilityPresetsClick}
                      size="small"
                      className={classes.button}
                    >
                      Use Facility Profiles
                    </Button>
                  </React.Fragment>
                )}
                {generateImportButton(DataType.load)}
              </div>

              {isUtilityAPI && (
                <UtilityAPIMeters
                  utilityAPIAccount={utilityAPIAccount}
                  utilityAPIAccounts={utilityAPIAccounts}
                  handleMeterSelection={handleMeterSelection}
                  handleUtilityAPIAccountUpdate={handleUtilityAPIAccountUpdate}
                />
              )}
              {!isUtilityAPI && <ProfileTable onUpdateData={updateLoadProfile} data={loadProfile} />}
            </React.Fragment>
          )}
          {value === DataType.solar && (
            <React.Fragment>
              <div
                style={{
                  paddingTop: 10,
                  paddingRight: 24,
                  paddingLeft: 24
                }}
              >
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="Start Date"
                  value={exploreDetailsMetaInfo.startDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleStartDateChange}
                />
                <TextField
                  id="solarFrequency"
                  name="solarFrequency"
                  label="Frequency"
                  select
                  value={exploreDetailsMetaInfo.solarFrequency}
                  onChange={handleUpdateFrequency}
                  margin="dense"
                  style={{ marginTop: 3 }}
                >
                  {profileFrequencyChoices.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {location.pathname.includes('proposals') && (
                  <div style={{ display: 'inline-block', marginLeft: 48, marginRight: 48, marginTop: 6 }}>
                    <Button variant="contained" size="small" className={classes.button} onClick={lookupPVMax}>
                      Update Max PV Production
                    </Button>
                    {pvMaxLookup !== 0 && <TextField style={{ width: 80, marginTop: 8 }} value={pvMaxLookup} />}
                  </div>
                )}
                {generateImportButton(DataType.solar)}
              </div>
              <ProfileTable onUpdateData={updateSolarProfile} data={solarProfile} />
            </React.Fragment>
          )}
          {value === DataType.lmpDa && (
            <React.Fragment>
              <div style={{ paddingTop: 10, paddingRight: 24, paddingLeft: 24 }}>
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="Start Date"
                  value={exploreDetailsMetaInfo.startDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleStartDateChange}
                />
                <TextField
                  id="lmpDAFrequency"
                  name="lmpDAFrequency"
                  label="Frequency"
                  select
                  value={exploreDetailsMetaInfo.lmpDAFrequency}
                  onChange={handleUpdateFrequency}
                  margin="dense"
                  style={{ marginTop: 3, marginRight: 8 }}
                >
                  {profileFrequencyChoices.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <span style={{ marginLeft: 24 }}>
                  <i>* Values in $/MWh</i>
                </span>

                <AccessControl requiredPermissions={['admin', 'editor']}>
                  <div style={{ float: 'right' }}>
                    <TextField
                      value={fixedEnergyCost}
                      onChange={handleFixedCostChange}
                      id="fixed-energy-cost"
                      label="Fixed energy cost ($/MWh)"
                      style={{ marginRight: 8 }}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Button
                      size="small"
                      onClick={() => setConfirmApply(true)}
                      style={{ marginRight: 24 }}
                      variant="contained"
                    >
                      Apply
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      onClick={() => openLMPZemaImportDialog(DataType.lmpDa)}
                    >
                      Import LMP Data
                    </Button>
                  </div>
                </AccessControl>
              </div>
              <ProfileTable onUpdateData={updateLMPDAProfile} data={lmpDAProfile} />
            </React.Fragment>
          )}
          {value === DataType.lmpRt && (
            <React.Fragment>
              <div style={{ paddingTop: 10, paddingRight: 24, paddingLeft: 24 }}>
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="Start Date"
                  value={exploreDetailsMetaInfo.startDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleStartDateChange}
                />
                <TextField
                  id="lmpRTFrequency"
                  name="lmpRTFrequency"
                  label="Frequency"
                  select
                  value={exploreDetailsMetaInfo.lmpRTFrequency}
                  onChange={handleUpdateFrequency}
                  margin="dense"
                  style={{ marginTop: 3, marginRight: 8 }}
                >
                  {profileFrequencyChoices.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <span style={{ marginLeft: 24 }}>
                  <i>* Values in $/MWh</i>
                </span>
                <AccessControl requiredPermissions={['admin', 'editor']}>
                  <div style={{ float: 'right' }}>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                      onClick={() => openLMPZemaImportDialog(DataType.lmpRt)}
                    >
                      Import LMP Data
                    </Button>
                  </div>
                </AccessControl>
              </div>

              <ProfileTable onUpdateData={updateLMPRTProfile} data={lmpRTProfile} />
            </React.Fragment>
          )}
          {value === DataType.mef && (
            <React.Fragment>
              <div style={{ paddingTop: 10, paddingRight: 24, paddingLeft: 24 }}>
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="Start Date"
                  value={exploreDetailsMetaInfo.startDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleStartDateChange}
                />
                <TextField
                  id="mefFrequency"
                  name="mefFrequency"
                  label="Frequency"
                  select
                  value={exploreDetailsMetaInfo.mefFrequency}
                  onChange={handleUpdateFrequency}
                  margin="dense"
                >
                  {profileFrequencyChoices.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <ProfileTable onUpdateData={updateMEFProfile} data={mefProfile} />
            </React.Fragment>
          )}
          {value === DataType.ev1 && (
            <React.Fragment>
              <div style={{ paddingTop: 10, paddingRight: 24, paddingLeft: 24 }}>
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="Start Date"
                  value={exploreDetailsMetaInfo.startDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleStartDateChange}
                />
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="End Date"
                  value={exploreDetailsMetaInfo.endDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleEndDateChange}
                />
              </div>
              <ProfileTable onUpdateData={updateEV1Profile} data={ev1Profile} />
            </React.Fragment>
          )}
          {value === DataType.ev2 && (
            <React.Fragment>
              <div style={{ paddingTop: 10, paddingRight: 24, paddingLeft: 24 }}>
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="Start Date"
                  value={exploreDetailsMetaInfo.startDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleStartDateChange}
                />
                <DatePicker
                  onFocus={() => {}}
                  onBlur={() => {}}
                  autoOk
                  label="End Date"
                  value={exploreDetailsMetaInfo.endDate}
                  format="L"
                  style={{ color: '#fff', marginRight: 12 }}
                  onChange={handleEndDateChange}
                />
              </div>
              <ProfileTable onUpdateData={updateEV2Profile} data={ev2Profile} />
            </React.Fragment>
          )}
        </div>
      )}
      <FacilityPresetDialog
        open={isFacilityPreset}
        handleClose={handleFacilityPresetsClick}
        handleSuccessClose={handleFacilityPresetSuccess}
      />
      <DataFileImportDialog
        dataType={dataType}
        open={importDialogOpen}
        handleClose={closeImportDialog}
        handleSuccessClose={handleFileUpload}
      />
      <LMPZemaImportDialog
        open={dialogLMPZemaOpen}
        handleClose={closeLMPZemaImportDialog}
        handleSuccessClose={handleLMPFileUpload}
        lmpType={dataType}
        startDate={exploreDetailsMetaInfo.startDate.format('YYYY-MM-DD')}
        endDate={exploreDetailsMetaInfo.endDate.format('YYYY-MM-DD')}
        frequency={
          dataType === DataType.lmpDa ? exploreDetailsMetaInfo.lmpDAFrequency : exploreDetailsMetaInfo.lmpRTFrequency
        }
      />
      <Dialog onClose={() => setOpenLog(false)} aria-labelledby="log-dialog" open={openLog} style={{ padding: 24 }}>
        <DialogTitle>
          <span style={{ display: 'flex' }}>
            <span style={{ flex: 1 }}>Log</span>
            <IconButton onClick={() => setOpenLog(false)}>
              <CloseIcon />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent>
          {cleanData.logs.split('\n').map((item, i) => {
            return <p key={i}>{item}</p>;
          })}
        </DialogContent>
      </Dialog>
      {/* Apply Fixed LMP  */}
      <Dialog
        onClose={() => setConfirmApply(false)}
        aria-labelledby="confirm-apply-dialog"
        open={confirmApply}
        style={{ padding: 24 }}
      >
        <DialogTitle>
          <span style={{ display: 'flex' }}>
            <span style={{ flex: 1 }}>Confirm applying fixed energy cost</span>
            <IconButton onClick={() => setConfirmApply(false)}>
              <CloseIcon />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent>
          This will populate each time interval of LMP DA and LMP Realtime with ${fixedEnergyCost}/MWh. Don't forget to
          save afterwards.
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setConfirmApply(false)} aria-label="cancel">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleApplyFixedCost} color="primary" autoFocus aria-label="confirm">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Apply Fixed LMP */}
      {/* Confirm PMax */}
      <Dialog
        onClose={() => setConfirmPVMax(false)}
        aria-labelledby="confirm-pvmax-dialog"
        open={confirmPVMax}
        style={{ padding: 24 }}
      >
        <DialogTitle>
          <span style={{ display: 'flex' }}>
            <span style={{ flex: 1 }}>Confirm applying PV Max to scenarios</span>
            <IconButton onClick={() => setConfirmPVMax(false)}>
              <CloseIcon />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent>
          This will update - Max Hourly Solar Production - in each scenario,that has auto-update toggled on, with{' '}
          {pvMaxLookup} kW. These scenarios results will be discarded.
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setConfirmPVMax(false)} aria-label="cancel">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleUpdatePVMax} color="primary" autoFocus aria-label="confirm">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Confirm PMax */}
      <Dialog
        onClose={() => setOpenSolarLog(false)}
        aria-labelledby="solar-log-dialog"
        open={openSolarLog}
        style={{ padding: 24 }}
      >
        <DialogTitle>
          <span style={{ display: 'flex' }}>
            <span style={{ flex: 1 }}>Log</span>
            <IconButton onClick={() => setOpenSolarLog(false)}>
              <CloseIcon />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent>
          {exploreDetailsMetaInfo.solarLog?.split('\n').map((item, i) => {
            return <p key={i}>{item}</p>;
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
};
HistoricalData.defaultProps = {
  required: { load: true, solar: true, lmp: false, mef: false, ev1: false, ev2: false },
  canEdit: true
};

export default HistoricalData;
