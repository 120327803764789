import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    item: {
      padding: 10
    }
  })
);

// var ENTER_KEY = 13;

interface BIPEventFormProps {
  dates: Array<string[]>;
  newDate: string;
  startTime: string;
  endTime: string;
  handleDelete: (index: number) => void;
  handleAdd: () => void;
  handleChange: (event: any) => void;
}

const BIPEventForm: React.FC<BIPEventFormProps> = ({
  dates,
  newDate,
  startTime,
  endTime,
  handleDelete,
  handleAdd,
  handleChange
}) => {
  const classes = useStyles();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    if (newDate.trim()) {
      handleAdd();
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={4} md={4} className={classes.item}>
          <TextField
            id="newDate"
            name="newDate"
            required
            onKeyDown={handleKeyDown}
            autoFocus={true}
            label="Add Event Date"
            helperText="(mm/dd/yyyy)"
            value={newDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} className={classes.item}>
          <TextField
            id="startTime"
            name="startTime"
            required
            onKeyDown={handleKeyDown}
            autoFocus={true}
            label="Start Time"
            helperText="hh:mm"
            value={startTime}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} className={classes.item}>
          <TextField
            id="endTime"
            name="endTime"
            required
            onKeyDown={handleKeyDown}
            autoFocus={true}
            label="End Time"
            helperText="hh:mm"
            value={endTime}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={1} md={1} className={classes.item}>
          <IconButton aria-label="add a bip event" onClick={handleAdd}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      <List dense={true}>
        {dates.map((date, index) => {
          return (
            <ListItem key={index}>
              <ListItemText primary={date[0] + ' ' + date[1] + ' - ' + date[2]} />
              <ListItemSecondaryAction>
                <IconButton aria-label="Delete" onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default BIPEventForm;
