import { ESAPTariff } from '../../types';
// import { ValidationResult } from '../../types';

function isOptionSTariff(tariff?: ESAPTariff): boolean {
  if (!tariff || !tariff.name.includes('Option S')) return false;
  return true;
}

export function isProposalTariffOptionS(proposalTariffs: ESAPTariff[]) {
  return function (tariffId?: string): boolean {
    const tariff = proposalTariffs.find(t => t.public_id === tariffId);
    return isOptionSTariff(tariff);
  };
}
