import React from 'react';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const utilityLookup = {
  'Con Edison - New York City': {
    community_credit_value: 0.12,
    drv_value: 0.8536,
    icap: 0.3384,
    totalTD: 5.9
  },
  'Con Edison - Westchester': {
    community_credit_value: 0.12,
    drv_value: 0.8536,
    icap: 0.3301,
    totalTD: 5.9
  },
  'NYSEG - Lower Hudson': {
    community_credit_value: 0.0225,
    drv_value: 0.0887,
    icap: 0.3152,
    totalTD: 6.79
  },
  'NYSEG - Rest of State': {
    community_credit_value: 0.0225,
    drv_value: 0.0887,
    icap: 0.0765,
    totalTD: 6.79
  },
  'Orange & Rockland': {
    community_credit_value: 0,
    drv_value: 0.2218,
    icap: 0.3113,
    totalTD: 7.4
  },
  'CHG&E': {
    community_credit_value: 0,
    drv_value: 0.0498,
    icap: 0.2755,
    totalTD: 2.57
  },
  'National Grid': {
    community_credit_value: 0.0225,
    drv_value: 0.2104,
    icap: 0.0932,
    totalTD: 7.75
  },
  'RG&E': {
    community_credit_value: 0.0225,
    drv_value: 0.1093,
    icap: 0.0793,
    totalTD: 6.48
  },
  'PSEG-LI': {
    community_credit_value: 0.05,
    drv_value: 0.3375,
    icap: 0.2074,
    totalTD: 6.27
  }
};

const csrpHoursLookup = [
  { id: 1, label: '11a-3p' },
  { id: 2, label: '2p-6p' },
  { id: 3, label: '4p-8p' },
  { id: 4, label: '7p-11p' }
];

const utilities = [
  { id: 1, label: 'Con Edison - New York City' },
  { id: 2, label: 'Con Edison - Westchester' },
  { id: 5, label: 'NYSEG - Lower Hudson' },
  { id: 6, label: 'NYSEG - Rest of State' },
  { id: 7, label: 'Orange & Rockland' },
  { id: 8, label: 'CHG&E' },
  { id: 9, label: 'National Grid' },
  { id: 10, label: 'RG&E' },
  { id: 11, label: 'PSEG-LI' }
];

const zones = [
  { value: 'a', label: 'A-West' },
  { value: 'b', label: 'B-Genesee' },
  { value: 'c', label: 'C-Central' },
  { value: 'd', label: 'D-North' },
  { value: 'e', label: 'E-Mohawk' },
  { value: 'f', label: 'F-Capital' },
  { value: 'g', label: 'G-Hudson' },
  { value: 'h', label: 'H-Millwood' },
  { value: 'i', label: 'I-Dunwoodie' },
  { value: 'j', label: 'J-NYC' },
  { value: 'k', label: 'K-Long Island' }
];

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  }
}));

interface NYISOAssumptionsProps {
  zone: string;
  utility: string;
  isCDG: boolean;
  isDRV: boolean;
  isVDER: boolean;
  exportMax: number;
  vderEnvironmentalBenefits: number;
  vderICAP: number;
  vderDRV: number;
  vderCDG: number;
  vderTotalTD: number;
  csrpHours: string;
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleGeneralAssumptionChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  handleMultiUpdate: any;
}

const NYISOAssumptions: React.FC<NYISOAssumptionsProps> = props => {
  const {
    handleGeneralAssumptionChange,
    handleMultiUpdate,
    handleToggle,
    isVDER,
    isDRV,
    isCDG,
    vderEnvironmentalBenefits,
    vderICAP,
    vderTotalTD,
    csrpHours,
    vderDRV,
    vderCDG,
    utility,
    zone,
    exportMax
  } = props;
  const classes = useStyles();

  const handleZoneUpdate = (updatedZone: string) => {
    handleMultiUpdate({
      zone: updatedZone
    });
  };

  const handleUtilityUpdate = (updatedUtility: string) => {
    let utilityInformation = utilityLookup[updatedUtility];
    handleMultiUpdate({
      utility: updatedUtility,
      vderDRV: utilityInformation.drv_value,
      vderCDG: utilityInformation.community_credit_value,
      vderICAP: utilityInformation.icap,
      vderTotalTD: utilityInformation.totalTD
    });
  };

  const handleCSRPHoursUpdate = (updatedCSRP: string) => {
    handleMultiUpdate({ csrpHours: updatedCSRP });
  };

  return (
    <div>
      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={handleToggle} checked={isVDER ?? false} name="isVDER" />}
        label="Is VDER"
      />
      {isVDER && (
        <div
          style={{
            paddingLeft: 25,
            borderLeft: '10px solid #eee'
          }}
        >
          <div>
            <FormControlLabel
              style={{ marginLeft: 4, marginTop: 16 }}
              label="Select a Zone:"
              labelPlacement="start"
              control={
                <Select
                  style={{ marginLeft: 4 }}
                  name="zone"
                  value={zone}
                  onChange={e => handleZoneUpdate(e.target.value as string)}
                  inputProps={{
                    name: 'selectedZone'
                  }}
                >
                  {zones.map(zone => {
                    return (
                      <MenuItem key={zone.value} value={zone.value}>
                        {zone.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
          </div>
          <div>
            <FormControlLabel
              style={{ marginLeft: 4, marginTop: 16, marginBottom: 16 }}
              label="Select a Utility:"
              labelPlacement="start"
              control={
                <Select
                  style={{ marginLeft: 8 }}
                  name="utility"
                  value={utility}
                  onChange={e => handleUtilityUpdate(e.target.value as string)}
                  inputProps={{
                    name: 'selectedUtility'
                  }}
                >
                  {utilities.map(utility => {
                    return (
                      <MenuItem key={utility.id} value={utility.label}>
                        {utility.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
          </div>
          {utility.includes('Con Edison') && (
            <div
              style={{
                paddingLeft: 25,
                borderLeft: '10px solid #eee'
              }}
            >
              <FormControlLabel
                style={{ marginLeft: 4, marginTop: 16, marginBottom: 16 }}
                label="CSRP Hours:"
                labelPlacement="start"
                control={
                  <Select
                    style={{ marginLeft: 8 }}
                    name="csrpHours"
                    value={csrpHours}
                    onChange={e => handleCSRPHoursUpdate(e.target.value as string)}
                    inputProps={{
                      name: 'selectedCSRPHour'
                    }}
                  >
                    {csrpHoursLookup.map((csrpHour: { id: number; label: string }) => {
                      return (
                        <MenuItem key={csrpHour.id} value={csrpHour.label}>
                          {csrpHour.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
              />
            </div>
          )}
          <TextField
            className={classes.block}
            label="Environmental Benefits ($/kWh)"
            key="vderEnvironmentalBenefits"
            name="vderEnvironmentalBenefits"
            value={vderEnvironmentalBenefits ?? '0'}
            onChange={handleGeneralAssumptionChange}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            className={classes.block}
            label="Total Energy T&D Loss Factors (%)"
            key="vderTotalTD"
            name="vderTotalTD"
            value={vderTotalTD ? Number(vderTotalTD).toFixed(2) : '0'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              readOnly: true
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            className={classes.block}
            label="ICAP ($/kWh)"
            key="vderICAP"
            name="vderICAP"
            value={vderICAP ?? '0'}
            onChange={handleGeneralAssumptionChange}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            margin="normal"
          />
          <div />
          <div>
            <FormControlLabel
              className={classes.block}
              control={<Switch onChange={handleToggle} checked={isDRV ?? false} name="isDRV" />}
              label="Is DRV"
            />
            {isDRV && (
              <div
                style={{
                  paddingLeft: 25,
                  borderLeft: '10px solid #eee'
                }}
              >
                <TextField
                  label="DRV Value ($/kWh)"
                  key="vderDRV"
                  name="vderDRV"
                  value={vderDRV ?? '0'}
                  onChange={handleGeneralAssumptionChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  margin="normal"
                />
              </div>
            )}
          </div>
          <div>
            <FormControlLabel
              className={classes.block}
              control={<Switch onChange={handleToggle} checked={isCDG ?? false} name="isCDG" />}
              label="Is CDG "
            />
            {isCDG && (
              <div
                style={{
                  paddingLeft: 25,
                  borderLeft: '10px solid #eee'
                }}
              >
                <TextField
                  label="Community Credit Value ($/kWh)"
                  key="vderCDG"
                  name="vderCDG"
                  value={vderCDG ?? '0'}
                  onChange={handleGeneralAssumptionChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  margin="normal"
                />
              </div>
            )}
          </div>
          <div>
            <TextField
              label="Limit Network Export"
              key="exportMax"
              name="exportMax"
              value={exportMax ?? 0}
              onChange={props.handleGeneralAssumptionChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NYISOAssumptions;
