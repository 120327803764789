import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { HotTable } from '@handsontable/react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Save from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getGlobalSettingByKey, updateGlobalSetting } from '../../actions/globalsettings';
import { createLoadingSelector } from '../../selectors';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import AccessControl, { isAdmin } from '../../utility/AccessControl';

import { regexpValidator } from '../../utility/Validator';
import { StoreState } from '../../reducers';
import { useLocation } from 'react-router';
import Handsontable from 'handsontable';
import { repeat } from 'ramda';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    info: {
      margin: theme.spacing(3)
    },
    container: {
      padding: 20,
      maxWidth: 1000,
      margin: '0 auto'
    }
  })
);

const colHeadersMap = [
  [
    'Utility',
    'Dispatch Option',
    'Months',
    'Maximum Event Duration (hours)',
    'DR Events Max',
    'Weekday Credit ($/kW)',
    'Weekend Credit ($/kW)',
    'Penalty ($/kW)'
  ],
  ['Date', 'Hours'],
  [
    'Dispatch Season',
    'Start Date (month & day)',
    'End Date (month & day)',
    'Time Window Start',
    'Time Window End',
    'Season Multiplier',
    'SMART Multiplier',
    'Season Credit ($/kWh)',
    'Penalty ($/kW)'
  ]
];
// Handsontable validation section
const runValidation = (validator: (v: string) => boolean) => (value: string, callback: any) =>
  callback(validator(value));
const dateValidatorRegExp = /(\d{4})-(\d{2})-(\d{2})$/;

const buildColumns = (tabIndex: number): Handsontable.ColumnSettings[] => {
  switch (tabIndex) {
    case 0:
      return [
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        }
      ];
    case 1:
      return [
        {
          type: 'date',
          dateFormat: 'YYYY-MM-DD',
          correctFormat: true,
          allowEmpty: false,
          readOnly: !isAdmin(),
          colWidths: 50,
          validator: runValidation(regexpValidator(dateValidatorRegExp)),
          allowInvalid: false
        },
        {
          type: 'text',
          colWidths: 50,
          allowEmpty: false,
          readOnly: !isAdmin(),
          allowInvalid: false
        }
      ];
    case 2:
      return [
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'numeric',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'numeric',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'numeric',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'numeric',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        }
      ];
    default:
      return [];
  }
};

const keys = ['ISONE CT Info', 'ISONE CT DR Events'];

type TableData = (string | number)[][];

const CTSettings: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const setting = useSelector((state: StoreState) => state.setting);
  const loadingSelector = createLoadingSelector(['GET_GLOBAL_SETTING_BY_KEY']);
  const isLoading = useSelector(loadingSelector);

  const [data, setData] = useState<TableData>([[]]);
  const [settings, setSettings] = useState<Handsontable.GridSettings | undefined>(undefined);
  const [message, setMessage] = useState('');
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [hasChanged, setHasChanged] = useState(false);
  const tableRef = useRef(null);

  // get the proper setting
  useEffect(() => {
    dispatch(getGlobalSettingByKey(keys[tabIndex]));
  }, [getGlobalSettingByKey, tabIndex, dispatch]);

  // build data
  useEffect(() => {
    const generateGrid = () => setting?.value ?? [];

    const generateVerticalSettings = (): Handsontable.GridSettings => {
      return {
        colHeaders: colHeadersMap[tabIndex],
        columnSorting: {
          sortEmptyCells: false,
          initialConfig: {
            column: 0,
            sortOrder: 'asc'
          }
        },
        contextMenu: {
          items: {
            row_below: {},
            remove_row: {}
          }
        },
        stretchH: 'all',
        className: 'htCenter htMiddle',
        rowHeights: 15,
        columns: buildColumns(tabIndex),
        afterCreateRow: function (index, amount, source) {
          const length = buildColumns(tabIndex).length;
          const newRow = repeat('0', length);
          setData(data => [...data, newRow]);
        }
      };
    };

    const updatedSettings = generateVerticalSettings();
    const updatedData = generateGrid();
    setSettings(updatedSettings);
    setData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting, tabIndex]);

  // breadcrumbs
  useEffect(() => {
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Settings', '/settings'));
    dispatch(pushBreadcrumb('Connecticut', location.pathname));
  }, [location.pathname, pushBreadcrumb]);

  // useEffect(() => {
  //   setHasChanged(false);
  // }, [data]);

  const handleModifyData = () => {
    setHasChanged(true);
  };

  // const generateSettings = () => {
  //   return generateVerticalSettings();
  // };

  const sortDataByDate = (data: TableData): TableData => {
    return data.sort((a, b) => moment(a[0]).unix() - moment(b[0]).unix());
  };

  const handleSave = () => {
    setData(sortDataByDate(data));
    dispatch(
      updateGlobalSetting(
        {
          ...setting,
          value: data
        },
        {
          onSuccess: () => {
            setMessage('Data Updated Successfully!');
            setHasChanged(false);
          },
          onError: (error: string) => {
            setMessage('Error! ' + error);
          }
        }
      )
    );
  };

  const handleTabChange = (e: any, value: number) => {
    setTabIndex(value);
  };
  return (
    <Paper className={classes.root}>
      <AccessControl requiredPermissions={['editor', 'admin']}>
        <div className="button-container">
          <Fab size="small" disabled={!hasChanged} color="primary" aria-label="Save" onClick={handleSave}>
            <Save />
          </Fab>
        </div>
      </AccessControl>
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Info" />
          <Tab label="DR Events" />
        </Tabs>
      </AppBar>
      <div className={classes.container}>
        {isLoading && (
          <div style={{ textAlign: 'center', padding: 25 }}>
            <CircularProgress color="secondary" size={50} />
          </div>
        )}
        {!isLoading && (
          <div id="hot-app">
            <HotTable
              ref={tableRef}
              data={data}
              settings={settings}
              height="800"
              stretchH="all"
              afterRemoveRow={handleModifyData}
              afterCreateRow={handleModifyData}
              afterSetDataAtCell={handleModifyData}
            />
          </div>
        )}

        <Snackbar
          open={message.length > 0}
          autoHideDuration={5000}
          onClose={() => {
            setMessage('');
          }}
          message={<span id="message-id">{message}</span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={() => {
                setMessage('');
              }}
            >
              OK
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => {
                setMessage('');
              }}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    </Paper>
  );
};

export default CTSettings;
