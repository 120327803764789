import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CustomerDetailContainer from '../../components/Customers/Detail/CustomerDetailContainer';

import CustomerProposalList from '../../components/Proposals/List/CustomerProposalList';
import { getCustomer, updateCustomer, handleInputFormChangeCustomer } from '../../actions/customers';
import { popBreadcrumb, pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import { getAllProposalsByCustomer, deleteProposal } from '../../actions/proposals';

const styles = theme => ({
  root: {
    width: '100%'
  }
});

class CustomerDetail extends React.Component {
  state = {
    value: 0
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    if (params.id) {
      this.props.getCustomer(params.id);
      this.props.getAllProposalsByCustomer(params.id);
    }

    const { name } = this.props.customer;

    // check if previous breadcrumbs should be popped or not
    if (
      this.props.breadcrumbsTrail[this.props.breadcrumbsTrail.length - 1] &&
      this.props.breadcrumbsTrail[this.props.breadcrumbsTrail.length - 1].link &&
      this.props.breadcrumbsTrail[this.props.breadcrumbsTrail.length - 1].link !== '/customers'
    ) {
      this.props.popBreadcrumb();
      this.props.pushBreadcrumb('Customers', '/customers');
    }
    this.props.resetBreadcrumb();
    this.props.pushBreadcrumb('Customers', '/customers');
    this.props.pushBreadcrumb(name, this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer.name !== this.props.customer.name) {
      const { name } = this.props.customer;

      // check if previous breadcrumbs should be popped or not
      if (
        this.props.breadcrumbsTrail[this.props.breadcrumbsTrail.length - 1] &&
        this.props.breadcrumbsTrail[this.props.breadcrumbsTrail.length - 1].link &&
        this.props.breadcrumbsTrail[this.props.breadcrumbsTrail.length - 1].link !== '/customers'
      ) {
        this.props.popBreadcrumb();
        this.props.pushBreadcrumb('Customers', '/customers');
      }
      this.props.resetBreadcrumb();
      this.props.pushBreadcrumb('Customers', '/customers');
      this.props.pushBreadcrumb(name, this.props.location.pathname);
    }
  }

  handleDelete = () => {
    this.props.history.push('/customers');
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="General" />
            <Tab label="Proposals" />
          </Tabs>
        </AppBar>
        <SwipeableViews index={this.state.value} onChangeIndex={this.handleChangeIndex}>
          <div style={{ padding: 8 * 3 }}>
            <CustomerDetailContainer
              customer={this.props.customer}
              handleCustomerChange={this.props.handleInputFormChangeCustomer}
              handleCustomerSave={this.props.updateCustomer}
            />
          </div>
          <div style={{ padding: 8 * 3 }}>
            <CustomerProposalList
              proposals={this.props.customer && this.props.customer.proposals ? this.props.customer.proposals : []}
              title="Proposals"
              history={this.props.history}
              deleteProposal={this.props.deleteProposal}
            />
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    customer: state.customer,
    customers: state.customers,
    breadcrumbsTrail: state.breadcrumbsTrail
  };
};

CustomerDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  getCustomer,
  updateCustomer,
  handleInputFormChangeCustomer,
  popBreadcrumb,
  pushBreadcrumb,
  resetBreadcrumb,
  deleteProposal,
  getAllProposalsByCustomer
})(withStyles(styles)(CustomerDetail));
