import { SET_CURRENT_TEMPLATE, UPDATE_CURRENT_TEMPLATE_SUCCESS } from '../../actions/templates';

const INITIAL_STATE = {
  scenario_templates: [
    {
      key: 'standardStatusQuo',
      reference_id: ''
    },
    {
      key: 'solarOnly',
      reference_id: ''
    },
    {
      key: 'solarStorageForced',
      reference_id: ''
    },
    {
      key: 'solarStorageNonForced',
      reference_id: ''
    }
  ],
  name: '',
  description: '',
  fields: [],
  constants: []
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_TEMPLATE:
      return action.payload;
    case UPDATE_CURRENT_TEMPLATE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
