import React from 'react';

// MATERIAL UI IMPORTS
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';

import CreateLSA from './CreateLSA';
import DisplayLSAResults from './DisplayLSAResults';
import { ScenarioResults } from '../../../../types';
import { assertIsDefined } from '../../../../utils/assertions';
import { useLSAAnalysis, useLSAAnalysisReset } from '../../../../queries/lsa';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    flex: {
      flex: 1
    },
    status: {
      margin: theme.spacing(3)
    },
    toolbar: {
      borderBottom: '1px solid #e5e5e5'
    }
  })
);

interface LoadSensitivityAnalysisProps {
  proposalId: string;
  scenarioId: string;
  statusQuoResult: ScenarioResults;
  scenarioResult: ScenarioResults;
  discountRate: { energy: number; demand: number };
}

const LoadSensitivityAnalysisContainer: React.FC<LoadSensitivityAnalysisProps> = ({
  proposalId,
  scenarioId,
  statusQuoResult,
  scenarioResult,
  discountRate
}) => {
  const classes = useStyles();

  const { data, error, status, refetch } = useLSAAnalysis({ proposalId, scenarioId });

  const { mutate } = useLSAAnalysisReset({ proposalId, scenarioId });

  const renderLSAView = (status: 'Processing' | 'Idle' | 'Complete' | 'Error' | 'Queued' | null): JSX.Element => {
    assertIsDefined(data, 'data should be defined here, found: ' + data);
    switch (status) {
      case 'Complete':
        return (
          <DisplayLSAResults
            loadSensitivity={data}
            statusQuoResult={statusQuoResult}
            scenarioResult={scenarioResult}
            discountRate={discountRate}
          />
        );

      case null:
      case 'Idle':
        return <CreateLSA proposalId={proposalId} scenarioId={scenarioId} />;
      case 'Queued':
        return (
          <Alert severity="info">
            <AlertTitle>Queued</AlertTitle>
            Will be processed soon — <strong>{data.detail}</strong>
          </Alert>
        );

      case 'Processing':
        return (
          <Alert severity="success">
            <AlertTitle>Processing</AlertTitle>
            Please come back later for result — <strong> {data.detail}</strong>
          </Alert>
        );
      default:
        return <h6 style={{ margin: 24 }}>Status unknown: {data.detail}</h6>;
    }
  };

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography color="inherit" variant="h6" className={classes.flex}>
          Load Sensitivity Analysis
        </Typography>
        {(status === 'success' || status === 'error') && (
          <Button color="primary" onClick={() => mutate({ proposalId, scenarioId })}>
            Reset
          </Button>
        )}
        <IconButton color="primary" onClick={() => refetch()}>
          <LoopIcon />
        </IconButton>
      </Toolbar>
      {status === 'error' && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          There was an error while processing this analysis — <strong>{`${error ? error.message! : ''}`}</strong>
        </Alert>
      )}
      {status === 'loading' && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {status === 'success' && !!data && renderLSAView(data.status!)}
    </Paper>
  );
};

export default LoadSensitivityAnalysisContainer;
