
const getSavedItem = <T extends unknown>(key: string): T => {
  try {
    const savedItem = localStorage.getItem(key);

    return savedItem ? JSON.parse(savedItem) : null;
  } catch (error) {
    return null as T;
  }
}

const setSavedItem = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
}

export {
  getSavedItem,
  setSavedItem
}
