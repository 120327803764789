import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import esapRoutes from '../../routes/esap.js';
import logo from '../../assets/img/exact-white.png';
import logoBottom from '../../assets/img/by-pf-white.png';
import logoSmall from '../../assets/img/x-white.png';
import { getAllProposals } from '../../actions/proposals';
import auth0Client from '../../utility/Auth';
import { drawerWidth } from '../../utility/Constants';
import 'handsontable/dist/handsontable.full.css'; // only needs to be imported once

//Material UI
import { Theme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import Assessment from '@material-ui/icons/Assessment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';
import Add from '@material-ui/icons/Add';
// import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

// React Imports
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import AccessControl from '../../utility/AccessControl';
import { ProposalInternal } from '../../types/index.js';
import { StoreState } from '../../reducers/index.js';
import { getSimplifiedLSEs } from '../../actions/index.js';
import UserFavorites from '../../components/User/UserFavorites';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.button-container': {
      position: 'absolute',
      top: 12,
      right: theme.spacing(2),
      zIndex: 10000
    },
    '.button-white': {
      color: '#fff',
      marginRight: 10
    }
  },
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    backgroundColor: '#EEEEEE',
    color: '#3C4858'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    width: `calc(100% - ${theme.spacing(7)}px)`,
    borderBottom: '1px solid rgba(180, 180, 180, 0.3)',
    color: '#222',
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 16,
    marginRight: 16
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7)
  },
  appBarSpacer: { ...theme.mixins.toolbar },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320
  },
  logoContainer: {
    margin: '10px 15px',
    paddingBottom: 10,
    borderBottom: '1px solid rgba(180, 180, 180, 0.3)'
  },
  logoLink: {
    color: '#ffffff',
    textDecoration: 'none',
    width: '100%'
  },
  logoImg: {
    width: 90,
    float: 'left',
    marginLeft: 15
  },
  logoBottomContainer: {
    position: 'absolute',
    bottom: 10,
    left: 10
  },
  logoBottom: {
    width: 138,
    float: 'right',
    marginLeft: 33
  },
  logoImgSmall: {
    width: 32,
    left: '5px',
    position: 'relative'
  },
  brandToolbar: {
    borderBottom: '1px solid rgba(180, 180, 180, 0.3)'
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: (theme.palette as any).esap.blueDim,
      '& div': {
        color: '#c2e0ff'
      }
    }
  },
  itemLink: {
    width: 'auto',
    transition: 'all 200ms ease-out',
    margin: '0',
    borderRadius: '0',
    position: 'relative',
    display: 'block',
    color: '#c2e0ff',
    backgroundColor: 'transparent',
    '& div': {
      color: '#c2e0ff',
      fontWeight: '500',
      letterSpacing: '.5px'
    },
    '&:hover': {
      backgroundColor: (theme.palette as any).esap.blueDim,
      '& div': {
        color: '#c2e0ff'
      }
    },
    '.active &': {
      backgroundColor: (theme.palette as any).esap.orange,
      '& div': {
        color: '#fff'
      }
    }
  },
  itemLinkSmall: {
    padding: '5px 16px'
  },
  itemIcon: {
    marginRight: -16,
    marginTop: 4,
    float: 'left',
    color: 'rgba(255, 255, 255, 0.8)'
  },
  itemText: {
    color: '#FFFFFF'
  },
  star: {}
}));

const switchRoutes = (
  <Switch>
    {esapRoutes.map((prop, key) => {
      if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

interface DashboardProps {
  proposals: ProposalInternal[];
  proposalsMeta: any;
  location: any;
}

const Dashboard: React.FC<DashboardProps> = ({ proposals, proposalsMeta, location }) => {
  const classes = useStyles();
  const simpleUtilityList = useSelector((state: StoreState) => state.simpleUtilityList);

  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const [isFavoriteOpen, setFavoriteOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!proposals || proposals.length === 0) {
      dispatch(getAllProposals({ includeScenarioSummary: true, page: 1, pageSize: proposalsMeta.pageSize }));
    }
  }, []);

  useEffect(() => {
    if (!simpleUtilityList || simpleUtilityList.length == 0) {
      dispatch(getSimplifiedLSEs());
    }
  }, []);

  const handleToggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleToggleFavorites = () => {
    setFavoriteOpen(!isFavoriteOpen);
  };

  const showCreateProposal = /proposals$/.test(location.pathname);
  const showCreateCustomer = location.pathname.includes('customers') ? true : false;
  const showCreatePortfolio = /portfolios$/.test(location.pathname);
  const showCreateTariff = /tariffs$/.test(location.pathname);

  const links = (
    <List className={classes.brandToolbar}>
      {esapRoutes.map((prop, key) => {
        if (prop.redirect || !prop.isSidebar) return null;
        if (prop.sidebarName == 'Users') {
          return (
            <AccessControl requiredPermissions={['admin']} key={key}>
              <NavLink to={prop.path} className={classes.item} activeClassName="active" key={key}>
                <ListItem button className={isDrawerOpen ? classes.itemLink : classes.itemLinkSmall}>
                  <ListItemIcon className={classes.itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText primary={prop.sidebarName} className={classes.itemText} disableTypography={true} />
                </ListItem>
              </NavLink>
            </AccessControl>
          );
        } else {
          return (
            <NavLink to={prop.path} className={classes.item} activeClassName="active" key={key}>
              <ListItem button className={isDrawerOpen ? classes.itemLink : classes.itemLinkSmall}>
                <ListItemIcon className={classes.itemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText primary={prop.sidebarName} className={classes.itemText} disableTypography={true} />
              </ListItem>
            </NavLink>
          );
        }
      })}
      {auth0Client.isAuthenticated() ? (
        <ListItem
          button
          className={isDrawerOpen ? classes.itemLink : classes.itemLinkSmall}
          onClick={auth0Client.signOut}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" className={classes.itemText} disableTypography={true} />
        </ListItem>
      ) : (
        <button className="btn btn-dark" onClick={auth0Client.signIn}>
          Sign In
        </button>
      )}
    </List>
  );

  const secondaryLinks = (
    <List>
      <a onClick={handleToggleFavorites} className={classes.item}>
        <ListItem button className={isDrawerOpen ? classes.itemLink : classes.itemLinkSmall}>
          <ListItemIcon className={classes.itemIcon}>
            <StarBorder />
          </ListItemIcon>
          <ListItemText primary="Favorites and Recent" className={classes.itemText} disableTypography={true} />
        </ListItem>
      </a>
      {/* {isDrawerOpen && (
          <ListSubheader style={{ color: '#fff', paddingTop: 24 }} onClick={handleToggleFavorites}>
            <StarBorder /> Favorites and Recent
          </ListSubheader>
        )}
        {!isDrawerOpen && (
          <ListItemIcon onClick={handleToggleFavorites} className={classes.itemIcon} aria-label="Favorites">
            <StarBorder />
          </ListItemIcon>
        )} */}
    </List>
  );

  if (!auth0Client.isAuthenticated()) {
    auth0Client.signIn();
    return <div />;
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="absolute" className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}>
          <Toolbar disableGutters={!isDrawerOpen} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleToggleDrawer}
              className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h2" color="inherit" noWrap className={classes.title}>
              <Breadcrumbs />
            </Typography>
            <AccessControl requiredPermissions={['editor', 'admin']}>
              <>
                {showCreateProposal && (
                  <div>
                    <Link to={'/create-proposal'}>
                      <IconButton>
                        <Add />
                      </IconButton>
                    </Link>
                  </div>
                )}
                {showCreateCustomer && (
                  <div>
                    <Link to={'/create-customer'}>
                      <IconButton>
                        <Add />
                      </IconButton>
                    </Link>
                  </div>
                )}
                {showCreateTariff && (
                  <div>
                    <Link to={'/create-tariff'}>
                      <IconButton>
                        <Add />
                      </IconButton>
                    </Link>
                  </div>
                )}
                {showCreatePortfolio && (
                  <div>
                    <Link to={'/create-portfolio'}>
                      <IconButton>
                        <Add />
                      </IconButton>
                    </Link>
                  </div>
                )}
              </>
            </AccessControl>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose)
          }}
          open={isDrawerOpen}
        >
          {isDrawerOpen ? (
            <>
              <div className={classes.toolbarIcon}>
                <a href="/" className={classes.logoLink}>
                  <img src={logo} alt="logo" className={classes.logoImg} />
                </a>
                <IconButton onClick={handleToggleDrawer} style={{ color: '#fff' }}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <div className={classes.logoBottomContainer}>
                <img src={logoBottom} alt="logo-bottom" className={classes.logoBottom} />
              </div>
            </>
          ) : (
            <div className={classes.toolbarIcon}>
              <a href="/" className={classes.logoLink}>
                <img src={logoSmall} alt="logo" className={classes.logoImgSmall} />
              </a>
            </div>
          )}
          <div style={{ marginTop: 18 }}>
            {links}
            {secondaryLinks}
          </div>
        </Drawer>
        <Drawer anchor="left" open={isFavoriteOpen} onClose={handleToggleFavorites}>
          <UserFavorites toggleFavoritesDrawer={handleToggleFavorites} />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {switchRoutes}
        </main>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    proposals: state.proposals,
    proposalsMeta: state.proposalsMeta
  };
};

export default connect(mapStateToProps, { getAllProposals })(Dashboard);
