import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListSearchInput from '../../Table/ListSearchInput';
import BaseTable from '../../Table/BaseTable';
import { stableSort, getSorting } from '../../../utility/Table';
import { usersColumnSchema } from '../../../utility/User';

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
    overflowX: 'auto'
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  spacer: {
    flex: '1 1 auto'
  }
});

class UserList extends React.Component {
  state = {
    search: '',
    orderBy: 'auth0_created_on',
    order: 'desc',
    page: 0,
    rowsPerPage: 25,
    isConfirm: false
  };

  // filterBySearch = item => {
  //   if (!this.state.search) {
  //     return true;
  //   }
  //   const search = this.state.search;
  //   if (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
  //     return true;
  //   }
  //   return false;
  // };

  // handleSearchChange = event => {
  //   this.setState({
  //     search: event.target.value
  //   });
  // };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleRowClick = proposal => () => {};

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getActiveDataSlice = data => {
    return stableSort(data, getSorting(this.state.order, this.state.orderBy)).slice(
      this.state.page * this.state.rowsPerPage,
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
    );
  };

  render() {
    const { classes, title, users } = this.props;
    // const filteredData = users.filter(this.filterBySearch);

    return (
      <Paper>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            {title ? title : 'Users'}
          </Typography>
          <div className={classes.spacer} />
        </Toolbar>
        {this.props.loading && (
          <div style={{ textAlign: 'center', padding: 25 }}>
            <CircularProgress color="secondary" size={50} />
          </div>
        )}
        {!this.props.loading && (
          <BaseTable
            data={this.getActiveDataSlice(users)}
            onRowClick={this.handleRowClick}
            onRequestSort={this.handleRequestSort}
            columnSchema={usersColumnSchema}
            order={this.state.order}
            orderBy={this.state.orderBy}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            dataCount={users.length}
          />
        )}
      </Paper>
    );
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool
};

export default withStyles(styles)(UserList);
