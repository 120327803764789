import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import axios from 'axios';
import moment from 'moment';
import { generateProfileGrid } from '../../utility/Explore';
import { STORE_LMP_DA_PROFILE, STORE_LMP_RT_PROFILE } from '../../actions/profiledata';
import { UPDATE_EXPLORE_PAGE_INFORMATION } from '../../actions/explore';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    padding: theme.spacing(4)
  },
  dialogContent: {
    marginTop: theme.spacing(1),
    width: 350
  },
  selectContainer: {
    marginTop: theme.spacing(1),
    marginBottom: 50
  },
  dialogTitle: {
    background: theme.palette.esap.blue,
    color: 'white'
  },
  checkboxLabel: {
    fontSize: 12
  }
}));

export interface LMPZemaImportDialogProps {
  open: boolean;
  handleClose: any;
  handleSuccessClose: any;
  lmpType: string;
  startDate: string;
  endDate: string;
  frequency: 1 | 4;
}

const LMPZemaImportDialog: React.FC<LMPZemaImportDialogProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleClose, handleSuccessClose, open, lmpType, startDate, endDate, frequency } = props;

  const lmpTypeStr = lmpType === 'LMPRT' ? 'lmprt' : 'lmp';
  const frequencyStr = frequency === 4 ? '15min' : '60min';

  type ISONodeOption = { key: string; value: string; minDate: string; maxDate: string; market: string };
  const [ISONodeOptions, setISONodeOptions] = useState<ISONodeOption[]>([]);
  const [selectedISONodeOption, setSelectedISONodeOption] = useState<ISONodeOption>({
    key: '',
    value: '',
    minDate: '',
    maxDate: '',
    market: ''
  });

  const [importBoth, setImportBoth] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccessAlert, setIsSuccessAlert] = useState(false);

  const getOptions = (search: string) => {
    return getISONodes({ search, changedSince: startDate })
      .then((res: any) => {
        const options = res.data.map((node: ISONodeOption) => ({
          key: node.key,
          value: node.value,
          minDate: node.minDate,
          maxDate: node.maxDate,
          market: node.market
        }));
        setISONodeOptions(options);
        return { options };
      })
      .catch((err: any) => {
        return { options: [] };
      });
  };

  function getISONodes(options: { search: string | undefined; changedSince: string | undefined }) {
    let url = `${(window as any).REACT_APP_API_BASE_URL_V2}/services/zema/nodes?data_type=${lmpTypeStr}`;
    if (options?.search) {
      url += `&search=${options.search}`;
    }
    if (options?.changedSince) {
      url += `&changed_since=${options.changedSince}`;
    }
    return axios.get(url);
  }

  function getLMPFromZema(importTypeList: string[]) {
    setLoading(true);
    let promises: any[] = [];
    importTypeList.forEach(importType => {
      promises.push(
        axios({
          url: `${(window as any).REACT_APP_API_BASE_URL_V2}/services/zema/data?node=${
            selectedISONodeOption.key
          }&data_type=${importType}&start_date=${startDate}&end_date=${endDate}&freq=${frequencyStr}`,
          method: 'GET'
        }).then((res: any) => {
          const profileData = generateProfileGrid(
            frequency,
            moment(startDate),
            moment(endDate),
            res.data.data.map((item: any) => item.value)
          );

          if (importType === 'lmprt') {
            dispatch({ type: STORE_LMP_RT_PROFILE, payload: profileData });
          } else {
            dispatch({ type: STORE_LMP_DA_PROFILE, payload: profileData });
          }
        })
      );
    });
    axios
      .all(promises)
      .then((res: any) => {
        dispatch({
          type: UPDATE_EXPLORE_PAGE_INFORMATION,
          payload: {}
        });

        showSuccessAlert();
        handleImportSuccess();
        setLoading(false);
      })
      .catch((err: any) => {
        setErrorMessage(
          'There was an error requesting this Node from Zema.  Please contact an admin if you think this is incorrect'
        );
        setLoading(false);
      });
  }

  const resetForm = () => {
    setErrorMessage('');
    setSelectedISONodeOption({ key: '', value: '', minDate: '', maxDate: '', market: '' });
  };

  const handleImportSuccess = () => {
    resetForm();
    handleSuccessClose();
  };

  const handleISONodeSelection = (option: any) => {
    setSelectedISONodeOption(option);
  };

  const handleImport = () => {
    if (importBoth) {
      getLMPFromZema(['lmp', 'lmprt']);
    } else {
      getLMPFromZema([lmpTypeStr]);
    }
  };

  const showSuccessAlert = () => {
    setIsSuccessAlert(true);
  };

  const hideSuccessAlert = () => {
    setIsSuccessAlert(false);
  };

  const handleImportBothCheckboxChange = () => {
    setImportBoth(!importBoth);
  };

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
        <DialogTitle className={classes.dialogTitle}>Import LMP Data</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {loading && (
            <div style={{ textAlign: 'center', padding: 25 }}>
              <Typography variant="subtitle1" style={{ fontSize: 11, color: '#666' }}>
                Loading data from Zema...
              </Typography>
              <CircularProgress color="secondary" size={30} />
            </div>
          )}
          {!loading && (
            <div>
              {errorMessage != '' && (
                <div>
                  <Alert severity="error">{errorMessage}</Alert>
                  <br />
                </div>
              )}
              <Alert severity="info" style={{ marginBottom: 10 }}>
                Will import data from {startDate} to {endDate}
              </Alert>
              <Typography variant="h6">Please select a CAISO Node</Typography>
              <Typography variant="subtitle1" style={{ fontSize: 11, color: '#666' }}>
                *Currently we ONLY SUPPORT CAISO
              </Typography>
              <div className={classes.selectContainer}>
                <Select
                  async
                  loadOptions={getOptions}
                  options={ISONodeOptions}
                  style={{ width: 300 }}
                  name="iso-node"
                  id="iso-node"
                  value={selectedISONodeOption}
                  valueKey="key"
                  labelKey="value"
                  clearable
                  multi={false}
                  onChange={newValue => handleISONodeSelection(newValue)}
                />
                <Typography variant="subtitle1" style={{ fontSize: 12, color: '#444' }}>
                  <br />
                  Some Suggestions for CAISO Zones:
                  <ul>
                    <li>DLAP_SDGE-APND</li>
                    <li>DLAP_SCE-APND</li>
                    <li>DLAP_PGAE-APND</li>
                  </ul>
                </Typography>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={<Checkbox checked={importBoth} onChange={handleImportBothCheckboxChange} />}
                  label="Query same node for [DA/RT]"
                />
              </div>
            </div>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button onClick={handleImportSuccess} variant="contained" size="small">
              Cancel
            </Button>
            <Button
              onClick={handleImport}
              color="primary"
              variant="contained"
              size="small"
              disabled={selectedISONodeOption && selectedISONodeOption.value === ''}
            >
              Import
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Snackbar open={isSuccessAlert} autoHideDuration={5000} onClose={hideSuccessAlert}>
        <Alert onClose={hideSuccessAlert} severity="success">
          LMP Data has been successfully imported to session
        </Alert>
      </Snackbar>
    </>
  );
};
export default LMPZemaImportDialog;
