import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateConnectedSolutionsOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before NUM_WEEKDAY_DR_EVENTS',
    label: 'Before Number of Weekday DR Events',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before NUM_WEEKDAY_DR_EVENTS_PARTICIPATED',
    label: 'Before Number of Weekday DR Events Participated',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before NUM_WEEKEND_DR_EVENTS',
    label: 'Before Number of Weekend DR Events',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before NUM_WEEKEND_DR_EVENTS_PARTICIPATED',
    label: 'Before Number of Weekend DR Events Participated',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before AVERAGE_WEEKDAY_DISCHARGE_KW',
    label: 'Before Average Weekday Discharge kW',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before AVERAGE_WEEKEND_DISCHARGE_KW',
    label: 'Before Average Weekend Discharge kW',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before CONNECTED_SOLUTIONS_DR_INCENTIVE_WEEKDAY',
    label: 'Before Weekday Performance Payment',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before CONNECTED_SOLUTIONS_DR_INCENTIVE_WEEKEND',
    label: 'Before Weekend Performance Payment',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before CONNECTED_SOLUTIONS_DR_INCENTIVE',
    label: 'Before Total Performance Payment',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  // scenario
  chartOptions.push({
    key: 'After NUM_WEEKDAY_DR_EVENTS',
    label: 'After Number of Weekday DR Events',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After NUM_WEEKDAY_DR_EVENTS_PARTICIPATED',
    label: 'After Number of Weekday DR Events Participated',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After NUM_WEEKEND_DR_EVENTS',
    label: 'After Number of Weekend DR Events',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After NUM_WEEKEND_DR_EVENTS_PARTICIPATED',
    label: 'After Number of Weekend DR Events Participated',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After AVERAGE_WEEKDAY_DISCHARGE_KW',
    label: 'After Average Weekday Discharge kW',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After AVERAGE_WEEKEND_DISCHARGE_KW',
    label: 'After Average Weekend Discharge kW',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After CONNECTED_SOLUTIONS_DR_INCENTIVE_WEEKDAY',
    label: 'After Weekday Performance Payment',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After CONNECTED_SOLUTIONS_DR_INCENTIVE_WEEKEND',
    label: 'After Weekend Performance Payment',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After CONNECTED_SOLUTIONS_DR_INCENTIVE',
    label: 'After Total Performance Payment',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  return chartOptions;
};
