// exports methods that calculate demand & energy saving
import { MonthlyTableData } from '../components/Scenarios/Detail/ScenarioMonthlyResultsTable';
import { MonthlyOperationsData, MonthlyTableKey, OperationsData, ScenarioResults } from '../types';
import { isFinite } from 'lodash';

export enum CostResultKeys {
  DemandCostGA = 'Demand Cost GA',
  DemandCostMidPeak = 'Demand Cost MidPeak',
  DemandCostNC = 'Demand Cost NC',
  DemandCostOffpeak = 'Demand Cost OffPeak',
  DemandCostPDPReservation = 'Demand Cost PDP Reservation',
  DemandCostPeak = 'Demand Cost Peak',
  DemandCostTotal = 'Demand Cost Total',
  DemandCreditPDP = 'Demand Credit PDP',
  DumbChargingCost = 'Dumb Charging Cost',
  EVOverageCost = 'EV Overage Cost',
  EnergyCostBIP = 'Energy Cost BIP',
  EnergyCostGen = 'Energy Cost Gen',
  EnergyCostMidPeak = 'Energy Cost MidPeak',
  EnergyCostNetwork = 'Energy Cost Network',
  EnergyCostOffPeak = 'Energy Cost OffPeak',
  EnergyCostPDP = 'Energy Cost PDP',
  EnergyCostPeak = 'Energy Cost Peak',
  EnergyCostTotal = 'Energy Cost Total',
  ICAPCost = 'ICAP Cost',
  ITRANCost = 'ITRAN Cost',
  SmartChargingCost = 'Smart Charging Cost'
}

export const calculateDemandSavings = (
  index: number,
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  discountRate: number
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;

  let sqScenarioDifference = statusQuoResult['Demand Cost Total'][index] - scenarioResult['Demand Cost Total'][index];
  let adjustment = sqScenarioDifference * (1 - discountRate);
  return +(sqScenarioDifference - adjustment).toFixed(0);
};

export const calculateDemandReductionNC = (
  index: number,
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  return statusQuoResult['Demand Max NC'][index] - scenarioResult['Demand Max NC'][index];
};

export const calculateTotalGHGReduction = (
  index: number,
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  if (scenarioResult['Battery Kgs CO2 / kWh'] && statusQuoResult['Battery Kgs CO2 / kWh']) {
    return (scenarioResult['Battery Kgs CO2 / kWh'][index] - statusQuoResult['Battery Kgs CO2 / kWh'][index]) * -1;
  }
  return 0;
};

export const calculateDemandReductionPeak = (
  index: number,
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  return statusQuoResult['Demand Max Peak'][index] - scenarioResult['Demand Max Peak'][index];
};

export const calculateEnergySavings = (
  index: number,
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  discountRate: number
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  let sqScenarioDifference = statusQuoResult['Energy Cost Total'][index] - scenarioResult['Energy Cost Total'][index];
  let adjustment = sqScenarioDifference * (1 - discountRate);
  return +(sqScenarioDifference - adjustment).toFixed(0);
};

export const calculateTotalDemandSavings = (
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  discountRate: number = 1
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  let statusQuoTotal = statusQuoResult['Demand Cost Total'].reduce((total, num) => {
    return total + num;
  }, 0);
  let scenarioTotal = scenarioResult['Demand Cost Total'].reduce((total, num) => {
    return total + num;
  }, 0);
  let savingsTotal = (statusQuoTotal - scenarioTotal) * discountRate;
  return +savingsTotal.toFixed(0);
};

export const calculateNCDemandSavings = (
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  discountRate: number = 1
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  let statusQuoTotal = statusQuoResult['Demand Cost NC'].reduce((total, num) => {
    return total + num;
  });
  let scenarioTotal = scenarioResult['Demand Cost NC'].reduce((total, num) => {
    return total + num;
  });
  let savingsTotal = (statusQuoTotal - scenarioTotal) * discountRate;
  return +savingsTotal.toFixed(0);
};

export const calculateTOUDemandSavings = (
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  discountRate: number = 1
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  let statusQuoPeak = statusQuoResult['Demand Cost Peak'].reduce((total, num) => {
    return total + num;
  });
  let scenarioPeak = scenarioResult['Demand Cost Peak'].reduce((total, num) => {
    return total + num;
  });
  let savingsTotalPeak = statusQuoPeak - scenarioPeak;

  let statusQuoMidPeak = statusQuoResult['Demand Cost MidPeak'].reduce((total, num) => {
    return total + num;
  });
  let scenarioMidPeak = scenarioResult['Demand Cost MidPeak'].reduce((total, num) => {
    return total + num;
  });
  let savingsTotalMidPeak = statusQuoMidPeak - scenarioMidPeak;

  return +((savingsTotalPeak + savingsTotalMidPeak) * discountRate).toFixed(0);
};

export const calculateTotalEnergySavings = (
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  discountRate: number = 1
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  let statusQuoTotal = statusQuoResult['Energy Cost Total'].reduce((total, num) => {
    return total + num;
  }, 0);
  let scenarioTotal = scenarioResult['Energy Cost Total'].reduce((total, num) => {
    return total + num;
  }, 0);
  let savingsTotal = (statusQuoTotal - scenarioTotal) * discountRate;
  return +savingsTotal.toFixed(0);
};

export const calculateMonthlyTotalSavings = (
  index: number,
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  demandDiscountRate: number,
  energyDiscountRate: number
): number => {
  if (!statusQuoResult || !scenarioResult) return 0;
  let statusQuoTotal =
    statusQuoResult['Demand Cost Total'][index] * demandDiscountRate +
    statusQuoResult['Energy Cost Total'][index] * energyDiscountRate;
  let scenarioTotal =
    scenarioResult['Demand Cost Total'][index] * demandDiscountRate +
    scenarioResult['Energy Cost Total'][index] * energyDiscountRate;
  let savingsTotal = statusQuoTotal - scenarioTotal;
  return +savingsTotal.toFixed(0);
};

export const calculatePerfectKnowledgePercentAverage = (
  key: CostResultKeys,
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  perfectKnowledgeResult: ScenarioResults
): number => {
  if (!statusQuoResult || !scenarioResult || !perfectKnowledgeResult) return 0;
  let statusQuoTotal = statusQuoResult[key].reduce((total: number, num: number) => {
    return total + Math.round(num);
  }, 0);
  let scenarioTotal = scenarioResult[key].reduce((total: number, num: number) => {
    return total + Math.round(num);
  }, 0);
  let perfectKnowledgeTotal = perfectKnowledgeResult[key].reduce((total: number, num: number) => {
    return total + Math.round(num);
  }, 0);
  const x = statusQuoTotal / statusQuoResult[key].length - scenarioTotal / scenarioResult[key].length;
  const y = statusQuoTotal / statusQuoResult[key].length - perfectKnowledgeTotal / perfectKnowledgeResult[key].length;
  const results = (x / y) * 100;
  return !isNaN(results) ? Math.round(results) : 0;
};

export const getRounding = (key: string) => {
  switch (key) {
    case 'After Demand Peak Factor GA':
    case 'Before Demand Peak Factor GA':
      return 8;
    case 'CPS_CREDIT_VALUE':
      return 2;
    default:
      return 0;
  }
};

export const calculateTotal = (
  data: MonthlyTableData[],
  key: MonthlyTableKey,
  statusQuoResult: ScenarioResults,
  scenarioResult: ScenarioResults,
  demandDiscountRate: number,
  energyDiscountRate: number,
  perfectKnowledgeResult?: ScenarioResults
): number | string => {
  if (key === 'Months') {
    return 'Total';
  }

  switch (key) {
    case 'Total Demand Savings':
      return +calculateTotalDemandSavings(statusQuoResult, scenarioResult, demandDiscountRate);
    case 'Total Energy Savings':
      return +calculateTotalEnergySavings(statusQuoResult, scenarioResult, energyDiscountRate);
  }

  if (typeof perfectKnowledgeResult !== 'undefined') {
    switch (key) {
      case '% from Perfect Knowledge - NC Demand Savings':
        return +calculatePerfectKnowledgePercentAverage(
          CostResultKeys.DemandCostNC,
          statusQuoResult,
          scenarioResult,
          perfectKnowledgeResult
        );
      case '% from Perfect Knowledge - On-Peak Demand Savings':
        return +calculatePerfectKnowledgePercentAverage(
          CostResultKeys.DemandCostPeak,
          statusQuoResult,
          scenarioResult,
          perfectKnowledgeResult
        );
      case '% from Perfect Knowledge - Mid-Peak Demand Savings':
        return +calculatePerfectKnowledgePercentAverage(
          CostResultKeys.DemandCostMidPeak,
          statusQuoResult,
          scenarioResult,
          perfectKnowledgeResult
        );
      case '% from Perfect Knowledge - Energy Savings':
        return +calculatePerfectKnowledgePercentAverage(
          CostResultKeys.EnergyCostTotal,
          statusQuoResult,
          scenarioResult,
          perfectKnowledgeResult
        );
    }
  }

  let finalData: number = data.reduce((total: number, item) => total + +item[key], 0);

  return !isNaN(finalData) ? finalData : 0;
};

export const calculateExtraTotal = (data: { [key: string]: number[] }[], key: string): number => {
  return data[key].reduce((total: number, num: number) => {
    return total + num;
  });
};

export const calculateSolarFirming = (solarDemandSavings: number, solarDemandDiscount: number): number => {
  return solarDemandSavings * (1 - solarDemandDiscount / 100);
};

// TODO: Consolidate all methods to use the following:

export function calculateSavingsFunc(resultKey: CostResultKeys) {
  return function (index: number, statusQuoResult: ScenarioResults, scenarioResult: ScenarioResults) {
    if (!statusQuoResult || !scenarioResult) return 0;
    const results = statusQuoResult[resultKey][index] - scenarioResult[resultKey][index];
    return !isNaN(results) && isFinite(results) ? results : 0;
  };
}

export function calculateSavingsPercentageFunc(resultKey: CostResultKeys) {
  return function (
    index: number,
    statusQuoResult: ScenarioResults,
    scenarioResult: ScenarioResults,
    perfectKnowledgeResults: ScenarioResults
  ) {
    if (!statusQuoResult || !scenarioResult || !perfectKnowledgeResults) return 0;
    const x = statusQuoResult[resultKey][index] - scenarioResult[resultKey][index]; // Realtime Savings
    const y = statusQuoResult[resultKey][index] - perfectKnowledgeResults[resultKey][index]; // Perfect Knowledge Savings
    const results = (x / y) * 100;
    return !isNaN(results) && isFinite(results) ? Math.round(results) : 0;
  };
}

function calculateMonthlyGHGEmissionsPerTOU(data: OperationsData): { [key: string]: number } {
  if (!Array.isArray(data?.touPeriod) || !Array.isArray(data?.vecPNetIn) || !Array.isArray(data?.mef))
    return { ON_PEAK: 0, OFF_PEAK: 0, PARTIAL_PEAK: 0 };
  return data.touPeriod.reduce((obj, tou, index) => {
    return createOrUpdate(obj, tou, (data.vecPNetIn[index] / 4) * data.mef[index] ?? 0);
  }, {});
}

function createOrUpdate<T extends object>(obj: T, key: string, value: number) {
  let copy = { ...obj };
  if (copy.hasOwnProperty(key)) {
    copy[key] += value;
  } else {
    copy[key] = value;
  }
  return copy;
}

export function calculateGHGEmissionsPerTOU(
  data: MonthlyOperationsData
): { month: string; emissions: { [key: string]: number } }[] {
  return Object.keys(data).map(month => ({ month: month, emissions: calculateMonthlyGHGEmissionsPerTOU(data[month]) }));
}
