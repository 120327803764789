import auth0 from 'auth0-js';
import axios from 'axios';
// import { appConfig } from '../config';

// function secureMathRandom() {
//   // Divide a random UInt32 by the maximum value (2^32 -1) to get a result between 0 and 1
//   return window.crypto.getRandomValues(new Uint32Array(1))[0]; // 4294967295;
// }

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: 'edf-esap.auth0.com',
      audience: 'https://edf-esap',
      clientID: window.REACT_APP_AUTH0_CLIENT_ID || '12345',
      redirectUri: window.REACT_APP_AUTH0_REDIRECT_URL,
      responseType: 'token id_token',
      scope: 'openid profile user_profile'
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getRoles() {
    return this.roles;
  }

  isAdmin() {
    return this.roles && Array.isArray(this.roles) ? this.roles.includes('admin') : false;
  }

  handleTestAuthentication(access_token, id_token, expires_in) {
    this.accessToken = access_token;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken;
    this.idToken = '';
    this.roles = ['editor', 'admin', 'viewer'];
    this.expiresAt = expires_in * 1000 + new Date().getTime();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.accessToken) {
          return reject(err);
        }
        this.accessToken = authResult.accessToken ? authResult.accessToken : '';
        this.idToken = authResult.idToken;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken;
        this.roles = authResult.idTokenPayload['https://edf-esap/roles'];

        this.profile = authResult.idTokenPayload;

        // set the time that the id token will expire at
        this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

        resolve();
      });
    });
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
    // return true;
  }

  signIn() {
    this.auth0.authorize();
  }

  signOut() {
    // clear id token, profile, and expiration
    this.idToken = null;
    this.accessToken = null;
    this.profile = null;
    this.expiresAt = null;
    const logoutUrl = window.REACT_APP_AUTH0_LOGOUT_URL;
    this.auth0.logout({ returnTo: logoutUrl });
  }
}

const auth0Client = new Auth();

export default auth0Client;
