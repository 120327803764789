import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';

const style = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  container: {
    padding: 20
  },
  item: {
    paddingRight: 15
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  }
});

const CustomerForm = props => {
  const { classes, customer, handleCustomerChange, isEdit } = props;
  const isNew = !customer.public_id;
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={12} md={8} className={classes.item}>
          <TextField
            id="name"
            name="name"
            required
            label="Customer Name"
            value={customer.name || ''}
            onChange={handleCustomerChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            inputProps={{
              readOnly: isEdit || isNew ? false : true
            }}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} className={classes.item}>
          <TextField
            id="website"
            name="website"
            value={customer.website || ''}
            onChange={handleCustomerChange}
            label="Website"
            InputLabelProps={{ shrink: true }}
            fullWidth
            inputProps={{
              readOnly: isEdit || isNew ? false : true
            }}
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} className={classes.item}>
          <TextField
            id="contactName"
            name="contact_name"
            value={customer.contact_name || ''}
            onChange={handleCustomerChange}
            label="Contact Name"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={isEdit || isNew ? false : true}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} className={classes.item}>
          <TextField
            id="contactEmail"
            name="contact_email"
            value={customer.contact_email || ''}
            onChange={handleCustomerChange}
            label="Contact Email"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={isEdit || isNew ? false : true}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} className={classes.item}>
          <TextField
            id="contactPhone"
            name="contact_phone"
            value={customer.contact_phone || ''}
            onChange={handleCustomerChange}
            label="Contact Phone"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={isEdit || isNew ? false : true}
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={3} className={classes.item}>
          <TextField
            id="city"
            name="city"
            value={customer.address ? customer.address.city : ''}
            onChange={handleCustomerChange}
            label="City"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={isEdit || isNew ? false : true}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} className={classes.item}>
          <TextField
            id="state"
            name="state"
            value={customer.address ? customer.address.state : ''}
            onChange={handleCustomerChange}
            label="State"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={isEdit || isNew ? false : true}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} className={classes.item}>
          <TextField
            required
            id="zip_code"
            name="zip_code"
            value={customer.address ? customer.address.zip_code : ''}
            onChange={handleCustomerChange}
            label="Zip Code"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={isEdit || isNew ? false : true}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} className={classes.item}>
          <TextField
            id="country"
            name="country"
            value={customer.address ? customer.address.country : ''}
            onChange={handleCustomerChange}
            label="Country"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={isEdit || isNew ? false : true}
            margin="normal"
          />
        </Grid>
      </Grid>

      <Grid container className={classes.item}>
        <TextField
          id="description"
          name="description"
          value={customer.description || ''}
          onChange={handleCustomerChange}
          label="Description"
          InputLabelProps={{ shrink: true }}
          fullWidth
          multiline
          disabled={isEdit || isNew ? false : true}
          rowsMax="4"
          margin="normal"
        />
      </Grid>
    </div>
  );
};

CustomerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  handleCustomerChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool
};

export default withStyles(style)(CustomerForm);
