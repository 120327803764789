import { remove } from 'immutable';
import { RESET_SUCCESS_FLAGS } from '../../actions/status';

const successReducer = (state = {}, action) => {
  const { type } = action;

  if (type === RESET_SUCCESS_FLAGS) {
    if (!action.payload) {
      return {};
    }
    return remove(state, action.payload);
  }

  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'SUCCESS'
  };
};

export default successReducer;
