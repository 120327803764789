import React from 'react';

import Archive from '@material-ui/icons/Archive';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDate } from './Dates';
import ScenarioBadges from '../components/Scenarios/ScenarioBadges';
import { AssumptionsInternal, DataFromS3, DataRequired, DataType, ProposalInternal, ScenarioInternal } from '../types';

export const generateGrid = (data: DataFromS3, type: DataType): [string, string, number][] => {
  const grid: [string, string, number][] = [];
  let scaler = data['scaler'] ? data['scaler'] : 1;
  data['data'].forEach(consumptionInfo => {
    const currentTime = consumptionInfo['date'].split('T')[1].substring(0, 5);
    const consumptionValue = +consumptionInfo['value'];
    const currentDay = getFormattedDate(new Date(consumptionInfo['date']));
    grid.push([currentDay, currentTime, consumptionValue * scaler]);
  });
  return grid;
};

function getFormattedDate(date: Date): string {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

export const getRequiredFlags = (scenarios: ScenarioInternal[]): DataRequired => {
  let required: DataRequired = { load: true, solar: true, lmp: false, mef: false, ev1: false, ev2: false };
  if (scenarios) {
    scenarios.forEach(scenario => {
      required = checkAssumptionRequirements(scenario.assumptions, required);
    });
  }
  return required;
};

const checkAssumptionRequirements = (assumptions: AssumptionsInternal, required: DataRequired): DataRequired => {
  if (!assumptions) {
    return required;
  }
  if (!required['solar'] && assumptions.isPV) {
    required['solar'] = true;
  }
  if (!required['lmp'] && (assumptions.isUnbundled || assumptions.isGA || assumptions.isPDR)) {
    required['lmp'] = true;
  }

  if (!required['ev1'] && assumptions.useCustomEVLoadProfile && parseInt(assumptions.nbOfEVProfiles) >= 1) {
    required['ev1'] = true;
  }
  if (!required['ev2'] && assumptions.useCustomEVLoadProfile && parseInt(assumptions.nbOfEVProfiles) >= 2) {
    required['ev2'] = true;
  }

  return required;
};

const renderBadges = (scenarios: ScenarioInternal[]): React.ReactNode => {
  return scenarios?.length !== 0 ? <ScenarioBadges scenarios={scenarios} /> : null;
};

const renderName = (proposal: ProposalInternal): React.ReactNode => {
  return proposal.is_archived ? (
    <Tooltip title="This proposal has been archived" enterDelay={100}>
      <Archive
        aria-label="archive-icon"
        style={{ top: '7px', position: 'relative', width: '20px', marginRight: '3px', color: '#717171' }}
      />
    </Tooltip>
  ) : null;
};

export const proposalColumnSchema = [
  { key: 'name', numeric: false, disablePadding: false, label: 'Name', prefix: renderName },
  {
    key: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description'
  },
  { key: 'status', numeric: false, disablePadding: false, label: 'Status' },
  {
    key: 'updated_on',
    numeric: false,
    disablePadding: false,
    label: 'Updated',
    transform: formatDate
  },
  // {
  //   key: 'created_on',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Created',
  //   transform: formatDate
  // },
  {
    key: 'created_user',
    numeric: false,
    disablePadding: false,
    label: 'Created By'
  },
  {
    key: 'proposal_scenarios',
    numeric: false,
    disablePadding: false,
    label: 'Scenarios',
    transform: renderBadges
  }
];
