import React, { useState, useEffect } from 'react';

// MATERIAL UI IMPORTS
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { buildTariffApplicabilityVariables } from '../../../utility/Tariff';
import { ESAPTariff, GenabilityProperty, GenabilityTariff } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    container: {
      padding: 20
    }
  })
);

interface TariffPropertiesProps {
  currentTariff: GenabilityTariff | ESAPTariff | undefined;
  handleSave: (tariff: any) => void;
  handleCancel: any;
}

const TariffProperties: React.FC<TariffPropertiesProps> = ({ currentTariff, handleSave, handleCancel }) => {
  const classes = useStyles();
  const [appValues, setAppValues] = useState<Record<string, string | boolean>>({});
  const [properties, setProperties] = useState<GenabilityProperty[]>([]);

  useEffect(() => {
    if (currentTariff) {
      if (Object.keys(appValues).length === 0) {
        const { applicabilityValues } = buildTariffApplicabilityVariables(currentTariff.properties ?? []);
        setAppValues(applicabilityValues);
        setProperties(currentTariff.properties as GenabilityProperty[]);
      }
    }
  }, [currentTariff]);

  const handlePropertyUpdate = (name: string) => (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (typeof event.target.value === 'string') {
      setAppValues({ ...appValues, [name]: event.target.value });
    }
  };

  const handlePropertyUpdateBool = (name: string) => (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (typeof event.target.value === 'string') {
      setAppValues({ ...appValues, [name]: event.target.value.toLowerCase() === 'true' ? true : false });
    }
  };

  const handleSelect = () => {
    const updatedTariff = { ...currentTariff, applicability_values: appValues };
    handleSave(updatedTariff);
  };

  const displayName = (name: string): string => {
    return name === 'Connection Type  (San Diego)' || name === 'San Diego City Limits (Inside/Outside)'
      ? name + ' *'
      : name;
  };

  const getInputType = (property: GenabilityProperty) => {
    switch (property.dataType) {
      case 'BOOLEAN':
        return (
          <div>
            <Typography variant="subtitle1">{displayName(property.displayName)}</Typography>
            <Select
              id={property.propertyValue}
              name={property.keyName}
              onChange={handlePropertyUpdateBool(property.keyName)}
              value={appValues[property.keyName]}
              margin="none"
            >
              <MenuItem key="true" value="true">
                True
              </MenuItem>
              <MenuItem key="false" value="false">
                False
              </MenuItem>
            </Select>
          </div>
        );
      case 'CHOICE':
        return (
          <div>
            <Typography variant="subtitle1">{displayName(property.displayName)}</Typography>
            <Select
              id={property.propertyValue}
              name={property.keyName}
              onChange={handlePropertyUpdate(property.keyName)}
              value={appValues[property.keyName]}
              margin="none"
            >
              {property.choices &&
                property.choices.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.displayValue}
                  </MenuItem>
                ))}
            </Select>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <section className={classes.container}>
        <Typography variant="h6" gutterBottom>
          Tariff Properties
        </Typography>
        {properties &&
          properties
            .filter(property => {
              return property.isDefault && property.dataType === 'CHOICE' && property.propertyValue;
            })
            .map((property, index) => {
              return (
                <div key={index} style={{ marginBottom: 25 }}>
                  {getInputType(property)}
                </div>
              );
            })}
      </section>
      <section style={{ padding: 20, textAlign: 'right' }}>
        <Button
          id="select-button"
          variant="contained"
          color="primary"
          style={{ marginRight: 10 }}
          onClick={handleSelect}
        >
          Select
        </Button>
        <Button variant="contained" color="secondary" style={{ color: '#fff' }} onClick={handleCancel}>
          Cancel
        </Button>
      </section>
    </div>
  );
};

export default TariffProperties;
