import React, { useState, useEffect } from 'react';
import Plotly from '../../../custom-plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Layout, PlotData } from 'plotly.js';
import moment from 'moment';

// Material UI
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

// React Imports
import { PortfolioResults, Portfolio, ResultPortfolioMeter, PVExport } from '../../../types';
import { CircularProgress } from '@material-ui/core';

const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: 105
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  },
  flex: {
    flex: 1
  }
}));

const layout: Partial<Layout> = {
  legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
  margin: {
    t: 20,
    l: 60,
    r: 20,
    b: 80
  },
  xaxis: {
    title: 'Start Date',
    tickangle: -45,
    tickfont: {
      size: 8
    }
  },
  yaxis: {
    title: '$'
  }
};

interface IProps {
  portfolio: Portfolio;
}

const PortfolioMetersPVProfile: React.FC<IProps> = ({ portfolio }) => {
  const classes = useStyles();
  const [data, setData] = useState<Record<string, Partial<PlotData>[]>>();
  const [months, setMonths] = useState<string[]>([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const buildMonths = (results: PortfolioResults): string[] => {
      const everyMonths = results?.pv_export ? results.pv_export.map(x => moment(x.datetime).format('YYYY-MM')) : [];
      const monthsList = new Set(everyMonths);
      return [...monthsList];
    };
    if (months.length === 0) {
      setMonths(buildMonths(portfolio.results));
    }
  }, [portfolio.results]);

  useEffect(() => {
    if (months && months.length > 0) {
      setSelectedMonth(months[0]);
    }
  }, [months]);

  const getMonthlyData = (monthlyResults: PVExport[], pvProfileSplit: number): { x: string[]; y: number[] } => {
    const x = monthlyResults.map(x => moment(x.datetime).format());
    const y = monthlyResults.map(x => x.value * pvProfileSplit);

    return { x, y };
  };

  useEffect(() => {
    if ((portfolio?.results as PortfolioResults)?.portfolio_meters && selectedMonth.length > 0) {
      const buildData = (results: PortfolioResults): Partial<PlotData>[] => {
        if (data && data[selectedMonth]) {
          return data[selectedMonth];
        } else {
          setLoading(true);
          const data: Partial<PlotData>[] = [];
          const monthlyResults = results?.pv_export
            ? results.pv_export.filter(x => moment(x.datetime).format('YYYY-MM') === selectedMonth)
            : [];
          (results.portfolio_meters as ResultPortfolioMeter[]).forEach(meter => {
            const refMeter = portfolio.portfolio_meters.find(m => m.proposal_id == meter.proposal_id);
            const proposalName = refMeter?.proposal_name ? refMeter.proposal_name : 'NO_NAME';
            const { x, y } = getMonthlyData(monthlyResults, meter.pv_allocation);

            const meterData: Partial<PlotData> = {
              type: 'scatter',
              name: proposalName || '',
              x,
              y
            };
            data.push(meterData);
          });
          setLoading(false);
          return data;
        }
      };
      setData(prevData => ({ ...prevData, [selectedMonth]: buildData(portfolio.results) }));
    }
  }, [portfolio.results, selectedMonth]);

  const handleChangeSelectedMonth = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (typeof event.target.value === 'string') {
      setSelectedMonth(event.target.value);
    }
  };

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          Meters PV Profiles
        </Typography>
        <FormControl>
          <InputLabel id="select-month-pv-label">Month{'  '}</InputLabel>
          <Select
            labelId="select-month-pv-label"
            value={selectedMonth}
            onChange={handleChangeSelectedMonth}
            inputProps={{
              name: 'selectedMonth'
            }}
          >
            {months.map(month => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Toolbar>
      <div style={{ height: 350 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Plot
            style={{ width: '100%' }}
            useResizeHandler={true}
            data={data && data[selectedMonth] ? Object.values(data[selectedMonth]) : []}
            layout={layout}
            config={{ displayModeBar: false, responsive: true }}
          />
        )}
      </div>
    </Paper>
  );
};

export default PortfolioMetersPVProfile;
