import axios from 'axios';
import { fromJS } from 'immutable';
// import { appConfig } from '../config';
import { parseTariffInformationUpdated, parseCalculatedBill } from '../utility/Tariff';
import { ESAPTariff, GenabilityTariff, EsapTariffsFilters, TariffOverview } from '../types';
import { Dispatch } from 'redux';
// TODO: Need to put in REQUEST, SUCCESS, ERROR dispatches for these events
export const SELECT_TARIFFS = 'SELECT_TARIFFS';

export const GET_TARIFF_BY_ID_REQUEST = 'GET_TARIFF_BY_ID_REQUEST';
export const GET_TARIFF_BY_ID_ERROR = 'GET_TARIFF_BY_ID_ERROR';
export const GET_TARIFF_BY_ID_SUCCESS = 'GET_TARIFF_BY_ID_SUCCESS';

export const GET_TARIFFS_SUCCESS = 'GET_TARIFFS_SUCCESS';
export const GET_TARIFFS_ERROR = 'GET_TARIFFS_ERROR';
export const GET_TARIFFS_REQUEST = 'GET_TARIFFS_REQUEST';

export const SET_FILTER = 'SET_FILTER';

export const RESET_TARIFF = 'RESET_TARIFF';

export const CREATE_XML_TARIFF = 'CREATE_XML_TARIFF';
export const CREATE_XML_TARIFFS = 'CREATE_XML_TARIFFS';
export const DUPLICATE_TARIFF = 'DUPLICATE_TARIFF';
export const TARIFF_INPUT_FORM_CHANGE = 'TARIFF_INPUT_FORM_CHANGE';
export const BROADCAST_DATA_TARIFF = 'BROADCAST_DATA_TARIFF';

export const RESET_TARIFF_LIST = 'RESET_TARIFF_LIST';

export const BROADCAST_UPDATE_TARIFF = 'BROADCAST_UPDATE_TARIFF';

export const UPDATE_TARIFF_FILTERS = 'UPDATE_TARIFF_FILTERS';
export const RESET_TARIFF_FILTERS = 'RESET_TARIFF_FILTERS';

export const RUN_GENABILITY_BILLING_REQUEST = 'RUN_GENABILITY_BILLING_REQUEST';
export const RUN_GENABILITY_BILLING_SUCCESS = 'RUN_GENABILITY_BILLING_SUCCESS';
export const RUN_GENABILITY_BILLING_ERROR = 'RUN_GENABILITY_BILLING_ERROR';

export const GET_USED_TARIFFS_REQUEST = 'GET_USED_TARIFFS_REQUEST';
export const GET_USED_TARIFFS_SUCCESS = 'GET_USED_TARIFFS_SUCCESS';
export const GET_FREQUENTLY_USED_TARIFFS = 'GET_FREQUENTLY_USED_TARIFFS';
export const GET_USED_TARIFFS_ERROR = 'GET_USED_TARIFFS_ERROR';

export const POPULATE_SCENARIO_NBC_SUCCESS = 'POPULATE_SCENARIO_NBC_SUCCESS';
export const POPULATE_SCENARIO_NBC_ERROR = 'POPULATE_SCENARIO_NBC_ERROR';
export const POPULATE_SCENARIO_NBC_REQUEST = 'POPULATE_SCENARIO_NBC_REQUEST';

const ESAP_API_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export function selectTariffs(selectedTariffs: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SELECT_TARIFFS, payload: selectedTariffs });
  };
}

export function resetTariff() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_TARIFF });
  };
}

export function resetTariffList() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_TARIFF_LIST });
  };
}

export function updateTariffFilters(updatedFilter: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_TARIFF_FILTERS, payload: updatedFilter });
  };
}

export function resetTariffFilters() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_TARIFF_FILTERS });
  };
}

export function duplicateTariff(tariff: ESAPTariff) {
  return (dispatch: Dispatch) => {
    dispatch({ type: DUPLICATE_TARIFF, payload: tariff });
  };
}

export function setFilter(filter: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_FILTER, payload: filter });
  };
}

export const buildTariffsQueryString = (searchCriteria: EsapTariffsFilters): string => {
  const dateRegex = /\d\d\d\d-\d\d-\d\d/;
  let query = 'populateProperties=true&fields=ext&sortOn=customerLikelihood&customerCount&lseName&tariffBookName&tariffName&sortOrder=DESC';

  if (searchCriteria.effectiveOn && !dateRegex.test(searchCriteria.effectiveOn)) {
    searchCriteria.effectiveOn = null;
  }

  if (searchCriteria.zipCode) {
    query += '&zipCode=' + searchCriteria.zipCode;
  }
  if (searchCriteria.lseId) {
    query += '&utility_id=' + searchCriteria.lseId;
  }
  if (searchCriteria.effectiveOn) {
    query += '&effdate=' + searchCriteria.effectiveOn;
  } else {
    query += '&openOn=2018-11-01&isActive=true';
  }
  if (searchCriteria.general || searchCriteria.residential || searchCriteria.specialUse) {
    let customerClass = '';
    if (searchCriteria.general) customerClass += 'GENERAL,';
    if (searchCriteria.residential) customerClass += 'RESIDENTIAL,';
    if (searchCriteria.specialUse) customerClass += 'SPECIAL_USE';
    if (customerClass[customerClass.length - 1] === ',') {
      customerClass = customerClass.substring(0, customerClass.length - 1);
    }
    query += '&customerClasses=' + customerClass;
  }

  if (searchCriteria.tariffTypeTariff) {
    query += '&tariffTypes=DEFAULT,ALTERNATIVE';
    if (searchCriteria.tariffTypeProposed) {
      query += ',PROPOSED';
    }
    if (searchCriteria.tariffTypeRider) {
      query += ',RIDER';
    }
  } else if (searchCriteria.tariffTypeProposed) {
    query += '&tariffTypes=PROPOSED';
    if (searchCriteria.tariffTypeRider) {
      query += ',RIDER';
    }
  } else if (searchCriteria.tariffTypeRider) {
    query += '&tariffTypes=RIDER';
  } else {
    query += '&tariffTypes=DEFAULT,ALTERNATIVE,PROPOSED';
  }
  if (searchCriteria.pageStart) {
    query += '&pageStart=' + searchCriteria.pageStart * 25;
  } else {
    query += '&pageStart=' + 0;
  }
  if (searchCriteria.pageCount) {
    query += '&pageCount=' + searchCriteria.pageCount;
  } else {
    query += '&pageCount=' + 25;
  }
  if (searchCriteria.search) {
    query += '&search=' + searchCriteria.search;
    query += '&searchOn=masterTariffId,tariffCode,tariffName,tariffBookName,lseCode,lseName';
  }

  return query;
};

export function getTariffs(searchCriteria: any) {
  const query = buildTariffsQueryString(searchCriteria);

  return (dispatch: Dispatch) => {
    dispatch({ type: GET_TARIFFS_REQUEST });
    axios
      .get<{ results: GenabilityTariff[]; count: number }>(`${ESAP_API_URL}/genability/tariffs?${query}`)
      .then(res => {
        const tariffs = res.data;

        dispatch({
          type: GET_TARIFFS_SUCCESS,
          payload: { results: tariffs?.results?.map(fromGenabilityToESAP), count: tariffs.count }
        });
      })
      .catch(function (error) {
        console.error('Error while fetching tariffs list: ' + error);
        dispatch({ type: GET_TARIFFS_ERROR, payload: error });
      });
  };
}

export function fromGenabilityToESAP(parentTariff: GenabilityTariff): Partial<ESAPTariff> {
  return {
    name: parentTariff.tariffName,
    code: parentTariff.tariffCode,
    tariff_id: parentTariff.tariffId,
    lse_id: parentTariff.lseId,
    lse_name: parentTariff.lseName,
    type: parentTariff.tariffType,
    effective_date: parentTariff.effectiveDate,
    source_id: parentTariff.masterTariffId.toString(),
    properties: parentTariff.properties,
    description: '',
    notes: '',
    source_name: 'Genability',
    is_manual: false,
    documentsUrl: parentTariff.documents?.map(doc => doc.document.archiveUrl),
    tariffType: parentTariff.tariffType,
  };
}

export interface CalculateBillConfig {
  fromDateTime: Date;
  toDateTime: Date;
  masterTariffId: number;
  groupBy: string;
  fields: string;
  detailLevel: string;
  billingPeriod: boolean;
  bundleRates: boolean;
  minimums: boolean;
  propertyInputs: PropertyInput[];
}

export interface PropertyInput {
  keyName: string;
  fromDateTime: Date;
  duration: number;
  dataSeries: number[];
  unit: string;
}

export function getCalculatedBill(config: CalculateBillConfig, scenarioName: string, tariffId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: RUN_GENABILITY_BILLING_REQUEST });
    axios
      .post(`${ESAP_API_URL}/genability/calculate`, config)
      .then(res => {
        const response = res.data.results[0];
        const results = fromJS(parseCalculatedBill(response))
          .set('tariffId', tariffId)
          .set('scenarioName', scenarioName);
        dispatch({ type: RUN_GENABILITY_BILLING_SUCCESS, payload: results });
      })
      .catch(function (error) {
        dispatch({
          type: RUN_GENABILITY_BILLING_ERROR,
          payload: error
        });
      });
  };
}

export function handleInputFormChangeTariff(updateData: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: TARIFF_INPUT_FORM_CHANGE, payload: updateData });
  };
}

export function broadcastDataTariff(data: any) {
  return (dispatch: Dispatch) => {
    dispatch({ type: BROADCAST_DATA_TARIFF, payload: data });
  };
}

export function broadcastUpdateTariff(updatedTariff: ESAPTariff) {
  return (dispatch: Dispatch) => {
    dispatch({ type: BROADCAST_UPDATE_TARIFF, payload: updatedTariff });
  };
}

export interface ERatesResponse {
  count: number;
  pageCount: number;
  pageStart: number;
  results: GenabilityTariff[];
  status: string;
  type: 'Tariff';
}

export function getEscalationRates(id: string, currentApplicabilityValues: Record<string, string>) {
  let query = `masterTariffId=${id}&response_format=genability&pageCount=100&sortOn=effectiveDate&sortOrder=DESC&populateRates=true&lookupVariableRates=true&populateProperties=true`;
  return axios.get<ERatesResponse>(`${ESAP_API_URL}/genability/tariffs?${query}`).then(res => res.data);
}

function getSeasonRowIndex(charges: (string | number)[][], seasonName: string) {
  return charges.findIndex(row => row[0] === seasonName);
}

function getTOUColumnIndex(charges: (string | number)[][], touName: string) {
  return charges[0].findIndex(column => column === touName);
}

export function getTariff(id: string, effectiveOn: string = '', currentApplicabilityValues: Record<string, string>) {
  let query = `response_format=genability&applicableRateOnly=true&populateRates=true&lookupVariableRates=true&populateProperties=true`;
  if (effectiveOn && effectiveOn.length > 0) {
    query += `&effective_date=${effectiveOn}`;
  }
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_TARIFF_BY_ID_REQUEST });
    axios
      .get<GenabilityTariff>(`${ESAP_API_URL}/genability/tariff/${id}?${query}`)
      .then(res => {
        const tariff = res.data;
        const {
          properties,
          applicability_values,
          overview,
          energyCharges,
          demandCharges,
          all_rates
        } = parseTariffInformationUpdated(tariff, currentApplicabilityValues);

        const tariffInfo = tariff;

        const processedTariff: Partial<ESAPTariff> = {
          tariff_id: tariffInfo.tariffId,
          source_id: tariffInfo.masterTariffId.toString(),
          properties,
          applicability_values: applicability_values as Record<string, string>,
          overview: overview as TariffOverview,
          energyCharges,
          demandCharges,
          all_rates,
          lse_name: tariffInfo.lseName,
          lse_id: tariffInfo.lseId,
          code: tariffInfo.tariffCode,
          name: tariffInfo.tariffName,
          effective_date: tariffInfo.effectiveDate
        };
        dispatch({ type: GET_TARIFF_BY_ID_SUCCESS, payload: processedTariff });
      })
      .catch(function (error) {
        dispatch({ type: GET_TARIFF_BY_ID_ERROR, payload: error });
        dispatch({ type: RESET_TARIFF });
      });
  };
}

export function populateNBC(id: string, effectiveOn: string = '') {
  let query = `response_format=genability&applicableRateOnly=true&populateRates=true&lookupVariableRates=true&populateProperties=true`;
  if (effectiveOn && effectiveOn.length > 0) {
    query += `&effective_date=${effectiveOn}`;
  }
  return axios.get<GenabilityTariff>(`${ESAP_API_URL}/genability/tariff/${id}?${query}`);
}

export function getUsedTariffs() {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_USED_TARIFFS_REQUEST });
    axios
      .get(`${ESAP_API_URL}/proposal/tariff`)
      .then(res => {
        const payload = res.data;
        dispatch({ type: GET_USED_TARIFFS_SUCCESS, payload });
      })
      .catch(function (error) {
        dispatch({
          type: GET_USED_TARIFFS_ERROR,
          payload: error
        });
      });
  };
}

export function fetchFrequentlyUsedTariffs() {
  return (dispatch: Dispatch) => {
    axios
      .get(`${ESAP_API_URL}/proposal/tariff/frequently-used`)
      .then(res => {
        dispatch({ type: GET_FREQUENTLY_USED_TARIFFS, payload: res.data });
      })
  };
}

export function fetchTariffsList(query?: string) {
  return axios
    .get(`${ESAP_API_URL}/genability/tariffs?${query}`)
    .then(res => res.data);
}

export function buildEscalationRates(res: ERatesResponse, currentApplicabilityValues: Record<string, string>) {
  const tariffs = res.results;
  const results: any[] = [];
  const allSeasons = {};
  const allEnergyTOUNames = {};
  const allDemandTOUNames = {};
  tariffs.forEach(tariff => {
    const {
      properties,
      applicability_values,
      overview,
      energyCharges,
      demandCharges,
      all_rates
    } = parseTariffInformationUpdated(tariff, currentApplicabilityValues);
    energyCharges &&
      energyCharges.forEach(row => {
        const seasonName = row[0];
        allSeasons[seasonName] = 1;
      });
    energyCharges &&
      energyCharges[0].forEach(touName => {
        allEnergyTOUNames[touName] = 1;
      });
    demandCharges &&
      demandCharges[0].forEach(touName => {
        allDemandTOUNames[touName] = 1;
      });
    results.push({
      effective_date: tariff.effectiveDate,
      properties,
      applicability_values,
      overview,
      energyCharges,
      demandCharges,
      all_rates
    });
  });
  const seasonNames = Object.keys(allSeasons).filter(name => name !== 'Season');
  const touEnergyNames = Object.keys(allEnergyTOUNames).filter(name => name !== 'Season');
  const touDemandNames = Object.keys(allDemandTOUNames).filter(name => name !== 'Season');
  const energyTable: any[] = [];
  const demandTable: any[] = [];
  results.forEach(result => {
    seasonNames.forEach(seasonName => {
      const energyRowIndex = getSeasonRowIndex(result.energyCharges, seasonName);
      const demandRowIndex = getSeasonRowIndex(result.demandCharges, seasonName);
      // handle demand
      touDemandNames.forEach(touName => {
        if (demandRowIndex === -1) {
          demandTable.push('-');
          return;
        }
        const demandColumnIndex = getTOUColumnIndex(result.demandCharges, touName);
        if (demandColumnIndex === -1) {
          demandTable.push('-');
          return;
        }
        demandTable.push(result.demandCharges[demandRowIndex][demandColumnIndex]);
      });
      // handle energy
      touEnergyNames.forEach(touName => {
        if (energyRowIndex === -1) {
          energyTable.push('-');
          return;
        }
        const energyColumnIndex = getTOUColumnIndex(result.energyCharges, touName);
        if (energyColumnIndex === -1) {
          energyTable.push('-');
          return;
        }
        energyTable.push(result.energyCharges[energyRowIndex][energyColumnIndex]);
      });
    });
  });
  const effectiveDates = results.map(result => result.effectiveDate);
  const sliceSizeEnergy = touEnergyNames.length * seasonNames.length;
  const sliceSizeDemand = touDemandNames.length * seasonNames.length;
  // now build energy array into one usable table
  const finalEnergyTable: string[][] = [];
  const finalEnergySeasonHeader = [''];
  const finalEnergyColumns = ['Effective Date'];
  for (let i = 0; i < sliceSizeEnergy; i++) {
    finalEnergyColumns.push(touEnergyNames[i % touEnergyNames.length]);
    if (i % touEnergyNames.length === 0) {
      finalEnergySeasonHeader.push(seasonNames[i / touEnergyNames.length]);
    } else {
      finalEnergySeasonHeader.push('');
    }
  }
  finalEnergyTable.push(finalEnergySeasonHeader);
  finalEnergyTable.push(finalEnergyColumns);
  for (let i = 0; i < effectiveDates.length; i++) {
    finalEnergyTable.push(
      [effectiveDates[i]].concat(energyTable.slice(i * sliceSizeEnergy, i * sliceSizeEnergy + sliceSizeEnergy))
    );
  }
  const finalDemandTable: string[][] = [];
  const finalDemandSeasonHeader = [''];
  const finalDemandColumns = ['Effective Date'];
  for (let i = 0; i < sliceSizeDemand; i++) {
    finalDemandColumns.push(touDemandNames[i % touDemandNames.length]);
    if (i % touDemandNames.length === 0) {
      finalDemandSeasonHeader.push(seasonNames[i / touDemandNames.length]);
    } else {
      finalDemandSeasonHeader.push('');
    }
  }
  finalDemandTable.push(finalDemandSeasonHeader);
  finalDemandTable.push(finalDemandColumns);
  for (let i = 0; i < effectiveDates.length; i++) {
    finalDemandTable.push(
      [effectiveDates[i]].concat(demandTable.slice(i * sliceSizeDemand, i * sliceSizeDemand + sliceSizeDemand))
    );
  }
  const tables = {
    finalEnergyTable,
    finalDemandTable
  };

  return tables;
}
