import { RUN_GENABILITY_BILLING_SUCCESS } from '../../actions/tariffs';

const INITIAL_STATE = {
  billingInfo: [],
  consumptionColumns: [],
  demandColumns: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RUN_GENABILITY_BILLING_SUCCESS:
      const results = action.payload;
      return {
        ...state,
        billingInfo: results.billingInfo,
        consumptionColumns: [...results.consumptionColumns],
        demandColumns: [...results.demandColumns]
      };
    default:
      return state;
  }
}
