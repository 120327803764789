const tooltip = {
  maxHourlySolarProd:
    'The maximum solar power in kWh produced in a single hour throughout the year. This value will appear in the Annual Solar Summary as "Max Hourly Production". ',
  allowMaxAutoUpdate: 'Allows the Solar Data tab to update this value based on the uploaded 8760 production profile.',
  totalPVEfficiency:
    'Default to 100%. Allows the user to simulate energy losses or availability reductions. Set to 100% if you plan to model availability in your financial proforma.',
  interpolationType:
    'Default to Linear Interpolation. Tells ESAP how to fill the time gaps when converting the solar profile from hourly to 15-minute level time series. ',
  percentEnergyFromSolar:
    'Changes the percentage of energy the battery must receive from solar. For storage projects receiving ITC this must be 100% for the first 5-years. For batteries that can export to the grid (NEM-paired storage), this must be 100%.'
};

export default tooltip;
