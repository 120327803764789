import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ESAPTariff } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    flex: {
      flex: 1
    },
    block: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(3)
    },
    centerItem: {
      display: 'flex',
      justifyContent: 'center'
    },
    textField: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(1),
      width: 400
    },
    subtitle: {
      marginBottom: theme.spacing(2)
    },
    dense: {
      marginTop: 19
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    addButton: {
      marginLeft: theme.spacing(5),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    buttons: {}
  })
);

interface Props {
  newTariff: ESAPTariff;
  setIsUpdateSeasons: any;
  handleSaveTariff: any;
  baseTariff: any;
  isEV: boolean;
}

const UpdateSeasons: React.FC<Props> = ({ newTariff, setIsUpdateSeasons, handleSaveTariff, baseTariff, isEV }) => {
  const classes = useStyles();

  const getNCDemandCharges = (index: number): string => {
    let total = 0;
    if (baseTariff && Object.keys(baseTariff).length) {
      const currentSeasonKey = Object.keys(baseTariff.overview.seasons)[index];
      if (baseTariff?.overview?.demandBaseAdjustment) {
        total += baseTariff.overview.demandBaseAdjustment;
      }
      if (baseTariff?.overview?.seasons[currentSeasonKey]?.NON_COINCIDENTAL) {
        total += baseTariff.overview.seasons[currentSeasonKey].NON_COINCIDENTAL.reduce((acc: number, curr: any) => {
          acc += curr.amount;
          return acc;
        }, 0);
      }
    }
    return total.toFixed(2);
  };

  const [hasDuplicateNames, setHasDuplicateNames] = useState(false);
  const [seasons, setSeasons] = React.useState(
    Object.keys(newTariff.overview.seasons).map((seasonName, i) => ({
      id: i,
      name: seasonName || '',
      NCDemandCharges: getNCDemandCharges(i)
    }))
  );

  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    if (inputs && inputs[0]) {
      inputs[0].focus();
    }
  }, []);

  useEffect(() => {
    // validator
    const checkDuplicateNames = () => {
      const names = seasons.map((season: any) => season.name);
      if (names.every(name => name.length > 0) && new Set(names).size !== names.length) {
        return true;
      }
      return false;
    };

    setHasDuplicateNames(checkDuplicateNames());
  }, [seasons.map(season => season.name)]);

  const handleChangeSeasonName = (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSeasons = [...seasons];
    updatedSeasons[i]['name'] = event.target.value;
    setSeasons(updatedSeasons);
  };

  const handleChangeNCDemandCharges = (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSeasons = [...seasons];
    updatedSeasons[i].NCDemandCharges = event.target.value;
    setSeasons(updatedSeasons);
  };

  const formatDate = (date: number) => {
    return date.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  };
  const getSeasonDates = (i: number) => {
    const season = { ...newTariff.overview.seasons[`season${i + 1}`] } || undefined;
    if (
      season?.overview?.fromMonth &&
      season?.overview?.toMonth &&
      season?.overview?.fromDay &&
      season?.overview?.toDay
    ) {
      const { overview } = season;

      return `:   ${formatDate(overview.fromMonth as number)}/${formatDate(overview.fromDay as number)} - ${formatDate(
        overview.toMonth as number
      )}/${formatDate(overview.toDay as number)}`;
    }
    return null;
  };
  const handleSaveClick = () => {
    handleSaveTariff(seasons);
  };
  return (
    <div className={classes.root}>
      <div>
        {newTariff &&
          seasons.map((season: any, i: number) => {
            return (
              <div key={season.id} className={classes.block}>
                <Typography variant="h6" gutterBottom>
                  Season {i + 1} {getSeasonDates(i)}
                </Typography>
                {hasDuplicateNames && <FormHelperText error>Please use unique names.</FormHelperText>}
                <TextField
                  label="Season Name"
                  className={classes.textField}
                  value={seasons[i].name}
                  onChange={handleChangeSeasonName(i)}
                  margin="normal"
                />
                {!isEV && (
                  <TextField
                    label="NC Demand Charges"
                    className={classes.textField}
                    value={seasons[i].NCDemandCharges}
                    onChange={handleChangeNCDemandCharges(i)}
                    margin="normal"
                  />
                )}
              </div>
            );
          })}
      </div>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          onClick={() => setIsUpdateSeasons(false)}
          color="secondary"
          className={classes.addButton}
        >
          Back
        </Button>
        <Button
          disabled={hasDuplicateNames}
          variant="contained"
          onClick={handleSaveClick}
          color="primary"
          className={classes.addButton}
        >
          Save Tariff
        </Button>
      </div>
    </div>
  );
};

export default UpdateSeasons;
