import {
  GET_ALL_PORTFOLIOS_SUCCESS,
  DELETE_PORTFOLIO_SUCCESS,
  ARCHIVE_PORTFOLIO_SUCCESS,
  CREATE_PORTFOLIO_SUCCESS,
  COPY_PORTFOLIO_SUCCESS
} from '../../actions/portfolios';
import { Portfolio } from '../../types';

const INITIAL_STATE: Portfolio[] = [];

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case COPY_PORTFOLIO_SUCCESS:
      return [action.payload, ...state];
    case GET_ALL_PORTFOLIOS_SUCCESS:
      return [...action.payload.portfolios];
    case DELETE_PORTFOLIO_SUCCESS:
      let deleteIndex = state.findIndex(proposal => proposal.public_id === action.payload);
      if (deleteIndex > -1) {
        let newSelected = [...state.slice(0, deleteIndex), ...state.slice(deleteIndex + 1)];
        return newSelected;
      } else {
        return state;
      }
    case ARCHIVE_PORTFOLIO_SUCCESS:
      const newState = state.filter(portfolio => portfolio.public_id !== action.payload.public_id);
      return [action.payload, ...newState];
    case CREATE_PORTFOLIO_SUCCESS:
      return [action.payload, ...state];
    default:
      return state;
  }
}
