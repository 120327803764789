import React, { useState } from 'react';

interface IContext {
  selectedYears: { start: string; end: string };
  setSelectedYears: React.Dispatch<React.SetStateAction<{ start: string; end: string }>>;
  selectedMonths: { start: number; end: number };
  setSelectedMonths: React.Dispatch<React.SetStateAction<{ start: number; end: number }>>;
  isMultiYear: boolean;
  setIsMultiYear: React.Dispatch<React.SetStateAction<boolean>>;
  validMonths: string[];
  setValidMonths: React.Dispatch<React.SetStateAction<string[]>>;
  validYears: string[];
  setValidYears: React.Dispatch<React.SetStateAction<string[]>>;
  monthsList: string[];
}
export const ProposalDetailContext = React.createContext<IContext>({} as IContext);

const ProposalDetailContextProvider: React.FC = ({ children }) => {
  const [selectedYears, setSelectedYears] = useState({ start: '0', end: '0' });
  const [selectedMonths, setSelectedMonths] = useState({ start: 0, end: 11 });
  const [isMultiYear, setIsMultiYear] = useState(false);
  const [validMonths, setValidMonths] = useState<string[]>([]);
  const [validYears, setValidYears] = useState<string[]>([]);

  const monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  const value = {
    selectedYears,
    setSelectedYears,
    selectedMonths,
    setSelectedMonths,
    isMultiYear,
    setIsMultiYear,
    validYears,
    setValidYears,
    validMonths,
    setValidMonths,
    monthsList
  };

  return <ProposalDetailContext.Provider value={value}>{children}</ProposalDetailContext.Provider>;
};

export default ProposalDetailContextProvider;
