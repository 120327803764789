import React from 'react';
import { connect } from 'react-redux';
import { withStyles, createStyles } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import TariffSelectListContainer from '../../Tariffs/List/TariffSelectListContainer';
import Fab from '@material-ui/core/Fab';
import TariffProperties from '../../Tariffs/Detail/TariffProperties';
import { getTariffs, selectTariffs, updateTariffFilters } from '../../../actions/tariffs';
import { handleInputFormChangeProposal } from '../../../actions/proposals';
import { getAllManualTariffs } from '../../../actions/manualtariffs';
import { stageTariff, deleteProposalTariff, createProposalTariff } from '../../../actions/proposal_tariffs';
import { StoreState } from '../../../reducers';
import { ProposalInternal, ESAPTariff, GenabilityProperty, WizardChild } from '../../../types';
import { assertIsDefined } from '../../../utils/assertions';

const style = (theme: any) =>
  createStyles({
    root: {
      width: '100%'
    },
    sidenav: {
      backgroundColor: '#EEEEEE',
      width: `calc(100%)`,
      [theme.breakpoints.up('md')]: {
        width: `calc(50%)`
      }
    }
  });

interface IState {
  isOpen: boolean;
  isSkipStep: boolean;
}

interface IProps {
  newProposal: ProposalInternal;
  getTariffs: any;
  updateTariffFilters: any;
  getAllManualTariffs: any;
  handleNext?: any;
  tariffFilters: any;
  classes: any;
  stageTariff: any;
  createProposalTariff: any;
  deleteProposalTariff: any;
  selectTariffs: any;
  manualTariffs: ESAPTariff[];
  tariffs: ESAPTariff[];
}

class ProposalTariffContainer extends React.Component<WizardChild<IProps>, IState> {
  state = {
    isOpen: false,
    isSkipStep: false
  };

  componentDidMount() {
    const zipCode = this.props?.newProposal?.customer?.address?.zip_code
      ? this.props.newProposal.customer.address.zip_code
      : '92101';
    this.props.getTariffs({ zipCode });
    this.props.updateTariffFilters({ zipCode });
    this.props.getAllManualTariffs();
  }

  handleFTMToggle = () => {
    this.setState({
      isSkipStep: !this.state.isSkipStep
    });
  };

  handleSave = () => {
    if (this.props.handleNext) {
      this.props.handleNext();
    }
  };

  handleTariffFilterUpdate = (filters: any) => {
    this.props.updateTariffFilters(filters);
    this.props.getTariffs(filters);
  };

  handleTariffSearchByText = (search: string) => {
    this.handleTariffFilterUpdate({
      ...this.props.tariffFilters,
      search: search
    });
  };

  handleTariffSelect = (tariff: ESAPTariff, isManual: boolean) => {
    // TODO: Update so that tariff is saved to DB here if selected and deleted if not selected
    tariff.manualTariffId = null;
    const selected = this.props.newProposal.proposal_tariffs;
    const selectedIndex = selected.findIndex(selectedTariff => {
      return tariff.is_global
        ? selectedTariff.source_id === tariff.public_id
        : selectedTariff.source_id + selectedTariff.effective_date === tariff.source_id + tariff.effective_date;
    });
    if (selectedIndex > -1) {
      this.handleTariffDelete(selected[selectedIndex]);
    } else if (isManual) {
      // manual tariff and no need to select properties
      // need to move id into manualTariffId
      this.handleCustomTariffSave(tariff);
    } else if (
      tariff.properties &&
      tariff.properties.findIndex((property: GenabilityProperty) => property.dataType === 'CHOICE') === -1
    ) {
      // properties do not exist so you don't need to select them and can add safely
      this.handleTariffSave(tariff);
    } else {
      this.props.stageTariff(tariff);
      this.toggleDrawer();
    }
  };

  buildBaseTariffObject = (tariff: ESAPTariff): Partial<ESAPTariff> => {
    return {
      source_id: tariff.is_global ? tariff.public_id : tariff.source_id,
      lse_name: tariff.lse_name,
      lse_id: tariff.lse_id ? tariff.lse_id : 0,
      code: tariff.code,
      effective_date: tariff.effective_date,
      type: tariff.type,
      name: tariff.name,
      applicability_values: tariff.applicability_values,
      ev_subscriptions: tariff.ev_subscriptions,
      overage_charges: tariff.overage_charges,
      overview: tariff?.overview,
      properties: tariff?.properties
    };
  };

  handleTariffSave = (tariff: ESAPTariff) => {
    const tariffData = this.buildBaseTariffObject(tariff);
    assertIsDefined(this.props.newProposal.public_id, 'The new proposal public_id should not be undefined undefined');
    this.props.createProposalTariff(tariffData, this.props.newProposal.public_id);
  };

  handleCustomTariffSave = (tariff: ESAPTariff) => {
    const tariffData = this.buildBaseTariffObject(tariff);
    tariffData.is_manual = true;
    assertIsDefined(this.props.newProposal.public_id, 'The new proposal public_id should not be undefined undefined');
    this.props.createProposalTariff(tariffData, this.props.newProposal.public_id);
  };

  handleTariffDelete = (tariff: ESAPTariff) => {
    this.props.deleteProposalTariff(tariff, this.props.newProposal.public_id);
  };

  handleTariffAddFromProperties = (tariff: ESAPTariff) => {
    this.handleTariffSave(tariff);
    this.handleTariffAdd(tariff);
    this.toggleDrawer();
  };

  handleTariffAdd = (tariff: ESAPTariff) => {
    this.props.selectTariffs([...this.props.newProposal.proposal_tariffs, tariff]);
    // this.toggleDrawer();
  };

  toggleDrawer = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className="button-container">
          <Fab
            size="small"
            color="secondary"
            aria-label="Save"
            className="button-white"
            onClick={this.handleSave}
            disabled={
              (!this.props.newProposal.proposal_tariffs || this.props.newProposal.proposal_tariffs.length === 0) &&
              !this.state.isSkipStep
            }
          >
            <Save />
          </Fab>
        </div>
        <TariffSelectListContainer
          handleFTMToggle={this.handleFTMToggle}
          isFTM={this.props.newProposal.is_ftm}
          selected={this.props.newProposal.proposal_tariffs}
          tariffs={this.props.tariffs}
          manualTariffs={this.props.manualTariffs}
          filters={this.props.tariffFilters}
          updateTariffFilters={this.handleTariffFilterUpdate}
          handleTariffSelect={this.handleTariffSelect}
          handleTariffSearchByText={this.handleTariffSearchByText}
          isSkipStep={this.state.isSkipStep}
        />
        <Drawer
          classes={{ paper: classes.sidenav }}
          anchor="right"
          open={this.state.isOpen}
          onClose={this.toggleDrawer}
        >
          <TariffProperties
            currentTariff={this.props.newProposal.stagedTariff}
            handleCancel={this.toggleDrawer}
            handleSave={this.handleTariffAddFromProperties}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState) => {
  return {
    tariffs: state.tariffs,
    manualTariffs: state.manualTariffs,
    newProposal: state.newProposal,
    tariffFilters: state.tariffFilters
  };
};

export default connect(mapStateToProps, {
  getTariffs,
  getAllManualTariffs,
  selectTariffs,
  stageTariff,
  updateTariffFilters,
  deleteProposalTariff,
  createProposalTariff,
  handleInputFormChangeProposal
})(withStyles(style)(ProposalTariffContainer));
