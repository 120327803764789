const tooltip = {
  powerRating:
    'The rated capacity of the battery or the maximum power that the battery can discharge at any time. This must be below the "Max Around-the-Clock Load" for projects that will receive the SGIP incentive. termines how far into the future the battery has information for. Standard is a single month for perfect knowledge.',
  batteryCapacity:
    "The usable energy of the battery in kWh. The usable energy assumes a 5 - 15% loss to the inverter from the nameplate energy rating of the battery. This can also be found by multiplying the battery's power rating by the desired duration of the battery. ",
  nameplateCapacity: 'Equivalent to the total capacity of installed battery modules before losses to the inverter.',
  minSOC:
    'State of Charge (SOC) represents the level of charge the battery has relative to its maximum capacity. Minimum SOC of the battery is 0%. ',
  maxSOC:
    'State of Charge (SOC) represents the level of charge the battery has relative to its maximum capacity. Maximum SOC of the battery is 100%. ',
  percentFromSolar:
    'For locked/force-charged scenarios, 100% of the energy must be charged from solar. For unlocked/free-charged scenarios, 0% of the energy must come from solar. To qualify storage for the ITC, the BESS must be forced-charged for the first 5-years. If the BESS will be capable of exporting to the grid, it must be forced-charged from solar.',
  gridExport:
    'Enables the battery to export energy to the grid with a total solar + storage export amount no greater than the "limit network export" field in the strategies tab. The battery may only export when it is 100% charged from solar due to GHG emission requirements.'
};

export default tooltip;
