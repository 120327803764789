import React, { useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { TariffFactoryContext } from './TariffFactoryContext';
import LSESelector from './LSESelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    toolbar2: {
      flex: 1,
      borderTop: '1px solid #e5e5e5',
      borderBottom: '1px solid #e5e5e5',
      marginBottom: theme.spacing(4)
    },
    flex: {
      flex: 1
    },
    block: {
      width: '100%',
      flex: 1,
      marginBottom: theme.spacing(10),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    textField: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(1)
    },
    subtitle: {
      // marginBottom: theme.spacing(3)
      flex: 1
    },
    dense: {
      marginTop: 19
    },
    heading: {
      fontSize: theme.typography.pxToRem(24)
      // fontWeight: theme.typography.fontWeightRegular
    },
    addButton: {
      // marginLeft: theme.spacing(12),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    expansionPanel: {
      width: '100%'
    },
    expansionPanelHeader: {
      backgroundColor: 'rgba(0,26,113,0.17)'
    },
    LSEPicker: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(2)
    }
  })
);

const TariffDescriptionForm: React.FC = () => {
  const classes = useStyles();
  const { tariffMeta, handleChange, handleLSEChange } = useContext(TariffFactoryContext);

  return (
    <>
      <div>
        <TextField
          fullWidth
          id="tariff-name"
          label="Tariff Name"
          className={classes.textField}
          value={tariffMeta.name}
          onChange={handleChange('name')}
          margin="normal"
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="tariff-description"
          label="Tariff Description"
          className={classes.textField}
          value={tariffMeta.description}
          onChange={handleChange('description')}
          margin="normal"
        />
      </div>
      <div>
        <TextField
          fullWidth
          id="code"
          label="Tariff Code"
          className={classes.textField}
          value={tariffMeta.code}
          onChange={handleChange('code')}
          margin="normal"
        />
      </div>
      <div className={classes.LSEPicker}>
        <LSESelector
          value={tariffMeta.lse_id}
          // options={options}
          // filterOptions={filterOptions}
          handleLSEChange={handleLSEChange}
        />
      </div>
    </>
  );
};

export default TariffDescriptionForm;
