import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PJMEventsDialog from './PJMEventsDialog';

const ICAPEvents = ['7/19/2019 17:00', '7/20/2019 16:00', '7/17/2019 16:00', '7/10/2019 17:00', '8/19/2019 16:00'];

const ITRANEvents = {
  jclp: ['7/21/2019 16:00', '7/20/2019 17:00', '7/17/2019 17:00', '7/19/2019 17:00', '7/30/2019 17:00'],
  pseg: ['7/17/2019 17:00', '7/21/2019 17:00', '7/30/2019 17:00', '7/20/2019 17:00', '7/19/2019 17:00'],
  ace: ['7/21/2019 18:00', '7/20/2019 18:00', '7/17/2019 18:00', '7/19/2019 18:00', '7/22/2019 17:00'],
  rec: ['7/21/2019 16:00', '7/17/2019 14:00', '7/30/2019 15:00', '7/20/2019 16:00', '7/29/2019 16:00'],
  dom: [
    '1/24/2011 07:00',
    '2/11/2011 07:00',
    '3/4/2011 07:00',
    '4/25/2011 16:00',
    '5/31/2011 16:00',
    '6/9/2011 16:00',
    '7/22/2011 15:00',
    '8/8/2011 16:00',
    '9/14/2011 16:00',
    '10/31/2011 07:00',
    '11/18/2011 07:00',
    '12/19/2011 07:00',
    '1/4/2012 07:00',
    '2/13/2012 07:00',
    '3/6/2012 06:00',
    '4/16/2012 17:00',
    '5/29/2012 16:00',
    '6/29/2012 16:00',
    '7/26/2012 17:00',
    '8/2/2012 14:00',
    '9/5/2012 16:00',
    '10/3/2012 19:00',
    '11/29/2012 07:00',
    '12/14/2012 07:00',
    '1/23/2013 07:00',
    '2/18/2013 07:00',
    '3/22/2013 07:00',
    '4/10/2013 17:00',
    '5/31/2013 16:00',
    '6/25/2013 16:00',
    '7/18/2013 16:00',
    '8/9/2013 16:00',
    '9/11/2013 16:00',
    '10/4/2013 16:00',
    '11/25/2013 07:00',
    '12/11/2013 07:00',
    '1/7/2014 07:00',
    '2/12/2014 07:00',
    '3/4/2014 07:00',
    '4/17/2014 07:00',
    '5/13/2014 16:00',
    '6/18/2014 17:00',
    '7/2/2014 15:00',
    '8/21/2014 14:00',
    '9/2/2014 16:00',
    '10/14/2014 19:00',
    '11/19/2014 07:00',
    '12/31/2014 07:00',
    '1/8/2015 07:00',
    '2/20/2015 07:00',
    '3/6/2015 07:00',
    '4/2/2015 07:00',
    '5/19/2015 16:00',
    '6/23/2015 16:00',
    '7/20/2015 16:00',
    '8/4/2015 16:00',
    '9/9/2015 16:00',
    '10/19/2015 07:00',
    '11/24/2015 07:00',
    '12/4/2015 07:00',
    '1/19/2016 07:00',
    '2/14/2016 07:00',
    '3/3/2016 07:00',
    '4/6/2016 07:00',
    '5/27/2016 16:00',
    '6/16/2016 16:00',
    '7/25/2016 15:00',
    '8/13/2016 16:00',
    '9/9/2016 16:00',
    '10/19/2016 16:00',
    '11/22/2016 07:00',
    '12/16/2016 07:00',
    '1/9/2017 07:00',
    '2/10/2017 07:00',
    '3/15/2017 07:00',
    '4/29/2017 16:00',
    '5/19/2017 15:00',
    '6/13/2017 15:00',
    '7/14/2017 15:00',
    '8/18/2017 15:00',
    '9/27/2017 16:00',
    '10/9/2017 16:00',
    '11/27/2017 07:00',
    '12/28/2017 07:00',
    '1/7/2018 07:00',
    '2/3/2018 07:00',
    '3/15/2018 07:00',
    '4/11/2018 07:00',
    '5/15/2018 16:00',
    '6/19/2018 13:00',
    '7/2/2018 16:00',
    '8/29/2018 16:00',
    '9/6/2018 16:00',
    '10/4/2018 16:00',
    '11/29/2018 07:00',
    '12/11/2018 07:00',
    '1/31/2019 07:00',
    '2/1/2019 07:00',
    '3/6/2019 06:00',
    '4/2/2019 07:00',
    '5/29/2019 16:00',
    '6/27/2019 16:00',
    '7/20/2019 17:00',
    '8/19/2019 15:00',
    '9/12/2019 16:00'
  ]
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  }
}));

interface PJMAssumptionsProps {
  handleToggle: any;
  isPjmICAP: boolean;
  isPjmITRAN: boolean;
  signalICAP: number;
  utilityITRAN: string;
  ITRANCost: number;
  ICAPCost: number;
  handleGeneralAssumptionChange: (event: any) => void;
}

const PJMAssumptions: React.FC<PJMAssumptionsProps> = ({
  handleToggle,
  isPjmICAP,
  isPjmITRAN,
  signalICAP,
  utilityITRAN,
  ITRANCost,
  ICAPCost,
  handleGeneralAssumptionChange
}) => {
  const classes = useStyles();

  const [openICAP, setOpenICAP] = useState(false);
  const [openITRAN, setOpenITRAN] = useState(false);
  // const [utility, setUitlity] = useState('');
  return (
    <>
      <div>
        <FormControlLabel
          className={classes.block}
          control={<Switch onChange={handleToggle} checked={isPjmICAP} name="isPjmICAP" data-testid="isICAP" />}
          label="Is ICAP"
        />

        {isPjmICAP && (
          <div
            style={{
              paddingLeft: 25,
              borderLeft: '10px solid #eee'
            }}
          >
            <PJMEventsDialog title="ICAP" open={openICAP} events={ICAPEvents} onClose={() => setOpenICAP(false)} />
            <FormControl className={classes.formControl}>
              <InputLabel id="ICAP-simple-select-label">Signal</InputLabel>
              <Select
                labelId="ICAP-simple-select-label"
                id="ICAP-simple-select"
                name="signalICAP"
                value={signalICAP}
                onChange={handleGeneralAssumptionChange}
              >
                <MenuItem value={3}>3 hrs</MenuItem>
                <MenuItem value={2}>2 hrs</MenuItem>
                <MenuItem value={1}>1 hr</MenuItem>
              </Select>
              <TextField
                className={classes.block}
                label="ICAP Cost"
                key="ICAPCost"
                name="ICAPCost"
                value={ICAPCost}
                onChange={handleGeneralAssumptionChange}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                margin="normal"
              />
              <Button
                className={classes.block}
                color="primary"
                variant="contained"
                onClick={() => setOpenICAP(!openICAP)}
              >
                Show ICAP Events
              </Button>
            </FormControl>
          </div>
        )}
      </div>
      <div>
        <FormControlLabel
          className={classes.block}
          control={<Switch onChange={handleToggle} checked={isPjmITRAN} name="isPjmITRAN" />}
          label="Is ITRAN"
        />

        {isPjmITRAN && (
          <div
            style={{
              paddingLeft: 25,
              borderLeft: '10px solid #eee'
            }}
          >
            <PJMEventsDialog
              title="ITRAN"
              open={openITRAN}
              events={ITRANEvents[utilityITRAN]}
              onClose={() => setOpenITRAN(false)}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="ITRAN-simple-select-label">Select Utility</InputLabel>
              <Select
                labelId="ITRAN-simple-select-label"
                id="ITRAN-simple-select"
                data-testid="itran-simple-select"
                name="utilityITRAN"
                value={utilityITRAN}
                onChange={handleGeneralAssumptionChange}
              >
                <MenuItem value=""> </MenuItem>
                <MenuItem value="jclp" data-testid="jclp">
                  JCLP
                </MenuItem>
                <MenuItem value="pseg">PSEG</MenuItem>
                <MenuItem value="ace">ACE</MenuItem>
                <MenuItem value="rec">REC</MenuItem>
                <MenuItem value="dom">DOM</MenuItem>
              </Select>
              <TextField
                className={classes.block}
                label="ITRAN Cost"
                key="ITRANCost"
                name="ITRANCost"
                value={ITRANCost}
                onChange={handleGeneralAssumptionChange}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                margin="normal"
              />
              <Button
                data-testid="show-itran-events"
                disabled={utilityITRAN.length === 0}
                className={classes.block}
                color="primary"
                variant="contained"
                onClick={() => setOpenITRAN(!openITRAN)}
              >
                Show ITRAN Events
              </Button>
            </FormControl>
          </div>
        )}
      </div>
    </>
  );
};

export default PJMAssumptions;
