import React, { useEffect, useRef } from 'react';
import CellDetail from './CellDetail';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PeriodDetail } from './TariffDetailTOU';
import grey from '@material-ui/core/colors/grey';

const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const hour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const hourLabels = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm'
];
interface TOUGridDetailProps {
  selectedPeriod?: Readonly<PeriodDetail>;
  matrix: Readonly<number[][]>;
  periods: Readonly<PeriodDetail[]>;
  setSelected: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'grid',
    gridGap: '2px',
    // padding: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(25, 32px)',
    gridTemplateRows: 'repeat(13, 32px)'
  },
  btn: {
    marginBottom: theme.spacing(2)
  },
  months: {
    color: grey[400],
    marginBottom: theme.spacing(1)
  },
  hours: {
    color: grey[400],
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const TOUGridDetail: React.FC<TOUGridDetailProps> = ({ setSelected, matrix, periods }) => {
  const classes = useStyles();
  const grid = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (periods && Object.keys(periods).length > 0) {
      const cells = grid?.current && (grid.current.querySelectorAll('.cell-grid-item') as NodeListOf<HTMLElement>);
      const cellsArray = cells?.length && Array.from(cells);
      matrix.forEach((month, row) => {
        month.forEach((index, column) => {
          // find the period corresponding to the index
          const period = periods.find(period => period.index === index);
          //
          const cellIndex: number = row + column * 12;
          if (cellsArray && cellsArray.length) {
            const cell = cellsArray[cellIndex];
            if (cell) {
              cell.innerHTML = index.toString();
              if (period?.color) {
                cell.style.backgroundColor = period.color;
              }
            }
          }
        });
      });
    }
  }, [matrix]);

  const getPeriod = (row: number, column: number): PeriodDetail | undefined => {
    const periodIndex = matrix[row - 1][column - 1];
    const period = periods.find(period => period.index === periodIndex);
    return period;
  };

  return (
    <>
      <div className={classes.gridContainer} ref={grid}>
        {monthLabels.map((month, i) => {
          return (
            <div
              className={classes.months}
              key={`month-${i}`}
              style={{ gridRow: `${i + 2} / span 1`, gridColumn: '1 / span 1' }}
            >
              {month}
            </div>
          );
        })}
        {hourLabels.map((month, j) => {
          return (
            <div
              className={classes.hours}
              key={`hour-${j}`}
              style={{
                transform: `rotate(270deg)`,
                gridRow: `1/ span 1`,
                gridColumn: `${j + 2}/ span 1`
              }}
            >
              {month}
            </div>
          );
        })}
        {hour.map(column => {
          return month.map(row => {
            return (
              <CellDetail
                key={`${row}-${column}`}
                id={`${row}-${column}`}
                hour={column}
                month={row}
                period={getPeriod(row, column)}
                setSelected={setSelected}
              />
            );
          });
        })}
      </div>
    </>
  );
};

export default React.memo(TOUGridDetail);
