import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListSearchInput from '../../Table/ListSearchInput';
import BaseTable from '../../Table/BaseTable';
import { stableSort, getSorting } from '../../../utility/Table';
import { userCreatedTariffColumnSchema } from '../../../utility/Tariff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import { deleteManualTariff } from '../../../actions';
import { ESAPTariff } from '../../../types';
import ConfirmPopUp from '../../Common/ConfirmPopUp/ConfirmPopUp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  spacer: {
    flex: '1 1 auto'
  },
  white: {
    color: '#fff'
  },
  loadingContainer: {
    textAlign: 'center',
    padding: 25
  }
}));

interface UserCreatedTariffListProps {
  history: any;
  title?: string;
  tariffs: any;
  columnActionSchema: any;
  loading?: boolean;
  onTariffClick?: (tariff: ESAPTariff) => void;
}

const UserCreatedTariffList: React.FC<UserCreatedTariffListProps> = (props: UserCreatedTariffListProps) => {
  const {
    history,
    title,
    tariffs,
    columnActionSchema,
    loading,
    onTariffClick,
  } = props;
  const initialState = {
    search: '',
    orderBy: 'created_on',
    order: 'desc',
    page: 0,
    rowsPerPage: 25
  };

  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [isConfirm, setIsConfirm] = useState(false);
  const [selectedTariffId, setSelectedTariffId] = useState('');

  const handleDeleteAction = (tariff: ESAPTariff) => (event: React.MouseEvent<any>) => {
    if (tariff?.public_id) {
      event.stopPropagation();
      setSelectedTariffId(tariff.public_id);
      setIsConfirm(true);
    }
  };

  const handleConfirmClose = (success: boolean) => () => {
    if (success && selectedTariffId.length > 0) {
      dispatch(deleteManualTariff(selectedTariffId));
    }
    setIsConfirm(false);
    setSelectedTariffId('');
  };

  const proposalRowActionSchema = [
    {
      key: 'delete',
      icon: Delete,
      actionHandler: handleDeleteAction,
      label: 'Delete User Created Tariff'
    }
  ];

  const handleRowClick = (tariff: ESAPTariff) => () => {
    if (typeof onTariffClick === 'function') {
      onTariffClick(tariff);
      return;
    }

    history.push('/tariffs/' + tariff.public_id + '?manual=true&effectiveOn=' + tariff.effective_date);
  };

  const filterBySearch = (item: { name: string; code: string }) => {
    if (!state.search) {
      return true;
    }
    const search = state.search;
    if (
      item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
      item.code.toLowerCase().indexOf(search.toLowerCase()) > -1
    ) {
      return true;
    }
    return false;
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, search: event.target.value });
  };

  const handleRequestSort = (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
    const orderBy = property;
    let order = 'desc';

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc';
    }

    setState({ ...state, order, orderBy });
  };

  const handleChangePage = (event: React.ChangeEvent<HTMLInputElement>, page: number) => {
    setState({ ...state, page });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const getActiveDataSlice = (data: any) => {
    return stableSort(data, getSorting(state.order, state.orderBy)).slice(
      state.page * state.rowsPerPage,
      state.page * state.rowsPerPage + state.rowsPerPage
    );
  };

  const filteredData = tariffs.filter(filterBySearch);
  return (
    <Paper>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit">
          {title ? title : 'User Created Tariffs'}
        </Typography>
        <div className={classes.spacer} />
        <ListSearchInput handleSearchChange={handleSearchChange} search={state.search} />
        {columnActionSchema.map((action: any) => {
          return (
            <Tooltip title={action.label} placement="bottom" enterDelay={300} key={action.label}>
              <IconButton onClick={action.actionHandler} className={classes.white}>
                <action.icon />
              </IconButton>
            </Tooltip>
          );
        })}
      </Toolbar>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}

      {!loading && (
        <BaseTable
          data={getActiveDataSlice(filteredData)}
          onRowClick={handleRowClick}
          onRequestSort={handleRequestSort}
          columnSchema={userCreatedTariffColumnSchema}
          order={state.order}
          orderBy={state.orderBy}
          page={state.page}
          rowsPerPage={state.rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          dataCount={filteredData.length}
          rowActionSchema={proposalRowActionSchema}
        />
      )}
      <ConfirmPopUp
        open={isConfirm}
        onConfirmClose={handleConfirmClose}
        message="Are you sure that you want to delete this tariff?"
      />
    </Paper>
  );
};

export default UserCreatedTariffList;
