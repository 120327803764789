import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CustomerList from '../../components/Customers/List/CustomerList';
import { createLoadingSelector } from '../../selectors';
import { getAllCustomers } from '../../actions/customers';
import { resetBreadcrumb, pushBreadcrumb } from '../../actions/breadcrumbs';

const style = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  content: {
    padding: theme.spacing(3)
  }
});

class CustomerListPage extends React.Component {
  componentDidMount() {
    this.props.resetBreadcrumb();
    this.props.pushBreadcrumb('Customers', '/customers');
    if (!this.props.customers || this.props.customers.length === 0) {
      this.props.getAllCustomers();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <CustomerList
            title="Customers"
            customers={this.props.customers}
            history={this.props.history}
            loading={this.props.isLoading}
          />
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['GET_ALL_CUSTOMERS']);

const mapStateToProps = state => {
  return {
    customers: state.customers,
    isLoading: loadingSelector(state)
  };
};

export default connect(mapStateToProps, { getAllCustomers, resetBreadcrumb, pushBreadcrumb })(
  withStyles(style)(CustomerListPage)
);
