import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Settings from '@material-ui/icons/Settings';
// import Plot from 'react-plotly.js';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';

const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles({
  block: {
    marginTop: 8,
    marginBottom: 8
  }
});

const buildLayout = () => {
  var layout = {
    legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
    margin: {
      t: 30,
      l: 0,
      r: 0,
      b: 30
    }
  };
  return layout;
};

const DemandChargeTotalPie = props => {
  const theme = useTheme();
  const classes = useStyles();
  const { demandTotal, energyTotal, discountRate } = props;

  const [data, setData] = useState([]);
  const [showPercent, setShowPercent] = useState(true);

  const [label, setLabel] = useState(props.title);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const currencyFormatterForUI = value => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return formatter.format(value || 0);
  };

  const inDollars = value => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  const buildData = useCallback(
    renderPercent => {
      const data = [];
      if (renderPercent) {
        data.push({
          labels: [`Demand Cost: ${inDollars(demandTotal)}`, `Energy Cost: ${inDollars(energyTotal)}`],
          values: [
            +(100 * (demandTotal / (demandTotal + energyTotal))).toFixed(0),
            +(100 * (energyTotal / (demandTotal + energyTotal))).toFixed(0)
          ],
          type: 'pie',
          //textinfo: 'value',
          textfont: { size: 20, color: '#fff' },
          hoverinfo: 'value',
          automargin: true,
          marker: {
            colors: [theme.palette.lightBlue, theme.palette.green]
          }
        });
      } else {
        data.push({
          labels: ['Demand Cost', 'Energy Cost'],
          values: [+demandTotal.toFixed(0), +energyTotal.toFixed(0)],
          text: [+demandTotal.toFixed(0), +energyTotal.toFixed(0)].map(v => currencyFormatterForUI(v)),
          textinfo: 'text',
          textfont: { size: 20, color: '#fff' },
          hoverinfo: 'value',
          automargin: true,
          type: 'pie',
          marker: {
            colors: [theme.palette.lightBlue, theme.palette.green]
          }
        });
      }
      setData(data);
    },
    [demandTotal, energyTotal, theme.palette.green, theme.palette.lightBlue]
  );

  useEffect(() => {
    buildData(true);
  }, [buildData, discountRate, demandTotal, energyTotal]);
  // eslint-disable-next-line

  const handleChange = e => {
    setLabel(e.target.value);
  };

  return (
    <Paper>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Tooltip title={label} style={{ flex: 1 }}>
          <Typography variant="h6" noWrap={true} color="inherit" style={{ flex: 1 }}>
            {label}
          </Typography>
        </Tooltip>
        <IconButton aria-label="Chart Settings" onClick={handleOpen}>
          <Settings />
        </IconButton>
      </Toolbar>
      <div style={{ height: 300, overflow: 'hidden' }}>
        <Plot
          style={{ height: 280, width: '100%' }}
          useResizeHandler={true}
          data={data}
          layout={buildLayout()}
          config={{ displayModeBar: false }}
        />
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
        <DialogTitle id="form-dialog-title">Chart Settings</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField className={classes.block} label="Label" value={label} onChange={handleChange} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

DemandChargeTotalPie.propTypes = {
  title: PropTypes.string.isRequired,
  demandTotal: PropTypes.number.isRequired,
  energyTotal: PropTypes.number.isRequired,
  discountRate: PropTypes.object.isRequired
};

export default DemandChargeTotalPie;
