import {
  DUPLICATE_PROPOSAL_SCENARIO,
  CREATE_PROPOSAL_SCENARIO_SUCCESS,
  UPDATE_PROPOSAL_SCENARIO_SUCCESS,
  UPDATE_PROPOSAL_SCENARIO_SILENT,
  RESET_PROPOSAL_SCENARIO,
  SCENARIO_INPUT_FORM_CHANGE,
  RESET_REDIRECT
} from '../../actions/scenarios';
import { ScenarioInternal } from '../../types';
import { assumptionDefaults } from '../../utility/Assumption';

const INITIAL_STATE: ScenarioInternal = {
  proposal_id: '',
  name: '',
  assumptions: assumptionDefaults,
  active: true,
  public_id: '',
  results: null,
  notes: ''
};

export default function newScenarioReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case DUPLICATE_PROPOSAL_SCENARIO:
      const scenarioCopy = {
        name: action.payload.name,
        assumptions: action.payload.assumptions,
        proposal_id: action.payload.proposal_id
      };
      return scenarioCopy;
    case UPDATE_PROPOSAL_SCENARIO_SILENT:
    case CREATE_PROPOSAL_SCENARIO_SUCCESS:
    case UPDATE_PROPOSAL_SCENARIO_SUCCESS:
      return { ...state, ...action.payload, redirect: true };
    case SCENARIO_INPUT_FORM_CHANGE:
      return { ...state, ...action.payload };
    case RESET_PROPOSAL_SCENARIO:
      return INITIAL_STATE;
    case RESET_REDIRECT:
      return { ...state, redirect: false };
    default:
      return state;
  }
}
