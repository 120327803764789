import { ScenarioInternal, Portfolio } from '../../types';
import {
  CREATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_SUCCESS,
  RESET_CREATE_PORTFOLIO,
  PORTFOLIO_INPUT_FORM_CHANGE,
  UPDATE_PORTFOLIO_METER
} from '../../actions/portfolios';

const INITIAL_STATE: Partial<Portfolio> = {
  name: '',
  description: '',
  status: '',
  notes: '',
  public_id: '',
  portfolio_type: 'NEMA',
  is_archived: false,
  portfolio_meters: [
    {
      scenario_id: '',
      is_primary: true,
      proposal_id: '',
      portfolio_id: '',
      status_quo_id: undefined,
      scenarios: [] as ScenarioInternal[]
    }
  ]
};

export default function portfolioNewReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case RESET_CREATE_PORTFOLIO:
      return INITIAL_STATE;
    case CREATE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        description: action.payload.description,
        status: action.payload.status,
        notes: action.payload.notes,
        public_id: action.payload.public_id
      };
    case UPDATE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        description: action.payload.description,
        status: action.payload.status,
        notes: action.payload.notes
      };
    case PORTFOLIO_INPUT_FORM_CHANGE:
      return { ...state, ...action.payload };
    case UPDATE_PORTFOLIO_METER:
      return { ...state, portfolio_meters: action.payload };
    default:
      return state;
  }
}
