import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateVDERComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before VDER Revenues',
    label: 'Before VDER Revenues',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After VDER Revenues',
    label: 'After VDER Revenues',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before VDER Energy Revenue',
    label: 'Before VDER Energy Revenue',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After VDER Energy Revenue',
    label: 'After VDER Energy Revenue',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before VDER ICAP Revenue',
    label: 'Before VDER ICAP Revenue',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After VDER ICAP Revenue',
    label: 'After VDER ICAP Revenue',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before VDER DRV Revenue',
    label: 'Before VDER DRV Revenue',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After VDER DRV Revenue',
    label: 'After VDER DRV Revenue',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before VDER Environment Revenue',
    label: 'Before VDER Environment Revenue',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After VDER Environment Revenue',
    label: 'After VDER Environment Revenue',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before VDER Community Credit Revenue',
    label: 'Before VDER Community Credit Revenue',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After VDER Community Credit Revenue',
    label: 'After VDER Community Credit Revenue',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Exported Energy',
    label: 'Before Exported Energy',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Exported Energy',
    label: 'After Exported Energy',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  return chartOptions;
};
