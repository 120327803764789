import React from 'react';

//Material UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Error from '@material-ui/icons/Error';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      margin: '20px 20px 0 20px',
      paddingTop: '0 !important',
      background: '#ff000024',
      borderRadius: '3px'
    },
    text: {
      color: theme.palette.error.main
      // textAlign: 'center'
    },
    iconContainer: {
      width: '100%',
      textAlign: 'center',
      margin: '10px 0 0 0'
    },
    icon: {
      width: '1.5em',
      height: '1.5em',
      color: theme.palette.error.main
    },
    buttonLarge: {
      margin: '8px 16px',
      width: '100%'
    }
  })
);

export interface Props {
  open: boolean;
  errorMsg: string | React.ReactNode;
  onClose: () => void;
}
/**
 * Dialog modal that warns the user about errors and gives more context
 * Props are:
 * @param {boolean}  open - boolean value that opens the modal
 * @param {string | React.ReactNode } errorMsg - error message
 * @param  {() => void } onClose - callback executed after clicking on close button
 */
const ErrorDialog: React.FC<Props> = props => {
  const classes = useStyles();
  const { errorMsg, onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog className={classes.root} open={open} onClose={handleClose} aria-describedby="error-dialog-description">
      <DialogContent className={classes.content}>
        <div className={classes.iconContainer}>
          <Error className={classes.icon} />
        </div>
        <DialogContentText className={classes.text}>{errorMsg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.buttonLarge}
          onClick={handleClose}
          color="default"
          autoFocus
          aria-label="close-alert-button"
          variant="outlined"
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
