import React, { useState, useEffect } from 'react';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';

// REACT IMPORTS
import { Portfolio, PortfolioMeterResults, PortfolioResults, ResultPortfolioMeter } from '../../../types';
import * as R from 'ramda';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  flex: {
    flex: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    padding: '0 0 25px 0',
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700,
    border: '1px solid rgb(234, 234, 234)',
    '& td, & th': {
      padding: '0 6px',
      textAlign: 'center',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      fontSize: '11px',
      color: '#333'
    },
    '& tr': {
      height: 32
    },
    '& tr>td:first-child, & tr:first-child>th:first-child': {
      fontWeight: '500',
      color: '#17173c',
      verticalAlign: 'middle',
      backgroundColor: '#ebf6ff'
    },
    '& tr:first-child th': {
      whiteSpace: 'normal',
      fontWeight: '500',
      color: '#111',
      backgroundColor: '#e8f1ee',
      lineHeight: '1.2em',
      verticalAlign: 'top',
      padding: '9px',
      fontSize: '11px'
    }
  },
  statusQuoHeader: {
    backgroundColor: '#ccc',
    textAlign: 'center',
    color: '#000'
  },
  scenarioHeader: {
    backgroundColor: '#0071FF',
    textAlign: 'center',
    color: '#fff'
  },
  savingsHeader: {
    backgroundColor: '#333',
    textAlign: 'center',
    color: '#fff'
  },
  highlight: {
    backgroundColor: 'rgba(255, 255, 0, 0.5)',
    fontWeight: 'bold'
  }
}));

interface IProps {
  portfolio: Portfolio;
}

interface ChartOption {
  key: keyof PortfolioMeterResults;
  label: string | React.ReactNode;
  include: boolean;
  highlight: boolean;
  tooltip?: string;
  bgColor: string;
}

const initialOptions: ChartOption[] = [
  {
    key: 'Months',
    label: 'Start Date',
    include: true,
    highlight: false,
    bgColor: '#e8f1ee'
  }
];

const PortfolioResultsMonthlyTableNemA: React.FC<IProps> = ({ portfolio }) => {
  const [data, setData] = useState<(string | number)[][]>([]);
  const classes = useStyles();

  const [chartOptions, setChartOptions] = useState(initialOptions);

  useEffect(() => {
    const options = [...initialOptions];

    const renderColumnRender = (label: string, proposalName: string) => {
      return (
        <span>
          {label} <br /> <hr style={{ opacity: 0.7 }} />
          <i style={{ opacity: 0.6 }}>{proposalName} ($) </i>
        </span>
      );
    };

    const getBgColor = (i: number): string => {
      return i % 2 !== 0 ? '#e8f1ee' : '#e8e9f1';
    };

    if (portfolio && (portfolio?.results as PortfolioResults)?.portfolio_meters) {
      ((portfolio.results as PortfolioResults).portfolio_meters as ResultPortfolioMeter[]).forEach(
        (meter, meterIndex) => {
          const refMeter = portfolio.portfolio_meters.find(m => m.proposal_id == meter.proposal_id);
          const proposalName = refMeter?.proposal_name ? refMeter.proposal_name : 'NO_NAME';
          const scenarioName = refMeter?.scenario_name ? refMeter.scenario_name : 'NO_NAME';
          if (
            Array.isArray(meter.results['battery_energy_savings']) &&
            meter.results['battery_energy_savings'].length > 0
          ) {
            options.push({
              key: 'battery_energy_savings',
              label: (
                <span>
                  Battery Energy Savings <br /> <hr style={{ opacity: 0.7 }} />
                  <i style={{ opacity: 0.6 }}>{proposalName} ($) </i>
                </span>
              ),
              tooltip: `Proposal Name: ${proposalName} \nScenario Name: ${scenarioName}`,
              include: true,
              highlight: false,
              bgColor: getBgColor(meterIndex)
            });
          }
          if (meter.results['total_energy_savings'] && meter.results['total_energy_savings_sum'] != 0) {
            options.push({
              key: 'total_energy_savings',
              label: renderColumnRender('Total Energy Savings', proposalName),
              tooltip: `Proposal Name: ${proposalName} \nScenario Name: ${scenarioName}`,
              include: true,
              highlight: false,
              bgColor: getBgColor(meterIndex)
            });
          }
          if (meter.results['total_demand_savings'] && meter.results['total_demand_savings_sum'] != 0) {
            options.push({
              key: 'total_demand_savings',
              label: renderColumnRender('Total Demand Savings', proposalName),
              tooltip: `Proposal Name: ${proposalName} \nScenario Name: ${scenarioName}`,
              include: true,
              highlight: false,
              bgColor: getBgColor(meterIndex)
            });
          }
          if (Array.isArray(meter.results['battery_nem_revenue']) && meter.results['battery_nem_revenue'].length > 0) {
            options.push({
              key: 'battery_nem_revenue',
              label: renderColumnRender('Battery NEM Revenue', proposalName),
              tooltip: `Proposal Name: ${proposalName} \nScenario Name: ${scenarioName}`,
              include: true,
              highlight: false,
              bgColor: getBgColor(meterIndex)
            });
          }
          if (Array.isArray(meter.results['solar_nem_revenue']) && meter.results['solar_nem_revenue'].length > 0) {
            options.push({
              key: 'solar_nem_revenue',
              label: renderColumnRender('Solar NEM Revenue', proposalName),
              tooltip: `Proposal Name: ${proposalName} \nScenario Name: ${scenarioName}`,
              include: true,
              highlight: false,
              bgColor: getBgColor(meterIndex)
            });
          }
          options.push({
            key: 'nem_credit_revenue',
            label: renderColumnRender('NEM Credits', proposalName),
            tooltip: `Proposal Name: ${proposalName} \nScenario Name: ${scenarioName}`,
            include: true,
            highlight: false,
            bgColor: getBgColor(meterIndex)
          });
        }
      );
      options.push({
        key: 'Months', // just added this key for TS not to complain for now
        label: `Total Savings ($)`,
        include: true,
        highlight: false,
        bgColor: '#e8f1ee'
      });
      setChartOptions(options);
    }
  }, [portfolio.results]);

  const formatRevenue = (value: number) => {
    return Math.round(value).toLocaleString('en-US');
  };

  useEffect(() => {
    if (portfolio?.results?.portfolio_meters) {
      const buildData = (result: PortfolioResults) => {
        const mergeWithAdd = R.compose(R.values, R.mergeWith(R.add));
        // with the current implementation the order of columns matters and has to match the options built in useEffect above
        let data: (number | string)[][] = [];
        let totalRowValues: number[] = [];
        const length = result.portfolio_meters![0].nem_monthly.length ?? 13;
        let totalSavingsValues: number[] = R.repeat(0, length); // because not all columns have to be include in saving calcs

        (result.portfolio_meters as ResultPortfolioMeter[]).forEach(meter => {
          if (meter.results['battery_energy_savings'] && meter.results['battery_energy_savings'].length > 0) {
            data.push(meter.results['battery_energy_savings']);
            totalRowValues.push(meter.results['battery_energy_savings_sum'] as number);
          }
          if (meter.results['total_energy_savings'] && meter.results['total_energy_savings_sum'] != 0) {
            data.push(meter.results['total_energy_savings']);
            totalRowValues.push(meter.results['total_energy_savings_sum'] as number);
            totalSavingsValues = mergeWithAdd(totalSavingsValues, meter.results['total_energy_savings']);
          }
          if (meter.results['total_demand_savings'] && meter.results['total_demand_savings_sum'] != 0) {
            data.push(meter.results['total_demand_savings']);
            totalRowValues.push(meter.results['total_demand_savings_sum'] as number);
            totalSavingsValues = mergeWithAdd(totalSavingsValues, meter.results['total_demand_savings']);
          }
          if (meter.results['battery_nem_revenue'] && meter.results['battery_nem_revenue'].length > 0) {
            data.push(meter.results['battery_nem_revenue']);
            totalRowValues.push(meter.results['battery_nem_revenue_sum'] as number);
          }
          if (meter.results['solar_nem_revenue'] && meter.results['solar_nem_revenue'].length > 0) {
            data.push(meter.results['solar_nem_revenue']);
            totalRowValues.push(meter.results['solar_nem_revenue_sum'] as number);
          }
          if (
            meter.nem_monthly &&
            meter.results['nem_credit_revenue'] &&
            meter.results['nem_credit_revenue'].length > 0
          ) {
            data.push(meter.nem_monthly);
            totalRowValues.push(meter.results['nem_credit_revenue_sum'] as number);
            totalSavingsValues = mergeWithAdd(totalSavingsValues, meter.results['nem_credit_revenue']);
          }
        });

        data.push(totalSavingsValues);

        if (result?.portfolio_meters?.length) {
          data = [result.portfolio_meters[0].results.Months, ...data];
        }

        // transpose the matrix
        data = R.transpose(data);

        const totalPerMeterRow: (string | number)[] = ['Total Savings', ...totalRowValues, R.sum(totalSavingsValues)];

        data = [...data, totalPerMeterRow].map(row =>
          row.map(item => {
            if (typeof item == 'number') {
              return formatRevenue(item);
            }
            return item;
          })
        );

        return data;
      };
      setData(buildData(portfolio.results));
    }
  }, [portfolio.results, chartOptions]);

  return (
    <Paper className={classes.root}>
      <Toolbar style={{ borderBottom: '1px solid rgb(229, 229, 229)' }}>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          Portfolio Monthly Results
        </Typography>
      </Toolbar>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} padding="default">
          <TableHead>
            <TableRow>
              {chartOptions
                .filter(column => column.include)
                .map((item, i) => {
                  return (
                    <TableCell
                      className={item.highlight ? classes.highlight : ''}
                      style={{ backgroundColor: item.bgColor }}
                      key={i}
                    >
                      {item.label}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow key={index} hover data-testid="result-table-row">
                  {chartOptions
                    .filter(column => column.include)
                    .map((column, columnIndex) => {
                      return (
                        <TableCell className={column.highlight ? classes.highlight : ''} key={columnIndex}>
                          {row[columnIndex]}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PortfolioResultsMonthlyTableNemA;
