import React, { useState, useEffect, useContext } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { exportCSVFile } from '../../../utility/General';
import { InsertDriveFile } from '@material-ui/icons';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
//import Plot from 'react-plotly.js';
import { ScenarioInternal } from '../../../types';
import Plotly from '../../../custom-plotly.js';
import { ProposalDetailContext } from '../Detail/ProposalDetailContextProvider';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotData } from 'plotly.js';
const Plot = createPlotlyComponent(Plotly);

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  },
  flex: {
    flex: 1
  }
}));

const layout: any = {
  legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
  // height: 280,
  margin: {
    t: 20,
    l: 60,
    r: 20,
    b: 80
  },
  xaxis: {
    tickangle: -45
  },
  yaxis: {
    title: '$'
  }
};

interface ScenarioSavingsProps {
  scenarios: ScenarioInternal[];
  statusQuoScenario?: ScenarioInternal;
}

const ScenarioSavingsComparison: React.FC<ScenarioSavingsProps> = ({ scenarios, statusQuoScenario }) => {
  const classes = useStyles();
  const { selectedYears, selectedMonths, isMultiYear, validYears } = useContext(ProposalDetailContext);

  const [data, setData] = useState<Partial<PlotData>[]>([]);
  const [focusedYear, setFocusedYear] = useState(selectedYears.start);
  const [processed, setProcessed] = useState(false);

  // initailize the list of available dates
  // useEffect(() => {
  //   if (validYears.length && selectedYears.start === '0') {
  //     setSelectedYears({ start: validYears[0], end: validYears[0] });
  //   }
  // }, [validYears]);

  useEffect(() => {
    setFocusedYear(selectedYears.start);
  }, [selectedYears.start]);

  // build data
  useEffect(() => {
    const buildData = (scenarios: ScenarioInternal[], statusQuoScenario: ScenarioInternal) => {
      if (
        (processed && !isMultiYear) ||
        !scenarios ||
        scenarios.length === 0 ||
        !statusQuoScenario ||
        !statusQuoScenario.results
      ) {
        return;
      }

      scenarios = scenarios.filter(scenario => scenario.active && scenario.results);
      const statusQuoData = statusQuoScenario.results;
      const dataParts: any = [];
      let proposalMonths = statusQuoData['Months'];

      if (isMultiYear) {
        const start = moment(`${selectedYears.start}-${selectedMonths.start + 1}`, 'YYYY-MM');
        const end = moment(`${selectedYears.end}-${selectedMonths.end + 1}`, 'YYYY-MM');
        const range = moment.range(start, end);
        proposalMonths = proposalMonths.filter(date => {
          return moment(date).within(range);
        });
      }
      proposalMonths.forEach((month, index) => {
        if (isMultiYear) {
          let yearIndex = validYears.indexOf(focusedYear);
          index += yearIndex * 12;
        }
        const sqBaseline = statusQuoData['Demand Cost Total'][index] + statusQuoData['Energy Cost Total'][index];
        const entry = {
          month: month
        };
        scenarios.forEach(scenario => {
          if (!scenario.results) {
            entry[scenario.name] = 0;
          } else {
            entry[scenario.name] = +(
              sqBaseline -
              (scenario.results['Demand Cost Total'][index] + scenario.results['Energy Cost Total'][index])
            ).toFixed(2);
          }
        });
        dataParts.push(entry);
      });

      let data: Partial<PlotData>[] = [];
      scenarios.forEach(scenario => {
        data.push({
          type: 'scatter',
          name: scenario.name,
          x: dataParts.map((x: any) => moment(moment(x.month).valueOf()).format('YYYY-MM-DD')),
          y: dataParts.map((x: any) => x[scenario.name])
        } as Partial<PlotData>);
      });

      setData(data);
      setProcessed(true);
    };
    if (statusQuoScenario) {
      buildData(scenarios, statusQuoScenario);
    }
  }, [
    processed,
    scenarios,
    statusQuoScenario,
    selectedYears.start,
    selectedYears.end,
    focusedYear,
    selectedMonths.start,
    selectedMonths.end
  ]);

  const handleDownload = () => {
    exportCSVFile([], (data as unknown) as any[][], 'Scenario Savings Comparison');
  };

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          Savings By Scenario
        </Typography>
        {/* {isMultiYear && (
          <FormControl>
            <InputLabel id="select-year-savings-label">Year{'  '}</InputLabel>
            <Select
              labelId="select-year-savings-label"
              value={focusedYear}
              onChange={(e: any) => setFocusedYear(e.target.value)}
            >
              {validYears.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )} */}
        <IconButton aria-label="Download" className="button-white" onClick={handleDownload}>
          <InsertDriveFile />
        </IconButton>
      </Toolbar>
      <div style={{ height: 350 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={data}
          layout={layout}
          config={{ displayModeBar: false, responsive: true }}
        />
      </div>
    </Paper>
  );
};

export default ScenarioSavingsComparison;
