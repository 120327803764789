import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { getMeterInterval } from '../../actions/utilityapi';
import ListSearchInput from '../Table/ListSearchInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createLoadingSelector } from '../../selectors';
import { StoreState } from '../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  container: {
    padding: 20
  },
  flex: {
    flex: 1
  },
  table: {
    minWidth: 700
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  },
  search: {
    borderBottom: '1px solid #e5e5e5'
  },
  loadingContainer: {
    textAlign: 'center',
    padding: 25
  }
}));

interface UtilityAPIMeterProps {
  utilityAPIAccount: string;
  utilityAPIAccounts: string[];
  handleMeterSelection: any;
  handleUtilityAPIAccountUpdate: any;
}

const UtilityAPIMeters: React.FC<UtilityAPIMeterProps> = ({
  utilityAPIAccount,
  utilityAPIAccounts,
  handleMeterSelection,
  handleUtilityAPIAccountUpdate
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const meters = useSelector((state: StoreState) => state.meters);
  const loadingSelector = createLoadingSelector(['GET_METERS', 'GET_METER_INTERVAL']);
  const isLoading = useSelector((state: StoreState) => loadingSelector(state));

  const filterBySearch = (item: any) => {
    if (!search) {
      return true;
    }

    if (
      (item.utility && item.utility.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
      (item.base &&
        item.base.service_tariff &&
        item.base.service_tariff.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
      (item.base &&
        item.base.service_address &&
        item.base.service_address.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
      (item.base &&
        item.base.billing_contact &&
        item.base.billing_contact.toLowerCase().indexOf(search.toLowerCase()) > -1)
    ) {
      return true;
    }
    return false;
  };

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const getCoverage = (meter: any) => {
    if (!meter.interval_coverage || meter.interval_coverage.length === 0) {
      return [];
    }
    const startDate = meter.interval_coverage[0][0];
    const endDate = meter.interval_coverage[meter.interval_coverage.length - 1][1];
    return [startDate, endDate];
  };

  // .format('MM-DD-YYYY') +'-' +

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <Typography color="inherit" variant="h6" className={classes.flex}>
          Select Meter
        </Typography>
        <FormControlLabel
          style={{ marginLeft: 4, marginTop: 16, marginBottom: 16 }}
          label="Select an Account:"
          labelPlacement="start"
          control={
            <Select
              style={{ marginLeft: 8 }}
              name="utilityAPIAccount"
              value={utilityAPIAccount}
              onChange={e => handleUtilityAPIAccountUpdate(e.target.value)}
            >
              {utilityAPIAccounts.map(account => {
                return (
                  <MenuItem key={account} value={account}>
                    {account}
                  </MenuItem>
                );
              })}
            </Select>
          }
        />
        <ListSearchInput handleSearchChange={handleSearchChange} search={search} />
      </Toolbar>
      {isLoading && (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={30} />
        </div>
      )}
      {!isLoading && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Utility</TableCell>
              <TableCell>Tariff Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Service Address</TableCell>
              <TableCell>Coverage</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {meters.filter(filterBySearch).map((n: any) => {
              const [start, end] = getCoverage(n);
              if (!start || !end || getCoverage(n) == []) {
                return null;
              }
              return (
                <TableRow key={n.uid} hover style={{ cursor: 'pointer' }} onClick={handleMeterSelection(n.uid)}>
                  <TableCell component="th" scope="row">
                    {n.utility}
                  </TableCell>
                  <TableCell>{n.base.service_tariff}</TableCell>
                  <TableCell>{n.base.billing_contact}</TableCell>
                  <TableCell>{n.base.service_address}</TableCell>
                  <TableCell>{`${moment(start).format('MM-DD-YYYY')} - ${moment(end).format('MM-DD-YYYY')}`}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default UtilityAPIMeters;
