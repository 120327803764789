import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  block: {
    marginTop: 8,
    marginBottom: 8
  }
});

interface ComparisonSettingsProps {
  open: boolean;
  handleClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  labels: { statusQuoLabel: string; scenarioLabel: string };
  handleChange: (s: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  toggleValues: React.MouseEventHandler<HTMLButtonElement>;
  showUsage: boolean;
  keys: string[];
  handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDemand: boolean;
}

const ComparisonSettings: React.FC<ComparisonSettingsProps> = props => {
  const { open, handleClose, labels, handleChange, toggleValues, showUsage, keys, handleCheck, isDemand } = props;
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
      <DialogTitle id="form-dialog-title">Chart Settings</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.block}
              label="StatusQuo Label"
              value={labels.statusQuoLabel}
              onChange={handleChange('statusQuoLabel')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.block}
              label="Scenario Label"
              value={labels.scenarioLabel}
              onChange={handleChange('scenarioLabel')}
            />
          </Grid>
        </Grid>
        <IconButton onClick={toggleValues}>
          {isDemand && (
            <>
              {showUsage && <span>kW</span>}
              {!showUsage && <span>$</span>}
            </>
          )}
          {!isDemand && (
            <>
              {showUsage && <span>kWh</span>}
              {!showUsage && <span>$</span>}
            </>
          )}
        </IconButton>
        {showUsage && (
          <>
            {isDemand && (
              <React.Fragment>
                <Checkbox name="Demand Max NC" checked={keys.includes('Demand Max NC')} onChange={handleCheck} />{' '}
                Non-Coincidental
                <Checkbox
                  name="Demand Max Peak"
                  checked={keys.includes('Demand Max Peak')}
                  onChange={handleCheck}
                />{' '}
                Peak
                <Checkbox
                  name="Demand Max MidPeak"
                  checked={keys.includes('Demand Max MidPeak')}
                  onChange={handleCheck}
                />{' '}
                Mid-Peak
              </React.Fragment>
            )}
            {!isDemand && (
              <React.Fragment>
                <Checkbox name="Energy Use Peak" checked={keys.includes('Energy Use Peak')} onChange={handleCheck} />{' '}
                Peak
                <Checkbox
                  name="Energy Use MidPeak"
                  checked={keys.includes('Energy Use MidPeak')}
                  onChange={handleCheck}
                />{' '}
                Mid-Peak
                <Checkbox
                  name="Energy Use OffPeak"
                  checked={keys.includes('Energy Use OffPeak')}
                  onChange={handleCheck}
                />{' '}
                Off-Peak
              </React.Fragment>
            )}
          </>
        )}
        {!showUsage && (
          <React.Fragment>
            {isDemand && (
              <>
                <Checkbox name="Demand Cost NC" checked={keys.includes('Demand Cost NC')} onChange={handleCheck} />{' '}
                Non-Coincidental
                <Checkbox
                  name="Demand Cost Peak"
                  checked={keys.includes('Demand Cost Peak')}
                  onChange={handleCheck}
                />{' '}
                Peak
                <Checkbox
                  name="Demand Cost MidPeak"
                  checked={keys.includes('Demand Cost MidPeak')}
                  onChange={handleCheck}
                />{' '}
                Mid-Peak
              </>
            )}
            {!isDemand && (
              <>
                <Checkbox name="Energy Cost Peak" checked={keys.includes('Energy Cost Peak')} onChange={handleCheck} />{' '}
                Peak
                <Checkbox
                  name="Energy Cost MidPeak"
                  checked={keys.includes('Energy Cost MidPeak')}
                  onChange={handleCheck}
                />{' '}
                Mid-Peak
                <Checkbox
                  name="Energy Cost OffPeak"
                  checked={keys.includes('Energy Cost OffPeak')}
                  onChange={handleCheck}
                />{' '}
                Off-Peak
              </>
            )}
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ComparisonSettings;
