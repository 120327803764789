import { combineReducers } from 'redux';
import TariffListReducer from './tariffs/tariff_list_reducer';
import ManualTariffListReducer from './tariffs/tariff_manual_list_reducer';
import TariffFilterReducer from './tariffs/tariff_filter_reducer';
import TariffReducer from './tariffs/tariff_reducer';
import TariffCreateReducer from './tariffs/tariff_new_reducer';
import UtilityAPIMeterListReducer from './utilityapi/utilityapi_meter_list_reducer';
import UtilityAPIIntervalReducer from './utilityapi/utilityapi_interval_reducer';
import ScenarioListReducer from './scenarios/scenario_list_reducer';
import ScenarioReducer from './scenarios/scenario_reducer';
import ScenarioPresentationReducer from './scenarios/scenario_presentation_reducer';
import ScenarioCreateReducer from './scenarios/scenario_new_reducer';
import CustomerListReducer from './customers/customer_list_reducer';
import CustomerReducer from './customers/customer_reducer';
import ProposalListReducer from './proposals/proposal_list_reducer';
import ProposalsRecentReducer from './proposals/proposal_list_recent_reducer';
import ProposalCreateReducer from './proposals/proposal_new_reducer';
import ProposalReducer from './proposals/proposal_reducer';
import LoadingReducer from './helpers/loading_reducer';
import ErrorReducer from './helpers/error_reducer';
import SuccessReducer from './helpers/success_reducer';
import UtilityReducer from './utilities/utility_reducer';
import UtilityListReducer from './utilities/utility_list_reducer';
import GlobalSettingReducer from './globalsettings/global_setting_reducer';
import GlobalSettingListReducer from './globalsettings/global_setting_list_reducer';
import LoadProfileReducer from './explore/load_profile_reducer';
import SolarProfileReducer from './explore/solar_profile_reducer';
import LMPDAProfileReducer from './explore/lmp_profile_reducer';
import LMPRTProfileReducer from './explore/lmp_realtime_profile_reducer';
import MEFProfileReducer from './explore/mef_profile_reducer';
import LoadSummaryReducer from './explore/load_summary_reducer';
import LoadClusterAnalysisReducer from './explore/load_cluster_analysis_reducer';
import LoadForecastingAnalysisReducer from './explore/load_forecasting_analysis_reducer';
import BillingAnalysisReducer from './explore/load_billing_reducer';
import GenabilityBillingReducer from './explore/genability_billing_reducer';
import ExploreDetailsMetaReducer from './explore/explore_details_meta_reducer';
import ExploreScenariosReducer from './explore/explore_scenarios_reducer';
import ExploreTariffsReducer from './explore/explore_tariffs_reducer';
import ExploreCalculationsReducer from './explore/explore_calculations_reducer';
import BreadcrumbsReducer from './breadcrumbs/breadcrumbs_reducer';
import UserPermissionsReducer from './userPermissions/user_permissions_reducer';
import TemplateReducer from './templates/template_reducer';
import {
  Customer,
  ProposalInternal,
  ScenarioInternal,
  ESAPTariff,
  ExploreDetailsMeta,
  Favorite,
  Portfolio,
  LSEResult,
  GenabilityLSEs,
  ScenarioPresentation,
  UtilityForm,
  LoadProfile,
  FrequentUsedTariff,
} from '../types';
import { Template } from '../components/Utilities/Templates/Template';
import CleanedDataReducer from './cleaned_data/cleaned_data_reducer';
import UsersListReducer from './user/user_list_reducer';
import ProposalsListFiltersReducer from './proposals/proposal_list_filters_reducer';
import ProposalsMetaReducer from './proposals/proposal_list_meta_reducer';
import UsedTariffReducer from './tariffs/used_tariffs_reducer';
import FrequentlyUsedTariffReducer from './tariffs/frequently_used_tariffs_reducer';
import LocaleReducer from './locale/locale_reducer';
import SimpleUtilityListReducer from './utilities/simplified_utility_list_reducer';
import FavoritesReducer from './favorites/favorites_reducer';
import PortfoliosListReducer from './portfolios/portfolios_list_reducer';
import PortfoliosListMetaReducer from './portfolios/portfolios_list_meta_reducer';
import PortfoliosListFiltersReducer from './portfolios/portfolios_list_filters_reducer';
import PortfolioNewReducer from './portfolios/portfolio_new_reducer';
import PortfolioReducer from './portfolios/portfolio_reducer';
import utilitiesListMetaReducer from './utilities/utilities_list_meta_reducer';
import eventsReducer from './events/events_reducer';
import ev1ProfileReducer from './explore/ev1_profile_reducer';
import ev2ProfileReducer from './explore/ev2_profile_reducer';

export type SettingKey =
  | 'Net Benefits Test'
  | 'GA Info'
  | 'GA Costs'
  | 'DR Events'
  | 'GA Events'
  | 'GA Events Predicted'
  | 'ICAP'
  | 'JCPL'
  | 'PSEG'
  | 'ACE'
  | 'REC'
  | 'CAP'
  | 'COMM%20CREDIT'
  | 'TOTAL_LOSS_TD'
  | 'COMM_CREDIT'
  | 'ISONE CPS';

export interface Setting {
  public_id: string;
  old_id: string | null;
  updated_by: string | null;
  updated_on: string | null;
  value: Array<(string | number)[]>;
  is_editable: boolean;
  created_on: string | null;
  updated_user: string | null;
  key: SettingKey;
  created_user: string | null;
  id: number;
  enabled: boolean;
  created_by: string | null;
}

// export interface Settings {
//   GAEvents?: Array<[string, number, number]>;
//   GAEventsPredicted?: Array<[string, number, number]>;
// }
export interface StoreState {
  loading: any;
  errorStatus: any;
  successStatus: any;
  tariffs: ESAPTariff[];
  manualTariffs: ESAPTariff[];
  tariffFilters: any;
  tariff: ESAPTariff;
  meters: any;
  interval: any;
  scenarios: ScenarioInternal[];
  scenario: ScenarioInternal;
  scenarioPresentation: ScenarioPresentation;
  customers: Customer[];
  customer: Customer;
  portfolios: Portfolio[];
  newPortfolio: Portfolio;
  portfolio: Portfolio;
  portfoliosMeta: {
    page: number;
    pageSize: number;
    total: number;
  };
  portfoliosListFilters: any;
  proposals: ProposalInternal[];
  proposalsRecent: ProposalInternal[];
  proposalsMeta: {
    page: number;
    pageSize: number;
    total: number;
  };
  newProposal: ProposalInternal;
  proposal: ProposalInternal;
  newScenario: any;
  utilities: LSEResult[];
  utilitiesMeta: Omit<GenabilityLSEs, 'results'>;
  utility: UtilityForm;
  newTariff: ESAPTariff;
  setting: Setting;
  settings: Setting[];
  events: Events;
  loadProfile: LoadProfile;
  solarProfile: LoadProfile;
  lmpDAProfile: LoadProfile;
  lmpRTProfile: LoadProfile;
  mefProfile: LoadProfile;
  ev1Profile: LoadProfile;
  ev2Profile: LoadProfile;
  loadSummary: any;
  loadClustering: any;
  forecastingInfo: any;
  billingInfo: any;
  genabilityBillingInfo: any;
  exploreDetailsMetaInfo: ExploreDetailsMeta;
  exploreScenarios: any;
  exploreTariffs: any;
  exploreCalculations: any;
  breadcrumbsTrail: { label: string; link: string }[];
  userPermissions: any;
  template: Template;
  cleanData: {
    start: string;
    data: number[];
    frequency: string;
    logs: string;
  };
  users: any;
  proposalsListFilters: any;
  usedTariffs: string[];
  frequentlyUsedTariffs: FrequentUsedTariff[];
  locale: string;
  simpleUtilityList: { name: string; lseId: number }[];
  favorites: {
    proposals: Favorite[];
    proposal_scenarios: Favorite[];
  };
}

export interface Events {
  GAEvents?: string[];
  GAEventsPredicted?: string[];
}

const rootReducer = combineReducers<() => StoreState>({
  loading: LoadingReducer,
  errorStatus: ErrorReducer,
  successStatus: SuccessReducer,
  tariffs: TariffListReducer,
  manualTariffs: ManualTariffListReducer,
  tariffFilters: TariffFilterReducer,
  tariff: TariffReducer,
  meters: UtilityAPIMeterListReducer,
  interval: UtilityAPIIntervalReducer,
  scenarios: ScenarioListReducer,
  scenario: ScenarioReducer,
  scenarioPresentation: ScenarioPresentationReducer,
  customers: CustomerListReducer,
  customer: CustomerReducer,
  proposals: ProposalListReducer,
  proposalsRecent: ProposalsRecentReducer,
  proposalsMeta: ProposalsMetaReducer,
  newProposal: ProposalCreateReducer,
  portfolios: PortfoliosListReducer,
  newPortfolio: PortfolioNewReducer,
  portfolio: PortfolioReducer,
  portfoliosMeta: PortfoliosListMetaReducer,
  portfoliosListFilters: PortfoliosListFiltersReducer,
  proposal: ProposalReducer,
  newScenario: ScenarioCreateReducer,
  utilities: UtilityListReducer,
  utilitiesMeta: utilitiesListMetaReducer,
  utility: UtilityReducer,
  newTariff: TariffCreateReducer,
  setting: GlobalSettingReducer,
  settings: GlobalSettingListReducer,
  events: eventsReducer,
  loadProfile: LoadProfileReducer,
  solarProfile: SolarProfileReducer,
  lmpDAProfile: LMPDAProfileReducer,
  lmpRTProfile: LMPRTProfileReducer,
  mefProfile: MEFProfileReducer,
  ev1Profile: ev1ProfileReducer,
  ev2Profile: ev2ProfileReducer,
  loadSummary: LoadSummaryReducer,
  loadClustering: LoadClusterAnalysisReducer,
  forecastingInfo: LoadForecastingAnalysisReducer,
  billingInfo: BillingAnalysisReducer,
  genabilityBillingInfo: GenabilityBillingReducer as any,
  exploreDetailsMetaInfo: ExploreDetailsMetaReducer,
  exploreScenarios: ExploreScenariosReducer,
  exploreTariffs: ExploreTariffsReducer,
  exploreCalculations: ExploreCalculationsReducer,
  breadcrumbsTrail: BreadcrumbsReducer,
  userPermissions: UserPermissionsReducer,
  template: TemplateReducer,
  cleanData: CleanedDataReducer,
  users: UsersListReducer,
  proposalsListFilters: ProposalsListFiltersReducer,
  usedTariffs: UsedTariffReducer,
  frequentlyUsedTariffs: FrequentlyUsedTariffReducer,
  locale: LocaleReducer,
  simpleUtilityList: SimpleUtilityListReducer,
  favorites: FavoritesReducer
});

export default rootReducer;
