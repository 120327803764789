import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface RemoteDataRendererProps<Data> {
  onSuccess: (data?: Data) => React.ReactNode;
  onIdle?: () => React.ReactNode;
  onLoading?: () => React.ReactNode;
  onError?: () => React.ReactNode;
  data?: Data;
  status: 'idle' | 'error' | 'loading' | 'success';
  spinnerSize?: number;
  error?: { message: string } | null;
  customErrorMsg: string;
}

const RemoteDataRenderer = <Data extends object>({
  onSuccess,
  onIdle,
  onLoading,
  onError,
  data,
  status,
  spinnerSize,
  error,
  customErrorMsg
}: RemoteDataRendererProps<Data>) => {
  return (
    <>
      {(() => {
        switch (status) {
          case 'idle':
            return onIdle ? onIdle() : <div />;
          case 'loading':
            return onLoading ? (
              onLoading()
            ) : (
              <div style={{ textAlign: 'center', padding: 25 }}>
                <CircularProgress color="secondary" size={spinnerSize ?? 50} />
              </div>
            );
          case 'error':
            return onError ? (
              onError()
            ) : (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {customErrorMsg} - {error ? error.message : ''}
              </Alert>
            );
          case 'success':
            return <> {data ? onSuccess(data) : onSuccess()}</>;
        }
      })()}
    </>
  );
};

export default RemoteDataRenderer;
