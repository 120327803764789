import { RUN_CLUSTERING_SUCCESS } from '../../actions/explore';

const INITIAL_STATE = { clusters: [], counts: {} };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RUN_CLUSTERING_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
