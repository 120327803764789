import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { Template, ScenarioKeys, ScenarioTemplate } from '../Utilities/Templates/Template';
import { ProposalInternal } from '../../types';
import Drawer from '@material-ui/core/Drawer';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';

import { updateTemplateScenarios } from '../../actions/templates';

interface Props {
  open: boolean;
  handleEditClose: () => void;
  template: Template;
  proposal: ProposalInternal;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 40
    },
    block: {}
  })
);

type Action = { type: keyof IState; payload: any };

const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case ScenarioKeys.standardStatusQuo:
      return { ...state, [ScenarioKeys.standardStatusQuo]: action.payload };
    case ScenarioKeys.solarTariffStatusQuo:
      return { ...state, [ScenarioKeys.solarTariffStatusQuo]: action.payload };
    case ScenarioKeys.solarOnlyStandardTariff:
      return {
        ...state,
        [ScenarioKeys.solarOnlyStandardTariff]: action.payload
      };
    case ScenarioKeys.solarOnlySolarTariff:
      return {
        ...state,
        [ScenarioKeys.solarOnlySolarTariff]: action.payload
      };
    case ScenarioKeys.solarStorageForced:
      return { ...state, [ScenarioKeys.solarStorageForced]: action.payload };
    case ScenarioKeys.solarStorageNonForced:
      return { ...state, [ScenarioKeys.solarStorageNonForced]: action.payload };
    case 'reset':
      return action.payload;
    default:
      throw new Error();
  }
};

interface IState {
  reset: string;
  [ScenarioKeys.standardStatusQuo]: string;
  [ScenarioKeys.solarTariffStatusQuo]: string;
  [ScenarioKeys.solarOnlyStandardTariff]: string;
  [ScenarioKeys.solarOnlySolarTariff]: string;
  [ScenarioKeys.solarStorageForced]: string;
  [ScenarioKeys.solarStorageNonForced]: string;
}

const EditTemplateModal: React.FC<Props> = props => {
  const { open, handleEditClose, template, proposal } = props;
  const classes = useStyles();
  const dispatchAction = useDispatch();

  const initialState = template.scenario_templates.reduce((acc, curr) => {
    acc = { ...acc, [curr.key as keyof IState]: curr.reference_id as string };
    return acc;
  }, {}) as IState;

  const [state, dispatch] = useReducer(reducer, initialState);

  const mapScenario = {
    [ScenarioKeys.standardStatusQuo]: 'Status Quo - Standard Tariff',
    [ScenarioKeys.solarTariffStatusQuo]: 'Status Quo - Solar Tariff',
    [ScenarioKeys.solarOnlyStandardTariff]: 'Solar Only - Standard Tariff',
    [ScenarioKeys.solarOnlySolarTariff]: 'Solar Only - Solar Tariff',
    [ScenarioKeys.standardStorageForced]: 'Force Storage - Standard Tariff',
    [ScenarioKeys.standardStorageNonForced]: 'Non-Force Storage - Standard Tariff',
    [ScenarioKeys.solarStorageForced]: 'Force Storage - Solar Tariff',
    [ScenarioKeys.solarStorageNonForced]: 'Non-Force Storage - Solar Tariff'
  };

  const handleMenuChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    dispatch({
      type: event.target.name as keyof IState,
      payload: event.target.value as string
    });
  };

  const renderDropDown = (key: string, scenarioId: string | null) => {
    const scenario = proposal.proposal_scenarios.find(el => el.public_id === scenarioId);
    //if (!scenario) return null;
    return (
      <Select
        value={scenario && scenario.public_id ? scenario.public_id : ''}
        onChange={handleMenuChange}
        inputProps={{
          name: key,
          id: 'scenario-select'
        }}
      >
        {proposal.proposal_scenarios.map(scenario => {
          return (
            <MenuItem key={proposal.public_id} value={scenario.public_id as string}>
              {scenario.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const handleCancel = () => {
    dispatch({ type: 'reset', payload: initialState });
    handleEditClose();
  };

  const handleSave = () => {
    const updatedTemplate = { ...template };
    const updateScenarios: ScenarioTemplate[] = [];
    updatedTemplate.scenario_templates.forEach(scenario => {
      const newId: string = state[scenario.key];
      updateScenarios.push({ ...scenario, reference_id: newId });
    });
    dispatchAction(updateTemplateScenarios(updateScenarios, proposal.public_id, template.public_id as string));
    handleEditClose();
  };
  return (
    <Drawer
      aria-labelledby="Edit Template Modal"
      aria-describedby="Edit Scenarios selected in a Template"
      open={open}
      onClose={handleEditClose}
    >
      <div className={classes.container}>
        <DialogTitle id="simple-dialog-title" style={{ marginBottom: 24 }}>
          {' '}
          {template.name}
        </DialogTitle>
        <div className={classes.block}>
          <Typography variant="h6" gutterBottom style={{ marginLeft: 16 }}>
            Assign scenarios:
          </Typography>
          <List>
            {Object.keys(state).map((scenario, index) => (
              <ListItem key={index} style={{ marginRight: 24 }}>
                <ListItemText primary={mapScenario[scenario]} />
                {renderDropDown(scenario, state[scenario])}
              </ListItem>
            ))}
          </List>
        </div>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" aria-label="cancel">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus aria-label="confirm">
            Update
          </Button>
        </DialogActions>
      </div>
    </Drawer>
  );
};

export default EditTemplateModal;
