import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { updateExplorePageInformation } from '../../actions/explore';

const displayOptions = [
  {
    key: 'showMonthly',
    display: 'Show Monthly Average Profiles'
  },
  {
    key: 'showSeasonal',
    display: 'Show Seasonal Average Profiles'
  },
  {
    key: 'showTOU',
    display: 'Show TOU Analysis'
  },
  {
    key: 'showPeak',
    display: 'Show Peak Analysis'
  }
];

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
});
class ExploreDetailConfig extends React.Component {
  handleToggle = item => () => {
    this.props.updateExplorePageInformation({
      [item.key]: !this.props.exploreDetailsMetaInfo[item.key]
    });
  };

  render() {
    const { exploreDetailsMetaInfo, classes } = this.props;
    return (
      <List className={classes.root}>
        {displayOptions.map(item => (
          <ListItem key={item.key} dense button onClick={this.handleToggle(item)}>
            <Checkbox checked={exploreDetailsMetaInfo[item.key]} tabIndex={-1} disableRipple />
            <ListItemText primary={item.display} />
          </ListItem>
        ))}
      </List>
    );
  }
}

ExploreDetailConfig.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    exploreDetailsMetaInfo: state.exploreDetailsMetaInfo
  };
};

export default connect(mapStateToProps, { updateExplorePageInformation })(withStyles(styles)(ExploreDetailConfig));
