import React, { useState } from 'react';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepButton from '@material-ui/core/StepButton';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  activeLabel: {
    color: theme.palette.secondary.main
  }
}));
interface WizardProps {
  onFinished: any;
}

const Wizard: React.FC<WizardProps> = props => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set<number>());
  const classes = useStyles();

  const totalStepsCount = (): number => {
    return React.Children.count(props.children);
  };

  const isStepComplete = (step: number): boolean => {
    return completedSteps.has(step);
  };

  const completedStepsCount = (): number => {
    return completedSteps.size;
  };

  const allStepsCompleted = (): boolean => {
    return completedStepsCount() === totalStepsCount();
  };

  const isLastStep = (): boolean => {
    return activeStep === totalStepsCount() - 1;
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNext = (): void => {
    let newActiveStep;
    if (isLastStep() && !allStepsCompleted() && props.children) {
      newActiveStep = React.Children.map(props?.children as React.ReactChildren, x => x).findIndex(
        (_, i) => !completedSteps.has(i)
      );
    } else {
      newActiveStep = activeStep + 1;
    }
    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    completedSteps.add(activeStep);
    setCompletedSteps(completedSteps);
    if (completedSteps.size !== totalStepsCount()) {
      handleNext();
    } else {
      props.onFinished();
    }
  };
  return (
    <React.Fragment>
      <Stepper activeStep={activeStep} nonLinear>
        {React.Children.map<React.ReactNode, React.ReactNode>(props.children, (child, index) => {
          return (
            <Step key={index}>
              <StepButton onClick={handleStep(index)} completed={isStepComplete(index)}>
                <span className={activeStep === index ? classes.activeLabel : undefined}>
                  {React.isValidElement(child) && child?.props?.label ? child.props.label : 'No label provided'}
                </span>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {React.Children.map<React.ReactNode, React.ReactNode>(props.children, (child, index) => {
        if (index === activeStep && React.isValidElement(child)) {
          return React.cloneElement(child, {
            handleNext: handleComplete
          });
        }

        return null;
      })}
    </React.Fragment>
  );
};

export default Wizard;
