import { formatDate } from './Dates';

export const customerColumnSchema = [
  { key: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { key: 'website', numeric: false, disablePadding: false, label: 'Website' },
  { key: ['address', 'city'], numeric: false, disablePadding: false, label: 'City' },
  { key: ['address', 'state'], numeric: false, disablePadding: false, label: 'State' },
  { key: ['address', 'zip_code'], numeric: true, disablePadding: false, label: 'Zip' },
  { key: 'created_on', numeric: false, disablePadding: false, label: 'Created', transform: formatDate }
];
