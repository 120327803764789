import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MATERIAL UI IMPORTS
import { Save } from '@material-ui/icons';
import CustomerContainer from '../../Customers/Create/CustomerContainer';
import CustomerList from '../../Customers/List/CustomerList';
import { getAllCustomers } from '../../../actions/customers';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import { updateProposal } from '../../../actions/proposals';
import { selectCustomers } from '../../../actions/customers';
import AccessControl from '../../../utility/AccessControl';
import { StoreState } from '../../../reducers';
import { Customer } from '../../../types';

interface ProposalCustomerContainerProps {
  handleNext?: any;
}

const ProposalCustomerContainer: React.FC<ProposalCustomerContainerProps> = props => {
  const dispatch = useDispatch();

  const [view, setView] = useState<'select' | 'create'>('select');

  const proposal = useSelector((state: StoreState) => state.newProposal);
  const customers = useSelector((state: StoreState) => state.customers);

  useEffect(() => {
    if (!customers || customers.length === 0) {
      dispatch(getAllCustomers());
    }
  }, [customers, dispatch]);

  const handleSave = () => {
    if (proposal.public_id && proposal.customer && proposal.customer.public_id) {
      dispatch(
        updateProposal({
          ...proposal,
          customer_id: proposal.customer.public_id
        })
      );
    }
    if (props.handleNext) {
      props.handleNext();
    }
  };

  const handleSelectCustomer = (customer: Customer) => {
    dispatch(selectCustomers(customer));
  };

  const selectedCustomers: Customer[] = proposal && proposal.customer ? [proposal.customer] : [];

  const isSelected = (customer: Customer): boolean => selectedCustomers.indexOf(customer) !== -1;

  const isSaveDisabled: boolean = view === 'select' && (!proposal.customer || !proposal.customer.public_id);

  return (
    <div>
      <AccessControl
        requiredPermissions={['editor', 'admin']}
        renderNoAccess={() => <div>No Access. Permission denied.</div>}
      >
        <>
          {view === 'select' && (
            <div>
              <div className="button-container">
                <Fab
                  size="small"
                  color="secondary"
                  aria-label="Save"
                  className="button-white"
                  onClick={handleSave}
                  disabled={isSaveDisabled}
                >
                  <Save />
                </Fab>
              </div>
              <div style={{ textAlign: 'right', marginTop: -15 }}>
                <Button onClick={() => setView('create')} variant="contained" color="primary" style={{ margin: 10 }}>
                  Create New Customer
                </Button>
              </div>
              <CustomerList
                customers={customers}
                handleRowClick={handleSelectCustomer}
                isSelected={isSelected}
                selected={selectedCustomers}
              />
            </div>
          )}
          {view === 'create' && (
            <div>
              <div style={{ textAlign: 'right', marginTop: -15 }}>
                <Button onClick={() => setView('select')} variant="contained" color="primary" style={{ margin: 10 }}>
                  Cancel
                </Button>
              </div>
              <CustomerContainer
                handleSave={handleSave}
                proposal={proposal}
                handleNext={props.handleNext}
                handleSelectCustomer={handleSelectCustomer}
              />
            </div>
          )}
        </>
      </AccessControl>
    </div>
  );
};

export default ProposalCustomerContainer;
