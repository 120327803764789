import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

interface ConfirmProps {
  onConfirmClose: (confirm: boolean) => (e: any) => void;
  open: boolean;
  message?: string;
}

const ConfirmPopUp: React.FC<ConfirmProps> = props => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onConfirmClose(false)}
      aria-labelledby="alert-dialog-delete"
      aria-describedby="alert-dialog-delete-item"
    >
      <DialogContent
        id="alert-dialog-title"
        style={{ textAlign: 'center', fontSize: '17px', fontWeight: 500, padding: '30px 40px 40px' }}
      >
        {props.message}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onConfirmClose(false)} aria-label="cancel">
          Cancel
        </Button>
        <Button variant="contained" onClick={props.onConfirmClose(true)} color="primary" autoFocus aria-label="confirm">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPopUp;
