import { useEffect } from 'react';
import { clsx } from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';


import { resetBreadcrumb, pushBreadcrumb } from '../../actions/breadcrumbs';
import { useStyles } from './Index.styles';
import FavoriteAndFrequentTariffs from './_components/FavoriteAndFrequentTariffs';
import TariffsGridList from './_components/TariffsGridList';
import { ESAPTariff } from '../../types';

const TariffHome = (): JSX.Element => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Tariffs', '/tariffs'));
  }, [dispatch]);

  const handleTariffOnClick = ({ tariff_id, source_id, effective_date, public_id }: ESAPTariff, isManual?: boolean) => {
    if (isManual) {
      history.push(`/tariffs/${public_id}?manual=true&effectiveOn=${effective_date}`);
      return;
    }

    const tariffId = source_id ?? tariff_id;

    history.push(`/tariffs/${tariffId}?effectiveOn=${effective_date}`);
  }

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <div>
            <h2 data-test="header|title" className={clsx([styles.title, styles.textLeft])}>Tariff</h2>
            <p className={styles.my0}>Select a tariff bellow</p>
          </div>
          <div className={styles.flex}>
          </div>
        </div>

        <FavoriteAndFrequentTariffs handleTariffOnClick={handleTariffOnClick} />
      </div>

      <TariffsGridList onTariffClick={handleTariffOnClick} />
    </div>
  );
}

export default TariffHome;
