import {
  GET_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_METER,
  PORTFOLIO_INPUT_FORM_CHANGE,
  UPDATE_PORTFOLIO_SUCCESS
} from '../../actions/portfolios';
import { Portfolio } from '../../types';

const INITIAL_STATE = {} as Portfolio;

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_PORTFOLIO_SUCCESS:
    case UPDATE_PORTFOLIO_SUCCESS:
      return action.payload;
    case PORTFOLIO_INPUT_FORM_CHANGE:
      return { ...state, ...action.payload };
    case UPDATE_PORTFOLIO_METER:
      return { ...state, portfolio_meters: action.payload };
    default:
      return state;
  }
}
