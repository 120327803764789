import {
  GET_ALL_PROPOSALS_SUCCESS,
  GET_PROPOSAL_SUCCESS,
  CREATE_PROPOSAL_SUCCESS,
  RESET_PROPOSAL_LIST,
  DELETE_PROPOSAL_SUCCESS,
  UPDATE_PROPOSAL_SUCCESS,
  ARCHIVE_PROPOSAL_SUCCESS,
  COPY_PROPOSAL_SUCCESS
} from '../../actions/proposals';

const INITIAL_STATE = [];

export default function proposalListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_PROPOSAL_SUCCESS:
    case GET_PROPOSAL_SUCCESS:
    case ARCHIVE_PROPOSAL_SUCCESS:
      const newState = state.filter(proposal => proposal.public_id !== action.payload.public_id);
      return [action.payload, ...newState];
    case GET_ALL_PROPOSALS_SUCCESS:
      return [...action.payload.proposals];
    case CREATE_PROPOSAL_SUCCESS:
      return [action.payload].concat(state);
    case RESET_PROPOSAL_LIST:
      return INITIAL_STATE;
    case DELETE_PROPOSAL_SUCCESS:
      let deleteIndex = state.findIndex(proposal => proposal.public_id === action.payload);
      if (deleteIndex > -1) {
        let newSelected = [].concat(state.slice(0, deleteIndex), state.slice(deleteIndex + 1));
        return newSelected;
      } else {
        return state;
      }
    case COPY_PROPOSAL_SUCCESS:
      return [action.payload].concat(state);
    default:
      return state;
  }
}
