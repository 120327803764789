import React, { useEffect } from 'react';
import ProposalData from './ProposalData';
import { getRequiredFlags } from '../../../utility/Proposal';
import { uploadDataFiles } from '../../../actions/proposals';
import { updateExplorePageInformationSilently } from '../../../actions/explore';
import AccessControl from '../../../utility/AccessControl';
import { DataType, ProposalInternal, DataRequired, WizardChild } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../reducers';

// MATERIAL UI IMPORTS
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Save from '@material-ui/icons/Save';
import Fab from '@material-ui/core/Fab';

export interface DataSet {
  type: DataType;
  historicalData: [string, string, number][];
  scaler: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    }
  })
);

interface ProposalDataContainerProps {
  newProposal: ProposalInternal;
}

const ProposalDataContainer: React.FC<WizardChild<ProposalDataContainerProps>> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loadProfile = useSelector((state: StoreState) => state.loadProfile);
  const solarProfile = useSelector((state: StoreState) => state.solarProfile);
  const lmpDAProfile = useSelector((state: StoreState) => state.lmpDAProfile);
  const lmpRTProfile = useSelector((state: StoreState) => state.lmpRTProfile);
  const mefProfile = useSelector((state: StoreState) => state.mefProfile);
  const ev1Profile = useSelector((state: StoreState) => state.ev1Profile);
  const ev2Profile = useSelector((state: StoreState) => state.ev2Profile);

  useEffect(() => {
    dispatch(
      updateExplorePageInformationSilently({
        showMonthly: false,
        showSeasonal: true,
        showTOU: false,
        showPeak: true
      })
    );
  }, []);

  const handleProposalDataSave = (required: DataRequired) => () => {
    const dataSets: DataSet[] = [
      {
        type: DataType.load,
        historicalData: loadProfile,
        scaler: 1
      }
    ];
    if (required.solar) {
      dataSets.push({
        type: DataType.solar,
        historicalData: solarProfile,
        scaler: Math.max(...solarProfile.map(item => item[2])) // should probably have only once source of truth for this calc
      });
    }
    if (required.lmp) {
      dataSets.push({
        type: DataType.lmpDa,
        historicalData: lmpDAProfile,
        scaler: 1000
      });
      dataSets.push({
        type: DataType.lmpRt,
        historicalData: lmpRTProfile,
        scaler: 1000
      });
    }
    if (required.mef) {
      dataSets.push({
        type: DataType.mef,
        historicalData: mefProfile,
        scaler: 1
      });
    }

    if (required.ev1) {
      dataSets.push({
        type: DataType.ev1,
        historicalData: ev1Profile,
        scaler: 1
      });
    }
    if (required.ev2) {
      dataSets.push({
        type: DataType.ev2,
        historicalData: ev2Profile,
        scaler: 1
      });
    }
    dispatch(uploadDataFiles(props.newProposal.public_id, dataSets, props.handleNext));
  };

  const isRequired = getRequiredFlags(props.newProposal.proposal_scenarios);

  return (
    <div className={classes.root}>
      <AccessControl requiredPermissions={['editor', 'admin']}>
        <div className="button-container">
          <Fab
            data-testid="save-button"
            size="small"
            color="secondary"
            aria-label="Save"
            className="button-white"
            onClick={handleProposalDataSave(isRequired)}
          >
            <Save />
          </Fab>
        </div>
      </AccessControl>
      <ProposalData proposal={props.newProposal} isRequired={isRequired} />
    </div>
  );
};

export default ProposalDataContainer;
