import axios from 'axios';
import { Dispatch } from 'redux';

export const POPULATE_FACILITY_LOAD_REQUEST = 'POPULATE_FACILITY_LOAD_REQUEST';
export const POPULATE_FACILITY_LOAD_SUCCESS = 'POPULATE_FACILITY_LOAD_SUCCESS';
export const POPULATE_FACILITY_LOAD_ERROR = 'POPULATE_FACILITY_LOAD_ERROR';

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export const populateFacilityLoad = (facilityKey: string, annualConsumption: number) => (dispatch: Dispatch) => {
  dispatch({ type: 'POPULATE_FACILITY_LOAD_REQUEST' });
  axios
    .get(`${ROOT_URL}/proposal/data/preset/load/${facilityKey}?annual_energy_consumption=${annualConsumption}`)
    .then(payload => {
      dispatch({
        type: `POPULATE_FACILITY_LOAD_SUCCESS`,
        payload: payload.data
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({ type: 'POPULATE_FACILITY_LOAD_ERROR', payload: error });
    });
};
