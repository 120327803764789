import { AxiosError } from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { getUtilityInfo, updateUtilityInfo, createUtilityInfo, getLSE, getCalendars } from '../actions';
import { UtilityInfo, UtilityDetails, CalendarResult } from '../types';

/****************************************************************************/
/* . Utiltiy Details                                                        */
/****************************************************************************/
export function useGetUtilityDetails(lseId: number) {
  return useQuery<UtilityDetails, AxiosError>(['utilityDetails', lseId], () => getLSE(lseId), {
    enabled: !!lseId
  });
}

/****************************************************************************/
/* . Utility Info - PDP BIP Events                                          */
/****************************************************************************/

export function useGetUtilityInfo(lseId: number) {
  return useQuery<UtilityInfo, AxiosError>(['utilityInfo', lseId], () => getUtilityInfo(lseId), {
    enabled: !!lseId // prevent fetching if undefined or 0 (user created tariff)
  });
}

export function useUpdateUtilityInfo(lseId: number) {
  const queryClient = useQueryClient();
  return useMutation<UtilityInfo, AxiosError, UtilityInfo>(utilityInfo => updateUtilityInfo(utilityInfo), {
    retry: 3,
    onMutate: async () => {
      await queryClient.cancelQueries(['utilityInfo', lseId]);
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(['utilityInfo', lseId], result);
    }
  });
}

export function useCreateUtilityInfo(lseId: number) {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, any, string>(utilityInfo => createUtilityInfo(utilityInfo), {
    onSuccess: () => {
      queryClient.invalidateQueries(['utilityInfo', lseId]);
    },
    retry: 3
  });
}

/****************************************************************************/
/* . Utility calendars                                                      */
/****************************************************************************/

export function useGetCalendars(lseId: number, type: 'BILLING' | 'PRICING_PERIOD' | 'HOLIDAY') {
  return useQuery<CalendarResult[], AxiosError>(['utilityCalendars', lseId], () => getCalendars(lseId, type), {
    enabled: !!lseId,
    staleTime: 1000000
    // select: calendars => {
    //   if (type === 'PRICING_PERIOD') {
    //     const data: CalendarEvent[] = [];
    //     (calendars as CalendarResult[]).forEach(calendar => {
    //       if (calendar?.events && Array.isArray(calendar?.events)) {
    //         calendar.events.forEach(event => {
    //           data.push(event);
    //         });
    //       }
    //     });
    //     const dates: string[] = [];
    //     data.forEach(event => {
    //       dates.push(event.calendarEventName.toLowerCase().replace('peak day pricing event ', ''));
    //     });
    //     return dates as string[];
    //   }
    //   return calendars as CalendarResult[];
    // }
  });
}
