import { ADD_EXPLORE_SCENARIOS, REMOVE_EXPLORE_SCENARIOS } from '../../actions/explore';
const INITIAL_STATE = ['Base Load', 'Load + Solar'];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_EXPLORE_SCENARIOS:
      return state.concat([action.payload]);
    case REMOVE_EXPLORE_SCENARIOS:
      let deleteIndex = state.findIndex(scenario => scenario === action.payload);
      if (deleteIndex > -1) {
        let newSelected = [].concat(state.slice(0, deleteIndex), state.slice(deleteIndex + 1));
        return newSelected;
      } else {
        return state;
      }
    default:
      return state;
  }
}
