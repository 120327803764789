import React from 'react';
import { Template, TemplateName } from '../Utilities/Templates/Template';
import ResultMatrixCAISOStandard from './ResultMatrixCAISOStandard';
import ResultMatrixCAISOOptionR from './ResultMatrixCAISOOptionR';
import { ProposalInternal } from '../../types';

export interface TemplateResultsContainerProps {
  template: Template;
  proposal: ProposalInternal;
}

const ResultMatrixContainer: React.SFC<TemplateResultsContainerProps> = ({ template, proposal }) => {
  if (template && template.name) {
    const { name } = template;

    switch (name) {
      case TemplateName.solarPlusStorageCAISO:
        return <ResultMatrixCAISOStandard template={template} proposal={proposal} />;
      case TemplateName.solarPlusStorageCAISOSolarTariff:
        return <ResultMatrixCAISOOptionR template={template} proposal={proposal} />;
      default:
        return <div>Error displaying the Matrix </div>;
    }
  } else {
    return null;
  }
};

export default ResultMatrixContainer;
