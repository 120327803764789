import { GET_ALL_PROPOSALS_SUCCESS } from '../../actions';
const INITIAL_STATE = {
  page: 0,
  pageSize: 25,
  total: 0
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_ALL_PROPOSALS_SUCCESS:
      let { proposals, ...meta } = action.payload;
      return { ...state, ...meta };
    default:
      return state;
  }
}
