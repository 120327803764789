import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import Description from '@material-ui/icons/Description';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Edit from '@material-ui/icons/Edit';
import Drawer from '@material-ui/core/Drawer';

import { useStyles } from '../Index.styles';
import { StoreState } from '../../../reducers';
import { useGlobalTariffs } from '../../../queries/tariffs';
import { getAllManualTariffs, updateTariffFilters } from '../../../actions';
import UserCreatedTariffList from '../../../components/Tariffs/List/UserCreatedTariffList';
import TariffList from '../_components/TariffList';
import TariffFilters from '../_components/TariffFilters';
import { useFavoritesTariffs } from '../../../hooks/useFavoritesTariffs';
import { ESAPTariff, GenabilityTariff } from '../../../types';
import TariffProperties from '../../../components/Tariffs/Detail/TariffProperties';

interface TariffsGridListProps {
  onTariffClick?: (tariff: ESAPTariff, isManual?: boolean) => void;
  openCloseDrawer?: boolean | null;
  currentTariff?: ESAPTariff | GenabilityTariff;
  handleTariffSave?: (tariff: ESAPTariff) => void;
}

const TariffsGridList = (props: TariffsGridListProps) => {
  const {
    onTariffClick,
    openCloseDrawer = null,
    currentTariff,
    handleTariffSave = () => {},
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const manualTariffs = useSelector((state: StoreState) => state.manualTariffs);
  const filters = useSelector((state: StoreState) => state.tariffFilters);

  const [favoritesTariff, onFavoriteClick] = useFavoritesTariffs();
  const [displayManualTariffs, setDisplayManualTariffs] = useState<boolean>(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState<boolean>(false);
  const [selectedTariff, setSelectedTariff] = useState<ESAPTariff | GenabilityTariff | undefined>(currentTariff);

  useEffect(() => {
    dispatch(getAllManualTariffs());
  }, [dispatch]);

  useEffect(() => {
    setSelectedTariff(currentTariff);
  }, [currentTariff]);

  const toggleDrawer = () => {
    setIsSideNavOpen((prevValue) => !prevValue)
  };

  useEffect(() => {
    if (openCloseDrawer !== null) {
      toggleDrawer();
    } else {
      setIsSideNavOpen(false);
    }
  }, [openCloseDrawer]);

  const toggleDisplayManualTariffs = () => {
    setDisplayManualTariffs((prevValue) => !prevValue);
  };

  const USER_CREATED_COLUMNS_ACTIONS_SCHEMA = [
    {
      key: 'viewTariffs',
      icon: Description,
      actionHandler: toggleDisplayManualTariffs,
      label: 'View Tariffs'
    }
  ];

  const {
    status: globalTariffStatus,
    data: globalTariffList,
  } = useGlobalTariffs(filters);


  const handleOnTariffSearch = debounce((search: string) => {
    dispatch(updateTariffFilters({
      ...filters,
      search: search,
      pageStart: 0
    }));
  }, 500);

  const handleOnFiltersUpdate = (modalFilters: unknown) => {
    dispatch(updateTariffFilters(modalFilters));
  };

  const handleTariffClick = (tariff: ESAPTariff, isManual?: boolean) => {
    typeof onTariffClick === 'function' && onTariffClick(tariff, isManual);
  };

  const handleTariffFiltersModal = () => {
    setSelectedTariff(undefined);
    toggleDrawer();
  }

  return (
    <>
      <div className={styles.body}>
        <div className={styles.list}>
          <div className={clsx(styles.findTariff, styles.textLeft)}>
            {!displayManualTariffs ?
              (
                <TextField
                  id="find-tariff"
                  className={styles.tariffInput}
                  label="Find a Tariff"
                  size="small"
                  type="search"
                  onChange={(event: any) => handleOnTariffSearch(event.target.value)}
                  variant="outlined"
                />
              )
              : null
            }

            <div className={clsx(styles.flex, styles.alignCenter)}>
              <Tooltip title={displayManualTariffs ? USER_CREATED_COLUMNS_ACTIONS_SCHEMA[0].label : 'View User Created Tariffs'}>
                <IconButton
                  data-test="button|icon|user-tariffs"
                  onClick={toggleDisplayManualTariffs}
                  aria-label="User Created Tariffs"
                >
                  {displayManualTariffs ? <Description /> : <Edit />}
                </IconButton>
              </Tooltip>

              <Tooltip title="Filter list">
                <IconButton
                  onClick={handleTariffFiltersModal}
                  size="small"
                  aria-label="Filter"
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>

          </div>
          {displayManualTariffs ? (
            <UserCreatedTariffList
              tariffs={manualTariffs}
              columnActionSchema={USER_CREATED_COLUMNS_ACTIONS_SCHEMA}
              history={history}
              onTariffClick={(tariff) => handleTariffClick(tariff, true)}
            />
          ) : (
              <TariffList
                status={globalTariffStatus}
                tariffList={globalTariffList?.results}
                tariffCount={globalTariffList?.count}
                favoriteTariffs={favoritesTariff}
                onFavoriteClick={onFavoriteClick}
                onTariffClick={onTariffClick ? handleTariffClick : undefined}
              />
            )}
        </div>
      </div>

      <Drawer
        classes={{ paper: styles.sidenav }}
        anchor="right"
        open={isSideNavOpen}
        onClose={handleTariffFiltersModal}
      >
        {selectedTariff ? (
          <TariffProperties
            currentTariff={selectedTariff}
            handleCancel={handleTariffFiltersModal}
            handleSave={handleTariffSave}
          />
        ) : (
          <TariffFilters
            toggleFilterDrawer={toggleDrawer}
            handleFilterUpdate={handleOnFiltersUpdate}
            filters={filters}
          />
        )}
      </Drawer>
    </>
  )
}

export default TariffsGridList;
