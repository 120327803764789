import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

// REACT IMPORTS
import ConfirmPopUp from '../../Common/ConfirmPopUp/ConfirmPopUp';
import AccessControl from '../../../utility/AccessControl';
import CreateNewTemplateContainer from '../../TemplateResultsMatrix/CreateNewTemplateContainer';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { ProposalInternal, ScenarioInternal, ESAPTariff, ProcessedScenarioToTable } from '../../../types';
import {
  deleteProposalScenario,
  duplicateProposalScenario,
  resetProposalScenario,
  runScenario,
  updateProposal,
  updateProposalScenario,
  UPDATE_SCENARIOS_ORDER
} from '../../../actions';
import CurrencyDisplay from '../../../utility/CurrencyDisplay';
import ScenarioInfoBlock from '../ScenarioInfoBlock';
import { ProposalDetailContext } from '../../Proposals/Detail/ProposalDetailContextProvider';

// MATERIAL UI IMPORTS
import { makeStyles, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Refresh from '@material-ui/icons/Refresh';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import CheckboxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Done from '@material-ui/icons/Done';
import Delete from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import SlowMotionVideo from '@material-ui/icons/SlowMotionVideo';
import Queue from '@material-ui/icons/Queue';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    //width: '100%',
    minWidth: 700,
    marginTop: theme.spacing(3)
  },
  container: {
    padding: 20
  },
  flex: {
    flex: 1
  },
  table: {
    border: '1px solid #e5e5e5',
    '& th': {
      fontFamily: 'Roboto !important',
      fontSize: '.7rem !important',
      whiteSpace: 'normal !important'
    },
    '& td': {
      borderRight: '1px solid #f1f1f1'
    },
    '& tr:hover': {
      backgroundColor: '#f1f1f1'
    },
    '& td, & th': {
      paddingLeft: 12,
      paddingRight: 12,
      textAlign: 'center',
      borderRight: '1px solid #e5e5e5',
      letterSpacing: '.5px'
    },
    '& td:nth-child(1)': {
      width: 20,
      maxWidth: 20,
      padding: 0,
      cursor: 'move !important',
      '& svg': {
        fontSize: 16
      }
    },
    '& td:nth-child(3)': {
      position: 'relative',
      width: '25%',
      textAlign: 'left',
      color: '#003876',
      fontWeight: '700',
      fontFamily: '"Lato"',
      letterSpacing: '-.05px',
      whiteSpace: 'nowrap'
    },
    '& td:nth-child(4), & td:nth-child(5), & td:nth-child(6), & td:nth-child(7), & td:nth-child(8), & td:nth-child(9)': {
      // width: '10%',
      whiteSpace: 'nowrap'
    },
    '& td:nth-child(8)': {
      fontWeight: 'bold'
      // fontFamily: 'Roboto',
      // letterSpacing: '.5px'
    },
    '& td:nth-child(9)': {
      fontSize: '11px',
      letterSpacing: '0',
      textTransform: 'uppercase',
      fontWeight: '500',
      color: '#666'
    }
  },
  overflow: {
    overflow: 'auto'
  },
  toolbar: {
    // backgroundColor: theme.palette.primary.main,
    // color: "#fff"
    // borderBottom: '1px solid #e5e5e5'
  },
  button: {
    color: '#fff',
    border: '1px solid #fff',
    marginRight: 10
  },
  pointer: {
    cursor: 'pointer'
  },
  selected: {
    cursor: 'pointer',
    background: 'rgba(255,255,0,.75) !important',
    '&:hover': {
      background: 'rgba(255,255,0,.9) !important'
    }
  },
  actionRoot: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-end'
  },
  icon: {
    fill: '#394149'
  },
  statusQuo: {
    backgroundColor: '#deffc2',
    color: '#333',
    padding: '0 6px'
  },
  highlightTableCell: {
    color: `${(theme.palette as any).esap.greenDark} !important`
  },
  complete: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.greenDark
  },
  error: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.red
  },
  warning: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.yellowOrange
  },
  percentage: {
    fontWeight: 600,
    fontSize: '.7rem',
    letterSpacing: '-.5px'
  },
  scenarioActiveInfo: {
    fontSize: '12px',
    position: 'relative',
    top: '-2px',
    color: '#707070',
    paddingLeft: 20,
    '& svg': {
      fontSize: '12px',
      top: '2px',
      position: 'relative',
      marginLeft: 10
    }
  },
  scenarioName: {
    position: 'relative',
    top: -5
  }
}));

const initialState = {
  isConfirm: false,
  isRealtimeWarning: false,
  showHidden: false,
  processScenariosToTable: [],
  updateList: [],
  isMessage: false,
  message: '',
  isNewTemplateOpen: false,
  processedScenarios: [] as ProcessedScenarioToTable[],
  scenarioId: ''
};

interface IProps {
  proposal: ProposalInternal;
  getProposalScenario: (scenarioId: string, proposalId: string) => void;
  selectedScenarioIds: string[];
  proposalId: string;
  scenarios: ScenarioInternal[];
  assumptions: any;
  statusQuoResult: any;
  handleScenarioClick: (scenario: ScenarioInternal) => void;
  history: any;
  handleMonthlyViewClick(scenario: ScenarioInternal): any;
  handleToggleVisibility(scenario: ScenarioInternal): any;
  tariffs: ESAPTariff[];
  loading: boolean;
}

const ProposalDetailScenarioList: React.FC<IProps> = props => {
  const [state, setState] = useState(initialState);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedYears, selectedMonths } = useContext(ProposalDetailContext);

  const [scenarioToReRun, setScenarioToReRun] = useState<ScenarioInternal | null>(null);

  useEffect(() => {
    if (props.scenarios && props.scenarios.length > 0) {
      processScenariosToTable();
    }
  }, [props.scenarios, selectedYears.start, selectedYears.end, selectedMonths.start, selectedMonths.end]);

  useEffect(() => {
    processScenariosToTable();
  }, [props.scenarios]);

  // refresh cache for scenarios that have no results yet
  const refreshScenariosList = (): void => {
    props.scenarios.forEach(scenario => {
      if (scenario.public_id && (!scenario.results || scenario.error)) {
        dispatch(props.getProposalScenario(scenario.public_id, props.proposalId));
      }
    });
  };

  const getStatusQuoScenario = (): ScenarioInternal | undefined => {
    return props.scenarios.find(scenario => {
      return scenario.is_status_quo;
    });
  };

  const sliceResults = (scenario: ScenarioInternal | Partial<ProcessedScenarioToTable> | undefined) => {
    if (selectedYears.start == '0' || selectedYears.end == '0') return scenario?.results;
    let slicedResults: any = {};
    if (scenario && scenario.results) {
      if (scenario?.assumptions && !scenario.assumptions.isMultiyear) return scenario.results;
      const monthStart =
        (selectedMonths.start + 1).toString().length === 1
          ? '0' + (selectedMonths.start + 1).toString()
          : (selectedMonths.start + 1).toString();
      const monthEnd =
        (selectedMonths.end + 1).toString().length === 1
          ? '0' + (selectedMonths.end + 1).toString()
          : (selectedMonths.end + 1).toString();

      const startDate = `${monthStart}/01/${selectedYears.start}`;
      const endDate = `${monthEnd}/01/${selectedYears.end}`;

      let yearStartIndex = scenario.results['Months'].findIndex(el => el === startDate);
      let yearEndIndex = scenario.results['Months'].findIndex(el => el === endDate);

      Object.keys(scenario.results).forEach(item => {
        if (scenario && scenario?.results) {
          slicedResults[item] = scenario.results[item].slice(yearStartIndex, yearEndIndex + 1);
        }
      });
    }
    return slicedResults;
  };

  const processScenariosToTable = () => {
    let processedScenarios: ProcessedScenarioToTable[] = [];
    // check for proposal scenarios
    if (!props.scenarios || props.scenarios.length === 0) {
      return;
    }
    // check for base assumptions
    // if (!props.assumptions || !props.assumptions) {
    //   return;
    // }

    let statusQuoScenario = getStatusQuoScenario() as Partial<ProcessedScenarioToTable>;
    if (!statusQuoScenario) {
      statusQuoScenario = {};
    }
    let statusQuoResult = statusQuoScenario.results ? sliceResults(statusQuoScenario) : null;
    let energyCostBaseline = 0;
    let demandCostBaseline = 0;
    if (statusQuoResult && Object.keys(statusQuoResult).length > 0) {
      energyCostBaseline = statusQuoResult['Energy Cost Total'].reduce((total: number, num: number) => {
        return total + num;
      }, 0);
      demandCostBaseline = statusQuoResult['Demand Cost Total'].reduce((total: number, num: number) => {
        return total + num;
      }, 0);
    }
    props.scenarios
      // .filter(scenario => state.showHidden || scenario.active)
      .forEach(scenario => {
        let processedScenario = { ...scenario } as ProcessedScenarioToTable;
        const processedResults = sliceResults(processedScenario);

        if (
          scenario?.results &&
          processedScenario?.results &&
          Object.keys(processedScenario.results).length > 0 &&
          Object.keys(scenario.results).length > 0
        ) {
          processedScenario.energyCosts = processedResults['Energy Cost Total'].reduce((total: number, num: number) => {
            return total + num;
          }, 0);
          processedScenario.demandCosts = processedResults['Demand Cost Total'].reduce((total: number, num: number) => {
            return total + num;
          }, 0);
          processedScenario.totalCost = (+processedScenario.energyCosts + +processedScenario.demandCosts).toFixed(0);
          processedScenario.energySavings = +energyCostBaseline - +processedScenario.energyCosts;
          processedScenario.demandSavings = +demandCostBaseline - +processedScenario.demandCosts;
          processedScenario.totalSavings = (processedScenario.energySavings + processedScenario.demandSavings).toFixed(
            0
          );
          processedScenario.blendedSavings = '-';
          if (
            processedScenario.assumptions &&
            processedScenario.assumptions.isBatt &&
            processedScenario.assumptions?.capacity &&
            processedScenario.assumptions?.capacity > 0
          ) {
            processedScenario.blendedSavings = (
              (processedScenario.energySavings + processedScenario.demandSavings) /
              +processedScenario.assumptions.capacity
            ).toFixed(0);
          }
          processedScenario.energySavings = processedScenario.energySavings.toFixed(0);
          processedScenario.demandSavings = processedScenario.demandSavings.toFixed(0);
        } else {
          processedScenario.energyCosts = '-';
          processedScenario.demandCosts = '-';
          processedScenario.totalCost = '-';
          processedScenario.energySavings = '-';
          processedScenario.demandSavings = '-';
          processedScenario.totalSavings = '-';
          processedScenario.blendedSavings = '-';
        }
        processedScenarios.push(processedScenario);
      });
    setState({
      ...state,
      processedScenarios: processedScenarios
    });
  };

  const handleAddNewScenario = () => {
    // clear latest new scenario object so have fresh object
    dispatch(resetProposalScenario());
    props.history.push('/create-scenario');
  };

  const handleDuplicate = (scenario: ScenarioInternal) => (event: any) => {
    event.stopPropagation();
    dispatch(duplicateProposalScenario(scenario));
    navigateToDuplicate();
  };

  const handleHide = (scenario: ScenarioInternal) => (event: any) => {
    event.stopPropagation();
    props.handleToggleVisibility(scenario);
  };

  const handleScenarioDelete = (id: string) => (event: any) => {
    event.stopPropagation();
    handleConfirmOpen(id);
  };

  const handleConfirmRunScenario = (scenario: ScenarioInternal) => (event: any) => {
    event.stopPropagation();
    if (scenario.assumptions.isRealtime && props.proposal.forecasting_status !== 'complete') {
      setState(state => ({ ...state, isRealtimeWarning: true }));
      return;
    }
    if (!!scenario?.status) {
      setScenarioToReRun(scenario);
    } else {
      handleRunScenario(scenario);
    }
  };

  const handleReRunScenario = () => {
    if (scenarioToReRun) {
      handleRunScenario(scenarioToReRun);
      setScenarioToReRun(null);
    }
  };

  const handleRunScenario = (scenario: ScenarioInternal) => {
    if (scenario.public_id) {
      dispatch(runScenario(scenario.proposal_id, scenario.public_id));
      // TODO refactor this ... not connected to reducer and not modifying the backend
      const updatedScenario = { ...scenario, error: null, results: null, status: 'Queued' };
      dispatch(updateProposalScenario(updatedScenario, props.proposalId));
      showMessage('Running Scenario');
    }
  };

  const isSelected = (scenario: ScenarioInternal): boolean => {
    return scenario.is_status_quo === true;
  };

  const handleScenarioStatusQuo = (event: any, scenario_id: string) => {
    event.stopPropagation();
    let currentStatusQuo = props.scenarios.find(scenario => scenario.is_status_quo);
    let newStatusQuo = props.scenarios.find(scenario => scenario.public_id == scenario_id);
    if ((!currentStatusQuo || scenario_id != currentStatusQuo.public_id) && newStatusQuo && newStatusQuo?.results) {
      let new_status_quo_update = {
        public_id: scenario_id,
        is_status_quo: true
      } as Partial<ScenarioInternal>;
      dispatch(updateProposalScenario(new_status_quo_update, props.proposalId));
      if (currentStatusQuo) {
        let current_status_quo_scenario_update = {
          public_id: currentStatusQuo.public_id,
          is_status_quo: false
        } as Partial<ScenarioInternal>;
        dispatch(updateProposalScenario(current_status_quo_scenario_update, props.proposalId));
      }
    } else {
      if (newStatusQuo && !newStatusQuo.results) {
        showMessage('Status Quo must have results');
      }
    }
  };

  const handleScenarioClick = (scenario: ScenarioInternal) => () => {
    let statusQuoScenario = getStatusQuoScenario();
    if (scenario.results && (!statusQuoScenario || !statusQuoScenario.results)) {
      showMessage('Status Quo must have results before you can view a scenario results');
      return;
    }
    if (scenario && scenario.public_id) {
      props.handleScenarioClick(scenario);
      navigateToDetail(props.proposalId, scenario.public_id);
    }
  };

  const navigateToDetail = (proposalId: string, scenarioId: string) => {
    props.history.push(`/proposals/${proposalId}/scenarios/${scenarioId}`);
  };

  const navigateToDuplicate = () => {
    props.history.push('/create-scenario');
  };

  const hideMessage = () => {
    setState({ ...state, isMessage: false });
  };

  const showMessage = (message: string) => {
    setState({ ...state, isMessage: true, message: message });
  };

  const handleConfirmOpen = (id: string) => {
    setState({ ...state, isConfirm: true, scenarioId: id });
  };

  const handleConfirmClose = (success: boolean) => () => {
    if (success) {
      dispatch(deleteProposalScenario(state.scenarioId, props.proposalId));
    }
    setState({ ...state, isConfirm: false, scenarioId: '' });
  };

  const handleHiddenToggle = () => {
    setState({ ...state, showHidden: !state.showHidden });
  };

  const getTariffCode = (tariffId: string): string | null => {
    const tariff = props?.tariffs?.find(tariff => tariff.public_id === tariffId);
    return tariff?.code ?? null;
  };

  const toggleNewTemplate = () => {
    const isNewTemplateOpen = !state.isNewTemplateOpen;
    setState({ ...state, isNewTemplateOpen });
  };

  const handleTemplateClose = () => {
    setState({ ...state, isNewTemplateOpen: false });
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const updatedScenarios = [...props.scenarios];
    const draggedScenario = props.scenarios.find(scenario => scenario.public_id === draggableId);
    if (draggedScenario && updatedScenarios.length > 0) {
      updatedScenarios.splice(source.index, 1);
      updatedScenarios.splice(destination.index, 0, draggedScenario);
      const updatedProposal = { ...props.proposal, proposal_scenarios: updatedScenarios };
      dispatch(updateProposal(updatedProposal, { ignoreRerender: true }));
      dispatch({ type: UPDATE_SCENARIOS_ORDER, payload: updatedScenarios });
    }
  };

  const getStatusName = (scenario: ProcessedScenarioToTable) => {
    if (scenario.status === 'Complete' && !scenario.error) {
      return classes.complete;
    }
    if (scenario.active && !!scenario.error) {
      return classes.error;
    }
    if (scenario.active && !scenario.run_completed) {
      return classes.warning;
    }
    if (scenario.active && (!scenario.status || scenario.status === 'Idle')) {
      return classes.warning;
    }
    return classes.complete;
  };

  const renderStatusIcon = (scenario: ScenarioInternal, classes: any) => {
    // Error
    if (scenario.error) {
      return (
        <AccessControl requiredPermissions={['editor', 'admin']} renderNoAccess={() => <span>Not Run</span>}>
          <Tooltip title={scenario.error} placement="top" enterDelay={300}>
            <ErrorIcon />
          </Tooltip>
        </AccessControl>
      );
    }
    // Idle
    if (!scenario.status || scenario.status === 'Idle') {
      return (
        <AccessControl requiredPermissions={['editor', 'admin']} renderNoAccess={() => <span>Not Run</span>}>
          <>
            <Tooltip title="New Scenario" placement="top" enterDelay={300}>
              <SlowMotionVideo />
            </Tooltip>
          </>
        </AccessControl>
      );
    }
    // Queued
    if (scenario.status === 'Queued') {
      return (
        <span className={classes.percentage}>
          <Tooltip title="Queued" placement="top" enterDelay={300}>
            <Queue />
          </Tooltip>
        </span>
      );
    }
    // Complete
    if (scenario.status === 'Complete') {
      return (
        <span>
          <Tooltip title={`Complete - ${scenario.run_completed}`} placement="bottom" enterDelay={300}>
            <Check className={classes.complete} />
          </Tooltip>
        </span>
      );
    }

    // Processing
    if (/%/.test(scenario.status)) return <span className={classes.percentage}>{scenario.status}</span>;

    // Default
    return <span className={classes.percentage}>{scenario.status}</span>;
  };

  return (
    <>
      <CreateNewTemplateContainer
        open={state.isNewTemplateOpen}
        handleClose={handleTemplateClose}
        proposal={props.proposal}
        tariffs={props.tariffs}
      />
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography color="inherit" variant="h6" className={classes.flex}>
            Scenarios
            <span className={classes.scenarioActiveInfo}>
              <Tooltip title="Hidden Scenarios" placement="bottom" enterDelay={300}>
                <>
                  <VisibilityOff /> {state.processedScenarios.filter(s => !s.active).length}
                </>
              </Tooltip>
              <Tooltip title="Hidden Scenarios" placement="bottom" enterDelay={300}>
                <>
                  <Visibility /> {state.processedScenarios.filter(s => s.active).length}
                </>
              </Tooltip>
            </span>
          </Typography>
          <div>
            <AccessControl requiredPermissions={['editor', 'admin']}>
              <>
                <Tooltip title="Create New Template" placement="bottom" enterDelay={300}>
                  <Button color="primary" onClick={toggleNewTemplate} style={{ marginRight: 16 }}>
                    New Template
                  </Button>
                </Tooltip>
                <Tooltip title="Refresh Proposal" placement="bottom" enterDelay={300}>
                  <IconButton size="small" color="primary" aria-label="Refresh Proposal" onClick={refreshScenariosList}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Scenario" placement="bottom" enterDelay={300}>
                  <IconButton size="small" color="primary" aria-label="Add Scenario" onClick={handleAddNewScenario}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Show Hidden" placement="bottom" enterDelay={300}>
                  <IconButton size="small" color="primary" aria-label="Show Hidden" onClick={handleHiddenToggle}>
                    {!state.showHidden && <Visibility />}
                    {state.showHidden && <VisibilityOff />}
                  </IconButton>
                </Tooltip>
              </>
            </AccessControl>
          </div>
        </Toolbar>
        {state.processedScenarios && state.processedScenarios.length > 0 && (
          <div>
            <div data-testid="scenario-list-container" style={{ overflowX: 'scroll' }}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    {/* <TableCell padding="checkbox">SQ</TableCell> */}
                    <TableCell padding="checkbox">Actions</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Energy Savings</TableCell>
                    <TableCell>Demand Savings</TableCell>
                    <TableCell>Blended $/kWh</TableCell>
                    <TableCell>Total Savings</TableCell>
                    <TableCell>Tariff</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody component={DroppableComponent(onDragEnd)}>
                  {state.processedScenarios.map((scenario, i) => {
                    return (
                      <TableRow
                        component={DraggableComponent(scenario.public_id, i, state.showHidden, scenario.active)}
                        key={scenario.public_id}
                        hover
                        onClick={handleScenarioClick(scenario)}
                        className={
                          props.selectedScenarioIds && props.selectedScenarioIds.includes(scenario.public_id)
                            ? classes.selected
                            : classes.pointer
                        }
                      >
                        <TableCell className={getStatusName(scenario)}>
                          <div>{renderStatusIcon(scenario, classes)}</div>
                        </TableCell>
                        <TableCell padding="checkbox">
                          <div className={classes.actionRoot}>
                            <AccessControl
                              requiredPermissions={['editor', 'admin']}
                              renderNoAccess={() => {
                                return (isSelected(scenario) ? (
                                  <span>
                                    <Done />
                                  </span>
                                ) : null) as JSX.Element;
                              }}
                            >
                              <>
                                {/* {scenario.is_status_quo && (
                                  <CheckboxIcon
                                    onClick={(event) => handleScenarioStatusQuo(event, scenario.public_id)}
                                  />
                                )} */}
                                {!scenario.is_status_quo && (
                                  <Tooltip title="Select as Status Quo" placement="bottom" enterDelay={300}>
                                    <CheckboxOutlineIcon
                                      onClick={event => handleScenarioStatusQuo(event, scenario.public_id)}
                                    />
                                  </Tooltip>
                                )}
                              </>
                            </AccessControl>
                            <AccessControl requiredPermissions={['editor', 'admin']}>
                              <>
                                {!scenario.is_status_quo && (
                                  <Tooltip title="Delete Scenario" placement="bottom" enterDelay={300}>
                                    <Delete
                                      className={classes.icon}
                                      onClick={handleScenarioDelete(scenario.public_id)}
                                    />
                                  </Tooltip>
                                )}
                                {scenario.active && (
                                  <Tooltip title="Hide Scenario" placement="bottom" enterDelay={300}>
                                    <VisibilityOff className={classes.icon} onClick={handleHide(scenario)} />
                                  </Tooltip>
                                )}
                                {!scenario.active && (
                                  <Tooltip title="Show Scenario" placement="bottom" enterDelay={300}>
                                    <Visibility className={classes.icon} onClick={handleHide(scenario)} />
                                  </Tooltip>
                                )}
                                <Tooltip title="Duplicate Scenario" placement="bottom" enterDelay={300}>
                                  <OpenInNew
                                    className={classes.icon}
                                    onClick={handleDuplicate(scenario)}
                                    data-testid="duplicateScenario"
                                  />
                                </Tooltip>
                                {(scenario.status === 'Complete' || !scenario.status || !!scenario.error) && (
                                  <Tooltip
                                    title={!scenario.results ? 'Run Scenario' : 'Re-run scenario'}
                                    placement="bottom"
                                    enterDelay={300}
                                  >
                                    <PlayCircleFilled
                                      color={!scenario.results ? 'primary' : 'action'}
                                      onClick={handleConfirmRunScenario(scenario)}
                                    />
                                  </Tooltip>
                                )}
                              </>
                            </AccessControl>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <span
                              className={`${classes.scenarioName} ${scenario.is_status_quo ? classes.statusQuo : ''}`}
                            >
                              {scenario.name}
                            </span>
                            <div style={{ top: '29px', left: '11px', position: 'absolute' }}>
                              <ScenarioInfoBlock scenario={scenario} />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>{<CurrencyDisplay value={+scenario.totalCost} />}</TableCell>
                        <TableCell>
                          <CurrencyDisplay value={+scenario.energySavings} />
                        </TableCell>
                        <TableCell>
                          <CurrencyDisplay value={+scenario.demandSavings} />
                        </TableCell>
                        <TableCell>
                          <CurrencyDisplay value={+scenario.blendedSavings} />
                        </TableCell>
                        <TableCell className={classes.highlightTableCell}>
                          <CurrencyDisplay value={+scenario.totalSavings} />
                        </TableCell>
                        <TableCell>{getTariffCode(scenario.assumptions.tariffId as string)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        )}

        <Snackbar
          open={state.isMessage}
          autoHideDuration={5000}
          onClose={hideMessage}
          message={<span id="message-id">{state.message}</span>}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={hideMessage}>
              <CloseIcon />
            </IconButton>
          ]}
        />

        <ConfirmPopUp
          open={state.isConfirm}
          onConfirmClose={handleConfirmClose}
          message="Are you sure that you want to delete this scenario?"
        />
        {/* Pop up that show when trying to run a scenario with RT data without a forecast already generated */}
        <Dialog
          onClose={() => {
            setState({ ...state, isRealtimeWarning: false });
          }}
          aria-labelledby="confirm-realtime-dialog"
          data-testid="confirm-realtime-dialog"
          open={state.isRealtimeWarning}
          style={{ padding: 24 }}
        >
          <DialogTitle>
            <span style={{ display: 'flex' }}>
              <span style={{ flex: 1 }}>Real-time scenario run</span>
              <IconButton
                onClick={() => {
                  setState({ ...state, isRealtimeWarning: false });
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </DialogTitle>
          <DialogContent>You need to generate a forecast before running a real-time scenario.</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setState({ ...state, isRealtimeWarning: false });
              }}
              color="primary"
              autoFocus
              aria-label="confirm"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          onClose={() => setScenarioToReRun(null)}
          aria-labelledby="confirm-rerun-dialog"
          open={scenarioToReRun != null}
          style={{ padding: 24 }}
        >
          <DialogTitle>
            <span style={{ display: 'flex' }}>
              <span style={{ flex: 1 }}>Please confirm </span>
              <IconButton onClick={() => setScenarioToReRun(null)}>
                <CloseIcon />
              </IconButton>
            </span>
          </DialogTitle>
          <DialogContent>Do you want to discard previous results and re-run this scenario?</DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setScenarioToReRun(null)} aria-label="cancel">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleReRunScenario} color="primary" autoFocus aria-label="confirm">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
};

const getItemStyle = (isDragging: boolean, draggableStyle: any, showHidden: boolean, active: boolean) => ({
  ...draggableStyle,
  userSelect: 'none',
  background: isDragging ? 'rgb(255, 240, 234)' : 'white',
  border: isDragging ? 'rgba(254,88,21,0.2) 2px solid' : '1px solid #eaeaea',
  display: showHidden || active ? 'table-row' : 'none'
});

// TODO check TS types here https://stackoverflow.com/questions/51839298/use-material-ui-table-with-react-beautiful-dnd
const DroppableComponent = (onDragEnd: (result: DropResult, provided: ResponderProvided) => void) => (props: any) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'1'}>
        {(provided, snapshot) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps} {...props}>
              {props.children}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
const DraggableComponent = (id: string, index: number, showHidden: boolean, active: boolean) => (props: any) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...props}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, showHidden, active)}
        >
          {React.Children.map(props.children, (el, index) => {
            return index === 0 ? React.cloneElement(el, { ...provided.dragHandleProps }) : React.cloneElement(el);
          })}
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(ProposalDetailScenarioList);
