import { MutableRefObject, useState, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useLazyDisplay } from '../../../hooks/useLazyDisplay';
import { ESAPTariff } from '../../../types';
import clsx from 'clsx';

interface TariffListRowProps {
  row: ESAPTariff;
  isFavorite: boolean;
  onFavoriteClick: (tariff: ESAPTariff) => void;
  onRowClick?: (tariff: ESAPTariff) => void;
}

interface ListRowStyleProps {
  isClickable: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      height: '50px',
      position: 'relative',
      cursor: ({ isClickable }: ListRowStyleProps) => isClickable ? 'pointer' : 'default',
    },
    absolute: {
      position: 'absolute',
      left: '0',
      bottom: '0',
      zIndex: 10,
    },
    popup: {
      transform: 'translateX(30%)',
      width: '400px',
      height: '220px',
      boxShadow: '1px 1px 2px 0px rgb(0 0 0 / 30%), -1px -1px 2px 0px rgb(0 0 0 / 30%)',
      backgroundColor: '#fff',
      overflow: 'hidden',
    },
    popupContent: {
      backgroundColor: '#fff',
      zIndex: 2,
      height: 'inherit',
      width: 'inherit',
      padding: '0px 10px 15px',
      borderRadius: '4px',
      position: 'initial',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',

      '&::before': {
        position: 'absolute',
        boxShadow: '1px 1px 2px 0px rgb(0 0 0 / 30%), -1px -1px 2px 0px rgb(0 0 0 / 30%)',
        zIndex: '-1',
        content: '""',
        bottom: '-5px',
        left: '0',
        width: '20px',
        height: '20px',
        backgroundColor: '#fff',
        transform: 'translateX(250%) rotate(45deg)',
      }
    },
    utility: {
      fontWeight: 600,
      margin: 0,
      marginRight: '10px',
    },
    flex: {
      display: 'flex',
    },
    bold: {
      fontWeight: 600,
    },
    icon: {
      color: '#2196f3'
    },
    isFavorite: {
      color: '#272727'
    },
    favorite: {
      cursor: 'pointer',
    },
    toLeft: {
      marginLeft: 'auto',
    },
    tariffType: {
      backgroundColor: '#000',
      color: '#fcfcfc',
      padding: '7px 10px',
      borderRadius: '4px',
    },
    code: {
      flex: '1 0 auto',
    },
    cardHeader: {
      display: 'flex',
      paddingTop: '10px',
      marginBottom: '5px',
    },
    description: {
      display: 'flex',
      flexFlow: 'column',

      '& > span': {
        marginBottom: '2px',
      }
    },
    cardBody: {},
    cardFooter: {},
  })
);

const TariffListRow = (props: TariffListRowProps) => {
  const { row, isFavorite, onFavoriteClick, onRowClick } = props;
  const [visible, element] = useLazyDisplay();
  const styles = useStyles({ isClickable: typeof onRowClick === 'function' });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event: any) => {
    setAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const FavoriteIcon = useMemo(() => (isFavorite ? Favorite : FavoriteBorder), [isFavorite]);
  const handleFavoriteClick = (event: any) => onFavoriteClick(row);
  const handleOnClickRow = (event: any) => {
    if(!event.target.closest('section')) {
      onRowClick && onRowClick(row);
    }
  };

  return (
    <TableRow
      className={styles.row}
      ref={element as MutableRefObject<any>}
      hover={true}
      data-test={`row|tariff|${row.code}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleClose}
      onClick={handleOnClickRow}
    >
      {visible && (
        <>
          <TableCell scope="row">{row.lse_name}</TableCell>
          <TableCell scope="row">{row.name}</TableCell>
          <TableCell scope="row">{row.code}</TableCell>
          {open && (
            <TableCell data-test="section|tariff|detail" scope="row" className={styles.absolute}>
              <section className={styles.popup}>
                <div className={styles.popupContent}>
                  <div className={styles.cardHeader}>
                    <div className={styles.flex}>
                      <p className={styles.utility}>{row.lse_name}</p>
                      <span className={clsx(styles.code, styles.bold)}>{row.code}</span>
                    </div>
                    <div className={styles.toLeft}>
                      {<FavoriteIcon className={clsx(styles.favorite, { [styles.isFavorite]: isFavorite})} onClick={handleFavoriteClick} />}
                    </div>
                  </div>
                  <div className={styles.cardBody}>
                    <div className={styles.description}>
                      <span>
                        Min Monthly Consumption: <span className={styles.bold}>0</span>
                      </span>
                      <span>
                        Max Monthly Consumption: <span className={styles.bold}>0</span>
                      </span>
                      <span>
                        Min Monthly Demand: <span className={styles.bold}>0</span>
                      </span>
                      <span>
                        Max Monthly Demand: <span className={styles.bold}>0</span>
                      </span>
                      <span>
                        Effective Date: <span className={styles.bold}>{row.effective_date}</span>
                      </span>
                    </div>
                    <div>
                      <span>Effective Date:</span>
                      <span>{row.effective_date}</span>
                    </div>
                    <div>
                      {row.documentsUrl?.map((doc, index) => {
                        return (
                          <IconButton
                            key={`document-${index}`}
                            onClick={() => window.open(doc, "_blank")}
                          >
                            <PictureAsPdfIcon className={styles.icon} fontSize="large" />
                          </IconButton>
                        )
                      })}
                    </div>
                  </div>
                  <div className={styles.cardFooter}>
                    <span className={styles.tariffType}>{row.tariffType}</span>
                  </div>
                </div>
              </section>
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
}

export default TariffListRow;
