import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EnergyChargesCreate from './EnergyChargesCreate';
import DemandChargesCreate from './DemandChargesCreate';
import DemandSubscriptionsCreate from './DemandSubscriptionsCreate';
import OverageCharges from './OverageCharges';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    toolbar2: {
      flex: 1,
      borderTop: '1px solid #e5e5e5',
      borderBottom: '1px solid #e5e5e5',
      marginBottom: theme.spacing(4)
    },
    flex: {
      flex: 1
    },
    block: {
      width: '100%',
      flex: 1,
      marginBottom: theme.spacing(10),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    textField: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(1)
    },
    subtitle: {
      // marginBottom: theme.spacing(3)
      flex: 1
    },
    dense: {
      marginTop: 19
    },
    heading: {
      fontSize: theme.typography.pxToRem(24)
      // fontWeight: theme.typography.fontWeightRegular
    },
    addButton: {
      // marginLeft: theme.spacing(12),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    expansionPanel: {
      width: '100%'
    },
    expansionPanelHeader: {
      backgroundColor: 'rgba(0,26,113,0.17)'
    },
    LSEPicker: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(2)
    },
    inputField: {
      width: theme.spacing(25)
    },
    selectInputField: {}
  })
);

interface TariffCreationPanelsProps {
  isEV: boolean;
}

const TariffCreationPanels: React.FC<TariffCreationPanelsProps> = ({ isEV }) => {
  const classes = useStyles();

  return (
    <>
      {/* Energy
       Charges*/}
      <ExpansionPanel className={classes.expansionPanel}>
        <ExpansionPanelSummary
          className={classes.expansionPanelHeader}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="energy-charges-content"
          id="energy-charges-header"
        >
          <Typography variant="h6" className={classes.subtitle}>
            Energy Charges
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <EnergyChargesCreate isEV={isEV} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {/* Demand Charges */}
      {!isEV && (
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            className={classes.expansionPanelHeader}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="demand-charges-content"
            id="demand-charges-header"
          >
            <Typography variant="h6" className={classes.subtitle}>
              Demand Charges
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <DemandChargesCreate />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {/* */}
      {isEV && (
        <>
          {/* Demand Subscription */}
          <ExpansionPanel className={classes.expansionPanel}>
            <ExpansionPanelSummary
              className={classes.expansionPanelHeader}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="demand-subscription-content"
              id="demand-subscription-header"
            >
              <Typography variant="h6" className={classes.subtitle}>
                Demand Subscriptions
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <DemandSubscriptionsCreate />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {/* */}
          {/* Overage Charges */}
          <ExpansionPanel className={classes.expansionPanel}>
            <ExpansionPanelSummary
              className={classes.expansionPanelHeader}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="overage-charges-content"
              id="overage-charges-header"
            >
              <Typography variant="h6" className={classes.subtitle}>
                Overage Charges
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <OverageCharges />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {/*  */}
        </>
      )}
    </>
  );
};

export default React.memo(TariffCreationPanels);
