import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { Assessment, ChevronRight, Settings, Adjust } from '@material-ui/icons';
import { resetBreadcrumb, pushBreadcrumb } from '../../actions/breadcrumbs';
import { RouteComponentProps } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '10px auto',
    maxWidth: 600
  },
  card: {
    margin: 10,
    cursor: 'pointer'
  },
  actions: {
    display: 'flex'
  }
}));

const SettingDetail: React.FC<RouteComponentProps> = props => {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Settings', '/settings'));
  }, []);

  const navigateToNBT = () => {
    props.history.push('/settings/net-benefits-test');
  };

  const navigateToUserSettings = () => {
    props.history.push('/settings/account-settings');
  };

  const navigateToGlobalAdjustment = () => {
    props.history.push('/settings/global-adjustment');
  };

  const navigateToISONE = () => {
    props.history.push('/settings/iso-ne');
  };

  const navigateToMA = () => {
    props.history.push('/settings/iso-ne-ma');
  };

  const navigateToCT = () => {
    props.history.push('/settings/iso-ne-ct');
  };

  const navigateToPJM = () => {
    props.history.push('/settings/pjm');
  };

  const navigateToVDER = () => {
    props.history.push('/settings/vder');
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card} onClick={navigateToNBT}>
        <CardHeader
          avatar={
            <IconButton>
              <Assessment />
            </IconButton>
          }
          action={
            <IconButton>
              <ChevronRight />
            </IconButton>
          }
          title="Net Benefits Test"
          subheader="Used during PDR optimization"
        />
      </Card>
      {/* <Card className={classes.card}>
          <CardHeader
            avatar={
              <IconButton>
                <List />
              </IconButton>
            }
            action={
              <IconButton>
                <ChevronRight />
              </IconButton>
            }
            title="Default Assumptions"
            subheader="Defaults used to populate proposal status quo"
          />
        </Card> */}
      <Card className={classes.card} onClick={navigateToUserSettings}>
        <CardHeader
          avatar={
            <IconButton>
              <Settings />
            </IconButton>
          }
          action={
            <IconButton>
              <ChevronRight />
            </IconButton>
          }
          title="Account Settings"
          subheader="These are settings unique to your account"
        />
      </Card>
      {(window as any).ENVIRONMENT !== 'production' && (
        <Card className={classes.card} onClick={navigateToISONE}>
          <CardHeader
            avatar={
              <IconButton>
                <Adjust />
              </IconButton>
            }
            action={
              <IconButton>
                <ChevronRight />
              </IconButton>
            }
            title="ISO-NE"
            subheader="These are settings unique to your account"
          />
        </Card>
      )}
      <Card className={classes.card} onClick={navigateToMA}>
        <CardHeader
          avatar={
            <IconButton>
              <Adjust />
            </IconButton>
          }
          action={
            <IconButton>
              <ChevronRight />
            </IconButton>
          }
          title="Massachusetts"
          subheader="These are settings unique to your account"
        />
      </Card>
      <Card className={classes.card} onClick={navigateToCT}>
        <CardHeader
          avatar={
            <IconButton>
              <Adjust />
            </IconButton>
          }
          action={
            <IconButton>
              <ChevronRight />
            </IconButton>
          }
          title="Connecticut"
          subheader="These are settings unique to your account"
        />
      </Card>
      <Card className={classes.card} onClick={navigateToGlobalAdjustment}>
        <CardHeader
          avatar={
            <IconButton>
              <Adjust />
            </IconButton>
          }
          action={
            <IconButton>
              <ChevronRight />
            </IconButton>
          }
          title="Global Adjustment"
          subheader="These are settings unique to your account"
        />
      </Card>
      <Card className={classes.card} onClick={navigateToPJM}>
        <CardHeader
          avatar={
            <IconButton>
              <Adjust />
            </IconButton>
          }
          action={
            <IconButton>
              <ChevronRight />
            </IconButton>
          }
          title="PJM"
          subheader="These are settings unique to your account"
        />
      </Card>
      <Card className={classes.card} onClick={navigateToVDER}>
        <CardHeader
          avatar={
            <IconButton>
              <Adjust />
            </IconButton>
          }
          action={
            <IconButton>
              <ChevronRight />
            </IconButton>
          }
          title="VDER"
          subheader="These are settings unique to your account"
        />
      </Card>
    </div>
  );
};

export default SettingDetail;
