import { GET_GA_EVENTS_SUCCESS } from '../../actions';
import { Events } from '..';

const INITIAL_STATE: Events = {
  GAEvents: [],
  GAEventsPredicted: []
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_GA_EVENTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
