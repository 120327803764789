import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LoadSummaryTable from '../../components/Explore/LoadSummaryTable';
import Grid from '@material-ui/core/Grid';
const styles = theme => ({});

class LoadSummary extends React.Component {
  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <LoadSummaryTable
              titlePrefix={this.props.titlePrefix}
              annualSummary={this.props.annualSummary}
              monthSummary={this.props.monthSummary}
              touSummary={this.props.touSummary}
              touMonthSummary={this.props.touMonthSummary}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

LoadSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  annualSummary: PropTypes.object.isRequired,
  monthSummary: PropTypes.object.isRequired,
  touSummary: PropTypes.object.isRequired,
  touMonthSummary: PropTypes.object.isRequired,
  titlePrefix: PropTypes.string
};

export default withStyles(styles)(LoadSummary);
