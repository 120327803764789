import { GET_METERS_SUCCESS, GET_METERS_ERROR } from '../../actions/utilityapi';

const INITIAL_STATE = [];

export default function (state = [], action) {
  switch (action.type) {
    case GET_METERS_SUCCESS:
      return action.payload;
    case GET_METERS_ERROR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
