import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generatePJMChargesComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before ICAP Cost',
    label: 'Before ICAP Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Before ITRAN Cost',
    label: 'Before ITRAN Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After ICAP Cost',
    label: 'After ICAP Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'After ITRAN Cost',
    label: 'After ITRAN Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  return chartOptions;
};
