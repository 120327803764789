import { UPDATE_LOCALE } from '../../actions/locale';

const INITIAL_STATE = 'en-US';

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case UPDATE_LOCALE:
      return action.payload;
    default:
      return state;
  }
}
