import React from 'react';
import SolarSummaryTable from './SolarSummaryTable';
import Grid from '@material-ui/core/Grid';
import { MonthlySolarSummary } from '../../types';

interface SolarSummaryProps {
  annualTotalLoad: number;
  monthlyTotalLoad: number;
  solarSummary: {
    avgAnnualProductionPerInterval: number;
    maxAnnualProductionPerInterval: number;
    totalEnergy: number;
  };
  monthlySolarSummary: MonthlySolarSummary;
  solarFrequency: 1 | 4;
}

const SolarSummary: React.FC<SolarSummaryProps> = props => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <SolarSummaryTable
            monthlySolarSummary={props.monthlySolarSummary}
            solarSummary={props.solarSummary}
            annualTotalLoad={props.annualTotalLoad}
            monthlyTotalLoad={props.monthlyTotalLoad}
            solarFrequency={props.solarFrequency}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SolarSummary;
