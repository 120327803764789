import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&::before': {
        visibility: 'hidden'
      },
      boxShadow: 'none'
    },
    rootSummary: {
      padding: 0
    },
    rootDetails: {
      padding: 0,
      boxShadow: 'none'
    }
  })
);

interface ExpandableInputFieldsProps {
  childrenAtTop?: number;
}

/** Embed Input fields into an Accordion to hide some advanced configuration fields -
 * childrenAtTop optional props to select how many children will go in the accordion summary at the top -
 * defaults to 1
 */
const ExpandableInputFields: React.FC<ExpandableInputFieldsProps> = ({ children, childrenAtTop = 1 }) => {
  const classes = useStyles();

  return (
    <Accordion
      classes={{
        root: classes.root
      }}
    >
      <AccordionSummary
        classes={{
          root: classes.rootSummary
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="expandable-input-fields"
      >
        <>{React.Children.toArray(children).slice(0, childrenAtTop)}</>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.rootDetails
        }}
      >
        <div
          style={{
            width: '100%',
            marginBottom: 24,
            paddingLeft: 24,
            borderLeft: '10px solid #eee'
          }}
        >
          {React.Children.toArray(children).slice(childrenAtTop)}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpandableInputFields;
