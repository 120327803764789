import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import ListSearchInput from '../../Table/ListSearchInput';
import BaseTable from '../../Table/BaseTable';
import { stableSort, getSorting } from '../../../utility/Table';
import { proposalColumnSchema } from '../../../utility/Proposal';
import Delete from '@material-ui/icons/Delete';
import Star from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';

import ConfirmPropsalDelete from './ConfirmPropsalDelete';
import ProposalsListFilters from './ProposalsListFilters';
import { GET_ALL_PROPOSALS_IDLE } from '../../../actions/proposals';
import { createErrorSelector } from '../../../selectors';
import ErrorDialog from '../../Common/ErrorDialog';

import { UPDATE_PROPOSALS_LIST_FILTERS, getAllProposals } from '../../../actions/proposals';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
    overflowX: 'auto'
  },
  toolbar: {
    backgroundColor: theme.palette.esap.blue,
    color: '#fff'
  },
  spacer: {
    flex: '1 1 auto'
  },
  table: {},
  white: {
    color: '#fff',
    marginRight: 10
  },
  noProposals: {
    padding: '20px',
    fontStyle: 'italic',
    fontWeight: 500
  },
  filtersChip: {
    color: 'white',
    backgroundColor: theme.palette.esap.blueBright,
    margin: theme.spacing(1),
    '& svg': {
      color: 'white'
    }
  }
}));

const INITIAL_STATE = {
  search: '',
  orderBy: 'updated_on',
  order: 'desc',
  page: 0,
  rowsPerPage: 10,
  isConfirm: false,
  selectedProposal: null,
  isFilterOpen: false,
  timeout: 0,
  userId: ''
};

const ProposalList = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { title, proposals, proposalsMeta } = props;

  const [state, setState] = React.useState({ ...INITIAL_STATE, rowsPerPage: proposalsMeta.pageSize });
  const errorGetAllProposalsSelector = createErrorSelector(['GET_ALL_PROPOSALS']);
  const errorGetAllProposals = useSelector(state => errorGetAllProposalsSelector(state));
  // any row level action functions

  const filters = useSelector(state => state.proposalsListFilters);

  useEffect(() => {
    setState({ ...state, search: filters.search });
  }, []);

  const handleDeleteArchiveOpen = proposal => event => {
    event.stopPropagation();
    setState({ ...state, selectedProposal: proposal, isConfirm: true });
  };

  const handleDeleteArchiveClose = (type = 'close') => {
    setState({ ...state, selectedProposal: null, isConfirm: false });
  };

  const toggleFiltersDrawer = () => {
    setState({ ...state, isFilterOpen: !state.isFilterOpen });
  };

  // const handlePinAction = proposal => event => {
  //   event.stopPropagation();
  //   let { proposal_scenarios, selected_scenarios, ...payload } = proposal;
  //   dispatch(updateProposal({ ...proposal, is_archived: true }));
  // };

  // must declare after the methods used in the action handlers. could move to function that passes callback to delete
  const proposalRowActionSchema = [
    // {
    //   key: 'star',
    //   icon: Star,
    //   actionHandler: handleToggleFavorite,
    //   label: 'Favorite Proposal'
    // },
    {
      key: 'delete',
      icon: Delete,
      actionHandler: handleDeleteArchiveOpen,
      label: 'Delete/Archive Proposal'
    }
  ];

  const handleRowClick = proposal => () => {
    props.history.push('/proposals/' + proposal.public_id);
  };

  const handleSearchChange = event => {
    var searchText = event.target.value;
    if (state.timeout) clearTimeout(state.timeout);
    state.timeout = setTimeout(() => {
      getFilteredProposals({
        search: searchText
      });
      setState({ ...state, search: searchText, timeout: 0 });
      dispatch({ type: UPDATE_PROPOSALS_LIST_FILTERS, payload: { ...filters, search: searchText } });
    }, 1000);

    setState({ ...state, search: event.target.value });
  };

  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc';
    }

    getFilteredProposals({
      sortOn: property,
      orderBy: order
    });
    setState({ ...state, order, orderBy });
  };

  const handleChangePage = (event, page) => {
    getFilteredProposals({
      pageSize: state.rowsPerPage,
      page: page + 1
    });
    setState({ ...state, page });
  };

  const handleChangeRowsPerPage = event => {
    getFilteredProposals({
      pageSize: event.target.value
    });
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const updateProposalsListFilters = payload => {
    getFilteredProposals({
      userId: payload?.user?.value ?? null,
      customerId: payload?.customer?.value ?? null,
      tariffCodes: payload?.tariff ?? null,
      showArchived: payload?.showArchived ?? null
    });

    dispatch({ type: UPDATE_PROPOSALS_LIST_FILTERS, payload: { ...payload, search: state.search } });
  };

  const getFilteredProposals = filterChanges => {
    dispatch(
      getAllProposals({
        includeScenarioSummary: true,
        search: state.search,
        userId: filters?.user?.value,
        customerId: filters?.customer?.value,
        tariffCodes: filters?.tariff,
        showArchived: filters?.showArchived,
        pageSize: state.rowsPerPage,
        sortOn: state.orderBy,
        orderBy: state.order,
        ...filterChanges
      })
    );
  };

  const clearFilters = () => {
    setState({ ...state, search: '' });
    props.resetProposalsList();
  };

  const hasFiltersOn = filters => {
    return (
      filters.user ||
      filters.showArchived ||
      filters.tariff?.length ||
      filters.createdBy !== '' ||
      filters.customer !== '' ||
      (filters.search !== '' && state.timeout === 0)
    );
  };

  const handleCloseGetAllProposalsError = () => {
    dispatch({ type: GET_ALL_PROPOSALS_IDLE });
  };

  return (
    <Paper>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit">
          {title ? title : 'Proposals'}
        </Typography>
        <div className={classes.spacer} />
        {!props.loading && hasFiltersOn(filters) && (
          <Tooltip title="Clear Filters">
            <Chip
              className={classes.filtersChip}
              label={`${proposalsMeta.total} Filtered Results`}
              onDelete={clearFilters}
            />
          </Tooltip>
        )}
        <ListSearchInput handleSearchChange={handleSearchChange} search={state.search} />

        <Tooltip title="Filter list">
          <IconButton
            onClick={toggleFiltersDrawer}
            size="small"
            className={classes.white}
            aria-label="Filter Proposal List"
          >
            <FilterIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      {props.loading && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {!props.loading && proposals?.length === 0 && <div className={classes.noProposals}>No Proposals Found</div>}
      {!props.loading && proposals?.length !== 0 && (
        <BaseTable
          className={classes.table}
          data={proposals}
          onRowClick={handleRowClick}
          onRequestSort={handleRequestSort}
          columnSchema={proposalColumnSchema}
          order={state.order}
          orderBy={state.orderBy}
          page={proposalsMeta.page - 1}
          rowsPerPage={state.rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          dataCount={proposalsMeta.total}
          rowActionSchema={proposalRowActionSchema}
        />
      )}
      <ConfirmPropsalDelete
        open={state.isConfirm}
        handleClose={handleDeleteArchiveClose}
        selectedProposal={state.selectedProposal}
      />
      <Drawer
        classes={{ paper: classes.sidenav }}
        anchor="right"
        open={state.isFilterOpen}
        onClose={toggleFiltersDrawer}
      >
        <ProposalsListFilters
          toggleFiltersDrawer={toggleFiltersDrawer}
          filters={filters}
          updateProposalsListFilters={updateProposalsListFilters}
        />
      </Drawer>
      <ErrorDialog
        open={errorGetAllProposals}
        errorMsg="Oops, we are having trouble loading proposals! Please contact a site admin if problem persists."
        onClose={handleCloseGetAllProposalsError}
      />
    </Paper>
  );
};

ProposalList.propTypes = {
  proposals: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  history: PropTypes.object,
  loading: PropTypes.bool
};

export default ProposalList;
