import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { Period } from '../Create/TariffFactoryContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  container: {
    padding: theme.spacing(3)
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  }
}));

interface TariffSubscriptionsProps {
  subscriptions: Period[];
  overageCharges: { charges: string; type: string };
}

const TariffSubscriptions: React.FC<TariffSubscriptionsProps> = ({ subscriptions, overageCharges }) => {
  const classes = useStyles();
  const generateRow = (sub: Period) => {
    const { name, reservationSize, charges } = sub;
    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell align="center">{reservationSize}</TableCell>
        <TableCell align="center">{charges}</TableCell>
      </TableRow>
    );
  };
  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>Subscriptions</Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">Reservation Size (kW)</TableCell>
            <TableCell align="center">Charges ($/kW)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map(sub => {
            return generateRow(sub);
          })}
        </TableBody>
      </Table>
      <Toolbar className={classes.toolbar} style={{ marginTop: 24 }}>
        Overage Charges
      </Toolbar>
      {Number(overageCharges.charges) > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Charges ($ or $/kW)</TableCell>
              <TableCell align="center">Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">{overageCharges.charges}</TableCell>
              <TableCell align="center">{overageCharges.type}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {Number(overageCharges.charges) == 0 && (
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          As you didnt enter an override, the Demand Charge Overage will be charged at 2x the average per kW rate based
          on the selected subscription.
        </Alert>
      )}
    </Paper>
  );
};

export default TariffSubscriptions;
