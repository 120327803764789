import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MATERIAL UI IMPORTS
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { StoreState } from '../../reducers';

import { createUtilityInfo } from '../../actions/utilities';

import UtilityDetailTariffContainer from '../../components/Utilities/Detail/UtilityDetailTariffContainer';
import UtilityOverviewCard from '../../components/Utilities/Detail/UtilityOverviewCard';
import PDPContainer from '../../components/Data/PDPContainer';
import BIPContainer from '../../components/Data/BIPContainer';
import { UtilityInfo } from '../../types';
import { useHistory, useParams } from 'react-router';
import { useUpdateUtilityInfo } from '../../queries/utilities';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  content: {
    padding: theme.spacing(3)
  }
}));

// interface UtilityDetailsProps {
//   utilityDetails: UtilityDetails;
//   utilityInfo: UtilityInfo;
//   calendars: CalendarResult[];
// }
const UtilityDetail: React.FC = () => {
  const history = useHistory();
  let { id } = useParams<{ id?: string }>();

  const tariffs = useSelector((state: StoreState) => state.tariffs);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = useState<string>('overview');

  const { mutate: updateUtilityInfo } = useUpdateUtilityInfo(id ? parseInt(id) : 0);

  const handleTariffRowClick = (tariff: any) => () => {
    if (tariff.public_id) {
      history.push('/tariffs/' + tariff.public_id + '?manual=true&effectiveOn=' + tariff.effective_date);
    } else {
      history.push('/tariffs/' + tariff.source_id + '?effectiveOn=' + tariff.effective_date);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
    setValue(value);
  };

  const handleCreateUtilityInfo = (utilityInfo: any) => {
    dispatch(createUtilityInfo(utilityInfo));
  };

  const handleUpdateUtilityInfo = (utilityInfo: UtilityInfo) => {
    updateUtilityInfo(utilityInfo);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs variant="fullWidth" value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="Overview" value="overview" />
          <Tab label="Tariffs" value="tariffs" />
          <Tab label="PDP" value="pdp" />
          <Tab label="BIP" value="bip" />
        </Tabs>
      </AppBar>
      {value === 'overview' && (
        <div className={classes.content}>
          <UtilityOverviewCard />
        </div>
      )}

      {value === 'tariffs' && (
        <div className={classes.content}>
          <div style={{ marginTop: 8 * 3 }}>
            <UtilityDetailTariffContainer tariffs={tariffs} handleRowClick={handleTariffRowClick} />
          </div>
        </div>
      )}

      {value === 'pdp' && (
        <div className={classes.content}>
          <PDPContainer
            handleUpdateUtilityInfo={handleUpdateUtilityInfo}
            handleCreateUtilityInfo={handleCreateUtilityInfo}
          />
        </div>
      )}

      {value === 'bip' && (
        <div className={classes.content}>
          <BIPContainer
            handleUpdateUtilityInfo={handleUpdateUtilityInfo}
            handleCreateUtilityInfo={handleCreateUtilityInfo}
          />
        </div>
      )}
    </div>
  );
};

export default UtilityDetail;
