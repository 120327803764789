import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { calculateTotal } from '../../../utility/Tariff';
import { Season } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3)
    },
    container: {
      padding: 20
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    }
  })
);

interface SeasonConsumptionProps {
  baseAdjustment: number;
  season: Season;
}

const SeasonConsumption: React.FC<SeasonConsumptionProps> = ({ baseAdjustment, season }) => {
  const classes = useStyles();

  const touPeriodNames = Object.keys(season).filter(
    item => item !== 'overview' && item !== 'NON_COINCIDENTAL' && item !== 'FLAT_RATE'
  );

  const getSeasonConsumptionFlatRateTotal = (): number => {
    if (!season['FLAT_RATE']) return 0;

    return season['FLAT_RATE'].reduce((total, rate) => {
      if (rate.type !== 'CONSUMPTION_BASED') return total;
      return total + rate.amount;
    }, 0);
  };

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>{season.overview.name}</Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            {touPeriodNames.map(name => {
              return <TableCell key={name}>{name.replace('_', ' ')}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {touPeriodNames.map(name => {
              return (
                <TableCell key={name}>
                  ${' '}
                  {parseFloat(
                    calculateTotal(
                      season[name],
                      'CONSUMPTION_BASED',
                      baseAdjustment + getSeasonConsumptionFlatRateTotal()
                    ).toFixed(5)
                  )}{' '}
                  / kWh
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SeasonConsumption;
