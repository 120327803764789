import React from 'react';

//MaterialUI
import { makeStyles, Theme } from '@material-ui/core/styles';
import BatteryFull from '@material-ui/icons/BatteryFull';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import WbSunny from '@material-ui/icons/WbSunny';
import GridOn from '@material-ui/icons/GridOn';

import { ScenarioInternal } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  techBadges: {
    width: 50,
    display: 'inline-block',
    top: '2px',
    position: 'relative',
    '& svg': {
      fontSize: '.7rem',
      color: '#aaa'
    }
  },
  techOn: {
    color: `${(theme.palette as any).esap.greenLight} !important`
  }
}));

interface Props {
  scenario: ScenarioInternal;
}

const ScenarioTechBadges: React.FC<Props> = props => {
  const classes = useStyles();
  const { scenario } = props;

  return (
    <div className={classes.techBadges} data-testid="tech-badges">
      <BatteryFull className={scenario.assumptions.isBatt ? classes.techOn : ''} />
      <WbSunny className={scenario.assumptions.isPV ? classes.techOn : ''} />
      <GridOn className={scenario.assumptions.isGenset || scenario.assumptions.hasGridOutages ? classes.techOn : ''} />
      <DirectionsCar className={scenario.assumptions.isEV ? classes.techOn : ''} />
    </div>
  );
};

export default ScenarioTechBadges;
