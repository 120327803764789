import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Save, Delete, Add } from '@material-ui/icons';
import ProposalAssumptionContainer from './ProposalAssumptionContainer';
import { createProposalScenario, updateProposalScenario, deleteProposalScenario } from '../../../actions/scenarios';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import AccessControl from '../../../utility/AccessControl';
import TemplatesDrawer from './TemplatesDrawer';

const style = theme => ({
  root: {
    width: '100%'
  },
  whiteIcon: {
    color: '#fff'
  },
  flex: {
    flex: 1
  },
  toolbarMain: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: '#fff'
  },
  toolbarSecondary: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #e5e5e5'
  },
  chip: {
    marginLeft: theme.spacing(1)
  }
});

class ProposalScenarioContainerNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateView: false,
      selectedScenario: null,
      isConfirm: false,
      openTemplates: false
    };
  }

  handleToggleCreateView = () => {
    this.setState({
      isCreateView: !this.state.isCreateView
    });
  };

  handleInputUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleOpenTemplates = () => {
    this.setState({ openTemplates: true });
  };

  toggleDrawer = () => {
    this.setState({
      openTemplates: !this.state.openTemplates
    });
  };

  saveScenarioAssumptions = scenario => {
    if (this.state.selectedScenario && this.state.selectedScenario.public_id) {
      this.props.updateProposalScenario(scenario, this.props.proposal.public_id);
    } else {
      if (
        this.props.proposal.proposal_scenarios &&
        this.props.proposal.proposal_scenarios.length === 0 &&
        !scenario.is_template
      ) {
        scenario.is_status_quo = true;
      }
      this.props.createProposalScenario(scenario, this.props.proposal.public_id);
    }
    this.handleNewScenarioSave();
  };

  handleScenarioDelete = scenario => () => {
    if (scenario && scenario.public_id) {
      this.props.deleteProposalScenario(scenario.public_id, this.props.proposal.public_id);
    }
  };

  handleScenarioClick = scenario => () => {
    this.setState({
      isCreateView: true,
      selectedScenario: scenario
    });
  };

  handleNewScenarioSave = () => {
    this.setState({
      isCreateView: false,
      selectedScenario: null
    });
  };

  handleNewScenarioCancel = () => {
    this.setState({
      isCreateView: false,
      selectedScenario: null
    });
  };

  handleScenarioSaveStep = () => {
    if (this.props.handleNext) {
      this.props.handleNext();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <TemplatesDrawer
          open={this.state.openTemplates}
          onClose={this.toggleDrawer}
          tariffs={this.props.proposal.proposal_tariffs}
          saveScenarioAssumptions={this.saveScenarioAssumptions}
          proposal={this.props.proposal}
        />

        <div className={classes.root}>
          <AccessControl requiredPermissions={['editor', 'admin']}>
            <div className="button-container">
              <Fab
                data-testid="save-button"
                size="small"
                color="secondary"
                aria-label="Save"
                className="button-white"
                onClick={this.handleScenarioSaveStep}
                disabled={
                  !this.props.proposal.proposal_scenarios || this.props.proposal.proposal_scenarios.length === 0
                }
              >
                <Save />
              </Fab>
            </div>
          </AccessControl>
          <Paper className={classes.root}>
            {!this.state.isCreateView && (
              <div>
                <Toolbar className={classes.toolbarMain}>
                  <Typography color="inherit" variant="h6" className={classes.flex}>
                    Proposal Scenarios
                  </Typography>
                  <AccessControl requiredPermissions={['editor', 'admin']}>
                    {!this.state.isCreateView && (
                      <div>
                        <Button
                          aria-label="Select Template"
                          className={classes.whiteIcon}
                          onClick={this.handleOpenTemplates}
                        >
                          Select Templates
                        </Button>
                      </div>
                    )}
                  </AccessControl>
                  <AccessControl requiredPermissions={['editor', 'admin']}>
                    {!this.state.isCreateView && (
                      <IconButton
                        aria-label="Add New Scenario to new Proposal"
                        className={classes.whiteIcon}
                        onClick={this.handleToggleCreateView}
                      >
                        <Add />
                      </IconButton>
                    )}
                  </AccessControl>
                </Toolbar>
                <List component="div">
                  {this.props.proposal.proposal_scenarios.map((item, index) => {
                    return (
                      <ListItem key={item.public_id} button divider onClick={this.handleScenarioClick(item)}>
                        <ListItemText>
                          <span style={{ backgroundColor: '#ccc', padding: 5 }}>{item.name}</span>
                          {item.is_status_quo && <Chip label="Status Quo" className={classes.chip} color="primary" />}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton aria-label="Delete" onClick={this.handleScenarioDelete(item)}>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                  {(!this.props.proposal ||
                    !this.props.proposal.proposal_scenarios ||
                    this.props.proposal.proposal_scenarios.length === 0) && (
                    <ListItem onClick={this.handleToggleCreateView} style={{ cursor: 'pointer', marginLeft: 8 }}>
                      <Typography variant="h6">Please Create Your Scenarios</Typography>
                    </ListItem>
                  )}
                </List>
              </div>
            )}
            {this.state.isCreateView && (
              <div>
                <ProposalAssumptionContainer
                  proposal={this.props.proposal}
                  scenario={this.state.selectedScenario}
                  saveScenarioAssumptions={this.saveScenarioAssumptions}
                  handleCancel={this.handleNewScenarioCancel}
                  utility={this.props.utility}
                />
              </div>
            )}
          </Paper>
        </div>
      </>
    );
  }
}

ProposalScenarioContainerNew.propTypes = {
  classes: PropTypes.object.isRequired,
  proposal: PropTypes.object.isRequired,
  handleNext: PropTypes.func
};

const mapStateToProps = state => {
  return {
    utility: state.utility
  };
};

export default connect(mapStateToProps, {
  createProposalScenario,
  updateProposalScenario,
  deleteProposalScenario
})(withStyles(style)(ProposalScenarioContainerNew));
