import { GET_METER_INTERVAL_SUCCESS } from '../../actions/utilityapi';

export default function (state = [], action) {
  switch (action.type) {
    case GET_METER_INTERVAL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
