import React from 'react';
import { PeriodDetail } from './TariffDetailTOU';

interface CellDetailProps {
  id: Readonly<string>;
  hour: Readonly<number>;
  month: Readonly<number>;
  period?: Readonly<PeriodDetail>;
  setSelected: any;
}

const CellDetail: React.FC<CellDetailProps> = ({ hour, month, id, period, setSelected }) => {
  return (
    <div
      className="cell-grid-item"
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#C7D5DD',
        border: '1px solid grey',
        width: '100%',
        height: '100%',
        gridColumn: `${hour + 1} / span 1`,
        gridRow: `${month + 1} / span 1`
      }}
      onMouseOver={() => setSelected(period)}
    >
      1
    </div>
  );
};

export default CellDetail;
