import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import CustomerContainer from './CustomerContainer';

import { pushBreadcrumb, popBreadcrumb, resetBreadcrumb } from '../../../actions/breadcrumbs';

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5)
  }
});

const CustomerCreate = props => {
  const { classes, resetBreadcrumb, pushBreadcrumb, location } = props;

  useLayoutEffect(() => {
    // check if previous breadcrumbs should be popped or not
    resetBreadcrumb();
    pushBreadcrumb('Customers', '/customers');

    pushBreadcrumb('Create Customer', location.pathname);
  }, [location.pathname, pushBreadcrumb, resetBreadcrumb]);

  const handleNext = () => {
    props.history.push('/customers');
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.container}>
        <CustomerContainer handleNext={handleNext} />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  breadcrumbsTrail: state.breadcrumbsTrail
});

export default withStyles(styles)(
  connect(mapStateToProps, { pushBreadcrumb, popBreadcrumb, resetBreadcrumb })(CustomerCreate)
);
