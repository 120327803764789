import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Descendant, Element as SlateElement } from 'slate';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import BoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
// import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatSizeIcon from '@material-ui/icons/FormatSize';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { PostElement } from '../../../../types/slate';
import { ScenarioInternal } from '../../../../types';
import { useDispatch } from 'react-redux';
import { updateProposalScenario } from '../../../../actions';
import auth0Client from '../../../../utility/Auth';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  toolbar: {
    backgroundColor: (theme.palette as any).esap.blueDim,
    display: 'flex',
    color: '#fff',
    '& button': {
      color: 'white'
    }
  }
}));

const mapIcons = (icon: string) => {
  switch (icon) {
    case 'format_bold':
      return <BoldIcon />;
    case 'format_italic':
      return <FormatItalicIcon />;
    case 'format_underlined':
      return <FormatUnderlinedIcon />;
    case 'looks_one':
      return <FormatSizeIcon />;
    // case 'format_quote':
    // return <FormatQuoteIcon />;
    case 'format_list_numbered':
      return <FormatListNumberedIcon />;
    case 'format_list_bulleted':
      return <FormatListBulletedIcon />;
    default:
      return null;
  }
};

interface NotePadProps {
  scenario: ScenarioInternal;
  proposalId: string;
}

const ScenarioNotePad: React.FC<NotePadProps> = ({ scenario, proposalId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [post, setPost] = useState<Descendant[]>(initialValue);
  const [feed, setFeed] = useState<PostElement[]>([]);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);
  const feedEditor = useMemo(() => withReact(createEditor()), []);

  // get the feed from the loaded scenario if it exists
  useEffect(() => {
    if (scenario?.notes && scenario.notes.length > 0 && feed.length === 0) {
      setFeed(JSON.parse(scenario.notes));
    }
  }, [scenario.public_id, scenario?.notes, feed]);

  //save feed on change
  const handleSaveFeed = (currentFeed: PostElement[]): void => {
    if (currentFeed.length > 0) {
      const updatedScenario = { ...scenario, notes: JSON.stringify(currentFeed) };
      dispatch(updateProposalScenario(updatedScenario, proposalId));
    }
  };

  const handlePost = () => {
    const newPost = {
      type: 'post',
      id: uuid(),
      children: post,
      user: auth0Client?.profile?.nickname ?? 'Unknown user',
      date: moment().format('dddd, MMMM Do YYYY, h:mm:ss a')
    } as const;
    const newFeed = [newPost, ...feed];
    setFeed(newFeed);
    handleSaveFeed(newFeed);
    setPost(initialValue);
  };

  const handleSaveNotes = (value: Descendant[]) => {
    console.log('valval', value);
  };

  // const handleDeleteNote = (id: string) => () => {
  //   console.log(id);
  // };

  return (
    <>
      <Paper className={classes.root}>
        <Slate editor={editor} value={post} onChange={value => setPost(value)}>
          <Toolbar className={classes.toolbar}>
            <div style={{ flex: 1 }}>
              <MarkButton format="bold" icon="format_bold" />
              <MarkButton format="italic" icon="format_italic" />
              <MarkButton format="underline" icon="format_underlined" />
              <BlockButton format="heading-one" icon="looks_one" />
              {/* <BlockButton format="block-quote" icon="format_quote" /> */}
              <BlockButton format="numbered-list" icon="format_list_numbered" />
              <BlockButton format="bulleted-list" icon="format_list_bulleted" />
            </div>
            <Button onClick={handlePost}>Add Note</Button>
          </Toolbar>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            style={{ padding: '16px 24px' }}
            spellCheck
            autoFocus
            placeholder="Type your notes here..."
          />
        </Slate>
      </Paper>
      <Paper>
        <Slate editor={feedEditor} value={feed} onChange={handleSaveNotes}>
          <Editable renderElement={renderElement} renderLeaf={renderLeaf} style={{ padding: '16px 24px' }} readOnly />
        </Slate>
      </Paper>
    </>
  );
};

const toggleBlock = (editor: Editor, format: any) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) ? n.type : 'dfgdfgdfsgdg'),
    split: true
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: Editor, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: Editor, format: any) => {
  const [match] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
  });

  return !!match;
};

const isMarkActive = (editor: Editor, format: any) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }: { attributes: any; children: any; element: any }) => {
  switch (element.type) {
    // case 'block-quote':
    // return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'post':
      return (
        <Post {...attributes} date={element.date} user={element.user} id={element.id}>
          {children}
        </Post>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: { attributes: any; children: any; leaf: any }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: { format: any; icon: any }) => {
  const editor = useSlate();
  return (
    <IconButton
      // active={isBlockActive(editor, format)}
      onClick={event => {
        toggleBlock(editor, format);
      }}
    >
      {mapIcons(icon)}
    </IconButton>
  );
};

const MarkButton = ({ format, icon }: { format: any; icon: any }) => {
  const editor = useSlate();
  return (
    <IconButton
      style={{ color: isMarkActive(editor, format) ? 'rgb(255,160,47)' : 'white' }}
      onClick={event => {
        toggleMark(editor, format);
      }}
    >
      {mapIcons(icon)}
    </IconButton>
  );
};

const initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];

const Post: React.FC = ({ children, date, user, id }: any) => (
  <div
    style={{
      border: '1px solid rgba(204, 204, 204,.5)',
      borderRadius: 10,
      padding: '12px 12px 0 24px',
      marginBottom: 12
    }}
  >
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <span style={{ fontWeight: 500 }}>{user ?? 'Unkown user'}</span>
        <span style={{ color: 'rgba(52,52,52,.4)' }}> commented:</span>
      </div>
      <div style={{ color: 'rgba(52,52,52,.4)' }}>{date}</div>
    </div>
    {children}
  </div>
);

export default ScenarioNotePad;
