import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Plot from 'react-plotly.js';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import { exportCSVFile } from '../../../utility/General';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
const Plot = createPlotlyComponent(Plotly);

const layout = {
  showlegend: false,
  height: 280,
  margin: {
    t: 20,
    l: 60,
    r: 20,
    b: 40
  },
  xaxis: {
    //tickangle: -45
  }
};

// const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const ScenarioMonthlyCycles = props => {
  const { scenarioData } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const buildData = scenarioData => {
      if (!scenarioData || Object.keys(scenarioData).length === 0) {
        return;
      }
      const dataParts = [];
      let proposalMonths = scenarioData['Months'];
      proposalMonths.forEach((month, index) => {
        // let monthIndex = +month.split('-')[1] - 1;
        dataParts.push({
          month: month,
          cycles: +scenarioData['Cycles'][index].toFixed(2)
        });
      });
      let data = [];
      data.push({
        type: 'scatter',
        x: dataParts.map(x => moment(moment(x.month).valueOf()).format('YYYY-MM-DD')),
        y: dataParts.map(x => x.cycles),
        line: {
          color: '#222'
        }
      });

      setData(data);
    };
    buildData(scenarioData);
  }, [scenarioData]);

  const handleDownload = () => {
    const headers = ['Datetime', 'Cycles per Month'];
    let downloadData = [];
    data[0].x.forEach((date, index) => {
      const row = [date];
      data.forEach(item => {
        row.push(item.y[index]);
      });
      downloadData.push(row);
    });

    exportCSVFile(headers, downloadData, 'Cycles per Month');
  };

  return (
    <Paper>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          Cycles per Month
        </Typography>
        <IconButton aria-label="Download" onClick={handleDownload}>
          <InsertDriveFile />
        </IconButton>
      </Toolbar>
      <div style={{ height: 300 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={data}
          layout={layout}
          config={{ displayModeBar: false }}
        />
      </div>
    </Paper>
  );
};

ScenarioMonthlyCycles.propTypes = {
  scenarioData: PropTypes.object
};

export default ScenarioMonthlyCycles;
