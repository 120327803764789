import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generatePDPComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before Demand Cost PDP Reservation',
    label: 'Before Demand Cost PDP Reservation',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Demand Cost PDP Reservation',
    label: 'After Demand Cost PDP Reservation',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Demand Credit PDP',
    label: 'Before Demand Credit PDP',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Demand Credit PDP',
    label: 'After Demand Credit PDP',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Energy Cost PDP',
    label: 'Before Energy Cost PDP',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Cost PDP',
    label: 'After Energy Cost PDP',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  return chartOptions;
};
