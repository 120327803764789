import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFrequentlyUsedTariffs } from "../../../actions";
import { useFavoritesTariffs } from "../../../hooks/useFavoritesTariffs";
import { StoreState } from "../../../reducers";
import { FavoriteTariff, FrequentUsedTariff, ESAPTariff } from "../../../types";
import { getSavedItem } from "../../../utility/localStorage";
import TariffChipsList from "./TariffChipsList";

interface FavoriteAndFrequentTariffsProps<T> {
  handleTariffOnClick: (tariff: T) => void;
};

type Tariff = ESAPTariff | FavoriteTariff;

const FavoriteAndFrequentTariffs = <T extends unknown>(props: FavoriteAndFrequentTariffsProps<T>) => {
  const { handleTariffOnClick } = props;
  const dispatch = useDispatch();
  const frequentTariffs = useSelector((state: StoreState) => state.frequentlyUsedTariffs);

  const [savedFavoritesTariff, onFavoriteClick, TARIFF_STORAGE_KEY] = useFavoritesTariffs();
  const [frequentlyUsedTariff, setFrequentlyUsedTariff] = useState<FrequentUsedTariff[]>(frequentTariffs);
  const [favoritesTariff, setFavoritesTariff] = useState<Tariff[]>(savedFavoritesTariff);

  useEffect(() => {
    const refreshFavorites = () => setFavoritesTariff(getSavedItem<Tariff[]>(TARIFF_STORAGE_KEY) || []);

    window.addEventListener('FAVORITES-TARIFF-UPDATED', refreshFavorites);

    return () => window.removeEventListener('FAVORITES-TARIFF-UPDATED', refreshFavorites);
  }, []);

  useEffect(() => {
    if (!frequentTariffs.length) {
      dispatch(fetchFrequentlyUsedTariffs());
    }

    setFrequentlyUsedTariff(frequentTariffs.map((tariff) => ({ ...tariff, isFavorite: favoritesTariff.some((item) => item.code === tariff.code && item.effective_date === tariff.effective_date) })));
  }, [frequentTariffs, dispatch, favoritesTariff]);

  const handleFavoriteClick = (tariff: Tariff) => {
    const isFavorite = onFavoriteClick(tariff);

    setFrequentlyUsedTariff(frequentlyUsedTariff.map((freqTariff) => {
      if (freqTariff.code === tariff.code && freqTariff.effective_date === tariff.effective_date) {
        return { ...freqTariff, isFavorite: !isFavorite };
      }

      return freqTariff;
    }));
  }

  const tariffOnClick = (tariff?: Tariff) => {
    tariff && handleTariffOnClick(tariff as T)
  }

  return (
    <>
      <TariffChipsList
        tariffsList={favoritesTariff}
        title="Favorites"
        handleFavoriteClick={handleFavoriteClick}
        handleTariffOnClick={tariffOnClick}
        emptyState="No favorites tariffs yet"
      />

      <TariffChipsList
        tariffsList={frequentlyUsedTariff}
        title="Frequently Used"
        handleFavoriteClick={handleFavoriteClick}
        handleTariffOnClick={tariffOnClick}
      />
    </>
  )
}

export default FavoriteAndFrequentTariffs;
