import { UploadDataFileActionTypes } from '../../actions/uploadDataFile';

const INITIAL_STATE = {
  start: '',
  data: [],
  frequency: '',
  logs: ''
};

export default function cleanedDataReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case UploadDataFileActionTypes.UPLOAD_LOAD_DATA_FILE_SUCCESS:
    case UploadDataFileActionTypes.UPLOAD_SOLAR_DATA_FILE_SUCCESS:
      // case UploadDataFileActionTypes.UPLOAD_DCFC_DATA_FILE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
