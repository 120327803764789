import React, { useEffect } from 'react';
// import moment from 'moment';

// Material UI
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

interface PJMEventsDialogProps {
  title: string;
  open: boolean;
  onClose: any;
  events: string[];
}

const PJMEventsDialog: React.FC<PJMEventsDialogProps> = ({ open, onClose, events, title }) => {
  // const [newDate, setNewdate] = React.useState('');
  const [dates, setDates] = React.useState(['']);
  // const [error, setError] = React.useState(false);

  useEffect(() => {
    if (events) {
      setDates(events);
    }
  }, [events]);

  // const regex = /\d+\/\d\d\/\d\d\d\d\s\d\d:\d\d/;

  // const sortDates = (dates: string[]): string[] => {
  //   return dates.sort((a, b) => moment(a).unix() - moment(b).unix());
  // };

  // const handleChange = (e: any) => {
  //   setNewdate(e.target.value);
  //   if (regex.test(e.target.value)) {
  //     setError(false);
  //   }
  // };

  // const importDefaultEvents = () => {
  //   setDates(events);
  // };

  const handleClose = () => {
    // setNewdate('');
    onClose();
  };

  // const handleDateAdd = () => {
  //   if (!regex.test(newDate)) {
  //     setError(true);
  //     return;
  //   }
  //   if (dates && newDate.length > 0) {
  //     setDates(sortDates([...dates, newDate]));
  //   }
  //   setNewdate('');
  // };

  // const handleDateDelete = (index: number) => {
  //   setDates(dates.slice(0, index).concat(dates.slice(index + 1)));
  // };

  // const handleSaveEvents = () => {
  //    handleAssumptionUpdateByKey(dates);
  //   onClose();
  // };
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="edit-pjm-events">
        <DialogTitle id="form-dialog-title">PJM {title} Events</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Helper text here.</DialogContentText> */}
          {/* <Grid container justify="center" alignContent="center" alignItems="center"> */}
          {/* <Grid item xs={10}>
              <TextField
                id="newDate"
                name="newDate"
                autoFocus={true}
                label="Add Event Date and Time"
                helperText="(mm/dd/yyyy HH:mm)"
                value={newDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
                error={error}
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={handleDateAdd}>Add</Button>
            </Grid>
          </Grid> */}
          <List dense={true}>
            {dates &&
              dates.map((date, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText primary={date} />
                    {/* <ListItemSecondaryAction>
                      <IconButton aria-label="Delete" onClick={() => handleDateDelete(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction> */}
                  </ListItem>
                );
              })}
          </List>
          {/* <Button variant="contained" style={{ marginTop: 16 }} onClick={importDefaultEvents}>
            Import default PDP Events
          </Button> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEvents} color="primary">
            Save
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PJMEventsDialog;
