import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generatePerfectKnowledgeComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Perfect Knowledge NC Demand Savings',
    label: 'Perfect Knowledge NC Demand Savings',
    include: true,
    highlight: false,
    category: 'Perfect Knowledge'
  });
  chartOptions.push({
    key: 'Perfect Knowledge On-Peak Demand Savings',
    label: 'Perfect Knowledge On-Peak Demand Savings',
    include: true,
    highlight: false,
    category: 'Perfect Knowledge'
  });
  chartOptions.push({
    key: 'Perfect Knowledge Mid-Peak Demand Savings',
    label: 'Perfect Knowledge Mid-Peak Demand Savings',
    include: true,
    highlight: false,
    category: 'Perfect Knowledge'
  });
  chartOptions.push({
    key: 'Perfect Knowledge Energy Savings',
    label: 'Perfect Knowledge Energy Savings',
    include: true,
    highlight: false,
    category: 'Perfect Knowledge'
  });
  chartOptions.push({
    key: 'Realtime NC Demand Savings',
    label: 'Realtime NC Demand Savings',
    include: true,
    highlight: false,
    category: 'Realtime'
  });
  chartOptions.push({
    key: 'Realtime On-Peak Demand Savings',
    label: 'Realtime On-Peak Demand Savings',
    include: true,
    highlight: false,
    category: 'Realtime'
  });
  chartOptions.push({
    key: 'Realtime Mid-Peak Demand Savings',
    label: 'Realtime Mid-Peak Demand Savings',
    include: true,
    highlight: false,
    category: 'Realtime'
  });
  chartOptions.push({
    key: 'Realtime Energy Savings',
    label: 'Realtime Energy Savings',
    include: true,
    highlight: false,
    category: 'Realtime'
  });
  chartOptions.push({
    key: '% from Perfect Knowledge - NC Demand Savings',
    label: '% from Perfect Knowledge - NC Demand Savings',
    include: true,
    highlight: false,
    category: 'Perfect Knowledge'
  });
  chartOptions.push({
    key: '% from Perfect Knowledge - On-Peak Demand Savings',
    label: '% from Perfect Knowledge - On-Peak Demand Savings',
    include: true,
    highlight: false,
    category: 'Perfect Knowledge'
  });
  chartOptions.push({
    key: '% from Perfect Knowledge - Mid-Peak Demand Savings',
    label: '% from Perfect Knowledge - Mid-Peak Demand Savings',
    include: true,
    highlight: false,
    category: 'Perfect Knowledge'
  });
  chartOptions.push({
    key: '% from Perfect Knowledge - Energy Savings',
    label: '% from Perfect Knowledge - Energy Savings',
    include: true,
    highlight: false,
    category: 'Perfect Knowledge'
  });
  return chartOptions;
};
