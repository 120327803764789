import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HotTable } from '@handsontable/react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Save from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getGlobalSettingByKey, updateGlobalSetting } from '../../actions/globalsettings';
import { createLoadingSelector } from '../../selectors';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import AccessControl, { isAdmin } from '../../utility/AccessControl';

import { regexpValidator } from '../../utility/Validator';
import { StoreState } from '../../reducers';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  info: {
    margin: theme.spacing(3)
  },
  container: {
    padding: 20,
    maxWidth: 1000,
    margin: '0 auto'
  }
}));

// Handsontable validation section
const runValidation = (validator: any) => (value: any, callback: any) => callback(validator(value));
const dateValidatorRegExp = /(\d{4})-(\d{2})-(\d{2})$/;

const buildColumns = () => {
  return [
    {
      type: 'date',
      dateFormat: 'YYYY-MM-DD',
      correctFormat: true,
      allowEmpty: false,
      readOnly: !isAdmin(),
      colWidths: 50,
      validator: runValidation(regexpValidator(dateValidatorRegExp)),
      allowInvalid: false
    },
    {
      type: 'text',
      allowEmpty: false,
      readOnly: !isAdmin(),
      colWidths: 50,
      allowInvalid: false
    }
  ];
};

const keys = ['ICAP', 'JCPL', 'PSEG', 'ACE', 'REC'];

type TableData = (string | number)[][];

const PJMEvents: React.FC = () => {
  const location = useLocation();

  const setting = useSelector((state: StoreState) => state.setting);
  const loadingSelector = createLoadingSelector(['GET_GLOBAL_SETTING_BY_KEY']);
  const isLoading = useSelector((state: StoreState) => loadingSelector(state));

  const [data, setData] = useState<TableData>([]);
  const [settings, setSettings] = useState({});
  const [message, setMessage] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [hasChanged, setHasChanged] = useState(false);
  const tableRef = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  // get the proper setting on page load and tab changes
  useEffect(() => {
    dispatch(getGlobalSettingByKey(keys[tabIndex]));
  }, [getGlobalSettingByKey, tabIndex]);

  // build data
  useEffect(() => {
    const generateGrid = () => setting?.value ?? [];

    const generateVerticalSettings = () => {
      return {
        colHeaders: ['Date', 'Hours'],
        columnSorting: {
          sortEmptyCells: false,
          initialConfig: {
            column: 0,
            sortOrder: 'asc'
          }
        },
        contextMenu: {
          items: {
            row_above: {
              name: 'Insert row above this one'
            },
            row_below: {},
            remove_row: {}
          }
        },
        stretchH: 'all',
        className: 'htCenter htMiddle',
        rowHeights: 15,
        columns: buildColumns()
      };
    };

    const updatedSettings = generateVerticalSettings();
    const updatedData = generateGrid();
    setSettings(updatedSettings);
    setData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting, tabIndex]);

  // breadcrumbs
  useEffect(() => {
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Settings', '/settings'));
    dispatch(pushBreadcrumb('PJM', location.pathname));
  }, [location.pathname, pushBreadcrumb]);

  // on local state update, update the settings too, so proper row can be added/deleted
  // useEffect(() => {
  //   //setSettings(generateVerticalSettings());
  //   setHasChanged(false);
  // }, [data]);

  const handleModifyData = (index: any, amount: any) => {
    setData(data);
    setHasChanged(true);
  };

  const sortDates = <T extends (string | number)[]>(data: T[]): T[] => {
    return data.sort((a, b) => moment(a).unix() - moment(b).unix());
  };

  const handleSave = () => {
    setData(sortDates(data));
    dispatch(
      updateGlobalSetting(
        {
          ...setting,
          value: data
        },
        {
          onSuccess: () => {
            setMessage('Data Saved Successfully');
            setHasChanged(false);
          },
          onError: () => {
            setMessage('Error');
          }
        }
      )
    );
  };

  const handleTabChange = (e: any, value: any) => {
    setTabIndex(value);
  };
  return (
    <Paper className={classes.root}>
      <AccessControl requiredPermissions={['editor', 'admin']}>
        <div className="button-container">
          <Fab size="small" disabled={!hasChanged} color="primary" aria-label="Save" onClick={handleSave}>
            <Save />
          </Fab>
        </div>
      </AccessControl>
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {keys.map(key => {
            return <Tab label={key} />;
          })}
        </Tabs>
      </AppBar>
      <div className={classes.container}>
        {isLoading ? (
          <div style={{ textAlign: 'center', padding: 25 }}>
            <CircularProgress color="secondary" size={50} />
          </div>
        ) : (
          <div id="hot-app">
            <HotTable
              ref={tableRef}
              data={data}
              settings={settings}
              height="800"
              stretchH="all"
              afterRemoveRow={handleModifyData}
              afterCreateRow={handleModifyData}
              afterSetDataAtCell={handleModifyData}
            />
          </div>
        )}

        <Snackbar
          open={message.length > 0}
          autoHideDuration={5000}
          onClose={() => {
            setMessage('');
          }}
          message={<span id="message-id">{message}</span>}
          action={
            <>
              <Button
                key="undo"
                color="secondary"
                size="small"
                onClick={() => {
                  setMessage('');
                }}
              >
                OK
              </Button>
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  setMessage('');
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          }
        />
      </div>
    </Paper>
  );
};

export default PJMEvents;
