import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import { exportCSVFile } from '../../../utility/General';
//import Plot from 'react-plotly.js';
import Settings from '@material-ui/icons/Settings';
import { useTheme } from '@material-ui/styles';
import ComparisonSettings from './ComparisonSettings';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
import { Layout, PlotData } from 'plotly.js';
import { ScenarioResults } from '../../../types';
import { assertIsDefined } from '../../../utils/assertions';

const Plot = createPlotlyComponent(Plotly);

const costKeys = ['Energy Cost OffPeak', 'Energy Cost Peak', 'Energy Cost MidPeak'];
const usageKeys = ['Energy Use OffPeak', 'Energy Use Peak', 'Energy Use MidPeak'];

// const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

interface ScenarioEnergyChargeComparisonProps {
  scenarioData: ScenarioResults;
  statusQuoData: ScenarioResults;
  discountRate: number;
  scenarioName: string;
  statusQuoName: string;
}

const ScenarioEnergyChargeComparison: React.FC<ScenarioEnergyChargeComparisonProps> = props => {
  const { scenarioData, statusQuoData, discountRate, scenarioName, statusQuoName } = props;

  const [data, setData] = useState<Partial<PlotData>[]>([]);
  const [open, setOpen] = useState(false);
  const [keys, setKeys] = useState([...costKeys]);
  const [showUsage, setShowUsage] = useState(false);

  const [labels, setLabels] = useState({
    scenarioLabel: scenarioName,
    statusQuoLabel: statusQuoName
  });

  const theme = useTheme();

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLabels({ ...labels, [prop]: event.target.value });
  };

  const buildLayout = (): Partial<Layout> => {
    const layout: Partial<Layout> = {
      legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
      height: 280,
      margin: {
        t: 20,
        l: 60,
        r: 20,
        b: 40
      },
      xaxis: {
        // tickangle: -45
      },
      barmode: 'group'
    };

    return layout;
  };

  const buildData = (scenarioData: ScenarioResults, statusQuoData: ScenarioResults, keys: (string | number)[]) => {
    if (!scenarioData || Object.keys(scenarioData).length === 0 || keys.length === 0) {
      return;
    }
    assertIsDefined(statusQuoData);
    if (!scenarioData[keys[0]] || !statusQuoData[keys[0]]) {
      keys = ['Energy Cost Total'];
    }
    const dataParts: { month: any; statusQuo: number; scenario: number }[] = [];
    let proposalMonths = statusQuoData['Months'];
    proposalMonths.forEach((month: string, index: string | number) => {
      // let monthIndex = +month.split('-')[1] - 1;
      let dataItem = {
        month: month,
        statusQuo: 0,
        scenario: 0
      };
      keys.forEach((key: string | number) => {
        if (statusQuoData[key] && scenarioData[key]) {
          let energyCostAdjustment = (statusQuoData[key][index] - scenarioData[key][index]) * (1 - discountRate);
          dataItem['statusQuo'] += statusQuoData[key][index];
          dataItem['scenario'] += scenarioData[key][index] + energyCostAdjustment;
        }
      });
      dataParts.push(dataItem);
    });
    let updatedData: Partial<PlotData>[] = [];
    updatedData.push({
      type: 'bar',
      name: labels.statusQuoLabel,
      x: dataParts.map(x => moment(x.month).format('MM/YY')),
      y: dataParts.map(x => x.statusQuo),
      marker: {
        color: 'rgb(204,204,204)'
      }
    });
    updatedData.push({
      type: 'bar',
      name: labels.scenarioLabel,
      x: dataParts.map(x => moment(x.month).format('MM/YY')),
      y: dataParts.map(x => x.scenario),
      marker: {
        color: (theme as any).palette.green
      }
    });
    setData(updatedData);
  };

  // build data
  useEffect(() => {
    buildData(scenarioData, statusQuoData, keys);
    // eslint-disable-next-line
  }, [labels.scenarioLabel, labels.statusQuoLabel, discountRate, scenarioData, statusQuoData]);

  const toggleValues = () => {
    if (showUsage) {
      buildData(props.scenarioData, props.statusQuoData, [...costKeys]);
      setShowUsage(false);
      setKeys([...costKeys]);
    } else {
      buildData(props.scenarioData, props.statusQuoData, [...usageKeys]);
      setShowUsage(true);
      setKeys([...usageKeys]);
    }
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedKey = event.target.name;
    let updatedKeys = [...keys];
    let keyIndex = keys.indexOf(updatedKey);
    if (keyIndex === -1) {
      updatedKeys.push(updatedKey);
    } else {
      if (updatedKeys.length > 1) {
        updatedKeys.splice(keyIndex, 1);
      }
    }
    buildData(props.scenarioData, props.statusQuoData, updatedKeys);
    setKeys(updatedKeys);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    const headers = ['Datetime', 'SQ Energy Cost', 'Scenario Energy Cost'];
    let downloadData: any = [];
    assertIsDefined(data[0].x);
    data[0].x.forEach((date: any, index: string | number) => {
      const row = [date];
      data.forEach(item => {
        assertIsDefined(item?.y);
        row.push(item.y[index]);
      });
      downloadData.push(row);
    });

    exportCSVFile(headers, downloadData, 'Energy Charge Comparison');
  };

  return (
    <Paper>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          {labels.statusQuoLabel} vs {labels.scenarioLabel} Energy Charge
        </Typography>
        <IconButton aria-label="Chart Settings" onClick={handleOpen}>
          <Settings />
        </IconButton>
        <IconButton aria-label="Download" onClick={handleDownload}>
          <InsertDriveFile />
        </IconButton>
      </Toolbar>
      <div style={{ height: 300 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={data}
          layout={buildLayout()}
          config={{ displayModeBar: false, responsive: true }}
        />
      </div>
      <ComparisonSettings
        isDemand={false}
        open={open}
        keys={keys}
        showUsage={showUsage}
        labels={labels}
        handleChange={handleChange}
        handleClose={handleClose}
        toggleValues={toggleValues}
        handleCheck={handleCheck}
      />
    </Paper>
  );
};

export default ScenarioEnergyChargeComparison;
