import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
const PlotAverage = createPlotlyComponent(Plotly);
const Plot = createPlotlyComponent(Plotly);

const styles = {
  block: {
    marginTop: 8,
    marginBottom: 8
  }
};

const PDRBidByHour = props => {
  const { monthlyOperationsData } = props;
  const [months, setMonths] = useState([]);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState();

  const handleChange = event => {
    setSelected(event.target.value);
  };

  const transformDataAverage = data => {
    let updatedData = [];
    updatedData.push({
      type: 'bar',
      name: 'PDR Bids',
      x: data.map(x => x.hour),
      y: data.map(x => x.average),
      marker: {
        color: '#555'
      }
    });
    return updatedData;
  };

  const transformDataActivity = data => {
    let updatedData = [];
    updatedData.push({
      type: 'bar',
      name: 'PDR Bids',
      x: data.map(x => x.hour),
      y: data.map(x => x.total_hours),
      marker: {
        color: '#555'
      }
    });
    return updatedData;
  };

  useEffect(() => {
    if (monthlyOperationsData && Object.keys(monthlyOperationsData).length > 0) {
      const months = Object.keys(monthlyOperationsData).map(dateKey => {
        return { title: monthlyOperationsData[dateKey].month, key: dateKey };
      });
      setMonths(months);
      setSelected(months[0].key);
    }
  }, [monthlyOperationsData]);

  const buildLayout = () => {
    const layout = {
      legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
      height: 280,
      margin: {
        t: 20,
        l: 80,
        r: 20,
        b: 40
      },
      xaxis: {
        // tickangle: -45
      },
      barmode: 'group'
    };

    return layout;
  };

  return (
    <Paper>
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
            <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
              PDR Average Bid Size by Hour
            </Typography>
            <FormControl>
              <Select
                value={selected}
                onChange={handleChange}
                inputProps={{
                  name: 'selected'
                }}
              >
                {months.map(month => {
                  return (
                    <MenuItem key={month.key} value={month.key}>
                      {month.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Toolbar>
          {selected && Object.keys(monthlyOperationsData).length > 0 && monthlyOperationsData[selected]['pdr'] && (
            <div style={{ height: 300 }}>
              <PlotAverage
                style={{ width: '100%' }}
                useResizeHandler={true}
                data={transformDataAverage(monthlyOperationsData[selected]['pdr'])}
                layout={buildLayout()}
                config={{ displayModeBar: false, responsive: true }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
            <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
              PDR Participation by Hour
            </Typography>
            <FormControl>
              <Select
                value={selected}
                onChange={handleChange}
                inputProps={{
                  name: 'selected'
                }}
              >
                {months.map(month => {
                  return (
                    <MenuItem key={month.key} value={month.key}>
                      {month.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Toolbar>
          {selected && Object.keys(monthlyOperationsData).length > 0 && monthlyOperationsData[selected]['pdr'] && (
            <div style={{ height: 300 }}>
              <Plot
                style={{ width: '100%' }}
                useResizeHandler={true}
                data={transformDataActivity(monthlyOperationsData[selected]['pdr'])}
                layout={buildLayout()}
                config={{ displayModeBar: false, responsive: true }}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

PDRBidByHour.propTypes = {
  monthlyOperationsData: PropTypes.object.isRequired
};

export default withStyles(styles)(PDRBidByHour);
