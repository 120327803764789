import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Assessment from '@material-ui/icons/Assessment';
import CircularProgress from '@material-ui/core/CircularProgress';

import { createLoadingSelector } from '../../selectors';
import { getRecentProposals } from '../../actions/proposals';
import { getFavorites } from '../../actions/favorites';
import { StoreState } from '../../reducers/index.js';

const useStyles = makeStyles({
  root: {
    width: '420px',
    display: 'flex',
    marginBottom: '100px'
  },
  closeGutter: {
    '-webkit-box-align': 'center',
    'align-items': 'center',
    'box-sizing': 'border-box',
    color: 'rgb(66, 82, 110)',
    display: 'flex',
    'flex-shrink': '0',
    'flex-direction': 'column',
    height: '100vh',
    'padding-bottom': '16px',
    'padding-top': '24px',
    width: '64px'
  },
  content: {
    marginTop: 24,
    flex: '1 1 0%',
    overflow: 'auto'
  },
  listHead: {
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#6f6f6f',
    fontWeight: 500
  },
  listContainer: {
    margin: '10px 0'
  },
  list: {
    '& li': {
      padding: '2px 0',
      fontSize: '13px'
    }
  },
  navLink: {
    color: '#444444',
    textDecoration: 'none',
    transition: '0.2s',
    width: ' 90%',
    padding: '4px 0 4px 5px',
    borderRadius: '3px',
    '& span': {
      marginLeft: '30px'
    },
    '& svg': {
      top: '4px',
      position: 'absolute'
    },
    '&:hover': {
      backgroundColor: '#efefef',
      color: '#333333'
    }
  },
  noFavorites: {
    fontStyle: 'italic',
    padding: '8px',
    color: '#3c3c3c'
  }
});

interface FavoritesProps {
  toggleFavoritesDrawer(): any;
}

const UserFavorites: React.FC<FavoritesProps> = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const recentProposals = useSelector((state: StoreState) => state.proposalsRecent);
  const favorites = useSelector((state: StoreState) => state.favorites);

  const loadingSelector = createLoadingSelector(['GET_FAVORITES', 'GET_RECENT_PROPOSALS']);
  const loading = useSelector((state: StoreState) => loadingSelector(state));

  useEffect(() => {
    dispatch(getRecentProposals());
    dispatch(getFavorites());
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.closeGutter}>
        <IconButton onClick={props.toggleFavoritesDrawer} style={{ color: '#333' }} data-testid="favorites-close">
          <Close />
        </IconButton>
      </Box>

      <Box className={classes.content}>
        <Typography variant="h6" style={{ padding: '7px 0 15px 0' }}>
          Favorites and Recent
          {loading && (
            <span style={{ paddingLeft: 16 }}>
              <CircularProgress color="secondary" size={16} />
            </span>
          )}
        </Typography>

        {!loading && (
          <>
            <Box className={classes.listContainer}>
              <div className={classes.listHead}>Favorite Proposals</div>
              {favorites?.proposals?.length === 0 && (
                <div className={classes.noFavorites}>
                  <em></em>No Favorites
                </div>
              )}
              <List className={classes.list} data-testid="favorites-list">
                {favorites.proposals?.map((proposal, key) => {
                  return (
                    <ListItem onClick={props.toggleFavoritesDrawer}>
                      <NavLink
                        to={'/proposals/' + proposal.public_id}
                        activeClassName="active"
                        key={key}
                        className={classes.navLink}
                      >
                        <Assessment />
                        <span>{proposal?.name?.length > 40 ? `${proposal.name.slice(0, 40)}...` : proposal.name}</span>
                      </NavLink>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
            <Box className={classes.listContainer}>
              <div className={classes.listHead}>Recent Proposals</div>
              <List className={classes.list} data-testid="recents-list">
                {recentProposals?.map((proposal, key) => {
                  if (key > 4) return null;
                  return (
                    <ListItem onClick={props.toggleFavoritesDrawer}>
                      <NavLink
                        to={'/proposals/' + proposal.public_id}
                        activeClassName="active"
                        key={key}
                        className={classes.navLink}
                      >
                        <Assessment />
                        <span>{proposal?.name?.length > 40 ? `${proposal.name.slice(0, 40)}...` : proposal.name}</span>
                      </NavLink>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default UserFavorites;
