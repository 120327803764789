import axios from 'axios';
import { Dispatch } from 'redux';
import { ESAPTariff } from '../types';

export const GET_ALL_MANUALTARIFFS_REQUEST = 'GET_ALL_MANUALTARIFFS_REQUEST';
export const GET_ALL_MANUALTARIFFS_SUCCESS = 'GET_ALL_MANUALTARIFFS_SUCCESS';
export const GET_ALL_MANUALTARIFFS_ERROR = 'GET_ALL_MANUALTARIFFS_ERROR';

export const GET_MANUALTARIFF_REQUEST = 'GET_MANUALTARIFF_REQUEST';
export const GET_MANUALTARIFF_SUCCESS = 'GET_MANUALTARIFF_SUCCESS';
export const GET_MANUALTARIFF_ERROR = 'GET_MANUALTARIFF_ERROR';

export const CREATE_MANUALTARIFF_REQUEST = 'CREATE_MANUALTARIFF_REQUEST';
export const CREATE_MANUALTARIFF_SUCCESS = 'CREATE_MANUALTARIFF_SUCCESS';
export const CREATE_MANUALTARIFF_ERROR = 'CREATE_MANUALTARIFF_ERROR';

export const UPDATE_MANUALTARIFF_REQUEST = 'UPDATE_MANUALTARIFF_REQUEST';
export const UPDATE_MANUALTARIFF_SUCCESS = 'UPDATE_MANUALTARIFF_SUCCESS';
export const UPDATE_MANUALTARIFF_ERROR = 'UPDATE_MANUALTARIFF_ERROR';

export const DELETE_MANUALTARIFF_REQUEST = 'DELETE_MANUALTARIFF_REQUEST';
export const DELETE_MANUALTARIFF_SUCCESS = 'DELETE_MANUALTARIFF_SUCCESS';
export const DELETE_MANUALTARIFF_ERROR = 'DELETE_MANUALTARIFF_ERROR';

export const RESET_MANUALTARIFF = 'RESET_MANUALTARIFF';
export const RESET_MANUALTARIFF_LIST = 'RESET_MANUALTARIFF_LIST';
export const SELECT_MANUALTARIFFS = 'SELECT_MANUALTARIFF';

export const MANUALTARIFF_INPUT_FORM_CHANGE = 'MANUALTARIFF_INPUT_FORM_CHANGE';

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export function resetManualTariff() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_MANUALTARIFF });
  };
}

export function resetManualTariffList() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_MANUALTARIFF_LIST });
  };
}

// TODO: Update this address
export function getAllManualTariffs() {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_ALL_MANUALTARIFFS_REQUEST });
    axios
      .get(`${ROOT_URL}/proposal/globalTariff`)
      .then(res => {
        const tariffs = res.data;
        dispatch({ type: GET_ALL_MANUALTARIFFS_SUCCESS, payload: tariffs });
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALL_MANUALTARIFFS_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function getManualTariff(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_MANUALTARIFF_REQUEST });
    axios
      .get(`${ROOT_URL}/proposal/globalTariff/${id}`)
      .then(res => {
        const tariff = res.data;
        dispatch({ type: GET_MANUALTARIFF_SUCCESS, payload: tariff });
      })
      .catch(function (error) {
        dispatch({ type: GET_MANUALTARIFF_ERROR, payload: error, error: true });
        dispatch({ type: RESET_MANUALTARIFF });
      });
  };
}

export function createManualTariff(tariff: Omit<ESAPTariff, 'public_id'>) {
  return (dispatch: Dispatch) => {
    dispatch({ type: CREATE_MANUALTARIFF_REQUEST });
    axios
      .post(`${ROOT_URL}/proposal/globalTariff`, tariff)
      .then(res => {
        const manualTariff = res.data;
        dispatch({ type: CREATE_MANUALTARIFF_SUCCESS, payload: manualTariff });
      })
      .catch(function (error) {
        dispatch({
          type: CREATE_MANUALTARIFF_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function updateManualTariff(tariff: ESAPTariff) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_MANUALTARIFF_REQUEST });
    axios
      .put(`${ROOT_URL}/proposal/globalTariff/${tariff.public_id}`, tariff)
      .then(res => {
        const manualTariff = res.data;
        dispatch({ type: UPDATE_MANUALTARIFF_SUCCESS, payload: manualTariff });
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_MANUALTARIFF_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function deleteManualTariff(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: DELETE_MANUALTARIFF_REQUEST });
    axios
      .delete(`${ROOT_URL}/proposal/globalTariff/${id}`)
      .then(res => {
        dispatch({ type: DELETE_MANUALTARIFF_SUCCESS, payload: res.data });
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_MANUALTARIFF_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function selectManualTariffs(selectedManualTariffs: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SELECT_MANUALTARIFFS,
      payload: selectedManualTariffs
    });
  };
}

export function handleInputFormChangeManualTariff(updateData: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MANUALTARIFF_INPUT_FORM_CHANGE,
      payload: updateData
    });
  };
}
