import React, { useState, useEffect } from 'react';
import moment from 'moment';

// MATERIAL UI IMPORTS
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { TariffFilters } from '../../../types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    container: {
      padding: 20
    },
    menu: {
      minWidth: 200
    },
    item: {
      padding: 20
    }
  })
);

interface TariffFiltersProps {
  toggleFilterDrawer: () => void;
  handleFilterUpdate: (f: TariffFilters) => void;
  filters: TariffFilters;
}

const INITIAL_STATE: TariffFilters = {
  zipCode: '',
  country: 'US',
  lseId: '',
  effectiveOn: null,
  search: '',
  monthlyConsumptionAverage: '',
  annualMaxDemand: '',
  timeOfUse: false,
  tiered: false,
  contracted: false,
  netMetering: false,
  residential: false,
  general: true,
  specialUse: false,
  tariffTypeTariff: true,
  tariffTypeRider: false,
  tariffTypeProposed: false,
  pageStart: 0,
  pageCount: 25,
  count: 0
};

const TariffFiltersPage: React.FC<TariffFiltersProps> = ({ toggleFilterDrawer, handleFilterUpdate, filters }) => {
  const classes = useStyles();

  // only update the reducer filters when confirm filter change
  const [state, setState] = useState<TariffFilters>(INITIAL_STATE);

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      setState(filters);
    }
  }, [filters]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    setState({ ...state, effectiveOn: moment(date).format('YYYY-MM-DD') });
  };

  const handleCheckChange = (name: keyof TariffFilters) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setState({ ...state, [name]: checked });
  };

  const handleUpdate = () => {
    handleFilterUpdate(state);
    toggleFilterDrawer();
  };

  return (
    <Paper className={classes.root}>
      <section className={classes.container}>
        <Typography variant="h6" gutterBottom>
          Tariff Filters
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} className={classes.item}>
            <TextField
              id="zipCode"
              name="zipCode"
              label="ZIP Code"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={state.zipCode}
              onChange={handleChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.item}>
            <TextField
              id="country"
              name="country"
              value={state.country}
              onChange={handleChange}
              select
              label="Country"
              InputLabelProps={{ shrink: true }}
              fullWidth
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
            >
              <MenuItem value="US">USA</MenuItem>
              <MenuItem value="CA">Canada</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.item}>
            <TextField
              id="lseId"
              name="lseId"
              value={state.lseId}
              onChange={handleChange}
              select
              label="Energy Provider"
              InputLabelProps={{ shrink: true }}
              fullWidth
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
            >
              <MenuItem value="1071">SDGE</MenuItem>
              <MenuItem value="734">PGE</MenuItem>
              <MenuItem value="1228">SCE</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.item}>
            <KeyboardDatePicker
              autoOk
              id="effectiveOn"
              name="effectiveOn"
              label="Effective On"
              value={state.effectiveOn}
              format="YYYY-MM-DD"
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.item}>
            <TextField
              id="monthlyConsumptionAverage"
              name="monthlyConsumptionAverage"
              value={state.monthlyConsumptionAverage}
              onChange={handleChange}
              label="Monthly Consumption Average (kWh)"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.item}>
            <TextField
              id="annualMaxDemand"
              name="annualMaxDemand"
              value={state.annualMaxDemand}
              onChange={handleChange}
              label="Annual Max Demand (kW)"
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Rate Criteria</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox value="timeOfUse" checked={state.timeOfUse} onChange={handleCheckChange('timeOfUse')} />
                }
                label="Time of Use"
              />
              <FormControlLabel
                control={<Checkbox value="tiered" checked={state.tiered} onChange={handleCheckChange('tiered')} />}
                label="Tiered"
              />
              <FormControlLabel
                control={
                  <Checkbox value="contracted" checked={state.contracted} onChange={handleCheckChange('contracted')} />
                }
                label="Contracted"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="netMetering"
                    checked={state.netMetering}
                    onChange={handleCheckChange('netMetering')}
                  />
                }
                label="Net Metering"
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Customer Class</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="residential"
                    checked={state.residential}
                    onChange={handleCheckChange('residential')}
                  />
                }
                label="Residential"
              />
              <FormControlLabel
                control={<Checkbox value="general" checked={state.general} onChange={handleCheckChange('general')} />}
                label="General"
              />
              <FormControlLabel
                control={
                  <Checkbox value="specialUse" checked={state.specialUse} onChange={handleCheckChange('specialUse')} />
                }
                label="Special use"
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tariff Type</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="tariffTypeTariff"
                    checked={state.tariffTypeTariff}
                    onChange={handleCheckChange('tariffTypeTariff')}
                  />
                }
                label="Tariff"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="tariffTypeProposed"
                    checked={state.tariffTypeProposed}
                    onChange={handleCheckChange('tariffTypeProposed')}
                  />
                }
                label="Proposed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="tariffTypeRider"
                    checked={state.tariffTypeRider}
                    onChange={handleCheckChange('tariffTypeRider')}
                  />
                }
                label="Rider"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </section>
      <section style={{ padding: 20, textAlign: 'right' }}>
        <Button onClick={handleUpdate} variant="contained" color="primary" style={{ marginRight: 10 }}>
          Update
        </Button>
        <Button variant="contained" color="secondary" onClick={toggleFilterDrawer} style={{ color: '#fff' }}>
          Cancel
        </Button>
      </section>
    </Paper>
  );
};

export default TariffFiltersPage;
