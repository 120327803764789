import React from 'react';
import moment from 'moment';

//MaterialUI
import { makeStyles, Theme } from '@material-ui/core/styles';

import ScenarioTechBadges from './ScenarioTechBadges';
import { ScenarioInternal } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  scenarioInfoStrip: {
    color: '#9e9e9e',
    fontSize: '10px',
    '& span:not(.techBadges)': {
      paddingLeft: 10
    }
  },
  bullet: {
    color: 'rgb(219, 219, 222)'
  }
}));

interface Props {
  scenario: ScenarioInternal;
}

const ScenarioInfoBlock: React.FC<Props> = props => {
  const classes = useStyles();
  const { scenario } = props;

  return (
    <span className={classes.scenarioInfoStrip}>
      <ScenarioTechBadges scenario={scenario} />

      <span className={classes.bullet}>•</span>
      <span>Created {moment.utc(scenario.created_on).local().fromNow()}</span>
      <span className={classes.bullet}>•</span>
      <span>{scenario.created_user}</span>
    </span>
  );
};

export default ScenarioInfoBlock;
