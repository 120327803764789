import React, { useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

interface IStyleProps {
  backgroundColor: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ChipRoot: {
      backgroundColor: (props: IStyleProps) => props.backgroundColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: '10px',
      padding: '5px 7px',
      borderRadius: '5px',
      boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      minWidth: 'max-content',
      cursor: 'pointer',

    },
    label: {
      color: '#272727',
      textTransform: 'uppercase',
      fontSize: '14px',
      marginRight: '5px',
    },
    icon: {
      fontSize: '15px',
      color: '#272727',
      cursor: 'pointer',
    },
  })
);

interface ChipProps<T> {
  label: string;
  color?: string;
  isFavorite?: boolean;
  value?: T;
  onFavoriteClick?: (value?: T) => void;
  onClick: (value?: T) => void;
}

const Chip = <T extends unknown>(props: ChipProps<T>) => {
  const {
    label,
    isFavorite: isFavoriteProp = false,
    color = '#FED5A1',
    onClick = () => {},
    onFavoriteClick,
    value,
  } = props;
  const styles = useStyles({ backgroundColor: color });
  const FavoriteIcon = useMemo(() => (isFavoriteProp ? Favorite : FavoriteBorder), [isFavoriteProp])

  const handleFavoriteClick = (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation();
    typeof onFavoriteClick === 'function' && onFavoriteClick(value);
  }

  const handleChipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick(value);
  }

  return (
    <div className={styles.ChipRoot} data-test={`chip|container|${label}`} onClick={handleChipClick}>
      <span className={styles.label} data-test="chip|label">{label}</span>
      <FavoriteIcon data-test="chip|icon" onClick={handleFavoriteClick} className={styles.icon}/>
    </div>
  );
}

Chip.defaultProps = {
  color: '#FED5A1',
  isFavorite: false,
};

export default Chip;
