import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { TariffFactoryContext, Period } from './TariffFactoryContext';
import { ChargesType } from './RatePeriodsEditor';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(100),
    overflowX: 'auto'
  },
  centerItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  table: {
    width: theme.spacing(100)
  },
  inputField: {
    width: theme.spacing(25)
  },
  selectInputField: {
    width: theme.spacing(20)
  }
}));

const DemansSubscriptionsCreate: React.FC = () => {
  const classes = useStyles();
  const type = 'subscriptions';
  const { handleAddPeriodRate, handleDeletePeriod, handleChangeInput, periods } = useContext(TariffFactoryContext);
  const { subscriptions } = periods;

  const handleChange = (key: ChargesType, name: keyof Period, index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    handleChangeInput(key, name, index, value);
  };
  return (
    <div className={classes.centerItem}>
      <Table className={classes.table} aria-label="subscriptions table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Reservation Tranche Size (kW)</TableCell>
            <TableCell align="right">Charges ($/kW)</TableCell>
            <TableCell align="right"> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions &&
            subscriptions.length > 0 &&
            subscriptions.map((subscription, i) => (
              <TableRow key={subscription.index}>
                <TableCell align="right">
                  <TextField
                    className={classes.inputField}
                    value={subscription.name}
                    onChange={handleChange(type, 'name', subscription.index)}
                    margin="normal"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    className={classes.inputField}
                    value={subscription.reservationSize}
                    onChange={handleChange(type, 'reservationSize', subscription.index)}
                    margin="normal"
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    className={classes.inputField}
                    value={subscription.charges}
                    onChange={handleChange(type, 'charges', subscription.index)}
                    margin="normal"
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={handleDeletePeriod(type, i)} aria-label="Delete Subscription">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button style={{ marginTop: 24 }} variant="contained" onClick={() => handleAddPeriodRate(type)}>
          Add Tranche
        </Button>
      </div>
    </div>
  );
};

export default React.memo(DemansSubscriptionsCreate);
