import React, { useEffect } from 'react';
import moment from 'moment';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
// import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import SlowMotionVideo from '@material-ui/icons/SlowMotionVideo';

import { ScenarioInternal } from '../../types';
import ScenarioInfoBlock from './ScenarioInfoBlock';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 125
  },
  badge: {
    height: 20,
    border: 'none',
    marginRight: '3px',
    fontSize: 10,
    '& svg': {
      width: '13px !important'
    },
    '& svg, & span': {
      color: 'white !important'
    }
  },
  complete: {
    backgroundColor: (theme.palette as any).esap.greenDark
  },
  error: {
    background: (theme.palette as any).esap.red
  },
  inprogress: {
    background: (theme.palette as any).esap.yellowOrange
  },
  completeDate: {
    height: 15,
    color: (theme.palette as any).esap.greenDark,
    fontSize: 10
  },
  errorText: {
    color: (theme.palette as any).esap.red,
    fontSize: 10
  },
  newText: {
    color: (theme.palette as any).esap.blueBright,
    fontSize: 10
  },
  inprogressText: {
    color: (theme.palette as any).esap.yellowOrange,
    fontSize: 10
  },
  paper: {
    padding: theme.spacing(1)
  },
  popover: {
    pointerEvents: 'none'
  },
  table: {
    maxWidth: 500,
    minWidth: 400,
    '& td': {
      verticalAlign: 'top',
      padding: '2px 5px',
      fontSize: 11
    },
    '& td:first-child': {
      fontWeight: '500'
    },
    '& td:last-child': {}
  },
  popoverTable: {
    fontSize: 11,
    padding: '5px'
  }
}));

interface Props {
  scenarios: ScenarioInternal[];
}

const ScenarioBadges: React.FC<Props> = props => {
  const classes = useStyles();
  const { scenarios } = props;

  const [popoverScenarios, setPopoverScenarios] = React.useState<ScenarioInternal[]>();
  const [popoverScenariosStatus, setPopoverScenariosStatus] = React.useState('');
  const [scenariosThatAreComplete, setScenariosThatAreComplete] = React.useState<ScenarioInternal[]>([]);
  const [scenariosWithError, setScenariosWithError] = React.useState<ScenarioInternal[]>([]);
  const [scenariosNewAndInProgress, setScenariosNewAndInProgress] = React.useState<ScenarioInternal[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  useEffect(() => {
    setScenariosThatAreComplete(getFilteredScenarios('Complete'));
    setScenariosWithError(getFilteredScenarios('Error'));
    setScenariosNewAndInProgress(getFilteredScenarios('NewAndInProgress'));
  }, []);

  const getFilteredScenarios = (status: string) => {
    switch (status) {
      case 'Complete':
        return scenarios?.filter(scenario => scenario.active && scenario.run_completed);
      case 'Error':
        return scenarios?.filter(scenario => scenario.active && scenario.error != '' && scenario.error !== null);
      case 'NewAndInProgress':
        return scenarios?.filter(
          scenario => scenario.active && !scenario.run_completed && !(scenario.error != '' && scenario.error !== null)
        );
      default:
        return scenarios;
    }
  };

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    scenarios: ScenarioInternal[],
    status: string
  ) => {
    if (scenarios?.length) {
      setAnchorEl(event.currentTarget);
      setPopoverScenarios(scenarios);
      setPopoverScenariosStatus(status);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {scenarios?.length && (
        <Box className={classes.root}>
          {scenariosThatAreComplete.length > 0 && (
            <Chip
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={e => handlePopoverOpen(e, scenariosThatAreComplete, 'Complete')}
              onMouseLeave={handlePopoverClose}
              variant="outlined"
              className={`${classes.complete} ${classes.badge}`}
              avatar={<CheckCircle />}
              label={scenariosThatAreComplete?.length}
              size="small"
            />
          )}
          {scenariosWithError.length > 0 && (
            <Chip
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={e => handlePopoverOpen(e, scenariosWithError, 'Error')}
              onMouseLeave={handlePopoverClose}
              variant="outlined"
              className={`${classes.error} ${classes.badge}`}
              avatar={<Error />}
              label={scenariosWithError?.length}
              size="small"
            />
          )}
          {scenariosNewAndInProgress.length > 0 && (
            <Chip
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={e => handlePopoverOpen(e, scenariosNewAndInProgress, 'NewAndInProgress')}
              onMouseLeave={handlePopoverClose}
              variant="outlined"
              className={`${classes.inprogress} ${classes.badge}`}
              avatar={<SlowMotionVideo />}
              label={scenariosNewAndInProgress?.length}
              size="small"
            />
          )}
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Table className={classes.table}>
              <TableBody>
                {/* <TableHead>
              <TableRow>
                <TableCell>Scenario Name</TableCell>
                {scenario.status === 'Complete' && <TableCell>{moment(scenario.run_completed).format('MM/DD/YYYY MM:SS')}</TableCell>}
              </TableRow>
            </TableHead> */}
                {popoverScenarios?.map((scenario, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell style={{ position: 'relative', height: 30 }}>
                        {scenario.name || '<no name>'}
                        <div>
                          {popoverScenariosStatus === 'Complete' && (
                            <span className={classes.completeDate}>
                              Completed: {moment(scenario.run_completed).format('MM/DD/YYYY MM:SS')}
                            </span>
                          )}
                          {popoverScenariosStatus === 'Error' && (
                            <span className={classes.errorText}>{scenario.error}</span>
                          )}
                          {popoverScenariosStatus === 'NewAndInProgress' && (
                            <>
                              {!scenario.status && <span className={classes.newText}>New</span>}
                              {scenario.status && <span className={classes.inprogressText}>{scenario.status}</span>}
                            </>
                          )}
                        </div>
                        <div style={{ position: 'relative', top: 0, left: 0 }}>
                          <ScenarioInfoBlock scenario={scenario} />
                        </div>
                      </TableCell>

                      {/* {scenario.error && <TableCell>{scenario.error}</TableCell>} */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Popover>
        </Box>
      )}
    </>
  );
};

export default ScenarioBadges;
