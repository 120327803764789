import React, { useLayoutEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getAllProposals, deleteProposal, RESET_PROPOSALS_LIST_FILTERS } from '../../actions/proposals';
import { pushBreadcrumb, popBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import { createLoadingSelector } from '../../selectors';
import ProposalList from '../../components/Proposals/List/ProposalList';

const styles = theme => ({
  padding: {
    padding: theme.spacing(3)
  }
});

const ProposalListPage = props => {
  const { resetBreadcrumb, pushBreadcrumb, getAllProposals } = props;
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    resetBreadcrumb();
    pushBreadcrumb('Proposals', '/proposals');
    // TODO: fix the dependencies under
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetProposalsList = () => {
    dispatch({ type: RESET_PROPOSALS_LIST_FILTERS });
    getAllProposals({ includeScenarioSummary: true, page: 1, pageSize: props.proposalsMeta.pageSize });
  };

  return (
    <div className={props.classes.padding}>
      <ProposalList
        proposals={props.proposals}
        proposalsMeta={props.proposalsMeta}
        title="Proposals"
        history={props.history}
        loading={props.isLoading}
        deleteProposal={props.deleteProposal}
        resetProposalsList={resetProposalsList}
      />
    </div>
  );
};

const loadingSelector = createLoadingSelector(['GET_ALL_PROPOSALS', 'COPY_PROPOSAL']);

const mapStateToProps = state => {
  return {
    proposals: state.proposals,
    proposalsMeta: state.proposalsMeta,
    isLoading: loadingSelector(state),
    filters: state.proposalsListFilters
  };
};

export default connect(mapStateToProps, {
  getAllProposals,
  deleteProposal,
  pushBreadcrumb,
  popBreadcrumb,
  resetBreadcrumb
})(withStyles(styles)(ProposalListPage));
