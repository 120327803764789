import { SELECT_PROPOSAL_SCENARIO } from '../../actions/scenarios';

const INITIAL_STATE = {};

export default function scenarioReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SELECT_PROPOSAL_SCENARIO:
      if (!action.payload) return state;
      return action.payload;
    default:
      return state;
  }
}
