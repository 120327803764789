import React from 'react';
import PropTypes from 'prop-types';
import { HotTable } from '@handsontable/react';
import { withStyles } from '@material-ui/core';

const style = theme => ({
  root: {
    width: '100%'
  },
  container: {
    padding: 20
  },
  flex: {
    flex: 1
  },
  table: {
    minWidth: 700
  },
  item: {
    paddingRight: 15
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
});

class ProfileTable extends React.Component {
  validationErrors = {};
  generateVerticalSettings = () => {
    let maxRows = this.props.data.length;
    return {
      colHeaders: ['Date (MM/DD/YYYY)', 'Time', 'Value'],
      stretchH: 'all',
      maxRows: maxRows,
      beforeValidate: value => {
        let newValue = parseFloat(value.toString().replace(/,/g, '').trim());
        if (isNaN(newValue)) {
          return '';
        } else {
          return newValue;
        }
      },
      beforeChange: (changes, source) => {
        for (let i = 0; i < changes.length; i++) {
          const newValue = parseFloat(changes[i][3].toString().replace(/,/g, '').trim());
          if (isNaN(newValue)) {
            changes[i][3] = 0;
          } else {
            changes[i][3] = newValue;
          }
        }
        this.props.onUpdateData(changes);
      },
      className: 'htCenter htMiddle',
      rowHeights: 33,
      columns: [
        {
          type: 'text',
          dateFormat: 'MM/DD/YYYY',
          allowEmpty: false,
          readOnly: true,
          colWidths: 225
        },
        {
          type: 'text',
          allowEmpty: false,
          readOnly: true,
          colWidths: 225
        },
        {
          type: 'numeric',
          colWidths: 225,
          allowEmpty: false,
          numericFormat: {
            pattern: '0.00',
            culture: 'en-US'
          }
        }
      ],
      copyPaste: {
        rowsLimit: 40000
      }
    };
  };

  render() {
    const { classes } = this.props;
    const settings = this.generateVerticalSettings();
    return (
      <div className={classes.container}>
        <div id="hot-app">
          <HotTable ref={'table'} data={this.props.data} settings={settings} height="800" stretchH="all" />
        </div>
      </div>
    );
  }
}

ProfileTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  onUpdateData: PropTypes.func.isRequired
};

export default withStyles(style)(ProfileTable);
