import { getTimeStamps } from './General';
import moment from 'moment';
import { LoadProfile, MonthlySolarSummary } from '../types';

type Data = [string, string, number];

// TODO: write tests for this
export function rollupData(data: Data[], startHour = 0, endHour = 0) {
  // data in format: [[start, end, kw],...]
  const aggregate = {
    summary: {
      avgAnnualDemand: 0,
      peakAnnualDemand: 0,
      peakAnnualDemandDate: '',
      avgAnnualLoadFactor: 0,
      totalEnergy: 0
    }
  };
  let currentMonth = null as any;
  let processingData = data;
  if (startHour < endHour) {
    processingData = data.filter(item => {
      const hour = +item[1].split(':')[0];
      return hour >= startHour && hour <= endHour;
    });
  }
  processingData.forEach((item, index) => {
    const readingDate = new Date(item[0]);
    const month = readingDate.getMonth() + 1;
    const year = readingDate.getFullYear();
    const aggregateKey = month + '/' + year;
    const time = item[1];
    const value = +item[2];
    const weekend = readingDate.getDay() === 6 || readingDate.getDay() === 0 ? 'weekend' : 'weekday';
    let timeParts = time.split(':');
    let readingDatetime = new Date(
      readingDate.getFullYear(),
      readingDate.getMonth(),
      readingDate.getDate(),
      +timeParts[0],
      +timeParts[1]
    );
    // initialize if first occurence of the month
    if (!aggregate[aggregateKey]) {
      aggregate[aggregateKey] = {
        weekend: {},
        weekday: {},
        peak: 0,
        peakDatetime: '',
        totalEnergy: 0,
        startDataIndex: index,
        endDataIndex: index
      };
      // adjust last month's end index so I have the range of indices where that month applies
      if (currentMonth != null) {
        aggregate[currentMonth].endDataIndex = index - 1;
      }
      // update current month to capture the next month change adjustments
      currentMonth = aggregateKey;
    }
    // check monthly peak value
    if (aggregate[aggregateKey].peak < value) {
      aggregate[aggregateKey].peak = value;
      aggregate[aggregateKey].peakDatetime = readingDatetime;
    }
    // check annual peak
    if (aggregate.summary.peakAnnualDemand < value) {
      aggregate.summary.peakAnnualDemand = value;
      aggregate.summary.peakAnnualDemandDate = readingDatetime.toString();
    }
    // aggregate by given time, weekend, month
    if (!aggregate[aggregateKey][weekend][time]) {
      aggregate[aggregateKey][weekend][time] = {
        value: value,
        key: time,
        count: 1
      };
    } else {
      aggregate[aggregateKey][weekend][time].value += value;
      aggregate[aggregateKey][weekend][time].count += 1;
    }
    // for use later to calc avg demand, we need to keep it as kW for now, convert at the end
    aggregate.summary.totalEnergy += value;
    aggregate[aggregateKey].totalEnergy += value;
  });

  aggregate[currentMonth].endDataIndex = processingData.length - 1;

  for (let monthKey in aggregate) {
    if (monthKey !== 'summary') {
      for (let weekKey in aggregate[monthKey]) {
        for (let timeKey in aggregate[monthKey][weekKey]) {
          const timeElement = aggregate[monthKey][weekKey][timeKey];
          const newValue = timeElement.value / timeElement.count;
          timeElement.value = newValue;
        }
      }
      aggregate[monthKey].avgDemand =
        aggregate[monthKey].totalEnergy / (aggregate[monthKey].endDataIndex - aggregate[monthKey].startDataIndex);
      aggregate[monthKey].avgLoadFactor = aggregate[monthKey].avgDemand / aggregate[monthKey].peak;
      // convert to kwh
      aggregate[monthKey].totalEnergy = aggregate[monthKey].totalEnergy / 4;
    }
  }
  aggregate.summary.avgAnnualDemand = aggregate.summary.totalEnergy / processingData.length;
  aggregate.summary.avgAnnualLoadFactor = aggregate.summary.avgAnnualDemand / aggregate.summary.peakAnnualDemand;
  // convert to kwh
  aggregate.summary.totalEnergy = aggregate.summary.totalEnergy / 4;
  return aggregate;
}

type RollupSolarSummary = {
  summary: {
    avgAnnualProductionPerInterval: number;
    maxAnnualProductionPerInterval: number;
    totalEnergy: number;
  };
};
type RollupSolarMonthly = {
  [month: string]: MonthlySolarSummary;
};

type RollupSolar = RollupSolarSummary & RollupSolarMonthly;

export function rollupSolar(data: Data[], frequency: number = 1): RollupSolar {
  return frequency === 1 ? rollupHourlySolar(data) : rollup15minSolar(data);
}

// find the max value A[2] in an array of arrays A
function findMaxKW(data: Data[]): number {
  return data.reduce(function (acc, curr) {
    return Math.max(acc, curr[2]);
  }, 0);
}
export function rollup15minSolar(data: Data[]): RollupSolar {
  // data in format: [[start, end, kw],...]
  const aggregate: RollupSolarSummary = {
    summary: {
      totalEnergy: 0,
      avgAnnualProductionPerInterval: 0,
      maxAnnualProductionPerInterval: findMaxKW(data)
    }
  };
  let currentMonth = null as any;
  data.forEach((item, index) => {
    const readingDate = new Date(item[0]);
    const month = readingDate.getMonth() + 1;
    const year = readingDate.getFullYear();
    const aggregateKey = month + '/' + year;
    const time = item[1];
    const value = +item[2];
    // initialize if first occurence of the month
    if (!aggregate[aggregateKey]) {
      aggregate[aggregateKey] = {
        totalEnergy: 0,
        avgProductionPerInterval: 0,
        maxProductionPerInterval: 0,
        startDataIndex: index,
        endDataIndex: index
      };
      // adjust last month's end index so I have the range of indices where that month applies
      if (currentMonth != null) {
        aggregate[currentMonth].endDataIndex = index - 1;
      }
      // update current month to capture the next month change adjustments
      currentMonth = aggregateKey;
    }
    // aggregate by given time, weekend, month
    if (!aggregate[aggregateKey][time]) {
      aggregate[aggregateKey][time] = {
        value: value,
        key: time,
        count: 1
      };
    } else {
      aggregate[aggregateKey][time].value += value;
      aggregate[aggregateKey][time].count += 1;
    }
    // for use later to calc avg demand, we keep it in kw so far and then will convert to kwh at the end
    aggregate.summary.totalEnergy += value;
    // monthly max 15min  production
    aggregate[aggregateKey].maxProductionPerInterval = Math.max(
      value,
      aggregate[aggregateKey].maxProductionPerInterval
    );
    // for use later to calc avg demand, we keep it in kw so far and then will convert to kwh at the end
    aggregate[aggregateKey].totalEnergy += value;
  });

  // end loop on data

  aggregate[currentMonth].endDataIndex = data.length - 1;

  for (let monthKey in aggregate) {
    if (monthKey !== 'summary') {
      aggregate[monthKey].avgProductionPerInterval =
        aggregate[monthKey].totalEnergy / (aggregate[monthKey].endDataIndex - aggregate[monthKey].startDataIndex);
      aggregate.summary.avgAnnualProductionPerInterval += aggregate[monthKey].avgProductionPerInterval;
      aggregate[monthKey].totalEnergy = aggregate[monthKey].totalEnergy / 4;
    }
  }
  aggregate.summary.avgAnnualProductionPerInterval = aggregate.summary.totalEnergy / data.length;
  aggregate.summary.totalEnergy = aggregate.summary.totalEnergy / 4;
  // hopefully we can avoid having to type cast here at some point, couldnt figure out how to type
  // such a dynamic object
  return aggregate as RollupSolar;
}

export function rollupHourlySolar(data: Data[]): RollupSolar {
  // data in format: [[start, end, kw],...]
  const aggregate: RollupSolarSummary = {
    summary: {
      totalEnergy: 0,
      avgAnnualProductionPerInterval: 0,
      maxAnnualProductionPerInterval: findMaxKW(data)
    }
  };
  let currentMonth = null as any;
  data.forEach((item, index) => {
    const readingDate = new Date(item[0]);
    const month = readingDate.getMonth() + 1;
    const year = readingDate.getFullYear();
    const aggregateKey = month + '/' + year;
    const time = item[1];
    const value = +item[2];
    // initialize if first occurence of the month
    if (!aggregate[aggregateKey]) {
      aggregate[aggregateKey] = {
        totalEnergy: 0,
        avgProductionPerInterval: 0,
        maxProductionPerInterval: 0,
        startDataIndex: index,
        endDataIndex: index
      };
      // adjust last month's end index so I have the range of indices where that month applies
      if (currentMonth != null) {
        aggregate[currentMonth].endDataIndex = index - 1;
      }
      // update current month to capture the next month change adjustments
      currentMonth = aggregateKey;
    }

    // aggregate by given time, weekend, month
    if (!aggregate[aggregateKey][time]) {
      aggregate[aggregateKey][time] = {
        value: value,
        key: time,
        count: 1
      };
    } else {
      aggregate[aggregateKey][time].value += value;
      aggregate[aggregateKey][time].count += 1;
    }
    // for use later to calc avg demand
    aggregate.summary.totalEnergy += value;
    // monthly max hourly production
    aggregate[aggregateKey].maxProductionPerInterval = Math.max(
      value,
      aggregate[aggregateKey].maxProductionPerInterval
    );
    aggregate[aggregateKey].totalEnergy += value;
  });

  // end loop on data

  aggregate[currentMonth].endDataIndex = data.length - 1;

  for (let monthKey in aggregate) {
    if (monthKey !== 'summary') {
      aggregate[monthKey].avgProductionPerInterval =
        aggregate[monthKey].totalEnergy / (aggregate[monthKey].endDataIndex - aggregate[monthKey].startDataIndex);
      aggregate.summary.avgAnnualProductionPerInterval += aggregate[monthKey].avgProductionPerInterval;
    }
  }
  aggregate.summary.avgAnnualProductionPerInterval = aggregate.summary.totalEnergy / data.length;
  // hopefully we can avoid having to type cast here at some point, couldnt figure out how to type
  // such a dynamic object
  return aggregate as RollupSolar;
}

// TODO
export const getTopNPeaks = (data: Data[], numberOfPeaks: number, startHour = 0, endHour = 0) => {
  let processingData = data;
  if (startHour < endHour) {
    processingData = data.filter(item => {
      const hour = +item[1].split(':')[0];
      return hour >= startHour && hour <= endHour;
    });
  }
  return processingData
    .slice(0)
    .sort(function (a, b) {
      return b[2] - a[2];
    })
    .slice(0, numberOfPeaks);
};

export let getTopDayPeaks = (data: Data[], numberOfDayPeaks: number, startHour = 0, endHour = 0) => {
  let topDayPeaks: [string, string, number][] = [];
  let processingData = data;
  if (startHour < endHour) {
    processingData = data.filter(item => {
      const hour = +item[1].split(':')[0];
      return hour >= startHour && hour <= endHour;
    });
  }
  let sorted = processingData.slice(0).sort(function (a, b) {
    return b[2] - a[2];
  });
  let daysUsed = new Set<string>([]);
  for (let i = 0; i < sorted.length; i++) {
    if (!daysUsed.has(sorted[i][0])) {
      topDayPeaks.push(sorted[i]);
      daysUsed.add(sorted[i][0]);
    }
    if (topDayPeaks.length >= numberOfDayPeaks) {
      break;
    }
  }
  return topDayPeaks;
};

export function summarizeAggregateByMonths(aggregate: any, monthKeys: number[], summaryKey: string) {
  const timeSummary = {};
  const x = [];
  const y = [];
  let convertedMonthKeys = Object.keys(aggregate).filter(
    month => monthKeys.indexOf(parseInt(month.split('/')[0])) > -1
  );

  for (let i = 0; i < convertedMonthKeys.length; i++) {
    const monthKey = convertedMonthKeys[i];
    for (let timeKey in aggregate[monthKey][summaryKey]) {
      if (!timeSummary[timeKey]) {
        timeSummary[timeKey] = {
          value: aggregate[monthKey][summaryKey][timeKey].value,
          key: timeKey,
          count: 1
        };
      } else {
        timeSummary[timeKey].value += aggregate[monthKey][summaryKey][timeKey].value;
        timeSummary[timeKey].count += 1;
      }
    }
  }

  for (let timeKey in timeSummary) {
    const timeElement = timeSummary[timeKey];
    timeElement.value = timeElement.value / timeElement.count;
    x.push(timeKey);
    y.push(timeElement.value);
  }
  return { x, y };
}

// export function seasonalSummary(aggregate) {
//   // const aggregate = rollupData(data);
//   const seasonalSummary = {
//     summer: {
//       weekday: summarizeAggregateByMonths(aggregate, [6, 7, 8], 'weekday'),
//       weekend: summarizeAggregateByMonths(aggregate, [6, 7, 8], 'weekend')
//     },
//     winter: {
//       weekday: summarizeAggregateByMonths(aggregate, [12, 1, 2], 'weekday'),
//       weekend: summarizeAggregateByMonths(aggregate, [12, 1, 2], 'weekend')
//     },
//     fall: {
//       weekday: summarizeAggregateByMonths(aggregate, [9, 10, 11], 'weekday'),
//       weekend: summarizeAggregateByMonths(aggregate, [9, 10, 11], 'weekend')
//     },
//     spring: {
//       weekday: summarizeAggregateByMonths(aggregate, [3, 4, 5], 'weekday'),
//       weekend: summarizeAggregateByMonths(aggregate, [3, 4, 5], 'weekend')
//     }
//   };
// }

export function isDataEmpty(profileData: Data[]) {
  return !profileData.some(el => el[2] && el[2] !== 0);
}

export function generateIntraDayProfileGrid(
  frequency_per_hour: 1 | 4 = 1,
  startDate = moment().add(-1, 'y'),
  endDate = moment(),
  profile = [] as number[]
) {
  const profileGrid = generateProfileGrid(frequency_per_hour, startDate, endDate);
  const finalGrid = profileGrid.filter(item => {
    const itemDatetime = moment(item[0] + ' ' + item[1], 'MM/DD/YYYY HH:mm');
    return itemDatetime.isSameOrAfter(startDate) && itemDatetime.isBefore(endDate);
  });
  for (let i = 0; i < finalGrid.length; i++) {
    finalGrid[i][2] = profile[i] ? profile[i] : 0;
  }
  return finalGrid;
}

export function generateProfileGrid(
  frequency_per_hour: 1 | 4 = 1,
  startDate = moment().add(-1, 'y'),
  endDate = moment(),
  profile = [] as number[]
) {
  const grid: LoadProfile = [];
  const profileStartDate = moment(startDate);
  const profileEndDate = moment(endDate).clone().add(1, 'd').startOf('day');
  const duration = profileEndDate.valueOf() - profileStartDate.valueOf();
  const durationInDays = moment.duration({ milliseconds: duration }).asDays();
  const times = getTimeStamps(frequency_per_hour as 1 | 4);
  for (let i = 0; i < durationInDays; i++) {
    for (let j = 0; j < times.length; j++) {
      const profileIndex = i * 24 * frequency_per_hour + j;
      let value = profile[profileIndex] ? profile[profileIndex] : 0;
      grid.push([profileStartDate.format('MM/DD/YYYY'), times[j], value]);
    }
    profileStartDate.add(1, 'days');
  }
  return grid;
}
