import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateIESOComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before Demand Cost GA',
    label: 'Before Demand Cost GA',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Demand Cost GA',
    label: 'After Demand Cost GA',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Demand Peak Factor GA',
    label: 'Before Demand Peak Factor GA',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Demand Peak Factor GA',
    label: 'After Demand Peak Factor GA',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before DR Reduction',
    label: 'Before DR Reduction',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After DR Reduction',
    label: 'After DR Reduction',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before DR Reduction Capacity',
    label: 'Before DR Reduction Capacity',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After DR Reduction Capacity',
    label: 'After DR Reduction Capacity',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  return chartOptions;
};
