import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../reducers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  }
}));

const UtilityOverviewCard: React.FC = () => {
  const utility = useSelector((state: StoreState) => state.utility);

  const classes = useStyles();

  const { name, websiteHome } = utility?.utilityDetails;

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography color="inherit" variant="h6">
          Utility Overview
        </Typography>
      </Toolbar>
      <div style={{ padding: 8 * 3, paddingTop: 0 }}>
        <TextField
          label="Name"
          id="name"
          name="name"
          value={name || ''}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Website"
          id="website"
          name="website"
          value={websiteHome || ''}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
      </div>
    </Paper>
  );
};

export default UtilityOverviewCard;
