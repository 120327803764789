import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

//Material UI
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

//
import { StoreState } from '../../reducers';
import { updateLocale } from '../../actions/locale';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      minWidth: 200
    },
    select: {
      // color: 'white'
    }
  })
);

interface Props {}

const LocaleSelector: React.FC<Props> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locale = useSelector((state: StoreState) => state.locale);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(updateLocale(event.target.value as string));
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel shrink id="language-select-label">
        Currency format:
      </InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={locale}
        onChange={handleChange}
        className={classes.select}
      >
        <MenuItem value={'en-US'}>English (US)</MenuItem>
        <MenuItem value={'en-GB'}>English (UK)</MenuItem>
        <MenuItem value={'fr-FR'}>French</MenuItem>
        <MenuItem value={'fr-CA'}>Canadian French</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LocaleSelector;
