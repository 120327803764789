import { PUSH_BREADCRUMB, POP_BREADCRUMB, RESET_BREADCRUMB } from '../../actions/breadcrumbs';

const INITIAL_STATE = [];

function uniq(array) {
  return array.filter((obj, index, self) => index === self.findIndex(el => el.label === obj.label));
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PUSH_BREADCRUMB:
      return uniq([...state, action.payload]);
    case POP_BREADCRUMB:
      return uniq(state.slice(0, state.length - 1));
    case RESET_BREADCRUMB:
      return INITIAL_STATE;
    default:
      return state;
  }
}
