import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 220
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dialog: {
    padding: theme.spacing(2)
  },
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  button: {}
}));

export interface FacilityPresetDialogProps {
  open: boolean;
  handleClose: any;
  handleSuccessClose: any;
}

const facilityInfo = [
  { name: 'Hospital', size: 8498389, key: 'hospital' },
  { name: 'Hotel - Large', size: 2458786, key: 'hotel-large' },
  { name: 'Hotel - Small', size: 751880, key: 'hotel-small' },
  { name: 'Midrise Apartment', size: 248028, key: 'apartment-midrise' },
  { name: 'Office - Large', size: 6642784, key: 'office-large' },
  { name: 'Office - Medium', size: 846742, key: 'office-medium' },
  { name: 'Office - Small', size: 86655, key: 'office-small' },
  { name: 'Outpatient Health Care', size: 1565008, key: 'outpatient-healthcare' },
  { name: 'Restaurant - Full Service', size: 352240, key: 'estaurant-full-service' },
  { name: 'Restaurant - Fast Food', size: 188857, key: 'restaurant-fast-food' },
  { name: 'Retail Store', size: 486188, key: 'retail-store' },
  { name: 'School - Primary', size: 1095263, key: 'school-primary' },
  { name: 'School - Secondary', size: 2584380, key: 'school-secondary' },
  { name: 'Strip Mall', size: 491972, key: 'strip-mall' },
  { name: 'Supermarket', size: 1935886, key: 'supermarket' },
  { name: 'Warehouse', size: 182085, key: 'warehouse' }
];

const FacilityPresetDialog: React.FC<FacilityPresetDialogProps> = props => {
  const classes = useStyles();
  const { handleClose, handleSuccessClose, open } = props;

  const [facilityType, setFacilityType] = useState('office-small');

  const [facilitySize, setFacilitySize] = useState(86655);

  const handleFacilityTypeChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    setFacilityType(event.target.value);
    let currentFacilityItem = facilityInfo.find(item => item.key == event.target.value);
    if (currentFacilityItem) {
      setFacilitySize(currentFacilityItem.size);
    }
  };

  const handleFacilitySizeChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    setFacilitySize(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
      <DialogTitle>Import Facility Profile</DialogTitle>
      <DialogContent>
        <div className={classes.block}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-helper"></InputLabel>
            <Select value={facilityType} onChange={handleFacilityTypeChange}>
              {facilityInfo.map(facilityItem => (
                <MenuItem key={facilityItem.key} value={facilityItem.key}>
                  {facilityItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.block}>
          <TextField
            id="solarFrequency"
            name="solarFrequency"
            label="Annual energy consumption (kWh)"
            value={facilitySize}
            onChange={handleFacilitySizeChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleSuccessClose(facilityType, facilitySize)} color="primary">
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FacilityPresetDialog;
