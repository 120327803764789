import clsx from 'clsx';
import Chip from './Chip';
import { useStyles } from '../Index.styles';
import { FavoriteTariff } from '../../../types';


interface TariffChipsListProps<T> {
 tariffsList: FavoriteTariff[];
 title: string;
 handleFavoriteClick: (tariff: T) => void;
 handleTariffOnClick: (tariff: T) => void;
 emptyState?: string;
 children?: React.ReactNode;
}

const TariffChipsList = <T extends unknown>(props: TariffChipsListProps<T>) => {
  const styles = useStyles();
  const {
    title,
    tariffsList = [],
    emptyState,
    children,
    handleFavoriteClick,
    handleTariffOnClick,
  } = props;

  const favoriteOnClick = (tariff?: FavoriteTariff) => {
    tariff && handleFavoriteClick(tariff as T)
  };

  const tariffOnClick = (tariff?: FavoriteTariff) => {
    tariff && handleTariffOnClick(tariff as T)
  }

  return (
    <div className={styles.wFull}>
      <h3 className={clsx([styles.heading3, styles.textLeft])}>{title}</h3>
      <div data-test={`container|tariffs|${title}`} className={styles.chips}>
        {tariffsList.length ?
          tariffsList.map((tariff, index) => (
            <Chip
              key={`${tariff.code}-${index}`}
              value={tariff}
              label={`${tariff.code} - ${tariff.effective_date}`}
              isFavorite={tariff.isFavorite}
              onFavoriteClick={favoriteOnClick}
              onClick={tariffOnClick}
            />
          )) : (
            emptyState ? (
              <span>{emptyState}</span>
            ) : children
          )
        }
      </div>
    </div>
  )
}

export default TariffChipsList;
