import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#eee'
  },
  chip: {
    margin: 2
  }
});

function getFilterChips(filters) {
  const chips = [];
  if (filters.zipCode) {
    chips.push({
      label: 'Zipcode: ' + filters.zipCode,
      id: 'zipCode'
    });
  }
  if (filters.country && filters.country !== 'US') {
    chips.push({
      label: 'Country: ' + filters.country,
      id: 'country'
    });
  }
  if (filters.lse) {
    chips.push({
      label: 'Utility: ' + filters.lse,
      id: 'lse'
    });
  }
  if (filters.effectiveOn) {
    chips.push({
      label: 'Effective: ' + filters.effectiveOn,
      id: 'effectiveOn'
    });
  }
  if (filters.monthlyConsumptionAverage) {
    chips.push({
      label: 'Consumption Average: ' + filters.monthlyConsumptionAverage + ' kWh',
      id: 'monthlyConsumptionAverage'
    });
  }
  if (filters.annualMaxDemand) {
    chips.push({
      label: 'Annual Max Demand: ' + filters.annualMaxDemand + ' kW',
      id: 'annualMaxDemand'
    });
  }
  // Tariff Type
  if (filters.tariffTypeRider) {
    if (filters.tariffTypeRider) {
      chips.push({
        label: 'Tariff Type: Rider',
        id: 'tariffTypeRider'
      });
    }
  }
  // Rate Criteria
  if (filters.timeOfUse || filters.tiered || filters.contracted || filters.netMetering) {
    if (filters.timeOfUse) {
      chips.push({
        label: 'Rate: Time of Use',
        id: 'timeOfUse'
      });
    }
    if (filters.tiered) {
      chips.push({
        label: 'Rate: Tiered',
        id: 'tiered'
      });
    }
    if (filters.contracted) {
      chips.push({
        label: 'Rate: Contracted',
        id: 'contracted'
      });
    }
    if (filters.netMetering) {
      chips.push({
        label: 'Rate: Net Metering',
        id: 'netMetering'
      });
    }
  }

  // Customer Class
  if (filters.residential || filters.general || filters.specialUse) {
    if (filters.residential) {
      chips.push({
        label: 'Customer: Residential',
        id: 'residential'
      });
    }
    if (filters.general) {
      chips.push({
        label: 'Customer: General',
        id: 'general'
      });
    }
    if (filters.specialUse) {
      chips.push({
        label: 'Customer: Special use',
        id: 'specialUse'
      });
    }
  }
  return chips;
}

function handleDelete(id, props) {
  const newFilters = {
    ...props.filters,
    [id]: ''
  };
  props.handleFilterDelete(newFilters);
}

function TariffSearchParams(props) {
  const { classes } = props;
  const chipFilters = getFilterChips(props.filters);

  return (
    <div className={classes.root}>
      {chipFilters.map((filter, index) => {
        return (
          <Chip
            key={index}
            label={filter.label}
            className={classes.chip}
            onDelete={() => handleDelete(filter.id, props)}
          />
        );
      })}
    </div>
  );
}

TariffSearchParams.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  handleFilterDelete: PropTypes.func.isRequired
};

export default withStyles(styles)(TariffSearchParams);
