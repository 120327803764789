import React, { useState, useEffect } from 'react';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import DownloadIcon from '@material-ui/icons/GetApp';
import FileIcon from '@material-ui/icons/InsertDriveFile';
// REACT IMPORTS
import { Portfolio, PortfolioMeterResults, PortfolioResults } from '../../../types';
import { IconButton, Tooltip } from '@material-ui/core';
import { exportCSVFile } from '../../../utility/General';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    paddingBottom: 32
  },
  flex: {
    flex: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    padding: '0 0 25px 0',
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700,
    border: '1px solid rgb(234, 234, 234)',
    '& td, & th': {
      padding: '0 6px',
      textAlign: 'center',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      fontSize: '11px',
      color: '#333'
    },
    '& tr': {
      height: 32
    },
    '& tr>td:first-child, & tr:first-child>th:first-child': {
      fontWeight: '500',
      color: '#17173c',
      verticalAlign: 'middle',
      backgroundColor: '#ebf6ff'
    },
    '& tr:first-child th': {
      whiteSpace: 'normal',
      fontWeight: '500',
      color: '#111',
      backgroundColor: '#e8f1ee',
      lineHeight: '1.2em',
      verticalAlign: 'top',
      padding: '9px',
      fontSize: '11px'
    }
  },
  statusQuoHeader: {
    backgroundColor: '#ccc',
    textAlign: 'center',
    color: '#000'
  },
  scenarioHeader: {
    backgroundColor: '#0071FF',
    textAlign: 'center',
    color: '#fff'
  },
  savingsHeader: {
    backgroundColor: '#333',
    textAlign: 'center',
    color: '#fff'
  },
  highlight: {
    backgroundColor: 'rgba(255, 255, 0, 0.5)',
    fontWeight: 'bold'
  },
  legendBTM: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: '#e2ecf8',
    marginRight: 8,
    marginLeft: 8
  },
  legendNEM: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: '#f0f00063',
    marginRight: 8,
    marginLeft: 8
  }
}));

const ORDERED_KEYS = [
  'name',
  'consumed_kwh_sum',
  'energy_cost_sum',
  'solar_energy_savings_sum',
  'solar_demand_savings_sum',
  'battery_energy_savings_sum',
  'battery_demand_savings_sum',
  'tariff_demand_savings_sum',
  'tariff_energy_savings_sum',
  'pv_allocation',
  'exported_kwh_sum',
  'solar_nem_revenue_sum',
  'battery_nem_revenue_sum',
  'nem_credit_revenue_sum',
  'total_energy_savings_sum',
  'total_demand_savings_sum'
];
interface IProps {
  portfolio: Portfolio;
}

type Key = keyof PortfolioMeterResults | 'name' | 'pv_allocation';

interface TableColumn {
  key: Key;
  label: string;
  include: boolean;
  highlight: boolean;
  tooltip?: string;
  category?: 'btm' | 'nem' | 'total';
}

const initialColumns: TableColumn[] = [
  {
    key: 'name',
    label: 'Meter name',
    include: true,
    highlight: false
  },
  {
    key: 'pv_allocation',
    label: 'Percentage of NEM Credits Allocated',
    include: true,
    highlight: false,
    category: 'nem'
  }
];

const PortfolioResultsYearlyTableNemA: React.FC<IProps> = ({ portfolio }) => {
  const [data, setData] = useState<(string | number)[][]>([]);
  const classes = useStyles();

  const [tableColumns, setTableColumns] = useState(initialColumns);

  useEffect(() => {
    const columns = [...initialColumns];

    if (
      portfolio &&
      portfolio.results &&
      portfolio.results.portfolio_meters &&
      portfolio.results.portfolio_meters.length > 0
    ) {
      // build columns from every unique key found in meters results that includes _sum
      let columnKeys: Key[] = [];
      portfolio.results.portfolio_meters.forEach(meter => {
        columnKeys = columnKeys.concat(Object.keys(meter.results) as Key[]);
      });

      columnKeys = [...new Set(columnKeys.filter(key => key.includes('_sum')))];

      // re-order the column keys
      const indexEnergyCost = columnKeys.indexOf('energy_cost_sum');
      if (indexEnergyCost > -1) {
        columnKeys.splice(indexEnergyCost, 1);
        columnKeys = [...columnKeys, 'energy_cost_sum'];
      }

      const indexDemandTotal = columnKeys.indexOf('total_demand_savings_sum');
      if (indexDemandTotal > -1) {
        columnKeys.splice(indexDemandTotal, 1);
        columnKeys = [...columnKeys, 'total_demand_savings_sum'];
      }

      const indexEnergyTotal = columnKeys.indexOf('total_energy_savings_sum');
      if (indexEnergyTotal > -1) {
        columnKeys.splice(indexEnergyTotal, 1);
        columnKeys = [...columnKeys, 'total_energy_savings_sum'];
      }

      columnKeys.forEach(col => {
        columns.push({
          key: col,
          label: formatLabel(col),
          include: true,
          highlight: false,
          category: getColumnCategory(col)
        });
      });
    }

    setTableColumns(sortColumnsByKey(ORDERED_KEYS)(columns));
  }, [portfolio.results]);

  const getColumnCategory = (columnKey: string): 'btm' | 'nem' | 'total' | undefined => {
    columnKey = columnKey.toLowerCase();
    const btmResults = [
      'consumed_kwh_sum',
      'energy_cost_sum',
      'solar_energy_savings_sum',
      'solar_demand_savings_sum',
      'battery_energy_savings_sum',
      'battery_demand_savings_sum',
      'tariff_demand_savings_sum',
      'tariff_energy_savings_sum'
    ];

    const nemResults = [
      'pv_allocation',
      'exported_kwh_sum',
      'solar_nem_revenue_sum',
      'battery_nem_revenue_sum',
      'nem_credit_revenue_sum'
    ];

    if (columnKey.includes('total')) {
      return 'total';
    } else if (btmResults.includes(columnKey)) {
      return 'btm';
    } else if (nemResults.includes(columnKey)) {
      return 'nem';
    }
    return undefined;
  };

  const sortColumnsByKey = (order: string[]) => (columns: TableColumn[]): TableColumn[] => {
    const orderedColumns: TableColumn[] = [];
    order.forEach(key => {
      const column = columns.find(col => col.key === key);
      if (column) {
        orderedColumns.push(column);
      }
    });

    if (columns.length !== orderedColumns.length) {
      throw new Error(
        `Some columns keys are missing in ORDERED_KEYS\nColumns are ${columns}\nORDERED_KEYS are ${order}`
      );
    }

    return orderedColumns;
  };
  const formatLabel = (key: Key) => {
    return key
      .replace('_sum', '')
      .split('_')
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatRevenue = (value: number | string): string => {
    // if (value == undefined || (typeof value === 'number' && isNaN(value))) return '-';
    if (typeof value == 'string') return value;
    return Math.round(value).toLocaleString('en-US');
  };

  const getProposalName = (id: string): string => {
    const proposal = portfolio.portfolio_meters.find(p => p.proposal_id == id);

    return proposal ? (proposal.proposal_name as string) : 'N/A';
  };
  useEffect(() => {
    if (portfolio?.results?.portfolio_meters) {
      const buildData = (result: PortfolioResults) => {
        const data: (string | number)[][] = [];
        result.portfolio_meters?.forEach(meter => {
          const row: (string | number)[] = [];
          row.push(getProposalName(meter.proposal_id || ''));
          const pvAllocation = meter.pv_allocation * 100;

          const savings: (string | number)[] = tableColumns
            .filter(col => col.key !== 'name')
            .map(col => {
              if (col.key === 'pv_allocation') {
                return pvAllocation;
              }
              return meter.results[col.key] != undefined ? meter.results[col.key] : '-';
            });

          const totalSavings =
            meter.results.total_demand_savings_sum +
            meter.results.total_energy_savings_sum +
            meter.results.nem_credit_revenue_sum;

          data.push([...row, ...savings, totalSavings]);
        });

        const totalsRow = data.reduce((totals, row) => {
          row.forEach((col, index) => {
            if (typeof col == 'number') {
              totals[index] = ((totals[index] as number) || 0) + col;
            }
          });
          return totals;
        }, []);

        data.push(['Totals', ...totalsRow.slice(1)]);

        return data;
      };
      setData(buildData(portfolio.results));
    }
  }, [portfolio.results, tableColumns]);

  const handlePVProfileDownload = (index: number) => () => {
    if (!portfolio?.results?.pv_export || !portfolio?.results?.portfolio_meters) {
      throw new Error('No Meter PV profile available to download!');
    }
    const pvProfileSplit = portfolio.results.portfolio_meters[index].pv_allocation;
    const meterPvProfile = portfolio.results.pv_export.map(x => [x.datetime, x.value * pvProfileSplit]);
    const proposal_id = portfolio.results.portfolio_meters[index].proposal_id;
    const headers = ['Datetime', 'Value'];

    exportCSVFile(headers, meterPvProfile, `Meter PV Profile - ${getProposalName(proposal_id)}`);
  };

  const handleYearlyPVExport = () => {
    if (!portfolio?.results?.pv_export) {
      throw new Error('No Yearly PV Export available to download!');
    }
    const headers = ['Datetime', 'Value'];
    const pvProfile = portfolio.results.pv_export.map(x => [x.datetime, x.value]);
    exportCSVFile(headers, pvProfile, `Yearly PV Profile`);
  };

  const getCellBg = (item: TableColumn): string => {
    if (item.category === 'btm') {
      return '#e2ecf885';
    } else if (item.category === 'nem') {
      return '#f0f00026';
    }
    return 'inherit';
  };

  return (
    <Paper className={classes.root}>
      <Toolbar style={{ borderBottom: '1px solid rgb(229, 229, 229)' }}>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          Portfolio Yearly Results
        </Typography>
        <Tooltip title="Download Yearly PV Export">
          <IconButton onClick={handleYearlyPVExport}>
            <FileIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} padding="default">
          <TableHead>
            <TableRow>
              {tableColumns
                .filter(column => column.include)
                .map((item, i) => {
                  return (
                    <TableCell className={item.highlight ? classes.highlight : ''} key={i}>
                      {item.label}
                    </TableCell>
                  );
                })}
              <TableCell>Total Savings</TableCell>
              <TableCell>Meter PV profile</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow key={index} hover data-testid="result-table-row">
                  {tableColumns
                    .filter(column => column.include)
                    .map((column, columnIndex) => {
                      return (
                        <TableCell
                          style={{ backgroundColor: getCellBg(column) }}
                          className={column.highlight ? classes.highlight : ''}
                          key={columnIndex}
                        >
                          {formatRevenue(row[columnIndex])}
                        </TableCell>
                      );
                    })}
                  <TableCell>{formatRevenue(row[row.length - 1])}</TableCell>
                  <TableCell>
                    <Tooltip title="Download meter PV profile">
                      <IconButton onClick={handlePVProfileDownload(index)}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <div className={classes.legendBTM}></div>
        <span>Behind the meter Results</span>
        <div className={classes.legendNEM}></div>
        <span>NEM-Aggregation Results</span>
      </div>
    </Paper>
  );
};

export default PortfolioResultsYearlyTableNemA;
