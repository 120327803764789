import axios from 'axios';
import { Dispatch } from 'redux';
import { StoreState } from '../reducers';
import { DataType } from '../types';
import { UPDATE_EXPLORE_PAGE_INFORMATION } from './explore';
import moment from 'moment';

export enum UploadDataFileActionTypes {
  UPLOAD_DATA_FILE_REQUEST = 'UPLOAD_DATA_FILE_REQUEST',
  UPLOAD_LOAD_DATA_FILE_SUCCESS = 'UPLOAD_LOAD_DATA_FILE_SUCCESS',
  UPLOAD_SOLAR_DATA_FILE_SUCCESS = 'UPLOAD_SOLAR_DATA_FILE_SUCCESS',
  UPLOAD_HELIOSCOPE_DATA_FILE_SUCCESS = 'UPLOAD_HELIOSCOPE_DATA_FILE_SUCCESS',
  UPLOAD_DATA_FILE_ERROR = 'UPLOAD_DATA_FILE_ERROR'
}

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

const frequencyMapping = {
  '15min': 4,
  '60min': 1
};

export const uploadDataFile = (file: File, columnDates: string, columnValues: string, dataType: DataType) => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
  dispatch({ type: `UPLOAD_${dataType.toUpperCase()}_DATA_FILE_REQUEST` });
  const formData = new FormData();

  formData.set('date_column', columnDates);
  formData.set('load_column', columnValues);
  formData.append('file', file);
  axios
    .post<{ start: string; data: number[]; frequency: string }>(
      `${ROOT_URL}/proposal/data/clean/${dataType.toLowerCase()}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    .then(payload => {
      dispatch({
        type: `UPLOAD_${dataType.toUpperCase()}_DATA_FILE_SUCCESS`,
        payload: payload.data
      });
      dispatch({
        type: UPDATE_EXPLORE_PAGE_INFORMATION,
        payload: {
          startDate: moment(payload.data.start),
          endDate: moment(payload.data.start).add(1, 'y').subtract(1, 'd').endOf('day'),
          [`${dataType.toLowerCase()}Frequency`]: frequencyMapping[payload.data.frequency] || 4
        }
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({ type: `UPLOAD_${dataType.toUpperCase()}_DATA_FILE_ERROR`, payload: error });
    });
};

export const uploadHelioscopeDataFile = (file: File, startDate: string) => async (dispatch: Dispatch) => {
  dispatch({ type: `UPLOAD_HELIOSCOPE_DATA_FILE_REQUEST` });
  const formData = new FormData();

  formData.set('start_date', startDate);
  formData.append('file', file);
  axios
    .post<{ start: string; frequency: string; data: number[]; logs: string }>(
      `${ROOT_URL}/proposal/data/clean/helioscope`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    .then(payload => {
      dispatch({
        type: `UPLOAD_HELIOSCOPE_DATA_FILE_SUCCESS`,
        payload: payload.data
      });
      dispatch({
        type: UPDATE_EXPLORE_PAGE_INFORMATION,
        payload: {
          startDate: moment(payload.data.start)
        }
      });
      if (payload.data.logs) {
        dispatch({
          type: UPDATE_EXPLORE_PAGE_INFORMATION,
          payload: {
            [`solarLog`]: payload.data.logs
          }
        });
      }
      dispatch({
        type: UPDATE_EXPLORE_PAGE_INFORMATION,
        payload: {
          [`solarFrequency`]: 1
        }
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({ type: `UPLOAD_HELIOSCOPE_DATA_FILE_ERROR`, payload: error });
    });
};
