/**
 * Takes care of the state management for general tariff creation
 * and EV tariff creation, tariff edition too
 * **/
import React, { useCallback, useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChargesType } from './RatePeriodsEditor';
import { TOUTypes } from '../../../types';
import { resetTariff } from '../../../actions';
import { StoreState } from '../../../reducers';
import { Repeat } from 'immutable';

export type Period = Readonly<{
  name: string;
  index: number;
  TOU?: TOUTypes;
  charges: string;
  type: 'DEMAND_BASED' | 'CONSUMPTION_BASED' | 'SUBSCRIPTION_BASED';
  color?: string;
  reservationSize?: string;
  months?: {
    from: number;
    to: number;
  };
  daysAndHours?: {
    fromHour: number;
    toHour: number;
    fromDayOfWeek: number;
    toDayOfWeek: number;
  }[];
}>;

interface ITariffBuilderContext {
  handleChangeInput(key: ChargesType, name: keyof Period, index: number, value: string): void;
  handleChangeTOU(type: ChargesType, index: number, value: TOUTypes): void;
  handleDeletePeriod(type: ChargesType, index: number): any;
  handleAddPeriodRate: (type: ChargesType) => void;
  dispatchMatrix: React.Dispatch<MatrixAction>;
  handleClearSelection: (index: number) => void;
  handleCopyWeekdays: (type: ChargesType) => void;
  readonly matrix: ScheduleMatrix;
  copyScheduleToDemandCharges(): any;
  periods: { energy: Period[]; demand: Period[]; subscriptions: Period[] };
  setPeriods: React.Dispatch<
    React.SetStateAction<{
      energy: Period[];
      demand: Period[];
      subscriptions: Period[];
    }>
  >;
  tariffMeta: TariffMetadata;
  dispatchTariffMeta: React.Dispatch<TariffMetaAction>;
  handleChange: any;
  handleLSEChange: any;
  overage: OverageCharges;
  setOverage: React.Dispatch<React.SetStateAction<OverageCharges>>;
  resetCustomTariff: any;
  factoryState: FactoryState;
  setFactoryState: React.Dispatch<React.SetStateAction<FactoryState>>;
  openUsedPeriod: boolean;
  setOpenUsedPeriod: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface OverageCharges {
  readonly charges: string;
  readonly type: string;
}

export interface TariffMetaAction {
  type: string;
  payload?: any;
}

export interface TariffMetadata {
  name: string;
  description: string;
  lse_id: string;
  lse_name: string;
  code: string;
}
export interface MatrixAction {
  type: 'DEMAND_WEEKDAY' | 'DEMAND_WEEKEND' | 'ENERGY_WEEKDAY' | 'ENERGY_WEEKEND' | 'RESET_MATRIX';
  matrix: number[][];
}

export type ScheduleMatrix = Readonly<{
  energyWeekdaySchedule: Readonly<number[][]>;
  energyWeekendSchedule: Readonly<number[][]>;
  demandWeekdaySchedule: Readonly<number[][]>;
  demandWeekendSchedule: Readonly<number[][]>;
}>;

const matrixReducer = (state: ScheduleMatrix, action: MatrixAction) => {
  switch (action.type) {
    case 'DEMAND_WEEKDAY':
      return { ...state, demandWeekdaySchedule: action.matrix };
    case 'DEMAND_WEEKEND':
      return { ...state, demandWeekendSchedule: action.matrix };
    case 'ENERGY_WEEKDAY':
      return { ...state, energyWeekdaySchedule: action.matrix };
    case 'ENERGY_WEEKEND':
      return { ...state, energyWeekendSchedule: action.matrix };
    case 'RESET_MATRIX':
      return {
        energyWeekdaySchedule: initialMatrix.map(arr => arr.slice()),
        energyWeekendSchedule: initialMatrix.map(arr => arr.slice()),
        demandWeekdaySchedule: initialMatrix.map(arr => arr.slice()),
        demandWeekendSchedule: initialMatrix.map(arr => arr.slice())
      };
    default:
      return state;
  }
};
export const getColor = (i: number): string => {
  const colors = [
    '#C7D5DD',
    '#9DC6DD',
    '#3D7A9E',
    '#E1CA96',
    '#FE938C',
    '#D7F171',
    '#B5EF8A',
    '#7FC29B',
    '#BBBDF6',
    '#817E9F',
    '#8AEA92',
    '#D4E09B',
    '#F6F4D2',
    '#636940',
    '#7B5E7B',
    '#A0DDFF',
    '#758ECD',
    '#FA9F42',
    '#CCDBDC',
    '#80CED7',
    '#6CAE75',
    '#EAC8CA',
    '#F2D5F8'
  ];
  return i < colors.length ? colors[i - 1] : '#FFFFFF';
};

// create array of array filled with 1
const initialMatrix: Readonly<number[][]> = Repeat(Repeat(1, 24), 12).toJS();

const tariffMetaReducer = (state: TariffMetadata, action: TariffMetaAction) => {
  switch (action.type) {
    case 'UPDATE_NAME':
      return { ...state, name: action.payload };
    case 'UPDATE_DESCRIPTION':
      return { ...state, description: action.payload };
    case 'UPDATE_CODE':
      return { ...state, code: action.payload };
    case 'UPDATE_LSE':
      return { ...state, lse_name: action.payload.lse_name, lse_id: action.payload.lse_id };
    case 'UPDATE_ALL':
      return { ...state, ...action.payload };
    case 'RESET':
      return {
        name: '',
        code: '',
        description: '',
        lse_id: '0',
        lse_name: 'Esap Created'
      };
    default:
      return state;
  }
};

const initialStateMatrix: ScheduleMatrix = {
  energyWeekdaySchedule: initialMatrix.map(arr => arr.slice()),
  energyWeekendSchedule: initialMatrix.map(arr => arr.slice()),
  demandWeekdaySchedule: initialMatrix.map(arr => arr.slice()),
  demandWeekendSchedule: initialMatrix.map(arr => arr.slice())
};

const initialEnergyPeriods: Period[] = [
  {
    index: 1,
    name: 'Period 1',
    TOU: TOUTypes.OFF_PEAK,
    charges: '0',
    type: 'CONSUMPTION_BASED',
    color: getColor(1)
  },
  {
    index: 2,
    name: 'Period 2',
    TOU: TOUTypes.PARTIAL_PEAK,
    charges: '0',
    type: 'CONSUMPTION_BASED',
    color: getColor(2)
  },
  {
    index: 3,
    name: 'Period 3 ',
    TOU: TOUTypes.ON_PEAK,
    charges: '0',
    type: 'CONSUMPTION_BASED',
    color: getColor(3)
  }
];

const initialDemandPeriods: Period[] = [
  {
    index: 1,
    name: 'Period 1',
    TOU: TOUTypes.OFF_PEAK,
    charges: '0',
    type: 'DEMAND_BASED',
    color: getColor(1)
  },
  {
    index: 2,
    name: 'Period 2',
    TOU: TOUTypes.ON_PEAK,
    charges: '0',
    type: 'DEMAND_BASED',
    color: getColor(2)
  }
];

const initialSubscriptions: Period[] = [
  { index: 0, name: 'Subscription #1', reservationSize: '0', charges: '0', type: 'SUBSCRIPTION_BASED' }
];

const initialOverageCharges: OverageCharges = { charges: '0', type: 'kW' };

export const TariffFactoryContext = React.createContext<ITariffBuilderContext>({} as ITariffBuilderContext);

const initialState: TariffMetadata = {
  name: '',
  code: '',
  description: '',
  lse_id: '0',
  lse_name: 'Esap Created'
};
type FactoryState = 'CREATE' | 'EDIT';

const TariffFactoryProvider: React.FC = ({ children }) => {
  const [tariffMeta, dispatchTariffMeta] = useReducer(tariffMetaReducer, initialState);
  const [matrix, dispatchMatrix] = useReducer(matrixReducer, initialStateMatrix);

  const [factoryState, setFactoryState] = useState<FactoryState>('CREATE');

  const dispatch = useDispatch();
  const [overage, setOverage] = useState(initialOverageCharges);
  const [openUsedPeriod, setOpenUsedPeriod] = useState(false);

  const [periods, setPeriods] = useState<{
    energy: Period[];
    demand: Period[];
    subscriptions: Period[];
  }>({
    energy: initialEnergyPeriods,
    demand: initialDemandPeriods,
    subscriptions: initialSubscriptions
  });

  const resetCustomTariff = () => {
    dispatch(resetTariff());
    clearForms();
  };

  const clearForms = () => {
    dispatchTariffMeta({ type: 'RESET' });
    dispatchMatrix({ type: 'RESET_MATRIX', matrix: initialMatrix.map(arr => arr.slice()) }); // that's a dummy matrix payload so typescript doesnt yell at me
    setPeriods({
      energy: initialEnergyPeriods,
      demand: initialDemandPeriods,
      subscriptions: initialSubscriptions
    });
  };

  const tariff = useSelector((state: StoreState) => state.tariff);
  useEffect(() => {
    if (tariff && Object.keys(tariff).length === 0) {
      clearForms();
    }
  }, [tariff]);

  const handleChange = useCallback(
    (name: keyof TariffMetadata) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatchTariffMeta({ type: `UPDATE_${name.toUpperCase()}`, payload: event.target.value });
    },
    [dispatchTariffMeta]
  );

  const handleLSEChange = useCallback(
    (newValue: any) => {
      dispatchTariffMeta({
        type: 'UPDATE_LSE',
        payload: {
          lse_id: newValue.value,
          lse_name: newValue.label
        }
      });
    },
    [dispatchTariffMeta]
  );

  const handleAddPeriodRate = (type: ChargesType) => {
    let newIndex = 1;
    if (periods && periods[type] && periods[type].length > 0) {
      const lastPeriod = periods[type][periods[type].length - 1];
      newIndex = lastPeriod.index + 1;
    }
    const newPeriod =
      type === 'subscriptions'
        ? {
            index: newIndex,
            name: `Subscription #${newIndex + 1}`,
            charges: '0',
            reservationSize: '0',
            type: 'SUBSCRIPTION_BASED'
          }
        : {
            index: newIndex,
            name: `Period ${newIndex}`,
            TOU: TOUTypes.ON_PEAK,
            charges: '0',
            color: getColor(newIndex)
          };

    const updatedPeriods = {
      ...periods,
      [type]: [...periods[type], newPeriod]
    };
    setPeriods(updatedPeriods);
  };

  const handleChangeTOU = (type: ChargesType, index: number, value: TOUTypes) => {
    const periodIndex = periods[type].findIndex((period: Period) => period.index === index);
    if (periodIndex > -1) {
      const newPeriod = { ...periods[type][periodIndex], TOU: value } as Period;
      const updatedState = [...periods[type].slice(0, periodIndex), newPeriod, ...periods[type].slice(periodIndex + 1)];
      setPeriods({ ...periods, [type]: updatedState });
    }
  };

  const handleChangeInput = (type: ChargesType, name: keyof Period, index: number, value: string) => {
    const periodIndex = periods[type].findIndex((period: Period) => period.index === index);
    if (periodIndex > -1) {
      const newPeriod = { ...periods[type][periodIndex], [name]: value };
      const updatedState = [...periods[type].slice(0, periodIndex), newPeriod, ...periods[type].slice(periodIndex + 1)];
      setPeriods({ ...periods, [type]: updatedState });
    }
  };

  const isPeriodUsed = (type: ChargesType, index: number) => {
    let arrayOfIndexes: number[][] = [];
    if (type == 'energy') {
      arrayOfIndexes = [...Object.values(matrix.energyWeekdaySchedule), ...Object.values(matrix.energyWeekendSchedule)];
    } else if (type == 'demand') {
      arrayOfIndexes = [...Object.values(matrix.demandWeekdaySchedule), ...Object.values(matrix.demandWeekendSchedule)];
    }
    const setOfIndexes = new Set(arrayOfIndexes.flat().flat());
    return setOfIndexes.has(index + 1);
  };

  const deletePeriod = (type: ChargesType, i: number) => {
    const updatedPeriods = [...periods[type]];
    updatedPeriods.splice(i, 1);
    setPeriods({ ...periods, [type]: updatedPeriods });
    // update matrix by resetting the index
    // if energy charges
    // if (type == 'energy') {
    //   dispatchMatrix({
    //     type: 'ENERGY_WEEKDAY',
    //     matrix: matrix.energyWeekdaySchedule.map(row => row.map(col => (col == i + 1 ? 1 : col)))
    //   });
    //   dispatchMatrix({
    //     type: 'ENERGY_WEEKEND',
    //     matrix: matrix.energyWeekendSchedule.map(row => row.map(col => (col == i + 1 ? 1 : col)))
    //   });
    // }

    // // if demand charges
    // if (type == 'demand') {
    //   dispatchMatrix({
    //     type: 'DEMAND_WEEKDAY',
    //     matrix: matrix.demandWeekdaySchedule.map(row => row.map(col => (col == i + 1 ? 1 : col)))
    //   });
    //   dispatchMatrix({
    //     type: 'DEMAND_WEEKEND',
    //     matrix: matrix.demandWeekendSchedule.map(row => row.map(col => (col == i + 1 ? 1 : col)))
    //   });
    // }
  };

  const handleDeletePeriod = (type: ChargesType, i: number) => (e: any) => {
    if (isPeriodUsed(type, i)) {
      setOpenUsedPeriod(true);
      return;
    }
    deletePeriod(type, i);
  };

  const handleClearSelection = useCallback((index: number) => {
    const cells = document.querySelectorAll('.cell-grid-item') as NodeListOf<HTMLElement>;
    const cellsArray = Array.from(cells);

    const cellsToClear = getCellsToClear(index, cellsArray);

    cellsToClear.forEach(cell => {
      cell.innerHTML = '1';
      cell.style.backgroundColor = '#C7D5DD';
    });
  }, []);

  const handleCopyWeekdays = (type: ChargesType) => {
    if (type === 'energy') {
      const weekdays = [...matrix.energyWeekdaySchedule];
      dispatchMatrix({ type: 'ENERGY_WEEKEND', matrix: weekdays });
      const cells = document.querySelectorAll('.cell-grid-item') as NodeListOf<HTMLElement>;
      const cellsArray = Array.from(cells);
      const cellsFirstGrid = cellsArray.slice(0, 288);
      const cellsSecondGrid = cellsArray.slice(288, 576);
      cellsSecondGrid.forEach((cell, index) => {
        cell.innerText = cellsFirstGrid[index].innerText;
        cell.style.backgroundColor = cellsFirstGrid[index].style.backgroundColor;
      });
    } else if (type === 'demand') {
      const weekdays = [...matrix.demandWeekdaySchedule];
      dispatchMatrix({ type: 'DEMAND_WEEKEND', matrix: weekdays });
      const cells = document.querySelectorAll('.cell-grid-item') as NodeListOf<HTMLElement>;
      const cellsArray = Array.from(cells);
      const cellsWeekdayDemand = cellsArray.slice(576, 864);
      const cellsWeekendDemand = cellsArray.slice(864);
      cellsWeekendDemand.forEach((cell, index) => {
        cell.innerText = cellsWeekdayDemand[index].innerText;
        cell.style.backgroundColor = cellsWeekdayDemand[index].style.backgroundColor;
      });
    }
  };

  const copyScheduleToDemandCharges = () => {
    dispatchMatrix({
      type: 'DEMAND_WEEKDAY',
      matrix: [...matrix.energyWeekdaySchedule]
    });
    dispatchMatrix({
      type: 'DEMAND_WEEKEND',
      matrix: [...matrix.energyWeekendSchedule]
    });

    const cells = document.querySelectorAll('.cell-grid-item') as NodeListOf<HTMLElement>;
    const cellsArray = Array.from(cells);

    // copythe weeday over
    const energyWeekdayGrid = cellsArray.slice(0, 288);
    const demandWeekdayGrid = cellsArray.slice(576, 864);
    demandWeekdayGrid.forEach((cell, index) => {
      cell.innerText = energyWeekdayGrid[index].innerText;
      cell.style.backgroundColor = energyWeekdayGrid[index].style.backgroundColor;
    });

    // copythe weedend over
    const energyWeekendGrid = cellsArray.slice(288, 576);
    const demandWeekendGrid = cellsArray.slice(864);
    demandWeekendGrid.forEach((cell, index) => {
      cell.innerText = energyWeekendGrid[index].innerText;
      cell.style.backgroundColor = energyWeekendGrid[index].style.backgroundColor;
    });
  };
  const getCellsToClear = (index: number, cellsArray: HTMLElement[]): HTMLElement[] => {
    switch (index) {
      case 1:
        dispatchMatrix({
          type: 'ENERGY_WEEKDAY',
          matrix: initialMatrix.map(arr => arr.slice())
        });
        return cellsArray.slice(0, 288);
      case 2:
        dispatchMatrix({
          type: 'ENERGY_WEEKEND',
          matrix: initialMatrix.map(arr => arr.slice())
        });
        return cellsArray.slice(288, 576);
      case 3:
        dispatchMatrix({
          type: 'DEMAND_WEEKDAY',
          matrix: initialMatrix.map(arr => arr.slice())
        });
        return cellsArray.slice(576, 864);
      case 4:
        dispatchMatrix({
          type: 'DEMAND_WEEKEND',
          matrix: initialMatrix.map(arr => arr.slice())
        });
        return cellsArray.slice(864);

      default:
        return [];
    }
  };

  const contextValue = {
    tariffMeta,
    dispatchTariffMeta,
    handleChange,
    handleLSEChange,
    periods,
    setPeriods,
    handleChangeInput,
    handleChangeTOU,
    handleDeletePeriod,
    handleAddPeriodRate,
    handleClearSelection,
    handleCopyWeekdays,
    matrix,
    dispatchMatrix,
    copyScheduleToDemandCharges,
    overage,
    setOverage,
    resetCustomTariff,
    factoryState,
    setFactoryState,
    openUsedPeriod,
    setOpenUsedPeriod
  };

  return <TariffFactoryContext.Provider value={contextValue}>{children}</TariffFactoryContext.Provider>;
};

export default TariffFactoryProvider;
