import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QueueIcon from '@material-ui/icons/Queue';
import NoSsr from '@material-ui/core/NoSsr';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { Dashboard, Person, Assessment, Description, Business, Settings, Folder } from '@material-ui/icons';
import { StoreState } from '../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: 24,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300]
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12)
    }
  },
  avatar: {
    color: 'rgba(0, 0, 0, 0.54)',
    background: 'none',
    marginRight: -theme.spacing(1.5)
  }
}));

const StyledBreadcrumb: React.FC<any> = props => {
  const classes = useStyles();
  return <Chip className={classes.chip} {...props} />;
};

const iconsMap = {
  Proposals: <Assessment />,
  Portfolios: <Folder />,
  Customers: <Person />,
  Tariffs: <Description />,
  Settings: <Settings />,
  Utilities: <Business />,
  Explore: <Dashboard />,
  'Jobs Queue': <QueueIcon />
};

const CustomizedBreadcrumbs: React.FC<RouteComponentProps> = ({ location }) => {
  const breadcrumbs = useSelector((state: StoreState) => state.breadcrumbsTrail);
  const proposal = useSelector((state: StoreState) => state.proposal);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const handleToggle = (event: any): void => {
    if (!event.currentTarget.toString().includes('HTMLDocument')) {
      // bugfix hack for when user clicks same scenario in breadcrumb
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const shortenLabel = (label: string): string => {
    if (typeof label === 'string' && label.length > 55) {
      return label.slice(0, 54) + '...';
    }
    return label;
  };

  const open = Boolean(anchorEl);

  return (
    <NoSsr>
      <Paper className={classes.root}>
        <Breadcrumbs arial-label="Breadcrumb">
          {/* first have a link to the home route and then iterate over the pathnames */}
          <Link to={breadcrumbs[0] ? breadcrumbs[0].link : '/'} style={{ textDecoration: 'none' }}>
            <StyledBreadcrumb
              label={breadcrumbs[0] ? breadcrumbs[0].label : ''}
              avatar={
                <Avatar className={classes.avatar}>
                  {breadcrumbs[0] ? iconsMap[breadcrumbs[0].label] : <HomeIcon />}
                </Avatar>
              }
            />
          </Link>
          {breadcrumbs.slice(1).map((breadcrumb, index) => {
            const last = index === breadcrumbs.slice(1).length - 1;
            const to = `/${breadcrumbs.slice(0, index + 1).join('/')}`;

            return last && location.pathname.includes('/scenarios/') && proposal.proposal_scenarios.length > 1 ? (
              // scenario dropdown here
              <span key={to}>
                <StyledBreadcrumb
                  label={breadcrumb.label}
                  deleteIcon={<ExpandMoreIcon />}
                  onClick={handleToggle}
                  onDelete={handleToggle}
                />
                <Popper open={open} anchorEl={anchorEl} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                      }}
                    >
                      <Paper id="menu-list-grow">
                        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                          <MenuList>
                            {proposal.proposal_scenarios
                              .filter(current => !location.pathname.includes(current.public_id as string))
                              .filter(s => s.active)
                              .map((scenario, i) => (
                                <Link
                                  to={`/proposals/${proposal.public_id}/scenarios/${scenario.public_id}`}
                                  key={scenario?.public_id ? (scenario.public_id as string) : undefined}
                                  style={{ textDecoration: 'none' }}
                                >
                                  <MenuItem onClick={handleToggle}>
                                    <StyledBreadcrumb label={shortenLabel(scenario.name)} />
                                  </MenuItem>
                                </Link>
                              ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </span>
            ) : (
              <Link to={breadcrumb.link} style={{ textDecoration: 'none' }} key={breadcrumb.link}>
                <StyledBreadcrumb label={shortenLabel(breadcrumb.label)} />
              </Link>
            );
          })}
        </Breadcrumbs>
      </Paper>
    </NoSsr>
  );
};

export default withRouter(CustomizedBreadcrumbs);
