import { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { buildTariffsQueryString, fetchTariffsList, fromGenabilityToESAP } from '../actions';
import { ESAPTariff, EsapTariffsFilters } from '../types';

export interface IUseGlobalTariffs {
  results: Partial<ESAPTariff>[];
  count: number;
}

export function useGlobalTariffs(filters: EsapTariffsFilters) {
  let query = buildTariffsQueryString(filters);

  return useQuery<IUseGlobalTariffs, AxiosError>(
    ['Tarrif', 'GlobalTarriff', filters],
    () => fetchTariffsList(query),
    {
      refetchOnWindowFocus: false,
      staleTime: 50000,
      select: React.useCallback(
        (data) => ({ results: data.results.map(fromGenabilityToESAP), count: data.count}),
        []
      ),
    }
  );
}
