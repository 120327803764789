import {
  AssumptionsInternal,
  AssumptionsExternal,
  ScenarioExternal,
  ScenarioInternal,
  ProposalExternal,
  ProposalInternal,
  EVShiftInternal,
  EVShiftExternal
} from '../../types';
import moment from 'moment';
import { assertIsDefined } from '../assertions';
import { mapIfArray } from '../arrays';
import { cloneDeep } from 'lodash';

/****************************************************************************/
/* . ASSUMPTIONS LEVEL                                                      */
/****************************************************************************/

export const importAssumptions = (optimizerAssumptions: AssumptionsExternal): AssumptionsInternal => {
  let copy = { ...optimizerAssumptions };
  // convert ev arrival time and deviation ie ( "09:00", 1 )  to [8, 10]
  const updatedEVShift = mapIfArray(importEVArrivalTime, copy?.evShifts);
  return { ...copy, evShifts: updatedEVShift } as AssumptionsInternal;
};

export const exportAssumptions = (esapAssumptions: AssumptionsInternal): AssumptionsExternal => {
  let copy = { ...esapAssumptions };

  // convert strings to numeric values, temporary fix, should extract that to a function
  copy.ICAPCost = checkParseFloat(copy.ICAPCost);
  copy.ITRANCost = checkParseFloat(copy.ITRANCost);

  // convert ev arrival time and deviation ie [8, 10] to ( "09:00", 1 )
  const updatedEVShift = mapIfArray(exportEVArrivalTime, copy?.evShifts);
  return { ...copy, evShifts: updatedEVShift } as AssumptionsExternal;
};

/****************************************************************************/
/* . SCENARIOS LEVEL                                                        */
/****************************************************************************/

// import
export const convertExternalScenarioAssumptions = (scenario: ScenarioExternal): ScenarioInternal => {
  if (!scenario?.assumptions || Object.keys(scenario?.assumptions).length === 0) {
    return (scenario as unknown) as ScenarioInternal; // dont touch the scenario if it doesnt hold assumptions or its a partial update
  }
  const copy = cloneDeep(scenario);
  return { ...copy, assumptions: importAssumptions(copy?.assumptions) };
};

// export
export const convertInternalScenarioAssumptions = (scenario: ScenarioInternal): ScenarioExternal => {
  if (!scenario?.assumptions || Object.keys(scenario?.assumptions).length === 0) {
    return (scenario as unknown) as ScenarioExternal; // dont touch the scenario if it doesnt hold assumptions or its a partial update
  }
  const copy = cloneDeep(scenario);
  return { ...copy, assumptions: exportAssumptions(copy?.assumptions) };
};

/****************************************************************************/
/* . PROPOSAL LEVEL                                                         */
/****************************************************************************/

// import
export const convertExternalProposalAssumptions = (proposal: ProposalExternal): ProposalInternal => {
  return {
    ...proposal,
    proposal_scenarios:
      proposal.proposal_scenarios && Array.isArray(proposal.proposal_scenarios)
        ? proposal.proposal_scenarios.map(convertExternalScenarioAssumptions)
        : []
  };
};

//export
export const convertInternalProposalAssumptions = (proposal: ProposalInternal): ProposalExternal => {
  return {
    ...proposal,
    proposal_scenarios:
      proposal.proposal_scenarios && Array.isArray(proposal.proposal_scenarios)
        ? proposal.proposal_scenarios.map(convertInternalScenarioAssumptions)
        : []
  };
};

/****************************************************************************/
/* . SINGLE ASSUMPTION CONVERTION ON THE WAY IN                             */
/****************************************************************************/

function importEVArrivalTime(shift: EVShiftExternal): EVShiftInternal {
  assertIsDefined(shift, 'shift is undefined');
  // assertIsDefined(shift.muArrival, 'Didnt find muArrival, assumptions dont have the expected shape.');
  // assertIsDefined(
  //   shift.muArrivalStdDeviation,
  //   'Didnt find muArrivalStdDeviation, assumptions dont have the expected shape.'
  // );
  let copy = { ...shift };
  // default to [9,0] and 1 for the broken scenarios that dont have saved values
  const [hour, minute] = copy?.muArrival ? copy.muArrival.split(':') : ['9', '0'];
  const deviation = copy.muArrivalStdDeviation ?? 1;
  const deviationHour = Math.floor(deviation);
  const deviationMinutes = (deviation - deviationHour) * 60;

  const arrivalTime = moment({ hour: parseInt(hour), minute: parseInt(minute) });
  let min = arrivalTime.clone().subtract(deviationHour, 'hours').subtract(deviationMinutes, 'minutes').hour();
  let max = arrivalTime.clone().add(deviationHour, 'hours').add(deviationMinutes, 'minutes').hour();

  let isOvernight = false;

  if (min > max) {
    isOvernight = true;
    const temp = min;
    min = max;
    max = temp;
  }

  delete (copy as any).muArrival;
  delete (copy as any).muArrivalStdDeviation;

  return { ...copy, evArrivalRange: [min, max], isOvernight } as EVShiftInternal;
}

/****************************************************************************/
/* . SINGLE ASSUMPTION CONVERTION ON THE WAY OUT                            */
/****************************************************************************/

function exportEVArrivalTime(shift: EVShiftInternal): EVShiftExternal {
  assertIsDefined(shift.evArrivalRange, 'Didnt find evArrivalRange, assumptions dont have the expected shape.');

  let copy = { ...shift };
  const [start, end] = shift.evArrivalRange;

  const arrivalTime = !shift.isOvernight ? (start + end) / 2 : (end - 24 + start) / 2;
  const deviation = !shift.isOvernight ? (end - start) / 2 : (24 - end + start) / 2;

  const arrivalHour = Math.floor(arrivalTime);
  const arrivalMinutes = (arrivalTime - arrivalHour) * 60;

  const arrivalTimeToString = moment({ hour: arrivalHour, minutes: arrivalMinutes }).format('H:mm');

  delete (copy as any).evArrivalRange;

  return { ...copy, muArrival: arrivalTimeToString, muArrivalStdDeviation: deviation } as EVShiftExternal;
}

/****************************************************************************/
/* . HELPERS                                                                */
/****************************************************************************/

// function assertProposalHasScenarios(proposal: ProposalExternal | ProposalInternal) {
//   if (!proposal?.proposal_scenarios || !Array.isArray(proposal?.proposal_scenarios)) {
//     throw new Error(`The proposal with id ${proposal?.public_id} should hold scenarios and isn't.`);
//   }
// }

function checkParseFloat(value: string | number): number {
  if (typeof value === 'string') return parseFloat(value);
  return value;
}
