import { formatDate } from './Dates';

function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

export const usersColumnSchema = [
  {
    key: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email'
  },
  {
    key: 'auth0_created_on',
    numeric: false,
    disablePadding: false,
    label: 'Created On',
    transform: formatDate
  }
];
