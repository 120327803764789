import { GET_ALL_PORTFOLIOS_SUCCESS } from '../../actions/portfolios';

const INITIAL_STATE = {
  page: 0,
  pageSize: 10,
  total: 0
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_ALL_PORTFOLIOS_SUCCESS:
      let { portfolios, ...meta } = action.payload;
      return { ...state, ...meta };
    default:
      return state;
  }
}
