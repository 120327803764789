import React, { useEffect, useState } from 'react';
import { LsaCases, PeakLoadMagnitudeChange } from '../../../../types';
import Plotly from '../../../../custom-plotly.js';
import { Layout, PlotData } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

// MATERIAL UI IMPORTS
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import CurrencyDisplay from '../../../../utility/CurrencyDisplay';
import Help from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { MAGNITUDE_TOOLTIP } from './LSAToolipsDef';

const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    header: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      background: '#e2ecf8'
    },
    formControl: {
      margin: theme.spacing(3)
    },
    peakSelection: {
      width: 160,
      marginTop: 18,
      marginLeft: theme.spacing(3)
    },
    button: {
      margin: 30
    },
    questionMark: {
      fontSize: 16,
      color: '#929eaa',
      paddingTop: 4
    }
  })
);

const layout: Partial<Layout> = {
  legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
  margin: {
    t: 20,
    l: 60,
    r: 20,
    b: 120
  },
  xaxis: {
    tickangle: -45
  },
  yaxis: {
    title: 'Saving Changes (%)'
  }
};

interface LSAMagnitudeChangesChartsProps {
  data: PeakLoadMagnitudeChange;
  scenarioSavings: { energy: number; demand: number; total: number };
}

type SelectedPeak = 'top_5' | 'top_10' | 'top_15';

interface TableData {
  scenario: string;
  energy_savings: number;
  energy_savings_change: number;
  energy_savings_percent: number;
  demand_savings: number;
  demand_savings_change: number;
  demand_savings_percent: number;
  total_savings: number;
  total_savings_change: number;
  total_savings_percent: number;
  color: string;
}

const columnSchema = [
  {
    key: 'scenario',
    label: 'Scenario',
    align: 'left',
    isCurrency: false
  },
  { key: 'energy_savings', label: 'Energy Savings', align: 'right', isCurrency: true },
  { key: 'energy_savings_change', label: 'Energy Savings Change', align: 'right', isCurrency: true },
  {
    key: 'energy_savings_percent',
    label: 'Energy Savings Change (%)',
    align: 'right',
    isCurrency: false
  },
  { key: 'demand_savings', label: 'Demand Savings', align: 'right', isCurrency: true },
  { key: 'demand_savings_change', label: 'Demand Savings Change', align: 'right', isCurrency: true },
  { key: 'demand_savings_percent', label: 'Demand Savings Change (%)', align: 'right', isCurrency: false },
  {
    key: 'total_savings',
    label: 'Total Savings',
    align: 'right',
    isCurrency: true
    // transform: formatDate
  },
  {
    key: 'total_savings_change',
    label: 'Total Savings Change',
    align: 'right',
    isCurrency: true
    // transform: formatDate
  },
  {
    key: 'total_savings_percent',
    label: 'Total Savings Change (%)',
    align: 'right',
    isCurrency: false
  }
] as const;

const LSAMagnitudeChangesCharts: React.FC<LSAMagnitudeChangesChartsProps> = ({ data, scenarioSavings }) => {
  const classes = useStyles();
  const [peakSelected, setPeaksSelected] = useState('top_5');
  const [selectedData, setSelectedData] = useState<TableData[] | undefined>(undefined);
  const [chartData, setChartData] = useState<PlotData[]>([]);

  const isValidTop = (value: any): value is SelectedPeak => {
    return value == 'top_5' || value == 'top_10' || value == 'top_15';
  };

  const getCaseLabel = (key: string): string => {
    switch (key) {
      case 'minus_20_percent':
        return 'magnitude -20%';
      case 'minus_10_percent':
        return 'magnitude -10%';
      case 'minus_5_percent':
        return 'magnitude -5%';
      case 'minus_1_percent':
        return 'magnitude -1%';
      case 'plus_20_percent':
        return 'magnitude +20%';
      case 'plus_10_percent':
        return 'magnitude +10%';
      case 'plus_5_percent':
        return 'magnitude +5%';
      case 'plus_1_percent':
        return 'magnitude +1%';
      default:
        return key;
    }
  };

  const getColor = (key: string): string => {
    if (key.includes('minus')) {
      return 'rgba(218,121,119,0.5)';
    } else if (key.includes('plus')) {
      return 'rgba(91,142,93,0.5)';
    } else return '#fff';
  };
  useEffect(() => {
    const buildTableData = (peak: SelectedPeak): TableData[] => {
      const updatedData = [
        {
          scenario: 'base scenario',
          energy_savings: scenarioSavings.energy,
          energy_savings_change: 0,
          energy_savings_percent: 0,
          demand_savings: scenarioSavings.demand,
          demand_savings_change: 0,
          demand_savings_percent: 0,
          total_savings: scenarioSavings.total,
          total_savings_change: 0,
          total_savings_percent: 0,
          color: 'rgba(245,247,212)'
        }
      ];
      Object.keys(data[peak] as LsaCases).forEach(key => {
        const dataItem = {
          scenario: getCaseLabel(key),
          energy_savings: scenarioSavings.energy + data[peak][key].energy_savings,
          energy_savings_change: data[peak][key].energy_savings,
          energy_savings_percent: +((data[peak][key].energy_savings / scenarioSavings.energy) * 100).toFixed(2),
          demand_savings: scenarioSavings.demand + data[peak][key].demand_savings,
          demand_savings_change: data[peak][key].demand_savings,
          demand_savings_percent: +((data[peak][key].demand_savings / scenarioSavings.demand) * 100).toFixed(2),
          total_savings: scenarioSavings.total + data[peak][key].total_savings,
          total_savings_change: data[peak][key].total_savings,
          total_savings_percent: +data[peak][key].total_savings_percent.toFixed(2),
          color: getColor(key)
        };
        updatedData.push(dataItem);
      });
      return updatedData;
    };
    setSelectedData(buildTableData(peakSelected as SelectedPeak));
  }, [peakSelected, scenarioSavings]);

  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      const buildChartData = (): PlotData[] => {
        const chartData: PlotData[] = [];

        chartData.push({
          type: 'scatter',
          name: 'energy savings change (%)',
          x: selectedData.map(el => el.scenario),
          y: selectedData.map(el => el.energy_savings_percent)
        } as PlotData);

        chartData.push({
          type: 'scatter',
          name: 'demand savings change (%)',
          x: selectedData.map(el => el.scenario),
          y: selectedData.map(el => el.demand_savings_percent)
        } as PlotData);

        chartData.push({
          type: 'scatter',
          name: 'total savings change (%)',
          x: selectedData.map(el => el.scenario),
          y: selectedData.map(el => el.total_savings_percent)
        } as PlotData);

        return chartData;
      };
      setChartData(buildChartData());
    }
  }, [selectedData]);

  const handleChangePeaks = (event: React.ChangeEvent<{ value: unknown }>): void => {
    if (isValidTop(event.target.value)) {
      setPeaksSelected(event.target.value);
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h6">
          Peak Magnitude Changes{' '}
          <span>
            <Tooltip title={MAGNITUDE_TOOLTIP}>
              <Help className={classes.questionMark} />
            </Tooltip>
          </span>
        </Typography>
      </div>
      <div>
        <FormControl className={classes.peakSelection}>
          <InputLabel id="select-peaks">Selected Peaks: </InputLabel>
          <Select labelId="select-peaks" data-testid="select-peaks" value={peakSelected} onChange={handleChangePeaks}>
            <MenuItem value="top_5" data-testid="select-installation-type-item">
              Top 5 Peaks
            </MenuItem>
            <MenuItem value="top_10" data-testid="select-installation-type-item">
              Top 10 Peaks
            </MenuItem>
            <MenuItem value="top_15" data-testid="select-installation-type-item">
              Top 15 Peaks
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <Table size="small">
          <TableHead>
            {columnSchema.map(column => (
              <TableCell key={column.key} align={column.align}>
                {column.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {selectedData &&
              selectedData?.map(row => (
                <TableRow key={row.scenario} style={{ background: row.color }}>
                  {columnSchema.map(column => (
                    <TableCell key={column.key} align={column.align}>
                      {column.isCurrency ? <CurrencyDisplay value={+row[column.key]} /> : row[column.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div style={{ height: 440 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={chartData}
          layout={layout}
          config={{ displayModeBar: false, responsive: true }}
        />
      </div>
    </div>
  );
};

export default LSAMagnitudeChangesCharts;
