import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CustomerForm from '../Detail/CustomerForm';
import IconButton from '@material-ui/core/IconButton';
import AccessControl from '../../../utility/AccessControl';

const style = theme => ({
  flex: {
    flex: 1
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  }
});

class CustomerDetailContainer extends React.Component {
  state = {
    hasChanged: false,
    isComplete: false
  };

  componentDidMount = () => {
    const { customer } = this.props;
    const isComplete =
      customer &&
      customer.name &&
      customer.name.length > 0 &&
      customer.address &&
      customer.address.zip_code &&
      customer.address.zip_code.toString().length === 5
        ? true
        : false;
    this.setState({ isComplete });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.customer !== this.props.customer || prevProps.customer.address !== this.props.customer.address) {
      const { customer } = this.props;
      const isComplete =
        customer &&
        customer.name &&
        customer.name.length > 0 &&
        customer.address &&
        customer.address.zip_code &&
        customer.address.zip_code.toString().length === 5
          ? true
          : false;
      this.setState({ isComplete });
    }
  };

  // isComplete = customer => {
  //   return (
  //     customer &&
  //     customer.name &&
  //     customer.name.length > 0 &&
  //     customer.address &&
  //     customer.address.zip_code &&
  //     customer.address.zip_code.toString().length === 5
  //   );
  // };

  handleCustomerChange = event => {
    const customer = { ...this.props.customer };
    const address = { ...this.props.customer.address };
    const name = event.target.name;
    let customerUpdate;

    // check if the property to update is nested in address
    if (name === 'city' || name === 'state' || name === 'zip_code' || name === 'country') {
      customerUpdate = {
        ...customer,
        address: {
          ...address,
          [name]: event.target.value
        }
      };
    } else {
      customerUpdate = {
        ...customer,
        [event.target.name]: event.target.value
      };
    }
    this.props.handleCustomerChange(customerUpdate);
    this.setState({ hasChanged: true });
  };

  handleCustomerSave = () => {
    this.props.handleCustomerSave(this.props.customer);
    this.setState({ hasChanged: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography color="inherit" variant="h6" className={classes.flex}>
              Customer Details
            </Typography>
            <AccessControl requiredPermissions={['editor', 'admin']}>
              <IconButton
                color="primary"
                disabled={!(this.state.isComplete && this.state.hasChanged)}
                onClick={this.handleCustomerSave}
              >
                <Save />
              </IconButton>
            </AccessControl>
          </Toolbar>
          <CustomerForm handleCustomerChange={this.handleCustomerChange} customer={this.props.customer} isEdit={true} />
        </Paper>
      </div>
    );
  }
}

CustomerDetailContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  handleCustomerChange: PropTypes.func
};

export default withStyles(style)(CustomerDetailContainer);
