import React from 'react';
import FileInput from './FileInput';

interface FilePickerProps {
  children: React.ReactNode;
  onChange: (file: File) => any;
  onError: (errorMsg: string) => any;
  // file extension
  //extensions: string[];
  // validate file contents
}

const FilePicker: React.FC<FilePickerProps> = props => {
  const { onError, onChange, children } = props;

  const validate = (file: File) => {
    // make sure a file was provided in the first place
    if (!file) {
      onError('Failed to upload a file.');
      return;
    }

    // if we care about file extensions
    // if (extensions && file && file.name) {
    //   const uploadedFileExt = file.name
    //     .split('.')
    //     .pop()
    //     .toLowerCase();
    //   const isValidFileExt = extensions.map(ext => ext.toLowerCase()).includes(uploadedFileExt);

    //   if (!isValidFileExt) {
    //     onError(`Must upload a file of type: ${extensions.join(' or ')}`);
    //     return;
    //   }
    // }

    // return native file object
    onChange(file);
  };

  return <FileInput onChange={validate}>{children}</FileInput>;
};

export default FilePicker;
