import { StoreState } from '../reducers';

// import auth0Client from '../utility/Auth';

// export const createErrorMessageSelector = actions => state => {
//   const errors = actions.map(action => state.error[action]);
//   if (errors && errors[0]) {
//     return errors[0];
//   }
//   return '';
// };

export const createLoadingSelector = (actions: string[]) => (state: StoreState) =>
  actions.some(action => state.loading[action]);

export const createSuccessSelector = (actions: string[]) => (state: StoreState) =>
  actions.some(action => state.successStatus[action]);

export const createErrorSelector = (actions: string[]) => (state: StoreState) =>
  actions.some(action => state.errorStatus[action]);

// FILTER PROPOSALS LIST

// const getProposals = state => state.proposals;
// const getProposalsListFilters = state => state.proposalsListFilters;

// export const filterProposalsListSelector = createSelector(
//   [getProposalsListFilters, getProposals],
//   (filters, proposals) => {
//     let filteredProposals = [...proposals];
//     if (filters.viewMyOwn) {
//       filteredProposals = filteredProposals.filter(
//         proposal => proposal.created_user === auth0Client.profile.nickname
//       );
//     }
//     if (filters.tariff.length > 0) {
//     }
//     if (filters.createdBy.length > 0) {
//       filteredProposals = filteredProposals.filter(
//         proposal => proposal.created_user === filters.createdBy
//       );
//     }
//     return filteredProposals;
//   }
// );
