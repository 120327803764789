import { STORE_MEF_PROFILE, UPDATE_MEF_PROFILE, RESET_PROFILE_DATA } from '../../actions/profiledata';
import { generateProfileGrid } from '../../utility/Explore';
import { GET_PROPOSAL_DATA_SUCCESS } from '../../actions/proposals';
const INITIAL_STATE = generateProfileGrid(1);

export default function mefProfileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STORE_MEF_PROFILE:
      return action.payload;
    case GET_PROPOSAL_DATA_SUCCESS:
      if (action.payload && action.payload.type === 'MEF') {
        return action.payload.data;
      }
      return state;
    case UPDATE_MEF_PROFILE:
      const newData = state.slice();
      for (let [row, column, , newValue] of action.payload) {
        newData[row][column] = newValue;
      }
      return newData;
    case RESET_PROFILE_DATA:
      return generateProfileGrid(1);
    default:
      return state;
  }
}
