import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { add } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

// MATERIAL UI IMPORTS
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Error from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

// REACT IMPORTS
import { createLoadingSelector, createErrorSelector } from '../../../selectors';
import ScenarioTechBadges from '../../Scenarios/ScenarioTechBadges';
import { selectProposalSQPresentation, selectProposalScenarioPresentation } from '../../../actions/scenarios';
import { ProposalInternal } from '../../../types';
import { StoreState } from '../../../reducers';
import smartphoneIcon from '../../../assets/img/smartphoneIcon.png';
import carIcon from '../../../assets/img/carIcon.png';
import treeIcon from '../../../assets/img/treeIcon.png';

interface ProposalGHGPresentationProps {
  proposal: ProposalInternal;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minWidth: 983,
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      minHeight: 600
    },
    header: {
      background: '#001a70',
      padding: '20px',
      color: 'white'
    },
    scenarioSelect: {
      fontSize: '1em',
      minWidth: 300,
      padding: '0',
      marginLeft: 20,
      marginBottom: 0,
      marginRight: 12,
      background: 'white',
      '& .MuiSelect-select.MuiSelect-select': {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 10
      }
    },
    loadingContainer: {
      // padding: '180px',
      // textAlign: 'center'
    },
    errorContainer: {
      padding: '180px',
      textAlign: 'center',
      color: '#333',
      '& svg': {
        color: 'red',
        position: 'relative',
        top: '6px',
        marginRight: '6px'
      }
    },
    scenarioActive: {
      color: 'white',
      background: '#ff000087',
      fontWeight: 600,
      fontSize: '8px',
      letterSpacing: '1px',
      height: '16px',
      marginRight: 5
    },
    slideContainer: {
      padding: 7,
      maxWidth: 820,
      fontFamily: 'Roboto',
      letterSpacing: -0.2,
      margin: '30px auto',
      border: '1px solid #eaeaea'
    },
    slidePadding: {
      padding: '16px 26px'
    },
    slideTop: {
      textAlign: 'left',
      backgroundColor: '#F2F2F2'
    },
    slideBottom: {
      display: 'flex'
    },
    metricBoxContainer: {
      flex: 1,
      textAlign: 'left',
      padding: '12px 30px',
      '& img': {
        width: 130
      }
    },
    metricBoxText: {
      height: 80,
      lineHeight: '20px'
    },
    fontSizeXl: { fontSize: 72 },
    fontSizeLg: { fontSize: 36 },
    fontSizeMd: { fontSize: 24 },
    fontSizeSm: { fontSize: 22 },
    fontSizeXs: { fontSize: 18 },
    green: { color: '#037D5E' },
    grey: { color: '#5C5C5C' },
    lightItalic: {
      fontStyle: 'italic',
      fontWeight: 300
    },
    bold: {
      fontWeight: 700
    }
  })
);

const ProposalGHGPresentation: React.FC<ProposalGHGPresentationProps> = ({ proposal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const presentationData = useSelector((state: StoreState) => state.scenarioPresentation);

  const [selectedScenarioId, setSelectedScenarioId] = useState('');
  const [selectedSQId, setSelectedSQId] = useState('');

  const [yearlyGHGSavings, setYearlyGHGSavings] = useState(0);
  const [kWhSaved, setKWhSaved] = useState(0);
  const [carsOffRoad, setCarsOffRoad] = useState(0);
  const [treesPlanted, setTreesPlanted] = useState(0);
  const [phonesCharged, setPhonesCharged] = useState(0);

  const scenarios = proposal.proposal_scenarios;

  const loadingSelector = createLoadingSelector(['GET_SCENARIO_PRESENTATION_OPERATIONS_DATA']);
  const loading = useSelector(loadingSelector);

  const errorSelector = createErrorSelector(['GET_SCENARIO_PRESENTATION_OPERATIONS_DATA']);
  const error = useSelector(errorSelector);

  useEffect(() => {
    const calculateYearlyGHGSavings = () => {
      let GHGSavingsTotal = 0;
      if (
        presentationData?.statusQuo?.results &&
        Object.keys(presentationData?.statusQuo?.results).length > 0 &&
        presentationData?.scenario?.results &&
        Object.keys(presentationData?.scenario?.results).length > 0
      ) {
        const statusQuoYearlyEmissions = presentationData.statusQuo?.results['Total Kgs CO2'].reduce(add, 0);
        const scenarioYearlyEmissions = presentationData.scenario?.results['Total Kgs CO2'].reduce(add, 0);
        GHGSavingsTotal = statusQuoYearlyEmissions - scenarioYearlyEmissions;
      }

      return GHGSavingsTotal / 1000;
    };
    const calculateYearlyKWhSavings = () => {
      let kWhSavingsTotal = 0;
      if (
        presentationData?.statusQuo?.results &&
        Object.keys(presentationData?.statusQuo?.results).length > 0 &&
        presentationData?.scenario?.results &&
        Object.keys(presentationData?.scenario?.results).length > 0
      ) {
        const statusQuoYearlyEmissions = presentationData.statusQuo?.results['Energy Use Total'].reduce(add, 0);
        const scenarioYearlyEmissions = presentationData.scenario?.results['Energy Use Total'].reduce(add, 0);
        kWhSavingsTotal = statusQuoYearlyEmissions - scenarioYearlyEmissions;
      }

      return kWhSavingsTotal;
    };
    if (selectedSQId && selectedScenarioId) {
      const kWhSavings = calculateYearlyKWhSavings();
      setKWhSaved(kWhSavings);
      const GHGSavings = calculateYearlyGHGSavings();
      setYearlyGHGSavings(GHGSavings);
      setCarsOffRoad(GHGSavings / 4.63);
      setTreesPlanted(Math.ceil(GHGSavings / 0.06));
      setPhonesCharged(Math.ceil(GHGSavings / 0.00000784));
    }
  }, [selectedSQId, selectedScenarioId]);

  const handleScenarioChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (typeof event.target.value === 'string') {
      setSelectedScenarioId(event.target.value);
      dispatch(
        selectProposalScenarioPresentation(scenarios.find(scenario => scenario.public_id === event.target.value))
      );
    }
  };

  const handleSQChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (typeof event.target.value === 'string') {
      setSelectedSQId(event.target.value);
      dispatch(selectProposalSQPresentation(scenarios.find(scenario => scenario.public_id === event.target.value)));
    }
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        Select a Status Quo
        <Select
          className={classes.scenarioSelect}
          data-testid="select-scenario"
          value={selectedSQId}
          onChange={handleSQChange}
          inputProps={{
            name: 'selectedSQId',
            id: 'selectedSQId'
          }}
        >
          {proposal?.proposal_scenarios &&
            proposal.proposal_scenarios.map(scenario => {
              return (
                <MenuItem value={scenario.public_id} key={scenario.public_id} data-testid="select-scenario-item">
                  {!scenario.active && <Chip className={classes.scenarioActive} label="INACTIVE" />}
                  {scenario.name}
                  {'  '}
                  <ScenarioTechBadges scenario={scenario} />
                </MenuItem>
              );
            })}
        </Select>
        Select a ScenarioInternal to Generate Charts
        <Select
          className={classes.scenarioSelect}
          data-testid="select-scenario"
          value={selectedScenarioId}
          onChange={handleScenarioChange}
          inputProps={{
            name: 'selectedScenarioId',
            id: 'selectedScenarioId'
          }}
        >
          {proposal?.proposal_scenarios &&
            proposal.proposal_scenarios.map(scenario => {
              return (
                <MenuItem value={scenario.public_id} key={scenario.public_id} data-testid="select-scenario-item">
                  {!scenario.active && <Chip className={classes.scenarioActive} label="INACTIVE" />}
                  {scenario.name}
                  {'  '}
                  <ScenarioTechBadges scenario={scenario} />
                </MenuItem>
              );
            })}
        </Select>
      </Box>
      {!loading && !error && selectedScenarioId && selectedSQId && (
        <Box className={classes.slideContainer}>
          <div className={clsx(classes.slidePadding, classes.slideTop)} style={{ paddingTop: 10 }}>
            <div>
              <span className={clsx(classes.bold, classes.green, classes.fontSizeXl)}>
                {yearlyGHGSavings.toLocaleString('en-US', { maximumFractionDigits: 2 })}
              </span>
              <span
                className={clsx(classes.lightItalic, classes.grey, classes.fontSizeSm)}
                style={{ paddingLeft: 10, paddingRight: 4 }}
              >
                metric tons of{' '}
              </span>
              <span className={clsx(classes.bold, classes.grey, classes.fontSizeMd)}>Carbon Dioxide</span>
            </div>
            <div>
              <span className={clsx(classes.bold, classes.green, classes.fontSizeLg)}>
                ≈ {kWhSaved.toLocaleString('en-US', { maximumFractionDigits: 0 })}
              </span>
              <span
                className={clsx(classes.lightItalic, classes.grey, classes.fontSizeSm)}
                style={{ paddingLeft: 10, paddingRight: 4 }}
              >
                kWh of{' '}
              </span>
              <span className={clsx(classes.bold, classes.grey, classes.fontSizeMd)}>Electricity</span>
            </div>
          </div>
          <div className={clsx(classes.slidePadding, classes.slideBottom)}>
            <div className={clsx(classes.metricBoxContainer)}>
              <div>
                <span className={clsx(classes.bold, classes.fontSizeLg, classes.green)} style={{ marginLeft: -27 }}>
                  ≈ {carsOffRoad.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                </span>
              </div>
              <div className={clsx(classes.metricBoxText)}>
                <span
                  className={clsx(classes.lightItalic, classes.grey, classes.fontSizeXs)}
                  style={{ paddingRight: 4 }}
                >
                  co2 emissions from{' '}
                </span>
                <span className={clsx(classes.bold, classes.grey, classes.fontSizeSm)}>
                  cars off the
                  <br />
                  road
                </span>
              </div>
              <div>
                <img src={carIcon} alt="logo" />
              </div>
            </div>
            <div className={clsx(classes.metricBoxContainer)}>
              <div>
                <span className={clsx(classes.bold, classes.fontSizeLg, classes.green)} style={{ marginLeft: -27 }}>
                  ≈ {treesPlanted.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                </span>
              </div>
              <div className={clsx(classes.metricBoxText)}>
                <span
                  className={clsx(classes.lightItalic, classes.grey, classes.fontSizeXs)}
                  style={{ paddingRight: 4 }}
                >
                  carbon sequestered
                  <br />
                  by{' '}
                </span>
                <span className={clsx(classes.bold, classes.grey, classes.fontSizeSm)}>trees planted</span>
              </div>
              <div>
                <img src={treeIcon} alt="logo" />
              </div>
            </div>
            <div className={clsx(classes.metricBoxContainer)}>
              <div>
                <span className={clsx(classes.bold, classes.fontSizeLg, classes.green)} style={{ marginLeft: -27 }}>
                  ≈ {phonesCharged.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                </span>
              </div>
              <div className={clsx(classes.metricBoxText)}>
                <span
                  className={clsx(classes.lightItalic, classes.grey, classes.fontSizeXs)}
                  style={{ paddingRight: 4 }}
                >
                  ghg emissions from{' '}
                </span>
                <span className={clsx(classes.bold, classes.grey, classes.fontSizeSm)}>smartphones charged</span>
              </div>
              <div>
                <img src={smartphoneIcon} alt="logo" />
              </div>
            </div>
          </div>
        </Box>
      )}
      {loading && (
        <Box className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={180} />
        </Box>
      )}

      {error && (
        <Box className={classes.errorContainer}>
          <Error />
          No results for selected Scenario
        </Box>
      )}
    </Paper>
  );
};

export default ProposalGHGPresentation;
