import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import CustomerForm from '../Detail/CustomerForm';
import { updateCustomer, createCustomer, handleInputFormChangeCustomer } from '../../../actions';
import AccessControl from '../../../utility/AccessControl';

const style = theme => ({
  flex: {
    flex: 1
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  }
});

class CustomerContainer extends React.Component {
  isComplete = customer => {
    return (
      customer &&
      customer.name &&
      customer.name.length > 0 &&
      customer.address &&
      customer.address.zip_code &&
      customer.address.zip_code.toString().length === 5
    );
  };

  componentDidUpdate = prevProps => {
    if (prevProps.proposal && prevProps.proposal.isCustomerCreated !== this.props.proposal.isCustomerCreated) {
      if (this.props.proposal.isCustomerCreated && this.props.handleNext) {
        if (this.props.handleSave) {
          this.props.handleSave();
        }
        this.props.handleNext();
      }
    }
  };

  handleCustomerChange = event => {
    const customer = { ...this.props.customer };
    const address = { ...this.props.customer.address };
    const name = event.target.name;
    let customerUpdate;

    // check if the property to update is nested in address
    if (name === 'city' || name === 'state' || name === 'zip_code' || name === 'country') {
      customerUpdate = {
        ...customer,
        address: {
          ...address,
          [name]: event.target.value
        }
      };
    } else {
      customerUpdate = {
        ...customer,
        [event.target.name]: event.target.value
      };
    }
    this.props.handleInputFormChangeCustomer({ customer: customerUpdate });
  };

  handleCustomerSave = () => {
    if (this.props.customer.public_id) {
      this.props.updateCustomer(this.props.customer);
    } else {
      this.props.createCustomer(this.props.customer);
    }
    if (this.props.handleSelectCustomer) {
      this.props.handleSelectCustomer(this.props.customer);
    }
    if (this.props.handleNext && this.props.customer.public_id) {
      this.props.handleNext();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <AccessControl requiredPermissions={['editor', 'admin']}>
          <div className="button-container">
            <Fab
              disabled={!this.isComplete(this.props.customer)}
              size="small"
              color="secondary"
              aria-label="Save"
              className="button-white"
              onClick={this.handleCustomerSave}
            >
              <Save />
            </Fab>
          </div>
        </AccessControl>
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography color="inherit" variant="h6" className={classes.flex}>
              Create Customer
            </Typography>
          </Toolbar>
          <CustomerForm handleCustomerChange={this.handleCustomerChange} customer={this.props.customer} />
        </Paper>
      </div>
    );
  }
}

CustomerContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  proposal: PropTypes.object,
  handleNext: PropTypes.func,
  handleSave: PropTypes.func,
  handleSelectCustomer: PropTypes.func
};

const mapStateToProps = state => {
  return {
    customer: state.newProposal.customer
  };
};

export default connect(mapStateToProps, {
  handleInputFormChangeCustomer,
  createCustomer,
  updateCustomer
})(withStyles(style)(CustomerContainer));
