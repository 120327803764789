import React, { useState, useEffect } from 'react';
import { BarChart, Bar } from 'recharts';

// MATERIAL UI IMPORTS
import { Theme, makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import CurrencyDisplay from '../../../utility/CurrencyDisplay';

import {
  calculateDemandSavings,
  calculateEnergySavings,
  calculateTotalDemandSavings,
  calculateTotalEnergySavings
} from '../../../utility/Savings';
import { ScenarioResults } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 240
    },
    green: {
      color: (theme.palette as any).green
    },
    title: {
      fontWeight: 400,
      textTransform: 'uppercase',
      fontSize: '12px',
      letterSpacing: '1.1px',
      color: '#777'
    },
    currency: {
      fontFamily: "'Lato', sans-serif",
      fontWeight: 900,
      color: '#017650'
    }
  })
);

interface ScenarioRibbonProps {
  statusQuoResult: ScenarioResults;
  scenarioResult: ScenarioResults;
  discountRate: { demand: number; energy: number };
}

const ScenarioRibbon: React.FC<ScenarioRibbonProps> = ({ statusQuoResult, scenarioResult, discountRate }) => {
  const classes = useStyles();
  // assertIsDefined(statusQuoResult);
  const [months, setMonths] = useState(statusQuoResult?.Months ?? []);
  const [processedData, setProcessedData] = useState(
    [] as {
      month: string;
      energySavings: number;
      demandSavings: number;
      totalSavings: number;
    }[]
  );
  const [totalSavings, setTotalSavings] = useState(0);
  const [totalEnergySavings, setTotalEnergySavings] = useState(0);
  const [totalDemandSavings, setTotalDemandSavings] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const processData = () => {
      // check for proposal scenarios
      if (!scenarioResult || !statusQuoResult) {
        return;
      }
      const processedDataTemp: {
        month: string;
        energySavings: number;
        demandSavings: number;
        totalSavings: number;
      }[] = [];

      setMonths(statusQuoResult['Months']);
      months.forEach((month, index) => {
        let energySavings = +calculateEnergySavings(
          index,
          statusQuoResult,
          scenarioResult,
          discountRate.energy
        ).toFixed(0);
        let demandSavings = +calculateDemandSavings(
          index,
          statusQuoResult,
          scenarioResult,
          discountRate.demand
        ).toFixed(0);

        let totalSavings = energySavings + demandSavings;
        processedDataTemp.push({
          month: month,
          energySavings: energySavings,
          demandSavings: demandSavings,
          totalSavings: totalSavings
        });
      });
      setProcessedData(
        processedDataTemp as {
          month: string;
          energySavings: number;
          demandSavings: number;
          totalSavings: number;
        }[]
      );

      let totalDemandSavings = +calculateTotalDemandSavings(statusQuoResult, scenarioResult, discountRate.demand);
      let totalEnergySavings = +calculateTotalEnergySavings(statusQuoResult, scenarioResult, discountRate.energy);
      setTotalEnergySavings(totalEnergySavings);
      setTotalDemandSavings(totalDemandSavings);
      setTotalSavings(totalDemandSavings + totalEnergySavings);
    };
    processData();
  }, [discountRate, months, scenarioResult, statusQuoResult]);
  return (
    <div style={{ marginTop: 16 }}>
      <Grid container alignItems="center" justify="space-between" spacing={2}>
        <Grid item sm={12} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary">
                Demand Savings
              </Typography>
              <Typography variant="h5" component="h2" className={classes.currency}>
                {/* <span className={classes.green}>$</span>*/}
                <CurrencyDisplay value={totalDemandSavings} />
              </Typography>
              <div>
                <BarChart width={260} height={50} data={processedData}>
                  <Bar dataKey="demandSavings" fill={(theme.palette as any).lightBlue} />
                </BarChart>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary">
                Energy Savings
              </Typography>
              <Typography variant="h5" component="h2" className={classes.currency}>
                {/* <span className={classes.green}>$</span>  */}
                <CurrencyDisplay value={totalEnergySavings} />
              </Typography>
              <div>
                <BarChart width={260} height={50} data={processedData}>
                  <Bar dataKey="energySavings" fill={(theme.palette as any).lightBlue} />
                </BarChart>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary">
                Total Savings
              </Typography>
              <Typography variant="h5" component="h2" className={classes.currency}>
                {/* <span className={classes.green}>$</span> {totalSavings} */}
                <CurrencyDisplay value={totalSavings} />
              </Typography>
              <div>
                <BarChart width={260} height={50} data={processedData}>
                  <Bar dataKey="totalSavings" fill={(theme.palette as any).lightBlue} />
                </BarChart>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ScenarioRibbon;
