import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';

interface ValidationHelperTextProps {
  valueToValidate: any;
  predicate: (x: any) => boolean;
  message: string;
}

const ValidationHelperText: React.FC<ValidationHelperTextProps> = ({ valueToValidate, predicate, message }) => {
  return predicate(valueToValidate) ? null : <FormHelperText error>{message}</FormHelperText>;
};

export default ValidationHelperText;
