import React, { useEffect } from 'react';
import moment from 'moment';
import { AssumptionsInternal } from '../../types';

// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

interface EditEventsDialogProps {
  open: boolean;
  onClose: () => void;
  eventDates: string[];
  customEvents: string[];
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
  eventType: 'PDP' | 'ISONE_MA';
  assumptionKey: keyof Pick<AssumptionsInternal, 'pdpCustomEvents' | 'neisoCustomEvents'>;
}

const EditEventsDialog: React.FC<EditEventsDialogProps> = ({
  open,
  onClose,
  customEvents,
  handleAssumptionUpdateByKey,
  eventDates,
  eventType,
  assumptionKey
}) => {
  const [newDate, setNewdate] = React.useState('');
  const [dates, setDates] = React.useState<string[]>([]);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    if (customEvents) {
      setDates(customEvents);
    }
  }, [customEvents]);

  const regex = /(\d\d)\/(\d\d)\/(\d\d\d\d)/;

  const sortDates = (dates: string[]): string[] => {
    return dates.sort((a, b) => moment(a).unix() - moment(b).unix());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewdate(e.target.value);
    if (regex.test(e.target.value)) {
      setError(false);
    }
  };

  const importDefaultEvents = () => {
    setDates(eventDates);
  };

  const handleClose = () => {
    setNewdate('');
    onClose();
  };

  const handleDateAdd = () => {
    if (!regex.test(newDate)) {
      setError(true);
      return;
    }
    if (dates && newDate.length > 0) {
      setDates(sortDates([...dates, newDate]));
    }
    setNewdate('');
  };

  const handleDateDelete = (index: number) => {
    setDates(dates.slice(0, index).concat(dates.slice(index + 1)));
  };

  const handleSaveEvents = () => {
    handleAssumptionUpdateByKey(assumptionKey)(dates);
    onClose();
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="edit-peak-day-events">
        <DialogTitle id="form-dialog-title">Edit Event Days</DialogTitle>
        <DialogContent>
          <DialogContentText>Import default Utility {eventType} Dates, add or delete custom ones.</DialogContentText>
          <Grid container justify="center" alignContent="center" alignItems="center">
            <Grid item xs={10}>
              <TextField
                id="newDate"
                name="newDate"
                autoFocus={true}
                label="Add Event Date"
                helperText="(mm/dd/yyyy)"
                value={newDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
                error={error}
              />
            </Grid>
            <Grid item xs={2}>
              <Button style={{ marginLeft: 8 }} color="primary" variant="contained" onClick={handleDateAdd}>
                Add
              </Button>
            </Grid>
          </Grid>
          <List dense={true}>
            {dates &&
              dates.map((date, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText primary={date} />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="Delete" onClick={() => handleDateDelete(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
          <Button variant="contained" style={{ marginTop: 16 }} onClick={importDefaultEvents}>
            Import default {eventType} Events
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEvents} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditEventsDialog;
