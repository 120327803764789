import React, { useEffect, useState } from 'react';
import { Lsa, ScenarioResults } from '../../../../types';
import { calculateTotalDemandSavings, calculateTotalEnergySavings } from '../../../../utility/Savings';
import LSADurationChangesCharts from './LSADurationChangesCharts';
import LSAFrequencyChangesCharts from './LSAFrequencyChangesCharts';
import LSAMagnitudeChangesCharts from './LSAMagnitudeChangesCharts';

interface DisplayLSAProps {
  loadSensitivity: Lsa;
  statusQuoResult: ScenarioResults;
  scenarioResult: ScenarioResults;
  discountRate: { energy: number; demand: number };
}

const DisplayLSAResults: React.FC<DisplayLSAProps> = ({
  loadSensitivity,
  statusQuoResult,
  scenarioResult,
  discountRate
}) => {
  const [scenarioSavings, setScenarioSavings] = useState({ energy: 0, demand: 0, total: 0 });

  useEffect(() => {
    let totalDemandSavings = +calculateTotalDemandSavings(statusQuoResult, scenarioResult, discountRate.demand);
    let totalEnergySavings = +calculateTotalEnergySavings(statusQuoResult, scenarioResult, discountRate.energy);
    setScenarioSavings({
      energy: totalEnergySavings,
      demand: totalDemandSavings,
      total: totalDemandSavings + totalEnergySavings
    });
  }, [statusQuoResult, scenarioResult, discountRate]);

  return (
    <div style={{ padding: 32 }}>
      {loadSensitivity?.data?.peak_load_magnitude_change && (
        <LSAMagnitudeChangesCharts
          data={loadSensitivity.data?.peak_load_magnitude_change}
          scenarioSavings={scenarioSavings}
        />
      )}
      {loadSensitivity?.data?.peak_load_frequency_change && (
        <LSAFrequencyChangesCharts
          data={loadSensitivity.data?.peak_load_frequency_change}
          scenarioSavings={scenarioSavings}
        />
      )}
      {loadSensitivity?.data?.peak_load_duration_change && (
        <LSADurationChangesCharts
          data={loadSensitivity.data?.peak_load_duration_change}
          scenarioSavings={scenarioSavings}
        />
      )}
    </div>
  );
};

export default DisplayLSAResults;
