import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import LocaleSelector from '../../components/Locale/LocaleSelector';

import { pushBreadcrumb, popBreadcrumb } from '../../actions/breadcrumbs';
import auth0Client from '../../utility/Auth';

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  flex: {
    flex: 1
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  item: {
    paddingRight: 15,
    marginBottom: theme.spacing(2)
  }
});

const AccountSettings = props => {
  const { classes, breadcrumbsTrail, popBreadcrumb, pushBreadcrumb, location } = props;

  useEffect(() => {
    // check if previous breadcrumbs should be popped or not
    if (
      breadcrumbsTrail[breadcrumbsTrail.length - 1]?.link &&
      breadcrumbsTrail[breadcrumbsTrail.length - 1].link !== '/settings'
    ) {
      popBreadcrumb();
      pushBreadcrumb('Settings', '/settings');
    }
    pushBreadcrumb('Account Settings', location.pathname);
  }, [location.pathname, popBreadcrumb, pushBreadcrumb]);

  const getUserRole = () => {
    const roles = auth0Client.getRoles();

    if (roles.includes('admin')) {
      return 'Admin';
    } else if (roles.includes('editor')) {
      return 'Editor';
    } else if (roles.includes('viewer')) {
      return 'Viewer';
    } else {
      return 'Unknow - please contact your system administrator.';
    }
  };

  const getUserName = () => {
    try {
      const profile = auth0Client.getProfile();
      return profile ? profile.name : 'Unknown';
    } catch {
      throw new Error('Problem getting user profile.');
    }
  };

  return (
    <div className={classes.root}>
      <Paper>
        <Toolbar className={classes.toolbar}>
          <Typography color="inherit" variant="h6" className={classes.flex}>
            Account Settings
          </Typography>
        </Toolbar>
        <FormControl className={classes.formControl} fullWidth>
          <Grid container>
            <Grid item xs={12} className={classes.item}>
              <TextField
                label="Username"
                fullWidth
                value={getUserName()}
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <TextField
                label="User role"
                fullWidth
                value={getUserRole()}
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <LocaleSelector />
            </Grid>
          </Grid>
        </FormControl>
      </Paper>
    </div>
  );
};

AccountSettings.propTypes = {
  breadcrumbsTrail: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  popBreadcrumb: PropTypes.func.isRequired,
  pushBreadcrumb: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  breadcrumbsTrail: state.breadcrumbsTrail
});

export default withStyles(styles)(connect(mapStateToProps, { pushBreadcrumb, popBreadcrumb })(AccountSettings));
