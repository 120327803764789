import { Dispatch } from 'redux';
import axios from 'axios';

export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_ERROR = 'GET_FAVORITES_ERROR';

export const ADD_FAVORITES_REQUEST = 'ADD_FAVORITES_REQUEST';
export const ADD_FAVORITES_SUCCESS = 'ADD_FAVORITES_SUCCESS';
export const ADD_FAVORITES_ERROR = 'ADD_FAVORITES_ERROR';

export const DELETE_FAVORITES_REQUEST = 'DELETE_FAVORITES_REQUEST';
export const DELETE_FAVORITES_SUCCESS = 'DELETE_FAVORITES_SUCCESS';
export const DELETE_FAVORITES_ERROR = 'DELETE_FAVORITES_ERROR';

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export function getFavorites() {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_FAVORITES_REQUEST });
    return axios
      .get(`${ROOT_URL}/user/favorites`)
      .then(res => {
        dispatch({
          type: GET_FAVORITES_SUCCESS,
          payload: res.data
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_FAVORITES_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function addFavorite(type: string, id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ADD_FAVORITES_REQUEST });
    return axios
      .put(`${ROOT_URL}/user/favorites?${type}_id=${id}`)
      .then(res => {
        dispatch({
          type: ADD_FAVORITES_SUCCESS,
          payload: {
            type: type,
            newFavorite: res.data
          }
        });
      })
      .catch(function (error) {
        dispatch({
          type: ADD_FAVORITES_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function removeFavorite(type: string, id: string) {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DELETE_FAVORITES_REQUEST });
    return axios
      .delete(`${ROOT_URL}/user/favorites?${type}_id=${id}`)
      .then(res => {
        dispatch({
          type: DELETE_FAVORITES_SUCCESS,
          payload: {
            type: type,
            id: id
          }
        });
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_FAVORITES_ERROR,
          payload: error,
          error: true
        });
      });
  };
}
