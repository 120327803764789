import { GET_ALL_USERS_SUCCESS } from '../../actions/users';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_USERS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
