import { ADD_EXPLORE_TARIFFS, REMOVE_EXPLORE_TARIFFS } from '../../actions/explore';
const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_EXPLORE_TARIFFS:
      return state.concat([action.payload]);
    case REMOVE_EXPLORE_TARIFFS:
      let deleteIndex = state.findIndex(tariff => tariff.tariffId === action.payload.tariffId);
      if (deleteIndex > -1) {
        let newSelected = [].concat(state.slice(0, deleteIndex), state.slice(deleteIndex + 1));
        return newSelected;
      } else {
        return state;
      }
    default:
      return state;
  }
}
