import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { Save, Cancel } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ProposalGeneralForm from '../Detail/ProposalGeneralForm';
import { updateProposal, handleInputFormChangeProposal } from '../../../actions/proposals';
import AccessControl from '../../../utility/AccessControl';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  flex: {
    flex: 1
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  },
  button: {
    cursor: 'pointer'
  }
});

class ProposalDetailGeneralContainer extends React.Component {
  state = {
    hasChanged: false,
    isMessage: false,
    customer: null,
    customers: []
  };

  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false,
      original: { ...this.props.proposal }
    };
  }

  showMessage = () => {
    this.setState({
      isMessage: true
    });
  };

  hideMessage = (event, reason) => {
    this.setState({ isMessage: false });
  };

  isComplete = () => this.props.proposal.name;

  handleProposalChange = event => {
    const update = { [event.target.name]: event.target.value };

    this.props.handleInputFormChangeProposal(update);
    this.setState({
      hasChanged: true
    });
  };

  handleCustomerChange = event => {
    const update = { customer_id: event.value };

    this.props.handleInputFormChangeProposal(update);
    this.setState({
      hasChanged: true
    });
  };

  handleProposalEditCancel = () => {
    this.props.handleInputFormChangeProposal(this.state.original);
    this.setState({
      hasChanged: false
    });
  };

  handleProposalSave = () => {
    if (this.props.proposal.public_id) {
      this.props.updateProposal(this.props.proposal);
      this.showMessage();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography color="inherit" variant="h6" className={classes.flex}>
              Proposal Details
            </Typography>
            <AccessControl requiredPermissions={['editor', 'admin']}>
              <IconButton disabled={!this.isComplete()} aria-label="Save" onClick={this.handleProposalSave}>
                <Save />
              </IconButton>
              {this.state.hasChanged && (
                <IconButton aria-label="Save" onClick={this.handleProposalEditCancel}>
                  <Cancel />
                </IconButton>
              )}
            </AccessControl>
          </Toolbar>
          <ProposalGeneralForm
            handleProposalChange={this.handleProposalChange}
            handleCustomerChange={this.handleCustomerChange}
            isEdit={true}
            proposal={this.props.proposal}
            customer={this.props.customer}
            customers={this.props.customers}
          />
        </Paper>
        <Snackbar
          open={this.state.isMessage}
          autoHideDuration={5000}
          onClose={this.hideMessage}
          message={<span id="message-id">Proposal Updated Successfully</span>}
          action={[
            <Button key="undo" color="secondary" size="small" onClick={this.hideMessage}>
              OK
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.hideMessage}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

ProposalDetailGeneralContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  proposal: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired
};

export default connect(null, { updateProposal, handleInputFormChangeProposal })(
  withStyles(styles)(ProposalDetailGeneralContainer)
);
