import axios from 'axios';
import { Dispatch } from 'redux';
import { MonthlyOperationsData, ScenarioExternal, ScenarioInternal } from '../types';
import { convertExternalScenarioAssumptions, convertInternalScenarioAssumptions } from '../utils/assumptions';

export const GET_PROPOSAL_SCENARIO_REQUEST = 'GET_PROPOSAL_SCENARIO_REQUEST';
export const GET_PROPOSAL_SCENARIO_SUCCESS = 'GET_PROPOSAL_SCENARIO_SUCCESS';
export const GET_PROPOSAL_SCENARIO_ERROR = 'GET_PROPOSAL_SCENARIO_ERROR';

export const CREATE_PROPOSAL_SCENARIO_REQUEST = 'CREATE_PROPOSAL_SCENARIO_REQUEST';
export const CREATE_PROPOSAL_SCENARIO_SUCCESS = 'CREATE_PROPOSAL_SCENARIO_SUCCESS';
export const CREATE_PROPOSAL_SCENARIO_ERROR = 'CREATE_PROPOSAL_SCENARIO_ERROR';

export const UPDATE_PROPOSAL_SCENARIO_REQUEST = 'UPDATE_PROPOSAL_SCENARIO_REQUEST';
export const UPDATE_PROPOSAL_SCENARIO_SUCCESS = 'UPDATE_PROPOSAL_SCENARIO_SUCCESS';
export const UPDATE_PROPOSAL_SCENARIO_SILENT = 'UPDATE_PROPOSAL_SCENARIO_SILENT';
export const UPDATE_PROPOSAL_SCENARIO_ERROR = 'UPDATE_PROPOSAL_SCENARIO_ERROR';

export const DELETE_PROPOSAL_SCENARIO_REQUEST = 'DELETE_PROPOSAL_SCENARIO_REQUEST';
export const DELETE_PROPOSAL_SCENARIO_SUCCESS = 'DELETE_PROPOSAL_SCENARIO_SUCCESS';
export const DELETE_PROPOSAL_SCENARIO_ERROR = 'DELETE_PROPOSAL_SCENARIO_ERROR';

export const DISABLE_SCENARIO_REQUEST = 'DISABLE_SCENARIO_REQUEST';
export const DISABLE_SCENARIO_ERROR = 'DISABLE_SCENARIO_ERROR';
export const DISABLE_SCENARIO_SUCCESS = 'DISABLE_SCENARIO_SUCCESS';

export const ENABLE_SCENARIO_REQUEST = 'ENABLE_SCENARIO_REQUEST';
export const ENABLE_SCENARIO_ERROR = 'ENABLE_SCENARIO_ERROR';
export const ENABLE_SCENARIO_SUCCESS = 'ENABLE_SCENARIO_SUCCESS';

export const RESET_PROPOSAL_SCENARIO = 'RESET_PROPOSAL_SCENARIO';
export const SELECT_PROPOSAL_SCENARIOS = 'SELECT_PROPOSAL_SCENARIOS';
export const SELECT_PROPOSAL_SCENARIO = 'SELECT_PROPOSAL_SCENARIO';
export const SELECT_PROPOSAL_SCENARIO_PRESENTATION = 'SELECT_PROPOSAL_SCENARIO_PRESENTATION';
export const SELECT_PROPOSAL_SQ_PRESENTATION = 'SELECT_PROPOSAL_SQ_PRESENTATION';
export const DUPLICATE_PROPOSAL_SCENARIO = 'DUPLICATE_PROPOSAL_SCENARIO';
export const BROADCAST_PROPOSAL_SCENARIO = 'BROADCAST_PROPOSAL_SCENARIO';
export const BROADCAST_PROPOSAL_SCENARIO_ASSUMPTIONS = 'BROADCAST_PROPOSAL_SCENARIO_ASSUMPTIONS';
export const SCENARIO_INPUT_FORM_CHANGE = 'SCENARIO_INPUT_FORM_CHANGE';

export const RESET_REDIRECT = 'RESET_REDIRECT';

// TODO: Need to clean this one up. It is not consistent
export const SAVE_SCENARIOS = 'SAVE_SCENARIOS';

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export function duplicateProposalScenario(proposalScenario: ScenarioInternal) {
  let updatedName = proposalScenario.name;
  const reg = /\(\d+\)$/;
  const match = updatedName.match(reg);
  let copyVersion = 1;

  if (match) {
    copyVersion = parseInt(match[0].replace('(', '').replace(')', '')) + 1;
    updatedName = updatedName.replace(reg, '');
  }
  updatedName = `${updatedName} (${copyVersion})`;

  return (dispatch: Dispatch<any>) => {
    dispatch({ type: DUPLICATE_PROPOSAL_SCENARIO, payload: { ...proposalScenario, name: updatedName } });
  };
}

export function selectProposalScenario(proposalScenario: ScenarioInternal) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: SELECT_PROPOSAL_SCENARIO, payload: proposalScenario });
  };
}

export function selectProposalScenarioPresentation(proposalScenario?: ScenarioInternal) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: SELECT_PROPOSAL_SCENARIO_PRESENTATION, payload: proposalScenario });
  };
}

export function selectProposalSQPresentation(proposalScenario?: ScenarioInternal) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: SELECT_PROPOSAL_SQ_PRESENTATION, payload: proposalScenario });
  };
}

export function resetProposalScenario() {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: RESET_PROPOSAL_SCENARIO });
  };
}

export function getProposalScenario(scenarioId: string, proposalId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_PROPOSAL_SCENARIO_REQUEST });
    axios
      .get<ScenarioExternal>(`${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}`)
      .then(res => {
        const proposalScenario = res.data;
        dispatch({
          type: GET_PROPOSAL_SCENARIO_SUCCESS,
          payload: convertExternalScenarioAssumptions(proposalScenario)
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_PROPOSAL_SCENARIO_ERROR,
          payload: error
        });
      });
  };
}

export function createProposalScenario(proposalScenario: Partial<ScenarioInternal>, proposalId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: CREATE_PROPOSAL_SCENARIO_REQUEST });
    axios
      .post<ScenarioExternal>(
        `${ROOT_URL}/proposal/${proposalId}/scenario`,
        convertInternalScenarioAssumptions(proposalScenario as ScenarioInternal)
      )
      .then(res => {
        const proposalScenario = res.data;
        dispatch({
          type: CREATE_PROPOSAL_SCENARIO_SUCCESS,
          payload: convertExternalScenarioAssumptions(proposalScenario)
        });
      })
      .catch(function (error) {
        dispatch({
          type: CREATE_PROPOSAL_SCENARIO_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function resetProposalScenarioResults<T extends { public_id: string }>(proposalScenario: T, proposalId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_PROPOSAL_SCENARIO_REQUEST });
    axios
      .put<ScenarioExternal>(
        `${ROOT_URL}/proposal/${proposalId}/scenario/${proposalScenario.public_id}/reset_results`,
        {}
      )
      .then(res => {
        dispatch({
          type: UPDATE_PROPOSAL_SCENARIO_SUCCESS,
          payload: convertExternalScenarioAssumptions(res.data)
        });
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_PROPOSAL_SCENARIO_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function updateProposalScenario(proposalScenario: Partial<ScenarioInternal>, proposalId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_PROPOSAL_SCENARIO_REQUEST });
    axios
      .put<ScenarioExternal>(
        `${ROOT_URL}/proposal/${proposalId}/scenario/${proposalScenario.public_id}`,
        convertInternalScenarioAssumptions((proposalScenario as unknown) as ScenarioInternal) // it actually is a partial scenario
      )
      .then(res => {
        const proposalScenario = res.data;
        dispatch({
          type: UPDATE_PROPOSAL_SCENARIO_SUCCESS,
          payload: convertExternalScenarioAssumptions(proposalScenario)
        });
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_PROPOSAL_SCENARIO_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function deleteProposalScenario(scenarioId: string, proposalId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: DELETE_PROPOSAL_SCENARIO_REQUEST });
    axios
      .delete(`${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}`)
      .then(res => {
        dispatch({ type: DELETE_PROPOSAL_SCENARIO_SUCCESS, payload: scenarioId });
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_PROPOSAL_SCENARIO_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function getScenarioOperationsDataShort(proposalId: string, scenarioId: string): Promise<MonthlyOperationsData> {
  return axios
    .get<MonthlyOperationsData>(`${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}/results`)
    .then(res => res.data);
}

export function handleDisableScenario(proposalId: string, scenarioId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: DISABLE_SCENARIO_REQUEST });
    axios
      .put<ScenarioExternal>(`${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}/disable`)
      .then(res => {
        const proposalScenario = res.data;
        dispatch({
          type: DISABLE_SCENARIO_SUCCESS,
          payload: convertExternalScenarioAssumptions(proposalScenario)
        });
      })
      .catch(function (error) {
        dispatch({
          type: DISABLE_SCENARIO_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function handleEnableScenario(proposalId: string, scenarioId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: ENABLE_SCENARIO_REQUEST });
    axios
      .put<ScenarioExternal>(`${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}/enable`)
      .then(res => {
        const proposalScenario = res.data;
        dispatch({
          type: ENABLE_SCENARIO_SUCCESS,
          payload: convertExternalScenarioAssumptions(proposalScenario)
        });
      })
      .catch(function (error) {
        dispatch({
          type: ENABLE_SCENARIO_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export const resetRedirect = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: RESET_REDIRECT });
  };
};

export async function getLoadSensitivityAnalysis(proposalId: string, scenarioId: string) {
  return axios.get(`${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}/load-sensitivity`).then(response => {
    if (!response.data || response.data.status.toLowerCase() === 'error') {
      throw new Error(`Error while fetching LSA: ${response?.data?.detail ?? ''}`);
    }

    return response.data;
  });
}

export function resetLoadSensitivityAnalysis({ proposalId, scenarioId }: { proposalId: string; scenarioId: string }) {
  return axios
    .get(`${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}/load-sensitivity/reset`)
    .then(response => response.data);
}

type CreateLSAArgs = {
  proposalId: string;
  scenarioId: string;
  cases: { isPeakMagnitude?: boolean; isPeakFrequency?: boolean; isPeakDuration?: boolean };
};
export function createLoadSensitivityAnalysis({ proposalId, scenarioId, cases }: CreateLSAArgs) {
  let url = `${ROOT_URL}/proposal/${proposalId}/scenario/${scenarioId}/load-sensitivity?`;

  if (cases?.isPeakMagnitude) {
    url += 'is_peak_magnitude=true&';
  }

  if (cases?.isPeakFrequency) {
    url += 'is_peak_frequency=true&';
  }

  if (cases?.isPeakDuration) {
    url += 'is_peak_duration=true&';
  }

  const lastCharacter = url[url.length - 1];
  if (lastCharacter === '&') {
    url = url.slice(0, url.length - 1);
  }

  return axios.post(url).then(response => {
    if (JSON.stringify(response.data) != 'true') {
      throw new Error('Error while creating the Load Sensitivity Analysis');
    }
    return response.data;
  });
}
