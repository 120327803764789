import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateEVResultsOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];

  chartOptions.push({
    key: 'Before EV Fleet kWh charged',
    label: 'Before EV Fleet kWh charged',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After EV Fleet kWh charged',
    label: 'After EV Fleet kWh charged',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before EV Fleet Unmet kWh Percent',
    label: 'Before EV Fleet Unmet kWh Percent',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After EV Fleet Unmet kWh Percent',
    label: 'After EV Fleet Unmet kWh Percent',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before Dumb Charging Cost',
    label: 'Before Dumb Charging Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Dumb Charging Cost',
    label: 'After Dumb Charging Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before Smart Charging Cost',
    label: 'Before Smart Charging Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Smart Charging Cost',
    label: 'After Smart Charging Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before EV Base Subscription Cost',
    label: 'Before EV Base Subscription Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After EV Base Subscription Cost',
    label: 'After EV Base Subscription Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before EV Overage Cost',
    label: 'Before EV Overage Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After EV Overage Cost',
    label: 'After EV Overage Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before EV Total Demand Cost',
    label: 'Before EV Total Demand Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After EV Total Demand Cost',
    label: 'After EV Total Demand Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before EV Total Cost',
    label: 'Before EV Total Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After EV Total Cost',
    label: 'After EV Total Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before EV Total Average Cost',
    label: 'Before EV Total Average Cost ($/kWh)',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After EV Total Average Cost',
    label: 'After EV Total Average Cost ($/kWh)',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  return chartOptions;
};
