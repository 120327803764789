// map over an array or return an empty array is undefined
export function mapIfArray<A, B>(f: (el: A) => B, arr?: A[]): B[] {
  return arr !== undefined && arr !== null && Array.isArray(arr) ? arr.map(f) : [];
}

// map over an array or throw
export function mapIfArrayThrow<A, B>(f: (el: A) => B, arr?: A[]): B[] {
  if (arr === undefined || arr === null || !Array.isArray(arr)) {
    throw new TypeError('Was expecting an array, found ' + arr);
  }
  return arr.map(f);
}
