import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Redirect } from 'react-router-dom';
import {
  resetTariff
  //duplicateTariff, getTariff
} from '../../actions/tariffs';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import { parseQuery } from '../../utility/General';
// import { getTariffTemplate } from '../../utility/Tariff';
import { StoreState } from '../../reducers';
import TariffFactory from '../../components/Tariffs/Create/TariffFactory';
import TariffContextProvider from '../../components/Tariffs/Create/TariffFactoryContext';
import { RouteComponentProps } from 'react-router';

export interface QueryParams {
  id?: string;
  effectiveDate?: string;
  template?: string;
  edit?: boolean;
}

const TariffCreate: React.FC<RouteComponentProps> = props => {
  const dispatch = useDispatch();
  const tariff = useSelector((state: StoreState) => state.tariff);

  useEffect(() => {
    const queryParams = parseQuery(props.location.search) as QueryParams;
    if (queryParams.id == undefined) {
      dispatch(resetTariff());
    }
  }, [props.location]);

  useEffect(() => {
    // TODO: looks like code that is not useful anymore
    // if (queryParams.template) {
    //   dispatch(duplicateTariff(getTariffTemplate(queryParams.template)));
    // } else if (queryParams.effectiveDate) {
    //   let effectiveDate = queryParams.effectiveDate;
    //   dispatch(getTariff(queryParams.id, effectiveDate));
    // }
    const queryParams = parseQuery(props.location.search) as QueryParams;
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Tariffs', '/tariffs'));
    dispatch(pushBreadcrumb(queryParams.edit ? 'Edit Tariff' : 'Create Tariff', props.location.pathname || ''));
  }, [props.location.pathname]);

  return (
    <TariffContextProvider>
      <TariffFactory baseTariff={tariff} />
    </TariffContextProvider>
  );
};

export default TariffCreate;
