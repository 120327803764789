import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateGHGComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before Total GHG Emissions',
    label: 'Before Total GHG Emissions (kg C02eq)',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Total GHG Emissions',
    label: 'After Total GHG Emissions (kg CO2eq)',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Total Peak GHG Reductions',
    label: 'Total Peak GHG Reductions (kg CO2eq) ',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Total Part Peak GHG Reductions',
    label: 'Total Part Peak GHG Reductions (kg CO2eq) ',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Total Off Peak GHG Reductions',
    label: 'Total Off Peak GHG Reductions (kg CO2eq)',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'Total GHG Reductions',
    label: 'Total GHG Reductions (kg CO2eq)',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Total GHG Reductions After Battery',
    label: 'Total GHG Reductions After Battery (kg CO2eq)',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Solar Impact',
    label: 'Solar Impact Emissions (kg CO2eq)',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  return chartOptions;
};
