import { StandardSolarStorageCAISOTemplate, SolarTariffSolarStorageCAISOTemplate, Template } from './Template';

export type TemplateType = 'solar+storage' | 'solar+storage-different_tariff';

export abstract class TemplateFactory {
  public abstract type: TemplateType;
  public abstract createTemplate(): Template;

  init(): Template {
    return this.createTemplate();
    //template.generateScenarios(proposalId, equipmentValues);
    // template.save()
  }
}

export class CAISOTemplateFactory extends TemplateFactory {
  constructor(public type: TemplateType) {
    super();
  }
  public createTemplate(): Template {
    switch (this.type) {
      case 'solar+storage':
        return new StandardSolarStorageCAISOTemplate();
      case 'solar+storage-different_tariff':
        return new SolarTariffSolarStorageCAISOTemplate();
      default:
        throw new Error('Wrong template type passed.');
    }
  }
}
