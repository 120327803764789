import React from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Archive from '@material-ui/icons/Archive';

import { Portfolio } from '../../../types';
import { deletePortfolio, archivePortfolio } from '../../../actions/portfolios';

const useStyles = makeStyles({
  content: {
    paddingTop: 0,
    marginBottom: 20
  },
  name: {
    fontWeight: 500
  },
  optionHead: {
    fontWeight: 500
  },
  instruction: {
    fontSize: '11px'
  },
  archived: {
    width: '20px',
    position: 'relative',
    top: '6px',
    color: '#717171'
  }
});
interface Props {
  open: boolean;
  handleClose: any;
  selectedPortfolio: Portfolio | null;
}

const ConfirmPortfolioDelete: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleCancel = () => {
    props.handleClose();
  };

  const handleDelete = () => {
    if (props.selectedPortfolio && props.selectedPortfolio?.public_id) {
      dispatch(deletePortfolio(props.selectedPortfolio.public_id));
      props.handleClose('delete');
    }
  };

  const handleArchive = () => {
    if (props.selectedPortfolio) {
      dispatch(archivePortfolio({ ...props.selectedPortfolio, is_archived: true }));
      props.handleClose('archive');
    }
  };

  const handleUnarchive = () => {
    if (props.selectedPortfolio) {
      dispatch(archivePortfolio({ ...props.selectedPortfolio, is_archived: false }));
      props.handleClose('unarchive');
    }
  };

  return (
    <Dialog
      open={props.open}
      // onClose={props.onConfirmClose(false)}
      aria-labelledby="alert-dialog-delete"
      aria-describedby="alert-dialog-delete-item"
    >
      <DialogTitle>Archive or Delete Portfolio</DialogTitle>
      <DialogContent id="alert-dialog-title" className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <div className={classes.name}>
                  Portfolio: <Archive className={classes.archived} /> {props.selectedPortfolio?.name}
                </div>
              </TableCell>
            </TableRow>
            {!props.selectedPortfolio?.is_archived && (
              <TableRow>
                <TableCell>
                  <div className={classes.optionHead}>Archive this portfolio</div>
                  <div className={classes.instruction}>
                    This will hide the portfolio from the portfolios list. You can showing archived portfolios in the
                    filters menu
                  </div>
                </TableCell>
                <TableCell>
                  <Button variant="contained" onClick={handleArchive} color="primary" autoFocus aria-label="Archive">
                    Archive
                  </Button>
                </TableCell>
              </TableRow>
            )}
            {props.selectedPortfolio?.is_archived && (
              <TableRow>
                <TableCell>
                  <div className={classes.optionHead}>Unarchive this portfolio</div>
                  <div className={classes.instruction}>
                    Unarchive this portfolio to show in default load of portfolios
                  </div>
                </TableCell>
                <TableCell>
                  <Button variant="contained" onClick={handleUnarchive} color="primary" autoFocus aria-label="Archive">
                    UnArchive
                  </Button>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <div className={classes.optionHead}>Delete this portfolio</div>
                <div className={classes.instruction}>Careful, this will permanently delete your portfolio!</div>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  color="secondary"
                  autoFocus
                  aria-label="Delete"
                  style={{ color: 'white' }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel} aria-label="cancel">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPortfolioDelete;
