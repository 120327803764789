import React from 'react';
import { Portfolio, PortfolioType } from '../../../types';
import PortfolioResultsMonthlyTableNemA from './PortfolioResultsMonthlyTableNemA';
import PortfolioResultsYearlyTableNemA from './PortfolioResultsYearlyTableNemA';
import PortfolioResultsChartNemA from './PortfolioResultsChartNemA';
import PortfolioProformaTableNemA from './PortfolioProformaTableNemA';
import PortfolioMetersPVProfile from './PorfolioMetersPVProfile';
interface IProps {
  portfolio: Portfolio;
}

const PortfolioResultsTableContainer: React.FunctionComponent<IProps> = ({ portfolio }) => {
  const RESULT_PROFORMA_TABLE: Record<PortfolioType, React.ReactNode> = {
    NEMA: <PortfolioProformaTableNemA portfolio={portfolio} />,
    GENERAL: null
  };
  const RESULT_YEARLY_TABLE: Record<PortfolioType, React.ReactNode> = {
    NEMA: <PortfolioResultsYearlyTableNemA portfolio={portfolio} />,
    GENERAL: null
  };
  const RESULT_MONTHLY_TABLE: Record<PortfolioType, React.ReactNode> = {
    NEMA: <PortfolioResultsMonthlyTableNemA portfolio={portfolio} />,
    GENERAL: null
  };
  const RESULT_CHART: Record<PortfolioType, React.ReactNode> = {
    NEMA: <PortfolioResultsChartNemA portfolio={portfolio} />,
    GENERAL: null
  };

  const PV_PROFILES__CHART: Record<PortfolioType, React.ReactNode> = {
    NEMA: <PortfolioMetersPVProfile portfolio={portfolio} />,
    GENERAL: null
  };
  return (
    <>
      <div>{RESULT_PROFORMA_TABLE[portfolio.portfolio_type]}</div>
      <div>{RESULT_YEARLY_TABLE[portfolio.portfolio_type]}</div>
      <div>{RESULT_MONTHLY_TABLE[portfolio.portfolio_type]}</div>
      <div>{RESULT_CHART[portfolio.portfolio_type]}</div>
      <div>{PV_PROFILES__CHART[portfolio.portfolio_type]}</div>
    </>
  );
};

export default PortfolioResultsTableContainer;
