import { MonthlyTableKey, ScenarioResults } from '../../../../types';

export interface MonthlyTableOption {
  key: MonthlyTableKey;
  label: string;
  include: boolean;
  highlight: boolean;
  category: string;
}

export const keys: Partial<keyof NonNullable<ScenarioResults>>[] = [
  'Demand Cost MidPeak',
  'Demand Cost NC',
  'Demand Cost Peak',
  'Demand Cost Total',
  'Demand Max MidPeak',
  'Demand Max NC',
  'Demand Max Peak',
  'Energy Cost Total'
];

export const extraKeys: Partial<keyof NonNullable<ScenarioResults>>[] = [
  'Average SOC',
  'AVERAGE_WEEKDAY_DISCHARGE_KW',
  'AVERAGE_WEEKEND_DISCHARGE_KW',
  'Battery Kgs CO2 / kWh',
  'Battery Kgs CO2',
  'Charge From PV',
  'Clean Peak Standard',
  'CONNECTED_SOLUTIONS_DR_INCENTIVE',
  'CONNECTED_SOLUTIONS_DR_INCENTIVE_WEEKDAY',
  'CONNECTED_SOLUTIONS_DR_INCENTIVE_WEEKEND',
  'Cycles',
  'DR Reduction Capacity',
  'DR Reduction',
  'Degradation Rate',
  'Demand Cost GA',
  'Demand Cost PDP Reservation',
  'Demand Credit BIP',
  'Demand Credit GA DR',
  'Demand Credit PDP',
  'Demand Peak Factor GA',
  'EV Base Subscription Cost',
  'EV Fleet Unmet kWh Percent',
  'EV Fleet kWh charged',
  'EV Overage Cost',
  'Effective CP Rate',
  'Energy Cost BIP',
  'Energy Cost Gen',
  'Energy Cost MidPeak',
  'Energy Cost OffPeak',
  'Energy Cost PDP',
  'Energy Cost Peak',
  'Energy Revenues Net Metering MidPeak',
  'Energy Revenues Net Metering OffPeak',
  'Energy Revenues Net Metering On Peak',
  'Energy Revenues Net Metering',
  'Energy Use MidPeak',
  'Energy Use OffPeak',
  'Energy Use Peak',
  'Energy Use Total',
  'Exported Energy MidPeak',
  'Exported Energy Off Peak',
  'Exported Energy On Peak',
  'Exported Energy',
  'Genset Average Efficiency',
  'Genset Average Fuel Rate',
  'Genset Fuel Consumption',
  'Genset Fuel Cost',
  'Genset Runtime',
  'Genset kWh',
  'ICAP Cost',
  'ITRAN Cost',
  'Islanded Battery Cycles',
  'Load Curtailment',
  'Load Kgs CO2 / kWh',
  'Net Metering Credit Balance',
  'Net Metering Credit Change',
  'NUM_WEEKDAY_DR_EVENTS',
  'NUM_WEEKDAY_DR_EVENTS_PARTICIPATED',
  'NUM_WEEKEND_DR_EVENTS',
  'NUM_WEEKEND_DR_EVENTS_PARTICIPATED',
  'PDR Bids',
  'PDR Hours',
  'PDR Revenues',
  'Solar Curtailment During Outages',
  'Solar Curtailment',
  'Solar Generation',
  'Total Kgs CO2',
  'VDER Community Credit Revenue',
  'VDER DRV Revenue',
  'VDER Energy Revenue',
  'VDER Environment Revenue',
  'VDER ICAP Revenue',
  'VDER Revenues'
];
