import { GET_LSES_SUCCESS } from '../../actions/utilities';
import { LSEResult } from '../../types';

const INITIAL_STATE: LSEResult[] = [];

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_LSES_SUCCESS:
      return action.payload.results;
    default:
      return state;
  }
}
