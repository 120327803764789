import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Panorama from '@material-ui/icons/Panorama';
import ShowChart from '@material-ui/icons/ShowChart';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import Popover from '@material-ui/core/Popover';
import { ChromePicker } from 'react-color';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    position: 'relative',
    zIndex: 2
  }
}));

interface PCSProps {
  open: boolean;
  handleClose: any;
  editChartOptions: any;
  handleColorChangeComplete: any;
  handleMoveItem: any;
  handleChartTextUpdate: any;
  handleShowChartItem: any;
  handleChartLineType: any;
  showTOU: boolean;
  handleToggle: any;
  hideHoverInfo: boolean;
  TOUOpacity: number;
  handleValueChange: any;
  customRangeMin: any;
  customRangeMax: any;
  handleSuccessClose: any;
  handleStackChartItem: any;
}

const PresentationChartSettings: React.FC<PCSProps> = props => {
  const classes = useStyles();
  const colorRef = React.useRef<any>();

  const [colorOpen, setColorOpen] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState();
  const [selectedIndex, setSelectedIndex] = React.useState<number>();

  const openPicker = (color: any, index: number) => (e: any) => {
    colorRef.current = e.target;
    setSelectedColor(color);
    setSelectedIndex(index);
    setColorOpen(!colorOpen);
  };

  const closePicker = () => {
    setColorOpen(false);
  };

  const handleColorChange = (color: any) => {
    setSelectedColor(color);
  };

  const onColorComplete = () => {
    if (selectedColor) {
      props.handleColorChangeComplete(selectedColor, selectedIndex);
    }
  };

  return (
    <>
      <Popover
        anchorEl={colorRef.current}
        open={colorOpen}
        onClose={closePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <ChromePicker color={selectedColor} onChange={handleColorChange} onChangeComplete={onColorComplete} />
      </Popover>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
        <DialogTitle id="form-dialog-title">Chart Settings</DialogTitle>
        <DialogContent>
          <Typography>Data Streams</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Show</TableCell>
                <TableCell>Stack</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Opacity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.editChartOptions.map((dataItem: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    <IconButton
                      aria-label="Move Up in Order"
                      onClick={props.handleMoveItem(true, dataItem)}
                      style={{
                        height: 24,
                        width: 24
                      }}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                    <IconButton
                      aria-label="Move Down in Order"
                      onClick={props.handleMoveItem(false, dataItem)}
                      style={{
                        height: 24,
                        width: 24
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={dataItem.label}
                      name="label"
                      onChange={props.handleChartTextUpdate(dataItem)}
                      margin="dense"
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={dataItem.include} onClick={props.handleShowChartItem(dataItem)} />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={dataItem.stacked} onClick={props.handleStackChartItem(dataItem)} />
                  </TableCell>
                  <TableCell>
                    {dataItem.fill !== 'tozeroy' && (
                      <IconButton
                        aria-label="Line Chart"
                        style={{
                          height: 24,
                          width: 24
                        }}
                        onClick={props.handleChartLineType(dataItem)}
                      >
                        <ShowChart />
                      </IconButton>
                    )}
                    {dataItem.fill === 'tozeroy' && (
                      <IconButton
                        aria-label="Area Chart"
                        style={{
                          height: 24,
                          width: 24
                        }}
                        onClick={props.handleChartLineType(dataItem)}
                      >
                        <Panorama />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <div
                      ref={colorRef}
                      className={classes.popover}
                      onClick={openPicker(dataItem.color, index)}
                      data-testid="color-picker-button"
                      style={{
                        width: 25,
                        height: 25,
                        backgroundColor: dataItem.color,
                        borderRadius: 5
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: 0.1
                      }}
                      type="number"
                      value={dataItem.opacity}
                      name="opacity"
                      onChange={props.handleChartTextUpdate(dataItem)}
                      margin="dense"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br />
          {/* <Typography>Additional</Typography> */}
          {/* <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox checked={props.flipChargeDischarge} onClick={props.handleToggle} name="flipChargeDischarge" />
              }
              label="Flip Charge/Discharge"
            /> */}
          {/* <FormControlLabel
              control={<Checkbox checked={props.showTOU} onClick={props.handleToggle} name="showTOU" />}
              label="Show Time Of Use"
            />
            <FormControlLabel
              control={<Checkbox checked={props.showPeak} onClick={props.handleToggle} name="showPeak" />}
              label="Show Peak"
            />
            <FormControlLabel
              control={<Checkbox checked={props.showAnnotations} onClick={props.handleToggle} name="showAnnotations" />}
              label="Show Annotations"
            />
            <FormControlLabel
              control={<Checkbox checked={props.hideHoverInfo} onClick={props.handleToggle} name="hideHoverInfo" />}
              label="Hide Hover Info"
            />

            <FormControlLabel
              style={{
                marginLeft: 24
              }}
              control={
                <TextField
                  style={{
                    marginLeft: 8
                  }}
                  inputProps={{
                    min: 0,
                    max: 1,
                    step: 0.1
                  }}
                  type="number"
                  name="TOUOpacity"
                  value={props.TOUOpacity}
                  onChange={props.handleValueChange}
                />
              }
              label="TOU Opacity  "
              labelPlacement="start"
            />
            <FormControlLabel
              style={{
                marginLeft: 24
              }}
              control={
                <TextField
                  style={{
                    marginLeft: 8,
                    width: 100
                  }}
                  type="number"
                  name="customRangeMin"
                  value={props.customRangeMin}
                  onChange={props.handleValueChange}
                />
              }
              label="Custom Y-Range Min  "
              labelPlacement="start"
            />
            <FormControlLabel
              style={{
                marginLeft: 24
              }}
              control={
                <TextField
                  style={{
                    marginLeft: 8,
                    width: 100
                  }}
                  type="number"
                  name="customRangeMax"
                  value={props.customRangeMax}
                  onChange={props.handleValueChange}
                />
              }
              label="Custom Y-Range Max  "
              labelPlacement="start"
            /> */}
          {/* </FormGroup> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleSuccessClose} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PresentationChartSettings;
