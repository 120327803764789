import React, { useState } from 'react';
import { EVShiftInternal } from '../../../types';
import moment from 'moment';
import { Range } from 'immutable';

// MATERIAL UI IMPORTS
import TextField from '@material-ui/core/TextField';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ConfirmPopUp from '../../Common/ConfirmPopUp/ConfirmPopUp';
import VerticalWhiteSpace from '../../Common/VerticalWhiteSpace';
import ExpandableInputFields from '../../Common/ExpandableInputFields';

interface EVShiftProps {
  handleEVShiftAssumptionsChange: (
    id: number,
    assumptions?: Partial<EVShiftInternal>
  ) => (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  shift: EVShiftInternal;
  handleDeleteEVShift: (id: number) => () => void;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    // heading: {
    //   fontSize: theme.typography.pxToRem(15),
    //   fontWeight: theme.typography.fontWeightRegular,
    //   flex: 1
    // },
    mark: {
      fontSize: '0.6rem'
    }
  })
);

const EVShiftInputs: React.FC<EVShiftProps> = ({
  shift,
  handleEVShiftAssumptionsChange,
  handleDeleteEVShift,
  index
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // useEffect(() => {

  //   const { min, max } = getArrivalTimeFromAssumptions(shift);
  //   const sortedArrivalTime = [min, max].sort((a, b) => a - b) as [number, number];
  //   setArrival(sortedArrivalTime);
  // }, [shift]);

  const handleConfirmClose = (success: boolean) => (e: any) => {
    e.preventDefault();
    if (success) {
      handleDeleteEVShift(shift.id)();
    }
    setOpen(false);
  };

  const displayLabel = (value: number): string => moment({ hour: value }).format('hA');

  const marks = Range(0, 24)
    .toJS()
    .map(hour => ({ value: hour, label: displayLabel(hour) }));

  const handleArrivalChange = (event: any, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      throw new Error('expecting a range not a number');
    }
    handleEVShiftAssumptionsChange(shift.id, { evArrivalRange: newValue as [number, number] })(event);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" style={{ flex: 1 }}>
            Shift #{index}
          </Typography>
          <IconButton onClick={() => setOpen(true)}>
            <Delete />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <div>
              <div style={{ display: 'flex' }}>
                <Typography id="range-slider" gutterBottom style={{ flex: 1 }}>
                  Arrival window
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={shift.isOvernight}
                      onChange={handleEVShiftAssumptionsChange(shift.id, { isOvernight: !shift.isOvernight })}
                    />
                  }
                  label="Overnight"
                />
              </div>
              <Slider
                track={shift.isOvernight ? 'inverted' : 'normal'}
                min={0}
                max={23}
                // step={1}
                marks={marks}
                value={shift.evArrivalRange}
                onChange={handleArrivalChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                valueLabelFormat={displayLabel}
                name="evArrivalRange"
                classes={{
                  markLabel: classes.mark
                }}
              />
            </div>
            <VerticalWhiteSpace />
            <ExpandableInputFields>
              <TextField
                label="Average Charge Length (hrs)"
                key="muChargingPrd"
                name="muChargingPrd"
                value={shift.muChargingPrd}
                onChange={handleEVShiftAssumptionsChange(shift.id)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Charge Length Standard Deviation (hrs)"
                key="muChargingPrdStdDeviation"
                name="muChargingPrdStdDeviation"
                value={shift.muChargingPrdStdDeviation}
                onChange={handleEVShiftAssumptionsChange(shift.id)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
            </ExpandableInputFields>
            <VerticalWhiteSpace />
            <TextField
              label="EVSEs Utilisation Rate (%)"
              key="evseUtilisationRate"
              name="evseUtilisationRate"
              value={shift.evseUtilisationRate}
              onChange={handleEVShiftAssumptionsChange(shift.id)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
            <VerticalWhiteSpace />
            <ExpandableInputFields>
              <TextField
                label="EV Battery Capacity (kWh)"
                key="Smax"
                name="Smax"
                value={shift.Smax}
                onChange={handleEVShiftAssumptionsChange(shift.id)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Target SOC (%)"
                key="gamma"
                name="gamma"
                value={shift.gamma}
                onChange={handleEVShiftAssumptionsChange(shift.id)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
            </ExpandableInputFields>
          </div>
        </AccordionDetails>
      </Accordion>
      <ConfirmPopUp
        open={open}
        onConfirmClose={handleConfirmClose}
        message={`Are you sure that you want to delete shift #${index} ?`}
      />
    </>
  );
};

export default React.memo(EVShiftInputs);
