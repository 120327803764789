import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// REACT IMPORTS
import { uploadDataFiles, getProposalDataFromS3 } from '../../../actions/proposals';
import { getRequiredFlags } from '../../../utility/Proposal';
import { createLoadingSelector, createSuccessSelector } from '../../../selectors';
import ProposalData from '../Create/ProposalData';
import { updateExplorePageInformationSilently } from '../../../actions/explore';
import AccessControl from '../../../utility/AccessControl';
import { DataRequired, DataType, ProposalInternal, ScenarioInternal } from '../../../types';
import { StoreState } from '../../../reducers';

// MATERIAL UI IMPORTS
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MUIAlert from '@material-ui/lab/Alert';
import { UPDATE_PROPOSAL_SCENARIO_SILENT } from '../../../actions';
import { RESET_SUCCESS_FLAGS } from '../../../actions/status';
import { DataSet } from '../Create/ProposalDataContainer';

const useStyles = makeStyles({
  button: {
    marginRight: 10,
    marginBottom: 10
  }
});

interface IProps {
  proposal: ProposalInternal;
}

const ProposalDetailDataContainer: React.FC<IProps> = ({ proposal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    hasSaved: false,
    alertOpen: false
  });

  const loadingSelector = createLoadingSelector([
    'GET_ALL_PROPOSAL_DATA',
    'GET_PROPOSAL_DATA',
    'UPLOAD_ALL_DATA_FILES',
    'UPLOAD_LOAD_DATA_FILE',
    'UPLOAD_SOLAR_DATA_FILE',
    'UPLOAD_HELIOSCOPE_DATA_FILE',
    'GET_METER_INTERVAL'
  ]);
  const successSelector = createSuccessSelector(['UPLOAD_ALL_DATA_FILES']);
  const isLoading = useSelector(loadingSelector);
  const isSuccess = useSelector(successSelector);
  const loadProfile = useSelector((state: StoreState) => state.loadProfile);
  const solarProfile = useSelector((state: StoreState) => state.solarProfile);
  const lmpDAProfile = useSelector((state: StoreState) => state.lmpDAProfile);
  const lmpRTProfile = useSelector((state: StoreState) => state.lmpRTProfile);
  const ev1Profile = useSelector((state: StoreState) => state.ev1Profile);
  const ev2Profile = useSelector((state: StoreState) => state.ev2Profile);
  const exploreDetailsMetaInfo = useSelector((state: StoreState) => state.exploreDetailsMetaInfo);

  useEffect(() => {
    const getAllProposalData = (required: DataRequired) => {
      const types = [DataType.load];
      dispatch(getProposalDataFromS3(proposal.customer_id, proposal.public_id, DataType.load));
      if (required.solar) {
        dispatch(getProposalDataFromS3(proposal.customer_id, proposal.public_id, DataType.solar));
        types.push(DataType.solar);
      }
      if (required.lmp) {
        dispatch(getProposalDataFromS3(proposal.customer_id, proposal.public_id, DataType.lmpDa));
        dispatch(getProposalDataFromS3(proposal.customer_id, proposal.public_id, DataType.lmpRt));
        types.push(DataType.lmpDa);
        types.push(DataType.lmpRt);
      }
      if (required.mef) {
        dispatch(getProposalDataFromS3(proposal.customer_id, proposal.public_id, DataType.mef));
        types.push(DataType.mef);
      }

      if (required.ev1) {
        dispatch(getProposalDataFromS3(proposal.customer_id, proposal.public_id, DataType.ev1));
        types.push(DataType.ev1);
      }

      if (required.ev2) {
        dispatch(getProposalDataFromS3(proposal.customer_id, proposal.public_id, DataType.ev2));
        types.push(DataType.ev2);
      }
    };
    // load file is used for all proposals so I can safely check for that first
    const required = getRequiredFlags(proposal.proposal_scenarios);

    getAllProposalData(required);
    dispatch(
      updateExplorePageInformationSilently({
        showMonthly: false,
        showSeasonal: true,
        showTOU: false,
        showPeak: true
      })
    );
  }, [dispatch, proposal.public_id, proposal.customer_id]);

  useEffect(() => {
    setState(state => ({ ...state, hasSaved: isSuccess }));
  }, [isSuccess]);

  const hideMessage = () => {
    setState({ ...state, hasSaved: false });
    dispatch({ type: RESET_SUCCESS_FLAGS });
  };

  const handleProposalDataSave = (required: DataRequired) => () => {
    const dataSets: DataSet[] = [
      {
        type: DataType.load,
        historicalData: loadProfile,
        scaler: 1
      }
    ];

    if (required.solar) {
      dataSets.push({
        type: DataType.solar,
        historicalData: solarProfile,
        scaler: Math.max(...solarProfile.map(item => item[2]))
      });
    }
    if (required.lmp) {
      dataSets.push({
        type: DataType.lmpDa,
        historicalData: lmpDAProfile,
        scaler: 1000
      });
      dataSets.push({
        type: DataType.lmpRt,
        historicalData: lmpRTProfile,
        scaler: 1000
      });
    }

    if (required.ev1) {
      dataSets.push({
        type: DataType.ev1,
        historicalData: ev1Profile,
        scaler: 1
      });
    }

    if (required.ev2) {
      dataSets.push({
        type: DataType.ev2,
        historicalData: ev2Profile,
        scaler: 1
      });
    }

    dispatch(uploadDataFiles(proposal.public_id, dataSets, resetScenarios(proposal.proposal_scenarios)));
    setState({ ...state, hasSaved: true });

    if (state.alertOpen) {
      setState({ ...state, alertOpen: false });
    }
  };

  // callback after successfully saving data
  const resetScenarios = (scenarios: ScenarioInternal[]) => () => {
    for (let scenario of scenarios) {
      scenario.results = null;
      delete scenario.run_completed;
      delete scenario.run_started;
      delete scenario.status;
      dispatch({ type: UPDATE_PROPOSAL_SCENARIO_SILENT, payload: scenario });
    }
    dispatch({ type: RESET_SUCCESS_FLAGS, payload: 'UPDATE_PROPOSAL_SCENARIO' });
  };

  const handleClickOpen = () => {
    setState({ ...state, alertOpen: true });
  };

  const handleClose = () => {
    setState({ ...state, alertOpen: false });
  };

  const isRequired = getRequiredFlags(proposal.proposal_scenarios);

  return (
    <div>
      <AccessControl requiredPermissions={['editor', 'admin']}>
        <>
          {!isLoading && exploreDetailsMetaInfo.unSavedChanges && (
            <>
              <MUIAlert severity="warning" style={{ marginBottom: 10 }}>
                You have unsaved data! If you dont want to lose your changes, please click 'Save Data' below to save
                changes to your proposal.
              </MUIAlert>
            </>
          )}
          <div style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              aria-label="Save"
              onClick={handleClickOpen}
            >
              Save Data
            </Button>
          </div>
        </>
      </AccessControl>
      {isLoading && <div>Loading...</div>}
      {!isLoading && <ProposalData proposal={proposal} isRequired={isRequired} />}
      {/* Confirm that save has been successful */}
      <Snackbar open={state.hasSaved} autoHideDuration={5000} onClose={hideMessage}>
        <Alert severity="success" elevation={6} variant="filled">
          <span id="message-id">Data Saved Successfully</span>
        </Alert>
      </Snackbar>
      {/* Confirm you want to save to updated scenario */}
      <Dialog
        open={state.alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Data Save'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to save these changes? It will reset the results and you will have to rerun all
            scenarios.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button aria-label="Confirm Save" onClick={handleProposalDataSave(isRequired)} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProposalDetailDataContainer;
