import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Period, TariffFactoryContext } from './TariffFactoryContext';
import { TOUTypes } from '../../../types';
import ErrorDialog from '../../Common/ErrorDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(100),
    overflowX: 'auto'
  },
  table: {
    width: theme.spacing(100)
  },
  inputField: {
    width: theme.spacing(25)
  },
  selectInputField: {
    width: theme.spacing(20)
  }
}));

export type ChargesType = 'energy' | 'demand' | 'subscriptions';
interface Props {
  type: ChargesType;
  periods: Period[];
  handleChangeInput: any;
  handleDeletePeriod: any;
  handleChangeTOU: any;
}

const RatePeriodsEditor: React.FC<Props> = ({
  periods,
  type,
  handleChangeInput,
  handleDeletePeriod,
  handleChangeTOU
}) => {
  const classes = useStyles();

  const { openUsedPeriod, setOpenUsedPeriod } = useContext(TariffFactoryContext);

  const handleChange = (key: ChargesType, name: keyof Period, index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    handleChangeInput(key, name, index, value);
  };

  const handleTOUChange = (index: number) => (event: React.ChangeEvent<{ value: unknown }>) => {
    handleChangeTOU(type, index, event.target.value as TOUTypes);
  };

  return (
    <>
      <Table className={classes.table} aria-label="periods table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Period</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">TOU</TableCell>
            <TableCell align="right">Charges ($/kW{type === 'energy' ? 'h' : ''})</TableCell>
            <TableCell align="right"> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periods.length > 0 &&
            periods.map((period, i) => (
              <TableRow key={period.index}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40
                  }}
                >
                  <div
                    style={{
                      display: 'grid',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 8,
                      backgroundColor: period.color,
                      border: '1px solid grey'
                    }}
                  >
                    {period.index}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <TextField
                    className={classes.inputField}
                    value={periods[i].name}
                    onChange={handleChange(type, 'name', period.index)}
                    margin="normal"
                  />
                </TableCell>
                <TableCell align="right">
                  <Select
                    className={classes.selectInputField}
                    aria-label="simple-select-tou"
                    data-testid={`simple-select-tou-${type}-${i}`}
                    value={period.TOU}
                    onChange={handleTOUChange(period.index)}
                  >
                    <MenuItem value={TOUTypes.ON_PEAK}>On Peak</MenuItem>
                    <MenuItem value={TOUTypes.PARTIAL_PEAK}>Partial Peak</MenuItem>
                    <MenuItem value={TOUTypes.OFF_PEAK}>Off Peak</MenuItem>
                    <MenuItem value={TOUTypes.SUPER_OFF_PEAK}>Super Off Peak</MenuItem>
                    <MenuItem value={TOUTypes.FLAT_RATE}>Flat Rate</MenuItem>
                    {type === 'demand' && <MenuItem value={TOUTypes.NON_COINCIDENTAL}>Non Coincidental</MenuItem>}
                  </Select>
                </TableCell>
                <TableCell align="right">
                  <TextField
                    className={classes.inputField}
                    value={periods[i].charges}
                    onChange={handleChange(type, 'charges', period.index)}
                    margin="normal"
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={handleDeletePeriod(type, i)} aria-label="Delete Period Rate">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <ErrorDialog
        open={openUsedPeriod}
        onClose={() => setOpenUsedPeriod(false)}
        errorMsg="This period is in use. Please replace it by another one before deleting."
      />
    </>
  );
};

export default React.memo(RatePeriodsEditor);
