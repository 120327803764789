import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Save from '@material-ui/icons/Save';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, TableBody } from '@material-ui/core';
import { getGlobalSettingByKey, updateGlobalSetting } from '../../actions/globalsettings';
import { createLoadingSelector } from '../../selectors';
import { popBreadcrumb, pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import AccessControl, { isAdmin } from '../../utility/AccessControl';

import { regexpValidator } from '../../utility/Validator';

const styles = theme => ({
  root: {
    width: '100%'
  },
  info: {
    margin: theme.spacing(3)
  },
  container: {
    padding: 20,
    maxWidth: 1000,
    margin: '0 auto'
  }
});

const colHeadersMap = [
  [
    'DR Summer Credit',
    'DR Winter Credit',
    'DR Penalty',
    'DR Summer Months',
    'DR Winter Months',
    'DR Capacity',
    'DR Discount'
  ],
  ['Date', 'Hours', 'Total Demand'],
  ['Date', 'Hours'],
  ['Year', 'Month', 'Total Cost'],
  ['Date', 'Hours']
];
// Handsontable validation section
const runValidation = validator => (value, callback) => callback(validator(value));
const dateValidatorRegExp = /(\d{4})-(\d{2})-(\d{2})$/;

const buildColumns = tabIndex => {
  switch (tabIndex) {
    case 0:
      return [
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        },
        {
          type: 'text',
          colWidths: 30,
          allowEmpty: false,
          readOnly: !isAdmin()
        }
      ];
    case 1:
      return [
        {
          type: 'date',
          dateFormat: 'YYYY-MM-DD',
          correctFormat: true,
          allowEmpty: false,
          readOnly: !isAdmin(),
          colWidths: 50,
          validator: runValidation(regexpValidator(dateValidatorRegExp)),
          allowInvalid: false
        },
        {
          type: 'numeric',
          colWidths: 50,
          allowEmpty: false,
          readOnly: !isAdmin(),
          allowInvalid: false
        },
        {
          type: 'numeric',
          colWidths: 50,
          allowEmpty: false,
          readOnly: !isAdmin(),
          allowInvalid: false
        }
      ];
    case 2:
      return [
        {
          type: 'date',
          dateFormat: 'YYYY-MM-DD',
          correctFormat: true,
          allowEmpty: false,
          readOnly: !isAdmin(),
          colWidths: 50,
          validator: runValidation(regexpValidator(dateValidatorRegExp)),
          allowInvalid: false
        },
        {
          type: 'text',
          allowEmpty: false,
          readOnly: !isAdmin(),
          colWidths: 50,
          allowInvalid: false
        }
      ];
    case 3:
      return [
        {
          type: 'date',
          dateFormat: 'YYYY',
          correctFormat: true,
          colWidths: 50,
          allowEmpty: false,
          validator: runValidation(regexpValidator(dateValidatorRegExp)),
          allowInvalid: false,
          readOnly: !isAdmin()
        },
        {
          type: 'numeric',
          colWidths: 50,
          allowEmpty: false,
          allowInvalid: false,
          readOnly: !isAdmin()
        },
        {
          type: 'numeric',
          colWidths: 50,
          allowEmpty: false,
          allowInvalid: false,
          readOnly: !isAdmin()
        }
      ];
    case 4:
      return [
        {
          type: 'date',
          dateFormat: 'YYYY-MM-DD',
          correctFormat: true,
          allowEmpty: false,
          readOnly: !isAdmin(),
          colWidths: 50,
          validator: runValidation(regexpValidator(dateValidatorRegExp)),
          allowInvalid: false
        },
        {
          type: 'text',
          allowEmpty: false,
          readOnly: !isAdmin(),
          colWidths: 50,
          allowInvalid: false
        }
      ];

    default:
      break;
  }
};

const keys = ['GA Info', 'GA Events', 'GA Events Predicted', 'GA Costs', 'DR Events'];

const GlobalAdjustment = props => {
  const { classes, setting, handleDataSave, getGlobalSettingByKey, pushBreadcrumb, location, isLoading } = props;

  const [data, setData] = useState([]);
  const [settings, setSettings] = useState({});
  const [message, setMessage] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [hasChanged, setHasChanged] = useState(false);
  const tableRef = useRef(null);

  // get the proper setting
  useEffect(() => {
    getGlobalSettingByKey(keys[tabIndex]);
  }, [getGlobalSettingByKey, tabIndex]);

  // build data
  useEffect(() => {
    const generateGrid = () => {
      //const grid = generateVerticalGrid(2016, 7, props.setting);
      if (setting && setting && setting.value) {
        const grid = setting.value;
        if (handleDataSave) {
          handleDataSave(grid);
        }
        return grid;
      }
      return [];
    };

    const generateVerticalSettings = () => {
      return {
        colHeaders: colHeadersMap[tabIndex],
        columnSorting: {
          sortEmptyCells: false,
          initialConfig: {
            column: 0,
            sortOrder: 'asc'
          }
        },
        contextMenu: {
          items: {
            row_above: {
              name: 'Insert row above this one'
            },
            row_below: {},
            remove_row: {}
          }
        },
        stretchH: 'all',
        className: 'htCenter htMiddle',
        rowHeights: 15,
        columns: buildColumns(tabIndex)
      };
    };

    const updatedSettings = generateVerticalSettings();
    const updatedData = generateGrid();
    setSettings(updatedSettings);
    setData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDataSave, setting, tabIndex]);

  // breadcrumbs
  useEffect(() => {
    resetBreadcrumb();
    pushBreadcrumb('Settings', '/settings');
    pushBreadcrumb('Global Adjustment', location.pathname);
  }, [location.pathname, pushBreadcrumb]);

  // on local state update, update the settings too, so proper row can be added/deleted
  // useEffect(() => {
  //   //setSettings(generateVerticalSettings());
  //   setHasChanged(false);
  // }, [data]);

  const handleModifyData = (index, amount) => {
    setData(data);
    setHasChanged(true);
  };

  // const generateSettings = () => {
  //   return generateVerticalSettings();
  // };

  const sortDates = data => {
    return data.sort((a, b) => moment(a).unix() - moment(b).unix());
  };

  const handleSave = () => {
    setData(sortDates(data));
    props.updateGlobalSetting(
      {
        ...props.setting,
        value: data
      },
      {
        onSuccess: () => {
          setMessage('Data Updated');
          setHasChanged(false);
        },
        onError: error => {
          setMessage('Error! ' + error);
        }
      }
    );
  };

  const handleTabChange = (e, value) => {
    setTabIndex(value);
  };
  return (
    <Paper className={classes.root}>
      <AccessControl requiredPermissions={['editor', 'admin']}>
        <div className="button-container">
          <Fab size="small" disabled={!hasChanged} color="primary" aria-label="Save" onClick={handleSave}>
            <Save />
          </Fab>
        </div>
      </AccessControl>
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Info" />
          <Tab label="Events" />
          <Tab label="Events predicted" />
          <Tab label="Costs" />
          <Tab label="DR Events" />
        </Tabs>
      </AppBar>
      {/* <SwipeableViews index={tabIndex} onChangeIndex={handleChangeIndex}>
        <div style={{ padding: 8 * 3 }}>Tab 1</div>
        <div style={{ padding: 8 * 3 }}>Tab2</div>
      </SwipeableViews> */}
      <div className={classes.container}>
        {isLoading && (
          <div style={{ textAlign: 'center', padding: 25 }}>
            <CircularProgress color="secondary" size={50} />
          </div>
        )}
        {!props.isLoading && (
          <div id="hot-app">
            <HotTable
              ref={tableRef}
              data={data}
              settings={settings}
              height="800"
              stretchH="all"
              afterRemoveRow={handleModifyData}
              afterCreateRow={handleModifyData}
              afterSetDataAtCell={handleModifyData}
            />
          </div>
        )}

        <Snackbar
          open={message.length > 0}
          autoHideDuration={5000}
          onClose={() => {
            setMessage('');
          }}
          message={<span id="message-id">{message}</span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={() => {
                setMessage('');
              }}
            >
              OK
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => {
                setMessage('');
              }}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    </Paper>
  );
};

GlobalAdjustment.propTypes = {
  breadcrumbsTrail: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  getGlobalSettingByKey: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  popBreadcrumb: PropTypes.func.isRequired,
  pushBreadcrumb: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  updateGlobalSetting: PropTypes.func.isRequired
};

const loadingSelector = createLoadingSelector(['GET_GLOBAL_SETTING_BY_KEY']);

const mapStateToProps = state => {
  return {
    setting: state.setting,
    isLoading: loadingSelector(state),
    breadcrumbsTrail: state.breadcrumbsTrail
  };
};

export default connect(mapStateToProps, {
  getGlobalSettingByKey,
  updateGlobalSetting,
  popBreadcrumb,
  pushBreadcrumb,
  resetBreadcrumb
})(withStyles(styles)(GlobalAdjustment));
