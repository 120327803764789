import React, { useRef } from 'react';

interface FileInputProps {
  onChange: any;
  children: any;
}
const FileInput: React.FC<FileInputProps> = props => {
  const { onChange, children } = props;

  const fileInput = useRef<HTMLInputElement>(null);
  //const fileInput: any = useRef(null);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      onChange(file);

      // free up the fileInput again
      // if (fileInput && fileInput.current) {
      //   fileInput.current = null;
      // }
    }
  };

  return (
    <div>
      <input accept=".csv" type="file" style={{ display: 'none' }} onChange={handleUpload} ref={fileInput} />
      {children &&
        React.cloneElement(children, {
          onClick: () => fileInput.current && fileInput.current.click()
        })}
    </div>
  );
};

export default FileInput;
