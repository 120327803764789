import { MouseEvent } from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { QueryStatus } from 'react-query/types/core/types';
import { ESAPTariff, FavoriteTariff } from "../../../types";
import TariffListRow from "./TariffListRow";
import { StoreState } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { updateTariffFilters } from '../../../actions/tariffs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tariffContainer: {
      overflowX: 'initial',
    },
    table: {
      minWidth: 650,
    },
    center: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }
  })
);

interface TariffListProps {
  status: QueryStatus;
  tariffList?: Partial<ESAPTariff>[];
  tariffCount?: number;
  favoriteTariffs?: FavoriteTariff[];
  onFavoriteClick: (tariff: ESAPTariff) => void;
  onTariffClick?: (tariff: ESAPTariff) => void;
}

const TariffList = (props: TariffListProps) => {
  const {
    status,
    tariffList,
    tariffCount = 0,
    favoriteTariffs = [],
    onFavoriteClick,
    onTariffClick,
  } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector((state: StoreState) => state.tariffFilters);

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    dispatch(updateTariffFilters({
      ...filters,
      pageStart: page
    }));
  }
  return  (
    <TableContainer className={styles.tariffContainer} component={Paper}>
      <Table stickyHeader className={styles.table} aria-label="Tariff List">
        <TableHead>
          <TableRow>
            <TableCell scope="col">Utility</TableCell>
            <TableCell scope="col">Name</TableCell>
            <TableCell scope="col">Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-test="table|tariff-list">
          {status === 'loading' && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell className={styles.center}><CircularProgress color="secondary" size={50} /></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
          {status === 'success' ?
            tariffList?.map((row: any, index: any) => {
              return (
                <TariffListRow
                  key={`${index}-${row.tariffId}`}
                  row={row}
                  data-test={`${index}-${row.tariffId}`}
                  isFavorite={favoriteTariffs.some((tariff: FavoriteTariff) => tariff.code === row.code)}
                  onFavoriteClick={onFavoriteClick}
                  onRowClick={onTariffClick}
                />
              )
            }): null
          }
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={tariffCount}
        rowsPerPage={filters.pageCount}
        page={filters.pageStart}
        rowsPerPageOptions={[25]}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={() => {}}
      />
    </TableContainer>
  )
}

export default TariffList;
