import React from 'react';
import TOUGrid from './TOUGrid';
import { SelectableGroup } from 'react-selectable-fast';
import PeriodSelector from './PeriodSelector';
import { Period } from './TariffFactoryContext';

export interface SelectableItem {
  node: HTMLElement;
  props: {
    month: number;
    hour: number;
    selectedPeriod: Period;
  };
}

interface Props {
  periods: Period[];
  updateMatrix: (matrix: number[][]) => void;
  matrix: Readonly<number[][]>;
}

const TOUSelectionContainer: React.FC<Props> = ({ periods, updateMatrix, matrix }) => {
  const selectableGroupRef = React.useRef<SelectableGroup | null>(null);
  const [openSelector, setOpenSelector] = React.useState(false);

  const [selectedPeriod, setSelectedPeriod] = React.useState<Period>(periods[0]);
  const [selectedCells, setSelectedCells] = React.useState<SelectableItem[]>([]);

  const [currentSelection, setCurrentSelection] = React.useState<SelectableItem[]>([]);

  const handleSelecting = (selectingItems: SelectableItem[]) => {
    const inputs = document.querySelectorAll('.MuiInput-input') as any;
    inputs.forEach((input: any) => input.blur());
    const buttons = document.querySelectorAll('.MuiButtonBase-root') as any;
    buttons.forEach((input: any) => input.blur());
  };

  const handleSelectionFinish = (selectedItems: SelectableItem[]) => {
    if (selectedItems.length > 0) {
      //selectedItems.forEach(item => (item.node.style.backgroundColor = 'orange'));
      setOpenSelector(true);
      setCurrentSelection(selectedItems);
      const newSelection = [...selectedCells, ...selectedItems];
      setSelectedCells(newSelection);
    }
  };

  const handlePeriodSelection = (period: Period) => (e: any) => {
    setSelectedPeriod(period);
    setOpenSelector(false);
    if (currentSelection) {
      currentSelection.forEach(cell => {
        cell.node.style.backgroundColor = period.color || '#fff';
        cell.node.innerHTML = period.index.toString();
      });
    }
    handleSaveGrid();
  };

  const handleSaveGrid = () => {
    const matrixCopy = matrix.map(arr => arr.slice());
    selectedCells.forEach(cell => {
      const { month, hour } = cell.props;
      matrixCopy[month - 1][hour - 1] = parseInt(cell.node.innerHTML);
    });
    updateMatrix(matrixCopy);
  };

  return (
    <>
      <PeriodSelector
        open={openSelector}
        onClose={() => setOpenSelector(false)}
        periods={periods}
        handlePeriodSelection={handlePeriodSelection}
      />
      <SelectableGroup
        style={{ cursor: 'pointer' }}
        ref={selectableGroupRef}
        className="selectgroup-main"
        // clickClassName="tick"
        enableDeselect={true}
        tolerance={0}
        resetOnStart={true}
        deselectOnEsc={true}
        allowClickWithoutSelected={true}
        duringSelection={handleSelecting}
        onSelectionFinish={handleSelectionFinish}
        ignoreList={['.not-selectable']}
      >
        <TOUGrid selectedPeriod={selectedPeriod} matrix={matrix} periods={periods} />
      </SelectableGroup>
    </>
  );
};

export default React.memo(TOUSelectionContainer);
