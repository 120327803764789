import React, { useEffect, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../custom-plotly.js';
import { Config, PlotData } from 'plotly.js';
const Plot = createPlotlyComponent(Plotly);

interface IProps {
  handleDateChange: any;
  data: PlotData[];
  months: string[];
  selectedMonth: string;
}

const MonthlyTimeSeries: React.FC<IProps> = ({ handleDateChange, data, months, selectedMonth }) => {
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    handleDateChange(event.target.value);
  };

  const [layout, setLayout] = useState<Partial<Plotly.Layout>>({});

  useEffect(() => {
    const buildLayout = (): Partial<Plotly.Layout> => {
      if (data && data.length > 0) {
        const timeStamps = data[0].x;
        const rangeStart = timeStamps && timeStamps[0] ? timeStamps[0] : 0;
        const rangeEnd = timeStamps && timeStamps[timeStamps.length - 1] ? timeStamps[timeStamps.length - 1] : 0;
        const layout: Partial<Plotly.Layout> = {
          autosize: true,
          legend: { orientation: 'h' },
          height: 400,
          margin: {
            l: 80,
            r: 30,
            t: 10,
            b: 80
          },
          xaxis: {
            autorange: true,
            range: [rangeStart, rangeEnd],
            rangeselector: {
              buttons: [
                {
                  count: 1,
                  label: '1d',
                  step: 'day',
                  stepmode: 'backward'
                },
                {
                  count: 7,
                  label: '1w',
                  step: 'day',
                  stepmode: 'backward'
                },
                { step: 'all' }
              ]
            },
            // rangeslider: { range: [rangeStart, rangeEnd] },
            type: 'date'
          },
          yaxis: {
            autorange: true,
            // range: [86.8700008333, 138.870004167],
            type: 'linear'
            // hoverformat: ".2r"
          }
        };
        return layout;
      }
      return {} as Partial<Plotly.Layout>;
    };
    setLayout(buildLayout());
  }, [data]);

  const config: Partial<Config> = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian'
    ],
    displaylogo: false
  };

  return (
    <Paper>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          Load Overview
        </Typography>
        <FormControl>
          <Select value={selectedMonth} onChange={handleChange}>
            {months.map(month => {
              return <MenuItem value={month}>{month}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Toolbar>
      <Plot style={{ width: '100%' }} useResizeHandler={true} data={data} layout={layout} config={config} />
    </Paper>
  );
};

export default MonthlyTimeSeries;
