import React from 'react';
import { createSelectable, TSelectableItemProps } from 'react-selectable-fast';
import { Period } from './TariffFactoryContext';

type Props = {
  id: string;
  hour: number;
  month: number;
  selectedPeriod: Period;
};

type CellProps = TSelectableItemProps & Props;

const Cell: React.FC<CellProps> = props => {
  const { selectableRef, isSelected, isSelecting, hour, month, id } = props;

  const classNames = ['cell-grid-item', isSelecting && 'selecting', isSelected && 'selected'].filter(Boolean).join(' ');

  return (
    <div
      id={id}
      ref={selectableRef}
      className={classNames}
      style={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#C7D5DD',
        border: '1px solid grey',
        width: '100%',
        height: '100%',
        gridColumn: `${hour + 1} / span 1`,
        gridRow: `${month + 1} / span 1`
      }}
    >
      1
    </div>
  );
};

export default createSelectable(Cell);
