import { createMuiTheme } from '@material-ui/core/styles';

const esapTheme = createMuiTheme({
  typography: {
    fontSize: 14,
    h6: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.15rem'
      },
      fontWeight: '500',
      lineHeight: '1.1',
      letterSpacing: '0.5px'
    }
  },
  palette: {
    darkOrange: 'rgb(254,88,21)',
    orange: 'rgb(255,160,47)',
    lightGreen: 'rgb(196,214,0)',
    green: 'rgb(80,158,47)',
    lightBlue: 'rgb(0,91,187)',
    darkBlue: 'rgb(0,26,112)',
    primary: {
      main: 'rgb(0,26,112)',
      light: 'rgb(0,91,187)'
    },
    secondary: {
      light: 'rgb(255,160,47)',
      main: 'rgb(254,88,21)',
      contrastText: 'rgb(255,160,47)'
    },
    esap: {
      blue: '#001A70',
      orange: 'rgb(254,88,21)',
      blueDim: '#003876',
      blueBright: '#1F12A8',
      greenTint: '#e9f0ee',
      greenLight: '#00A872',
      greenDark: '#007650',
      red: '#d32f2f',
      redDark: '#8C1C1C',
      yellowOrange: '#FF9417'
    }
  },
  overrides: {
    MuiButton: {
      text: {
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: 'none'
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.05)'
      },
      elevation4: {
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
      }
    },
    MuiToolbar: {
      root: {
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px'
      }
    },
    MuiTable: {
      root: {
        '& tr:first-child th': {
          backgroundColor: '#f1f1f1',
          lineHeight: '1.2',
          letterSpacing: '.5px',
          whiteSpace: 'nowrap',
          fontSize: '.9rem'
        },
        '& td, & th': {
          letterSpacing: '-.05px'
        },
        '& td': {
          color: '#383838',
          lineHeight: '1.2'
        }
      }
    },
    MuiTableSortLabel: {
      icon: {
        fontSize: 12
      }
    },
    MuiIconButton: {
      root: {
        padding: 8
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1.2rem'
      }
    },
    MuiFab: {
      root: {
        boxShadow: 'none',
        '& .MuiSvgIcon-root': {
          fontSize: '1.2rem'
        }
      },
      sizeSmall: {
        width: '35px',
        height: '30px'
      }
    }
  }
} as any);

export default esapTheme;
