import moment from 'moment';

import { RESET_PROFILE_DATA, STORE_EV2_PROFILE, UPDATE_EV2_PROFILE } from '../../actions/profiledata';
import { generateProfileGrid } from '../../utility/Explore';
import { GET_PROPOSAL_DATA_SUCCESS } from '../../actions/proposals';

import { DataType, LoadProfile } from '../../types';
import { UploadDataFileActionTypes } from '../../actions';
// import { store } from '../../RootProvider';

// const startDate = store.getState().exploreDetailsMetaInfo.startDate;
// const endDate = store.getState().exploreDetailsMetaInfo.endDate;

const INITIAL_STATE: LoadProfile = generateProfileGrid(4);

export default function ev2ProfileReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case STORE_EV2_PROFILE:
      return action.payload;
    case UPDATE_EV2_PROFILE:
      const newData = state.slice();
      // eslint-disable-next-line
      for (let [row, column, _, newValue] of action.payload as any) {
        newData[row][column] = newValue;
      }
      return newData;
    case GET_PROPOSAL_DATA_SUCCESS:
      if (action.payload && action.payload.type === DataType.ev2) {
        return action.payload.data;
      }
      return state;
    case RESET_PROFILE_DATA:
      return generateProfileGrid(1);
    case UploadDataFileActionTypes.UPLOAD_LOAD_DATA_FILE_SUCCESS:
      const newStartDateTime = moment(action.payload.start);
      const newEndDateTime = moment(action.payload.start).add(1, 'y').subtract(1, 'd').endOf('day');
      let frequency = action.payload.frequency;
      if (frequency == '15min') {
        frequency = 4;
      } else {
        frequency = 1;
      }
      return generateProfileGrid(frequency, newStartDateTime, newEndDateTime, action.payload.data);
    default:
      return state;
  }
}
