import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import tooltip from '../../../tooltips/assumptions/battery';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextFieldTooltip from '../../Common/TextFieldTooltip';
import IconTooltip from '../../Common/IconTooltip';
import { AssumptionsInternal } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    block: {
      display: 'flex',
      marginTop: theme.spacing(2)
    },
    cycles: {
      marginRight: theme.spacing(2),
      width: theme.spacing(10)
    }
  })
);
interface BatteryConfigProps extends AssumptionsInternal {
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleGeneralAssumptionChange: (event: any) => void;
  handleYearlyCycleAssumptionChange: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMonthlyBidAssumptionChange: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const BatteryConfig: React.FC<BatteryConfigProps> = props => {
  const classes = useStyles();

  const { isRealtime } = props;

  return (
    <div style={{ width: '100%' }}>
      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={props.handleToggle} checked={props.isBatt} name="isBatt" />}
        label="Use Battery"
      />
      {props.isBatt && (
        <div>
          <TextFieldTooltip
            title={tooltip.powerRating}
            startAdornment={<InputAdornment position="start">kW</InputAdornment>}
            label="Power Rating"
            key="power"
            name="power"
            value={props.power}
            onChange={props.handleGeneralAssumptionChange}
            fullWidth
          />

          <TextFieldTooltip
            title={tooltip.batteryCapacity}
            label="Battery Capacity"
            startAdornment={<InputAdornment position="start">kWh</InputAdornment>}
            key="capacity"
            name="capacity"
            value={props.capacity}
            onChange={props.handleGeneralAssumptionChange}
            fullWidth
          />
          {!isRealtime && (
            <TextFieldTooltip
              title={tooltip.nameplateCapacity}
              type="number"
              label="Nameplate Capacity (optional)"
              startAdornment={<InputAdornment position="start">kWh</InputAdornment>}
              key="batteryNameplateCapacity"
              name="batteryNameplateCapacity"
              value={props.batteryNameplateCapacity}
              onChange={props.handleGeneralAssumptionChange}
              fullWidth
            />
          )}

          <TextField
            label="Charge Efficiency"
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            key="chargeEfficiency"
            name="chargeEfficiency"
            value={props.chargeEfficiency}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Discharge Efficiency"
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            key="dischargeEfficiency"
            name="dischargeEfficiency"
            value={props.dischargeEfficiency}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />

          <TextFieldTooltip
            title={tooltip.maxSOC}
            label="Maximum State Of Charge"
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
            key="socMax"
            name="socMax"
            value={props.socMax}
            onChange={props.handleGeneralAssumptionChange}
            fullWidth
          />

          <TextFieldTooltip
            title={tooltip.minSOC}
            label="Minimum State Of Charge"
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
            key="socMin"
            name="socMin"
            value={props.socMin}
            onChange={props.handleGeneralAssumptionChange}
            fullWidth
          />

          <TextField
            label="SOC Reserve"
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            key="socReserve"
            name="socReserve"
            value={props.socReserve}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />

          {!isRealtime && (
            <TextField
              label="Cost per Cycle (in USD)"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              key="cycleCost"
              name="cycleCost"
              value={props.cycleCost}
              onChange={props.handleGeneralAssumptionChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
          )}

          {props.isPV && props.isBatt && (
            <div>
              <TextFieldTooltip
                title={tooltip.percentFromSolar}
                label="Percentage of the Energy Charged from Solar"
                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                name="percentFromPV"
                key="percentFromPV"
                value={props.percentFromPV}
                onChange={props.handleGeneralAssumptionChange}
                fullWidth
              />
              {/* <FormControlLabel
                className={classes.block}
                control={<Switch onChange={props.handleToggle} checked={props.isDCCoupled} name="isDCCoupled" />}
                label="Is DC Coupled"
              /> */}
            </div>
          )}

          <FormControlLabel
            className={classes.block}
            control={<Switch onChange={props.handleToggle} checked={props.isBattExport} name="isBattExport" />}
            label={
              <>
                <span>Grid Export</span>
                <IconTooltip title={tooltip.gridExport} iconSize="small" iconSizeSmall={12} />
              </>
            }
          />
          {!isRealtime && (
            <>
              <FormControlLabel
                className={classes.block}
                control={<Switch onChange={props.handleToggle} checked={props.isPDR} name="isPDR" />}
                label="Use as Proxy Demand Resource"
              />
              {props.isPDR && (
                <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
                  <FormControlLabel
                    className={classes.block}
                    control={
                      <Switch
                        onChange={props.handleToggle}
                        checked={props.raOnlyCalculatePotentials}
                        name="raOnlyCalculatePotentials"
                      />
                    }
                    label="Only Calculate Potentials"
                  />
                  <TextField
                    label="Minimum Bid Capacity"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">kWh</InputAdornment>
                    }}
                    name="minBidCapacity"
                    key="minBidCapacity"
                    value={props.minBidCapacity}
                    onChange={props.handleGeneralAssumptionChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    margin="normal"
                  />

                  <FormControl fullWidth style={{ marginTop: 16 }}>
                    <InputLabel htmlFor="select-optimization-interval">Select RA Window</InputLabel>
                    <Select
                      value={props.selectedRAWindow}
                      name="selectedRAWindow"
                      onChange={props.handleGeneralAssumptionChange}
                      input={<Input id="select-optimization-interval" />}
                    >
                      <MenuItem key="4pm - 8pm" value="4pm - 8pm">
                        4pm - 8pm
                      </MenuItem>
                      <MenuItem key="5pm - 9pm" value="5pm - 9pm">
                        5pm - 9pm
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: 16 }}>
                    <InputLabel htmlFor="select-optimization-interval">Select Bid Inputs</InputLabel>
                    <Select
                      value={props.selectedBidInput}
                      name="selectedBidInput"
                      onChange={props.handleGeneralAssumptionChange}
                      input={<Input id="select-optimization-interval" />}
                    >
                      <MenuItem key="yearly" value="yearly">
                        Yearly ($/MWh)
                      </MenuItem>
                      <MenuItem key="monthly" value="monthly">
                        Monthly ($/MWh)
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {props.selectedBidInput == 'yearly' && (
                    <TextField
                      label="Yearly Bid Price"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      name="yearlyBid"
                      key="yearlyBid"
                      value={props.yearlyBid}
                      onChange={props.handleGeneralAssumptionChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      margin="normal"
                    />
                  )}
                  {props.selectedBidInput == 'monthly' && (
                    <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
                      {props.monthlyBids &&
                        props.monthlyBids.map((month, index) => (
                          <TextField
                            className={classes.cycles}
                            key={month.month}
                            label={month.month}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            name={month.month}
                            value={month.bids}
                            onChange={props.handleMonthlyBidAssumptionChange(index)}
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                          />
                        ))}
                    </div>
                  )}
                </div>
              )}

              <FormControlLabel
                className={classes.block}
                control={
                  <Switch
                    onChange={props.handleToggle}
                    checked={props.calculateBatteryDegradation}
                    name="calculateBatteryDegradation"
                  />
                }
                label="Calculate Monthly Degradation"
              />
              {props.calculateBatteryDegradation && (
                <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
                  <FormControlLabel
                    className={classes.block}
                    control={
                      <Switch
                        onChange={props.handleToggle}
                        checked={props.useBatteryDegradation}
                        name="useBatteryDegradation"
                      />
                    }
                    label="Apply Degradation Each Month"
                  />
                </div>
              )}

              <FormControlLabel
                className={classes.block}
                control={
                  <Switch onChange={props.handleToggle} checked={props.isForceDailyCycles} name="isForceDailyCycles" />
                }
                label="Force Daily Cycle Limit"
              />

              {props.isForceDailyCycles && (
                <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
                  <TextField
                    label="Max Daily Cycling limit"
                    key="maxDailyCycles"
                    name="maxDailyCycles"
                    value={props.maxDailyCycles}
                    onChange={props.handleGeneralAssumptionChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    margin="normal"
                  />
                </div>
              )}

              <FormControlLabel
                className={classes.block}
                control={
                  <Switch
                    onChange={props.handleToggle}
                    checked={props.isForceMonthlyCycles}
                    name="isForceMonthlyCycles"
                  />
                }
                label="Force Monthly Cycle Limit"
              />
              <FormControlLabel
                className={classes.block}
                control={
                  <Switch
                    onChange={props.handleToggle}
                    checked={props.isForceYearlyCycles}
                    name="isForceYearlyCycles"
                  />
                }
                label="Force Yearly Cycle Limit"
              />
              {props.isForceYearlyCycles && (
                <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
                  {!!props.yearlyCycles &&
                    props.yearlyCycles.map((month, index) => (
                      <TextField
                        className={classes.cycles}
                        key={month.month}
                        label={month.month}
                        name={month.month}
                        value={month.cycles}
                        onChange={props.handleYearlyCycleAssumptionChange(index)}
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                      />
                    ))}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BatteryConfig;
