import moment from 'moment';
import { STORE_LOAD_PROFILE, UPDATE_LOAD_PROFILE, RESET_PROFILE_DATA } from '../../actions/profiledata';
import { UploadDataFileActionTypes } from '../../actions/uploadDataFile';
import { POPULATE_FACILITY_LOAD_SUCCESS } from '../../actions/facility_loads';
import { generateProfileGrid } from '../../utility/Explore';
import { GET_PROPOSAL_DATA_SUCCESS } from '../../actions/proposals';
import { GET_METER_INTERVAL_SUCCESS } from '../../actions/utilityapi';

const INITIAL_STATE = generateProfileGrid(4);

export default function loadProfileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STORE_LOAD_PROFILE:
      return action.payload;
    case UPDATE_LOAD_PROFILE:
      const newData = state.slice();
      // eslint-disable-next-line
      for (let [row, column, oldValue, newValue] of action.payload) {
        newData[row][column] = newValue;
      }
      return newData;
    case GET_PROPOSAL_DATA_SUCCESS:
      if (action.payload && action.payload.type === 'load') {
        return action.payload.data;
      }
      return state;
    case GET_METER_INTERVAL_SUCCESS:
      return action.payload;
    case RESET_PROFILE_DATA:
      return generateProfileGrid(4);
    case UploadDataFileActionTypes.UPLOAD_LOAD_DATA_FILE_SUCCESS:
      const newStartDateTime = moment(action.payload.start);
      const newEndDateTime = moment(action.payload.start).add(1, 'y').subtract(1, 'd').endOf('day');
      let frequency = action.payload.frequency;
      if (frequency == '15min') {
        frequency = 4;
      } else {
        frequency = 1;
      }
      return generateProfileGrid(frequency, newStartDateTime, newEndDateTime, action.payload.data);
    // return action.payload.data.map((value, index) => {
    //   const newDateTime = moment(action.payload.start)
    //     .startOf('day')
    //     .add(15 * index, 'minutes');
    //   return [newDateTime.format('MM/DD/YYYY'), newDateTime.format('HH:mm'), value];
    // });
    case POPULATE_FACILITY_LOAD_SUCCESS:
      const startDate = state[0][0];
      const endDate = state[state.length - 1][0];
      const freq = 4;
      return generateProfileGrid(freq, startDate, endDate, action.payload);
    default:
      return state;
  }
}
