import React, { useState, useEffect } from 'react';

// MATERIAL UI IMPORTS
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PDPForm from './PDPForm';
import PDPEventForm from './PDPEventForm';
import DateRange from '@material-ui/icons/DateRange';
import Save from '@material-ui/icons/Save';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import AccessControl from '../../utility/AccessControl';
import { CalendarEvent, CalendarResult, PDPInformation, UtilityInfo } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../reducers';
import { set } from 'immutable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    container: {
      padding: 20
    },
    flex: {
      flex: 1
    },
    toolbar: {
      // borderBottom: '1px solid #e5e5e5'
    },
    button: {
      margin: theme.spacing(1)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    item: {
      paddingRight: 15
    },
    chipPrimary: {
      margin: theme.spacing(1) / 2,
      backgroundColor: 'blue',
      color: '#fff',
      '&:focus, &:visited, &:hover': {
        backgroundColor: 'blue',
        color: '#fff'
      }
    },
    chipDefault: {
      margin: theme.spacing(1) / 2
    }
  })
);

interface PDPContainerProps {
  handleUpdateUtilityInfo: (utilityInfo: UtilityInfo) => void;
  handleCreateUtilityInfo: (utilityInfo: any) => void;
}

const PDPContainer: React.FC<PDPContainerProps> = ({ handleUpdateUtilityInfo, handleCreateUtilityInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const utility = useSelector((state: StoreState) => state.utility);

  const [state, setState] = useState({
    newDate: '',
    dates: [] as string[],
    importedDates: [] as string[],
    selected: [] as CalendarResult[],
    value: 0,
    isAdd: false,
    pdp: {} as PDPInformation[],
    calendars: [] as CalendarResult[]
  });

  useEffect(() => {
    if (utility.utilityInfo?.event_dates) {
      setState(state => ({ ...state, dates: utility.utilityInfo.event_dates }));
    }
  }, [utility.utilityInfo?.event_dates]);

  useEffect(() => {
    if (utility.calendars) {
      setState(state => ({ ...state, calendars: utility.calendars }));
    }
  }, [utility.calendars]);

  useEffect(() => {
    if (utility.utilityInfo?.pdp_information) {
      setState(state => ({ ...state, pdp: utility.utilityInfo.pdp_information }));
    }
  }, [utility.utilityInfo?.pdp_information]);

  const handleAddClick = () => {
    setState({ ...state, isAdd: !state.isAdd });
  };

  const buildDateListFromCalendarSelection = (calendars: CalendarResult[]): string[] => {
    // This function is broken - Genability API must have changed - as the events property is
    // not available anymore from getCalendars, only from getCalendar
    const data: CalendarEvent[] = [];
    calendars.forEach(calendar => {
      if (Array.isArray(calendar?.events)) {
        calendar.events.forEach(event => {
          if (data.indexOf(event) === -1) {
            data.push(event);
          }
        });
      }
    });
    const dates: string[] = [];
    data.forEach(event => {
      if (
        event.calendarEventName.indexOf('2018') > -1 ||
        event.calendarEventName.indexOf('2017') > -1 ||
        event.calendarEventName.indexOf('2016')
      ) {
        dates.push(event.calendarEventName.toLowerCase().replace('peak day pricing event ', ''));
      }
    });
    return dates;
  };

  const updateCalendarSelection = (calendar: CalendarResult) => () => {
    const selectedIndex = state.selected.indexOf(calendar);
    let selected;
    if (selectedIndex > -1) {
      // process dates
      selected = state.selected.slice(0, selectedIndex).concat(state.selected.slice(selectedIndex + 1));
    } else {
      selected = state.selected.concat([calendar]);
    }
    const updatedDates = buildDateListFromCalendarSelection(selected);
    setState({ ...state, selected: selected, importedDates: updatedDates });
  };

  const isCalendarSelected = (calendar: CalendarResult) => {
    return state.selected.indexOf(calendar) > -1;
  };

  const handleDateChange = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const handleDateAdd = () => {
    setState({
      ...state,
      dates: [state.newDate, ...state.dates],
      newDate: ''
    });
  };

  const handleDateDelete = (index: number) => {
    setState({ ...state, dates: state.dates.slice(0, index).concat(state.dates.slice(index + 1)) });
  };

  const handleInputFormChange = (index: number) => (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const pdp_information = utility.utilityInfo.pdp_information.slice();

    const updated_pdp_info = set(pdp_information, index, {
      ...pdp_information[index],
      [event.target.name]: event.target.value
    });

    dispatch({ type: 'PDP_FORM_CHANGE', payload: updated_pdp_info });
  };

  // handleInputFormChangeUtilities = (updatedPDP) => {
  //    if(updatedPDP.title){
  //      utility.utilityInfo
  //    }
  //    const updatedPDP = {  [event.target.name] : event.target.value };
  //    // need to do more work with this one. Do a comparison on the title of the pdpInfo and update it that way
  //    handleInputFormChangeUtilities(updatedPDP);
  // }

  const deletePDPInfo = (index: number) => () => {
    const utilityInfoUpdated = { ...utility.utilityInfo };
    utilityInfoUpdated.pdp_information.splice(index, 1);
    handleUpdateUtilityInfo(utilityInfoUpdated);
  };

  // const handleNewInputFormChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   setState({ ...state, pdp: { ...state.pdp, [event.target.name]: event.target.value } });
  // };

  const handleSavePDPInfo = () => {
    if (utility.utilityInfo) {
      const utilityInfoUpdated = { ...utility.utilityInfo };
      if (utilityInfoUpdated.pdp_information) {
        utilityInfoUpdated.pdp_information = state.pdp;
      }
      handleUpdateUtilityInfo(utilityInfoUpdated);
    } else {
      handleCreateUtilityInfo({
        lseId: utility.utilityDetails.lseId,
        pdpInformation: state.pdp
      });
    }
  };

  // TODO: temporary uncommented bc I am not sure what it does
  const importUtilityDates = () => {
    // if (utilityDetails?.lseId) {
    //   dispatch(getCalendars(utilityDetails.lseId, 'PRICING_PERIOD'));
    // }
  };

  const handleSaveEventDates = () => {
    if (utility.utilityInfo && Object.keys(utility.utilityInfo).length > 0) {
      const utilityInfoUpdated = { ...utility.utilityInfo };
      utilityInfoUpdated.event_dates = state.dates;
      handleUpdateUtilityInfo(utilityInfoUpdated);
    } else {
      handleCreateUtilityInfo({
        lseId: utility.utilityDetails.lseId,
        eventDates: state.dates
      });
    }
  };

  // const handlePDPConfigurationPreparation = () => {
  //   const pdpInfo = utility.pdp;
  //   const info = {
  //     PDPPENALTY: pdpInfo.penalty,
  //     PDPPEAKCREDIT: pdpInfo.peakCredit,
  //     PDPMIDPEAKCREDIT: pdpInfo.midPeakCredit,
  //     PDPRESERVATIONPRICE: pdpInfo.reservationPrice,
  //     PEAKMONTHS: pdpInfo.peakMonthStart + ':' + pdpInfo.peakMonthEnd,
  //     MIDPEAKMONTHS: pdpInfo.midPeakMonthStart + ':' + pdpInfo.midPeakMonthEnd
  //   };
  //   const events = state.dates.map(date => {
  //     return {
  //       DATE: date,
  //       HOURS: pdpInfo.eventStartHour + ':' + pdpInfo.eventEndHour
  //     };
  //   });
  //   const pdp = {
  //     INFO: info,
  //     EVENTS: { EVENT: events }
  //   };
  //   handleSave(pdp);
  // };

  const handleChangeTab = (event: React.ChangeEvent<{}>, value: number) => {
    setState({ ...state, value });
  };

  return (
    <Paper>
      <Tabs value={state.value} onChange={handleChangeTab}>
        <Tab label="Information" />
        <Tab label="Events" />
      </Tabs>

      {state.value === 0 && (
        <div className={classes.root}>
          <div className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <Typography color="inherit" variant="h6" className={classes.flex}>
                PDP Groupings
              </Typography>
              <IconButton
                size="small"
                color="primary"
                aria-label="Add Item"
                className="button-white"
                onClick={handleAddClick}
              >
                <Add />
              </IconButton>
            </Toolbar>
            {state.isAdd && (
              <div>
                Need to fix that type error in props
                {/* <PDPForm pdp={state.pdp} handlePDPChange={handleNewInputFormChange} isEdit={true} />
                <div style={{ textAlign: 'right', marginTop: 10 }}>
                  <Button variant="contained" size="small" className="button-white" onClick={handleSavePDPInfo}>
                    Save
                  </Button>
                </div> */}
              </div>
            )}
            {/* {!state.isAdd && ( */}
            <div>
              {utility.utilityInfo.pdp_information &&
                utility.utilityInfo.pdp_information.map((info, index) => {
                  return (
                    <Accordion
                      key={index.toString()}
                      style={{
                        boxShadow: 'none',
                        border: '1px solid #eee',
                        marginTop: 5
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{info.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <PDPForm index={index} handlePDPChange={handleInputFormChange(index)} />
                      </AccordionDetails>
                      <AccessControl requiredPermissions={['editor', 'admin']}>
                        <AccordionActions>
                          <Button size="small" color="secondary" onClick={deletePDPInfo(index)}>
                            Delete
                          </Button>
                          <Button size="small" color="primary" onClick={handleSavePDPInfo}>
                            Save
                          </Button>
                        </AccordionActions>
                      </AccessControl>
                    </Accordion>
                  );
                })}
            </div>
            {/* )} */}
          </div>
        </div>
      )}

      {state.value === 1 && (
        <div className={classes.root}>
          <div className={classes.container}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} className={classes.item}>
                <Toolbar>
                  <Typography color="inherit" variant="h6" className={classes.flex}>
                    Events
                  </Typography>
                  <IconButton size="small" color="default" aria-label="Save Dates" onClick={handleSaveEventDates}>
                    <Save />
                  </IconButton>
                </Toolbar>
                <PDPEventForm
                  dates={state.dates}
                  newDate={state.newDate}
                  handleDelete={handleDateDelete}
                  handleAdd={handleDateAdd}
                  handleChange={handleDateChange}
                />
              </Grid>
              {/* TODO: Events imports from Genability seem broken as their API has changed and we cant access the
              events anymore with GET calendars, only GET calendar. So it has to be re-created but lets make sure
              Valuation team needs this */}
              <Grid item xs={12} sm={6} md={6} className={classes.item}>
                <Toolbar>
                  <Typography color="inherit" variant="h6" className={classes.flex}>
                    Utility Dynamic Pricing Calendars
                  </Typography>
                  {(!utility.calendars || utility.calendars.length === 0) && (
                    <IconButton size="small" color="primary" aria-label="Import Calendars" onClick={importUtilityDates}>
                      <DateRange />
                    </IconButton>
                  )}
                </Toolbar>

                {state.calendars &&
                  state.calendars.map(calendar => {
                    return (
                      <Chip
                        key={calendar.calendarId}
                        onClick={updateCalendarSelection(calendar)}
                        label={calendar.calendarName}
                        className={isCalendarSelected(calendar) ? classes.chipPrimary : classes.chipDefault}
                      />
                    );
                  })}

                <List dense={true}>
                  {state.importedDates.map((date, index) => {
                    return <ListItem key={index}>{date}</ListItem>;
                  })}
                </List>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default PDPContainer;
