import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import { exportCSVFile } from '../../../utility/General';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Select from '@material-ui/core/Select';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
const HeatmapPlot = createPlotlyComponent(Plotly);

const styles = {
  block: {
    marginTop: 8,
    marginBottom: 8
  }
};

const MOERHeatmap = props => {
  const { monthlyOperationsData } = props;
  const [months, setMonths] = useState([]);
  const [data, setData] = useState([]);
  const [heatmapData, setHeatmapData] = useState({});
  const [heatmapKey, setHeatmapKey] = useState();
  const [selected, setSelected] = useState();

  const buildLayout = () => {
    const layout = {
      legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
      height: 280,
      margin: {
        t: 20,
        l: 80,
        r: 20,
        b: 40
      },
      xaxis: {
        // tickangle: -45
      },
      barmode: 'group'
    };

    return layout;
  };

  const handleChange = event => {
    setHeatmapKey(event.target.value);
    setSelected(event.target.value);
  };

  const transformData = heatmapData => {
    let x_axis = Object.keys(heatmapData[0]);
    let y_axis = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let z_axis = [];
    Object.keys(heatmapData).forEach(x_key => {
      const z_contribution = [];
      Object.keys(heatmapData[x_key]).forEach(y_key => {
        z_contribution.push(heatmapData[x_key][y_key].average);
      });
      z_axis.push(z_contribution);
    });
    return [
      {
        z: z_axis,
        x: x_axis,
        y: y_axis,
        type: 'heatmap'
      }
    ];
  };

  useEffect(() => {
    if (monthlyOperationsData && Object.keys(monthlyOperationsData).length > 0) {
      const x = Object.keys(monthlyOperationsData);
      const firstMonth = x[0];
      const moerAverages = [];
      x.forEach(monthKey => {
        const moerMonthlyTotal = monthlyOperationsData[monthKey]['mef'].reduce((a, b) => a + b, 0);
        moerAverages.push(moerMonthlyTotal / monthlyOperationsData[monthKey]['mef'].length);
      });

      let updatedData = [];
      updatedData.push({
        type: 'bar',
        name: 'MOER',
        x: x,
        y: moerAverages,
        marker: {
          color: 'rgb(0, 91, 187)'
        }
      });
      setData(updatedData);
      let heatmapMonthlyResults = {};
      x.forEach(monthKey => {
        let startDate = moment(monthKey, 'YYYY-MM');
        const scenarioMonthlyData = monthlyOperationsData[monthKey];
        let heatmap = {};
        for (let i = 0; i < 7; i++) {
          heatmap[i] = {};
          for (let j = 0; j < 24; j++) {
            heatmap[i][j] = {
              total: 0,
              count: 0
            };
          }
        }
        for (let i = 0; i < scenarioMonthlyData.mef.length; i++) {
          const hourOfDay = startDate.hour();
          const dayOfWeek = startDate.day();
          heatmap[dayOfWeek][hourOfDay]['total'] += scenarioMonthlyData.mef[i];
          heatmap[dayOfWeek][hourOfDay]['count'] += 1;
          startDate.add(15, 'minutes');
        }
        for (let i = 0; i < 7; i++) {
          for (let j = 0; j < 24; j++) {
            heatmap[i][j]['average'] =
              heatmap[i][j]['count'] == 0 ? 0 : heatmap[i][j]['total'] / heatmap[i][j]['count'];
          }
        }
        heatmapMonthlyResults[monthKey] = heatmap;
      });
      const months = Object.keys(monthlyOperationsData).map(dateKey => {
        return { title: monthlyOperationsData[dateKey].month, key: dateKey };
      });
      setMonths(months);
      setSelected(months[0].key);
      setHeatmapData(heatmapMonthlyResults);
      setHeatmapKey(firstMonth);
    }
    // eslint-disable-next-line
  }, [monthlyOperationsData]);

  return (
    <Paper>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          Marginal Operating Emissions Rate Heatmap
        </Typography>
        <FormControl>
          <Select
            value={selected}
            onChange={handleChange}
            inputProps={{
              name: 'selected'
            }}
          >
            {months.map(month => {
              return (
                <MenuItem key={month.key} value={month.key}>
                  {month.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Toolbar>
      {/*
      <div style={{ height: 300 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={data}
          layout={buildLayout()}
          config={{ displayModeBar: false }}
        />
      </div> */}
      {heatmapKey && Object.keys(heatmapData).length > 0 && (
        <div style={{ height: 300 }}>
          <HeatmapPlot
            style={{ width: '100%' }}
            useResizeHandler={true}
            data={transformData(heatmapData[heatmapKey])}
            layout={buildLayout()}
            config={{ displayModeBar: false }}
          />
        </div>
      )}
    </Paper>
  );
};

MOERHeatmap.propTypes = {
  monthlyOperationsData: PropTypes.object.isRequired
};

export default withStyles(styles)(MOERHeatmap);
