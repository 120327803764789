import { AssumptionsInternal, TemplateEV } from '../../types';

export const evProfilesTemplates: Record<TemplateEV, Partial<AssumptionsInternal>> = {
  hospital: {
    evShifts: [
      {
        id: 1,
        gamma: 0.9,
        evArrivalRange: [8, 22] as [number, number],
        muChargingPrd: 6,
        muChargingPrdStdDeviation: 0.6,
        Smax: 30,
        evseUtilisationRate: 0,
        isOvernight: false
      }
    ]
  },
  officeBuilding: {
    Rmax: 7.2,
    evWeekendUtilization: 0.1,
    evShifts: [
      {
        id: 1,
        gamma: 0.9,
        evArrivalRange: [7, 9] as [number, number],
        muChargingPrd: 9,
        muChargingPrdStdDeviation: 0.6,
        Smax: 70,
        evseUtilisationRate: 80,
        isOvernight: false
      },
      {
        id: 2,
        gamma: 0.9,
        evArrivalRange: [6, 7] as [number, number],
        muChargingPrd: 9,
        muChargingPrdStdDeviation: 0.6,
        Smax: 70,
        evseUtilisationRate: 15,
        isOvernight: false
      },
      {
        id: 3,
        gamma: 0.9,
        evArrivalRange: [12, 16] as [number, number],
        muChargingPrd: 2,
        muChargingPrdStdDeviation: 0.6,
        Smax: 70,
        evseUtilisationRate: 10,
        isOvernight: false
      }
    ]
  },
  shippingWarehouse: {
    evShifts: [
      {
        id: 1,
        gamma: 0.9,
        evArrivalRange: [8, 10] as [number, number],
        muChargingPrd: 6,
        muChargingPrdStdDeviation: 0.6,
        Smax: 30,
        evseUtilisationRate: 0,
        isOvernight: false
      },
      {
        id: 2,
        gamma: 0.9,
        evArrivalRange: [8, 10] as [number, number],
        muChargingPrd: 6,
        muChargingPrdStdDeviation: 0.6,
        Smax: 30,
        evseUtilisationRate: 0,
        isOvernight: false
      }
    ]
  },
  none: {
    Rmax: 5,
    eta: 0.922,
    evShifts: [
      {
        id: 1,
        gamma: 0.9,
        evArrivalRange: [8, 10] as [number, number],
        muChargingPrd: 6,
        muChargingPrdStdDeviation: 0.6,
        Smax: 30,
        evseUtilisationRate: 0,
        isOvernight: false
      },
      {
        id: 2,
        gamma: 0.9,
        evArrivalRange: [8, 10] as [number, number],
        muChargingPrd: 6,
        muChargingPrdStdDeviation: 0.6,
        Smax: 30,
        evseUtilisationRate: 0,
        isOvernight: false
      },
      {
        id: 3,
        gamma: 0.9,
        evArrivalRange: [8, 10] as [number, number],
        muChargingPrd: 6,
        muChargingPrdStdDeviation: 0.6,
        Smax: 30,
        evseUtilisationRate: 0,
        isOvernight: false
      }
    ],
    maxEVFleetDemand: 1000000
  }
};
