import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ResourceOverviewChartOption as ChartOption } from '../../../types';
import { ChromePicker, ColorResult } from 'react-color';

// MATERIAL UI Imports
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Panorama from '@material-ui/icons/Panorama';
import ShowChart from '@material-ui/icons/ShowChart';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles({
  popover: { position: 'relative', zIndex: 2 }
});

interface ChartSettingsProps {
  open: boolean;
  handleClose: () => void;
  editChartOptions: ChartOption[];
  handleColorChangeComplete: (color: string, index: number) => void;
  handleMoveItem: (upDirection: boolean, item: ChartOption) => () => void;
  handleChartTextUpdate: (item: ChartOption) => (event: any) => void;
  handleShowChartItem: (item: ChartOption) => () => void;
  handleChartLineType: (item: ChartOption) => () => void;
  showTOU: boolean;
  handleToggle: (e: any) => void;
  showPeaksBefore: boolean;
  showPeaksAfter: boolean;
  showAnnotations: boolean;
  hideHoverInfo: boolean;
  TOUOpacity: number;
  handleValueChange: (e: any) => void;
  customRangeMin: string;
  customRangeMax: string;
  handleSuccessClose: () => void;
  handleStackChartItem: (item: ChartOption) => () => void;
  flipChargeDischarge: boolean;
  chartLayout: 'microgrid' | 'none';
  handleChangeLayout: (e: any) => void;
  toggleChartConfig: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ChartSettings: React.FC<ChartSettingsProps> = props => {
  const classes = useStyles();
  const colorRef: any = React.useRef();

  const [colorOpen, setColorOpen] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState<string>();
  const [selectedIndex, setSelectedIndex] = React.useState<number>();

  const openPicker = (color: string, index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    colorRef.current = e.target;
    setSelectedColor(color);
    setSelectedIndex(index);
    setColorOpen(true);
  };

  const closePicker = () => {
    setColorOpen(false);
  };

  const onColorComplete = (color: ColorResult) => {
    setSelectedColor(color.hex);
    if (selectedIndex !== undefined) {
      props.handleColorChangeComplete(color.hex, selectedIndex);
    }
  };

  return (
    <>
      <Popover
        anchorEl={colorRef.current}
        open={colorOpen}
        onClose={closePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <ChromePicker color={selectedColor} onChangeComplete={onColorComplete} />
      </Popover>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={false}
        onBackdropClick={props.handleClose}
      >
        <DialogTitle id="form-dialog-title" disableTypography>
          <Toolbar>
            <Typography variant="h6" style={{ flex: 1 }}>
              Chart Settings
            </Typography>
            <FormControl style={{ width: 120 }}>
              <InputLabel id="chart-layout-select-label">Chart Layout</InputLabel>
              <Select
                labelId="chart-layout-select-label"
                id="chart-layout-select"
                data-testid="layoutSelector"
                value={props.chartLayout}
                onChange={props.handleChangeLayout}
              >
                <MenuItem data-testid="layout-none" value="none">
                  None
                </MenuItem>
                <MenuItem data-testid="layout-microgrid" value="microgrid">
                  Microgrid
                </MenuItem>
              </Select>
            </FormControl>
          </Toolbar>
        </DialogTitle>

        <DialogContent>
          <Typography>Data Streams</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Show</TableCell>
                <TableCell>Stack</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Opacity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.editChartOptions.map((dataItem, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <IconButton
                      aria-label="Move Up in Order"
                      onClick={props.handleMoveItem(true, dataItem)}
                      style={{
                        height: 24,
                        width: 24
                      }}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                    <IconButton
                      aria-label="Move Down in Order"
                      onClick={props.handleMoveItem(false, dataItem)}
                      style={{
                        height: 24,
                        width: 24
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={dataItem.label}
                      name="label"
                      onChange={props.handleChartTextUpdate(dataItem)}
                      margin="dense"
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={dataItem.include}
                      onClick={props.handleShowChartItem(dataItem)}
                      inputProps={
                        {
                          'data-testid': 'show-checkbox'
                        } as any
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={dataItem.stacked} onClick={props.handleStackChartItem(dataItem)} />
                  </TableCell>
                  <TableCell>
                    {dataItem.fill !== 'tozeroy' && (
                      <IconButton
                        aria-label="Line Chart"
                        style={{
                          height: 24,
                          width: 24
                        }}
                        onClick={props.handleChartLineType(dataItem)}
                      >
                        <ShowChart />
                      </IconButton>
                    )}
                    {dataItem.fill === 'tozeroy' && (
                      <IconButton
                        aria-label="Area Chart"
                        style={{
                          height: 24,
                          width: 24
                        }}
                        onClick={props.handleChartLineType(dataItem)}
                      >
                        <Panorama />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <div
                      ref={colorRef}
                      className={classes.popover}
                      onClick={openPicker(dataItem.color, index)}
                      style={{
                        width: 25,
                        height: 25,
                        backgroundColor: dataItem.color,
                        borderRadius: 5
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: 0.1
                      }}
                      type="number"
                      value={dataItem.opacity}
                      name="opacity"
                      onChange={props.handleChartTextUpdate(dataItem)}
                      margin="dense"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br />
          <Typography>Additional</Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.flipChargeDischarge}
                  onClick={props.toggleChartConfig}
                  name="flipChargeDischarge"
                  data-testid="flip-battery-charge-toggle"
                />
              }
              label="Flip Charge/Discharge"
            />
            <FormControlLabel
              control={<Checkbox checked={props.showTOU} onClick={props.toggleChartConfig} name="showTOU" />}
              label="Show Time Of Use"
            />
            <FormControlLabel
              control={
                <Checkbox checked={props.showPeaksBefore} onClick={props.toggleChartConfig} name="showPeaksBefore" />
              }
              label="Show Peaks Before"
            />
            <FormControlLabel
              control={
                <Checkbox checked={props.showPeaksAfter} onClick={props.toggleChartConfig} name="showPeaksAfter" />
              }
              label="Show Peaks After"
            />
            <FormControlLabel
              control={
                <Checkbox checked={props.showAnnotations} onClick={props.toggleChartConfig} name="showAnnotations" />
              }
              label="Show Annotations"
            />
            <FormControlLabel
              control={
                <Checkbox checked={props.hideHoverInfo} onClick={props.toggleChartConfig} name="hideHoverInfo" />
              }
              label="Hide Hover Info"
            />

            <FormControlLabel
              style={{
                marginLeft: 24
              }}
              control={
                <TextField
                  style={{
                    marginLeft: 8
                  }}
                  inputProps={{
                    min: 0,
                    max: 1,
                    step: 0.1
                  }}
                  type="number"
                  name="TOUOpacity"
                  value={props.TOUOpacity}
                  onChange={props.handleValueChange}
                />
              }
              label="TOU Opacity  "
              labelPlacement="start"
            />
            <FormControlLabel
              style={{
                marginLeft: 24
              }}
              control={
                <TextField
                  style={{
                    marginLeft: 8,
                    width: 100
                  }}
                  name="customRangeMin"
                  value={props.customRangeMin}
                  onChange={props.handleValueChange}
                />
              }
              label="Custom Y-Range Min  "
              labelPlacement="start"
            />
            <FormControlLabel
              style={{
                marginLeft: 24
              }}
              control={
                <TextField
                  style={{
                    marginLeft: 8,
                    width: 100
                  }}
                  name="customRangeMax"
                  value={props.customRangeMax}
                  onChange={props.handleValueChange}
                />
              }
              label="Custom Y-Range Max  "
              labelPlacement="start"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleSuccessClose} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChartSettings;
