import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateEnergyChargeComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before Energy Cost Peak',
    label: 'Before Energy Cost Peak',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Cost Peak',
    label: 'After Energy Cost Peak',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before Energy Cost MidPeak',
    label: 'Before Energy Cost MidPeak',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Cost MidPeak',
    label: 'After Energy Cost MidPeak',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before Energy Cost OffPeak',
    label: 'Before Energy Cost OffPeak',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Cost OffPeak',
    label: 'After Energy Cost OffPeak',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before Energy Cost Total',
    label: 'Before Energy Cost Total',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Cost Total',
    label: 'After Energy Cost Total',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Total Energy Savings',
    label: 'Total Energy Savings',
    include: true,
    highlight: false,
    category: 'Totals'
  });
  chartOptions.push({
    key: 'Total Demand Savings',
    label: 'Total Demand Savings',
    include: false,
    highlight: false,
    category: 'Totals'
  });
  chartOptions.push({
    key: 'Total Savings',
    label: 'Total Savings',
    include: false,
    highlight: false,
    category: 'Totals'
  });

  return chartOptions;
};
