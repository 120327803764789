import * as R from 'ramda';
import { ResourceOverviewChartOption, ChartOptionName } from '../../../../types';

export type ChartLayout = 'microgrid' | 'none';

export interface LayoutConfig {
  show: ChartOptionName[];
  stack: ChartOptionName[];
  order: ChartOptionName[];
}

export const getLayoutConfig = (layout: ChartLayout): LayoutConfig => {
  switch (layout) {
    case 'microgrid':
      return {
        show: [
          'Current Demand',
          'Net Demand',
          'Genset Power',
          'Battery Charge',
          'Battery Discharge',
          'Load Curtailment',
          'PV Power'
        ],
        stack: ['Genset Power', 'Battery Discharge', 'PV Power'],
        order: [
          'Current Demand',
          'Load Net Solar',
          'Net Demand',
          'Battery SOC',
          'Genset Power',
          'Battery Charge',
          'Battery Discharge',
          'Load Curtailment',
          'PV Power',
          'PV Curtailed',
          'PV Used Power',
          'Net Export'
        ]
      };
    default:
      return { show: [], stack: [], order: [] };
  }
};

export const applyLayout = (
  layout: ChartLayout,
  options: ResourceOverviewChartOption[],
  defaultOptionsFactory?: () => ResourceOverviewChartOption[]
): ResourceOverviewChartOption[] => {
  let updatedOptions = [...options];
  const config = getLayoutConfig(layout);
  const sort = sortOptionsByName(config.order);

  switch (layout) {
    case 'microgrid':
      updatedOptions.forEach(option => {
        option.include = config.show.indexOf(option.name) > -1;
        option.stacked = config.stack.indexOf(option.name) > -1;
      });
      return sort(updatedOptions);
    default:
      if (defaultOptionsFactory) return defaultOptionsFactory();
      updatedOptions.forEach(option => {
        option.include = true;
        option.stacked = false;
      });
      return updatedOptions;
  }
};

export const sortOptionsByName = (order: string[]) => (
  options: ResourceOverviewChartOption[]
): ResourceOverviewChartOption[] => {
  if (!order) return options;

  let orderedOptions: ResourceOverviewChartOption[] = [];

  order.forEach(name => {
    const option = options.find(col => col.name === name);
    if (option) {
      orderedOptions.push(option);
    }
  });

  // makes sure no options were left out, if so put them at the end of the array
  const diff = R.difference(options, orderedOptions);
  orderedOptions = diff.length > 0 ? [...orderedOptions, ...diff] : orderedOptions;

  // and if still we changed the options length on the way, throw an error
  if (options.length !== orderedOptions.length) {
    throw new Error(
      `the chart options dont have the same length after sorting.\n In: ${options}|n Out: ${orderedOptions} `
    );
  }

  return orderedOptions;
};
