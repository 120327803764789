import { store } from '../../../RootProvider';
// import { StoreState } from '../../../reducers';
import { createTemplateScenariosAndSave, saveTemplate } from '../../../actions/templates';
import { assumptionDefaults } from '../../../utility/Assumption';

import { ScenarioInternal, ProposalInternal } from '../../../types';
// TODO: test in TS playground
//TODO Fill in the assumption keys and fields required
//TODO check how a scenario is created in the code

// TODO check the shape of a scenario create payload

export enum ScenarioKeys {
  solarOnlyStandardTariff = 'solarOnlyStandardTariff',
  solarOnlySolarTariff = 'solarOnlySolarTariff',
  standardStatusQuo = 'standardStatusQuo',
  solarTariffStatusQuo = 'solarTariffStatusQuo',
  standardStorageNonForced = 'standardStorageNonForced',
  standardStorageForced = 'standardStorageForced',
  solarStorageNonForced = 'solarStorageNonForced',
  solarStorageForced = 'solarStorageForced'
}

export enum VariableKey {
  power = 'power',
  capacity = 'capacity',
  tariffStatusQuo = 'tariffStatusQuo',
  tariffSolar = 'tariffSolar',
  market = 'market',
  isNetMetering = 'isNetMetering',
  isBatt = 'isBatt',
  isPV = 'isPV',
  exportCost = 'exportCost',
  pInstPV = 'pInstPV',
  percentFromPV = 'percentFromPV'
}

export enum AssumptionKey {
  isNetMetering = 'isNetMetering',
  isBatt = 'isBatt',
  isPV = 'isPV',
  pInstPV = 'pInstPV',
  exportCost = 'exportCost',
  selectedMarket = 'selectedMarket',
  tariffId = 'tariffId',
  percentFromPV = 'percentFromPV',
  power = 'power',
  capacity = 'capacity'
}

export interface Field {
  display_name: string;
  assumption_key: string;
  variable_key: VariableKey;
}

interface Constant {
  assumption_key: AssumptionKey;
  value: AssumptionValue;
  variable_key: VariableKey;
}

export interface ScenarioTemplate {
  key: ScenarioKeys;
  name: string;
  assumptions_required: { [assumption_key in AssumptionKey]?: VariableKey }[];
  reference_id: string | undefined;
  public_id?: string;
}

export type AssumptionValue = number | string | boolean | null;

export interface EquipmentValues {
  power: string;
  capacity: string;
  pInstPV: string;
  tariffStatusQuo: string;
  tariffSolar?: string;
}

export enum TemplateName {
  solarPlusStorageCAISO = 'Solar plus Storage CAISO',
  solarPlusStorageCAISOSolarTariff = 'Solar plus Storage CAISO - Option R Tariff'
}

export abstract class Template {
  abstract public_id: string | null;
  abstract constants: Constant[];
  abstract name: TemplateName;
  abstract description: string;
  abstract scenario_templates: ScenarioTemplate[];
  abstract fields: Field[];

  public getFields(): Field[] {
    return this.fields;
  }

  public createBlankTemplate(proposalId: string, currentTemplate: Template) {
    store.dispatch(saveTemplate(proposalId, currentTemplate));
  }

  private getNonBypassableRate = (proposal: ProposalInternal, tariffId: string): number => {
    const tariff = proposal?.proposal_tariffs?.find(tariff => tariff.public_id === tariffId);
    return tariff && tariff.overview && tariff.overview.nonBypassableRate ? tariff.overview.nonBypassableRate : 0;
  };

  public generateScenarios(proposalId: string, equipmentValues: EquipmentValues, currentTemplate: Template): void {
    const templateScenarios: ScenarioInternal[] = [];

    const currentStore: any = store.getState();

    const proposal: ProposalInternal = currentStore.proposal;

    // lets build the required scenarios using the scenario templates
    this.scenario_templates.forEach((scenarioTemplate, index) => {
      // update using equipment values
      const mappedEquipmentValues = Object.keys(equipmentValues).reduce((acc, curr) => {
        const targetAssumption = scenarioTemplate.assumptions_required.find(obj => obj[Object.keys(obj)[0]] === curr);
        if (targetAssumption) {
          acc = {
            ...acc,
            [Object.keys(targetAssumption)[0]]: equipmentValues[curr]
          };
        }
        return acc;
      }, {});

      //update assumptions using template constants
      let scenarioAssumptions = this.constants.reduce((acc, constant) => {
        if (
          scenarioTemplate.assumptions_required.some(key => {
            return Object.keys(key)[0] === constant.variable_key;
          })
        ) {
          acc = { ...acc, [constant.assumption_key]: constant.value };
        }
        return acc;
      }, {});

      const updateAssumptions = {
        ...assumptionDefaults,
        ...scenarioAssumptions,
        ...mappedEquipmentValues
      };

      let scenario: Partial<ScenarioInternal> = {
        results: null,
        public_id: '',
        proposal_id: proposalId,
        name: scenarioTemplate.key.toLowerCase().includes('storage')
          ? `${scenarioTemplate.name} - ${equipmentValues.power} kW // ${equipmentValues.capacity} kWh`
          : scenarioTemplate.name,
        assumptions: updateAssumptions,
        active: true
      };

      if (scenario.assumptions && scenario.assumptions.isNetMetering && scenario.assumptions.tariffId) {
        scenario.assumptions.exportCost = this.getNonBypassableRate(proposal, scenario.assumptions.tariffId);
      }

      if (
        index === 0 &&
        proposal &&
        proposal.proposal_scenarios &&
        !proposal.proposal_scenarios.some(scenario => scenario.is_status_quo)
      ) {
        scenario.is_status_quo = true;
      }
      templateScenarios.push(scenario as ScenarioInternal);
    });

    //store.dispatch(createProposalScenario(templateScenarios, proposalId));
    store.dispatch(createTemplateScenariosAndSave(templateScenarios, proposalId, currentTemplate));
  }
}

/**** CAISO Solar plus Storage same tariff ****/
export class StandardSolarStorageCAISOTemplate extends Template {
  public_id: null;
  constants: Constant[];
  name: TemplateName;
  description: string;
  scenario_templates: ScenarioTemplate[];
  fields: Field[];

  constructor() {
    super();
    this.name = TemplateName.solarPlusStorageCAISO;
    this.description = `This template is for the Californian market. It produces 5 different scenarios
    based on a standard tariff and compare the savings in a result matrix :
    - status quo
    - solar only
    - forcecharged storage + solar
    -non-forcecharged storage + solar
    `;

    /** Template level Assumptions */
    this.constants = [
      {
        assumption_key: AssumptionKey.selectedMarket,
        value: 'CAISO',
        variable_key: VariableKey.market
      },
      {
        assumption_key: AssumptionKey.isBatt,
        value: true,
        variable_key: VariableKey.isBatt
      },
      {
        assumption_key: AssumptionKey.isPV,
        value: true,
        variable_key: VariableKey.isPV
      },
      {
        assumption_key: AssumptionKey.isNetMetering,
        value: true,
        variable_key: VariableKey.isNetMetering
      },
      {
        assumption_key: AssumptionKey.percentFromPV,
        value: 100,
        variable_key: VariableKey.percentFromPV
      }
    ];

    /*** Fields to display in the menu */
    this.fields = [
      {
        display_name: 'Battery Power',
        assumption_key: 'power',
        variable_key: VariableKey.power
      },
      {
        display_name: 'Battery Capacity',
        assumption_key: 'capacity',
        variable_key: VariableKey.capacity
      },
      {
        display_name: 'Max Hourly Solar Production',
        assumption_key: 'pInstPV',
        variable_key: VariableKey.pInstPV
      },
      {
        display_name: 'Tariff Status Quo',
        assumption_key: 'tariffId',
        variable_key: VariableKey.tariffStatusQuo
      }
    ];
    /*** scenario required by the template */
    this.scenario_templates = [
      {
        key: ScenarioKeys.standardStatusQuo,
        name: 'Status Quo Load on Standard Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.solarOnlyStandardTariff,
        name: 'Solar Only on Standard Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.solarStorageForced,
        name: 'Forcecharged Storage on Standard Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.isBatt]: VariableKey.isBatt },
          { [AssumptionKey.power]: VariableKey.power },
          { [AssumptionKey.capacity]: VariableKey.capacity },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.percentFromPV]: VariableKey.percentFromPV },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.solarStorageNonForced,
        name: 'Non-Forcecharged Storage on Standard Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.isBatt]: VariableKey.isBatt },
          { [AssumptionKey.power]: VariableKey.power },
          { [AssumptionKey.capacity]: VariableKey.capacity },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo }
        ],
        reference_id: undefined
      }
    ];
  }
}

export class SolarTariffSolarStorageCAISOTemplate extends Template {
  public_id: null;
  constants: Constant[];
  name: TemplateName;
  description: string;
  scenario_templates: ScenarioTemplate[];
  fields: Field[];

  constructor() {
    super();
    this.name = TemplateName.solarPlusStorageCAISOSolarTariff;
    this.description = `This template is for the Californian market. It produces 5 different scenarios
    based on 2 different tariffs (standard and solar) and compare savings in a result matrix:
    - status quo on standard tariff
    - status quo on solar tariff
    - solar on standard tariff
    - solar only on solar tariff
    - forcecharged storage + solar on standard tariff
    - non-forcecharged storage + solar on standard tariff
    - forcecharged storage + solar on solar tariff
    - non-forcecharged storage + solar on solar tariff
    `;

    /** Template level Assumptions */
    this.constants = [
      {
        assumption_key: AssumptionKey.selectedMarket,
        value: 'CAISO',
        variable_key: VariableKey.market
      },
      {
        assumption_key: AssumptionKey.isBatt,
        value: true,
        variable_key: VariableKey.isBatt
      },
      {
        assumption_key: AssumptionKey.isPV,
        value: true,
        variable_key: VariableKey.isPV
      },
      {
        assumption_key: AssumptionKey.isNetMetering,
        value: true,
        variable_key: VariableKey.isNetMetering
      },
      {
        assumption_key: AssumptionKey.percentFromPV,
        value: 100,
        variable_key: VariableKey.percentFromPV
      }
    ];

    /*** Fields to display in the menu */
    this.fields = [
      {
        display_name: 'Battery Power',
        assumption_key: 'power',
        variable_key: VariableKey.power
      },
      {
        display_name: 'Battery Capacity',
        assumption_key: 'capacity',
        variable_key: VariableKey.capacity
      },
      {
        display_name: 'Max Hourly Solar Production',
        assumption_key: 'pInstPV',
        variable_key: VariableKey.pInstPV
      },
      {
        display_name: 'Tariff Status Quo',
        assumption_key: 'tariffId',
        variable_key: VariableKey.tariffStatusQuo
      },
      {
        display_name: 'Option-R Tariff',
        assumption_key: 'tariffId',
        variable_key: VariableKey.tariffSolar
      }
    ];
    /*** scenario required by the template */
    this.scenario_templates = [
      {
        key: ScenarioKeys.standardStatusQuo,
        name: 'Status Quo Load on Standard Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.solarTariffStatusQuo,
        name: 'Status Quo Load on Solar Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.tariffId]: VariableKey.tariffSolar }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.solarOnlyStandardTariff,
        name: 'Solar Only on Standard Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.solarOnlySolarTariff,
        name: 'Solar Only on Solar Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.tariffId]: VariableKey.tariffSolar }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.standardStorageForced,
        name: 'Forcecharged Storage on Standard Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.isBatt]: VariableKey.isBatt },
          { [AssumptionKey.power]: VariableKey.power },
          { [AssumptionKey.capacity]: VariableKey.capacity },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.percentFromPV]: VariableKey.percentFromPV },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.standardStorageNonForced,
        name: 'Non-Forcecharged Storage on Standard Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.isBatt]: VariableKey.isBatt },
          { [AssumptionKey.power]: VariableKey.power },
          { [AssumptionKey.capacity]: VariableKey.capacity },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.tariffId]: VariableKey.tariffStatusQuo }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.solarStorageForced,
        name: 'Forcecharged Storage on Solar Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.isBatt]: VariableKey.isBatt },
          { [AssumptionKey.power]: VariableKey.power },
          { [AssumptionKey.capacity]: VariableKey.capacity },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.percentFromPV]: VariableKey.percentFromPV },
          { [AssumptionKey.tariffId]: VariableKey.tariffSolar }
        ],
        reference_id: undefined
      },
      {
        key: ScenarioKeys.solarStorageNonForced,
        name: 'Non-Forcecharged Storage on Solar Tariff',
        assumptions_required: [
          { [AssumptionKey.selectedMarket]: VariableKey.market },
          { [AssumptionKey.isNetMetering]: VariableKey.isNetMetering },
          { [AssumptionKey.isBatt]: VariableKey.isBatt },
          { [AssumptionKey.power]: VariableKey.power },
          { [AssumptionKey.capacity]: VariableKey.capacity },
          { [AssumptionKey.isPV]: VariableKey.isPV },
          { [AssumptionKey.pInstPV]: VariableKey.pInstPV },
          { [AssumptionKey.exportCost]: VariableKey.exportCost },
          { [AssumptionKey.tariffId]: VariableKey.tariffSolar }
        ],
        reference_id: undefined
      }
    ];
  }
}
