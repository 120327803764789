import React from 'react';
import { Range } from 'immutable';
import moment from 'moment';

// MATERIAL UI
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Grid from '@material-ui/core/Grid';
import { EVShiftInternal } from '../../types';

interface EVArrivalChartProps {
  evShifts: EVShiftInternal[];
}

const dayRange = Range(0, 24).toJS();

const getColor = (i: number): string => {
  const colors = [
    '#9DC6DD',
    '#3D7A9E',
    '#7FC29B',
    '#BBBDF6',
    '#817E9F',
    '#8AEA92',
    '#D4E09B',
    '#636940',
    '#758ECD',
    '#80CED7',
    '#6CAE75'
  ];
  return colors[i % 10] ?? '#3D7A9E';
};

const EVArrivalChart: React.FC<EVArrivalChartProps> = ({ evShifts }) => {
  const numberOfRows = evShifts.length;
  const displayLabel = (value: number): string => moment({ hour: value }).format('hA');
  return (
    <>
      <div
        style={{
          display: 'grid',
          width: '100%',
          gridTemplateColumns: `repeat(24, 1fr)`,
          rowGap: '16px',
          gridTemplateRows: `repeat(${numberOfRows + 1}, 50px)`
        }}
      >
        {evShifts.map((shift, index) => (
          <ShiftRow shift={shift} key={shift.id} index={index} />
        ))}

        {/* Display background grid */}
        {dayRange.map(hour => (
          <div
            key={hour}
            style={{
              background: 'grey',
              opacity: 0.3,
              width: 1,
              gridColumn: `${hour + 1} / ${hour + 1}`,
              gridRow: ` 1 / ${numberOfRows + 1}`
            }}
          ></div>
        ))}

        {/* Display the hours label */}
        {dayRange.map(hour => (
          <div
            key={hour}
            style={{
              gridColumn: `${hour + 1} / span 1`,
              gridRow: `${numberOfRows + 1} / ${numberOfRows + 1}`,
              fontSize: '0.6rem',
              transform: `rotate(320deg)`
              // marginLeft: -4
            }}
          >
            {displayLabel(hour)}
          </div>
        ))}
      </div>
      {/* Legends */}
      <Grid container spacing={1}>
        <Grid item>
          <div style={{ backgroundColor: '#3D7A9E', height: 24, width: 24, opacity: 0.9 }}></div>
        </Grid>
        <Grid item>Arrival window</Grid>
        <Grid item>
          <div style={{ backgroundColor: '#3D7A9E', height: 24, width: 24, marginLeft: 16, opacity: 0.6 }}>
            <SVGPattern />
          </div>
        </Grid>
        <Grid item>Charging window</Grid>
      </Grid>
    </>
  );
};

function ShiftRow({ shift, index }: { shift: EVShiftInternal; index: number }) {
  const [start, end] = shift.evArrivalRange;
  const endCharge = Math.round(
    (shift.isOvernight ? start : end) + +shift.muChargingPrd + +shift.muChargingPrdStdDeviation
  );
  const color = getColor(shift.id);
  return (
    <>
      {!shift.isOvernight ? (
        <>
          <div
            style={{
              gridColumn: `${start + 1} / ${end + 1}`,
              gridRow: `${index + 1} / ${index + 1}`,
              backgroundColor: color,
              alignSelf: 'stretch',
              paddingLeft: 8,
              opacity: 0.8,
              color: '#fff',
              fontWeight: 600
            }}
          >
            Shift {shift.id} - <span style={{ fontSize: '0.6rem' }}>UR {shift.evseUtilisationRate}% </span>
            <div>
              <DriveEtaIcon />
            </div>
          </div>
          <div
            style={{
              gridColumn: `${end + 1}/ ${endCharge + 2}`,
              gridRow: `${index + 1} / ${index + 1}`,
              background: `linear-gradient(90deg, ${color} 0%, rgba(255,255,255,0) 75%)`,
              opacity: 0.6
            }}
          >
            {' '}
            <SVGPattern />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              gridColumn: `${end + 1} / 24`,
              gridRow: `${index + 1} / ${index + 1}`,
              backgroundColor: color,
              alignSelf: 'stretch',
              paddingLeft: 8,
              opacity: 0.8,
              color: '#fff',
              fontWeight: 600
            }}
          >
            Shift {shift.id} - <span style={{ fontSize: '0.6rem' }}>UR {shift.evseUtilisationRate}% </span>
            <div>
              <DriveEtaIcon />
            </div>
          </div>

          <div
            style={{
              gridColumn: `1 / ${start + 1}`,
              gridRow: `${index + 1} / ${index + 1}`,
              backgroundColor: color,
              alignSelf: 'stretch',
              paddingLeft: 8,
              opacity: 0.8,
              color: '#fff',
              fontWeight: 600
            }}
          >
            Shift {shift.id} - <span style={{ fontSize: '0.6rem' }}>UR {shift.evseUtilisationRate}% </span>
            <div>
              <DriveEtaIcon />
            </div>
          </div>
          <div
            style={{
              gridColumn: `${start + 1}/ ${endCharge + 2}`,
              gridRow: `${index + 1} / ${index + 1}`,
              background: `linear-gradient(90deg, ${color} 0%, rgba(255,255,255,0) 75%)`,
              opacity: 0.6
            }}
          >
            {' '}
            <SVGPattern />
          </div>
        </>
      )}
    </>
  );
}

function SVGPattern() {
  return (
    <>
      <svg width="100%" height="100%">
        <defs>
          <pattern
            id="diagonalHatch"
            patternUnits="userSpaceOnUse"
            width="4"
            height="8"
            patternTransform="rotate(-45 2 2)"
          >
            <path d="M -1,2 l 6,0" stroke="rgba(255,255,255, 0.8)" strokeWidth=".5" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" strokeWidth="2" fill="url(#diagonalHatch)" />
      </svg>
    </>
  );
}

export default React.memo(EVArrivalChart);
