import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { DialogContent, DialogContentText } from '@material-ui/core';
import { Period } from './TariffFactoryContext';

interface PeriodSelectorProps {
  open: boolean;
  onClose(): any;
  periods: Period[];
  handlePeriodSelection(period: Period): any;
}

const PeriodSelector: React.FC<PeriodSelectorProps> = ({ open, onClose, periods, handlePeriodSelection }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>Select a period</DialogContentText>
        <List>
          {periods.map((period, index) => {
            return (
              <ListItem button onClick={handlePeriodSelection(period)} key={index}>
                <ListItemIcon>
                  <div
                    style={{
                      backgroundColor: period.color,
                      // width: 16,
                      // height: 16,
                      border: '1px solid white',
                      borderRadius: 50,
                      display: 'grid',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 8
                    }}
                  >
                    {period.index}
                  </div>
                </ListItemIcon>
                <ListItemText>
                  {period.name} --- {period.TOU}
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default PeriodSelector;
