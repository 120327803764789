import { GET_TARIFFS_SUCCESS, RESET_TARIFF_LIST } from '../../actions/tariffs';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TARIFFS_SUCCESS:
      return action.payload.results;
    case RESET_TARIFF_LIST:
      return INITIAL_STATE;
    default:
      return state;
  }
}
