import React, { useState } from 'react';
import { ProposalInternal } from '../../../types';
import ProposalPresentationCharts from './ProposalPresentationCharts';
import ProposalGHGPresentation from './ProposalGHGPresentation';

// MATERIAL UI IMPORTS
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface ProposalPresentationContainerProps {
  proposal: ProposalInternal;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      margin: theme.spacing(3)
    }
  })
);

const ProposalPresentationContainer: React.FC<ProposalPresentationContainerProps> = ({ proposal }) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: any, value: any) => {
    setTabIndex(value);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs variant="fullWidth" value={tabIndex} onChange={handleChange}>
          <Tab label="Charts Generator" />
          <Tab label="GHG emissions equivalences" />
        </Tabs>
      </AppBar>
      <div className={classes.spacing}>
        {tabIndex === 0 && <ProposalPresentationCharts proposal={proposal} />}
        {tabIndex === 1 && <ProposalGHGPresentation proposal={proposal} />}
      </div>
    </>
  );
};

export default ProposalPresentationContainer;
