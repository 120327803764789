import {
  GET_ALL_MANUALTARIFFS_SUCCESS,
  RESET_MANUALTARIFF_LIST,
  CREATE_MANUALTARIFF_SUCCESS,
  UPDATE_MANUALTARIFF_SUCCESS,
  DELETE_MANUALTARIFF_SUCCESS
} from '../../actions/manualtariffs';
import { ESAPTariff } from '../../types';

const INITIAL_STATE: ESAPTariff[] = [];

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_ALL_MANUALTARIFFS_SUCCESS:
      return action.payload;
    case CREATE_MANUALTARIFF_SUCCESS:
      return [action.payload, ...state];
    case RESET_MANUALTARIFF_LIST:
      return INITIAL_STATE;
    case UPDATE_MANUALTARIFF_SUCCESS:
      let updateIndex = state.findIndex(tariff => tariff.public_id === action.payload.public_id);
      if (updateIndex > -1) {
        let newSelected = ([] as ESAPTariff[]).concat(state.slice(0, updateIndex), state.slice(updateIndex + 1));
        return [action.payload, ...newSelected];
      } else {
        return state;
      }
    case DELETE_MANUALTARIFF_SUCCESS:
      let deleteIndex = state.findIndex(tariff => tariff.public_id === action.payload.public_id);
      if (deleteIndex > -1) {
        let newSelected = ([] as ESAPTariff[]).concat(state.slice(0, deleteIndex), state.slice(deleteIndex + 1));
        return newSelected;
      } else {
        return state;
      }
    default:
      return state;
  }
}
