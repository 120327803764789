import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';

//Material UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Error from '@material-ui/icons/Error';
import Warning from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

//ESAP imports
import { ProposalInternal, AssumptionsInternal, ESAPTariff, MonthlyOperationsData } from '../../../types';
import PresentScenarioChart from '../../Scenarios/Charts/PresentScenarioChart';
import ScenarioTechBadges from '../../Scenarios/ScenarioTechBadges';
import { selectProposalScenarioPresentation } from '../../../actions/scenarios';
import { StoreState } from '../../../reducers';
import { useGetOperationData } from '../../../queries/operationsData';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minWidth: 983,
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    minHeight: 600
  },
  header: {
    background: '#001a70',
    padding: '20px',
    color: 'white'
  },
  scenarioSelect: {
    fontSize: '1em',
    minWidth: 300,
    padding: '0',
    marginLeft: 20,
    marginBottom: 0,
    marginRight: 12,
    background: 'white',
    '& .MuiSelect-select.MuiSelect-select': {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 10
    }
  },
  loadingContainer: {
    padding: '180px',
    textAlign: 'center'
  },
  errorContainer: {
    padding: '180px',
    textAlign: 'center',
    color: '#333',
    '& svg': {
      color: 'red',
      position: 'relative',
      top: '6px',
      marginRight: '6px'
    }
  },
  warningContainer: {
    color: '#333',
    '& svg': {
      color: '#ffaa00',
      position: 'relative',
      top: '6px',
      marginRight: '6px'
    }
  },
  chartConfig: {
    background: '#e2ecf8',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    width: 'auto'
    //background: '#e2ecf8',
    // padding: theme.spacing(2)
  },
  scenarioInfo: {
    fontSize: 12,
    marginBottom: theme.spacing(2)
  },
  scenarioInfoHeader: {
    fontWeight: 500,
    fontStyle: 'italic'
  },
  scenarioActive: {
    color: 'white',
    background: '#ff000087',
    fontWeight: 600,
    fontSize: '8px',
    letterSpacing: '1px',
    height: '16px',
    marginRight: 5
  },
  formHeaderText: {
    margin: '3px',
    textTransform: 'uppercase',
    fontWeight: 500,
    color: '#353535',
    borderBottom: '1px dashed #b8b8b8',
    paddingBottom: 6
  },
  formElement: {},
  formControl: {
    margin: theme.spacing(3)
  },
  checkboxGroup: {
    margin: '0 10px',
    '& span.MuiButtonBase-root': {
      padding: '1px'
    },
    '& span.MuiFormControlLabel-label': {
      fontSize: '12px'
    },
    '& svg': {
      width: '16px',
      fontSize: '16px'
    }
  },
  minMaxInput: {
    width: '60px',
    marginRight: theme.spacing(3),
    display: 'block'
  },
  dateChip: {
    fontSize: '.7rem',
    height: '20px',
    color: (theme.palette as any).esap.greenLight,
    letterSpacing: '0',
    lineHeight: '.7rem',
    backgroundColor: 'transparent',
    '& .MuiChip-label': {
      paddingLeft: 6,
      paddingRight: 6
    }
  },
  timeJump: {
    width: '160px',
    position: 'relative',
    top: '14px',
    left: '10px'
  },
  legend: {
    fontSize: 12
  },
  datePicker: {
    // marginTop: '6px'
  },
  rangeSelector: {
    margin: '6px 10px 6px 0'
  },
  switch: {
    '& .MuiSwitch-root': {
      width: 54,
      height: 34
    },
    '& .MuiSwitch-thumb': {
      width: 15,
      height: 15
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '0.9rem'
    }
  },
  chartsContainer: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}));

interface Props {
  proposal: ProposalInternal;
}

const ProposalPresentationCharts: React.FC<Props> = ({ proposal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedNumberOfDays, setSelectedNumberOfDays] = useState(7);
  const [selectedEventDate, setSelectedEventDate] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState<Moment>();
  const [dataStartDate, setDataStartDate] = useState('');
  const [dataEndDate, setDataEndDate] = useState('');
  const [dateIsInRange, setDateIsInRange] = useState(true);
  const [worstWeek, setWorstWeek] = useState<string>();

  const [minY, setMinY] = useState(-800);
  const [maxY, setMaxY] = useState(800);

  const [showTickLabels, setShowTickLabels] = useState(false);
  const [colorblindModeOn, setColorblindModeOn] = useState(false);
  const [showTOUBands, setShowTOUBands] = useState(true);

  const presentationData = useSelector((state: StoreState) => state.scenarioPresentation);
  const [selectedTariff, setSelectedTariff]: any = useState('');

  const scenarios = proposal.proposal_scenarios;
  const [selectedScenarioId, setSelectedScenarioId] = useState('');

  const charts = [
    {
      title: 'Solar + Storage',
      defaultChartData: ['Facility Demand', 'Net Demand', 'Demand Net Solar', 'Solar Generation', 'Battery Power'],
      showIf: (chartTypes: any, technologies: any) => {
        return chartTypes.solarAndStorage && technologies.isPV && technologies.isBatt;
      }
    },
    {
      title: 'Solar + Storage + Genset',
      defaultChartData: ['Facility Demand', 'Solar Generation', 'Genset Power', 'Battery Power'],
      showIf: (chartTypes: any, technologies: any) => {
        return chartTypes.solarAndStorageAndGenset && technologies.isPV && technologies.isBatt && technologies.isGenset;
      }
    },
    {
      title: 'Storage + Genset',
      defaultChartData: ['Facility Demand', 'Genset Power', 'Battery Power'],
      showIf: (chartTypes: any, technologies: any) => {
        return chartTypes.storageAndGenset && technologies.isBatt && technologies.isGenset;
      }
    },
    {
      title: 'Load',
      defaultChartData: ['Facility Demand'],
      showIf: (chartTypes: any, technologies: any) => {
        return chartTypes.load;
      }
    },

    {
      title: 'Solar Only',
      defaultChartData: ['Facility Demand', 'Demand Net Solar', 'Solar Generation'],
      showIf: (chartTypes: any, technologies: any) => {
        return chartTypes.solar && technologies.isPV;
      }
    },
    {
      title: 'Storage Only',
      defaultChartData: ['Facility Demand', 'Battery Power', 'Net Demand'],
      showIf: (chartTypes: any, technologies: any) => {
        return chartTypes.storage && technologies.isBatt;
      }
    },
    {
      title: 'Genset Only',
      defaultChartData: ['Facility Demand', 'Genset Power'],
      showIf: (chartTypes: any, technologies: any) => {
        return chartTypes.load && technologies.isGenset;
      }
    }
  ];

  const [chartTypes, setChartTypes] = React.useState({
    load: true,
    storage: true,
    solar: true,
    genset: true,
    solarAndStorage: true,
    storageAndGenset: true,
    solarAndStorageAndGenset: true
  });

  const { load, storage, solar, genset, solarAndStorage, storageAndGenset, solarAndStorageAndGenset } = chartTypes;

  const handleChartTypeChange = (name: string) => (event: any) => {
    setChartTypes({ ...chartTypes, [name]: event.target.checked });
  };

  const { data: scenarioMonthlyOperationsData, refetch, status } = useGetOperationData(
    proposal?.public_id,
    selectedScenarioId
  );

  useEffect(() => {
    if (proposal.public_id && selectedScenarioId) {
      refetch();
    }
  }, [selectedScenarioId, proposal.public_id]);

  useEffect(() => {
    if (scenarioMonthlyOperationsData) {
      const findSelectedTariff = (tariffId?: string): ESAPTariff | undefined => {
        if (proposal.proposal_tariffs.length > 0 && tariffId) {
          return proposal.proposal_tariffs.find(tariff => {
            return tariff.public_id === tariffId;
          });
        }
        return undefined;
      };

      setSelectedStartDate(moment(Object.keys(scenarioMonthlyOperationsData)[0]));
      setSelectedEventDate('');

      const monthlyKeys = Object.keys(scenarioMonthlyOperationsData);
      setDataStartDate(moment(monthlyKeys[0]).format('MM/DD/YYYY'));
      setDataEndDate(
        moment(monthlyKeys[monthlyKeys.length - 1])
          .add(1, 'months')
          .add(-1, 'days')
          .format('MM/DD/YYYY')
      );

      //find max
      getMaxAndMin(scenarioMonthlyOperationsData);
      if (presentationData?.scenario?.assumptions?.isPV) {
        findWorstSolarWeek(scenarioMonthlyOperationsData);
      }

      //get tariff
      setSelectedTariff(findSelectedTariff(presentationData?.scenario?.assumptions?.tariffId));
    }
  }, [presentationData, scenarioMonthlyOperationsData]);

  const getMaxAndMin = (dataByMonth: any) => {
    const joinedArray: any[] = [];
    Object.keys(dataByMonth).forEach(monthKey => {
      const monthData = dataByMonth[monthKey];
      Object.keys(monthData).forEach(dataStreamKey => {
        if (dataStreamKey === 'vecPInv' || dataStreamKey === 'vecLoad' || dataStreamKey === 'vecPBatt') {
          joinedArray.push(...monthData[dataStreamKey]);
        }
      });
    });
    let len = joinedArray.length;
    let max = -Infinity;

    while (len--) {
      max = joinedArray[len] > max ? joinedArray[len] : max;
    }

    max = Math.round(max) + 100;

    setMinY(max * -1);
    setMaxY(max);
  };

  const handleToggleTickLabels = (event: React.ChangeEvent<{ checked: boolean }>) => {
    setShowTickLabels(event.target.checked);
  };

  const handleToggleColorblindMode = (event: React.ChangeEvent<{ checked: boolean }>) => {
    setColorblindModeOn(event.target.checked);
  };

  const handleToggleTOUBands = (event: React.ChangeEvent<{ checked: boolean }>) => {
    setShowTOUBands(event.target.checked);
  };

  const handleScenarioChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedScenarioId(event.target.value as string);
    dispatch(selectProposalScenarioPresentation(scenarios.find(scenario => scenario.public_id === event.target.value)));
  };
  const handleNumberOfDaysChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedNumberOfDays(event.target.value as number);
  };

  const handleSelectedStartDateChange = (from: string) => (date: any) => {
    setSelectedStartDate(date);

    if (isDateInRange(date)) {
      setDateIsInRange(true);
    } else {
      setDateIsInRange(false);
    }
    if (from === 'userdatechange') {
      setSelectedEventDate('');
    }
  };

  const handleEventDateChange = (event: any) => {
    handleSelectedStartDateChange('jumptochange')(moment(event.target.value));
    setSelectedEventDate(event.target.value);
  };

  const jumpForwardTime = () => {
    handleSelectedStartDateChange('userdatechange')(moment(selectedStartDate).add(selectedNumberOfDays, 'days'));
  };

  const jumpBackTime = () => {
    handleSelectedStartDateChange('userdatechange')(moment(selectedStartDate).add(-selectedNumberOfDays, 'days'));
  };

  const isDateInRange = (date: any) => {
    return (
      moment(dataStartDate).add(-1, 'days').isBefore(moment(date)) &&
      moment(dataEndDate).add(1, 'days').isAfter(moment(date))
    );
  };

  const generateTechnologyFlags = () => {
    const technologies = {
      isPV: false,
      isBatt: false,
      isGenset: false,
      isEV: false,
      isIslanding: false
    };
    if (!selectedScenarioId) {
      return technologies;
    }
    const assumptions: Partial<AssumptionsInternal> | undefined = presentationData?.scenario?.assumptions;
    if (assumptions?.isPV) {
      technologies.isPV = true;
    }
    if (assumptions?.isEV) {
      technologies.isEV = true;
    }
    if (assumptions?.isBatt) {
      technologies.isBatt = true;
    }
    if (assumptions?.isGenset) {
      technologies.isGenset = true;
    }
    if (assumptions?.isIslanding) {
      technologies.isIslanding = true;
    }
    return technologies;
  };

  const technologies = generateTechnologyFlags();

  const findWorstSolarWeek = (scenarioData: MonthlyOperationsData) => {
    const pv: any = {};
    Object.keys(scenarioData).forEach(key => {
      const month = scenarioData[key];
      const daysInMonth = moment(key, 'YYYY-MM').daysInMonth();
      const keyParts = key.split('-');
      if (keyParts[1] === '0') {
        keyParts[1] = '12';
      }
      const startDate = new Date(+keyParts[0], +keyParts[1] - 1, 1);
      let currentDate;
      let step = 4;
      let minuteStepSize = 15;

      const stepsForwardFromStart = daysInMonth * 24 * step - month.vecLoad.length;
      currentDate = moment(startDate).add(minuteStepSize * stepsForwardFromStart, 'minutes');

      //Building Timestamps
      for (let i = 0; i < month.vecPInv.length; i++) {
        pv[currentDate.valueOf()] = month.vecPInv[i];
        currentDate.add(minuteStepSize, 'minutes');
      }
    });

    let weeklyData: any = {};
    let weeklyDayData: any = {};
    for (let i = 1; i <= 52; i++) {
      weeklyData[i] = [];
    }
    Object.keys(pv).forEach(key => {
      weeklyData[moment(+key).week()].push({
        timestamp: key,
        value: pv[key]
      });
    });

    for (let i = 1; i <= 52; i++) {
      let temp = {};
      for (let i = 0; i <= 6; i++) {
        temp[i] = [];
      }

      weeklyData[i].forEach((dp: any) => {
        temp[moment(+dp.timestamp).day()].push(dp.value);
      });
      weeklyDayData[i] = temp;
    }

    //solve for daily peaks
    let peaks: any = {};
    let weekPeakAverage: any = {};
    let worstWeek = 0;
    let worstWeekAverage = 100000;
    Object.keys(weeklyDayData).forEach(week => {
      peaks[week] = {};
      let sumOfPeaks = 0;
      Object.keys(weeklyDayData[week]).forEach(day => {
        peaks[week][day] = Math.max(...weeklyDayData[week][day]);
        sumOfPeaks += peaks[week][day];
      });
      weekPeakAverage[week] = sumOfPeaks / 7;
      if (weekPeakAverage[week] > 0 && weekPeakAverage[week] < worstWeekAverage) {
        worstWeek = +week;
        worstWeekAverage = weekPeakAverage[week];
      }
    });

    const worstWeekTimestamp = weeklyData[worstWeek][0].timestamp;

    setWorstWeek(moment(+worstWeekTimestamp).format('MM/DD/YYYY'));
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        Select a Scenario to Generate Charts
        <Select
          className={classes.scenarioSelect}
          data-testid="select-scenario"
          value={selectedScenarioId}
          onChange={handleScenarioChange}
          inputProps={{
            name: 'selectedScenarioId',
            id: 'selectedScenarioId'
          }}
        >
          {proposal?.proposal_scenarios &&
            proposal.proposal_scenarios.map((scenario, index) => {
              return (
                <MenuItem
                  value={scenario.public_id as string}
                  key={scenario.public_id as string}
                  data-testid="select-scenario-item"
                >
                  {!scenario.active && <Chip className={classes.scenarioActive} label="INACTIVE" />}
                  {scenario.name}
                  {'  '}
                  <ScenarioTechBadges scenario={scenario} />
                </MenuItem>
              );
            })}
        </Select>
      </Box>
      {status === 'loading' && (
        <Box className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={50} />
        </Box>
      )}

      {status === 'error' && (
        <Box className={classes.errorContainer}>
          <Error />
          No results for selected Scenario
        </Box>
      )}
      {status === 'success' &&
        presentationData?.scenario?.proposal_id === proposal.public_id &&
        scenarioMonthlyOperationsData && (
          <>
            <Grid container className={classes.chartConfig} data-testid="chart-config">
              <Grid item xs={12}>
                <Box className={classes.formHeaderText}>
                  Configuration Options
                  <span style={{ float: 'right' }}>
                    <ScenarioTechBadges scenario={presentationData.scenario} />{' '}
                    <Chip className={classes.dateChip} label={`Data Start: ${dataStartDate}`} />
                    <Chip className={classes.dateChip} label={`Data End: ${dataEndDate}`} />
                  </span>
                </Box>
                <Box style={{ float: 'left', margin: '13px 30px 0 5px' }}>
                  <Box className={classes.formElement}>
                    <FormControl style={{ width: 120 }}>
                      <InputLabel id="select-number-of-days">Number of Days</InputLabel>
                      <Select
                        label="Number of Days"
                        labelId="select-number-of-days"
                        value={selectedNumberOfDays}
                        onChange={handleNumberOfDaysChange}
                        className={classes.rangeSelector}
                        inputProps={{
                          name: 'selectedNumberOfDays'
                        }}
                      >
                        <MenuItem value={1}>1 Day</MenuItem>
                        <MenuItem value={2}>2 Day</MenuItem>
                        <MenuItem value={3}>3 Day</MenuItem>
                        <MenuItem value={4}>4 Day</MenuItem>
                        <MenuItem value={5}>5 Day</MenuItem>
                        <MenuItem value={6}>6 Day</MenuItem>
                        <MenuItem value={7}>7 Days</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl style={{ width: 160 }}>
                      <KeyboardDatePicker
                        id="startDate"
                        data-testid="datepicker-startdate"
                        label="Select Start Date"
                        value={selectedStartDate}
                        format="MM/DD/YYYY"
                        onChange={handleSelectedStartDateChange('userdatechange')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        className={classes.datePicker}
                      />
                    </FormControl>
                    <span className={classes.timeJump}>
                      <IconButton onClick={jumpBackTime}>
                        <KeyboardArrowLeft />
                      </IconButton>
                      <IconButton onClick={jumpForwardTime}>
                        <KeyboardArrowRight />
                      </IconButton>
                    </span>
                  </Box>
                  <Box>
                    <FormControl style={{ minWidth: 356, marginTop: 7 }}>
                      <InputLabel id="select-jumptodate">Select Jump To Date{'  '}</InputLabel>
                      <Select
                        labelId="select-jumptodate"
                        data-testid="select-jumptodate"
                        value={selectedEventDate}
                        onChange={handleEventDateChange}
                        className={classes.rangeSelector}
                        inputProps={{
                          name: 'selectEventDate'
                        }}
                      >
                        {presentationData.scenario.assumptions.isPV && (
                          <MenuItem key={worstWeek} value={worstWeek} data-testid="select-jumptodate-item">
                            Worst Solar Week: {`${worstWeek}`}
                          </MenuItem>
                        )}
                        {presentationData.scenario.assumptions.gridOutageDates?.length > 0 &&
                          presentationData.scenario.assumptions.gridOutageDates.map((event: any, index: any) => (
                            <MenuItem key={event.start} value={event.start} data-testid="select-jumptodate-item">
                              Outage {`${index + 1}: ${event.start}`}
                            </MenuItem>
                          ))}
                        <MenuItem key={dataStartDate} value={dataStartDate} data-testid="select-jumptodate-item">
                          Start of Data: {`${dataStartDate}`}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box style={{ marginTop: 31, margin: '15px 0 0 400px' }}>
                  <div style={{ float: 'left' }}>
                    <TextField
                      label="Min Y"
                      value={minY}
                      type="number"
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ step: '10' }}
                      onChange={e => {
                        setMinY(+e.target.value);
                      }}
                      className={classes.minMaxInput}
                    />

                    <TextField
                      label="Max Y"
                      value={maxY}
                      type="number"
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ step: '10' }}
                      onChange={e => {
                        setMaxY(+e.target.value);
                      }}
                      className={classes.minMaxInput}
                      style={{ marginTop: 13 }}
                    />
                  </div>
                  <FormControl component="fieldset" style={{ float: 'left' }}>
                    <FormLabel component="legend" className={classes.legend}>
                      Select Charts to Generate
                    </FormLabel>
                    <div style={{ marginTop: 10 }}>
                      <FormGroup className={classes.checkboxGroup} style={{ float: 'left' }}>
                        <FormControlLabel
                          control={<Checkbox checked={load} onChange={handleChartTypeChange('load')} value="load" />}
                          label="Load"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={storage} onChange={handleChartTypeChange('storage')} value="storage" />
                          }
                          label="Storage"
                          disabled={!technologies.isBatt}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={solar}
                              onChange={handleChartTypeChange('solar')}
                              value="solar"
                              disabled={!technologies.isPV}
                            />
                          }
                          label="Solar"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={genset}
                              onChange={handleChartTypeChange('genset')}
                              value="genset"
                              disabled={!technologies.isGenset}
                            />
                          }
                          label="Genset"
                        />
                      </FormGroup>
                      <FormGroup className={classes.checkboxGroup}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={solarAndStorage}
                              onChange={handleChartTypeChange('solarAndStorage')}
                              value="solarAndStorage"
                              disabled={!technologies.isPV || !technologies.isBatt}
                            />
                          }
                          label="Solar+Storage"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={solarAndStorageAndGenset}
                              onChange={handleChartTypeChange('solarAndStorageAndGenset')}
                              value="solarAndStorageAndGenset"
                              disabled={!technologies.isPV || !technologies.isBatt || !technologies.isGenset}
                            />
                          }
                          label="Solar+Storage+Genset"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={storageAndGenset}
                              onChange={handleChartTypeChange('storageAndGenset')}
                              value="storageAndGenset"
                              disabled={!technologies.isBatt || !technologies.isGenset}
                            />
                          }
                          label="Storage+Genset"
                        />
                      </FormGroup>
                    </div>
                  </FormControl>
                  <FormGroup style={{ marginTop: 7 }}>
                    <FormLabel component="legend" className={classes.legend}>
                      Other Options
                    </FormLabel>
                    <FormControlLabel
                      className={classes.switch}
                      control={
                        <Switch checked={showTickLabels} onChange={handleToggleTickLabels} name="showTickLabels" />
                      }
                      label="Axis labels"
                    />
                    <FormControlLabel
                      className={classes.switch}
                      control={
                        <Switch
                          checked={colorblindModeOn}
                          onChange={handleToggleColorblindMode}
                          name="colorblindModeOn"
                        />
                      }
                      label="Colorblind Mode"
                    />
                    <FormControlLabel
                      className={classes.switch}
                      control={<Switch checked={showTOUBands} onChange={handleToggleTOUBands} name="TOUBandsOn" />}
                      label="Show Time of Use"
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      <Box className={classes.chartsContainer}>
        {status === 'success' && !dateIsInRange && (
          <Box className={classes.warningContainer}>
            <Warning /> Selected Start Date is not within the dataset
          </Box>
        )}

        {status === 'success' &&
          presentationData?.scenario?.proposal_id === proposal.public_id &&
          scenarioMonthlyOperationsData &&
          dateIsInRange && (
            <>
              <Box style={{ fontSize: 12, borderBottom: 'solid 1px #eaeaea' }}>
                {/* {getNumberOfCharts()} Charts Generated */}
              </Box>
              {charts.map(chart => {
                if (chart.showIf(chartTypes as any, technologies as any)) {
                  return (
                    <PresentScenarioChart
                      key={chart.title}
                      chartTitle={chart.title}
                      defaultChartData={chart.defaultChartData}
                      scenarioData={scenarioMonthlyOperationsData}
                      scenarioResults={presentationData?.scenario?.results}
                      energyCapacity={presentationData?.scenario?.assumptions?.capacity ?? 1}
                      technologies={technologies}
                      frequency={'15min'}
                      startDate={selectedStartDate}
                      numberOfDays={selectedNumberOfDays}
                      minY={minY}
                      maxY={maxY}
                      showticklabels={showTickLabels}
                      colorblindMode={colorblindModeOn}
                      showTOU={showTOUBands}
                      tariffOverview={selectedTariff?.overview || null}
                    />
                  );
                }
                return null;
              })}
            </>
          )}
      </Box>
    </Paper>
  );
};

export default ProposalPresentationCharts;
