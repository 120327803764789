import { ESAPTariff, TOUTypes } from '../types';

export const touRateTemplate: ESAPTariff = {
  public_id: '',
  lse_name: 'ESAP Created',
  lse_id: 0,
  source_id: '0',
  description: '',
  code: 'TOU Rate Template',
  name: 'TOU Rate Template',
  properties: [],
  effective_date: '2019-01-01',
  applicability_values: {},
  demandCharges: [
    ['Season', 'OFF PEAK', 'PARTIAL_PEAK', 'ON_PEAK', 'NON COINCIDENTAL'],
    ['Summer', 0, 0, 0, 0],
    ['Winter', 0, 0, 0, 0]
  ],
  energyCharges: [
    ['Season', 'OFF PEAK', 'PARTIAL_PEAK', 'ON_PEAK'],
    ['Summer', 0, 0, 0],
    ['Winter', 0, 0, 0]
  ],
  overview: {
    consumptionBaseAdjustment: 0,
    demandBaseAdjustment: 0,
    demandBaseCharges: [],
    consumptionBaseCharges: [],
    seasons: {}
  }
};

export const flatRateTemplate: ESAPTariff = {
  public_id: '',
  lse_name: 'ESAP Created',
  lse_id: 0,
  source_id: '0',
  code: 'Flat Rate Template',
  name: 'Flat Rate Template',
  description: '',
  properties: [],
  effective_date: '2019-01-01',
  applicability_values: {},
  demandCharges: [
    ['Season', 'NON COINCIDENTAL'],
    ['Summer', 0],
    ['Winter', 0]
  ],
  energyCharges: [
    ['Season', 'OFF PEAK'],
    ['Summer', 0],
    ['Winter', 0]
  ],
  overview: {
    consumptionBaseAdjustment: 0,
    demandBaseAdjustment: 0,
    demandBaseCharges: [],
    consumptionBaseCharges: [],
    seasons: {
      Summer: {
        OFF_PEAK: [
          {
            name: 'Base Rate',
            amount: 0,
            credit: false,
            touType: TOUTypes.OFF_PEAK,
            type: 'CONSUMPTION_BASED',
            periods: [
              {
                fromDayOfWeek: 0,
                fromHour: 0,
                fromMinute: 0,
                toDayOfWeek: 6,
                toHour: 0,
                toMinute: 0
              }
            ]
          },
          {
            name: 'Base Rate',
            amount: 0,
            credit: false,
            touType: TOUTypes.OFF_PEAK,
            type: 'DEMAND_BASED',
            periods: [
              {
                fromDayOfWeek: 0,
                fromHour: 0,
                fromMinute: 0,
                toDayOfWeek: 6,
                toHour: 0,
                toMinute: 0
              }
            ]
          }
        ],
        overview: {
          fromDay: 1,
          fromMonth: 6,
          name: 'Summer',
          toDay: 31,
          toMonth: 10
        }
      },
      Winter: {
        OFF_PEAK: [
          {
            name: 'Base Rate',
            amount: 0,
            credit: false,
            touType: TOUTypes.OFF_PEAK,
            type: 'CONSUMPTION_BASED',
            periods: [
              {
                fromDayOfWeek: 0,
                fromHour: 0,
                fromMinute: 0,
                toDayOfWeek: 6,
                toHour: 0,
                toMinute: 0
              }
            ]
          },
          {
            name: 'Base Rate',
            amount: 0,
            credit: false,
            touType: TOUTypes.OFF_PEAK,
            type: 'DEMAND_BASED',
            periods: [
              {
                fromDayOfWeek: 0,
                fromHour: 0,
                fromMinute: 0,
                toDayOfWeek: 6,
                toHour: 0,
                toMinute: 0
              }
            ]
          }
        ],
        overview: {
          fromDay: 1,
          fromMonth: 11,
          name: 'Winter',
          toDay: 31,
          toMonth: 5
        }
      }
    }
  }
};
