import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  }
}));

interface OntarioAssumptionsProps {
  handleToggle: any;
  isGADR?: boolean;
  gaArbitrageCycles: any;
  handleGeneralAssumptionChange: (event: any) => void;
  gaArbitrageThresholdSTD: any;
  isGA?: boolean;
}

const OntarioAssumptions: React.FC<OntarioAssumptionsProps> = props => {
  const { handleToggle, isGADR, isGA } = props;

  const classes = useStyles();

  return (
    <div>
      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={handleToggle} checked={isGA} name="isGA" />}
        label="Is Global Adjustment"
      />
      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={handleToggle} checked={isGADR} name="isGADR" />}
        label="Is Demand Response Program"
      />
    </div>
  );
};

export default OntarioAssumptions;
