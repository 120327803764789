import axios from 'axios';

export const GET_METERS_SUCCESS = 'GET_METERS_SUCCESS';
export const GET_METERS_ERROR = 'GET_METERS_ERROR';
export const GET_METERS_REQUEST = 'GET_METERS_REQUEST';

export const GET_METER_INTERVAL_REQUEST = 'GET_METER_INTERVAL_REQUEST';
export const GET_METER_INTERVAL_ERROR = 'GET_METER_INTERVAL_ERROR';
export const GET_METER_INTERVAL_SUCCESS = 'GET_METER_INTERVAL_SUCCESS';

const ROOT_URL = window.REACT_APP_API_BASE_URL_V2;

export function getMeters(account = 'Ethan') {
  return dispatch => {
    dispatch({ type: GET_METERS_REQUEST });
    axios
      .get(`${ROOT_URL}/services/utility-api/meters/${account}`)
      .then(res => {
        const { meters } = res.data;
        dispatch({
          type: GET_METERS_SUCCESS,
          payload: meters
        });
      })
      .catch(err => {
        dispatch({
          type: GET_METERS_ERROR,
          payload: err
        });
      });
  };
}

function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

function getFormattedTime(d) {
  const hours = format_two_digits(d.getHours());
  const minutes = format_two_digits(d.getMinutes());
  return hours + ':' + minutes;
}

function format_two_digits(n) {
  return n < 10 ? '0' + n : n;
}

function generateVerticalGridFromUtilityAPI(intervalData, startDate) {
  const grid = [];
  let previousDate = startDate;
  intervalData.forEach(item => {
    const readingDate = new Date(item.start);
    if (readingDate < startDate) {
      return;
    }
    if (readingDate.getMinutes() === 0 && readingDate.getHours() === previousDate.getHours() + 2) {
      // Handle DST Hour Skip
      grid.push([getFormattedDate(new Date(readingDate.setDate(readingDate.getDate()))), '02:00', item.kw]);
      grid.push([getFormattedDate(new Date(readingDate.setDate(readingDate.getDate()))), '02:15', item.kw]);
      grid.push([getFormattedDate(new Date(readingDate.setDate(readingDate.getDate()))), '02:30', item.kw]);
      grid.push([getFormattedDate(new Date(readingDate.setDate(readingDate.getDate()))), '02:45', item.kw]);
    }
    grid.push([
      getFormattedDate(new Date(readingDate.setDate(readingDate.getDate()))),
      getFormattedTime(readingDate),
      item.kw
    ]);
    previousDate = readingDate;
  });
  return grid;
}

export function getMeterInterval(meterId, account = 'Ethan') {
  return dispatch => {
    dispatch({ type: GET_METER_INTERVAL_REQUEST });
    axios
      .get(`${ROOT_URL}/services/utility-api/intervals/${account}/${meterId}`)
      .then(res => {
        const { intervals } = res.data;
        var startDate = new Date(intervals[0].start);

        const gridIntervalData = generateVerticalGridFromUtilityAPI(intervals, startDate);
        dispatch({
          type: GET_METER_INTERVAL_SUCCESS,
          payload: gridIntervalData
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_METER_INTERVAL_ERROR,
          payload: error,
          error: true
        });
      });
  };
}
