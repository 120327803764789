import axios from 'axios';
import { Dispatch } from 'redux';
import { Portfolio, PortfolioMeter } from '../types';

export const GET_ALL_PORTFOLIOS_IDLE = 'GET_ALL_PORTFOLIOS_IDLE';
export const GET_ALL_PORTFOLIOS_SUCCESS = 'GET_ALL_PORTFOLIOS_SUCCESS';
export const GET_ALL_PORTFOLIOS_ERROR = 'GET_ALL_PORTFOLIOS_ERROR';
export const GET_ALL_PORTFOLIOS_REQUEST = 'GET_ALL_PORTFOLIOS_REQUEST';

export const GET_PORTFOLIO_SUCCESS = 'GET_PORTFOLIO_SUCCESS';
export const GET_PORTFOLIO_ERROR = 'GET_PORTFOLIO_ERROR';
export const GET_PORTFOLIO_REQUEST = 'GET_PORTFOLIO_REQUEST';

export const CREATE_PORTFOLIO_SUCCESS = 'CREATE_PORTFOLIO_SUCCESS';
export const CREATE_PORTFOLIO_ERROR = 'CREATE_PORTFOLIO_ERROR';
export const CREATE_PORTFOLIO_REQUEST = 'CREATE_PORTFOLIO_REQUEST';

export const RUN_PORTFOLIO_SUCCESS = 'RUN_PORTFOLIO_SUCCESS';
export const RUN_PORTFOLIO_ERROR = 'RUN_PORTFOLIO_ERROR';
export const RUN_PORTFOLIO_REQUEST = 'RUN_PORTFOLIO_REQUEST';

export const ARCHIVE_PORTFOLIO_SUCCESS = 'ARCHIVE_PORTFOLIO_SUCCESS';
export const ARCHIVE_PORTFOLIO_ERROR = 'ARCHIVE_PORTFOLIO_ERROR';
export const ARCHIVE_PORTFOLIO_REQUEST = 'ARCHIVE_PORTFOLIO_REQUEST';

export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS';
export const UPDATE_PORTFOLIO_ERROR = 'UPDATE_PORTFOLIO_ERROR';
export const UPDATE_PORTFOLIO_REQUEST = 'UPDATE_PORTFOLIO_REQUEST';

export const DELETE_PORTFOLIO_SUCCESS = 'DELETE_PORTFOLIO_SUCCESS';
export const DELETE_PORTFOLIO_ERROR = 'DELETE_PORTFOLIO_ERROR';
export const DELETE_PORTFOLIO_REQUEST = 'DELETE_PORTFOLIO_REQUEST';

export const PORTFOLIO_INPUT_FORM_CHANGE = 'PORTFOLIO_INPUT_FORM_CHANGE';
export const UPDATE_PORTFOLIO_METER = '  UPDATE_PORTFOLIO_METER';

export const UPDATE_PORTFOLIOS_LIST_FILTERS = 'UPDATE_PORTFOLIOS_LIST_FILTERS';
export const RESET_PORTFOLIOS_LIST_FILTERS = 'RESET_PORTFOLIOS_LIST_FILTERS';

export const RESET_PORTFOLIOS_LIST = 'RESET_PORTFOLIOS_LIST';
export const RESET_CREATE_PORTFOLIO = 'RESET_CREATE_PORTFOLIO';

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export const COPY_PORTFOLIO_SUCCESS = 'COPY_PORTFOLIO_SUCCESS';
export const COPY_PORTFOLIO_REQUEST = 'COPY_PORTFOLIO_REQUEST';
export const COPY_PORTFOLIO_ERROR = 'COPY_PORTFOLIO_ERROR';

function assertIsPortfolio(res: any, errorMessage: string): asserts res is Portfolio {
  if (
    !Array.isArray(res.portfolio_meters) ||
    typeof res.name !== 'string' ||
    typeof res.portfolio_type !== 'string' ||
    typeof res.public_id !== 'string'
  ) {
    throw new Error(`${errorMessage}`);
  }
}

export function getAllPortfolios(params: any) {
  let baseUrl = `${ROOT_URL}/portfolio/`;

  if (params && Object.keys(params).length > 0) {
    baseUrl += '?';
  }

  if (params?.includeScenarioSummary) {
    baseUrl += `include_scenario_summary=true&`;
  }

  if (params?.search) {
    baseUrl += `search=${params.search}&`;
  }

  if (params?.tariffCodes) {
    for (let tariff of params.tariffCodes) {
      baseUrl += `tariff_code=${tariff.value}&`;
    }
  }

  if (params?.userId) {
    baseUrl += `filter_by_user=${params.userId}&`;
  }

  if (params?.showArchived) {
    baseUrl += `include_archived_portfolios=true&`;
  }

  if (params?.customerId) {
    baseUrl += `customer_id=${params.customerId}&`;
  }

  if (params?.page) {
    baseUrl += `page=${params.page}&`;
  } else {
    baseUrl += `page=1&`;
  }
  if (params?.pageSize) {
    baseUrl += `page_size=${params.pageSize}&`;
  }

  if (params?.sortOn) {
    baseUrl += `sort_on=${params.sortOn}&`;
  }

  if (params?.orderBy) {
    baseUrl += `order_by=${params.orderBy}&`;
  }

  return (dispatch: Dispatch) => {
    dispatch({ type: GET_ALL_PORTFOLIOS_REQUEST });
    return axios
      .get(baseUrl)
      .then(res => {
        dispatch({
          type: GET_ALL_PORTFOLIOS_SUCCESS,
          payload: res.data
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALL_PORTFOLIOS_ERROR,
          payload: error
        });
      });
  };
}

export function getPortfolio(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_PORTFOLIO_REQUEST });
    axios
      .get(`${ROOT_URL}/portfolio/${id}`)
      .then(res => {
        const portfolio = res.data;
        dispatch({ type: GET_PORTFOLIO_SUCCESS, payload: portfolio });
      })
      .catch(function (error) {
        console.error(GET_PORTFOLIO_ERROR + ' error: ' + error);
        dispatch({ type: GET_PORTFOLIO_ERROR, payload: error, error: true });
      });
  };
}

export function runPortfolio(portfolioId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: RUN_PORTFOLIO_REQUEST });
    axios
      .post(`${ROOT_URL}/portfolio/${portfolioId}/run`)
      .then(res => {
        dispatch({ type: RUN_PORTFOLIO_SUCCESS, payload: res });
      })
      .catch(function (error) {
        console.error(RUN_PORTFOLIO_ERROR + ' error: ' + error);
        dispatch({ type: RUN_PORTFOLIO_ERROR, payload: error, error: true });
      });
  };
}

export function resetCreatePortfolio() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_CREATE_PORTFOLIO });
  };
}

export function updatePortfolio(portfolio: Portfolio, options: { ignoreRerender?: boolean } = {}) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_PORTFOLIO_REQUEST });
    axios
      .put(`${ROOT_URL}/portfolio/${portfolio.public_id}`, portfolio)
      .then(res => {
        if (!options?.ignoreRerender) {
          const portfolio = res.data;
          dispatch({ type: UPDATE_PORTFOLIO_SUCCESS, payload: portfolio });
        }
      })
      .catch(function (error) {
        console.error(UPDATE_PORTFOLIO_ERROR + ' error: ' + error);
        dispatch({ type: UPDATE_PORTFOLIO_ERROR, payload: error, error: true });
      });
  };
}

export function createPortfolioMeters(id: string, meters: PortfolioMeter[]) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_PORTFOLIO_REQUEST });
    axios
      .post(`${ROOT_URL}/portfolio/${id}/meter`, meters)
      .then(res => {
        const portfolio = res.data;
        dispatch({ type: UPDATE_PORTFOLIO_SUCCESS, payload: portfolio });
      })
      .catch(function (error) {
        console.error(UPDATE_PORTFOLIO_ERROR + ' error: ' + error);
        dispatch({ type: UPDATE_PORTFOLIO_ERROR, payload: error, error: true });
      });
  };
}

export function archivePortfolio(portfolio: Portfolio, options: { ignoreRerender?: boolean } = {}) {
  return (dispatch: Dispatch) => {
    dispatch({ type: ARCHIVE_PORTFOLIO_REQUEST });
    axios
      .put(`${ROOT_URL}/portfolio/${portfolio.public_id}`, portfolio)
      .then(res => {
        if (!options.ignoreRerender) {
          const portfolio = res.data;
          dispatch({ type: ARCHIVE_PORTFOLIO_SUCCESS, payload: portfolio });
        }
      })
      .catch(function (error) {
        dispatch({ type: ARCHIVE_PORTFOLIO_ERROR, payload: error });
        console.error(`Error while archiving proposal: ${error}`);
      });
  };
}

export function deletePortfolio(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: DELETE_PORTFOLIO_REQUEST });
    axios
      .delete(`${ROOT_URL}/portfolio/${id}`)
      .then(res => {
        dispatch({ type: DELETE_PORTFOLIO_SUCCESS, payload: id });
      })
      .catch(error => {
        dispatch({ type: DELETE_PORTFOLIO_ERROR, payload: error });
        console.error('Error deleting the portfolio: ' + error);
      });
  };
}

export function resetPortfoliosList() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_PORTFOLIOS_LIST });
  };
}

export function resetPortfolioListsFilters() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_PORTFOLIOS_LIST_FILTERS });
  };
}

export function createPortfolio(portfolio: Portfolio) {
  return (dispatch: Dispatch) => {
    dispatch({ type: CREATE_PORTFOLIO_REQUEST });
    axios
      .post(`${ROOT_URL}/portfolio/`, portfolio)
      .then(res => {
        const portfolio = res.data;
        dispatch({ type: CREATE_PORTFOLIO_SUCCESS, payload: portfolio });
      })
      .catch(function (error) {
        console.error(CREATE_PORTFOLIO_ERROR + ' error: ' + error);
        dispatch({ type: CREATE_PORTFOLIO_ERROR, payload: error, error: true });
      });
  };
}

export function getProposalsOptions(search: string) {
  let url = `${ROOT_URL}/proposal/?include_scenario_summary=true&search=${search}&page=1&page_size=25&`;
  return axios.get(url);
}

export function duplicatePortfolio(portfolioId: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: COPY_PORTFOLIO_REQUEST });

    axios
      .post(`${ROOT_URL}/portfolio/${portfolioId}/copy`, {})
      .then(res => {
        assertIsPortfolio(res.data, 'There was a problem trying to duplicate a scenario');
        dispatch({ type: COPY_PORTFOLIO_SUCCESS, payload: res.data });
      })
      .catch(function (error) {
        console.error(COPY_PORTFOLIO_ERROR + ' error: ' + error);
        dispatch({
          type: COPY_PORTFOLIO_ERROR,
          payload: error
        });
      });
  };
}
