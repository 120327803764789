import axios from 'axios';
import moment from 'moment';

/**************************************
 **** USER ACTION TYPES *****
 **************************************/
export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';

const ROOT_URL = window.REACT_APP_API_BASE_URL_V2;

/**************************************
 ******* USER ACTIONS *********
 **************************************/

export const sortUsers = users => {
  // latest updated comes first
  if (users && users.length > 0) {
    return users.sort((a, b) => moment(b.created_on).unix() - moment(a.created_on).unix());
  }
  return [];
};

export function getAllUsers() {
  return dispatch => {
    dispatch({ type: GET_ALL_USERS_REQUEST });
    return axios
      .get(`${ROOT_URL}/user/`)
      .then(res => {
        const users = res.data;
        dispatch({ type: GET_ALL_USERS_SUCCESS, payload: users });
      })
      .catch(function (error) {
        console.error(GET_ALL_USERS_ERROR + ' error: ' + error);
        dispatch({
          type: GET_ALL_USERS_ERROR,
          payload: error,
          error: true
        });
      });
  };
}
