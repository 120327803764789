import React, { useEffect, useState } from 'react';
import {
  ScenarioInternal,
  AssumptionsInternal,
  ESAPTariff,
  EVShiftInternal,
  TemplateEV,
  ProposalInternal
} from '../../../types';
import { useDispatch } from 'react-redux';
import ParametersConfig from './ParametersConfig';
import BatteryConfig from './BatteryConfig';
import PVConfig from './PVConfig';
import MicrogridConfig from './MicrogridConfig';
import EVConfig from './EVConfig';
import { resetUtilityInfo } from '../../../actions/utilities';
import { populateNBC } from '../../../actions/tariffs';
import AccessControl from '../../../utility/AccessControl';

// MATERIAL UI IMPORTS
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { GridOn, BatteryFull, WbSunny, List, DirectionsCar } from '@material-ui/icons';

import { parseTariffInformationUpdated } from '../../../utility/Tariff';
import { assertIsDefined, assertUnreachable } from '../../../utils/assertions';
import { useGetUtilityInfo } from '../../../queries/utilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    },
    flex: {
      flex: '1 1 100%'
    },
    title: {
      flex: '0 0 auto'
    },
    whiteLabel: {
      color: '#fff'
    },
    tabHighlight: {
      '& svg': {
        color: '#4fa50b'
      }
    }
  })
);

interface AssumptionsDetailProps {
  handleYearlyCycleAssumptionChange: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMonthlyBidAssumptionChange: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  assumptions: AssumptionsInternal;
  handleGeneralAssumptionChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  handleEVShiftAssumptionsChange: (
    id: number,
    assumptions?: Partial<EVShiftInternal>
  ) => (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  handleMultiUpdate: any;
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  selectedTariffs: ESAPTariff[];
  editStatusQuo: boolean;
  setIsComplete: any;
  scenario: ScenarioInternal;
  handleAddEVShift: () => void;
  handleDeleteEVShift: (id: number) => () => void;
  handleChangeEVProfileTemplate: (template: TemplateEV) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  proposal: ProposalInternal;
}

const AssumptionsDetail: React.FC<AssumptionsDetailProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  type ITab = 'strategies' | 'battery' | 'pv' | 'microgrid' | 'ev';

  const [tabIndex, setTabIndex] = React.useState<ITab>('strategies');

  const [lseId, setLSEId] = useState<number | undefined>();

  useEffect(() => {
    let lseId = 0;

    // get utility info for scenario selected tariff if it exists
    if (props?.assumptions?.tariffId?.length > 0 && Array.isArray(props.selectedTariffs)) {
      const scenarioTariff = props.selectedTariffs.find(t => t.public_id == props?.assumptions?.tariffId);
      if (scenarioTariff && scenarioTariff.lse_id) {
        lseId = scenarioTariff.lse_id;
      }
      // else get utility info for the first of the proposal tariffs
    } else if (props.selectedTariffs && props.selectedTariffs.length > 0) {
      lseId = props.selectedTariffs[0].lse_id;
    } else {
      // TODO not sure its actually needed since we use React Query now
      dispatch(resetUtilityInfo());
    }
    setLSEId(lseId);
  }, [dispatch, props.selectedTariffs, props?.assumptions?.tariffId]);

  // lseId would be 0 for user created tariffs, thus not triggering a utilityInnfo query
  const { data: utilityInfo, status: utilityInfoStatus, error: utilityInfoError } = useGetUtilityInfo(lseId ?? 0);

  const handleChange = (event: any, value: any) => {
    setTabIndex(value);
  };

  const handleMultiUpdate = (updateInfo: any) => {
    if (props.editStatusQuo) {
      return props.handleMultiUpdate(updateInfo);
    }
  };

  const handlePopulateNBC = () => {
    let currentTariff = findSelectedTariff(props.assumptions.tariffId);
    if (currentTariff && currentTariff.is_manual === false) {
      populateNBC(currentTariff.source_id, currentTariff.effective_date)
        .then(res => {
          assertIsDefined(currentTariff);
          const tariff = res.data;
          const { overview } = parseTariffInformationUpdated(tariff, currentTariff.applicability_values);

          props.handleAssumptionUpdateByKey('exportCost')(overview?.nonBypassableRate ?? 0);
        })
        .catch(function (error) {
          console.error('Error trying to fetch NBC charges: ' + error);
        });
    }
  };

  const findSelectedTariff = (tariffId: string): ESAPTariff | undefined => {
    return props.selectedTariffs.find(tariff => tariff.public_id === tariffId);
  };

  return (
    <div className={classes.root}>
      <AccessControl requiredPermissions={['viewer', 'editor']}>
        <Tabs value={tabIndex} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <Tab label="Strategies" value="strategies" icon={<List />} />
          <Tab
            label="Battery"
            value="battery"
            icon={<BatteryFull />}
            className={props.assumptions.isBatt ? classes.tabHighlight : ''}
          />
          <Tab
            label="PV"
            value="pv"
            icon={<WbSunny />}
            className={props.assumptions.isPV ? classes.tabHighlight : ''}
          />
          {!props.assumptions.isRealtime && (
            <Tab
              label="Microgrid"
              value="microgrid"
              icon={<GridOn />}
              className={props.assumptions.isGenset || props.assumptions.hasGridOutages ? classes.tabHighlight : ''}
            />
          )}
          {!props.assumptions.isRealtime && (
            <Tab
              label="EV"
              value="ev"
              icon={<DirectionsCar />}
              className={props.assumptions.isEV ? classes.tabHighlight : ''}
            />
          )}
        </Tabs>
      </AccessControl>
      {tabIndex === 'strategies' && (
        <div style={{ padding: 8 * 3 }}>
          {(() => {
            switch (utilityInfoStatus) {
              case 'loading':
                return (
                  <div style={{ textAlign: 'center', padding: 25 }}>
                    <CircularProgress color="secondary" size={50} />
                  </div>
                );
              case 'error':
                return (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    There was an error while trying to get the utility events info —{' '}
                    <strong>{`${utilityInfoError ? utilityInfoError.message! : ''}`}</strong>
                  </Alert>
                );
              case 'idle':
              case 'success':
                return props.assumptions.isBehindMeter && props.selectedTariffs?.length === 0 ? (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    There was an error while trying to get the utility events info —{' '}
                    <strong>{`Tariff might be missing.}`}</strong>
                  </Alert>
                ) : (
                  <ParametersConfig
                    handleToggle={props.handleToggle}
                    handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
                    handleAssumptionUpdateByKey={props.handleAssumptionUpdateByKey}
                    handlePopulateNBC={handlePopulateNBC}
                    handleMultiUpdate={handleMultiUpdate}
                    utilityInfo={utilityInfo}
                    tariffs={props.selectedTariffs}
                    scenario={props.scenario}
                    proposal={props.proposal}
                    {...props.assumptions}
                  />
                );
              default:
                assertUnreachable(utilityInfoStatus);
            }
          })()}
        </div>
      )}
      {tabIndex === 'battery' && (
        <div style={{ padding: 8 * 3 }}>
          <BatteryConfig
            handleToggle={props.handleToggle}
            handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
            handleYearlyCycleAssumptionChange={props.handleYearlyCycleAssumptionChange}
            handleMonthlyBidAssumptionChange={props.handleMonthlyBidAssumptionChange}
            {...props.assumptions}
          />
        </div>
      )}
      {tabIndex === 'pv' && (
        <div style={{ padding: 8 * 3 }}>
          <PVConfig
            handleToggle={props.handleToggle}
            handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
            {...props.assumptions}
          />
        </div>
      )}
      {tabIndex === 'microgrid' && (
        <div style={{ padding: 8 * 3 }}>
          <MicrogridConfig
            handleToggle={props.handleToggle}
            handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
            setIsComplete={props.setIsComplete}
            handleAssumptionUpdateByKey={props.handleAssumptionUpdateByKey}
            {...props.assumptions}
          />
        </div>
      )}
      {tabIndex === 'ev' && (
        <div style={{ padding: 8 * 3 }}>
          <EVConfig
            tariffs={props.selectedTariffs}
            handleToggle={props.handleToggle}
            handleGeneralAssumptionChange={props.handleGeneralAssumptionChange}
            handleAssumptionUpdateByKey={props.handleAssumptionUpdateByKey}
            handleEVShiftAssumptionsChange={props.handleEVShiftAssumptionsChange}
            handleAddEVShift={props.handleAddEVShift}
            handleDeleteEVShift={props.handleDeleteEVShift}
            handleChangeEVProfileTemplate={props.handleChangeEVProfileTemplate}
            assumptions={props.assumptions}
          />
        </div>
      )}
    </div>
  );
};

export default AssumptionsDetail;
