import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilePicker from '../Utilities/FilePicker/FilePicker';
import { DataType } from '../../types';
import Papa from 'papaparse';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dialog: {
    padding: theme.spacing(4)
  },
  block: {
    display: 'flex',
    marginTop: theme.spacing(4)
  },
  button: {}
}));

export interface DataFileImportDialogProps {
  open: boolean;
  handleClose: any;
  handleSuccessClose: any;
  dataType: DataType;
}

const DataFileImportDialog: React.FC<DataFileImportDialogProps> = props => {
  const classes = useStyles();
  const { handleClose, handleSuccessClose, open, dataType } = props;

  const [file, setFile] = useState<File | null>(null);
  const [fields, setFields] = useState<string[] | null>();
  const [column, setColumn] = useState({ dates: '', values: '' });

  useEffect(() => {
    const handleNewFields = (fields: string[]) => {
      setFields(fields.filter(field => field !== ''));
    };
    if (dataType !== DataType.solar && file) {
      Papa.parse(file, {
        header: true,
        preview: 1,
        complete: results => handleNewFields(results.meta.fields as string[])
      });
    }
  }, [file]);

  useEffect(() => {
    setFile(null);
    setColumn({ dates: '', values: '' });
    setFields(null);
  }, [dataType]);

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setColumn(oldValues => ({
      ...oldValues,
      [event.target.name as string]: event.target.value
    }));
  };
  const handleSuccess = () => {
    if ((file && column.dates && column.values) || dataType == DataType.solar) {
      handleSuccessClose(file, column.dates, column.values, dataType);
    }
  };
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
      <DialogTitle>Select File and columns to import</DialogTitle>
      <DialogTitle>{dataType} data.</DialogTitle>
      <DialogContent>
        <FilePicker onChange={file => setFile(file)} onError={err => console.error(err)}>
          <Button variant="contained" size="small" className={classes.button}>
            Select File
          </Button>
        </FilePicker>
        <Typography variant="caption">File: {file ? file.name : 'Select CSV file'}</Typography>
        {fields && (
          <div className={classes.block}>
            <form className={classes.root} autoComplete="off">
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-simple">Date Column</InputLabel>
                <Select
                  value={column.dates}
                  onChange={handleChange}
                  inputProps={{
                    name: 'dates',
                    id: 'column-dates'
                  }}
                >
                  {fields.map(field => (
                    <MenuItem key={field} value={field}>
                      {field}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-helper">Value Column</InputLabel>
                <Select
                  value={column.values}
                  onChange={handleChange}
                  inputProps={{
                    name: 'values',
                    id: 'column-values'
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {fields.map(field => (
                    <MenuItem key={field} value={field}>
                      {field}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSuccess} color="primary">
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DataFileImportDialog;
