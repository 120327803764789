import { GET_FREQUENTLY_USED_TARIFFS } from '../../actions/tariffs';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FREQUENTLY_USED_TARIFFS:
      return action.payload;
    default:
      return state;
  }
}
