import React from 'react';

interface VerticalWhiteSpaceProps {
  space?: number;
}

/** Takes a optional space prop that is the bottom padding in pixels, default to 24 */
const VerticalWhiteSpace: React.FC<VerticalWhiteSpaceProps> = ({ space = 24 }) => {
  return <div style={{ width: '100%', flex: 1, paddingBottom: space }}></div>;
};

export default VerticalWhiteSpace;
