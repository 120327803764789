import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import IconTooltip from './IconTooltip';
import { makeStyles } from '@material-ui/core/styles';

interface InputLabelTooltipProps {
  title: string;
  label: string;
  htmlFor: string;
}

const useStyles = makeStyles(() => ({
  root: {
    top: -3,
    fontSize: '1rem'
  }
}));

const InputLabelTooltip: React.FC<InputLabelTooltipProps> = ({ title, label, htmlFor }) => {
  const classes = useStyles();
  return (
    <InputLabel htmlFor={htmlFor} classes={{ root: classes.root }} shrink={true}>
      <span>
        {label} <IconTooltip title={title} />
      </span>
    </InputLabel>
  );
};

export default InputLabelTooltip;
