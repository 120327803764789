import { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ProposalDetailContext } from '../../Proposals/Detail/ProposalDetailContextProvider';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const MultiYearSelector = () => {
  const {
    monthsList,
    selectedYears,
    setSelectedYears,
    selectedMonths,
    setSelectedMonths,
    validYears,
    validMonths
  } = useContext(ProposalDetailContext);

  const isAfter = (year1: string, year2: string): boolean => {
    const monthStart =
      (selectedMonths.start + 1).toString().length === 1
        ? '0' + (selectedMonths.start + 1).toString()
        : (selectedMonths.start + 1).toString();
    const monthEnd =
      (selectedMonths.end + 1).toString().length === 1
        ? '0' + (selectedMonths.end + 1).toString()
        : (selectedMonths.end + 1).toString();
    return moment(`${year1}-${monthStart}-01`).isAfter(`${year2}-${monthEnd}-01`);
  };

  const isOutOfBounds = (year: string, indexMonth: number): boolean => {
    if (validMonths.length == 0 || !validMonths) return false;
    const month = indexMonth.toString().length > 1 ? indexMonth.toString() : '0' + indexMonth.toString();
    const start = moment(validMonths[0], 'MM/DD/YYYY');
    const end = moment(validMonths[validMonths.length - 1], 'MM/DD/YYYY');
    const range = moment.range(start, end);
    return !moment(`${year}-${month}`).within(range);
  };

  return (
    <AppBar
      position="sticky"
      style={{ backgroundColor: 'white', paddingTop: 16, paddingBottom: 16, zIndex: 99999 }}
      id="multi-years-selector"
    >
      <Grid container justify="center" spacing={10} alignContent="center">
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Start Date:</FormLabel>
            <FormGroup style={{ flexDirection: 'row' }}>
              <Select
                style={{ marginRight: 16 }}
                value={selectedYears.start}
                onChange={e => setSelectedYears({ ...selectedYears, start: e?.target.value as string })}
                inputProps={{
                  name: 'selectedYearStart'
                }}
              >
                {validYears.map((year, i) => {
                  return (
                    <MenuItem disabled={isAfter(validYears[i], selectedYears.end)} key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                value={selectedMonths.start}
                onChange={e => setSelectedMonths({ ...selectedMonths, start: parseInt(e.target.value as string) })}
                inputProps={{
                  name: 'selectedMonthsStart'
                }}
              >
                {monthsList.map((month, i) => {
                  return (
                    <MenuItem
                      disabled={
                        (selectedYears.start >= selectedYears.end && i > selectedMonths.end) ||
                        isOutOfBounds(selectedYears.start, i + 1)
                      }
                      key={month}
                      value={i}
                    >
                      {month}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">End Date:</FormLabel>
            <FormGroup style={{ flexDirection: 'row' }}>
              <Select
                style={{ marginRight: 16 }}
                data-testid="yearEndSelector"
                value={selectedYears.end}
                onChange={e => setSelectedYears({ ...selectedYears, end: e?.target.value as string })}
                inputProps={{
                  name: 'selectedYearEnd'
                }}
              >
                {validYears.map((year, i) => {
                  return (
                    <MenuItem disabled={isAfter(selectedYears.start, validYears[i])} key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                value={selectedMonths.end}
                onChange={e => setSelectedMonths({ ...selectedMonths, end: parseInt(e.target.value as string) })}
                inputProps={{
                  name: 'selectedMonthsEnd'
                }}
              >
                {monthsList.map((month, i) => {
                  return (
                    <MenuItem
                      disabled={
                        (selectedYears.start >= selectedYears.end && i < selectedMonths.end) ||
                        isOutOfBounds(selectedYears.end, i + 1)
                      }
                      key={month}
                      value={i}
                    >
                      {month}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default MultiYearSelector;
