import React from 'react';
import { connect } from 'react-redux';

// MATERIAL UI IMPORTS
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Settings from '@material-ui/icons/Settings';
import { Divider } from '@material-ui/core';

// Comparison Options Generator imports
import { extraKeys, keys, MonthlyTableOption } from './MonthlySummaryOptions/MonthlySummaryOptionsConfig';
import { buildChartOptions } from './MonthlySummaryOptions/ChartOptions';
import { generateDemandChargeComparisonOptions } from './MonthlySummaryOptions/DemandChargeComparisonOptions';
import { generateDemandUsageComparisonOptions } from './MonthlySummaryOptions/DemandUsageComparisonOptions';
import { generateEnergyChargeComparisonOptions } from './MonthlySummaryOptions/EnergyChargeComparisonOptions';
import { generateEnergyUsageComparisonOptions } from './MonthlySummaryOptions/EnergyUsageComparisonOptions';
import { generatePerfectKnowledgeComparisonOptions } from './MonthlySummaryOptions/PerfectKnowledgeComparisonOptions';
import { generatePJMChargesComparisonOptions } from './MonthlySummaryOptions/PJMChargesComparisonOptions';
import { generatePDPComparisonOptions } from './MonthlySummaryOptions/PDPComparisonOptions';
import { generateBIPComparisonOptions } from './MonthlySummaryOptions/BIPComparisonOptions';
import { generatePDRComparisonOptions } from './MonthlySummaryOptions/PDRComparisonOptions';
import { generateGHGComparisonOptions } from './MonthlySummaryOptions/GHGComparisonOptions';
import { generateVDERComparisonOptions } from './MonthlySummaryOptions/VDERComparisonOptions';
import { generateIESOComparisonOptions } from './MonthlySummaryOptions/IESOComparisonOptions';
import { generateBatteryComparisonOptions } from './MonthlySummaryOptions/BatteryComparisonOptions';
import { generateSolarComparisonOptions } from './MonthlySummaryOptions/SolarComparisonOptions';
import { generateEVResultsOptions } from './MonthlySummaryOptions/EVResultsOptions';
import { generateMicrogridInformationOptions } from './MonthlySummaryOptions/MicrogridInformationOptions';
import { generateEnergyExportedOptions } from './MonthlySummaryOptions/EnergyExportedOptions';
import { generateNetMeteringOptions } from './MonthlySummaryOptions/NetMeteringOptions';
import { generateConnectedSolutionsOptions } from './MonthlySummaryOptions/ConnectedSolutionsOptions';
import { generateCleanPeakStandardOptions } from './MonthlySummaryOptions/CleanPeakStandardOptions';

// REACT IMPORTS
import { exportCSVFile } from '../../../utility/General';
import {
  calculateDemandReductionNC,
  calculateDemandReductionPeak,
  calculateDemandSavings,
  calculateEnergySavings,
  calculateGHGEmissionsPerTOU,
  calculateMonthlyTotalSavings,
  calculateSavingsFunc,
  calculateSavingsPercentageFunc,
  calculateTotal,
  calculateTotalGHGReduction,
  CostResultKeys,
  getRounding
} from '../../../utility/Savings';
import { MonthlyOperationsData, MonthlyTableKey, ScenarioInternal, ScenarioResults } from '../../../types';
import { assertIsDefined } from '../../../utils/assertions';
import { getGlobalSettingByKey } from '../../../actions/globalsettings';
import { Setting, SettingKey, StoreState } from '../../../reducers';

enum TableViewOptions {
  DefaultTableView = 'Default Table View',
  DemandUsageComparison = 'Demand Usage Comparison',
  DemandChargesComparison = 'Demand Charge Comparison',
  EnergyChargesComparison = 'Energy Charges Comparison',
  EnergyUsageComparison = 'Energy Usage Comparison',
  RealtimeComparison = 'Realtime Comparison',
  SolarDetails = 'Solar Details',
  BatteryDetails = 'Battery Details',
  EVResults = 'EV Results',
  MicrogridInformation = 'Microgrid Information',
  PDPComparison = 'PDP Comparison',
  BIPComparison = 'BIP Comparison',
  ICAPITRANChargesComparison = 'ICAP/ITRAN Charges Comparison',
  NetMetering = 'Net Metering',
  PDRComparison = '"PDR Comparison',
  GHGComparison = 'GHG Comparison',
  VDERDetails = 'VDER Details',
  IESODetails = 'IESO Details',
  EnergyExported = 'Energy Exported',
  ConnectedSolutionsDR = 'Connected Solutions DR',
  CleanPeakStandard = 'Clean Peak Standard'
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto'
    },
    flex: {
      flex: 1
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    tableContainer: {
      padding: '0 0 25px 0',
      width: '100%',
      overflowX: 'auto'
    },
    table: {
      minWidth: 700,
      border: '1px solid rgb(234, 234, 234)',
      '& td, & th': {
        padding: '0 6px',
        textAlign: 'center',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        fontSize: '11px',
        color: '#333'
      },
      '& tr': {
        height: 32
      },
      '& tr>td:first-child, & tr:first-child>th:first-child': {
        fontWeight: '500',
        color: '#17173c',
        verticalAlign: 'middle',
        backgroundColor: '#ebf6ff'
      },
      '& tr:first-child th': {
        whiteSpace: 'normal',
        fontWeight: '500',
        color: '#111',
        backgroundColor: '#e8f1ee',
        lineHeight: '1.2em',
        verticalAlign: 'top',
        padding: '9px',
        fontSize: '11px'
      }
    },
    statusQuoHeader: {
      backgroundColor: '#ccc',
      textAlign: 'center',
      color: '#000'
    },
    scenarioHeader: {
      backgroundColor: '#0071FF',
      textAlign: 'center',
      color: '#fff'
    },
    savingsHeader: {
      backgroundColor: '#333',
      textAlign: 'center',
      color: '#fff'
    },
    highlight: {
      backgroundColor: 'rgba(255, 255, 0, 0.5)',
      fontWeight: 'bold'
    }
  });

export interface IProps {
  classes: any;
  scenarioResult: ScenarioResults;
  statusQuoResult: ScenarioResults;
  statusQuoTitle: string;
  discountRate: { demand: number; energy: number };
  scenarioTitle: string;
  statusQuo: ScenarioInternal;
  scenario: ScenarioInternal;
  scenarios: ScenarioInternal[];
  selectedBaseScenarioId: string;
  getGlobalSettingByKey: (setting: SettingKey, options?: any) => void;
  setting: Setting;
  scenarioOperationsData: MonthlyOperationsData;
  statusQuoOperationsData?: MonthlyOperationsData;
}

export type MonthlyTableData = Record<MonthlyTableKey, number> | { Months: string };

interface IState {
  open: boolean;
  data: MonthlyTableData[];
  tableViewSelected: TableViewOptions;
  editChartOptions: MonthlyTableOption[];
  chartOptions: MonthlyTableOption[];
  perfectKnowledgeSelected: string | undefined;
  perfectKnowledgeResults: ScenarioResults;
}

class ScenarioMonthlyResultsTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
      data: [],
      tableViewSelected: TableViewOptions.DefaultTableView,
      editChartOptions: [],
      chartOptions: [],
      perfectKnowledgeSelected: undefined,
      perfectKnowledgeResults: null
    };
  }

  componentDidMount() {
    const chartOptions = buildChartOptions();
    const data = this.buildData();
    this.setState({ data, editChartOptions: chartOptions, chartOptions: chartOptions.slice(0) });
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.discountRate && prevProps.discountRate && this.props.discountRate !== prevProps.discountRate) {
      const data = this.buildData();
      this.setState({ data });
    }

    if (
      prevProps.scenarioResult !== this.props.scenarioResult ||
      prevProps.statusQuoResult !== this.props.statusQuoResult
    ) {
      this.setState({ data: this.buildData() });
    }
  }

  generateEVTotals = (
    scenario: ScenarioInternal,
    data: MonthlyTableData,
    result: ScenarioResults,
    i: number,
    isStatusQuo: boolean
  ) => {
    assertIsDefined(result);
    let copy = { ...data };
    const prefix = isStatusQuo ? 'Before ' : 'After ';
    const totalDemandCost = (+result['EV Base Subscription Cost'][i] ?? 0) + (+result['EV Overage Cost'][i] ?? 0);
    const energyCost = scenario.assumptions.useSmartCharging
      ? +result['Smart Charging Cost'][i]
      : +result['Dumb Charging Cost'][i];
    const totalCost = totalDemandCost + energyCost;
    const averageCost = +result['EV Fleet kWh charged'][i] === 0 ? 0 : totalCost / +result['EV Fleet kWh charged'][i];
    copy[prefix + 'EV Total Demand Cost'] = totalDemandCost.toFixed(0);
    copy[prefix + 'EV Total Cost'] = totalCost.toFixed(0);
    copy[prefix + 'EV Total Average Cost'] = averageCost;
    return copy;
  };

  buildData = (): MonthlyTableData[] => {
    if (this.state.tableViewSelected === 'Clean Peak Standard') {
      if (this.props.setting.key === 'ISONE CPS') {
        return this.buildCPSData(this.props.setting);
      } else {
        this.props.getGlobalSettingByKey('ISONE CPS', {
          onSuccess: (setting: Setting) => {
            this.setState({ data: this.buildCPSData(setting) });
            return;
          }
        });
      }
    }

    if (this.state.tableViewSelected === 'GHG Comparison') {
      return this.buildGHGData();
    }
    const data: MonthlyTableData[] = [];
    assertIsDefined(this.props.statusQuoResult);
    assertIsDefined(this.props.scenarioResult);
    const minLength = Math.min(this.props.statusQuoResult['Months'].length, this.props.scenarioResult['Months'].length);
    const months = this.props.statusQuoResult['Months'].slice(0, minLength);
    months.forEach((month, index) => {
      assertIsDefined(this.props.statusQuoResult);
      assertIsDefined(this.props.scenarioResult);
      let dataItem: MonthlyTableData = { Months: month };
      if (this.props.scenarioResult['Bill Range'] && this.props.scenarioResult['Bill Range'][index]) {
        dataItem['Bill Range'] = this.props.scenarioResult['Bill Range'][index];
      }
      // building data from status quo
      keys.forEach(key => {
        assertIsDefined(this.props.statusQuoResult);
        if (this.props.statusQuoResult[key]) {
          dataItem['Before ' + key] = (+this.props.statusQuoResult[key][index]).toFixed(0);
        }
      });
      extraKeys.forEach(key => {
        assertIsDefined(this.props.statusQuoResult);
        if (this.props.statusQuoResult[key]) {
          switch (key) {
            case 'Average SOC':
            case 'Charge From PV':
            case 'Degradation Rate':
            case 'Effective CP Rate':
            case 'Genset Runtime':
            case 'Genset Average Efficiency':
            case 'Genset Average Fuel Rate':
            case 'Islanded Battery Cycles':
              dataItem['Before ' + key] = Math.round(+this.props.statusQuoResult[key][index] * 100) / 100;
              break;
            case 'Load Kgs CO2 / kWh':
              dataItem['Before ' + key] = (+this.props.statusQuoResult[key][index]).toFixed(2);
              break;
            case 'Demand Peak Factor GA':
              dataItem['Before ' + key] = (+this.props.statusQuoResult[key][index]).toFixed(8);
              break;
            default:
              dataItem['Before ' + key] = (+this.props.statusQuoResult[key][index]).toFixed(0);
              break;
          }

          // TODO: This logic is never run as written
          if (key.indexOf('EV Base Subscription Cost') > -1 && key.indexOf('EV Overage Cost') > -1) {
            dataItem = this.generateEVTotals(this.props.statusQuo, dataItem, this.props.statusQuoResult, index, true);
          }
        }
      });

      //build data from scenario
      keys.forEach(key => {
        const discountRate = key.includes('Demand') ? this.props.discountRate.demand : this.props.discountRate.energy;
        const scenarioValue =
          this.props.scenarioResult && this.props.scenarioResult[key] && this.props.scenarioResult[key][index]
            ? this.props.scenarioResult[key][index]
            : 0;
        const statusQuoValue =
          this.props.statusQuoResult && this.props.statusQuoResult[key] && this.props.statusQuoResult[key][index]
            ? this.props.statusQuoResult[key][index]
            : 0;
        const sq_scenario_spread = +statusQuoValue - +scenarioValue;
        let adjustmentValue = sq_scenario_spread * (1 - discountRate);
        dataItem['After ' + key] = (+scenarioValue + +adjustmentValue).toFixed(0);
      });
      extraKeys.forEach(key => {
        const discountRate = key.includes('Demand') ? this.props.discountRate.demand : this.props.discountRate.energy;
        const scenarioValue =
          this.props.scenarioResult && this.props.scenarioResult[key] && this.props.scenarioResult[key][index]
            ? this.props.scenarioResult[key][index]
            : 0;
        const statusQuoValue =
          this.props.statusQuoResult && this.props.statusQuoResult[key] && this.props.statusQuoResult[key][index]
            ? this.props.statusQuoResult[key][index]
            : 0;
        const sq_scenario_spread = +statusQuoValue - +scenarioValue;
        let adjustmentValue = sq_scenario_spread * (1 - discountRate);

        switch (key) {
          case 'Average SOC':
          case 'Charge From PV':
          case 'Degradation Rate':
          case 'Effective CP Rate':
          case 'Genset Runtime':
          case 'Genset Average Efficiency':
          case 'Genset Average Fuel Rate':
          case 'Islanded Battery Cycles':
            dataItem['After ' + key] = Math.round((+scenarioValue + +adjustmentValue) * 100) / 100;
            break;
          case 'Demand Peak Factor GA':
            dataItem['After ' + key] = (+scenarioValue + +adjustmentValue).toFixed(8);
            break;
          default:
            dataItem['After ' + key] = (+scenarioValue + +adjustmentValue).toFixed(0);
            break;
        }

        // TODO: This code never executes as written. When it does it thows and exception
        if (key.indexOf('EV Base Subscription Cost') > -1 && key.indexOf('EV Overage Cost') > -1) {
          dataItem = this.generateEVTotals(this.props.scenario, dataItem, this.props.statusQuoResult, index, false);
        }
      });

      dataItem['Total GHG Reduction / kWh'] = calculateTotalGHGReduction(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult
      ).toFixed(0);
      dataItem['Demand Reduction NC'] = calculateDemandReductionNC(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult
      ).toFixed(0);
      dataItem['Demand Reduction Peak'] = calculateDemandReductionPeak(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult
      ).toFixed(0);
      dataItem['Total Demand Savings'] = +calculateDemandSavings(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult,
        this.props.discountRate.demand
      ).toFixed(0);
      dataItem['Total Energy Savings'] = +calculateEnergySavings(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult,
        this.props.discountRate.energy
      ).toFixed(0);
      dataItem['Total Savings'] = +calculateMonthlyTotalSavings(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult,
        this.props.discountRate.demand,
        this.props.discountRate.energy
      ).toFixed(0);
      extraKeys.forEach(key => {
        assertIsDefined(this.props.scenarioResult);
        if (this.props.scenarioResult[key]) {
          switch (key) {
            case 'Average SOC':
            case 'Charge From PV':
            case 'Degradation Rate':
            case 'Effective CP Rate':
              dataItem[key] = Math.round(+this.props.scenarioResult[key][index] * 100) / 100;
              break;
            case 'Load Kgs CO2 / kWh':
              dataItem[key] = +(+this.props.scenarioResult[key][index]).toFixed(2);
              break;
            default:
              dataItem[key] = +(+this.props.scenarioResult[key][index]).toFixed(0);
              break;
          }
        }
      });
      data.push(dataItem);
    });
    return data;
  };

  buildGHGData = (): MonthlyTableData[] => {
    const data: MonthlyTableData[] = [];
    assertIsDefined(this.props.statusQuoResult);
    assertIsDefined(this.props.scenarioResult);
    assertIsDefined(this.props.statusQuoOperationsData);
    const scenarioGHGData = calculateGHGEmissionsPerTOU(this.props.scenarioOperationsData);
    const statusQuoGHGData = calculateGHGEmissionsPerTOU(this.props.statusQuoOperationsData);

    const calcReduction = (tou: string, index: number): number => {
      return statusQuoGHGData[index].emissions[tou] - scenarioGHGData[index].emissions[tou];
    };

    const months = this.props.statusQuoResult['Months'];
    months.forEach((month, index) => {
      const totalReduction =
        this.props.statusQuoResult!['Total Kgs CO2'][index] - this.props.scenarioResult!['Total Kgs CO2'][index];
      let dataItem: MonthlyTableData = { Months: month };
      const operationMonthFromIndex = Object.keys(this.props.scenarioOperationsData)[index];

      const solarOnlyEmissions = this.props.scenarioOperationsData[operationMonthFromIndex]?.mef.reduce(
        (total, mef, i) => {
          if (!this?.props?.scenarioOperationsData[operationMonthFromIndex]?.vecPInv[i]) return total;
          return total + mef * (this.props.scenarioOperationsData[operationMonthFromIndex].vecPInv[i] / 4);
        },
        0
      );

      dataItem['Before Total GHG Emissions'] = this.props.statusQuoResult!['Total Kgs CO2'][index];
      dataItem['After Total GHG Emissions'] = this.props.scenarioResult!['Total Kgs CO2'][index];
      dataItem['Total Peak GHG Reductions'] = calcReduction('ON_PEAK', index);
      dataItem['Total Part Peak GHG Reductions'] = calcReduction('PARTIAL_PEAK', index);
      dataItem['Total Off Peak GHG Reductions'] = calcReduction('OFF_PEAK', index);
      dataItem['Total GHG Reductions'] = totalReduction;

      dataItem['Total GHG Reductions After Battery'] = !this.props.scenario.assumptions.capacity
        ? 0
        : totalReduction / this.props.scenario.assumptions.capacity;
      dataItem['Solar Impact'] = solarOnlyEmissions;
      data.push(dataItem);
    });

    return data;
  };

  buildCPSData = (setting: Setting): MonthlyTableData[] => {
    const data: MonthlyTableData[] = [];
    assertIsDefined(this.props.statusQuoResult);
    assertIsDefined(this.props.scenarioResult);
    const minLength = Math.min(this.props.statusQuoResult['Months'].length, this.props.scenarioResult['Months'].length);
    const months = this.props.statusQuoResult['Months'].slice(0, minLength);
    months.forEach((month, index) => {
      assertIsDefined(this.props.statusQuoResult);
      assertIsDefined(this.props.scenarioResult);
      let dataItem: MonthlyTableData = { Months: month };
      dataItem['Num CPEC Produced'] = this.props.scenarioResult['Num CPEC Produced'][index];
      dataItem['Average kW Discharged CPS'] = this.props.scenarioResult['Average kW Discharged CPS'][index];
      dataItem['Average kWh Discharged CPS'] = this.props.scenarioResult['Average kWh Discharged CPS'][index];
      dataItem['CPS_CREDIT_VALUE'] = this.props.scenarioResult['CPS_CREDIT_VALUE'][index];
      data.push(dataItem);
    });
    return data;
  };

  buildPerfectKnowledgeData = (perfectKnowledgeScenario: ScenarioInternal): MonthlyTableData[] => {
    const data: MonthlyTableData[] = [];
    assertIsDefined(this.props.statusQuoResult);
    assertIsDefined(perfectKnowledgeScenario.results);
    const minLength = Math.min(
      this.props.statusQuoResult['Months'].length,
      perfectKnowledgeScenario.results['Months'].length
    );
    const months = this.props.statusQuoResult['Months'].slice(0, minLength);
    months.forEach((month, index) => {
      let dataItem: MonthlyTableData = { Months: month };

      //
      // Perfect Knowledge Savings
      //
      const calculatePKDemandCostNC = calculateSavingsFunc(CostResultKeys.DemandCostNC);
      dataItem['Perfect Knowledge NC Demand Savings'] = calculatePKDemandCostNC(
        index,
        this.props.statusQuoResult,
        perfectKnowledgeScenario.results
      ).toFixed(0);

      const calculatePKDemandPeakCost = calculateSavingsFunc(CostResultKeys.DemandCostPeak);
      dataItem['Perfect Knowledge On-Peak Demand Savings'] = calculatePKDemandPeakCost(
        index,
        this.props.statusQuoResult,
        perfectKnowledgeScenario.results
      ).toFixed(0);

      const calculatePKDemandMidPeak = calculateSavingsFunc(CostResultKeys.DemandCostMidPeak);
      dataItem['Perfect Knowledge Mid-Peak Demand Savings'] = calculatePKDemandMidPeak(
        index,
        this.props.statusQuoResult,
        perfectKnowledgeScenario.results
      ).toFixed(0);

      const calculatePKEnergySavings = calculateSavingsFunc(CostResultKeys.EnergyCostTotal);
      dataItem['Perfect Knowledge Energy Savings'] = calculatePKEnergySavings(
        index,
        this.props.statusQuoResult,
        perfectKnowledgeScenario.results
      ).toFixed(0);

      //
      // Realtime Savings
      //
      const calculateRTDemandCostNC = calculateSavingsFunc(CostResultKeys.DemandCostNC);
      dataItem['Realtime NC Demand Savings'] = calculateRTDemandCostNC(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult
      ).toFixed(0);

      const calculateRTDemandPeakCost = calculateSavingsFunc(CostResultKeys.DemandCostPeak);
      dataItem['Realtime On-Peak Demand Savings'] = calculateRTDemandPeakCost(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult
      ).toFixed(0);

      const calculateRTDemandMidPeak = calculateSavingsFunc(CostResultKeys.DemandCostMidPeak);
      dataItem['Realtime Mid-Peak Demand Savings'] = calculateRTDemandMidPeak(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult
      ).toFixed(0);

      const calculateRTEnergySavings = calculateSavingsFunc(CostResultKeys.EnergyCostTotal);
      dataItem['Realtime Energy Savings'] = calculateRTEnergySavings(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult
      ).toFixed(0);

      //
      // Percent Perfect Knowledge
      //
      const calculatePKNCDemandSavingsPercent = calculateSavingsPercentageFunc(CostResultKeys.DemandCostNC);
      dataItem['% from Perfect Knowledge - NC Demand Savings'] = calculatePKNCDemandSavingsPercent(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult,
        perfectKnowledgeScenario.results
      ).toFixed(0);

      const calculatePKOnPeakDemandPercent = calculateSavingsPercentageFunc(CostResultKeys.DemandCostPeak);
      dataItem['% from Perfect Knowledge - On-Peak Demand Savings'] = calculatePKOnPeakDemandPercent(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult,
        perfectKnowledgeScenario.results
      ).toFixed(0);

      const calculatePKMidPeakDemandPercent = calculateSavingsPercentageFunc(CostResultKeys.DemandCostMidPeak);
      dataItem['% from Perfect Knowledge - Mid-Peak Demand Savings'] = calculatePKMidPeakDemandPercent(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult,
        perfectKnowledgeScenario.results
      ).toFixed(0);

      const calculatePKEnergySavingsPercent = calculateSavingsPercentageFunc(CostResultKeys.EnergyCostTotal);
      dataItem['% from Perfect Knowledge - Energy Savings'] = calculatePKEnergySavingsPercent(
        index,
        this.props.statusQuoResult,
        this.props.scenarioResult,
        perfectKnowledgeScenario.results
      ).toFixed(0);

      data.push(dataItem);
    });
    return data;
  };

  handleDownload = () => {
    let downloadData: any = [];
    let headers = this.state.chartOptions.filter(column => column.include).map(item => item.label);
    this.state.data.forEach(item => {
      downloadData.push(this.state.chartOptions.filter(column => column.include).map(column => item[column.key]));
    });
    exportCSVFile(headers, downloadData, 'Scenario Monthly Results');
  };

  handleClose = () => {
    this.setState({
      open: false,
      editChartOptions: [...this.state.chartOptions]
    });
  };

  handleSuccessClose = () => {
    const data = this.buildData();
    this.setState({
      data: data,
      chartOptions: [...this.state.editChartOptions],
      open: false
    });
  };

  handleMoveItem = (upDirection: boolean, item: MonthlyTableOption) => () => {
    const updatedEditChartOptions = this.state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.key === currentItem.key);
    if (updateIndex > 0 && upDirection) {
      const tempOptionHolder = updatedEditChartOptions.splice(updateIndex, 1)[0];
      updatedEditChartOptions.splice(updateIndex - 1, 0, tempOptionHolder);
    } else if (updateIndex !== updatedEditChartOptions.length - 1 && !upDirection) {
      const tempOptionHolder = updatedEditChartOptions.splice(updateIndex, 1)[0];
      updatedEditChartOptions.splice(updateIndex + 1, 0, tempOptionHolder);
    }
    this.setState({
      editChartOptions: updatedEditChartOptions
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleColumnToggle = (item: MonthlyTableOption) => (event: any) => {
    const updatedEditChartOptions = this.state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.key === currentItem.key);
    if (updateIndex > -1) {
      updatedEditChartOptions[updateIndex][event.target.name] = !updatedEditChartOptions[updateIndex][
        event.target.name
      ];
    }
    this.setState({
      editChartOptions: updatedEditChartOptions
    });
  };

  handlePerfectKnowledgeComparisonChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    assertIsDefined(
      event?.target?.value,
      'Expecting a value coming from the event target, got: ' + event?.target?.value
    );
    let selected = event.target.value as string;
    this.setState(
      {
        perfectKnowledgeSelected: selected,
        editChartOptions: generatePerfectKnowledgeComparisonOptions()
      },
      () => {
        const perfectKnowledgeScenario = this.props.scenarios.find(scenario => {
          return scenario.public_id === this.state.perfectKnowledgeSelected;
        });
        assertIsDefined(perfectKnowledgeScenario);
        const data = this.buildPerfectKnowledgeData(perfectKnowledgeScenario);
        this.setState({
          data: data,
          chartOptions: [...this.state.editChartOptions],
          open: false,
          perfectKnowledgeResults: perfectKnowledgeScenario.results
        });
      }
    );
  };

  handleChangeTemplate = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    assertIsDefined(
      event?.target?.value,
      'Expecting a value coming from the event target, got: ' + event?.target?.value
    );
    let updatedEditChartOptions: MonthlyTableOption[] = [];
    let tableViewSelected = event.target.value as TableViewOptions;
    switch (event.target.value) {
      case TableViewOptions.DefaultTableView:
        updatedEditChartOptions = buildChartOptions();
        break;
      case TableViewOptions.DemandUsageComparison:
        updatedEditChartOptions = generateDemandUsageComparisonOptions();
        break;
      case TableViewOptions.DemandChargesComparison:
        updatedEditChartOptions = generateDemandChargeComparisonOptions();
        break;
      case TableViewOptions.EnergyChargesComparison:
        updatedEditChartOptions = generateEnergyChargeComparisonOptions();
        break;
      case TableViewOptions.EnergyUsageComparison:
        updatedEditChartOptions = generateEnergyUsageComparisonOptions();
        break;
      case TableViewOptions.RealtimeComparison:
        updatedEditChartOptions = generatePerfectKnowledgeComparisonOptions();
        break;
      case TableViewOptions.ICAPITRANChargesComparison:
        updatedEditChartOptions = generatePJMChargesComparisonOptions();
        break;
      case TableViewOptions.PDPComparison:
        updatedEditChartOptions = generatePDPComparisonOptions();
        break;
      case TableViewOptions.BIPComparison:
        updatedEditChartOptions = generateBIPComparisonOptions();
        break;
      case TableViewOptions.PDRComparison:
        updatedEditChartOptions = generatePDRComparisonOptions();
        break;
      case TableViewOptions.GHGComparison:
        updatedEditChartOptions = generateGHGComparisonOptions();
        break;
      case TableViewOptions.VDERDetails:
        updatedEditChartOptions = generateVDERComparisonOptions();
        break;
      case TableViewOptions.IESODetails:
        updatedEditChartOptions = generateIESOComparisonOptions();
        break;
      case TableViewOptions.BatteryDetails:
        updatedEditChartOptions = generateBatteryComparisonOptions();
        break;
      case TableViewOptions.SolarDetails:
        updatedEditChartOptions = generateSolarComparisonOptions();
        break;
      case TableViewOptions.EVResults:
        updatedEditChartOptions = generateEVResultsOptions();
        break;
      case TableViewOptions.MicrogridInformation:
        updatedEditChartOptions = generateMicrogridInformationOptions();
        break;
      case TableViewOptions.EnergyExported:
        updatedEditChartOptions = generateEnergyExportedOptions(this.props);
        break;
      case TableViewOptions.NetMetering:
        updatedEditChartOptions = generateNetMeteringOptions(this.props);
        break;
      case TableViewOptions.ConnectedSolutionsDR:
        updatedEditChartOptions = generateConnectedSolutionsOptions();
        break;
      case TableViewOptions.CleanPeakStandard:
        updatedEditChartOptions = generateCleanPeakStandardOptions();
        break;
      default:
        throw new Error(`Invalid event target value: ${event.target.value}`);
    }
    this.setState(
      {
        tableViewSelected: tableViewSelected,
        editChartOptions: updatedEditChartOptions
      },
      () => {
        const data = this.buildData();
        this.setState({
          data: data,
          chartOptions: [...this.state.editChartOptions],
          open: false
        });
      }
    );
  };

  formatSummaryOutput = (item: MonthlyTableData, key: MonthlyTableKey) => {
    if (item && key && item[key]) {
      return item[key].toLocaleString('en-US', {
        minimumFractionDigits: getRounding(key)
      });
    }
    return 0;
  };

  getRowStyle = (i: number) => {
    const data = this.state.data;
    const borderLeft =
      (data[i] &&
        data[i + 1] &&
        data[i]['Bill Range'] &&
        data[i + 1]['Bill Range'] &&
        data[i]['Bill Range'] === data[i + 1]['Bill Range']) ||
      (data[i] &&
        data[i - 1] &&
        data[i]['Bill Range'] &&
        data[i - 1]['Bill Range'] &&
        data[i]['Bill Range'] === data[i - 1]['Bill Range'])
        ? '4px inset rgb(80,158,47) '
        : 'inherit';

    const borderBottom =
      data[i] &&
      data[i + 1] &&
      data[i]['Bill Range'] &&
      data[i + 1]['Bill Range'] &&
      data[i]['Bill Range'] === data[i + 1]['Bill Range']
        ? 'hidden'
        : 'inherit';

    return { borderLeft, borderBottom };
  };

  renderPlaceholder() {
    return (
      !this.state.perfectKnowledgeSelected || this.state.perfectKnowledgeSelected == this.props.selectedBaseScenarioId
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Toolbar style={{ borderBottom: '1px solid rgb(229, 229, 229)' }}>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Scenario Monthly Summary
          </Typography>

          {this.state.tableViewSelected === TableViewOptions.RealtimeComparison && (
            <FormControl className={classes.formControl}>
              <Select
                value={this.state.perfectKnowledgeSelected}
                onChange={this.handlePerfectKnowledgeComparisonChange}
                displayEmpty
                renderValue={this.renderPlaceholder() ? () => <em>Select Perfect Knowledge Table</em> : undefined}
              >
                {this.props.scenarios != null &&
                  this.props.scenarios
                    .filter(
                      s => s.results && s.active && !s.is_status_quo && s.public_id != this.props.selectedBaseScenarioId
                    )
                    .map((s, index) => {
                      return (
                        <MenuItem value={s.public_id} key={s.public_id}>
                          {s.name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          )}

          <FormControl className={classes.formControl}>
            {/* <InputLabel htmlFor="template-select">Templates</InputLabel> */}
            <Select
              value={this.state.tableViewSelected}
              onChange={this.handleChangeTemplate}
              input={<Input name="templates" id="template-select" />}
            >
              <MenuItem value={TableViewOptions.DefaultTableView}>
                <em>{TableViewOptions.DefaultTableView}</em>
              </MenuItem>
              <MenuItem value={TableViewOptions.DemandUsageComparison}>
                {TableViewOptions.DemandUsageComparison}
              </MenuItem>
              <MenuItem value={TableViewOptions.DemandChargesComparison}>
                {TableViewOptions.DemandChargesComparison}
              </MenuItem>
              <MenuItem value={TableViewOptions.EnergyChargesComparison}>
                {TableViewOptions.EnergyChargesComparison}
              </MenuItem>
              <MenuItem value={TableViewOptions.EnergyUsageComparison}>
                {TableViewOptions.EnergyUsageComparison}
              </MenuItem>
              <MenuItem
                disabled={!this.props.scenario.assumptions.isRealtime}
                value={TableViewOptions.RealtimeComparison}
              >
                {TableViewOptions.RealtimeComparison}
              </MenuItem>
              <Divider />
              <MenuItem disabled={!this.props.scenario.assumptions.isPV} value={TableViewOptions.SolarDetails}>
                {TableViewOptions.SolarDetails}
              </MenuItem>
              <MenuItem disabled={!this.props.scenario.assumptions.isBatt} value={TableViewOptions.BatteryDetails}>
                {TableViewOptions.BatteryDetails}
              </MenuItem>
              <MenuItem disabled={!this.props.scenario.assumptions.isEV} value={TableViewOptions.EVResults}>
                {TableViewOptions.EVResults}
              </MenuItem>
              <MenuItem
                disabled={!this.props.scenario.assumptions.hasGridOutages}
                value={TableViewOptions.MicrogridInformation}
              >
                {TableViewOptions.MicrogridInformation}
              </MenuItem>
              <Divider />
              <MenuItem disabled={!this.props.scenario.assumptions.isPDP} value={TableViewOptions.PDPComparison}>
                {TableViewOptions.PDPComparison}
              </MenuItem>
              <MenuItem disabled={!this.props.scenario.assumptions.isBIP} value={TableViewOptions.BIPComparison}>
                {TableViewOptions.BIPComparison}
              </MenuItem>
              <MenuItem
                disabled={this.props.scenario.assumptions.selectedMarket !== 'PJM'}
                value={TableViewOptions.ICAPITRANChargesComparison}
              >
                {TableViewOptions.ICAPITRANChargesComparison}
              </MenuItem>
              <MenuItem disabled={!this.props.scenario.assumptions.isNetMetering} value={TableViewOptions.NetMetering}>
                {TableViewOptions.NetMetering}
              </MenuItem>
              <MenuItem disabled={!this.props.scenario.assumptions.isPDR} value={TableViewOptions.PDRComparison}>
                {TableViewOptions.PDRComparison}
              </MenuItem>
              <MenuItem
                disabled={
                  !(
                    this.props.scenario.assumptions.calculateGHGEmissions &&
                    !!this.props.statusQuoOperationsData &&
                    !!this.props.scenarioOperationsData
                  )
                }
                value={TableViewOptions.GHGComparison}
              >
                {TableViewOptions.GHGComparison}
              </MenuItem>
              <Divider />
              <MenuItem
                disabled={this.props.scenario.assumptions.selectedMarket !== 'NYISO'}
                value={TableViewOptions.VDERDetails}
              >
                {TableViewOptions.VDERDetails}
              </MenuItem>
              <MenuItem
                disabled={this.props.scenario.assumptions.selectedMarket !== 'IESO'}
                value={TableViewOptions.IESODetails}
              >
                {TableViewOptions.IESODetails}
              </MenuItem>
              <Divider />
              <MenuItem value={TableViewOptions.EnergyExported}>{TableViewOptions.EnergyExported}</MenuItem>
              <Divider />
              <MenuItem
                disabled={!this.props.scenario.assumptions.useConnectedSolutionsDR}
                value={TableViewOptions.ConnectedSolutionsDR}
              >
                {TableViewOptions.ConnectedSolutionsDR}
              </MenuItem>
              <MenuItem value={TableViewOptions.CleanPeakStandard}>{TableViewOptions.CleanPeakStandard}</MenuItem>
            </Select>
          </FormControl>
          <IconButton data-testid="resultTableSettings" aria-label="Results Table Settings" onClick={this.handleOpen}>
            <Settings />
          </IconButton>
          <IconButton aria-label="Download" onClick={this.handleDownload}>
            <InsertDriveFile />
          </IconButton>
        </Toolbar>
        {this.props.statusQuoResult &&
          this.props.scenarioResult &&
          Object.keys(this.props.statusQuoResult).length &&
          Object.keys(this.props.scenarioResult).length && (
            <Box className={classes.tableContainer}>
              <Table className={classes.table} padding="default">
                <TableHead>
                  <TableRow>
                    {this.state.chartOptions
                      .filter(column => column.include)
                      .map((item, i) => {
                        return (
                          <TableCell
                            className={item.highlight ? classes.highlight : ''}
                            style={{
                              backgroundColor:
                                item.category === 'Scenario' || item.category === 'Realtime' ? '#ebf6ff' : undefined
                            }}
                            key={i}
                          >
                            {item.label}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.map((item, index) => {
                    return (
                      <TableRow key={index} hover data-testid="result-table-row" style={this.getRowStyle(index)}>
                        {this.state.chartOptions
                          .filter(column => column.include)
                          .map((column, columnIndex) => {
                            return (
                              <TableCell className={column.highlight ? classes.highlight : ''} key={columnIndex}>
                                {this.formatSummaryOutput(item, column.key)}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    {this.state.chartOptions
                      .filter(column => column.include)
                      .map((item, i) => {
                        return (
                          <TableCell className={item.highlight ? classes.highlight : ''} key={i}>
                            {calculateTotal(
                              this.state.data,
                              item.key,
                              this.props.statusQuoResult,
                              this.props.scenarioResult,
                              this.props.discountRate.demand,
                              this.props.discountRate.energy,
                              this.state.perfectKnowledgeResults
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: getRounding(item.key)
                            })}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          )}

        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
          <DialogTitle id="form-dialog-title">Chart Settings</DialogTitle>
          <DialogContent>
            <Typography>Data Streams</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order</TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Show</TableCell>
                  <TableCell>Highlight</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.editChartOptions.map((dataItem, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton
                        aria-label="Move Up in Order"
                        onClick={this.handleMoveItem(true, dataItem)}
                        style={{ height: 24, width: 24 }}
                      >
                        <KeyboardArrowUp />
                      </IconButton>
                      <IconButton
                        aria-label="Move Down in Order"
                        onClick={this.handleMoveItem(false, dataItem)}
                        style={{ height: 24, width: 24 }}
                      >
                        <KeyboardArrowDown />
                      </IconButton>
                    </TableCell>
                    <TableCell>{dataItem.label}</TableCell>
                    <TableCell>
                      <Checkbox name="include" checked={dataItem.include} onClick={this.handleColumnToggle(dataItem)} />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        name="highlight"
                        checked={dataItem.highlight}
                        onClick={this.handleColumnToggle(dataItem)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSuccessClose} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({ setting: state.setting });

const mapDispatchToProps = { getGlobalSettingByKey };

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ScenarioMonthlyResultsTable));
