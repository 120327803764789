import Archive from '@material-ui/icons/Archive';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDate } from './Dates';
import { Portfolio } from '../types';

const renderName = (portfolio: Portfolio) => {
  return portfolio.is_archived ? (
    <Tooltip title="This portfolio has been archived" enterDelay={100}>
      <Archive
        aria-label="archive-icon"
        style={{ top: '7px', position: 'relative', width: '20px', marginRight: '3px', color: '#717171' }}
      />
    </Tooltip>
  ) : (
    ''
  );
};

export const portfolioColumnSchema = [
  { key: 'name', numeric: false, disablePadding: false, label: 'Name', prefix: renderName },
  {
    key: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description'
  },
  { key: 'status', numeric: false, disablePadding: false, label: 'Status' },
  {
    key: 'updated_on',
    numeric: false,
    disablePadding: false,
    label: 'Updated',
    transform: formatDate
  },
  // {
  //   key: 'created_on',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Created',
  //   transform: formatDate
  // },
  {
    key: 'created_user',
    numeric: false,
    disablePadding: false,
    label: 'Created By'
  }
];
