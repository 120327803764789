import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Help from '@material-ui/icons/Help';
import { Theme, makeStyles } from '@material-ui/core/styles';

interface IconTooltipProps {
  iconSize?: 'small' | 'inherit' | 'default' | 'large';
  title: string;
  color?: string;
  backgroundColor?: string;
  fontSize?: number;
  padding?: number;
  iconSizeSmall?: number;
  iconColor?: string;
}

const useStyles = makeStyles<Theme, IconTooltipProps>((theme: Theme) => ({
  tooltip: {
    backgroundColor: props => props.backgroundColor ?? '#eaeaea',
    color: props => props.color ?? 'rgba(0, 0, 0, 0.87)',
    fontSize: props => props.fontSize ?? theme.typography.pxToRem(13),
    padding: props => props.padding ?? 15,
    maxWidth: 220,
    border: '1px solid #dadde9'
  },
  rootIcon: {
    marginLeft: 4,
    color: props => props.iconColor ?? 'rgba(0,0,0,0.44)'
  },
  fontSizeSmall: {
    fontSize: props => props.iconSizeSmall ?? '1rem'
  }
}));

const IconTooltip: React.FC<IconTooltipProps> = props => {
  const classes = useStyles(props);
  return (
    <Tooltip title={props.title} classes={{ tooltip: classes.tooltip }} placement="right-start" interactive>
      <Help
        fontSize={props.iconSize ?? 'small'}
        classes={{ fontSizeSmall: classes.fontSizeSmall, root: classes.rootIcon }}
      />
    </Tooltip>
  );
};

export default IconTooltip;
