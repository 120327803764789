import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
// import IconButton from '@material-ui/core/IconButton';
// import { exportCSVFile } from '../../../utility/General';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../../custom-plotly.js';
const Plot = createPlotlyComponent(Plotly);

const layout = {
  // showlegend: false,
  height: 280,
  margin: {
    t: 20,
    l: 60,
    r: 20,
    b: 40
  },
  xaxis: {
    tickangle: -45
  }
};

const EscalationChart = props => {
  const { data } = props;
  const [chartData, setChartData] = useState([]);

  // 1. Initial mount
  useEffect(() => {
    if (data && data.length > 0) {
      let seasons = data[0].slice(1);
      let touBands = data[1].slice(1);
      let chargeData = data.slice(2);
      let x = chargeData.map(x => x[0]);
      let y_data = {};
      let currentSeason = '';
      seasons.forEach((season, index) => {
        if (season) {
          currentSeason = season;
        }
        const dataKey = currentSeason + ' ' + touBands[index];
        y_data[dataKey] = chargeData.map(x => x[index + 1]);
      });
      let escalationChartData = [];
      Object.keys(y_data).forEach(chartDataKey => {
        escalationChartData.push({
          type: 'scatter',
          x: x,
          y: y_data[chartDataKey],
          name: chartDataKey
        });
      });
      setChartData(escalationChartData);
    }
    // let ignore = false;
    // if (!ignore) {
    //   buildData(data);
    // }
    // return () => {
    //   ignore = false;
    // };
  }, [data]);

  // handleDownload = () => {
  //   const headers = ["Datetime", "Cycles per Month"];
  // exportCSVFile(null, data, 'Cycles per Month');
  // };

  const handleDownload = () => {
    const headers = ['Datetime', 'Cycles per Month'];
    let downloadData = [];
    data[0].x.forEach((date, index) => {
      const row = [date];
      data.forEach(item => {
        row.push(item.y[index]);
      });
      downloadData.push(row);
    });

    // exportCSVFile(headers, downloadData, 'Cycles per Month');
  };

  return (
    <Paper>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          {props.title}
        </Typography>
        {/* <IconButton aria-label="Download" onClick={handleDownload}> */}
        {/* <InsertDriveFile /> */}
        {/* </IconButton> */}
      </Toolbar>
      <div style={{ height: 300 }}>
        {chartData && chartData.length > 0 && (
          <Plot
            style={{ width: '100%' }}
            useResizeHandler={true}
            data={chartData}
            layout={layout}
            config={{ displayModeBar: false }}
          />
        )}
      </div>
    </Paper>
  );
};

EscalationChart.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default EscalationChart;
