import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AccessControl from '../../utility/AccessControl';
import ScenarioBadges from '../Scenarios/ScenarioBadges';
import { getIn, get } from 'immutable';

class BaseTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, columnSchema, showCheckbox, showRowActions } = this.props;

    return (
      <TableHead>
        <TableRow>
          {showCheckbox && <TableCell padding="checkbox" />}
          {columnSchema.map(
            row => (
              <TableCell
                style={{ paddingLeft: 24 }}
                key={row.key}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.key ? order : false}
              >
                <Tooltip title="Sort" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === row.key}
                    direction={order}
                    onClick={this.createSortHandler(row.key)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
          {!showCheckbox && showRowActions && <TableCell padding="checkbox" style={{ maxWidth: 75 }} />}
        </TableRow>
      </TableHead>
    );
  }
}

BaseTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  columnSchema: PropTypes.array.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  showRowActions: PropTypes.bool.isRequired
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  // table: {
  //   '& tr:first-child th': {
  //     backgroundColor: '#eceffa',
  //     lineHeight: '1.2',
  //     fontSize: '12px',
  //     letterSpacing: '.5px',
  //     whiteSpace: 'nowrap',
  //     fontSize: '.85rem'
  //   },
  //   '& td': {
  //     color: '#383838',
  //     lineHeight: '1.2'
  //   }
  // },
  tableWrapper: {
    overflowX: 'auto'
  },
  iconButton: {
    padding: 0
  },
  cell: {
    fontSize: '0.825rem'
  }
});

class BaseTable extends React.Component {
  renderValue(row, column) {
    // allows to render a nested value if the key is an array of the path
    // e-g { key: ['address','city'], ....}
    const value = typeof column.key == 'object' ? getIn(row, column.key, 'None') : get(row, column.key, 'None');
    return column.transform ? column.transform(value) : value;
  }
  render() {
    const {
      classes,
      data,
      order,
      orderBy,
      rowsPerPage,
      page,
      onChangeRowsPerPage,
      onChangePage,
      onRequestSort,
      columnSchema,
      dataCount,
      onRowClick,
      rowActionSchema
    } = this.props;

    const isSelectList = this.props.isSelected ? true : false;

    return (
      <div>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="proposal list">
            <BaseTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
              columnSchema={columnSchema}
              showCheckbox={isSelectList}
              showRowActions={this.props.rowActionSchema && this.props.rowActionSchema.length > 0 ? true : false}
            />
            <TableBody>
              {data.map(row => {
                const isSelected = this.props.isSelected ? this.props.isSelected(row) : null;
                return (
                  <TableRow
                    hover
                    onClick={onRowClick(row)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={row.public_id}
                    selected={isSelected}
                  >
                    {isSelectList && (
                      <TableCell padding="checkbox" className={classes.cell}>
                        <Checkbox checked={isSelected} />
                      </TableCell>
                    )}
                    {columnSchema.map(column => (
                      <TableCell key={column.key} style={{ paddingLeft: 24 }} className={classes.cell}>
                        {column.prefix ? column.prefix(row) : ''}
                        {this.renderValue(row, column)}
                      </TableCell>
                    ))}
                    {!isSelectList && rowActionSchema && rowActionSchema.length > 0 && (
                      <TableCell style={{ paddingLeft: 24 }} className={classes.cell}>
                        {this.props.rowActionSchema.map((action, i) => {
                          return (
                            <AccessControl key={action.key} requiredPermissions={['admin', 'edit']}>
                              <Tooltip title={action.label} placement="bottom" enterDelay={300} key={i}>
                                <IconButton
                                  onClick={action.actionHandler(row)}
                                  className={classes.iconButton}
                                  name="action"
                                >
                                  <action.icon />
                                </IconButton>
                              </Tooltip>
                            </AccessControl>
                          );
                        })}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
    );
  }
}

BaseTable.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isSelected: PropTypes.func,
  columnSchema: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  dataCount: PropTypes.number.isRequired,
  rowActionSchema: PropTypes.array
};

export default withStyles(styles)(BaseTable);
