import React, { useEffect, useState } from 'react';
import { ESAPTariff, AssumptionsInternal, TemplateEV } from '../../../types';

// MATERIAL UI IMPORTS
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormLabel from '@material-ui/core/FormLabel';

import EVShiftInputs from './EVShiftInputs';
import ExpandableInputFields from '../../Common/ExpandableInputFields';
import VerticalWhiteSpace from '../../Common/VerticalWhiteSpace';
import { help } from '../../../tooltips/assumptions/ev';
import EVArrivalChart from '../../EV/EVArrivalChart';

const useStyles = makeStyles(theme => ({
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  evtoolbar: {
    marginTop: theme.spacing(2),
    border: '1px solid #e5e5e5'
  }
}));

interface EVConfigProps {
  tariffs: ESAPTariff[];
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleGeneralAssumptionChange: (event: any) => void;
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
  handleEVShiftAssumptionsChange: (id: number) => (event: any) => void;
  handleAddEVShift: () => void;
  handleDeleteEVShift: (id: number) => () => void;
  handleChangeEVProfileTemplate: (template: TemplateEV) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  assumptions: AssumptionsInternal;
}
const EVConfig: React.FC<EVConfigProps> = ({
  tariffs,
  handleToggle,
  handleGeneralAssumptionChange,
  handleAssumptionUpdateByKey,
  handleEVShiftAssumptionsChange,
  handleAddEVShift,
  handleDeleteEVShift,
  handleChangeEVProfileTemplate,
  assumptions
}) => {
  const classes = useStyles();

  const [template, setTemplate] = useState<TemplateEV>(assumptions?.evTemplate ?? 'none');

  const {
    tariffId,
    evTemplate,
    useCustomEVLoadProfile,
    isEV,
    nbOfEVProfiles,
    ignoreFacilityLoad,
    isEVTariff,
    setSubscriptionLevel,
    evNbSubscriptionTranches,
    useLoadComparisonDemandChargeCalc,
    useSmartCharging,
    numEV,
    eta,
    maxEVFleetDemand,
    Rmax,
    evWeekendUtilization,
    evShifts,
    coOptimEV
  } = assumptions;

  useEffect(() => {
    setTemplate(evTemplate);
  }, [evTemplate]);

  useEffect(() => {
    if (tariffs?.length > 0 && tariffId) {
      const tariff = tariffs.find(t => tariffId === t.public_id);
      if (tariff) {
        const handleisEVTariff = handleAssumptionUpdateByKey('isEVTariff');
        let isEVTariff =
          tariff.ev_subscriptions !== undefined &&
          tariff.ev_subscriptions !== null &&
          tariff.ev_subscriptions.length > 0;
        handleisEVTariff(isEVTariff);
      }
    }
    // adding handleAssumptionUpdateByKey to deps causes infinite re-renders, gotta find why
  }, [tariffs, tariffId]);

  // turn off smart charging if using custom EV profile
  useEffect(() => {
    if (useCustomEVLoadProfile) {
      handleAssumptionUpdateByKey('useSmartCharging')(false);
    }
    // adding handleAssumptionUpdateByKey to deps causes infinite re-renders, gotta find why
  }, [useCustomEVLoadProfile]);

  // turn off co-optimization if smart charging is turned off
  useEffect(() => {
    if (!useSmartCharging) {
      handleAssumptionUpdateByKey('coOptimEV')(false);
    }
    // adding handleAssumptionUpdateByKey to deps causes infinite re-renders, gotta find why
  }, [useSmartCharging]);

  return (
    <div style={{ width: '100%' }}>
      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={handleToggle} checked={isEV} name="isEV" />}
        label="Use EVs"
      />
      {isEV && (
        <div>
          <FormControlLabel
            className={classes.block}
            control={<Switch onChange={handleToggle} name="useCustomEVLoadProfile" checked={useCustomEVLoadProfile} />}
            label="Use Custom EV Load Profiles"
          />
          {useCustomEVLoadProfile && (
            <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
              <FormControl component="fieldset">
                {/* <FormLabel component="legend">Number of custom EV Profiles</FormLabel> */}
                <RadioGroup
                  row
                  aria-label="ev-custom-profile"
                  name="nbOfEVProfiles"
                  onChange={handleGeneralAssumptionChange}
                  value={nbOfEVProfiles}
                >
                  <FormControlLabel value="1" control={<Radio />} label="1 profile" />
                  <FormControlLabel value="2" control={<Radio />} label="2 profiles" />
                </RadioGroup>
              </FormControl>
            </div>
          )}
          <FormControlLabel
            className={classes.block}
            control={<Switch onChange={handleToggle} name="ignoreFacilityLoad" checked={ignoreFacilityLoad} />}
            label="Ignore Facility Load"
          />
          <FormControlLabel
            disabled={!isEVTariff}
            className={classes.block}
            control={<Switch readOnly name="isEVTariff" checked={isEVTariff} />}
            label="Has Demand Subscriptions"
          />

          {isEVTariff && (
            <div
              style={{
                marginBottom: 24,
                paddingLeft: 24,
                borderLeft: '10px solid #eee'
              }}
            >
              <FormControlLabel
                className={classes.block}
                control={<Switch name="setSubscriptionLevel" onChange={handleToggle} checked={setSubscriptionLevel} />}
                label="Set Subscriptions Level"
              />
              {setSubscriptionLevel && (
                <div
                  style={{
                    marginBottom: 24,
                    paddingLeft: 24,
                    borderLeft: '10px solid #eee'
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      type="number"
                      label="Number of Tranches Selected"
                      key="evNbSubscriptionTranches"
                      name="evNbSubscriptionTranches"
                      value={evNbSubscriptionTranches}
                      onChange={handleGeneralAssumptionChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      margin="normal"
                    />
                  </FormControl>
                </div>
              )}
            </div>
          )}
          <FormControlLabel
            className={classes.block}
            control={
              <Switch
                name="useLoadComparisonDemandChargeCalc"
                onChange={handleToggle}
                checked={useLoadComparisonDemandChargeCalc}
              />
            }
            label="Use Load Comparison Demand Charge Calculation"
          />
          {!useCustomEVLoadProfile && (
            <ExpandableInputFields>
              <FormControlLabel
                className={classes.block}
                control={<Switch onChange={handleToggle} name="useSmartCharging" checked={useSmartCharging} />}
                label="Use Smart Charging"
              />
              <FormControlLabel
                className={classes.block}
                control={<Switch disabled={true} onChange={handleToggle} name="coOptimEV" checked={coOptimEV} />}
                label="Co-optimization of EVs + BESS"
              />
            </ExpandableInputFields>
          )}
          <VerticalWhiteSpace />
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="ev-profiles-select-helper-label">Profile templates</InputLabel>
                <Select
                  labelId="ev-profiles-select-helper-label"
                  id="ev-profiles-select-helper"
                  value={template}
                  onChange={e => setTemplate(e.target.value as TemplateEV)}
                >
                  <MenuItem value="none">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="officeBuilding">Office Building</MenuItem>
                  <MenuItem value="hospital">Hospital</MenuItem>
                  <MenuItem value="shippingWarehouse">Shipping Warehouse</MenuItem>
                </Select>
                <FormHelperText>{help[template] ?? ''}</FormHelperText>
              </FormControl>
            </div>
            <div style={{ marginLeft: 8 }}>
              <Button color="primary" variant="contained" onClick={handleChangeEVProfileTemplate(template)}>
                Import
              </Button>
            </div>
          </div>

          <>
            <TextField
              className={classes.block}
              label="Number of EVSEs"
              key="numEV"
              value={numEV}
              name="numEV"
              onChange={handleGeneralAssumptionChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
            <ExpandableInputFields>
              <TextField
                label="EVSEs Charge Rate (kW)"
                key="Rmax"
                name="Rmax"
                value={Rmax}
                onChange={handleGeneralAssumptionChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Charge Efficiency (%)"
                key="eta"
                name="eta"
                value={eta}
                onChange={handleGeneralAssumptionChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
            </ExpandableInputFields>
            <TextField
              label="Maximum EV Fleet Demand (kW)"
              key="maxEVFleetDemand"
              name="maxEVFleetDemand"
              value={maxEVFleetDemand}
              onChange={handleGeneralAssumptionChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Weekend Utilization (ratio)"
              key="evWeekendUtilization"
              name="evWeekendUtilization"
              value={evWeekendUtilization}
              onChange={handleGeneralAssumptionChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
            <VerticalWhiteSpace space={48} />
            {Array.isArray(evShifts) && evShifts.length > 0 && <EVArrivalChart evShifts={evShifts} />}
            <VerticalWhiteSpace />
            {Array.isArray(evShifts) && evShifts.length > 0 && (
              <>
                <Toolbar className={classes.evtoolbar}>
                  <Typography style={{ flex: 1 }} variant="h6">
                    EV Shifts
                  </Typography>
                  <Button color="primary" onClick={handleAddEVShift}>
                    Add
                  </Button>
                </Toolbar>
                {evShifts.map((shift, index) => (
                  <EVShiftInputs
                    index={index + 1}
                    key={shift.id}
                    handleEVShiftAssumptionsChange={handleEVShiftAssumptionsChange}
                    shift={shift}
                    handleDeleteEVShift={handleDeleteEVShift}
                  />
                ))}
              </>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default EVConfig;
