import React, { useEffect, useState } from 'react';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

// REACT IMPORTS
import PortfolioGeneralForm from '../Detail/PortfolioGeneralForm';
import { PORTFOLIO_INPUT_FORM_CHANGE, updatePortfolio, createPortfolio } from '../../../actions/portfolios';
import AccessControl from '../../../utility/AccessControl';
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from '../../../reducers';
import { Portfolio } from '../../../types';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  }
}));

interface IPortfolioGeneralContainerProps {
  handleNext?: any;
}

const PortfolioGeneralContainer: React.FC<IPortfolioGeneralContainerProps> = props => {
  const portfolio = useSelector((state: StoreState) => state.newPortfolio);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (portfolio?.name) {
      setIsComplete(portfolio.name.length > 0);
    }
  }, [portfolio, portfolio?.name]);

  const location = useLocation();
  const isDetail = /portfolios\/\w+/.test(location.pathname);

  const handlePortfolioChange = (event: any) => {
    const payload = { [event.target.name]: event.target.value };
    dispatch({ type: PORTFOLIO_INPUT_FORM_CHANGE, payload });
  };

  const handlePortfolioSave = () => {
    if (portfolio.public_id) {
      dispatch(updatePortfolio(portfolio));
    } else {
      // TODO: Clean up reducer so this type of selector isn't needed or make this into a select function that maps props
      const selectPortfolio = (({ name, description, notes, status, portfolio_type }) => ({
        name,
        description,
        notes,
        status,
        portfolio_type
      }))(portfolio);
      dispatch(createPortfolio(selectPortfolio as Portfolio));
    }
    if (props.handleNext) {
      props.handleNext();
    }
  };

  return (
    <div>
      {!isDetail && (
        <AccessControl requiredPermissions={['editor', 'admin']}>
          <div className="button-container">
            <Fab
              disabled={!isComplete}
              size="small"
              color="secondary"
              aria-label="Save"
              className="button-white"
              onClick={handlePortfolioSave}
            >
              <Save />
            </Fab>
          </div>
        </AccessControl>
      )}
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography color="inherit" variant="h6">
            Portfolio Details
          </Typography>
        </Toolbar>
        <PortfolioGeneralForm handlePortfolioChange={handlePortfolioChange} portfolio={portfolio} />
      </Paper>
    </div>
  );
};

export default PortfolioGeneralContainer;
