import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

interface PDPEventFormProps {
  dates: string[];
  newDate: string;
  handleDelete: (index: number) => void;
  handleAdd: () => void;
  handleChange: (event: any) => void;
}

const PDPEventForm: React.FC<PDPEventFormProps> = ({ dates, newDate, handleDelete, handleAdd, handleChange }) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    if (newDate.trim()) {
      handleAdd();
    }
  };

  return (
    <div>
      <TextField
        id="newDate"
        name="newDate"
        required
        onKeyDown={handleKeyDown}
        autoFocus={true}
        label="Add Event Date"
        helperText="(mm/dd/yyyy)"
        value={newDate}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
      />
      <List dense={true}>
        {dates.map((date, index) => {
          return (
            <ListItem key={index}>
              <ListItemText primary={date} />
              <ListItemSecondaryAction>
                <IconButton aria-label="Delete" onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default PDPEventForm;
