import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: 20
  },
  card: {
    marginBottom: 10
  }
});

interface AnnualSummary {
  avgAnnualDemand: number;
  peakAnnualDemand: number;
  peakAnnualDemandDate: string;
  avgAnnualLoadFactor: number;
  totalEnergy: number;
  avgDemand?: number;
  avgLoadFactor?: number;
}

interface MonthlySummary {
  weekend: {
    [key: string]: {
      value: number;
      key: string;
      count: number;
    };
  };
  weekday: {
    [key: string]: {
      value: number;
      key: string;
      count: number;
    };
  };
  peak: number;
  peakDatetime: string;
  totalEnergy: number;
  startDataIndex: number;
  endDataIndex: number;
  avgDemand: number;
  avgLoadFactor: number;
}

interface LoadSummaryTableProps {
  monthSummary: MonthlySummary;
  annualSummary: AnnualSummary;
  touMonthSummary: MonthlySummary;
  touSummary: AnnualSummary;
  titlePrefix?: string;
}

const LoadSummaryTable: React.FC<LoadSummaryTableProps> = ({
  monthSummary,
  annualSummary,
  touMonthSummary,
  touSummary,
  titlePrefix
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <div>
            <CardContent>
              <Typography component="h5" variant="h5" style={{ marginBottom: 15, textAlign: 'center' }}>
                {titlePrefix} Annual Load Summary
              </Typography>
              <Table padding="default">
                <TableBody>
                  <TableRow>
                    <TableCell>Average Load</TableCell>
                    <TableCell>{Math.round(annualSummary.avgAnnualDemand).toLocaleString()} kW</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Max Around-the-Clock Load</TableCell>
                    <TableCell>{Math.round(annualSummary.peakAnnualDemand).toLocaleString()} kW</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Max Peak Load</TableCell>
                    <TableCell>{Math.round(touSummary.peakAnnualDemand).toLocaleString()} kW</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Average Load Factor</TableCell>
                    <TableCell>{Math.round(annualSummary.avgAnnualLoadFactor * 100)}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Energy</TableCell>
                    <TableCell>{Math.round(annualSummary.totalEnergy).toLocaleString()} kWh</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </div>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <div>
            <CardContent>
              <Typography component="h5" variant="h5" style={{ marginBottom: 15, textAlign: 'center' }}>
                {titlePrefix} Monthly Load Summary
              </Typography>
              <Table padding="default">
                <TableBody>
                  <TableRow>
                    <TableCell>Average Load</TableCell>
                    <TableCell>{Math.round(monthSummary.avgDemand).toLocaleString()} kW</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Max Around-the-Clock Load</TableCell>
                    <TableCell>{Math.round(monthSummary.peak).toLocaleString()} kW</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Max Peak Load</TableCell>
                    <TableCell>{Math.round(touMonthSummary.peak).toLocaleString()} kW</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Average Load Factor</TableCell>
                    <TableCell>{Math.round(monthSummary.avgLoadFactor * 100)}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Energy</TableCell>
                    <TableCell>{Math.round(monthSummary.totalEnergy).toLocaleString()} kWh</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoadSummaryTable;
