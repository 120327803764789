import { SELECT_PROPOSAL_SCENARIO_PRESENTATION, SELECT_PROPOSAL_SQ_PRESENTATION } from '../../actions/scenarios';

import { ScenarioPresentation } from '../../types';

const INITIAL_STATE: ScenarioPresentation = {
  statusQuo: undefined,
  scenario: undefined
};

export default function scenarioPresentationReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SELECT_PROPOSAL_SCENARIO_PRESENTATION:
      if (!action.payload) return state;
      return { ...state, scenario: action.payload };
    case SELECT_PROPOSAL_SQ_PRESENTATION:
      if (!action.payload) return state;
      return { ...state, statusQuo: action.payload };
    default:
      return state;
  }
}
