import { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { TariffFactoryContext } from './TariffFactoryContext';

const useStyles = makeStyles((theme: Theme) => ({
  inputField: {
    width: theme.spacing(25)
  },
  selectInputField: {}
}));

const OverageCharges = () => {
  const { overage, setOverage } = useContext(TariffFactoryContext);
  const classes = useStyles();

  const handleChangeOverageCharges = (e: any) => {
    setOverage({ ...overage, charges: e.target.value });
  };

  const handleChangeOverageType = (e: any) => {
    setOverage({ ...overage, type: e.target.value });
  };
  return (
    <Grid container justify="center" alignItems="center" alignContent="center">
      <Grid item xs={12} style={{ marginBottom: 16 }}>
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          Unless you enter an override, the Demand Charge Overage will be charged at 2x the average per kW rate based on
          the selected subscription.
        </Alert>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Overage Charges ($/kW)"
          name="overageCharges"
          className={classes.inputField}
          value={overage.charges}
          onChange={handleChangeOverageCharges}
          margin="normal"
        />
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="simple-overage-rate-selector-label">Type</InputLabel>
        <Select
          label="Category"
          className={classes.selectInputField}
          aria-label="simple-overage-rate-selector"
          data-testid="simple-overage-rate-selector"
          name="overageRatesType"
          value={overage.type}
          onChange={handleChangeOverageType}
        >
          <MenuItem value="kW">Per kW</MenuItem>
          <MenuItem value="flat">Flat Rate</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default OverageCharges;
