import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

// REACT IMPORTS
import HistoricalData from '../../components/Explore/HistoricalData';
import ExploreDetailConfig from '../../components/Explore/ExploreDetailConfig';
import { resetBreadcrumb, pushBreadcrumb } from '../../actions/breadcrumbs';
import AnalysisOverview from '../../components/Explore/AnalysisOverview';

// MATERIAL UI IMPORTS
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { ChevronRight, Settings, ShowChart, Assessment } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 24
    },
    card: {
      margin: 10,
      cursor: 'pointer'
    },
    actions: {
      display: 'flex'
    },
    flex: {
      flex: 1
    },
    toolbar: {
      // borderBottom: "1px solid #e5e5e5"
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    }
  })
);

const ExploreDetailClean: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    currentView: 'table',
    showConfig: false
  });

  useEffect(() => {
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Explore', '/explore'));
  }, [resetBreadcrumb, pushBreadcrumb]);

  const handleAnalysisClick = () => {
    setState({ ...state, currentView: 'analysis' });
  };

  const handleShowTable = () => {
    setState({ ...state, currentView: 'table' });
  };

  const handleCreateProposal = () => {
    history.push('/create-proposal?ref=explore');
  };

  const handleClose = () => {
    setState({ ...state, showConfig: !state.showConfig });
  };

  return (
    <div className={classes.container}>
      {state.currentView === 'table' && (
        <div>
          <Paper>
            <Toolbar className={classes.toolbar}>
              <Typography color="inherit" variant="h6" className={classes.flex}>
                Enter Historical Data
              </Typography>

              <Button variant="contained" onClick={handleAnalysisClick} size="small">
                <ShowChart />
                Run Analysis
              </Button>
            </Toolbar>
            <HistoricalData />
          </Paper>
        </div>
      )}
      {state.currentView === 'analysis' && (
        <div>
          <div className="button-container">
            <Fab color="primary" size="small" aria-label="Create Proposal" onClick={handleCreateProposal}>
              <Assessment />
            </Fab>
            <Fab size="small" aria-label="Settings" onClick={handleClose}>
              <Settings />
            </Fab>
            <Fab
              size="small"
              color="secondary"
              aria-label="Back to Table"
              className="button-white"
              onClick={handleShowTable}
            >
              <ChevronRight />
            </Fab>
          </div>
          <Dialog onClose={handleClose} open={state.showConfig}>
            <ExploreDetailConfig />
          </Dialog>
          <AnalysisOverview />
        </div>
      )}
    </div>
  );
};

export default ExploreDetailClean;
