import {
  GET_ALL_CUSTOMERS_SUCCESS,
  CREATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_SUCCESS,
  RESET_CUSTOMER_LIST
} from '../../actions/customers';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ALL_CUSTOMERS_SUCCESS:
      return action.payload;
    case CREATE_CUSTOMER_SUCCESS:
      return [action.payload].concat(state);
    case DELETE_CUSTOMER_SUCCESS:
      return state;
    case RESET_CUSTOMER_LIST:
      return INITIAL_STATE;
    default:
      return state;
  }
}
