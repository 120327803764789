import React from 'react';

import { humanizeDatesDiffFromNow, sortByDates } from '../../utility/Dates';
import { Job, RedisJobStatus } from '../../types';
import { useRemoveJob, useProcessingQueue } from '../../queries/queue';

// MATERIAL UI IMPORTS
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import { assertUnreachable } from '../../utils/assertions';

const queueColumnSchema = [
  { key: 'run_status', numeric: false, disablePadding: false, label: 'Status' },
  { key: 'queue_name', numeric: false, disablePadding: false, label: 'Queue' },
  { key: 'display_name', numeric: false, disablePadding: false, label: 'Source' },
  {
    key: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'When',
    transform: humanizeDatesDiffFromNow
  },
  {
    key: 'created_user',
    numeric: false,
    disablePadding: false,
    label: 'Created By'
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(3),
      overflowX: 'auto'
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    spacer: {
      flex: '1 1 auto'
    },
    complete: {
      color: 'white !important',
      backgroundColor: (theme.palette as any).esap.greenDark
    },
    error: {
      color: 'white !important',
      backgroundColor: (theme.palette as any).esap.red
    },
    started: {
      color: 'white !important',
      backgroundColor: (theme.palette as any).esap.yellowOrange
    },
    queued: {
      color: 'white !important',
      backgroundColor: (theme.palette as any).esap.orange
    },
    percentage: {
      fontWeight: 600,
      fontSize: '.7rem',
      letterSpacing: '-.5px'
    }
  })
);

interface ProcessingQueueListProps {}

const ProcessingQueueList: React.FC<ProcessingQueueListProps> = () => {
  const classes = useStyles();

  const { status, data, error, refetch } = useProcessingQueue();
  const { mutate } = useRemoveJob();

  const buildData = (): Job[] => {
    return data
      ? sortByDates(
          Object.keys(data).reduce((acc, curr) => {
            return acc.concat(data[curr].queued_jobs).concat(data[curr].running_jobs).concat(data[curr].finished_jobs);
          }, [])
        )
      : [];
  };

  const totalJobsByProp = (prop: 'num' | 'started' | 'finished' | 'failed'): number => {
    return data
      ? Object.keys(data).reduce((acc, curr) => {
          return acc + data[curr][prop];
        }, 0)
      : 0;
  };

  const handleRemoveJob = (jobId: string) => () => {
    mutate(jobId);
  };

  const getClassName = (status: RedisJobStatus) => {
    switch (status) {
      case 'finished':
        return classes.complete;
      case 'failed':
        return classes.error;
      case 'queued':
        return classes.queued;
      case 'started':
        return classes.started;
      default:
        return undefined;
    }
  };

  const getStatusName = (status: RedisJobStatus, runStatus?: string) => {
    switch (status) {
      case 'finished':
        return 'Complete';
      case 'failed':
        return 'Error';
      case 'queued':
        return 'Queued';
      case 'started':
        return `Processing ${!!runStatus && /%/.test(runStatus) ? runStatus : ''}`;
      case 'deferred':
        return 'Deferred';
      case 'stopped':
        return 'Stopped';
      default:
        assertUnreachable(status);
    }
  };
  return (
    <Paper>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          Jobs Queue
        </Typography>
        <Tooltip title="Refresh">
          <IconButton onClick={() => refetch()} style={{ color: 'white', marginRight: 24 }}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption" color="inherit">
            In queue: {totalJobsByProp('num') ?? 0}
          </Typography>
          <Typography variant="caption" color="inherit">
            Processing: {totalJobsByProp('started') ?? 0}
          </Typography>
        </div>
        {/* <div className={classes.spacer} /> */}
      </Toolbar>
      {status === 'error' && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          There was an error while processing this analysis — <strong>{`${error ? error.message! : ''}`}</strong>
        </Alert>
      )}
      {status === 'loading' && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {status === 'success' && data && buildData().length === 0 && (
        <Alert severity="info">
          <AlertTitle>Empty</AlertTitle>
          Nothing in queue
        </Alert>
      )}
      {status === 'success' && data && buildData().length > 0 && (
        <Table>
          <TableHead>
            {queueColumnSchema.map(col => (
              <TableCell key={col.key}>{col.label}</TableCell>
            ))}
            <TableCell> </TableCell>
          </TableHead>
          <TableBody>
            {buildData().map((row, index) => (
              <TableRow key={row.job_id} style={index % 2 ? { background: '#f8f8f8' } : { background: 'white' }}>
                {queueColumnSchema.map(column =>
                  column.key === 'run_status' ? (
                    <TableCell key={column.key} style={{ textAlign: 'center' }} className={getClassName(row.status)}>
                      {getStatusName(row.status, row.run_status)}
                    </TableCell>
                  ) : (
                    <TableCell>{column.transform ? column.transform(row[column.key]) : row[column.key]}</TableCell>
                  )
                )}
                <TableCell>
                  {row.status !== 'finished' ? (
                    <IconButton style={{ padding: 0 }} onClick={handleRemoveJob(row.job_id)}>
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
};

export default ProcessingQueueList;
