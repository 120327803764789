import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import ListSearchInput from '../../Table/ListSearchInput';
import BaseTable from '../../Table/BaseTable';
import { stableSort, getSorting } from '../../../utility/Table';
import { proposalColumnSchema } from '../../../utility/Proposal';
import Delete from '@material-ui/icons/Delete';
import ConfirmPopUp from '../../Common/ConfirmPopUp/ConfirmPopUp';
import ProposalsListFilters from './ProposalsListFilters';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { UPDATE_PROPOSALS_LIST_FILTERS } from '../../../actions/proposals';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
    overflowX: 'auto'
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  spacer: {
    flex: '1 1 auto'
  },
  white: {
    color: '#fff',
    marginRight: 10
  }
}));

const INITIAL_STATE = {
  search: '',
  orderBy: 'updated_on',
  order: 'desc',
  page: 0,
  rowsPerPage: 25,
  isConfirm: false,
  selectedProposal: null,
  isFilterOpen: false
};

const CustomerProposalList = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = React.useState(INITIAL_STATE);
  // any row level action functions

  const handleDeleteAction = proposal => event => {
    event.stopPropagation();
    setState({ ...state, selectedProposal: proposal, isConfirm: true });
  };

  const handleConfirmClose = success => () => {
    if (success) {
      props.deleteProposal(state.selectedProposal.public_id);
    }
    setState({ ...state, isConfirm: false, selectedProposal: null });
  };

  const toggleFiltersDrawer = () => {
    setState({ ...state, isFilterOpen: !state.isFilterOpen });
  };

  // must declare after the methods used in the action handlers. could move to function that passes callback to delete
  const proposalRowActionSchema = [
    {
      key: 'delete',
      icon: Delete,
      actionHandler: handleDeleteAction,
      label: 'Delete Proposal'
    }
  ];

  const handleRowClick = proposal => () => {
    props.history.push('/proposals/' + proposal.public_id);
  };

  const filterBySearch = item => {
    if (!state.search) {
      return true;
    }
    const search = state.search;
    if (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
      return true;
    }
    return false;
  };

  const handleSearchChange = event => {
    setState({ ...state, search: event.target.value, page: 0 });
  };

  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc';
    }

    setState({ ...state, order, orderBy });
  };

  const handleChangePage = (event, page) => {
    setState({ ...state, page });
  };

  const handleChangeRowsPerPage = event => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const getActiveDataSlice = data => {
    return stableSort(data, getSorting(state.order, state.orderBy)).slice(
      state.page * state.rowsPerPage,
      state.page * state.rowsPerPage + state.rowsPerPage
    );
  };

  const updateProposalsListFilters = payload => {
    dispatch({ type: UPDATE_PROPOSALS_LIST_FILTERS, payload });
  };

  const { title, proposals } = props;
  const filteredData = proposals.filter(filterBySearch);
  return (
    <Paper>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit">
          {title ? title : 'Proposals'}
        </Typography>
        <div className={classes.spacer} />
        <ListSearchInput handleSearchChange={handleSearchChange} search={state.search} />
        {/* <Tooltip title="Reset Proposals List">
          <IconButton
            onClick={props.resetProposalsList}
            size="small"
            className={classes.white}
            aria-label="Reset Proposals List"
          >
            <AutorenewIcon />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
      {props.loading && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {!props.loading && (
        <BaseTable
          data={getActiveDataSlice(filteredData)}
          onRowClick={handleRowClick}
          onRequestSort={handleRequestSort}
          columnSchema={proposalColumnSchema}
          order={state.order}
          orderBy={state.orderBy}
          page={state.page}
          rowsPerPage={state.rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          dataCount={filteredData.length}
          rowActionSchema={proposalRowActionSchema}
        />
      )}

      <ConfirmPopUp open={state.isConfirm} onConfirmClose={handleConfirmClose} itemToDelete="proposal" />
    </Paper>
  );
};

CustomerProposalList.propTypes = {
  classes: PropTypes.object.isRequired,
  proposals: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  history: PropTypes.object,
  loading: PropTypes.bool
};

export default CustomerProposalList;
