import React, { useState, useEffect } from 'react';
import { withStyles, Theme, useTheme, makeStyles, createStyles } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { LSEResult, GenabilityLSEs } from '../../types';

// REACT IMPORTS
import { StoreState } from '../../reducers';
import { getLSEs, UPDATE_UTILITY_META } from '../../actions/utilities';
import { resetBreadcrumb, pushBreadcrumb } from '../../actions/breadcrumbs';

// MATERIAL UI IMPORTS
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import LocationOn from '@material-ui/icons/LocationOn';
import { fade } from '@material-ui/core/styles/colorManipulator';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

const style = (theme: Theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  content: {
    padding: theme.spacing(3)
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  cell: {
    fontSize: '0.825rem'
  },
  spacer: {
    flex: '1 1 auto'
  },
  searchRoot: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: 2,
    background: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      background: fade(theme.palette.common.white, 0.25)
    },
    '& $input': {
      transition: theme.transitions.create('width'),
      width: 200,
      '&:focus': {
        width: 250
      }
    }
  },
  search: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    font: 'inherit',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(9)}px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    margin: 0, // Reset for Safari
    color: 'inherit',
    width: '100%',
    '&:focus': {
      outline: 0
    }
  }
});

interface Props {
  getLSEs: any;
  resetBreadcrumb: any;
  pushBreadcrumb: any;
  history: any;
  classes: any;
  title: string;
  lses: LSEResult[];
  metaData: Omit<GenabilityLSEs, 'results'>;
}

const UtilityList: React.FC<Props> = props => {
  const { getLSEs, resetBreadcrumb, pushBreadcrumb, classes, title, lses, metaData } = props;

  const dispatch = useDispatch();

  const [timeout, setTimeoutTo] = useState(0);
  const [zipTimeout, setZipTimeout] = useState(0);
  const [search, setSearch] = useState('');
  const [zip, setZip] = useState('');

  useEffect(() => {
    // getLSEs({});
    resetBreadcrumb();
    pushBreadcrumb('Utilities', '/utilities');
  }, [getLSEs, pushBreadcrumb, resetBreadcrumb]);

  useEffect(() => {
    getLSEs({ pageStart: metaData.pageStart });
  }, [metaData.pageStart]);

  const navigateToDetail = (id: number) => () => {
    props.history.push('/utilities/' + id.toString());
  };

  // const filterBySearch = item => {
  //   if (!search) {
  //     return true;
  //   }

  //   if (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
  //     return true;
  //   }
  //   return false;
  // };

  const handleSearchChange = (event: any) => {
    var searchText = event.target.value; // is the search text
    if (timeout) clearTimeout(timeout);
    const newTimeout = window.setTimeout(() => {
      if (zip.length === 5 && !isNaN(parseInt(zip))) {
        props.getLSEs({
          search: searchText,
          addressString: zip
        });
      } else {
        props.getLSEs({ search: searchText, addressString: null });
      }
    }, 500);

    setTimeoutTo(newTimeout);
    setSearch(searchText);
  };

  const handleZipChange = (event: any) => {
    const zipText = event.target.value;
    if (zipText.length === 5 && !isNaN(zipText)) {
      if (zipTimeout) clearTimeout(zipTimeout);
      const newZipTimeout = window.setTimeout(() => {
        props.getLSEs({
          addressString: zipText,
          search: search
        });
      }, 500);
      setZipTimeout(newZipTimeout);
    } else {
      props.getLSEs({ search, addressString: null });
    }
    setZip(zipText);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch({ type: UPDATE_UTILITY_META, payload: { pageStart: newPage } });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit">
              {title ? title : 'Utilities'}
            </Typography>
            <div className={classes.spacer} />
            <div className={classes.searchRoot}>
              <div className={classes.search}>
                <LocationOn />
              </div>
              <input className={classes.input} onChange={handleZipChange} value={zip} />
            </div>

            <div className={classes.searchRoot}>
              <div className={classes.search}>
                <SearchIcon />
              </div>
              <input className={classes.input} onChange={handleSearchChange} value={search} />
            </div>
          </Toolbar>

          <div className={classes.container}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>Energy Provider</TableCell>
                  <TableCell className={classes.cell}>Short Name</TableCell>
                  <TableCell className={classes.cell}>Website</TableCell>
                  <TableCell className={classes.cell}>Customers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lses.map(n => {
                  return (
                    <TableRow key={n.lseId} onClick={navigateToDetail(n.lseId)} hover style={{ cursor: 'pointer' }}>
                      <TableCell className={classes.cell}>{n.name}</TableCell>
                      <TableCell className={classes.cell}>{n.lseCode}</TableCell>
                      <TableCell className={classes.cell}>{n.websiteHome}</TableCell>
                      <TableCell className={classes.cell}>{n.totalCustomers}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[25]}
                    colSpan={4}
                    count={metaData.count}
                    rowsPerPage={25}
                    page={metaData.pageStart}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true
                    }}
                    onChangePage={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    lses: state.utilities,
    metaData: state.utilitiesMeta
  };
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5)
    }
  })
);

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default connect(mapStateToProps, {
  getLSEs,
  resetBreadcrumb,
  pushBreadcrumb
})(withStyles(style as any)(UtilityList));
