import React from 'react';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Customer, ProposalInternal } from '../../../types';

const useStyles = makeStyles({
  container: {
    padding: 20
  },
  item: {
    paddingRight: 15
  }
});

interface ProposalGeneralFormProps {
  customer?: Customer;
  customers?: Customer[];
  isEdit?: boolean;
  proposal: ProposalInternal;
  handleProposalChange: any;
  handleCustomerChange?: any;
}

const ProposalGeneralForm: React.FC<ProposalGeneralFormProps> = props => {
  const { proposal, customer, customers, handleProposalChange, handleCustomerChange, isEdit } = props;
  const isNew = !proposal.public_id;

  const classes = useStyles();

  const options =
    customer && customers
      ? customers.map(c => {
          return {
            value: c.public_id,
            label: c.name
          };
        })
      : undefined;

  const filterOptions = options ? createFilterOptions({ options }) : undefined;

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <TextField
            id="name"
            name="name"
            required
            InputProps={{
              autoFocus: true,
              required: true,
              readOnly: isEdit || isNew ? false : true
            }}
            label="Proposal Name"
            value={proposal.name || ''}
            onChange={handleProposalChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.item}>
          <TextField
            id="description"
            name="description"
            value={proposal.description || ''}
            onChange={handleProposalChange}
            label="Description"
            InputLabelProps={{ shrink: true }}
            fullWidth
            InputProps={{ readOnly: isEdit || isNew ? false : true }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.item}>
          <TextField
            id="status"
            name="status"
            value={proposal.status || ''}
            onChange={handleProposalChange}
            label="Status"
            InputLabelProps={{ shrink: true }}
            fullWidth
            InputProps={{ readOnly: isEdit || isNew ? false : true }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <TextField
            id="notes"
            name="notes"
            label="Notes"
            value={proposal.notes || ''}
            onChange={handleProposalChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            multiline
            InputProps={{ readOnly: isEdit || isNew ? false : true }}
            margin="normal"
          />
        </Grid>
        {customers && (
          <Grid item xs={12} sm={12} md={6} className={classes.item}>
            <label htmlFor="customer">Customer</label>
            <Select
              name="Customer"
              id="customer"
              value={proposal.customer_id}
              clearable={false}
              options={options}
              multi={false}
              filterOptions={filterOptions}
              onChange={handleCustomerChange}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ProposalGeneralForm;
