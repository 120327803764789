import { fromJS } from 'immutable';
import {
  DUPLICATE_TARIFF,
  TARIFF_INPUT_FORM_CHANGE,
  BROADCAST_DATA_TARIFF,
  GET_TARIFF_BY_ID_SUCCESS
} from '../../actions/tariffs';
import { CREATE_MANUALTARIFF_SUCCESS, UPDATE_MANUALTARIFF_SUCCESS } from '../../actions/manualtariffs';
import { ESAPTariff, Seasons, TOUPeriod } from '../../types';

const INITIAL_STATE: Partial<ESAPTariff> = {};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_TARIFF_BY_ID_SUCCESS:
    case DUPLICATE_TARIFF:
      let newTariff: ESAPTariff = { ...action.payload };
      const seasons: Seasons = fromJS(newTariff.overview.seasons).toJS();
      const touNames = ['OFF_PEAK', 'PARTIAL_PEAK', 'ON_PEAK'];
      const touTemplate = [
        {
          name: 'Demand Charges',
          amount: 0,
          credit: false,
          periods: [],
          type: 'DEMAND_BASED'
        },
        {
          name: 'Energy Charges',
          amount: 0,
          credit: false,
          // touType: "ON_PEAK",
          periods: [],
          type: 'CONSUMPTION_BASED'
        }
      ];
      for (let seasonName in seasons) {
        touNames.forEach(touName => {
          if (seasons[seasonName][touName]) {
            if (!seasons[seasonName][touName].find((rate: TOUPeriod) => rate.type === 'DEMAND_BASED')) {
              seasons[seasonName][touName].push({ ...touTemplate[0] });
            }
            if (!seasons[seasonName][touName].find((rate: TOUPeriod) => rate.type === 'CONSUMPTION_BASED')) {
              seasons[seasonName][touName].push({ ...touTemplate[1] });
            }
          } else {
            seasons[seasonName][touName] = [...touTemplate];
            seasons[seasonName][touName][0].touType = touName;
            seasons[seasonName][touName][1].touType = touName;
          }
        });
      }
      // add in code to fill the gaps for TOU periods.
      newTariff.code += ' Copy';
      newTariff.name += ' Copy';
      return newTariff;
    case TARIFF_INPUT_FORM_CHANGE:
      return { ...state, ...action.payload };
    case UPDATE_MANUALTARIFF_SUCCESS:
      return { ...state, ...action.payload };
    case CREATE_MANUALTARIFF_SUCCESS:
      return { ...state, public_id: action.payload.public_id };
    case BROADCAST_DATA_TARIFF:
      const update = { [action.payload.type]: action.payload.data };
      return { ...state, ...update };
    default:
      return state;
  }
}
