export const STORE_LOAD_PROFILE = 'STORE_LOAD_PROFILE';
export const UPDATE_LOAD_PROFILE = 'UPDATE_LOAD_PROFILE';
export const UPDATE_LOAD_FREQUENCY = 'UPDATE_LOAD_FREQUENCY';

export const STORE_SOLAR_PROFILE = 'STORE_SOLAR_PROFILE';
export const UPDATE_SOLAR_PROFILE = 'UPDATE_SOLAR_PROFILE';
export const UPDATE_SOLAR_FREQUENCY = 'UPDATE_SOLAR_FREQUENCY';

export const STORE_EV1_PROFILE = 'STORE_EV1_PROFILE';
export const UPDATE_EV1_PROFILE = 'UPDATE_EV1_PROFILE';
export const UPDATE_EV1_FREQUENCY = 'UPDATE_EV1_FREQUENCY';

export const STORE_EV2_PROFILE = 'STORE_EV2_PROFILE';
export const UPDATE_EV2_PROFILE = 'UPDATE_EV2_PROFILE';
export const UPDATE_EV2_FREQUENCY = 'UPDATE_EV2_FREQUENCY';

export const STORE_LMP_DA_PROFILE = 'STORE_LMP_DA_PROFILE';
export const UPDATE_LMP_DA_PROFILE = 'UPDATE_LMP_DA_PROFILE';
export const UPDATE_LMP_DA_FREQUENCY = 'UPDATE_LMP_DA_FREQUENCY';

export const STORE_LMP_RT_PROFILE = 'STORE_LMP_RT_PROFILE';
export const UPDATE_LMP_RT_PROFILE = 'UPDATE_LMP_RT_PROFILE';
export const UPDATE_LMP_RT_FREQUENCY = 'UPDATE_LMP_RT_FREQUENCY';

export const STORE_MEF_PROFILE = 'STORE_MEF_PROFILE';
export const UPDATE_MEF_PROFILE = 'UPDATE_MEF_PROFILE';
export const UPDATE_MEF_FREQUENCY = 'UPDATE_MEF_FREQUENCY';

export const UPDATE_PROFILE_START_DATE = 'UPDATE_PROFILE_START_DATE';
export const UPDATE_PROFILE_END_DATE = 'UPDATE_PROFILE_END_DATE';

export const RESET_PROFILE_DATA = 'RESET_PROFILE_DATA';

export function storeLoadProfile(data) {
  return dispatch => {
    dispatch({
      type: STORE_LOAD_PROFILE,
      payload: data
    });
  };
}

export function updateLoadProfile(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_PROFILE,
      payload: changes
    });
  };
}

export function updateLoadFrequency(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_FREQUENCY,
      payload: changes
    });
  };
}

export function storeSolarProfile(data) {
  return dispatch => {
    dispatch({
      type: STORE_SOLAR_PROFILE,
      payload: data
    });
  };
}

export function updateSolarProfile(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_SOLAR_PROFILE,
      payload: changes
    });
  };
}

export function storeEV1Profile(changes) {
  return dispatch => {
    dispatch({
      type: STORE_EV1_PROFILE,
      payload: changes
    });
  };
}

export function updateEV1Profile(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_EV1_PROFILE,
      payload: changes
    });
  };
}

export function storeEV2Profile(changes) {
  return dispatch => {
    dispatch({
      type: STORE_EV2_PROFILE,
      payload: changes
    });
  };
}

export function updateEV2Profile(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_EV2_PROFILE,
      payload: changes
    });
  };
}

export function updateSolarFrequency(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_SOLAR_FREQUENCY,
      payload: changes
    });
  };
}

export function storeLMPDAProfile(data) {
  return dispatch => {
    dispatch({
      type: STORE_LMP_DA_PROFILE,
      payload: data
    });
  };
}

export function updateLMPDAProfile(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_LMP_DA_PROFILE,
      payload: changes
    });
  };
}

export function updateLMPDAFrequency(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_LMP_DA_FREQUENCY,
      payload: changes
    });
  };
}

export function storeLMPRTProfile(data) {
  return dispatch => {
    dispatch({
      type: STORE_LMP_RT_PROFILE,
      payload: data
    });
  };
}

export function updateLMPRTProfile(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_LMP_RT_PROFILE,
      payload: changes
    });
  };
}

export function updateLMPRTFrequency(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_LMP_RT_FREQUENCY,
      payload: changes
    });
  };
}

export function resetProfileData() {
  return dispatch => {
    dispatch({
      type: RESET_PROFILE_DATA
    });
  };
}

export function storeMEFProfile(data) {
  return dispatch => {
    dispatch({
      type: STORE_MEF_PROFILE,
      payload: data
    });
  };
}

export function updateMEFProfile(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_MEF_PROFILE,
      payload: changes
    });
  };
}

export function updateMEFFrequency(changes) {
  return dispatch => {
    dispatch({
      type: UPDATE_MEF_FREQUENCY,
      payload: changes
    });
  };
}
