import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Edit from '@material-ui/icons/Edit';
import ShowChart from '@material-ui/icons/ShowChart';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

// REACT IMPORTS
import SeasonConsumption from '../../components/Tariffs/Detail/SeasonConsumption';
import SeasonDemand from '../../components/Tariffs/Detail/SeasonDemand';
import EscalationChart from '../../components/Tariffs/Detail/charts/EscalationChart';
import { getTariff, broadcastUpdateTariff } from '../../actions/tariffs';
import { getManualTariff } from '../../actions/manualtariffs';
import { pushBreadcrumb, popBreadcrumb } from '../../actions/breadcrumbs';
import { parseQuery } from '../../utility/General';
import { parseRateInformation } from '../../utility/Tariff';
import { StoreState } from '../../reducers';
import AccessControl from '../../utility/AccessControl';
import TariffDetailTOU from '../../components/Tariffs/Detail/TariffDetailTOU';
import TariffSubscriptions from '../../components/Tariffs/Detail/TariffSubscriptions';
import { GenabilityProperty } from '../../types';
import { getProposalTariff } from '../../actions/proposal_tariffs';
import { useEscalationRate } from '../../queries/escalationRates';

const TabContainer: React.FC = props => {
  return (
    <Typography component="div" style={{ padding: 8 * 3, overflow: 'scroll' }}>
      {props.children}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  flex: {
    flex: '1 1 100%'
  },
  menu: {
    width: 200
  },
  title: {
    flex: '0 0 auto'
  },
  whiteLabel: {
    color: '#fff'
  }
}));

const SEASON_KEY = 'seasons';

// TODO: its propbably not the right way to extend location and params
type TParams = { id: string };
interface Props extends RouteComponentProps<TParams> {
  location: any;
}

const TariffDetailPage: React.FC<Props> = props => {
  const [state, setState] = useState({
    properties: [],
    applicabilityValues: {},
    overview: {},
    value: 'overview'
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const tariff = useSelector((state: StoreState) => state.tariff);
  const breadcrumbsTrail = useSelector((state: StoreState) => state.breadcrumbsTrail);

  const { status: ERStatus, data: ERData, error: ERError, refetch: refetchER } = useEscalationRate({
    masterTariffId: tariff.source_id,
    currentApplicabilityValues: tariff.applicability_values || {}
  });

  useEffect(() => {
    let applicabilityValues;
    if (props?.location?.state?.applicabilityValues) {
      applicabilityValues = props.location.state.applicabilityValues;
    }
    const {
      match: { params }
    } = props;
    const queryParams: any = parseQuery(props.location.search as any);
    if (params?.id) {
      if (queryParams?.proposal) {
        dispatch(getProposalTariff(queryParams.proposal, params.id));
      } else if (queryParams?.manual) {
        dispatch(getManualTariff(params.id));
      } else if (queryParams?.effectiveOn) {
        dispatch(getTariff(params.id, queryParams.effectiveOn, applicabilityValues));
      }
    }

    // breadcrumbs check
    if (
      breadcrumbsTrail[breadcrumbsTrail.length - 1] &&
      breadcrumbsTrail[breadcrumbsTrail.length - 1].link &&
      breadcrumbsTrail[breadcrumbsTrail.length - 1].link !== '/tariffs'
    ) {
      dispatch(popBreadcrumb());
      dispatch(pushBreadcrumb('Tariffs', '/tariffs'));
    }
  }, []);

  useEffect(() => {
    const { lse_name, name, code } = tariff;
    if (lse_name && name && code) {
      dispatch(pushBreadcrumb(`${lse_name} - ${code} - ${name}`, props.location.pathname));
    }
  }, [tariff]);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setState({ ...state, value });
  };

  const handlePropertyUpdate = (name: string) => (event: any) => {
    if (!tariff.all_rates) return;

    const updatedApplicability = {
      ...tariff.applicability_values,
      [name]: event.target.value
    };
    const results = parseRateInformation(tariff.all_rates, updatedApplicability);
    dispatch(broadcastUpdateTariff({ ...tariff, ...results }));
  };

  const handleDuplicate = () => {
    // TODO: need to add applicability values to this
    props.history.push(
      '/create-tariff?id=' + (tariff.source_id || tariff.public_id) + '&effectiveDate=' + tariff.effective_date
    );
    // history.push('/create-tariff');
  };

  const handleEditTariff = () => {
    props.history.push(
      '/create-tariff?id=' +
        (tariff.source_id || tariff.public_id) +
        '&effectiveDate=' +
        tariff.effective_date +
        '&edit=true'
    );
  };

  const getInputType = (property: GenabilityProperty, applicabilityValues: Record<string, string> = {}) => {
    if (!applicabilityValues || applicabilityValues == {}) return null;
    switch (property.dataType) {
      case 'BOOLEAN':
        return (
          <div>
            <Typography variant="h6">{property.displayName}</Typography>
            <Switch />
          </div>
        );
      case 'CHOICE':
        return (
          <div>
            <Typography variant="subtitle1">{property.displayName}</Typography>
            <TextField
              id={property.propertyValue}
              name={property.displayName}
              select
              onChange={handlePropertyUpdate(property.keyName)}
              value={applicabilityValues[property.keyName]}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="none"
            >
              {property.choices &&
                property.choices.map((option: any) => (
                  <MenuItem className={classes.menu} key={option.value} value={option.value}>
                    {option.displayValue}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        );
      default:
        return null;
    }
  };

  const calculateEscalation = () => {
    if (!!tariff.source_id && !!tariff.applicability_values && Object.keys(tariff.applicability_values).length > 0) {
      refetchER();
    }
  };

  const { value } = state;
  const seasons = tariff.overview && tariff.overview[SEASON_KEY] ? tariff.overview[SEASON_KEY] : [];
  const isEV =
    tariff?.ev_subscriptions &&
    tariff?.ev_subscriptions?.length > 0 &&
    tariff?.overage_charges &&
    Object.keys(tariff.overage_charges).length > 1;

  // TODO: refactor so only one tariff type lives in esap
  const lseName = tariff.lse_name || 'No LSE Name';
  const tariffCode = tariff.code || 'No Tariff Code';
  return (
    <div className={classes.root}>
      <AccessControl requiredPermissions={['editor', 'admin']}>
        <div className="button-container">
          <Tooltip title="Duplicate Tariff">
            <Fab
              size="small"
              color="primary"
              aria-label="Duplicate Tariff"
              className="button-white"
              onClick={handleDuplicate}
            >
              <OpenInNew />
            </Fab>
          </Tooltip>
          {tariff?.is_global && (
            <Tooltip title="Edit Tariff">
              <Fab
                size="small"
                color="primary"
                aria-label="Edit Tariff"
                className="button-white"
                onClick={handleEditTariff}
              >
                <Edit />
              </Fab>
            </Tooltip>
          )}
        </div>
      </AccessControl>

      <AppBar position="static">
        <Toolbar>
          <Typography color="inherit" variant="h6">
            {lseName + ':  ' + tariffCode}
          </Typography>
        </Toolbar>
        <Tabs value={value} color="primary" onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <Tab label="Overview" value="overview" />
          <Tab label="Seasons / TOU" value="tou" />
          <Tab label="Consumption" value="consumption" />
          {!isEV && <Tab label="Demand" value="demand" />}
          {!isEV && <Tab label="Escalation" value="escalation" />}
          {isEV && <Tab label="Subscriptions" value="subscriptions" />}
        </Tabs>
      </AppBar>
      <Paper>
        {value === 'overview' && (
          <TabContainer>
            {tariff.properties &&
              tariff.properties
                .filter((property: any) => property.dataType === 'CHOICE' && property.propertyValue)
                .map((property: any, index: number) => {
                  return (
                    <div key={index} style={{ marginBottom: 25 }}>
                      {getInputType(property, tariff.applicability_values as Record<string, string>)}
                      {/* TODO: make sure we have this value available instead of casting */}
                    </div>
                  );
                })}
          </TabContainer>
        )}

        {value === 'tou' && (
          <div>
            {Object.keys(seasons).length > 0 && <TariffDetailTOU tariff={tariff} />}
            {Object.keys(seasons).length === 0 && (
              <div style={{ padding: 8 * 3 }}>
                <Typography>There are no seasons associated with this tariff</Typography>
              </div>
            )}
          </div>
        )}

        {value === 'consumption' && (
          <TabContainer>
            {Object.keys(seasons).map(seasonName => {
              return (
                <SeasonConsumption
                  key={seasonName}
                  season={seasons[seasonName]}
                  baseAdjustment={
                    tariff.is_global || tariff.is_manual ? 0 : tariff?.overview?.consumptionBaseAdjustment
                  }
                />
              );
            })}
            {Object.keys(seasons).length === 0 && tariff?.overview?.consumptionBaseAdjustment > 0 && (
              <Typography>
                Year Long Energy Charge: $ {tariff.overview.consumptionBaseAdjustment.toFixed(5)} / kWh
              </Typography>
            )}
          </TabContainer>
        )}

        {!isEV && value === 'demand' && (
          <TabContainer>
            {Object.keys(seasons).map(seasonName => {
              return (
                <SeasonDemand
                  key={seasonName}
                  baseCharges={tariff.is_global || tariff.is_manual ? [] : tariff.overview.demandBaseCharges}
                  season={seasons[seasonName]}
                />
              );
            })}
            {Object.keys(seasons).length === 0 && (
              <div>
                {tariff.overview?.demandBaseAdjustment > 0 && (
                  <div style={{ padding: 8 * 3 }}>
                    <Typography>
                      Base Demand Charge: $ {tariff.overview?.demandBaseAdjustment.toFixed(5)} / kW
                    </Typography>
                  </div>
                )}
                {tariff.overview?.demandBaseAdjustment === 0 && (
                  <Typography>There are no demand charges associated with this tariff</Typography>
                )}
              </div>
            )}
          </TabContainer>
        )}

        {isEV && value === 'subscriptions' && (
          <TabContainer>
            <TariffSubscriptions
              subscriptions={tariff.ev_subscriptions as any}
              overageCharges={tariff.overage_charges as any}
            />
          </TabContainer>
        )}

        {!isEV && value === 'escalation' && (
          <TabContainer>
            {ERStatus !== 'success' && (
              <Button color="primary" aria-label="Escalation Rate Analysis" onClick={calculateEscalation}>
                <ShowChart /> Calculate Escalation Rates
              </Button>
            )}
            {ERStatus === 'error' && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                There was an error while processing this analysis —{' '}
                <strong>{`${ERError ? ERError.message! : ''}`}</strong>
              </Alert>
            )}
            {ERStatus === 'loading' && (
              <div style={{ textAlign: 'center', padding: 25 }}>
                <CircularProgress color="secondary" size={50} />
              </div>
            )}
            {!!ERData && ERStatus === 'success' && (
              <div>
                <div>
                  <EscalationChart data={ERData.finalEnergyTable} title="Energy Escalation" />
                </div>
                <Table padding="none">
                  <TableBody>
                    {ERData.finalEnergyTable.map((row: any, rowIndex: number) => {
                      return (
                        <TableRow key={rowIndex}>
                          {row.map((value: any, valueIndex: number) => {
                            return <TableCell key={valueIndex}>{value}</TableCell>;
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <div>
                  <EscalationChart data={ERData.finalDemandTable} title="Demand Escalation" />
                </div>
                <Table padding="none">
                  <TableBody>
                    {ERData.finalDemandTable.map((row: any, rowIndex: number) => {
                      return (
                        <TableRow key={rowIndex}>
                          {row.map((value: any, valueIndex: number) => {
                            return <TableCell key={valueIndex}>{value}</TableCell>;
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </TabContainer>
        )}
      </Paper>
    </div>
  );
};

export default TariffDetailPage;
