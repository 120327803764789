import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core/styles';

// REACT IMPORTS
import Wizard from '../../Wizard/Wizard';
import WizardStep from '../../Wizard/WizardStep';
// import { parseQuery } from '../../utility/General';
import { resetCreatePortfolio, runPortfolio } from '../../../actions/portfolios';
import { pushBreadcrumb, resetBreadcrumb } from '../../../actions/breadcrumbs';
// import { Portfolio } from '../../../types';
import { StoreState } from '../../../reducers';
import PortfolioGeneralContainer from './PortfolioGeneralContainer';
import { RouteComponentProps } from 'react-router';
import PortfolioMetersContainer from '../Detail/PortfolioMetersContainer';
import ErrorDialog from '../../Common/ErrorDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: '10px'
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  activeLabel: {
    color: theme.palette.secondary.main
  },
  runButton: {
    margin: 10,
    padding: 25,
    fontSize: '1em'
  },
  stepContainer: {
    margin: 20
  },
  center: {
    textAlign: 'center'
  }
}));

const steps = [
  {
    label: 'General',
    component: <PortfolioGeneralContainer />
  },
  {
    label: 'Assign Meters',
    component: <PortfolioMetersContainer />
  }
];

const PortfolioCreate: React.FC<RouteComponentProps> = props => {
  const { location } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const newPortfolio = useSelector((state: StoreState) => state.newPortfolio);

  const [validation, setValidation] = useState<string[]>([]);

  // initialize form
  useEffect(() => {
    dispatch(resetCreatePortfolio());
  }, [dispatch]);

  // breadcrumbs
  useEffect(() => {
    // check if previous breadcrumbs should be popped or not
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Portfolios', '/portfolios'));
    dispatch(pushBreadcrumb('Create Portfolio', location.pathname));
  }, [location.pathname, dispatch]);

  const validateNewPortfolio = () => {
    const messages: string[] = [];
    if (!newPortfolio.name) {
      messages.push('A portfolio must  have a name.');
    }
    if (!newPortfolio.portfolio_meters || !newPortfolio.portfolio_meters) {
      messages.push('You need to assign proposals/meters to this portfolio.');
    } else {
      newPortfolio.portfolio_meters.forEach(meter => {
        if (!meter.scenario_id) {
          messages.push(`You must choose a scenario for proposal ${meter.proposal_name || meter.proposal_id}`);
        }
      });

      // check if primary meter has solar
      const primary = newPortfolio.portfolio_meters.find(m => m.is_primary);
      if (primary) {
        const primary_scenario = primary.scenarios.find(s => s.public_id == primary.scenario_id);
        if (primary_scenario) {
          if (!primary_scenario.assumptions.isPV) {
            messages.push(`Primary scenario must use solar.`);
          }
        }
      }

      if (newPortfolio.portfolio_meters.length < 2) {
        messages.push(`There should be at least one primary and one beneficiary meter.`);
      }
    }
    handleRunPortfolio(messages);
  };

  const handleRunPortfolio = (messages: string[]) => {
    if (!newPortfolio || !newPortfolio.public_id) {
      throw new Error('Error: Portfolio hasnt been created!');
    }
    if (messages.length) {
      setValidation(messages);
    } else {
      setValidation([]);
      dispatch(runPortfolio(newPortfolio.public_id));
      props.history.push('/portfolios');
    }
  };

  return (
    <div className={classes.root}>
      <ErrorDialog
        open={validation.length > 0}
        errorMsg={
          <>
            <h3>This portfolio has been saved but can't be run because of missing or incorrect data.</h3>
            <ul>
              {validation.map(warning => {
                return <li key={warning}>{warning}</li>;
              })}
            </ul>
          </>
        }
        onClose={() => setValidation([])}
      />
      <Wizard onFinished={validateNewPortfolio}>
        {steps.map((step, i) => (
          <WizardStep key={i} label={step.label}>
            {step.component}
          </WizardStep>
        ))}
      </Wizard>
    </div>
  );
};

export default PortfolioCreate;
