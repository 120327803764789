import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// MATERIAL UI IMPORTS
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Delete from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
// import FilterIcon from '@material-ui/icons/FilterList';
// import AutorenewIcon from '@material-ui/icons/Autorenew';

// REACT IMPORTS
import ListSearchInput from '../../Table/ListSearchInput';
import BaseTable from '../../Table/BaseTable';
// import { stableSort, getSorting } from '../../../utility/Table';
import { portfolioColumnSchema } from '../../../utility/Portfolio';
import ConfirmPortfolioDelete from './ConfirmPortfolioDelete';
import PortfoliosListFilters from './PortfoliosListFilters';
import { createErrorSelector } from '../../../selectors';
import ErrorDialog from '../../Common/ErrorDialog';
import {
  UPDATE_PORTFOLIOS_LIST_FILTERS,
  GET_ALL_PORTFOLIOS_IDLE,
  // updatePortfolio,
  // archivePortfolio,
  getAllPortfolios
} from '../../../actions/portfolios';
import { StoreState } from '../../../reducers';
import { Portfolio } from '../../../types';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
    overflowX: 'auto'
  },
  toolbar: {
    backgroundColor: theme.palette.esap.blue,
    color: '#fff'
  },
  spacer: {
    flex: '1 1 auto'
  },
  table: {},
  white: {
    color: '#fff',
    marginRight: 10
  },
  noPortfolios: {
    padding: '20px',
    fontStyle: 'italic',
    fontWeight: 500
  },
  filtersChip: {
    color: 'white',
    backgroundColor: theme.palette.esap.blueBright,
    margin: theme.spacing(1),
    '& svg': {
      color: 'white'
    }
  }
}));

const INITIAL_STATE = {
  search: '',
  orderBy: 'updated_on',
  order: 'desc',
  page: 0,
  rowsPerPage: 10,
  isConfirm: false,
  selectedPortfolio: null as Portfolio | null,
  isFilterOpen: false,
  timeout: 0,
  userId: ''
};

type PortfoliosListProps = {
  title: string;
  portfolios: Portfolio[];
  portfoliosMeta: { page: number; pageSize: number; total: number };
  isLoading: boolean;
  deletePortfolio: any;
  resetPortfoliosList: any;
  history: any;
};

const PortfoliosList: React.FC<PortfoliosListProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { title, portfolios, portfoliosMeta, isLoading, resetPortfoliosList, history } = props;

  const [state, setState] = useState({ ...INITIAL_STATE, rowsPerPage: portfoliosMeta.pageSize });
  const errorGetAllPortfoliosSelector = createErrorSelector(['GET_ALL_PORTFOLIOS']);
  const errorGetAllPortfolios = useSelector(errorGetAllPortfoliosSelector);

  const filters = useSelector((state: StoreState) => state.portfoliosListFilters);

  useEffect(() => {
    setState({ ...state, search: filters.search });
    return () => {
      if (state.timeout) clearTimeout(state.timeout);
    };
  }, []);

  const handleDeleteArchiveOpen = (portfolio: Portfolio) => (event: any) => {
    event.stopPropagation();
    setState({ ...state, selectedPortfolio: portfolio, isConfirm: true });
  };

  const handleDeleteArchiveClose = (type = 'close') => {
    setState({ ...state, selectedPortfolio: null, isConfirm: false });
  };

  const toggleFiltersDrawer = () => {
    setState({ ...state, isFilterOpen: !state.isFilterOpen });
  };

  // must declare after the methods used in the action handlers. could move to function that passes callback to delete
  const portfolioRowActionSchema = [
    {
      key: 'delete',
      icon: Delete,
      actionHandler: handleDeleteArchiveOpen,
      label: 'Delete/Archive Portfolio'
    }
  ];

  const handleRowClick = (portfolio: Portfolio) => () => {
    history.push('/portfolios/' + portfolio.public_id);
  };

  const handleSearchChange = (event: any) => {
    var searchText = event.target.value;
    if (state.timeout) clearTimeout(state.timeout);
    const timeout: number = window.setTimeout(() => {
      getFilteredPortfolios({
        search: searchText
      });
      setState({ ...state, search: searchText, timeout: 0 });
      dispatch({ type: UPDATE_PORTFOLIOS_LIST_FILTERS, payload: { ...filters, search: searchText } });
    }, 1000);

    setState({ ...state, search: event.target.value, timeout, page: 0 });
  };

  const handleRequestSort = (event: any, property: any) => {
    const orderBy = property;
    let order = 'desc';

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc';
    }

    getFilteredPortfolios({
      sortOn: property,
      orderBy: order
    });
    setState({ ...state, order, orderBy });
  };

  const handleChangePage = (event: any, page: any) => {
    getFilteredPortfolios({
      pageSize: state.rowsPerPage,
      page: page + 1
    });
    setState({ ...state, page });
  };

  const handleChangeRowsPerPage = (event: any) => {
    getFilteredPortfolios({
      pageSize: event.target.value
    });
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const updatePortfoliosListFilters = (payload: any): void => {
    getFilteredPortfolios({
      userId: payload?.user?.value ?? null,
      customerId: payload?.customer?.value ?? null,
      tariffCodes: payload?.tariff ?? null,
      showArchived: payload?.showArchived ?? null
    });

    dispatch({ type: UPDATE_PORTFOLIOS_LIST_FILTERS, payload: { ...payload, search: state.search } });
  };

  const getFilteredPortfolios = (filterChanges: any) => {
    dispatch(
      getAllPortfolios({
        includeScenarioSummary: true,
        search: state.search,
        userId: filters?.user?.value,
        customerId: filters?.customer?.value,
        tariffCodes: filters?.tariff,
        showArchived: filters?.showArchived,
        pageSize: state.rowsPerPage,
        sortOn: state.orderBy,
        orderBy: state.order,
        ...filterChanges
      })
    );
  };

  const clearFilters = () => {
    setState({ ...state, search: '' });
    resetPortfoliosList();
  };

  const hasFiltersOn = (filters: any) => {
    return (
      filters.user ||
      filters.showArchived ||
      filters.tariff?.length ||
      filters.createdBy !== '' ||
      filters.customer !== '' ||
      (filters.search !== '' && state.timeout === 0)
    );
  };

  const handleCloseGetAllPortfoliosError = () => {
    dispatch({ type: GET_ALL_PORTFOLIOS_IDLE });
  };

  return (
    <Paper>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit">
          {title ? title : 'Portfolios'}
        </Typography>
        <div className={classes.spacer} />
        {!isLoading && hasFiltersOn(filters) && (
          <Tooltip title="Clear Filters">
            <Chip
              className={classes.filtersChip}
              label={`${portfoliosMeta.total} Filtered Results`}
              onDelete={clearFilters}
            />
          </Tooltip>
        )}
        <ListSearchInput handleSearchChange={handleSearchChange} search={state.search} />

        {/* <Tooltip title="Filter list">
          <IconButton
            onClick={toggleFiltersDrawer}
            size="small"
            className={classes.white}
            aria-label="Filter Portfolio List"
          >
            <FilterIcon />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
      {isLoading && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {!isLoading && portfolios?.length === 0 && <div className={classes.noPortfolios}>No Portfolios Found</div>}
      {!isLoading && portfolios?.length !== 0 && (
        <BaseTable
          data={portfolios}
          onRowClick={handleRowClick}
          onRequestSort={handleRequestSort}
          columnSchema={portfolioColumnSchema}
          order={state.order}
          orderBy={state.orderBy}
          page={portfoliosMeta.page - 1}
          rowsPerPage={state.rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          dataCount={portfoliosMeta.total}
          rowActionSchema={portfolioRowActionSchema}
        />
      )}
      <ConfirmPortfolioDelete
        open={state.isConfirm}
        handleClose={handleDeleteArchiveClose}
        selectedPortfolio={state.selectedPortfolio}
      />
      <Drawer
        // classes={{ paper: classes.sidenav }}
        anchor="right"
        open={state.isFilterOpen}
        onClose={toggleFiltersDrawer}
      >
        <PortfoliosListFilters
          toggleFiltersDrawer={toggleFiltersDrawer}
          filters={filters}
          updatePortfoliosListFilters={updatePortfoliosListFilters}
        />
      </Drawer>
      <ErrorDialog
        open={errorGetAllPortfolios}
        errorMsg="Oops, we are having trouble loading portfolios! Please contact a site admin if problem persists."
        onClose={handleCloseGetAllPortfoliosError}
      />
    </Paper>
  );
};

export default PortfoliosList;
