import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import * as actionCreators from './actions';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Dont send big payloads to Sentry for perf reasons
  stateTransformer: state => {
    return null;
  },
  actionTransformer: action => {
    return { ...action, payload: null };
  }
});

const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });
export const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer)
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

const RootProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default RootProvider;
