import React, { useCallback, useEffect, useState } from 'react';
// import Plot from 'react-plotly.js';
import moment from 'moment';
import { useTheme, withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import { exportCSVFile } from '../../../utility/General';
import Settings from '@material-ui/icons/Settings';
import ComparisonSettings from './ComparisonSettings';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
import { Layout, PlotData } from 'plotly.js';
import { assertIsDefined } from '../../../utils/assertions';
import { ScenarioResults } from '../../../types';

const Plot = createPlotlyComponent(Plotly);

const costKeys = ['Demand Cost NC', 'Demand Cost Peak', 'Demand Cost MidPeak'];
const usageKeys = ['Demand Max NC', 'Demand Max Peak', 'Demand Max MidPeak'];

// const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const styles = {
  block: {
    marginTop: 8,
    marginBottom: 8
  }
};

interface ScenarioDemandChargeComparisonProps {
  scenarioData: ScenarioResults;
  statusQuoData: ScenarioResults;
  scenarioName: string;
  statusQuoName: string;
  discountRate: number;
}

const ScenarioDemandChargeComparison: React.FC<ScenarioDemandChargeComparisonProps> = props => {
  const { scenarioData, statusQuoData, scenarioName, statusQuoName, discountRate } = props;

  const [data, setData] = useState<Partial<PlotData>[]>([]);
  const [open, setOpen] = useState(false);
  const [keys, setKeys] = useState([...costKeys]);
  const [showUsage, setShowUsage] = useState(false);

  const theme = useTheme();

  const [labels, setLabels] = useState({
    scenarioLabel: scenarioName,
    statusQuoLabel: statusQuoName
  });

  const buildLayout = (): Partial<Layout> => {
    const layout: Partial<Layout> = {
      legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
      height: 280,
      margin: {
        t: 20,
        l: 60,
        r: 20,
        b: 40
      },
      xaxis: {
        // tickangle: -45
      },
      barmode: 'group'
    };

    return layout;
  };

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLabels({ ...labels, [prop]: event.target.value });
  };

  const buildData = useCallback(
    (scenarioData, statusQuoData, keys) => {
      if (!scenarioData || Object.keys(scenarioData).length === 0) {
        return;
      }
      const dataParts: { month: any; statusQuo: number; scenario: number }[] = [];
      let proposalMonths = statusQuoData['Months'];
      proposalMonths.forEach((month: any, index: string | number) => {
        // let monthIndex = +month.split('-')[1] - 1;
        let dataItem = {
          month: month,
          statusQuo: 0,
          scenario: 0
        };
        keys.forEach((key: string | number) => {
          let demandCostAdjustment = (statusQuoData[key][index] - scenarioData[key][index]) * (1 - discountRate);
          dataItem['statusQuo'] += statusQuoData[key][index];
          dataItem['scenario'] += scenarioData[key][index] + demandCostAdjustment;
        });
        dataParts.push(dataItem);
      });
      let updatedData: Partial<PlotData>[] = [];
      updatedData.push({
        type: 'bar',
        name: labels.statusQuoLabel,
        x: dataParts.map(x => moment(x.month).format('MM/YY')),
        y: dataParts.map(x => x.statusQuo),
        marker: {
          color: 'rgb(204,204,204)'
        }
      });
      updatedData.push({
        type: 'bar',
        name: labels.scenarioLabel,
        x: dataParts.map(x => moment(x.month).format('MM/YY')),
        y: dataParts.map(x => x.scenario),
        marker: {
          color: (theme as any).palette.lightBlue
        }
      });
      setData(updatedData);
    },
    [discountRate, labels.scenarioLabel, labels.statusQuoLabel, (theme as any).palette.lightBlue]
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      buildData(scenarioData, statusQuoData, keys);
    }
    return () => {
      ignore = false;
    };
  }, [labels.scenarioLabel, labels.statusQuoLabel, discountRate, buildData, keys, scenarioData, statusQuoData]);

  // Need to update for technologies
  const handleDownload = () => {
    const headers = ['Datetime', 'Status Quo', 'Scenario'];
    let downloadData: any = [];
    assertIsDefined(data[0].x);
    data[0].x.forEach((date: any, index: string | number) => {
      const row = [date];
      data.forEach(item => {
        assertIsDefined(item?.y);
        row.push(item.y[index]);
      });
      downloadData.push(row);
    });

    exportCSVFile(headers, downloadData, 'Demand Charge Comparison ');
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleValues = () => {
    if (showUsage) {
      buildData(props.scenarioData, props.statusQuoData, [...costKeys]);
      setShowUsage(false);
      setKeys([...costKeys]);
    } else {
      buildData(props.scenarioData, props.statusQuoData, [...usageKeys]);
      setShowUsage(true);
      setKeys([...usageKeys]);
    }
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedKey = event.target.name;
    let updatedKeys = [...keys];
    let keyIndex = keys.indexOf(updatedKey);
    if (keyIndex === -1) {
      updatedKeys.push(updatedKey);
    } else {
      if (updatedKeys.length > 1) {
        updatedKeys.splice(keyIndex, 1);
      }
    }
    buildData(props.scenarioData, props.statusQuoData, updatedKeys);
    setKeys(updatedKeys);
  };

  return (
    <Paper>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          {labels.statusQuoLabel} vs {labels.scenarioLabel} Demand Charge
        </Typography>
        <IconButton aria-label="Chart Settings" onClick={handleOpen}>
          <Settings />
        </IconButton>
        <IconButton aria-label="Download" onClick={handleDownload}>
          <InsertDriveFile />
        </IconButton>
      </Toolbar>
      <div style={{ height: 300 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={data}
          layout={buildLayout()}
          config={{ displayModeBar: false }}
        />
      </div>
      <ComparisonSettings
        isDemand={true}
        open={open}
        keys={keys}
        showUsage={showUsage}
        labels={labels}
        handleChange={handleChange}
        handleClose={handleClose}
        toggleValues={toggleValues}
        handleCheck={handleCheck}
      />
    </Paper>
  );
};

export default withStyles(styles)(ScenarioDemandChargeComparison);
