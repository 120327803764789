import { RUN_BILLING_SUCCESS } from '../../actions/explore';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RUN_BILLING_SUCCESS:
      let billingOverview = [];
      let billingKeys = Object.keys(action.payload);
      let numberOfBillings = Object.keys(action.payload[billingKeys[0]]).length;
      billingOverview.push([
        'End',
        'Start',
        'Season',
        'Demand NC',
        'Demand Peak',
        'Demand Midpeak',
        'Demand Charges',
        'Energy Peak',
        'Energy Midpeak',
        'Energy Super Off Peak',
        'Energy Charges',
        'Total'
      ]);
      for (let i = 0; i < numberOfBillings; i++) {
        let billingRow = [];
        billingKeys.forEach(key => {
          billingRow.push(action.payload[key][i.toString()]);
        });
        billingOverview.push(billingRow);
      }
      return billingOverview;
    default:
      return state;
  }
}
