import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Delete from '@material-ui/icons/Delete';
import OpenInNew from '@material-ui/icons/OpenInNew';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
// notification section for confirmations and snackbar messages
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

// REACT IMPORTS
import ConfirmPropsalDelete from '../../components/Proposals/List/ConfirmPropsalDelete';
import ProposalDetailTariffContainer from '../../components/Proposals/Detail/ProposalDetailTariffContainer';
import ProposalDetailGeneralContainer from '../../components/Proposals/Detail/ProposalDetailGeneralContainer';
import ProposalDetailDataContainer from '../../components/Proposals/Detail/ProposalDetailDataContainer';
import ScenariosSavingsComparison from '../../components/Proposals/Charts/ScenariosSavingsComparison';
import ProposalDetailScenarioList from '../../components/Scenarios/List/ProposalDetailScenarioList';
import ProposalDetailOverview from '../../components/Proposals/Detail/ProposalDetailOverview';
import ProposalSizingContainer from '../../components/Proposals/Detail/ProposalSizingContainer';
import ProposalPresentationContainer from '../../components/Proposals/Detail/ProposalPresentationContainer';
import MultiYearSelector from '../../components/Scenarios/Detail/MultiYearSelector';

import { createLoadingSelector } from '../../selectors';
import {
  getProposal,
  copyProposalData,
  runESAP,
  downloadSimulationFileFromS3,
  UPDATE_PROPOSAL_SUCCESS,
  GET_PROPOSAL_IDLE
} from '../../actions/proposals';
import {
  selectProposalScenario,
  getProposalScenario,
  handleEnableScenario,
  handleDisableScenario
} from '../../actions/scenarios';
import { getCustomer, getAllCustomers } from '../../actions/customers';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import { resetProfileData } from '../../actions/profiledata';

import AccessControl from '../../utility/AccessControl';
import ResultMatrixContainer from '../../components/TemplateResultsMatrix/ResultMatrixContainer';
import { ProposalInternal, ScenarioInternal, ESAPTariff } from '../../types';
import { StoreState } from '../../reducers';
import { ProposalDetailContext } from '../../components/Proposals/Detail/ProposalDetailContextProvider';

import { createErrorSelector } from '../../selectors';
import ErrorDialog from '../../components/Common/ErrorDialog';
import { assertIsDefined } from '../../utils/assertions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  },
  spacing: {
    margin: theme.spacing(3)
  },
  item: {
    paddingRight: 15,
    marginTop: 30,
    marginBottom: 30
  },
  close: {},
  runESAPButton: {
    background: (theme.palette as any).esap.greenDark,
    color: '#fff',
    marginRight: 8,
    '&:hover': {
      background: (theme.palette as any).esap.greenDark
    }
  }
}));

type ITab = 'scenarios' | 'general' | 'tariffs' | 'data' | 'sizing' | 'presentation';

const ProposalDetail: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [value, setValue] = useState<ITab>('scenarios');
  const [isMessage, setIsMessage] = useState(false);
  const [isMissingDataMessage, setIsMissingDataMessage] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationAction, setConfirmationAction] = useState('');
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const {
    isMultiYear,
    setIsMultiYear,
    setValidYears,
    setSelectedMonths,
    setSelectedYears,
    setValidMonths
  } = useContext(ProposalDetailContext);
  const errorGetProposalSelector = createErrorSelector(['GET_PROPOSAL']);
  const errorGetProposal = useSelector(state => errorGetProposalSelector(state as StoreState));

  const classes = useStyles();

  const ProposalLoadingSelector = createLoadingSelector([
    'GET_PROPOSAL',
    'COPY_PROPOSAL',
    'GET_PROPOSAL_RESULT',
    'RERUN_ESAP'
  ]);
  const isLoading = useSelector(ProposalLoadingSelector);

  const ScenarioLoadingSelector = createLoadingSelector(['CREATE_PROPOSAL_SCENARIO']);
  const isNewScenarioLoading = useSelector(ScenarioLoadingSelector);

  const proposal = useSelector((state: StoreState) => state.proposal);
  const customer = useSelector((state: StoreState) => state.customer);
  const customers = useSelector((state: StoreState) => state.customers);

  const breadcrumbsTrail = useSelector((state: StoreState) => state.breadcrumbsTrail);

  let { id } = useParams<{ id?: string }>();

  // initialize data
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      if (id && id.length > 0 && id !== proposal.public_id) {
        dispatch(getProposal(id, { onSuccess: resetProfileData }));
      }
    }

    return () => {
      ignore = true;
    };
  }, [id, proposal.public_id, dispatch]);

  // initilaize breadcrumbs
  const { name } = proposal;
  useEffect(() => {
    if (
      breadcrumbsTrail[breadcrumbsTrail.length - 1] &&
      breadcrumbsTrail[breadcrumbsTrail.length - 1].link &&
      !breadcrumbsTrail[breadcrumbsTrail.length - 1].link.includes('/customers/')
    ) {
      dispatch(resetBreadcrumb());
    }
    dispatch(pushBreadcrumb('Proposals', '/proposals'));
    dispatch(pushBreadcrumb(name, location.pathname));
    // eslint-disable-next-line
  }, [location.pathname, name, dispatch]);

  useEffect(() => {
    if (proposal?.customer_id) {
      dispatch(getCustomer(proposal.customer_id));
    }
  }, [proposal.customer_id, dispatch]);

  useEffect(() => {
    if (!customers || customers.length == 0) {
      dispatch(getAllCustomers());
    }
  }, [dispatch, customers]);

  useEffect(() => {
    if (setIsMultiYear && proposal.proposal_scenarios.length > 0) {
      let isMulti = proposal.proposal_scenarios.some(scenario => scenario.assumptions.isMultiyear);
      setIsMultiYear(isMulti);
    }
  }, [proposal.proposal_scenarios, proposal.proposal_scenarios.length, setIsMultiYear]);

  // grab the different years in a multiple years scenario and save them in state
  useEffect(() => {
    if (isMultiYear) {
      const years: string[] = [];
      const scenarios = proposal.proposal_scenarios.filter(scenario => scenario.assumptions.isMultiyear);
      if (scenarios.length) {
        scenarios.sort(function sortByNbOfYears(x, y) {
          if (x.results && y.results) {
            return y.results['Months'].length - x.results['Months'].length;
          } else return 0;
        });
        if (scenarios[0]?.results && Object.keys(scenarios[0].results).length > 0) {
          const months = scenarios[0].results['Months'];
          setValidMonths(months);
          const dateRegex = /(\d\d)\/(\d\d)\/(\d\d\d\d)/;
          months.forEach(function collectUniqueYears(month) {
            const dateMatch = month.match(dateRegex);

            assertIsDefined(dateMatch);

            const year = dateMatch[3];
            if (year && !years.includes(year)) {
              years.push(year);
            }
          });
          // initialize mutliyear months to the first months in results
          setSelectedMonths({
            start: parseInt(months[0].slice(0, 2)) - 1,
            end: parseInt(months[11].slice(0, 2)) - 1
          });
          setSelectedYears({
            start: months[0].slice(6),
            end: months[11].slice(6)
          });
        }
      }
      setValidYears(years);
    }
  }, [isMultiYear, proposal.proposal_scenarios, setSelectedMonths, setSelectedYears, setValidMonths, setValidYears]);

  const showMessage = () => {
    setIsMessage(true);
  };

  const hideMessage = () => {
    setIsMessage(false);
  };

  const hideMissingDataMessage = () => {
    setIsMissingDataMessage(false);
  };

  const handleCopy = () => {
    dispatch(copyProposalData(proposal.public_id));
    history.push('/proposals');
  };

  const handleOperationsDownload = () => {
    if (proposal?.customer?.public_id) {
      downloadSimulationFileFromS3(proposal.customer.public_id, proposal.public_id);
    }
  };

  const handleRunESAP = () => {
    dispatch(runESAP(proposal.public_id));
    showMessage();
  };

  const handleChange = (event: any, value: any) => {
    setValue(value);
  };

  const handleMonthlyViewClick = (scenario: ScenarioInternal) => {
    // setSelectedScenario(scenario);
  };

  const handleScenarioToggleVisibility = (scenario: ScenarioInternal) => {
    if (scenario.active) {
      dispatch(handleDisableScenario(proposal.public_id, scenario.public_id));
    } else {
      dispatch(handleEnableScenario(proposal.public_id, scenario.public_id));
    }
  };
  // const getAssumptions = proposal => {
  //   if (proposal && proposal.assumptions && proposal.assumptions) {
  //     return proposal.assumptions;
  //   } else {
  //     return {};
  //   }
  // };

  const handleTariffRowClick = (tariff: ESAPTariff) => () => {
    history.push('/tariffs/' + tariff.public_id + '?proposal=' + proposal.public_id);
  };

  const handleDeleteArchiveOpen = (proposal: ProposalInternal) => (event: any) => {
    // event.stopPropagation();
    setIsConfirmDelete(true);
    //setState({ ...state, selectedProposal: proposal, isConfirm: true });
  };

  const handleDeleteArchiveClose = (type: string = 'close') => {
    setIsConfirmDelete(false);
    if (type === 'delete') {
      history.push('/proposals');
    }
    if (type === 'archive') {
      dispatch({ type: UPDATE_PROPOSAL_SUCCESS, payload: { ...proposal, is_archived: true } });
    }
    if (type === 'unarchive') {
      dispatch({ type: UPDATE_PROPOSAL_SUCCESS, payload: { ...proposal, is_archived: false } });
    }
  };

  const getStatusQuoScenario = (): ScenarioInternal | undefined => {
    return proposal?.proposal_scenarios.find(scenario => scenario.is_status_quo);
  };

  const handleConfirmClose = (success: boolean) => () => {
    if (success) {
      if (confirmationAction === 'runESAP') {
        handleRunESAP();
      }
      // } else if (confirmationAction === 'deleteProposal') {
      //   deleteProposal(proposal.public_id);
      //   history.push('/proposals');
      // }
    }
    setIsConfirm(false);
    setConfirmationMessage('');
    setConfirmationAction('');
  };

  const handleCloseGetProposalError = () => {
    dispatch({ type: GET_PROPOSAL_IDLE });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className="button-container">
          <AccessControl editorHasPermission={proposal.is_editable} requiredPermissions={['editor', 'admin']}>
            <>
              {proposal.last_run && proposal.status_quo_result && (
                <React.Fragment>
                  <Tooltip title="Download Operations Data">
                    <Fab
                      data-testid="save-button"
                      size="small"
                      color="primary"
                      aria-label="Download Operations Data"
                      className="button-white"
                      onClick={handleOperationsDownload}
                    >
                      <InsertDriveFile />
                    </Fab>
                  </Tooltip>
                </React.Fragment>
              )}
              <Tooltip title="Copy Proposal">
                <Fab
                  size="small"
                  color="primary"
                  aria-label="Copy Proposal"
                  className="button-white"
                  onClick={handleCopy}
                >
                  <OpenInNew />
                </Fab>
              </Tooltip>
              <Tooltip title="Delete Proposal">
                <Fab
                  color="secondary"
                  size="small"
                  aria-label="Delete/Archive Proposal"
                  className="button-white"
                  onClick={handleDeleteArchiveOpen(proposal)}
                >
                  <Delete />
                </Fab>
              </Tooltip>
            </>
          </AccessControl>
        </div>

        <AppBar position="static" color="default">
          <Tabs variant="fullWidth" value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
            <Tab label="Scenarios" value="scenarios" />
            <Tab label="General" value="general" />
            <Tab label="Tariffs" value="tariffs" />
            <Tab label="Data" value="data" />
            <Tab label="Sizing" value="sizing" />
            <Tab label="Presentation" value="presentation" />
          </Tabs>
        </AppBar>
        <div className={classes.spacing}>
          {isLoading && (
            <div style={{ textAlign: 'center', padding: 80 }}>
              <CircularProgress color="secondary" size={50} />
            </div>
          )}
          {!isLoading && (
            <div>
              {value === 'scenarios' && (
                <div>
                  <ProposalDetailOverview proposal={proposal} customer={customer} />
                  {proposal &&
                    proposal.proposal_templates &&
                    proposal.proposal_templates.length > 0 &&
                    proposal.proposal_templates.map(template => {
                      return <ResultMatrixContainer proposal={proposal} template={template} />;
                    })}
                  {isMultiYear && <MultiYearSelector />}
                  <ProposalDetailScenarioList
                    proposal={proposal}
                    scenarios={proposal.proposal_scenarios}
                    assumptions={proposal.assumptions}
                    selectedScenarioIds={proposal.selected_scenarios}
                    statusQuoResult={proposal.status_quo_result}
                    handleScenarioClick={selectProposalScenario}
                    handleMonthlyViewClick={handleMonthlyViewClick}
                    handleToggleVisibility={handleScenarioToggleVisibility}
                    proposalId={proposal.public_id}
                    getProposalScenario={getProposalScenario}
                    history={history}
                    tariffs={proposal.proposal_tariffs}
                    loading={isNewScenarioLoading}
                  />
                  {proposal && proposal.proposal_scenarios && (
                    <div>
                      <ScenariosSavingsComparison
                        scenarios={proposal.proposal_scenarios}
                        statusQuoScenario={getStatusQuoScenario()}
                      />
                    </div>
                  )}
                </div>
              )}
              {value === 'general' && (
                <div style={{ marginTop: 8 * 3 }}>
                  <ProposalDetailGeneralContainer proposal={proposal} customer={customer} customers={customers} />
                </div>
              )}
              {value === 'tariffs' && (
                <div style={{ marginTop: 8 * 3 }}>
                  <ProposalDetailTariffContainer
                    proposal={proposal}
                    proposalScenarios={proposal.proposal_scenarios}
                    proposalId={proposal.public_id}
                    tariffs={proposal.proposal_tariffs}
                    handleRowClick={handleTariffRowClick}
                  />
                </div>
              )}
              {value === 'data' && <ProposalDetailDataContainer proposal={proposal} />}
              {value === 'sizing' && <ProposalSizingContainer proposal={proposal} />}
              {value === 'presentation' && <ProposalPresentationContainer proposal={proposal} />}
            </div>
          )}
        </div>

        <Snackbar
          open={isMissingDataMessage}
          autoHideDuration={5000}
          onClose={hideMissingDataMessage}
          message={<span id="message-id">You have missing data files. Please verify on the data tab.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={hideMissingDataMessage as any}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <Snackbar
          open={isMessage}
          autoHideDuration={5000}
          onClose={hideMessage}
          message={<span id="message-id">ESAP Running Proposal</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={hideMessage as any}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
        <ConfirmPropsalDelete
          open={isConfirmDelete}
          handleClose={handleDeleteArchiveClose}
          selectedProposal={proposal}
        />
        <Dialog
          open={isConfirm}
          onClose={handleConfirmClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmationMessage}</DialogTitle>
          <DialogActions>
            <Button onClick={handleConfirmClose(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmClose(true)} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <ErrorDialog
          open={errorGetProposal}
          errorMsg="Oops, we are having trouble loading this proposal! Please contact a site admin if problem persists."
          onClose={handleCloseGetProposalError}
        />
      </div>
    </React.Fragment>
  );
};

export default ProposalDetail;
