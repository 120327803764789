import { ANALYZE_LOAD_PROFILE } from '../../actions/explore';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ANALYZE_LOAD_PROFILE:
      return action.payload;
    default:
      return state;
  }
}
