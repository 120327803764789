import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Archive from '@material-ui/icons/Archive';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
// import StarOutlined from '@material-ui/icons/StarOutlined';

import ScenarioBadges from '../../Scenarios/ScenarioBadges';
import { ProposalInternal, Customer } from '../../../types';
import { addFavorite, removeFavorite, getFavorites } from '../../../actions/favorites';
import { StoreState } from '../../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    flexGrow: 1,
    height: '100%',
    marginBottom: theme.spacing(2)
  },
  paper: {
    height: '100%',
    minHeight: 250,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    flexGrow: 1,
    transition: 'min-height 0.3s ease-out'
  },
  minimize: {
    height: 60,
    minHeight: 0
  },
  head: {
    lineHeight: '18px',
    fontSize: 18,
    fontWeight: 500,
    marginBottom: '5px',
    '& >span': {
      float: 'left',
      '& button': {
        padding: 0
      }
    }
  },
  name: {
    lineHeight: '18px',
    fontSize: 18,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    color: '#333'
  },
  badgeContainer: {
    float: 'right'
  },
  infoContainer: {
    clear: 'both'
  },
  table: {
    '& td': {
      padding: 5,
      fontSize: 12,
      borderBottom: '1px solid #f3f3f3'
    },
    '& td:first-child': {
      // backgroundColor: '#f3f3f3',
      width: '120px',
      fontWeight: '500'
    }
  },
  archived: {
    position: 'relative',
    top: '3px',
    color: '#717171'
  },
  timestamps: {
    margin: '5px 0',
    '& span': {
      fontSize: 11,
      fontStyle: 'italic'
    }
  },
  tariffDisplay: {
    '&::after': {
      content: "', '"
    },
    '&:last-child::after': {
      content: "''"
    }
  },
  //logs
  tableContainer: {
    maxHeight: 210,
    marginTop: theme.spacing(2),
    '& th': {
      padding: '3px',
      fontSize: '11px'
    }
  },
  logName: {
    textTransform: 'capitalize'
  },
  star: {
    padding: 5,
    marginRight: 4,
    position: 'relative',
    top: -1
  },
  favorited: {
    color: '#ffab00'
  }
}));

interface Props {
  proposal: ProposalInternal;
  customer: Customer;
}

const ProposalDetailOverview: React.FC<Props> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { proposal, customer } = props;

  const [showOverview, setShowOverview] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const favorites = useSelector((state: StoreState) => state.favorites);

  useEffect(() => {
    const fav = favorites.proposals.filter(favoriteProposal => favoriteProposal.public_id === proposal.public_id);
    setIsFavorite(fav.length === 0 ? false : true);
  }, [favorites]);

  const handleToggleFavorite = () => {
    if (isFavorite) {
      dispatch(removeFavorite('proposal', proposal.public_id));
    } else {
      dispatch(addFavorite('proposal', proposal.public_id));
    }
    setIsFavorite(!isFavorite);
    dispatch(getFavorites());
  };

  const handleToggleOverview = () => {
    setShowOverview(!showOverview);
  };

  return (
    <>
      <Typography className={classes.name}>
        <IconButton
          data-testid="favorite-button"
          key="favorite"
          aria-label="favorite"
          color="inherit"
          onClick={handleToggleFavorite}
          className={classes.star}
        >
          {isFavorite && <Star className={classes.favorited} />}
          {!isFavorite && <StarBorder />}
        </IconButton>
        {proposal.is_archived && (
          <Tooltip title="This proposal has been archived" enterDelay={100}>
            <Archive className={classes.archived} aria-label="archive-icon" />
          </Tooltip>
        )}
        {proposal.name}
      </Typography>
      <Grid container className={classes.gridContainer} spacing={2}>
        <Grid item md={6} xs={12}>
          <Paper className={`${classes.paper} ${!showOverview ? classes.minimize : ''}`}>
            <div className={classes.head}>
              <span style={{ paddingRight: 16 }}>Overview</span>
              <span>{!showOverview && <ScenarioBadges scenarios={proposal.proposal_scenarios} />}</span>
              <span style={{ float: 'right' }}>
                <IconButton key="toggle" aria-label="Toggle Overview" color="inherit" onClick={handleToggleOverview}>
                  {!showOverview && <Add />}
                  {showOverview && <Remove />}
                </IconButton>
              </span>
            </div>
            {showOverview && (
              <>
                <Box className={classes.timestamps}>
                  <span>Last Updated: {moment(proposal.updated_on).format('MMM DD, YYYY')}</span>{' '}
                  <span>Created On: {moment(proposal.updated_on).format('MMM DD, YYYY')}</span>
                </Box>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>{proposal.description}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Proposal Status</TableCell>
                      <TableCell>{proposal.status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Customer</TableCell>
                      <TableCell>
                        {' '}
                        <Link href={`/customers/${customer.public_id}`}>{customer.name}</Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Scenarios</TableCell>
                      <TableCell>
                        <ScenarioBadges scenarios={proposal.proposal_scenarios} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* lse_name=ESAP Created - show user tariff */}
                      <TableCell>Tariffs</TableCell>
                      <TableCell>
                        {proposal?.proposal_tariffs?.map(tariff => {
                          return <span className={classes.tariffDisplay}>{tariff.name}</span>;
                        })}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ProposalDetailOverview;
