import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { fromJS, Repeat } from 'immutable';

// MATERIAL UI
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// REACT IMPORTS
import { parseQuery } from '../../../utility/General';
import UpdateSeasons from './UpdateSeasons';
import GenerateSeasons from './GenerateSeasons';
import { touRateTemplate } from '../../../utility/TariffTemplate';
import { createManualTariff, updateManualTariff } from '../../../actions/manualtariffs';
import { calculateTotal } from '../../../utility/Tariff';
import { TariffBuilder } from './TariffBuilder';
import { TOUTypes, ESAPTariff, TOUPeriod } from '../../../types';
import { TariffFactoryContext, Period, getColor } from './TariffFactoryContext';
import ConfirmPopUp from '../../Common/ConfirmPopUp/ConfirmPopUp';
import { QueryParams } from '../../../pages/Tariffs/TariffCreate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    flex: {
      flex: 1
    },
    block: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(3)
    },
    centerItem: {
      display: 'flex',
      justifyContent: 'center'
    },
    textField: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(1),
      width: 400
    },
    subtitle: {
      marginBottom: theme.spacing(3)
    },
    dense: {
      marginTop: 19
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    addButton: {
      marginLeft: theme.spacing(12),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4)
    }
  })
);

interface TariffFactoryProps {
  baseTariff?: Readonly<ESAPTariff>;
}

const TariffFactory: React.FC<TariffFactoryProps> = props => {
  const classes = useStyles(props);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    dispatchTariffMeta,
    matrix,
    periods,
    setPeriods,
    dispatchMatrix,
    tariffMeta,
    handleChange,
    handleLSEChange,
    overage,
    setOverage,
    resetCustomTariff,
    factoryState,
    setFactoryState
  } = useContext(TariffFactoryContext);

  const { baseTariff } = props;

  useEffect(() => {
    const queryParams = parseQuery(location.search) as QueryParams;
    if (queryParams.edit) {
      setFactoryState('EDIT');
    }
  }, [location]);

  const [isEVTariff, setIsEVTariff] = useState(false);
  // const isEVTariff = false;

  const [isUpdateSeasons, setIsUpdateSeasons] = useState(false);

  const [newTariff, setNewTariff] = React.useState<ESAPTariff>(touRateTemplate);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  // fill metadata if creating from an existing tariff
  useEffect(() => {
    const initializeMetaData = () => {
      if (baseTariff && Object.keys(baseTariff).length) {
        dispatchTariffMeta({
          type: 'UPDATE_ALL',
          payload: {
            name: factoryState === 'CREATE' ? `${baseTariff.name} copy` : `${baseTariff.name}`,
            code: baseTariff.code,
            lse_id: baseTariff.lse_id.toString() || '0',
            lse_name: baseTariff.lse_name || 'Esap Created',
            description: baseTariff.description || ''
          }
        });
        if (baseTariff?.ev_subscriptions && baseTariff?.ev_subscriptions.length > 0) {
          setIsEVTariff(true);
        }
      } else {
        resetCustomTariff();
      }
    };
    initializeMetaData();
  }, [baseTariff, factoryState]);

  useEffect(() => {
    const initializePeriods = () => {
      const newPeriods: {
        energy: Period[];
        demand: Period[];
        subscriptions: Period[];
      } = {
        energy: [],
        demand: [
          {
            index: 1,
            name: `No Demand Charges`,
            TOU: TOUTypes.OFF_PEAK,
            color: getColor(1),
            charges: '0',
            type: 'DEMAND_BASED'
          }
        ],
        subscriptions: []
      };

      if (baseTariff?.overview?.seasons && Object.keys(baseTariff?.overview?.seasons).length > 0) {
        // handle EV Tariff specifics first
        if (baseTariff?.ev_subscriptions && baseTariff?.ev_subscriptions.length > 0) {
          baseTariff?.ev_subscriptions.forEach(sub => {
            newPeriods.subscriptions.push({
              index: sub.index,
              name: sub.name,
              reservationSize: sub.reservationSize,
              charges: sub.charges,
              type: 'SUBSCRIPTION_BASED'
            });
          });
          if (baseTariff?.overage_charges) {
            setOverage(baseTariff.overage_charges);
          }
        }
        // then parse general tariff
        const seasons = fromJS(baseTariff.overview.seasons).toJS();
        let index = 1;
        Object.keys(seasons).forEach(season => {
          Object.keys(seasons[season]).forEach(period => {
            if (period !== 'overview' && period !== 'NON_COINCIDENTAL') {
              // UPDATE ENERGY RATES
              let energyPeriodRate: any = {
                index,
                name: `${seasons[season].overview.name} ${period}`,
                TOU: period,
                color: getColor(index),
                charges: calculateTotal(
                  seasons[season][period],
                  'CONSUMPTION_BASED',
                  baseTariff.is_global ? 0 : baseTariff?.overview?.consumptionBaseAdjustment
                ),
                months: {
                  from: seasons[season].overview.fromMonth,
                  to: seasons[season].overview.toMonth
                }
              };
              if (energyPeriodRate.charges != '0') {
                energyPeriodRate.daysAndHours = seasons[season][period].reduce((acc: any, rate: any) => {
                  if (rate.type === 'CONSUMPTION_BASED' && rate?.periods) {
                    acc.push(...rate.periods);
                  }
                  return acc;
                }, []);
                // check if period starts one day and finish another
                energyPeriodRate.daysAndHours.forEach((tou: any) => {
                  if (tou.toHour > 0 && tou.fromHour > tou.toHour) {
                    const tempTOU = { ...tou };
                    tou.toHour = 24;
                    tempTOU.fromHour = 0;
                    energyPeriodRate.daysAndHours.push(tempTOU);
                  }
                });
                newPeriods.energy.push(energyPeriodRate);
              }

              // UPDATE DEMAND RATES
              let demandPeriodRate: any = {
                index: index + 1,
                name: `${seasons[season].overview.name} ${period}`,
                TOU: period,
                color: getColor(index + 1),
                charges: calculateTotal(
                  seasons[season][period],
                  'DEMAND_BASED',
                  baseTariff.is_global ? 0 : baseTariff?.overview?.demandBaseAdjustment
                ),
                months: {
                  from: seasons[season].overview.fromMonth,
                  to: seasons[season].overview.toMonth
                }
              };

              if (demandPeriodRate.charges != '0') {
                demandPeriodRate.daysAndHours = seasons[season][period].reduce((acc: any, rate: any) => {
                  if (rate.type === 'DEMAND_BASED' && rate?.periods) {
                    acc.push(...rate.periods);
                  }
                  return acc;
                }, []);
                // check if period starts one day and finish another
                demandPeriodRate.daysAndHours.forEach((tou: any) => {
                  if (tou.toHour > 0 && tou.fromHour > tou.toHour) {
                    const tempTOU = { ...tou };
                    tou.toHour = 24;
                    tempTOU.fromHour = 0;
                    demandPeriodRate.daysAndHours.push(tempTOU);
                  }
                });
                newPeriods.demand.push(demandPeriodRate);
              }
              index++;
            }
          });
        });
        setPeriods(newPeriods);
        generateMatrixFromBaseTariffPeriods(newPeriods);
      } else {
        // if it has no season so this is  a flat rate tariff:
        if (baseTariff?.overview) {
          const flatRatePeriods: {
            energy: Period[];
            demand: Period[];
            subscriptions: Period[];
          } = {
            energy: [
              {
                index: 1,
                name: `Flat Rate Energy Charges`,
                TOU: TOUTypes.FLAT_RATE,
                color: getColor(1),
                charges: baseTariff?.overview?.consumptionBaseAdjustment
                  ? baseTariff?.overview?.consumptionBaseAdjustment.toString()
                  : '0',
                type: 'CONSUMPTION_BASED'
              }
            ],
            demand: [
              {
                index: 1,
                name: `Flat Rate Demand Charges`,
                TOU: TOUTypes.FLAT_RATE,
                color: getColor(1),
                charges: baseTariff?.overview?.demandBaseAdjustment
                  ? baseTariff.overview.demandBaseAdjustment.toString()
                  : '0',
                type: 'DEMAND_BASED'
              }
            ],
            subscriptions: []
          };

          // Not pure! Plus should not be this function's concern. Not sure yet how to improve this.
          baseTariff.overview.consumptionBaseAdjustment = 0;
          baseTariff.overview.demandBaseAdjustment = 0;

          setPeriods(flatRatePeriods);
          generateMatrixFromBaseTariffPeriods(flatRatePeriods);
        }
      }
    };
    const generateMatrixFromBaseTariffPeriods = (periods: { energy: Period[]; demand: Period[] }) => {
      if (baseTariff && Object.keys(baseTariff).length) {
        // create array of array filled with 1
        const matrix: Readonly<number[][]> = Repeat(Repeat(1, 96), 12).toJS();
        periods.energy.forEach(period => {
          if (period && period?.daysAndHours && period?.months?.to && period?.months?.from) {
            period.daysAndHours.forEach(subPeriod => {
              let startColumn = subPeriod.fromHour;
              let endColumn = subPeriod.toHour === 0 ? 24 : subPeriod.toHour;

              let weekendToProcess;

              // period is for weekdays and weekends
              if (subPeriod.fromDayOfWeek === 0 && subPeriod.toDayOfWeek === 6) {
                const duplicate = { ...subPeriod, fromDayOfWeek: 5 };
                // duplicate the period and restrict it to the weekend
                weekendToProcess = duplicate;
                // restrict the current period to be just weekedays
                subPeriod.toDayOfWeek = 4;
              }

              if (subPeriod.fromDayOfWeek === 5 && subPeriod.toDayOfWeek === 6) {
                startColumn += 24;
                endColumn += 24;
              }

              if (period?.months?.to && period.months.to >= period.months.from) {
                for (let month = period.months.from; month < period.months.to + 1; month++) {
                  for (let hour = startColumn; hour < endColumn; hour++) {
                    matrix[month - 1][hour] = period.index;
                  }
                }
              } else {
                // case where the season is winter and start at the end of the year and stops at the beginning of the year
                // Split it in two parts
                if (period?.months?.from && period?.months?.to) {
                  for (let month = 1; month < period.months.to + 1; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }

                  for (let month = period.months.from; month < 13; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }
                }
              }

              // now parse the weekend part of this period if it exists
              if (weekendToProcess) {
                let startColumn = subPeriod.fromHour + 24;
                let endColumn = subPeriod.toHour === 0 ? 48 : subPeriod.toHour + 24;
                if (period?.months?.to && period.months.to >= period.months.from) {
                  for (let month = period.months.from; month < period.months.to + 1; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }
                } else {
                  // case where the season is winter and start at the end of the year and stops at the beginning of the year
                  // Split it in two parts
                  if (period?.months?.from && period?.months?.to) {
                    for (let month = 1; month < period.months.to + 1; month++) {
                      for (let hour = startColumn; hour < endColumn; hour++) {
                        matrix[month - 1][hour] = period.index;
                      }
                    }

                    for (let month = period.months.from; month < 13; month++) {
                      for (let hour = startColumn; hour < endColumn; hour++) {
                        matrix[month - 1][hour] = period.index;
                      }
                    }
                  }
                }
              }
            });
          }
        });
        periods.demand.forEach(period => {
          if (period && period?.daysAndHours && period?.months?.to && period?.months?.from) {
            period.daysAndHours.forEach(subPeriod => {
              let startColumn = subPeriod.fromHour + 48;
              let endColumn = subPeriod.toHour === 0 ? 72 : subPeriod.toHour + 48;

              let weekendToProcess;

              // period is for weekdays and weekends
              if (subPeriod.fromDayOfWeek === 0 && subPeriod.toDayOfWeek === 6) {
                const duplicate = { ...subPeriod, fromDayOfWeek: 5 };
                // duplicate the period and restrict it to the weekend
                weekendToProcess = duplicate;
                // restrict the current period to be just weekedays
                subPeriod.toDayOfWeek = 4;
              }

              if (subPeriod.fromDayOfWeek === 5 && subPeriod.toDayOfWeek === 6) {
                startColumn += 24;
                endColumn += 24;
              }

              if (period?.months?.to && period.months.to >= period.months.from) {
                for (let month = period.months.from; month < period.months.to + 1; month++) {
                  for (let hour = startColumn; hour < endColumn; hour++) {
                    matrix[month - 1][hour] = period.index;
                  }
                }
              } else {
                // case where the season is winter and start at the end of the year and stops at the beginning of the year
                // Split it in two parts
                if (period?.months?.from && period?.months?.to) {
                  for (let month = 1; month < period.months.to + 1; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }

                  for (let month = period.months.from; month < 13; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }
                }
              }

              // now parse the weekend part of this period if it exists
              if (weekendToProcess) {
                let startColumn = subPeriod.fromHour + 72;
                let endColumn = subPeriod.toHour === 0 ? 96 : subPeriod.toHour + 72;
                if (period?.months?.to && period.months.to >= period.months.from) {
                  for (let month = period.months.from; month < period.months.to + 1; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }
                } else {
                  // case where the season is winter and start at the end of the year and stops at the beginning of the year
                  // Split it in two parts
                  if (period?.months?.from && period?.months?.to) {
                    for (let month = 1; month < period.months.to + 1; month++) {
                      for (let hour = startColumn; hour < endColumn; hour++) {
                        matrix[month - 1][hour] = period.index;
                      }
                    }

                    for (let month = period.months.from; month < 13; month++) {
                      for (let hour = startColumn; hour < endColumn; hour++) {
                        matrix[month - 1][hour] = period.index;
                      }
                    }
                  }
                }
              }
            });
          }
        });
        const energyWeekdaySchedule = matrix.map(month => month.slice(0, 24));
        const energyWeekendSchedule = matrix.map(month => month.slice(24, 48));
        const demandWeekdaySchedule = matrix.map(month => month.slice(48, 72));
        const demandWeekendSchedule = matrix.map(month => month.slice(72, 96));
        dispatchMatrix({ type: 'ENERGY_WEEKDAY', matrix: energyWeekdaySchedule });
        dispatchMatrix({ type: 'ENERGY_WEEKEND', matrix: energyWeekendSchedule });
        dispatchMatrix({ type: 'DEMAND_WEEKDAY', matrix: demandWeekdaySchedule });
        dispatchMatrix({ type: 'DEMAND_WEEKEND', matrix: demandWeekendSchedule });
      }
    };
    initializePeriods();
  }, [baseTariff]);

  // useEffect(() => {
  //   const generateMatrixFromBaseTariffPeriods = (periods: { energy: Period[]; demand: Period[] }) => {
  //     if (baseTariff && Object.keys(baseTariff).length) {
  //       // create array of array filled with 1
  //       const matrix: Readonly<number[][]> = Repeat(Repeat(1, 96), 12).toJS();
  //       periods.energy.forEach(period => {
  //         if (period && period?.daysAndHours && period?.months?.to && period?.months?.from) {
  //           period.daysAndHours.forEach(subPeriod => {
  //             let startColumn = subPeriod.fromHour;
  //             let endColumn = subPeriod.toHour === 0 ? 24 : subPeriod.toHour;

  //             let weekendToProcess;

  //             // period is for weekdays and weekends
  //             if (subPeriod.fromDayOfWeek === 0 && subPeriod.toDayOfWeek === 6) {
  //               const duplicate = { ...subPeriod, fromDayOfWeek: 5 };
  //               // duplicate the period and restrict it to the weekend
  //               weekendToProcess = duplicate;
  //               // restrict the current period to be just weekedays
  //               subPeriod.toDayOfWeek = 4;
  //             }

  //             if (subPeriod.fromDayOfWeek === 5 && subPeriod.toDayOfWeek === 6) {
  //               startColumn += 24;
  //               endColumn += 24;
  //             }

  //             if (period?.months?.to && period.months.to >= period.months.from) {
  //               for (let month = period.months.from; month < period.months.to + 1; month++) {
  //                 for (let hour = startColumn; hour < endColumn; hour++) {
  //                   matrix[month - 1][hour] = period.index;
  //                 }
  //               }
  //             } else {
  //               // case where the season is winter and start at the end of the year and stops at the beginning of the year
  //               // Split it in two parts
  //               if (period?.months?.from && period?.months?.to) {
  //                 for (let month = 1; month < period.months.to + 1; month++) {
  //                   for (let hour = startColumn; hour < endColumn; hour++) {
  //                     matrix[month - 1][hour] = period.index;
  //                   }
  //                 }

  //                 for (let month = period.months.from; month < 13; month++) {
  //                   for (let hour = startColumn; hour < endColumn; hour++) {
  //                     matrix[month - 1][hour] = period.index;
  //                   }
  //                 }
  //               }
  //             }

  //             // now parse the weekend part of this period if it exists
  //             if (weekendToProcess) {
  //               let startColumn = subPeriod.fromHour + 24;
  //               let endColumn = subPeriod.toHour === 0 ? 48 : subPeriod.toHour + 24;
  //               if (period?.months?.to && period.months.to >= period.months.from) {
  //                 for (let month = period.months.from; month < period.months.to + 1; month++) {
  //                   for (let hour = startColumn; hour < endColumn; hour++) {
  //                     matrix[month - 1][hour] = period.index;
  //                   }
  //                 }
  //               } else {
  //                 // case where the season is winter and start at the end of the year and stops at the beginning of the year
  //                 // Split it in two parts
  //                 if (period?.months?.from && period?.months?.to) {
  //                   for (let month = 1; month < period.months.to + 1; month++) {
  //                     for (let hour = startColumn; hour < endColumn; hour++) {
  //                       matrix[month - 1][hour] = period.index;
  //                     }
  //                   }

  //                   for (let month = period.months.from; month < 13; month++) {
  //                     for (let hour = startColumn; hour < endColumn; hour++) {
  //                       matrix[month - 1][hour] = period.index;
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           });
  //         }
  //       });
  //       periods.demand.forEach(period => {
  //         if (period && period?.daysAndHours && period?.months?.to && period?.months?.from) {
  //           period.daysAndHours.forEach(subPeriod => {
  //             let startColumn = subPeriod.fromHour + 48;
  //             let endColumn = subPeriod.toHour === 0 ? 72 : subPeriod.toHour + 48;

  //             let weekendToProcess;

  //             // period is for weekdays and weekends
  //             if (subPeriod.fromDayOfWeek === 0 && subPeriod.toDayOfWeek === 6) {
  //               const duplicate = { ...subPeriod, fromDayOfWeek: 5 };
  //               // duplicate the period and restrict it to the weekend
  //               weekendToProcess = duplicate;
  //               // restrict the current period to be just weekedays
  //               subPeriod.toDayOfWeek = 4;
  //             }

  //             if (subPeriod.fromDayOfWeek === 5 && subPeriod.toDayOfWeek === 6) {
  //               startColumn += 24;
  //               endColumn += 24;
  //             }

  //             if (period?.months?.to && period.months.to >= period.months.from) {
  //               for (let month = period.months.from; month < period.months.to + 1; month++) {
  //                 for (let hour = startColumn; hour < endColumn; hour++) {
  //                   matrix[month - 1][hour] = period.index;
  //                 }
  //               }
  //             } else {
  //               // case where the season is winter and start at the end of the year and stops at the beginning of the year
  //               // Split it in two parts
  //               if (period?.months?.from && period?.months?.to) {
  //                 for (let month = 1; month < period.months.to + 1; month++) {
  //                   for (let hour = startColumn; hour < endColumn; hour++) {
  //                     matrix[month - 1][hour] = period.index;
  //                   }
  //                 }

  //                 for (let month = period.months.from; month < 13; month++) {
  //                   for (let hour = startColumn; hour < endColumn; hour++) {
  //                     matrix[month - 1][hour] = period.index;
  //                   }
  //                 }
  //               }
  //             }

  //             // now parse the weekend part of this period if it exists
  //             if (weekendToProcess) {
  //               let startColumn = subPeriod.fromHour + 72;
  //               let endColumn = subPeriod.toHour === 0 ? 96 : subPeriod.toHour + 72;
  //               if (period?.months?.to && period.months.to >= period.months.from) {
  //                 for (let month = period.months.from; month < period.months.to + 1; month++) {
  //                   for (let hour = startColumn; hour < endColumn; hour++) {
  //                     matrix[month - 1][hour] = period.index;
  //                   }
  //                 }
  //               } else {
  //                 // case where the season is winter and start at the end of the year and stops at the beginning of the year
  //                 // Split it in two parts
  //                 if (period?.months?.from && period?.months?.to) {
  //                   for (let month = 1; month < period.months.to + 1; month++) {
  //                     for (let hour = startColumn; hour < endColumn; hour++) {
  //                       matrix[month - 1][hour] = period.index;
  //                     }
  //                   }

  //                   for (let month = period.months.from; month < 13; month++) {
  //                     for (let hour = startColumn; hour < endColumn; hour++) {
  //                       matrix[month - 1][hour] = period.index;
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           });
  //         }
  //       });
  //       const energyWeekdaySchedule = matrix.map(month => month.slice(0, 24));
  //       const energyWeekendSchedule = matrix.map(month => month.slice(24, 48));
  //       const demandWeekdaySchedule = matrix.map(month => month.slice(48, 72));
  //       const demandWeekendSchedule = matrix.map(month => month.slice(72, 96));
  //       dispatchMatrix({ type: 'ENERGY_WEEKDAY', matrix: energyWeekdaySchedule });
  //       dispatchMatrix({ type: 'ENERGY_WEEKEND', matrix: energyWeekendSchedule });
  //       dispatchMatrix({ type: 'DEMAND_WEEKDAY', matrix: demandWeekdaySchedule });
  //       dispatchMatrix({ type: 'DEMAND_WEEKEND', matrix: demandWeekendSchedule });
  //     }
  //   };
  //   if (baseTariff != undefined || (baseTariff && Object.keys(baseTariff).length > 0)) {
  //     generateMatrixFromBaseTariffPeriods(periods);
  //   }
  // }, [baseTariff]);

  const buildTariffFromMatrix = (combinedMatrix: number[][]): ESAPTariff => {
    let tariff, builder;
    if (baseTariff && Object.keys(baseTariff).length > 0 && !isEVTariff) {
      builder = new TariffBuilder(combinedMatrix, periods, tariffMeta, undefined);
      tariff = builder.updateGeneralTariff(baseTariff);
    } else if (isEVTariff) {
      builder = new TariffBuilder(combinedMatrix, periods, tariffMeta, overage);
      tariff = builder.generateEVTariff();
    } else {
      builder = new TariffBuilder(combinedMatrix, periods, tariffMeta, undefined);
      tariff = builder.generateGeneralTariff();
    }
    return tariff;
  };

  const handleNext = () => {
    const combinedMatrix: number[][] = [];
    for (let i = 0; i < matrix.energyWeekdaySchedule.length; i++) {
      combinedMatrix.push([
        ...matrix.energyWeekdaySchedule[i],
        ...matrix.energyWeekendSchedule[i],
        ...matrix.demandWeekdaySchedule[i],
        ...matrix.demandWeekendSchedule[i]
      ]);
    }
    const tariff = buildTariffFromMatrix(combinedMatrix);
    setNewTariff(tariff);
    setIsUpdateSeasons(true);
  };

  const handleSaveTariff = (
    seasons: {
      id: number;
      name: string;
      NCDemandCharges: string;
    }[]
  ) => {
    const tariff: ESAPTariff = fromJS(newTariff).toJS();

    Object.keys(tariff.overview.seasons).forEach((seasonName, index) => {
      const updatedSeason = seasons.find(season => season.id === index);
      if (updatedSeason) {
        tariff.overview.seasons[seasonName].NON_COINCIDENTAL = [
          {
            name: 'Non-Coincidental Demand Charges',
            amount: updatedSeason.NCDemandCharges ? parseFloat(updatedSeason.NCDemandCharges) : 0,
            credit: false,
            type: 'DEMAND_BASED'
          } as TOUPeriod
        ];
      }
      if (updatedSeason && updatedSeason.name.length > 0) {
        const tempSeason = tariff.overview.seasons[seasonName];
        tempSeason.overview.name = updatedSeason.name.replace(' ', '');
        delete tariff.overview.seasons[seasonName];
        tariff.overview.seasons[updatedSeason.name.replace(' ', '')] = tempSeason;
      }
    });
    if (baseTariff && baseTariff.applicability_values && baseTariff.overview) {
      tariff.properties = baseTariff.properties;
      tariff.applicability_values = baseTariff.applicability_values;
      tariff.overview.consumptionBaseAdjustment = 0;
      tariff.overview.demandBaseAdjustment = 0;
      tariff.overview.demandBaseCharges = baseTariff.overview.demandBaseCharges;
      tariff.overview.consumptionBaseCharges = baseTariff.overview.consumptionBaseCharges;
      tariff.overview.demandBaseCharges = [];
      tariff.source_name = 'ESAP';
    }

    if (baseTariff && baseTariff.all_rates) {
      tariff.all_rates = baseTariff.all_rates;
    }

    if (baseTariff && factoryState === 'EDIT') {
      tariff.public_id = baseTariff?.public_id;
    }

    if (factoryState === 'CREATE') {
      dispatch(createManualTariff(tariff));
    } else {
      dispatch(updateManualTariff(tariff));
    }
    if (history) {
      history.push('/tariffs?ignoreReload=true&manualTariffs=true');
    }
  };

  const handleToggleEV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEVTariff(event.target.checked);
  };

  const confirmReset = (confirm: boolean) => () => {
    if (confirm) {
      resetCustomTariff();
    }
    setIsConfirmOpen(false);
  };

  return (
    <>
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {isUpdateSeasons
              ? 'Update Seasons'
              : factoryState === 'EDIT' && baseTariff
              ? `Edit Tariff ${baseTariff.code || baseTariff.name}`
              : 'Create New Tariff'}
          </Typography>
          <FormControlLabel
            control={<Switch checked={isEVTariff} onChange={handleToggleEV} style={{ color: 'white' }} />}
            label="EV Tariff"
          />
          <Tooltip title="Clear All">
            <IconButton onClick={() => setIsConfirmOpen(true)} aria-label="Clear All">
              <RotateLeftIcon style={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Toolbar>
        {!isUpdateSeasons && (
          <GenerateSeasons
            isEV={isEVTariff}
            state={tariffMeta}
            handleChange={handleChange}
            handleNext={handleNext}
            handleLSEChange={handleLSEChange}
          />
        )}
        {isUpdateSeasons && (
          <UpdateSeasons
            isEV={isEVTariff}
            setIsUpdateSeasons={setIsUpdateSeasons}
            newTariff={newTariff}
            handleSaveTariff={handleSaveTariff}
            baseTariff={baseTariff}
          ></UpdateSeasons>
        )}
      </Paper>
      <ConfirmPopUp
        open={isConfirmOpen}
        message="Are you sure that you want to reset this custom tariff"
        onConfirmClose={confirmReset}
      />
    </>
  );
};

export default TariffFactory;
