import { IProps } from '../ScenarioMonthlyResultsTable';
import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateEnergyExportedOptions = (props: IProps): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  if (props.statusQuo && props.statusQuo.assumptions.isNetMetering) {
    chartOptions.push({
      key: 'Before Exported Energy',
      label: 'Before Exported Energy',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
    chartOptions.push({
      key: 'Before Exported Energy On Peak',
      label: 'Before Exported Energy On Peak',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
    chartOptions.push({
      key: 'Before Exported Energy MidPeak',
      label: 'Before Exported Energy MidPeak',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
    chartOptions.push({
      key: 'Before Exported Energy Off Peak',
      label: 'Before Exported Energy Off Peak',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
  }

  if (props.scenario && props.scenario.assumptions.isNetMetering) {
    chartOptions.push({
      key: 'After Exported Energy',
      label: 'After Exported Energy',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
    chartOptions.push({
      key: 'After Exported Energy On Peak',
      label: 'After Exported Energy On Peak',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
    chartOptions.push({
      key: 'After Exported Energy MidPeak',
      label: 'After Exported Energy MidPeak',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
    chartOptions.push({
      key: 'After Exported Energy Off Peak',
      label: 'After Exported Energy Off Peak',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
  }

  return chartOptions;
};
