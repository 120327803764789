import { SAVE_SCENARIOS } from '../../actions/scenarios';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_SCENARIOS:
      return action.payload;
    default:
      return state;
  }
}
