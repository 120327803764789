import React, { useEffect, useState } from 'react';
import { PeakLoadFrequencyChange } from '../../../../types';
import Plotly from '../../../../custom-plotly.js';
import { Layout, PlotData } from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

// MATERIAL UI IMPORTS
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CurrencyDisplay from '../../../../utility/CurrencyDisplay';
import Help from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { FREQ_TOOLTIP } from './LSAToolipsDef';

const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5)
    },
    header: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      background: '#e2ecf8'
    },
    formControl: {
      margin: theme.spacing(3)
    },
    peakSelection: {
      width: 160,
      marginTop: 18,
      marginLeft: theme.spacing(3)
    },
    button: {
      margin: 30
    },
    questionMark: {
      fontSize: 16,
      color: '#929eaa',
      paddingTop: 4
    }
  })
);

const layout: Partial<Layout> = {
  legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
  margin: {
    t: 20,
    l: 60,
    r: 20,
    b: 120
  },
  xaxis: {
    tickangle: -45
  },
  yaxis: {
    title: 'Saving Changes (%)'
  }
};

interface LSAMagnitudeChangesChartsProps {
  data: PeakLoadFrequencyChange;
  scenarioSavings: { energy: number; demand: number; total: number };
}

interface Data {
  scenario: string;
  energy_savings: number;
  energy_savings_change: number;
  energy_savings_percent: number;
  demand_savings: number;
  demand_savings_change: number;
  demand_savings_percent: number;
  total_savings: number;
  total_savings_change: number;
  total_savings_percent: number;
  color: string;
}

const columnSchema = [
  {
    key: 'scenario',
    label: 'Scenario',
    align: 'left',
    isCurrency: false
  },
  { key: 'energy_savings', label: 'Energy Savings ', align: 'right', isCurrency: true },
  { key: 'energy_savings_change', label: 'Energy Savings Change ', align: 'right', isCurrency: true },
  {
    key: 'energy_savings_percent',
    label: 'Energy Savings Change (%)',
    align: 'right',
    isCurrency: false
  },
  { key: 'demand_savings', label: 'Demand Savings ', align: 'right', isCurrency: true },
  { key: 'demand_savings_change', label: 'Demand Savings Change ', align: 'right', isCurrency: true },
  { key: 'demand_savings_percent', label: 'Demand Savings Change (%)', align: 'right', isCurrency: false },
  {
    key: 'total_savings',
    label: 'Total Savings ',
    align: 'right',
    isCurrency: true
  },
  {
    key: 'total_savings_change',
    label: 'Total Savings Change ',
    align: 'right',
    isCurrency: true
  },
  {
    key: 'total_savings_percent',
    label: 'Total Savings Change (%)',
    align: 'right',
    isCurrency: false
  }
] as const;

const LSAFrequencyChangesCharts: React.FC<LSAMagnitudeChangesChartsProps> = ({ data, scenarioSavings }) => {
  const classes = useStyles();
  const [selectedData, setSelectedData] = useState<Data[] | undefined>(undefined);
  const [chartData, setChartData] = useState<PlotData[]>([]);

  const getCaseLabel = (key: string): string => {
    switch (key) {
      case 'top_2_peaks':
        return 'top 2 peaks';
      case 'top_5_peaks':
        return 'top 5 peaks';
      case 'top_10_peaks':
        return 'top 10 peaks';
      default:
        return key;
    }
  };
  useEffect(() => {
    const buildData = (): Data[] => {
      const updatedData = [
        {
          scenario: 'base scenario',
          energy_savings: scenarioSavings.energy,
          energy_savings_change: 0,
          energy_savings_percent: 0,
          demand_savings: scenarioSavings.demand,
          demand_savings_change: 0,
          demand_savings_percent: 0,
          total_savings: scenarioSavings.total,
          total_savings_change: 0,
          total_savings_percent: 0,
          color: 'rgba(245,247,212)'
        }
      ];
      Object.keys(data).forEach((peaks, i) => {
        const dataItem = {
          scenario: getCaseLabel(peaks),
          energy_savings: scenarioSavings.energy + data[peaks].energy_savings,
          energy_savings_change: data[peaks].energy_savings,
          energy_savings_percent: +((data[peaks].energy_savings / scenarioSavings.energy) * 100).toFixed(2),
          demand_savings: scenarioSavings.demand + data[peaks].demand_savings,
          demand_savings_change: data[peaks].demand_savings,
          demand_savings_percent: +((data[peaks].demand_savings / scenarioSavings.demand) * 100).toFixed(2),
          total_savings: scenarioSavings.total + data[peaks].total_savings,
          total_savings_change: data[peaks].total_savings,
          total_savings_percent: +data[peaks].total_savings_percent.toFixed(2),
          color: i === 1 ? '#f5f5f5' : '#fff'
        };
        updatedData.push(dataItem);
      });
      return updatedData;
    };
    setSelectedData(buildData());
  }, [scenarioSavings]);

  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      const buildChartData = (): PlotData[] => {
        const chartData: PlotData[] = [];

        chartData.push({
          type: 'scatter',
          name: 'energy savings change (%)',
          x: selectedData.map(el => el.scenario),
          y: selectedData.map(el => el.energy_savings_percent)
        } as PlotData);

        chartData.push({
          type: 'scatter',
          name: 'demand savings change (%)',
          x: selectedData.map(el => el.scenario),
          y: selectedData.map(el => el.demand_savings_percent)
        } as PlotData);

        chartData.push({
          type: 'scatter',
          name: 'total savings change (%)',
          x: selectedData.map(el => el.scenario),
          y: selectedData.map(el => el.total_savings_percent)
        } as PlotData);

        return chartData;
      };
      setChartData(buildChartData());
    }
  }, [selectedData]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6">
          Peak Frequency Changes{' '}
          <span>
            <Tooltip title={FREQ_TOOLTIP}>
              <Help className={classes.questionMark} />
            </Tooltip>
          </span>
        </Typography>
      </div>
      <div>
        <Table size="small">
          <TableHead>
            {columnSchema.map(column => (
              <TableCell key={column.key} align={column.align}>
                {column.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {selectedData &&
              selectedData?.map(row => (
                <TableRow key={row.scenario} style={{ background: row.color }}>
                  {columnSchema.map(column => (
                    <TableCell key={column.key} align={column.align}>
                      {column.isCurrency ? <CurrencyDisplay value={+row[column.key]} /> : row[column.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div style={{ height: 440 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={chartData}
          layout={layout}
          config={{ displayModeBar: false, responsive: true }}
        />
      </div>
    </div>
  );
};

export default LSAFrequencyChangesCharts;
