import axios from 'axios';

export const GET_ALL_CUSTOMERS_REQUEST = 'GET_ALL_CUSTOMERS_REQUEST';
export const GET_ALL_CUSTOMERS_SUCCESS = 'GET_ALL_CUSTOMERS_SUCCESS';
export const GET_ALL_CUSTOMERS_ERROR = 'GET_ALL_CUSTOMERS_ERROR';

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const RESET_CUSTOMER_LIST = 'RESET_CUSTOMER_LIST';
export const SELECT_CUSTOMERS = 'SELECT_CUSTOMER';

export const CUSTOMER_INPUT_FORM_CHANGE = 'CUSTOMER_INPUT_FORM_CHANGE';

const ROOT_URL = window.REACT_APP_API_BASE_URL_V2;

export function resetCustomer() {
  return dispatch => {
    dispatch({ type: RESET_CUSTOMER });
  };
}

export function resetCustomerList() {
  return dispatch => {
    dispatch({ type: RESET_CUSTOMER_LIST });
  };
}

export function getAllCustomers() {
  return dispatch => {
    dispatch({ type: GET_ALL_CUSTOMERS_REQUEST });
    axios
      .get(`${ROOT_URL}/customer/`)
      .then(res => {
        const customers = res.data;
        dispatch({ type: GET_ALL_CUSTOMERS_SUCCESS, payload: customers });
      })
      .catch(function (error) {
        dispatch({
          type: GET_ALL_CUSTOMERS_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function getCustomer(id) {
  return dispatch => {
    dispatch({ type: GET_CUSTOMER_REQUEST });
    axios
      .get(`${ROOT_URL}/customer/${id}`)
      .then(res => {
        const customer = res.data;
        dispatch({ type: GET_CUSTOMER_SUCCESS, payload: customer });
      })
      .catch(function (error) {
        dispatch({ type: GET_CUSTOMER_ERROR, payload: error, error: true });
      });
  };
}

export function createCustomer(customer) {
  return dispatch => {
    dispatch({ type: CREATE_CUSTOMER_REQUEST });
    axios
      .post(`${ROOT_URL}/customer/`, customer)
      .then(res => {
        const customer = res.data;
        dispatch({ type: CREATE_CUSTOMER_SUCCESS, payload: customer });
      })
      .catch(function (error) {
        dispatch({ type: CREATE_CUSTOMER_ERROR, payload: error, error: true });
      });
  };
}

export function updateCustomer(customer) {
  return dispatch => {
    dispatch({ type: UPDATE_CUSTOMER_REQUEST });
    axios
      .put(`${ROOT_URL}/customer/${customer.public_id}`, customer)
      .then(res => {
        const customer = res.data;
        dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: customer });
      })
      .catch(function (error) {
        dispatch({ type: UPDATE_CUSTOMER_ERROR, payload: error, error: true });
      });
  };
}

export function deleteCustomer(id) {
  return dispatch => {
    dispatch({ type: DELETE_CUSTOMER_REQUEST });
    axios
      .delete(`${ROOT_URL}/customer/${id}`)
      .then(res => {
        dispatch({ type: DELETE_CUSTOMER_SUCCESS });
      })
      .catch(function (error) {
        dispatch({ type: DELETE_CUSTOMER_ERROR, payload: error, error: true });
      });
  };
}

export function selectCustomers(selectedCustomers) {
  return dispatch => {
    dispatch({
      type: SELECT_CUSTOMERS,
      payload: selectedCustomers
    });
  };
}

export function handleInputFormChangeCustomer(updateData) {
  return dispatch => {
    dispatch({
      type: CUSTOMER_INPUT_FORM_CHANGE,
      payload: updateData
    });
  };
}
