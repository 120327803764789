import React from 'react';

// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

// React Imports
import { AssumptionsInternal } from '../../../types';

interface Props {
  open: boolean;
  handleCloseConfig: any;
  assumptions: AssumptionsInternal;
  scenarioName: string;
  statusQuoName: string;
  discountRate: { demand: number; energy: number };
}

const FinancialPerfSettings: React.FC<Props> = ({
  open,
  handleCloseConfig,
  assumptions,
  scenarioName,
  statusQuoName,
  discountRate
}) => {
  return (
    <Dialog open={open} onClose={handleCloseConfig} aria-labelledby="finperf-dialog-title" maxWidth="sm">
      <DialogTitle id="finperf-dialog-title">Assumptions</DialogTitle>
      <DialogContent>
        <TextField
          // className={classes.block}
          label="Selected Scenario"
          name="selectedScenario"
          value={scenarioName}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          // className={classes.block}

          label="Compared with"
          name="comparedWith"
          value={statusQuoName}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          // className={classes.block}

          label="Project Term (Years)"
          name="numberOfYears"
          value={assumptions.numberOfYears}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="number"
          // className={classes.block}
          label="Energy Escalation (%)"
          name="energyEscalation"
          value={assumptions.energyEscalation}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="number"
          // className={classes.block}
          label="Demand Escalation (%)"
          name="demandEscalation"
          value={assumptions.demandEscalation}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="number"
          // className={classes.block}
          label="Battery Capacity Degradation (%)"
          name="battCapacityDegradation"
          value={assumptions.batteryEnergyCapacityDegradation}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="number"
          // className={classes.block}
          label="Battery Power Degradation (%)"
          name="battPowerDegradation"
          value={assumptions.batteryPowerCapacityDegradation}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="number"
          // className={classes.block}
          label="Load Escalation"
          name="loadEscalation (%)"
          value={assumptions.consumptionEscalation}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          type="number"
          // className={classes.block}
          label="Demand Saving Haircut (%)"
          name="demandSavingHaircut"
          value={discountRate.demand * 100}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          // className={classes.block}
          type="number"
          label="Energy Saving Haircut (%)"
          name="energySavingHaircut"
          value={discountRate.energy * 100}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          fullWidth
          margin="normal"
        />
        {/* <FormControlLabel
          style={{ marginLeft: 4, marginTop: 16, marginBottom: 16 }}
          label="Perfect / Real-time:"
          labelPlacement="start"
          control={
            <Select
              // style={{ marginLeft: 8 }}
              name="knowledge"
              value={editChartConfig.knowledge}
              onChange={e => setEditChartConfig({ ...editChartConfig, knowledge: e.target.value })}
            >
              <MenuItem key={1} value={'perfect'}>
                Perfect
              </MenuItem>
              <MenuItem key={2} value={'real-time'}>
                Real-time
              </MenuItem>
              })}
            </Select>
          }
        /> */}
      </DialogContent>
    </Dialog>
  );
};

export default FinancialPerfSettings;
