import React from 'react';
import PropTypes from 'prop-types';
// import Plot from 'react-plotly.js';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../custom-plotly.js';
const Plot = createPlotlyComponent(Plotly);

export default class SingleProfile extends React.Component {
  layout = {
    height: 250,
    margin: {
      l: 50,
      r: 30,
      t: 10,
      b: 50
    }
  };

  config = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian'
    ],
    displaylogo: false
  };

  processData = data => {
    return [
      {
        x: data.x,
        y: data.y,
        type: 'scatter'
      }
    ];
  };

  render() {
    const { title } = this.props;
    const profileData = this.processData(this.props.data);
    return (
      <Paper>
        <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
          <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
            {title}
          </Typography>
          <div>{this.props.children}</div>
        </Toolbar>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={profileData}
          layout={this.layout}
          config={this.config}
        />
      </Paper>
    );
  }
}

SingleProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};
