import { mean } from 'ramda';

export const getSorting = <T extends object>(order: 'asc' | 'desc', orderBy: string) => {
  return order === 'desc'
    ? (a: T, b: T) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a: T, b: T) => (a[orderBy] < b[orderBy] ? -1 : 1);
};

export const parseQuery = (queryString: string) => {
  var query: { [param: string]: string | number | boolean } = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

export const exportCSVFile = (headers: string[] = [], items: any[][], fileTitle?: string) => {
  if (headers && headers.length > 0) {
    items = [headers, ...items];
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilename = fileTitle ? fileTitle + '.csv' : 'data.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if ((navigator as any)?.msSaveBlob) {
    // IE 10+
    (navigator as any)?.msSaveBlob(blob, exportedFilename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

function convertToCSV(objArray: string): string {
  var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line !== '') line += ',';

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}

export const getRange = (start: number, end: number, inclusive = false) => {
  let range = [...Array(end - start).keys()].map(i => i + start);
  if (inclusive) {
    range.push(end);
  }
  return range.join();
};

export const getTimeStamps = (interval: 1 | 4) => {
  var times: string[] = [];
  var multiplier = 60 / interval;
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < interval; j++) {
      const hour = i < 10 ? '0' + i : i;
      const minutes = multiplier * j;
      const minutesDisplay = minutes < 10 ? '0' + minutes : minutes;
      times.push(hour + ':' + (j === 0 ? '00' : minutesDisplay));
    }
  }
  return times;
};

export const interpolate = (numPoints: number, start: number, end: number) => {
  const values: number[] = [];
  if (numPoints <= 1) {
    return values;
  }
  const difference = (end - start) / (numPoints - 1);
  for (let i = 0; i < numPoints; i++) {
    values.push(start + i * difference);
  }
  return values;
};

export const aggregateData = (step: number) => (arr: [string, string, number][]) => {
  const res: [string, string, number][] = [];
  for (let i = 0; i < arr.length; i += step) {
    const temp: number[] = [];
    let tempDate = arr[i][0];
    let tempHour = arr[i][1];
    for (let j = 0; j < step; j++) {
      temp.push(arr[i + j][2]);
    }
    res.push([tempDate, tempHour, mean(temp)]);
  }
  return res;
};
export const hours = [
  {
    value: 0,
    label: '12:00 AM'
  },
  {
    value: 1,
    label: '1:00 AM'
  },
  {
    value: 2,
    label: '2:00 AM'
  },
  {
    value: 3,
    label: '3:00 AM'
  },
  {
    value: 4,
    label: '4:00 AM'
  },
  {
    value: 5,
    label: '5:00 AM'
  },
  {
    value: 6,
    label: '6:00 AM'
  },
  {
    value: 7,
    label: '7:00 AM'
  },
  {
    value: 8,
    label: '8:00 AM'
  },
  {
    value: 9,
    label: '9:00 AM'
  },
  {
    value: 10,
    label: '10:00 AM'
  },
  {
    value: 11,
    label: '11:00 AM'
  },
  {
    value: 12,
    label: '12:00 PM'
  },
  {
    value: 13,
    label: '1:00 PM'
  },
  {
    value: 14,
    label: '2:00 PM'
  },
  {
    value: 15,
    label: '3:00 PM'
  },
  {
    value: 16,
    label: '4:00 PM'
  },
  {
    value: 17,
    label: '5:00 PM'
  },
  {
    value: 18,
    label: '6:00 PM'
  },
  {
    value: 19,
    label: '7:00 PM'
  },
  {
    value: 20,
    label: '8:00 PM'
  },
  {
    value: 21,
    label: '9:00 PM'
  },
  {
    value: 22,
    label: '10:00 PM'
  },
  {
    value: 23,
    label: '11:00 PM'
  }
];

export const profileFrequencyChoices = [
  // { key: 12, label: '5 Minutes', frequencyPerHour: 12 },
  { key: 4, label: '15 Minutes', frequencyPerHour: 4 },
  { key: 1, label: '1 Hour', frequencyPerHour: 1 }
];

export const plotlyBaseConfig = {
  modeBarButtonsToRemove: [
    'sendDataToCloud',
    'zoom2d',
    'pan2d',
    'select2d',
    'lasso2d',
    'zoomIn2d',
    'zoomOut2d',
    'autoScale2d',
    'toggleSpikelines',
    'hoverClosestCartesian',
    'hoverCompareCartesian'
  ],
  displaylogo: false
};
