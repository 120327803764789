import moment from 'moment';

export function formatDate(inputDate: number | string): string {
  return moment(inputDate).format('l');
}

export function sortByDates<T extends { created_at: string }>(data: T[]): T[] {
  return data.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf());
}

// date should be utc
export function humanizeDatesDiffFromNow(date: string) {
  const minutes = -moment.utc().diff(moment.utc(date), 'minutes');
  return moment.duration(minutes, 'minutes').humanize(true);
}
