import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

//Material UI
import { withStyles, Theme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Settings from '@material-ui/icons/Settings';

//ESAP imports
import { Technologies, OperationsData, ScenarioResults, TariffOverview } from '../../../types';
import { getTOUBands } from '../../../utility/TOU';
import PresentationChartSettings from './PresentationChartSettings';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
import { Config, Layout, PlotData, Shape, LayoutAxis } from 'plotly.js';
import { ColorResult } from 'react-color';
import { StoreState } from '../../../reducers';
const Plot = createPlotlyComponent(Plotly);

const styles = (theme: Theme) => ({
  modal: {
    position: 'absolute',
    top: '45%',
    left: '43%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2)
    //outline: "none"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

interface PresentChartOption {
  fill?: string | null;
  name: string;
  label: string;
  color: string;
  fillcolor?: string;
  include: boolean;
  opacity: number;
  stacked: boolean;
}

interface State {
  data: PlotData[];
  showPeakEventCharts: boolean;
  open: boolean;
  chartOptions: PresentChartOption[];
  editChartOptions: PresentChartOption[];
  TOUOpacity: number;
  hoverInfo: boolean;
  customRangeMin: number | null;
  customRangeMax: number | null;
  flipChargeDischarge: boolean;
  hideHoverInfo: boolean;
  showTOU: boolean;
}

interface Props {
  classes: any;
  chartTitle: string;
  defaultChartData: string[];
  scenarioData: Record<string, OperationsData> | undefined;
  scenarioResults: ScenarioResults | undefined | null;
  energyCapacity: number;
  technologies: Technologies;
  frequency: '15min' | '60min';
  startDate: any;
  numberOfDays: number;
  minY: number;
  maxY: number;
  showticklabels: boolean;
  colorblindMode: boolean;
  showTOU: boolean;
  tariffOverview: TariffOverview;
}

class PresentScenarioChart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const chartOptions = this.buildChartOptions();
    const data = this.buildData(chartOptions);

    this.state = {
      data: data,
      showPeakEventCharts: false,
      open: false,
      chartOptions: chartOptions,
      editChartOptions: chartOptions,
      TOUOpacity: 0.4,
      hoverInfo: false,
      customRangeMin: null,
      customRangeMax: null,
      hideHoverInfo: false,
      showTOU: false,
      flipChargeDischarge: this.props.defaultChartData.includes('Genset Power') ? true : false
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.flipChargeDischarge !== this.state.flipChargeDischarge) {
      const data = this.buildData(this.state.chartOptions);
      this.setState({ data });
    }
  };

  handleValueChange = (e: any): void => {
    this.setState({ [e.target.name]: e.target.value } as any);
  };

  handleShowChartItem = (item: PresentChartOption) => () => {
    const updatedEditChartOptions = this.state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    if (updateIndex > -1) {
      updatedEditChartOptions[updateIndex].include = !updatedEditChartOptions[updateIndex].include;
    }
    this.setState({
      editChartOptions: updatedEditChartOptions
    });
  };

  handleStackChartItem = (item: PresentChartOption) => () => {
    const updatedEditChartOptions = this.state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    if (updateIndex > -1) {
      updatedEditChartOptions[updateIndex].stacked = !updatedEditChartOptions[updateIndex].stacked;
    }
    this.setState({
      editChartOptions: updatedEditChartOptions
    });
  };

  handleChartLineType = (item: PresentChartOption) => () => {
    const updatedEditChartOptions = this.state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    updatedEditChartOptions[updateIndex].fill = updatedEditChartOptions[updateIndex].fill ? null : 'tozeroy';
    this.setState({
      editChartOptions: updatedEditChartOptions
    });
  };

  handleChartTextUpdate = (item: PresentChartOption) => (event: any) => {
    const updatedEditChartOptions = this.state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    updatedEditChartOptions[updateIndex][event.target.name] = event.target.value;
    this.setState({
      editChartOptions: updatedEditChartOptions
    });
  };

  handleColorChangeComplete = (selectedColor: ColorResult, selectedIndex: string) => {
    const updatedEditChartOptions = this.state.editChartOptions.slice(0);
    updatedEditChartOptions[selectedIndex]['color'] =
      typeof selectedColor === 'object'
        ? `rgb(${selectedColor.rgb.r},${selectedColor.rgb.g},${selectedColor.rgb.b})`
        : selectedColor;
    updatedEditChartOptions[selectedIndex]['fillcolor'] =
      typeof selectedColor === 'object'
        ? `${selectedColor.rgb.r},${selectedColor.rgb.g},${selectedColor.rgb.b}`
        : updatedEditChartOptions[selectedIndex]['fillcolor'];
    this.setState({
      editChartOptions: updatedEditChartOptions
    });
  };

  handleMoveItem = (upDirection: boolean, item: PresentChartOption) => () => {
    const updatedEditChartOptions = this.state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    if (updateIndex > 0 && upDirection) {
      const tempOptionHolder = updatedEditChartOptions.splice(updateIndex, 1)[0];
      updatedEditChartOptions.splice(updateIndex - 1, 0, tempOptionHolder);
    } else if (updateIndex !== updatedEditChartOptions.length - 1 && !upDirection) {
      const tempOptionHolder = updatedEditChartOptions.splice(updateIndex, 1)[0];
      updatedEditChartOptions.splice(updateIndex + 1, 0, tempOptionHolder);
    }
    this.setState({
      editChartOptions: updatedEditChartOptions
    });
  };

  buildChartOptions = (): PresentChartOption[] => {
    const chartOptions: PresentChartOption[] = [];
    if (this.props?.technologies?.isPV && this.props?.technologies?.isBatt) {
      chartOptions.push({
        fill: 'tozeroy',
        name: 'Demand Net Solar',
        label: 'Demand Net Solar',
        color: '#fe5815',
        fillcolor: '254, 88, 21',
        include: this.props.defaultChartData.includes('Demand Net Solar') ? true : false,
        opacity: 0.5,
        stacked: false
      });
    }

    if (this.props?.technologies?.isBatt) {
      chartOptions.push({
        fill: 'tozeroy',
        name: 'Battery Power',
        label: 'Battery Power',
        color: '#509E2F',
        fillcolor: '80, 158, 47',
        include: this.props.defaultChartData.includes('Battery Power') ? true : false,
        opacity: 0.5,
        stacked: false
      });

      chartOptions.push({
        fill: null,
        name: 'Battery SOC',
        label: 'Battery SOC',
        color: '#f00',
        fillcolor: '255, 0, 0',
        include: false,
        opacity: 1,
        stacked: false
      });
      chartOptions.push({
        fill: 'tozeroy',
        name: 'Net Demand',
        label: 'Net Demand',
        color: '#005bbb',
        fillcolor: '0, 91, 187',
        include: this.props.defaultChartData.includes('Net Demand') ? true : false,
        opacity: 0.5,
        stacked: false
      });
    }
    if (this.props?.technologies?.isPV) {
      chartOptions.push({
        fill: null,
        name: 'Solar Generation',
        label: 'Solar Generation',
        color: this.props.colorblindMode ? 'green' : '#ffa02f',
        fillcolor: '255, 160, 47',
        include: this.props.defaultChartData.includes('Solar Generation') ? true : false,
        opacity: 1,
        stacked: this.props.defaultChartData.includes('Genset Power') ? true : false
      });
    }
    if (this.props?.technologies?.isEV) {
      chartOptions.push({
        fill: null,
        name: 'EV Power',
        label: 'EV Power',
        color: 'purple',
        // fillcolor: '255, 160, 47',
        include: this.props.defaultChartData.includes('EV Power') ? true : false,
        opacity: 1,
        stacked: false
      });
    }
    if (this.props?.technologies?.isGenset) {
      chartOptions.push({
        fill: 'tozeroy',
        name: 'Genset Power',
        label: 'Genset Power',
        color: 'red',
        fillcolor: 'red',
        include: this.props.defaultChartData.includes('Genset Power') ? true : false,
        opacity: 1,
        stacked: this.props.defaultChartData.includes('Genset Power') ? true : false
      });
    }
    if (this.props?.technologies?.isIslanding) {
      chartOptions.push({
        fill: null,
        name: 'Load Curtailment',
        label: 'Load Curtailment',
        color: 'brown',
        // fillcolor: '255, 160, 47',
        include: this.props.defaultChartData.includes('Load Curtailment') ? true : false,
        opacity: 1,
        stacked: false
      });
    }
    chartOptions.push({
      fill: 'tozeroy',
      name: 'Net Export',
      label: 'Net Export',
      color: '#800080',
      fillcolor: '128, 0, 128',
      include: false,
      opacity: 0.7,
      stacked: false
    });
    chartOptions.push({
      fill: this.props.defaultChartData.length === 1 ? 'tozeroy' : null,
      name: 'Facility Demand',
      label: 'Facility Demand',
      color: '#222',
      fillcolor: '34,34,34',
      include: true,
      opacity: 0.3,
      stacked: false
    });

    return chartOptions;
  };

  handleClose = () => {
    this.setState({
      open: false,
      editChartOptions: [...this.state.chartOptions]
    });
  };

  handleSuccessClose = () => {
    const data = this.buildData(this.state.editChartOptions);

    this.setState({
      data: data,
      chartOptions: [...this.state.editChartOptions],
      open: false
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  // key format will be: 'yyyy-mm'
  buildData = (chartOptions: PresentChartOption[]): PlotData[] => {
    const x: (number | string)[] = [];
    const data: PlotData[] = [];
    if (this.props.scenarioData) {
      Object.keys(this.props.scenarioData).map(key => {
        if (this.props.scenarioData && this.props.scenarioData[key]) {
          const month = this.props.scenarioData[key];
          const daysInMonth = moment(key, 'YYYY-MM').daysInMonth();
          const keyParts = key.split('-');
          if (parseInt(keyParts[1]) == 0) {
            keyParts[1] = '12';
          }
          const startDate = new Date(+keyParts[0], +keyParts[1] - 1, 1);
          let currentDate;
          let step = this.props.frequency == '60min' ? 1 : 4;
          let minuteStepSize = 15;
          if (this.props.frequency == '60min') {
            minuteStepSize = 60;
          }

          const stepsForwardFromStart = daysInMonth * 24 * step - month.vecLoad.length;
          currentDate = moment(startDate).add(minuteStepSize * stepsForwardFromStart, 'minutes');

          //Building Timestamps
          for (let i = 0; i < month.vecLoad.length; i++) {
            x.push(currentDate.valueOf());
            currentDate.add(minuteStepSize, 'minutes');
          }
        }
      });

      //Build chart data for each dataset
      const dataSetLength = chartOptions.filter(option => option.include === true).length;
      chartOptions.forEach(option => {
        if (option.include) {
          const baseData: Partial<PlotData> = {
            hoverinfo: this.state && this.state.hideHoverInfo ? 'none' : 'all',
            type: 'scatter',
            mode: 'lines',
            name: option.label,
            visible: option.include,
            x: x,
            y: this.buildFullChartDataStream(option.name),
            line: { color: option.color, width: 1 }
          };
          if (option.fill) {
            baseData.fill = 'tozeroy';
            baseData.fillcolor = 'rgba(' + option.fillcolor + ',' + option.opacity + ')';
          }
          if (option.name === 'Battery SOC') {
            baseData.yaxis = 'y2';
            baseData.line = { ...baseData.line, dash: 'dot' };
          }
          if (option.stacked) {
            baseData.stackgroup = 'stacked';
            baseData.fill = 'tonexty';
          }

          if (dataSetLength !== 1 && option.name === 'Facility Demand') {
            baseData.line = { ...baseData.line, dash: 'dot' };
          }
          data.push(baseData as PlotData);
        }
      });
    }
    return data.reverse();
  };

  buildFullChartDataStream = (option: string): number[] => {
    const data: number[] = [];
    if (this.props.scenarioData && Object.keys(this.props.scenarioData).length > 0) {
      Object.keys(this.props.scenarioData).map(key => {
        if (this.props?.scenarioData && this.props.scenarioData[key]) {
          const month = this.props.scenarioData[key] || undefined;
          data.push(...this.buildChartDataStream(option, month));
        }
      });
    }
    return data;
  };

  buildChartDataStream = (key: string, scenarioMonthlyData: OperationsData): number[] => {
    switch (key) {
      case 'Facility Demand':
        return scenarioMonthlyData.vecLoad;
      case 'Solar Generation':
        return scenarioMonthlyData.vecPInv;
      // case 'Forecasted':
      //   return scenarioMonthlyData.forecasted;
      case 'Genset Power':
        return scenarioMonthlyData.vecPGen;
      case 'Load Curtailment':
        return scenarioMonthlyData.vecPCurtail;
      case 'EV Power':
        if (scenarioMonthlyData.vecPev) {
          return scenarioMonthlyData.vecPev.map((value, index) => {
            return -1 * value;
          });
        } else {
          return [];
        }
      case 'Demand Net Solar':
        return scenarioMonthlyData.vecLoad.map((value, index) => {
          return +value - +scenarioMonthlyData.vecPInv[index];
        });
      case 'Battery Power':
        return scenarioMonthlyData.vecPBatt.map((value, index) => {
          return this.state && this.state.flipChargeDischarge ? value : -1 * value;
        });
      case 'Net Demand':
        return scenarioMonthlyData.vecPNetIn.map((value, index) => {
          if (scenarioMonthlyData.vecPNetOut) {
            return +value - +scenarioMonthlyData.vecPNetOut[index];
          } else {
            return +value;
          }
        });
      case 'Net Export':
        if (scenarioMonthlyData.vecPNetOut) {
          return scenarioMonthlyData.vecPNetOut.map(value => -value);
        } else {
          return [];
        }
      case 'Battery SOC':
        return scenarioMonthlyData.vecEnerBatt.map(battValue => battValue / this.props.energyCapacity);
      default:
        return [] as number[];
    }
  };

  updateData = (data: PlotData[]): PlotData[] => {
    const initialColors = {
      solarGeneration: '#ffa02f',
      demandNetSolar: '#fe5815',
      demandNetSolarFill: '254, 88, 21',
      batteryPower: '#509E2F',
      batteryPowerFill: '80, 158, 47'
    };

    const colorBlindColors = {
      solarGeneration: 'green',
      demandNetSolar: 'black',
      demandNetSolarFill: '255,255,255',
      batteryPower: 'blue',
      batteryPowerFill: '0, 26, 112'
    };
    return (data = data.map(set => {
      if (set.name === 'Solar Generation') {
        //if not chart options color change - do colorblind/default option
        if (set.line.color === initialColors.solarGeneration || set.line.color === colorBlindColors.solarGeneration) {
          return {
            ...set,
            line: {
              ...set.line,
              color: this.props.colorblindMode ? colorBlindColors.solarGeneration : initialColors.solarGeneration
            }
          };
        }
      }
      if (set.name === 'Demand Net Solar') {
        if (set.line.color === initialColors.demandNetSolar || set.line.color === colorBlindColors.demandNetSolar) {
          const color = this.props.colorblindMode ? 'black' : '#fe5815';
          const fillcolor = this.props.colorblindMode ? '255,255,255' : '254, 88, 21';
          return { ...set, line: { color: color, width: 1 }, fillcolor: fillcolor };
        }
      }
      if (set.name === 'Battery Power') {
        if (set.line.color === initialColors.batteryPower || set.line.color === colorBlindColors.batteryPower) {
          const color = this.props.colorblindMode ? 'blue' : '#509E2F';
          const fillcolor = this.props.colorblindMode ? '0, 26, 112' : '80, 158, 47';
          return { ...set, line: { color: color, width: 1 }, fillcolor: fillcolor };
        }
      }
      return set;
    }));
  };

  buildLayout = (): Partial<Layout> => {
    const rangeStart = this.props.startDate
      ? moment(this.props.startDate).valueOf()
      : (this.state.data[0].x[0] as number);
    const rangeEnd = rangeStart + this.props.numberOfDays * 24 * 60 * 60 * 1000;
    const onPeakShapes: Partial<Shape>[] = [];
    const partialPeakShapes: Partial<Shape>[] = [];

    if (this.props.showTOU && this.props.tariffOverview && this.props.tariffOverview.seasons) {
      const onPeakBands = getTOUBands(this.props.tariffOverview.seasons, rangeStart, rangeEnd, 'ON_PEAK');
      onPeakBands.forEach(bands => {
        bands.forEach(band => {
          onPeakShapes.push({
            type: 'rect',
            xref: 'x',
            yref: 'paper',
            x0: band.start,
            y0: 0,
            x1: band.end,
            y1: 1,
            fillcolor: '#bbb',
            opacity: this.state.TOUOpacity,
            layer: 'below',
            line: {
              width: 0
            }
          });
        });
      });

      const partialPeakBands = getTOUBands(this.props.tariffOverview.seasons, rangeStart, rangeEnd, 'PARTIAL_PEAK');
      partialPeakBands.forEach(bands => {
        bands.forEach(band => {
          partialPeakShapes.push({
            type: 'rect',
            xref: 'x',
            yref: 'paper',
            x0: band.start,
            y0: 0,
            x1: band.end,
            y1: 1,
            fillcolor: '#ddd',
            opacity: this.state.TOUOpacity,
            layer: 'below',
            line: {
              width: 0
            }
          });
        });
      });
    }

    const layout: Partial<Layout> = {
      // uirevision: 'same',
      autosize: true,
      hovermode: 'closest',
      // displaylogo: false,
      showlegend: true,
      legend: { x: 0.5, xanchor: 'center', y: -0.1, orientation: 'h' },
      margin: {
        t: 20,
        b: 20
      },
      shapes: [...onPeakShapes, ...partialPeakShapes],
      xaxis: {
        fixedrange: true,
        range: [rangeStart, rangeEnd],
        showticklabels: this.props.showticklabels,
        type: 'date',
        hoverformat: '%a %b %e, %Y %H:%M',
        tickfont: {
          size: 10
        }
      },
      yaxis: {
        fixedrange: true,
        showticklabels: this.props.showticklabels,
        type: 'linear',
        title: {
          text: 'Power (kW)',
          font: {
            family: 'Open Sans, Arial',
            size: 12,
            color: '#666'
          }
        },
        tickfont: {
          size: 10
        }
      },
      yaxis2: {
        overlaying: 'y',
        side: 'right',
        range: [0, 1]
      }
    };

    if (this.props.minY && this.props.maxY) {
      (layout.yaxis as LayoutAxis).range = [+this.props.minY, +this.props.maxY];
    }

    return layout;
  };

  config: Partial<Config> = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian'
    ],
    displaylogo: false,
    toImageButtonOptions: { format: 'svg', filename: 'esap-chart', width: 890, height: 400 }
  };

  handleToggle = (e: any) => {
    const name = e.target.name;
    this.setState(
      state =>
        ({
          [name]: !state[name]
        } as any)
    );
  };

  isBatteryVisible = () => {
    return this.state.chartOptions.some(option => option.name == 'Battery Power' && option.include === true);
  };

  render() {
    return (
      <Paper style={{ margin: '20px' }}>
        <Box style={{ background: '#f5f7d4', width: '100%', height: 36 }}>
          <Box style={{ width: '890px', margin: '0 auto' }}>
            <span style={{ float: 'left', marginLeft: 70, marginTop: 8 }}>{this.props.chartTitle}</span>
            <span style={{ float: 'right', marginRight: 70, marginTop: 0 }}>
              {this.isBatteryVisible() && (
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={this.state.flipChargeDischarge}
                      onChange={() => this.setState({ flipChargeDischarge: !this.state.flipChargeDischarge })}
                    />
                  }
                  label={<span style={{ fontSize: '13px' }}>Flip Charge</span>}
                  style={{ fontSize: 10 }}
                />
              )}
              <IconButton aria-label="Chart Settings" onClick={this.handleOpen} data-testid="chart-settings-button">
                <Settings />
              </IconButton>
            </span>
          </Box>
        </Box>
        <Plot
          style={{ width: '890px', height: '400px', margin: '0 auto' }}
          useResizeHandler={true}
          data={this.updateData(this.state.data)}
          layout={this.buildLayout()}
          config={this.config}
        />

        <PresentationChartSettings
          open={this.state.open}
          handleClose={this.handleClose}
          editChartOptions={this.state.editChartOptions}
          handleColorChangeComplete={this.handleColorChangeComplete}
          handleMoveItem={this.handleMoveItem}
          handleChartTextUpdate={this.handleChartTextUpdate}
          handleShowChartItem={this.handleShowChartItem}
          handleChartLineType={this.handleChartLineType}
          showTOU={this.state.showTOU}
          handleToggle={this.handleToggle}
          hideHoverInfo={this.state.hideHoverInfo}
          TOUOpacity={this.state.TOUOpacity}
          handleValueChange={this.handleValueChange}
          customRangeMin={this.state.customRangeMin}
          customRangeMax={this.state.customRangeMax}
          handleSuccessClose={this.handleSuccessClose}
          handleStackChartItem={this.handleStackChartItem}
        />
      </Paper>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  settings: state.settings,
  setting: state.setting,
  proposal: state.proposal
});

export default withStyles(styles as any)(connect(mapStateToProps)(PresentScenarioChart));
