import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// MODULES IMPORTS
import UtilityDetail from './UtilityDetail';
import { handleInputFormChangeUtilities } from '../../actions/utilities';
import { useGetCalendars, useGetUtilityDetails, useGetUtilityInfo } from '../../queries/utilities';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import { getTariffs } from '../../actions/tariffs';

// MATERIAL UI IMPORTS
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useLocation, useParams } from 'react-router';

const UtilityContainer: React.FC = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  let { id } = useParams<{ id?: string }>();

  // QUERIES
  const { data: utilityInfo, status: infoStatus, error: infoError } = useGetUtilityInfo(id ? parseInt(id) : 0);
  const { data: utilityDetails, status: detailsStatus, error: detailsError } = useGetUtilityDetails(
    id ? parseInt(id) : 0
  );
  const { data: calendars, status: calendarStatus, error: calendarsError } = useGetCalendars(
    id ? parseInt(id) : 0,
    'PRICING_PERIOD'
  );

  const isAnyError = detailsStatus === 'error' || infoStatus === 'error' || calendarStatus === 'error';
  const isAllSuccess = detailsStatus === 'success' && infoStatus === 'success' && calendarStatus === 'success';
  const isAnyLoading = detailsStatus === 'loading' || infoStatus === 'loading' || calendarStatus === 'loading';

  useEffect(() => {
    if (!!utilityDetails && !!utilityInfo && !!calendars) {
      dispatch(
        handleInputFormChangeUtilities({
          utilityInfo,
          utilityDetails,
          calendars
        })
      );
    }
  }, [utilityDetails, utilityInfo, calendars, dispatch]);

  useEffect(() => {
    if (!!utilityDetails) {
      const name = utilityDetails.name;
      dispatch(resetBreadcrumb());
      dispatch(pushBreadcrumb('Utilities', '/utilities'));
      if (name) {
        dispatch(pushBreadcrumb(name, location.pathname));
      }
    }
  }, [location.pathname, dispatch, utilityDetails]);

  useEffect(() => {
    if (id) {
      dispatch(getTariffs({ lseId: id }));
    }
  }, [dispatch, id]);

  return (
    <div>
      {isAnyLoading && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {isAnyError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <div>{infoError?.message ?? ''}</div>
          <div>{detailsError?.message ?? ''}</div>
          <div>{calendarsError?.message ?? ''}</div>
        </Alert>
      )}
      {isAllSuccess && <UtilityDetail />}
    </div>
  );
};

export default UtilityContainer;
