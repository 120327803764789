import axios from 'axios';
import { Dispatch } from 'redux';
import { GenabilityLSEs, GenabilityCalendar, UtilityInfo } from '../types';
export const GET_LSES_REQUEST = 'GET_LSES_REQUEST';
export const GET_LSES_SUCCESS = 'GET_LSES_SUCCESS';
export const GET_LSES_ERROR = 'GET_LSES_ERROR';

export const UPDATE_UTILITY_META = 'UPDATE_UTILITY_META';

export const RESET_UTILITY_INFO = 'RESET_UTILITY_INFO';

export const CREATE_UTILITY_INFO_REQUEST = 'CREATE_UTILITY_INFO_REQUEST';
export const CREATE_UTILITY_INFO_SUCCESS = 'CREATE_UTILITY_INFO_SUCCESS';
export const CREATE_UTILITY_INFO_ERROR = 'CREATE_UTILITY_INFO_ERROR';

export const DELETE_UTILITY_INFO_REQUEST = 'DELETE_UTILITY_INFO_REQUEST';
export const DELETE_UTILITY_INFO_SUCCESS = 'DELETE_UTILITY_INFO_SUCCESS';
export const DELETE_UTILITY_INFO_ERROR = 'DELETE_UTILITY_INFO_ERROR';

export const GET_SIMPLE_LSE_REQUEST = 'GET_SIMPLE_LSE_REQUEST';
export const GET_SIMPLE_LSE_SUCCESS = 'GET_SIMPLE_LSE_SUCCESS';
export const GET_SIMPLE_LSE_ERROR = 'GET_SIMPLE_LSE_ERROR';

export const UTILITY_INPUT_FORM_CHANGE = 'UTILITY_INPUT_FORM_CHANGE';

const ESAP_API_URL = (window as any).REACT_APP_API_BASE_URL_V2;

/*************************** *****************/

export function getSimplifiedLSEs() {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_SIMPLE_LSE_REQUEST });

    axios
      .get(`${ESAP_API_URL}/genability/utilities?simplified=true`)
      .then(res => {
        const payload = res.data;

        dispatch({ type: GET_SIMPLE_LSE_SUCCESS, payload });
      })
      .catch(function (error) {
        dispatch({
          type: GET_SIMPLE_LSE_ERROR,
          payload: error
        });
      });
  };
}

interface SearchCriteria {
  pageStart: number;
  pageCount: number;
  search: string;
  sortOn: string;
  sortOrder: 'ASC' | 'DESC';
  addressString: string;
}

function assertIsLSE(res: any): asserts res is GenabilityLSEs {
  if (
    typeof res.status != 'string' ||
    !Array.isArray(res.results) ||
    res.type !== 'LoadServingEntity' ||
    typeof res.count != 'number' ||
    typeof res.pageStart !== 'number' ||
    typeof res.pageCount !== 'number'
  ) {
    throw new Error('The /utilities response is not the expected shape.');
  }
}

export function getLSEs(searchCriteria: SearchCriteria) {
  let query = '&serviceTypes=ELECTRICITY&fields=ext';
  if (searchCriteria.pageStart) {
    query += '&pageStart=' + searchCriteria.pageStart * 25;
  } else {
    query += '&pageStart=' + 0;
  }
  if (searchCriteria.pageCount) {
    query += '&pageCount=' + searchCriteria.pageCount;
  } else {
    query += '&pageCount=' + 25;
  }
  if (searchCriteria.search) {
    query += '&search=' + searchCriteria.search;
    query += '&searchOn=name,lseCode,lseId,code';
  }
  if (searchCriteria.sortOn) {
    query += '&sortOn=' + searchCriteria.sortOn;
  } else {
    query += '&sortOn=totalCustomers';
  }
  if (searchCriteria.sortOrder) {
    query += '&sortOrder=' + searchCriteria.sortOrder;
  } else {
    query += '&sortOrder=DESC';
  }
  if (searchCriteria.addressString) {
    query += '&zipCode=' + searchCriteria.addressString;
  }
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_LSES_REQUEST });
    axios
      .get<GenabilityLSEs>(`${ESAP_API_URL}/genability/utilities?${query}`)
      .then(response => {
        assertIsLSE(response.data as any);
        dispatch({
          type: GET_LSES_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: GET_LSES_ERROR, error });
      });
  };
}

export function getLSE(lseId: number) {
  return axios.get(`${ESAP_API_URL}/genability/utility/${lseId}`).then(res => res.data);
}

export function getCalendars(lseId: number, type: 'BILLING' | 'PRICING_PERIOD' | 'HOLIDAY') {
  let query = 'utility_id=' + lseId + '&calendar_type=' + type;
  if (type === 'PRICING_PERIOD') {
    query += '&populateEvents=true&pageCount=100&fromDateTime=2018-01-01';
  }
  return axios.get<GenabilityCalendar>(`${ESAP_API_URL}/genability/calendars?${query}`).then(res => res.data.results);
}

export function getUtilityInfo(lseId: number) {
  return axios.get(`${ESAP_API_URL}/utility/${lseId}`).then(res => res.data);
}

export function createUtilityInfo(utilityInfo: any) {
  return axios.post(`${ESAP_API_URL}/utility/${utilityInfo.lse_id}`, utilityInfo).then(res => res.data);
}

export function updateUtilityInfo(utilityInfo: UtilityInfo) {
  return axios.put<UtilityInfo>(`${ESAP_API_URL}/utility/${utilityInfo.lse_id}`, utilityInfo).then(res => res.data);
}

// export function deleteUtilityInfo(lseId: string) {
//   return (dispatch: Dispatch) => {
//     dispatch({ type: DELETE_UTILITY_INFO_REQUEST });
//     axios
//       .delete(`${ESAP_API_URL}/utility/${lseId}`)
//       .then(() => {
//         dispatch({ type: DELETE_UTILITY_INFO_SUCCESS, payload: lseId });
//       })
//       .catch(function (error) {
//         dispatch({ type: DELETE_UTILITY_INFO_ERROR, payload: error });
//       });
//   };
// }

export function resetUtilityInfo() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESET_UTILITY_INFO });
  };
}

export function handleInputFormChangeUtilities(updateData: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UTILITY_INPUT_FORM_CHANGE,
      payload: updateData
    });
  };
}
