import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SingleProfile from '../../components/Explore/SingleProfile';
import { summarizeAggregateByMonths } from '../../utility/Explore';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({});

const months = [
  { key: 1, display: 'January' },
  { key: 2, display: 'February' },
  { key: 3, display: 'March' },
  { key: 4, display: 'April' },
  { key: 5, display: 'May' },
  { key: 6, display: 'June' },
  { key: 7, display: 'July' },
  { key: 8, display: 'August' },
  { key: 9, display: 'September' },
  { key: 10, display: 'October' },
  { key: 11, display: 'November' },
  { key: 12, display: 'December' }
];

class MonthlyProfiles extends React.Component {
  state = {
    weekType: 'weekday'
  };

  handleChangeWeekType = () => {
    let update = 'weekday';
    if (this.state.weekType === 'weekday') {
      update = 'weekend';
    }
    this.setState({
      weekType: update
    });
  };

  render() {
    return (
      <div>
        <Grid container spacing={3} style={{ marginTop: 16 }}>
          {months.map(item => (
            <Grid item xs={4} key={item.key}>
              <SingleProfile
                title={item.display}
                data={summarizeAggregateByMonths(this.props.summary, [item.key], this.state.weekType)}
              >
                <FormControlLabel
                  control={
                    <Switch
                      value={this.state.weekType === 'weekend'}
                      color="primary"
                      onChange={this.handleChangeWeekType}
                    />
                  }
                  label="Weekend"
                />
              </SingleProfile>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

MonthlyProfiles.propTypes = {
  classes: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired
};

export default withStyles(styles)(MonthlyProfiles);
