import moment from 'moment';
import { formatDate } from './Dates';
import { touRateTemplate, flatRateTemplate } from './TariffTemplate';
import {
  ESAPTariff,
  TariffOverview,
  RateSchedule,
  Season,
  Seasons,
  TOUPeriod,
  GenabilityProperty,
  GenabilityRate,
  GenabilityRateBand,
  NBCharges,
  GenabilityTariff,
  GenabilityChoice
} from '../types';

export const getTariffTemplate = (name: 'touRate' | 'flatRate'): ESAPTariff => {
  if (name === 'touRate') {
    return touRateTemplate;
  } /*(name === 'flatRate')*/ else {
    return flatRateTemplate;
  }
};

export interface TariffColumn {
  key: keyof ESAPTariff;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  transform?: any;
}
export const tariffColumnSchema: TariffColumn[] = [
  { key: 'lse_name', numeric: false, disablePadding: false, label: 'Utility' },
  {
    key: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Name'
  },
  {
    key: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Code'
  },
  {
    key: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Type'
  },
  {
    key: 'effective_date',
    numeric: true,
    disablePadding: false,
    label: 'Effective Date',
    transform: formatDate
  }
];

export const userCreatedTariffColumnSchema: TariffColumn[] = [
  { key: 'lse_name', numeric: false, disablePadding: false, label: 'Utility' },
  {
    key: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Name'
  },
  {
    key: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Code'
  },
  {
    key: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Type'
  },
  {
    key: 'effective_date',
    numeric: true,
    disablePadding: false,
    label: 'Effective Date',
    transform: formatDate
  },
  {
    key: 'created_on',
    numeric: false,
    disablePadding: false,
    label: 'Created',
    transform: formatDate
  },
  {
    key: 'created_user',
    numeric: false,
    disablePadding: false,
    label: 'Created By'
  }
];

const CONSUMPTION_KEY = 'CONSUMPTION_BASED';
const DEMAND_KEY = 'DEMAND_BASED';
const SEASON_KEY = 'seasons';
const NON_COINCIDENTAL_KEY: string = 'NON_COINCIDENTAL';

// tariff parse logic
export const isInvalidConsumption = (name: string): boolean => {
  const CONSUMPTION_CHARGE_FILTERS = ['State Regulatory Fee', 'State Surcharge Tax'];
  return CONSUMPTION_CHARGE_FILTERS.indexOf(name) > -1;
};

// filter out invalid demand rates
const isInvalidDemand = (name: string): boolean => {
  const DEMAND_CHARGE_MATCH_FILTERS = [
    'Power Cost Adjustment',
    'Power Factor Adjustment',
    'Power Factor Adjustment Rate',
    'CPP Charges',
    'PDP Charges & Credits',
    'Critical Peak Pricing'
  ];
  const hasMatchFilter = DEMAND_CHARGE_MATCH_FILTERS.indexOf(name) > -1;

  const DEMAND_CHARGE_CONTAIN_FILTERS = ['Reservation Minimum'];
  const hasContainFilter = DEMAND_CHARGE_CONTAIN_FILTERS.some(filter => name.includes(filter));
  return hasContainFilter || hasMatchFilter;
};

const isAcceptableChargeClass = (name: string | undefined): boolean => {
  if (!name) return true;
  const CHARGE_CLASSES = ['SUPPLY', 'TRANSMISSION', 'DISTRIBUTION', 'NON_BYPASSABLE'];
  return name.split(',').some(n => CHARGE_CLASSES.includes(n.toUpperCase()));
};

export const buildTariffApplicabilityVariables = (properties: GenabilityProperty[]) => {
  const choices: {
    displayName: string;
    description: string;
    choices: GenabilityChoice[];
    key: string;
    selected: string;
  }[] = [];
  let applicabilityValues: Record<string, string> = {};
  properties.forEach(property => {
    applicabilityValues[property.keyName] = property.propertyValue;
    if (property.dataType === 'CHOICE' || property.dataType === 'BOOLEAN') {
      choices.push({
        displayName: property.displayName,
        description: property.description,
        choices: property.choices,
        key: property.keyName,
        selected: property.propertyValue
      });
    }
  });
  return { choices, applicabilityValues };
};

const isBaseDemandCondition = (rate: GenabilityRate): boolean => {
  return rate.chargeType === DEMAND_KEY && isAcceptableChargeClass(rate.chargeClass);
};

const isBaseConsumptionCondition = (rate: GenabilityRate): boolean => {
  return rate.chargeType === CONSUMPTION_KEY && isAcceptableChargeClass(rate.chargeClass);
};

const isNonBypassableCondition = (rate: GenabilityRate): boolean => {
  return (
    (rate.chargeClass && rate.chargeClass.indexOf('NON_BYPASSABLE') > -1) ||
    (rate.chargePeriod === 'HOURLY' && rate?.transactionType === 'BUY')
  );
};

const parseTimeOfUsePeriod = (
  rate: GenabilityRate,
  rateBand: GenabilityRateBand,
  overview: TariffOverview
): TariffOverview | undefined => {
  const season = rate.timeOfUse.season;
  if (!season) {
    return undefined;
  }
  // short term fix for having ON_PEAK, MID_PEAK, OFF_PEAK, and SUPER_OFF_PEAK in one tariff
  const touType = rate.rateName.indexOf('Super Off-Peak') > -1 ? 'SUPER_OFF_PEAK' : rate.timeOfUse.touType;
  if (!overview[SEASON_KEY][season.seasonId]) {
    overview[SEASON_KEY][season.seasonId] = {
      [touType]: [],
      overview: {
        fromDay: season.seasonFromDay,
        toDay: season.seasonToDay,
        fromMonth: season.seasonFromMonth,
        toMonth: season.seasonToMonth,
        name: season.seasonName
      }
    };
  } else if (!overview[SEASON_KEY][season.seasonId][touType]) {
    overview[SEASON_KEY][season.seasonId][touType] = [];
  }
  const periods = rate.timeOfUse.touPeriods.map((touPeriod: RateSchedule) => {
    return {
      fromDayOfWeek: touPeriod.fromDayOfWeek,
      fromHour: touPeriod.fromHour,
      fromMinute: touPeriod.fromMinute,
      toDayOfWeek: touPeriod.toDayOfWeek,
      toHour: touPeriod.toHour,
      toMinute: touPeriod.toMinute
    };
  });
  overview[SEASON_KEY][season.seasonId][touType].push({
    name: rate.rateName,
    amount: rateBand.rateAmount,
    credit: rateBand.isCredit,
    touType: touType,
    periods: periods,
    type: rate.chargeType,
    calculationFactor: rateBand.calculationFactor
  });
  return overview;
};

const parseSeasonRate = (rate: GenabilityRate, rateBand: GenabilityRateBand, overview: TariffOverview) => {
  if (isBaseDemandCondition(rate)) {
    const season = rate.season;
    if (!overview[SEASON_KEY][season.seasonId]) {
      overview[SEASON_KEY][season.seasonId] = {
        [NON_COINCIDENTAL_KEY]: [],
        overview: {
          fromDay: season.seasonFromDay,
          toDay: season.seasonToDay,
          fromMonth: season.seasonFromMonth,
          toMonth: season.seasonToMonth,
          name: season.seasonName
        }
      };
    } else if (!overview[SEASON_KEY][season.seasonId][NON_COINCIDENTAL_KEY]) {
      overview[SEASON_KEY][season.seasonId][NON_COINCIDENTAL_KEY] = [];
    }
    overview[SEASON_KEY][season.seasonId][NON_COINCIDENTAL_KEY].push({
      name: rate.rateName,
      amount: rateBand.rateAmount,
      credit: rateBand.isCredit,
      type: rate.chargeType,
      calculationFactor: rateBand.calculationFactor
    });
  } else if (isBaseConsumptionCondition(rate)) {
    const season = rate.season;
    if (!overview[SEASON_KEY][season.seasonId]) {
      overview[SEASON_KEY][season.seasonId] = {
        FLAT_RATE: [],
        overview: {
          fromDay: season.seasonFromDay,
          toDay: season.seasonToDay,
          fromMonth: season.seasonFromMonth,
          toMonth: season.seasonToMonth,
          name: season.seasonName
        }
      };
    } else if (!overview[SEASON_KEY][season.seasonId]['FLAT_RATE']) {
      overview[SEASON_KEY][season.seasonId]['FLAT_RATE'] = [];
    }
    (overview[SEASON_KEY][season.seasonId]['FLAT_RATE'] as TOUPeriod[]).push({
      periods: [],
      name: rate.rateName,
      amount: rateBand.rateAmount,
      credit: rateBand.isCredit,
      type: rate.chargeType,
      touType: 'FLAT_RATE',
      calculationFactor: rateBand.calculationFactor
    });
  }
};

const parseBaseRate = (rate: GenabilityRate, rateBand: GenabilityRateBand, overview: TariffOverview) => {
  if (isNonBypassableCondition(rate)) {
    parseNonbypassable(rate, rateBand, overview);
  }
  if (isBaseDemandCondition(rate)) {
    parseBaseDemand(rate, rateBand, overview);
  } else if (isBaseConsumptionCondition(rate)) {
    parseBaseConsumption(rate, rateBand, overview);
  }
};

const parseNonbypassable = (rate: GenabilityRate, rateBand: GenabilityRateBand, overview: TariffOverview) => {
  if (overview?.nonBypassableRate != undefined && Array.isArray(overview.nonBypassableCharges)) {
    if (rateBand.isCredit) {
      overview.nonBypassableRate -= rateBand.rateAmount;
    } else {
      overview.nonBypassableRate += rateBand.rateAmount;
    }
    overview.nonBypassableCharges.push({
      name: rate.rateName,
      amount: rateBand.rateAmount,
      credit: rateBand.isCredit,
      type: rate.chargeType,
      calculationFactor: rateBand.calculationFactor
    });
  }
};

const parseBaseConsumption = (rate: GenabilityRate, rateBand: GenabilityRateBand, overview: TariffOverview) => {
  if (rateBand.isCredit) {
    overview.consumptionBaseAdjustment -= rateBand.rateAmount;
  } else {
    overview.consumptionBaseAdjustment += rateBand.rateAmount;
  }
  overview.consumptionBaseCharges.push({
    name: rate.rateName,
    amount: rateBand.rateAmount,
    credit: rateBand.isCredit,
    type: rate.chargeType,
    calculationFactor: rateBand.calculationFactor
  });
};

const parseBaseDemand = (rate: GenabilityRate, rateBand: GenabilityRateBand, overview: TariffOverview) => {
  if (rateBand.isCredit) {
    overview.demandBaseAdjustment -= rateBand.rateAmount;
  } else {
    overview.demandBaseAdjustment += rateBand.rateAmount;
  }
  overview.demandBaseCharges.push({
    name: rate.rateName,
    amount: rateBand.rateAmount,
    credit: rateBand.isCredit,
    type: rate.chargeType,
    calculationFactor: rateBand.calculationFactor
  });
};

export const calculateTotal = (
  periodOverview: TOUPeriod[],
  chargeType: 'DEMAND_BASED' | 'CONSUMPTION_BASED',
  baseAdjustment: number = 0
) => {
  if (!periodOverview) {
    return 0;
  }
  let total = 0;
  periodOverview
    .filter(periodItem => periodItem.type === chargeType)
    .forEach(charge => {
      let calculationFactor = 1;
      if (charge.calculationFactor) {
        calculationFactor = charge.calculationFactor;
      }
      if (charge.credit) {
        total -= charge.amount * calculationFactor;
      } else {
        total += charge.amount * calculationFactor;
      }
    });
  if (chargeType === 'CONSUMPTION_BASED' && total > 0 && baseAdjustment) {
    total += baseAdjustment;
  }
  return parseFloat(total.toFixed(5));
};

export const calculateNCTotal = (season: Season, baseCharges: NBCharges[]) => {
  let total = 0;
  if (season[NON_COINCIDENTAL_KEY] && season[NON_COINCIDENTAL_KEY].length > 0) {
    season[NON_COINCIDENTAL_KEY].forEach((charge: TOUPeriod) => {
      if (charge.credit) {
        total -= charge.amount;
      } else {
        total += charge.amount;
      }
    });
  }
  if (baseCharges && baseCharges.length > 0) {
    baseCharges.forEach(charge => {
      if (charge.credit) {
        total -= charge.amount;
      } else {
        total += charge.amount;
      }
    });
  }
  return parseFloat(total.toFixed(5));
};

const getTouPeriodNames = (seasons: Seasons) => {
  let touPeriodNames = {};
  Object.keys(seasons).forEach(seasonName => {
    Object.keys(seasons[seasonName]).forEach(touType => {
      if (!touPeriodNames[touType]) {
        touPeriodNames[touType] = true;
      }
    });
  });
  let touPeriodNamesArray = Object.keys(touPeriodNames).filter(
    item => item !== 'overview' && item.toLowerCase().indexOf('coincidental') === -1
  );
  return touPeriodNamesArray;
};

export const getEnergyDataTable = (seasons: Seasons, baseAdjustment: any) => {
  let touPeriodNames = getTouPeriodNames(seasons);
  let prettyColumns = touPeriodNames.map(name => name.toString().replace('_', ' ').replace('_', ' '));
  let headerRow = ['Season'].concat(prettyColumns);
  let data: (string | number)[][] = [headerRow];
  Object.keys(seasons).forEach(seasonName => {
    let seasonRow: (string | number)[] = [seasonName];
    touPeriodNames.forEach(name => {
      seasonRow.push(
        parseFloat(calculateTotal(seasons[seasonName][name], 'CONSUMPTION_BASED', baseAdjustment).toFixed(5))
      );
    });
    data.push(seasonRow);
  });
  return data;
};

export const getDemandDataTable = (seasons: Seasons, baseAdjustment: any, baseCharges: any) => {
  let touPeriodNames = getTouPeriodNames(seasons);
  let prettyColumns = touPeriodNames.map(name => name.toString().replace('_', ' ').replace('_', ' '));
  let headerRow = ['Season'].concat(prettyColumns, ['NON COINCIDENTAL']);
  let data: (string | number)[][] = [headerRow];
  Object.keys(seasons).forEach(seasonName => {
    let seasonRow: (string | number)[] = [seasonName];
    touPeriodNames.forEach(name => {
      seasonRow.push(parseFloat(calculateTotal(seasons[seasonName][name], 'DEMAND_BASED').toFixed(5)));
    });
    seasonRow.push(calculateNCTotal(seasons[seasonName], baseCharges));
    data.push(seasonRow);
  });
  return data;
};

export const parseRateInformation = (all_rates: GenabilityRate[], applicabilityValues: Record<string, string>) => {
  let overview = {
    [SEASON_KEY]: {},
    consumptionBaseAdjustment: 0,
    consumptionBaseCharges: [],
    demandBaseAdjustment: 0,
    demandBaseCharges: [],
    nonBypassableCharges: [],
    nonBypassableRate: 0
  };

  all_rates.forEach(rate => {
    // only consider demand and consumption charges
    if (rate.chargeType !== DEMAND_KEY && rate.chargeType !== CONSUMPTION_KEY) {
      return;
    }

    let rateBand: GenabilityRateBand | undefined;
    if (rate.applicabilityKey) {
      const applicabilityValue = applicabilityValues[rate.applicabilityKey];
      // basically need to look for consumptionUpperLimit. I've found this on CRITICAL_PEAK touType
      rateBand = rate.rateBands.find(band => {
        return band.applicabilityValue === applicabilityValue;
      });
    } else {
      rateBand = rate.rateBands[0];
    }
    if (!rateBand || rateBand.rateAmount === 0) {
      return;
    }
    const rateLabel = rate.rateName || rate.rateGroupName;
    if (rate.chargeType === DEMAND_KEY && isInvalidDemand(rateLabel)) {
      return;
    } else if (rate.chargeType === CONSUMPTION_KEY && isInvalidConsumption(rateLabel)) {
      return;
    }
    if (rate.timeOfUse) {
      parseTimeOfUsePeriod(rate, rateBand, overview);
    } else if (rate.season) {
      parseSeasonRate(rate, rateBand, overview);
    } else {
      parseBaseRate(rate, rateBand, overview);
    }
  });
  const energyCharges = getEnergyDataTable(overview.seasons, overview.consumptionBaseAdjustment);
  const demandCharges = getDemandDataTable(overview.seasons, overview.demandBaseAdjustment, overview.demandBaseCharges);

  return {
    applicability_values: applicabilityValues,
    overview,
    energyCharges,
    demandCharges,
    all_rates
  };
};

export const parseTariffInformationUpdated = (
  tariff_response: GenabilityTariff,
  applicabilityValues: Record<string, string> | null = null
): Partial<ESAPTariff> => {
  if (Object.keys(tariff_response).length === 0) {
    return { properties: [] as GenabilityProperty[], applicability_values: {}, overview: {} as TariffOverview };
  }
  const all_rates = tariff_response.rates;
  const properties = tariff_response.properties;
  if (!applicabilityValues) {
    let propertyInfo = buildTariffApplicabilityVariables(properties);
    applicabilityValues = propertyInfo.applicabilityValues as Record<string, string>;
  }

  const results = parseRateInformation(all_rates, applicabilityValues);

  return {
    ...results,
    properties
  };
};

export const parseCalculatedBill = (billResults: any) => {
  let overview_2 = {};
  billResults.items.forEach((item: any) => {
    const key = item.fromDateTime + '__' + item.toDateTime;
    const chargeType = item.chargeType;
    if (!overview_2[key]) {
      overview_2[key] = {
        from: item.fromDateTime,
        to: item.toDateTime
      };
    }
    if (!overview_2[key]['season'] && item.seasonName) {
      overview_2[key]['season'] = item.seasonName;
    }
    if (chargeType === 'DEMAND_BASED' && !item.period) {
      item.period = 'NON_COINCIDENTAL';
    }
    // TOU
    if (item.period) {
      if (!overview_2[key][chargeType]) {
        overview_2[key][chargeType] = {};
      }
      if (!overview_2[key][chargeType][item.period]) {
        overview_2[key][chargeType][item.period] = {
          cost: 0,
          itemQuantity: 0
        };
      }
      let cost = overview_2[key][chargeType][item.period]['cost'] || 0;
      cost += item.cost;
      let itemQuantity = overview_2[key][chargeType][item.period]['itemQuantity'] || 0;
      itemQuantity += item.itemQuantity;
      overview_2[key][chargeType][item.period]['cost'] = cost;
      overview_2[key][chargeType][item.period]['itemQuantity'] = itemQuantity;
    } else {
      if (!overview_2[key][chargeType]) {
        overview_2[key][chargeType] = {
          cost: 0,
          itemQuantity: 0
        };
      }
      let cost = overview_2[key][chargeType]['cost'] || 0;
      cost += item.cost;
      let itemQuantity = overview_2[key][chargeType]['itemQuantity'] || 0;
      itemQuantity += item.itemQuantity;
      overview_2[key][chargeType]['cost'] = cost;
      overview_2[key][chargeType]['itemQuantity'] = itemQuantity;
    }
  });

  const billingInfo = overview_2;
  const billingInfoList: any = [];
  const demandColumns = new Set();
  const consumptionColumns = new Set();
  Object.keys(billingInfo).forEach(key => {
    let item = billingInfo[key];
    let totals = {};
    totals['ENERGY_COST'] = 0;
    totals['ENERGY_USED'] = 0;
    if (item['CONSUMPTION_BASED']) {
      let energyTotalCost = 0;
      let energyTotalQuantity = 0;
      Object.keys(item['CONSUMPTION_BASED']).forEach(consumptionKey => {
        if (!consumptionColumns.has(consumptionKey)) {
          consumptionColumns.add(consumptionKey);
        }
        energyTotalCost += item['CONSUMPTION_BASED'][consumptionKey].cost;
        energyTotalQuantity += item['CONSUMPTION_BASED'][consumptionKey].itemQuantity;
      });
      totals['ENERGY_COST'] = energyTotalCost;
      totals['ENERGY_USED'] = energyTotalQuantity;
    }
    totals['DEMAND_COST'] = 0;
    if (item['DEMAND_BASED']) {
      let demandTotalCost = 0;
      Object.keys(item['DEMAND_BASED']).forEach(demandKey => {
        if (!demandColumns.has(demandKey)) {
          demandColumns.add(demandKey);
        }
        demandTotalCost += item['DEMAND_BASED'][demandKey].cost;
      });
      totals['DEMAND_COST'] = demandTotalCost;
    }
    totals['TOTAL_COST'] = totals['DEMAND_COST'] + totals['ENERGY_COST'];
    item['TOTALS'] = totals;
    billingInfoList.push(item);
  });
  billingInfoList.sort(function (a: any, b: any) {
    return moment(a.from).valueOf() - moment(b.from).valueOf();
  });
  return {
    billingInfo: billingInfoList,
    consumptionColumns: [...consumptionColumns],
    demandColumns: [...demandColumns]
  };
};
