import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createProposalScenario } from '../../../actions/scenarios';
import {
  getProposalBatterySizing,
  runProposalBatterySizing,
  getProposalBatterySizingStatus
} from '../../../actions/proposals';
import { createLoadingSelector } from '../../../selectors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ProposalSizingRecommendations from './ProposalSizingRecommendations';
import { assumptionDefaults } from '../../../utility/Assumption';
import { StoreState } from '../../../reducers';
import { ProposalInternal, ScenarioInternal, AssumptionsInternal } from '../../../types';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  flex: {
    flex: 1
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  },
  button: {
    cursor: 'pointer'
  },
  statusContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3)
  }
});

interface IProps {
  proposal: ProposalInternal;
  classes: any;
  getProposalBatterySizing: (proposalId: string) => void;
  getProposalBatterySizingStatus: (proposalId: string) => void;
  runProposalBatterySizing: (
    proposalId: string,
    tariffId: string,
    isSolarStorage: boolean,
    pvScaleFactor: number
  ) => void;
  createProposalScenario: (proposalScenario: Partial<ScenarioInternal>, proposalId: string) => void;
}

interface IState {
  openTariffDialog: boolean;
  selectedTariffId: string;
  selectedSizing: string[];
  showSizing: boolean;
  pvScaleFactor: string;
  hasPV: boolean;
}

interface Status {
  completed: boolean;
  errored: boolean;
  tariff_id: string;
  error: string;
  in_progress: boolean;
  status: number;
}

class ProposalSizingContainer extends React.Component<IProps, IState> {
  private interval: any;
  state = {
    openTariffDialog: false,
    selectedTariffId: '',
    selectedSizing: [] as string[],
    showSizing: false,
    pvScaleFactor: '1',
    hasPV: false
  };

  componentDidMount() {
    this.props.getProposalBatterySizing(this.props.proposal.public_id);
    this.props.getProposalBatterySizingStatus(this.props.proposal.public_id);
    const isAllProcessed =
      this.props.proposal.sizing_status &&
      Object.keys(this.props.proposal.sizing_status).length > 0 &&
      Object.keys(this.props.proposal.sizing_status).every(key => this.props.proposal.sizing_status[key].completed);
    if (
      this.props.proposal?.sizing_recommendations &&
      (Object.keys(this.props.proposal.sizing_recommendations).length === 0 || !isAllProcessed)
    ) {
      this.interval = setInterval(() => {
        this.props.getProposalBatterySizingStatus(this.props.proposal.public_id);
      }, 10000);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      this.props?.proposal?.sizing_recommendations &&
      prevProps?.proposal?.sizing_recommendations &&
      Object.keys(this.props.proposal.sizing_recommendations).length >
        Object.keys(prevProps.proposal.sizing_recommendations).length
    ) {
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private handleSizingClick = (): void => {
    this.setState({ openTariffDialog: true });
  };

  private handleScaleUpdate = (e: React.ChangeEvent<{ name?: string; value: any }>) => {
    this.setState({
      pvScaleFactor: e.target.value
    });
  };

  handleToggle = (): void => {
    this.setState({ hasPV: !this.state.hasPV });
  };

  private handleTariffChange = (e: React.ChangeEvent<{ name?: string; value: any }>) => {
    this.setState({
      selectedTariffId: e.target.value
    });
  };

  private handleTariffDialogClose = (): void => {
    this.setState({ openTariffDialog: false, showSizing: false });
  };

  private handleRunBatterySizing = () => {
    if (this.state.selectedTariffId && ((this.state.hasPV && this.state.pvScaleFactor) || !this.state.hasPV)) {
      this.props.runProposalBatterySizing(
        this.props.proposal.public_id,
        this.state.selectedTariffId,
        this.state.hasPV,
        parseInt(this.state.pvScaleFactor)
      );
      this.setState({
        showSizing: true,
        openTariffDialog: false
      });
      this.props.getProposalBatterySizingStatus(this.props.proposal.public_id);
    }
  };

  private saveScenarioAssumptions = (scenario: ScenarioInternal) => {
    if (
      this.props.proposal.proposal_scenarios &&
      this.props.proposal.proposal_scenarios.length === 0 &&
      !scenario.is_template
    ) {
      scenario.is_status_quo = true;
    }
    this.props.createProposalScenario(scenario, this.props.proposal.public_id);
  };

  closeSizingSelections = () => {
    this.setState({
      showSizing: false
    });
  };

  private saveSizingSelections = () => {
    this.saveAllSizingSelections();
    this.setState({
      showSizing: false
    });
  };

  private saveAllSizingSelections = () => {
    let tariff = this.props.proposal.proposal_tariffs[0];
    this.state.selectedSizing.forEach(battery_sizing => {
      const power = +battery_sizing.split('__')[0];
      const energy_capacity = +battery_sizing.split('__')[1];
      let scenarioAssumptions = {
        tariffId: tariff.public_id,
        isBatt: true,
        power: power,
        capacity: energy_capacity,
        pBattMaxCH: power,
        pBattMaxDisch: -1 * power
      };
      let scenarioName = 'Storage Only - ' + power + ' kW ' + energy_capacity + ' kWh';
      let updateAssumption = {
        ...assumptionDefaults,
        ...scenarioAssumptions
      } as AssumptionsInternal;
      // create scenario object for parent components to do whatever they need
      let scenario: Partial<ScenarioInternal> = {
        proposal_id: this.props.proposal.public_id,
        name: scenarioName,
        assumptions: updateAssumption,
        is_template: true
      };
      this.saveScenarioAssumptions(scenario as ScenarioInternal);
    });
  };

  handleSizingSelectionClick = (sizing: string) => () => {
    const selectedIndex = this.state.selectedSizing.findIndex(item => {
      return item === sizing;
    });
    if (selectedIndex > -1) {
      this.setState({
        selectedSizing: [
          ...this.state.selectedSizing.slice(0, selectedIndex),
          ...this.state.selectedSizing.slice(selectedIndex + 1)
        ]
      });
    } else {
      this.setState({
        selectedSizing: [...this.state.selectedSizing, sizing]
      });
    }
  };

  getTariffCode = (tariffId: string): string => {
    const tariff = this.props.proposal.proposal_tariffs.find(tariff => tariff.public_id === tariffId);
    return tariff ? tariff.code : 'Unknown Tariff';
  };

  private renderStatus = (status: Status, classes: any): React.ReactNode => {
    if (status.completed) return null;
    if (status.errored) {
      return (
        <div className={classes.statusContainer}>
          <Typography variant="h5">Battery-sizing status for {this.getTariffCode(status.tariff_id)}</Typography>
          <Typography variant="body1">Error: {status.error}</Typography>
        </div>
      );
    }
    return status.in_progress ? (
      <div className={classes.statusContainer}>
        <Typography variant="h5">Status for Tariff: {this.getTariffCode(status.tariff_id)}</Typography>
        <Typography variant="body1">Processing: {Math.round(status.status) || '0'}% done.</Typography>
      </div>
    ) : null;
  };

  private renderSizingResults = () => {
    if (
      this.props.proposal?.sizing_recommendations &&
      Object.keys(this.props.proposal.sizing_recommendations).length > 0 &&
      Object.keys(this.props.proposal.sizing_recommendations).every(
        key => this.props.proposal.sizing_recommendations[key] !== null
      )
    ) {
      return Object.keys(this.props.proposal?.sizing_recommendations).map(tariff => {
        return (
          <div style={{ textAlign: 'right' }}>
            <strong style={{ marginRight: 40 }}>Battery Recommandations for {this.getTariffCode(tariff)}</strong>
            <Button
              aria-label="Add"
              onClick={this.saveSizingSelections}
              variant="contained"
              color="primary"
              style={{ marginRight: 36, marginTop: 16, marginBottom: 16 }}
            >
              Save
            </Button>
            <ProposalSizingRecommendations
              selected={this.state.selectedSizing}
              sizing_recommendations={this.props.proposal?.sizing_recommendations[tariff]}
              handleRowClick={this.handleSizingSelectionClick}
            />
          </div>
        );
      });
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography color="inherit" variant="h6" className={classes.flex}>
              Battery Recommendations
            </Typography>
            <Button color="primary" aria-label="Run Sizing" onClick={this.handleSizingClick}>
              Find Sizing
            </Button>
          </Toolbar>
          {this.props.proposal?.sizing_status &&
            Object.keys(this.props.proposal.sizing_status).length > 0 &&
            Object.keys(this.props.proposal.sizing_status).map(tariff => {
              return this.renderStatus(this.props.proposal.sizing_status[tariff], classes);
            })}
          {this.renderSizingResults()}
        </Paper>
        <Dialog
          open={this.state.openTariffDialog}
          onClose={this.handleTariffDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Select Tariff</DialogTitle>
          <DialogContent>
            <div className={classes.block}>
              <FormControlLabel
                control={<Switch onChange={this.handleToggle} checked={this.state.hasPV} name="hasPV" />}
                label="Use PV"
              />
              {this.state.hasPV && (
                <div>
                  <Typography variant="h6" gutterBottom>
                    Enter PV Sizing
                  </Typography>
                  <TextField
                    label="Max Hourly Solar Production (kW)"
                    key="pvScaleFactor"
                    value={this.state.pvScaleFactor}
                    name="pvScaleFactor"
                    onChange={this.handleScaleUpdate}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    margin="normal"
                  />
                </div>
              )}
              <Typography variant="h6" gutterBottom>
                Select Which Tariff To Use
              </Typography>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-tariff">Tariff</InputLabel>
                <Select
                  style={{ width: 240 }}
                  value={this.state.selectedTariffId}
                  name="tariffId"
                  onChange={this.handleTariffChange}
                  input={<Input id="select-tariff" />}
                >
                  {this.props.proposal &&
                    this.props.proposal.proposal_tariffs &&
                    this.props.proposal.proposal_tariffs.map(tariff => (
                      <MenuItem key={tariff.public_id} value={tariff.public_id}>
                        {tariff.code} - Effective: {tariff.effective_date}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleTariffDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleRunBatterySizing} color="primary">
              Run
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['GET_PROPOSAL_BATTERY_SIZING', 'GET_PROPOSAL_BATTERY_SIZING_STATUS']);

const mapStateToProps = (state: StoreState) => {
  return {
    isLoading: loadingSelector(state)
  };
};

export default connect(mapStateToProps, {
  createProposalScenario,
  getProposalBatterySizing,
  getProposalBatterySizingStatus,
  runProposalBatterySizing
})(withStyles(styles)(ProposalSizingContainer));
