import { GET_LSES_SUCCESS, UPDATE_UTILITY_META } from '../../actions/utilities';
import { GenabilityLSEs } from '../../types';

const INITIAL_STATE: Omit<GenabilityLSEs, 'results'> = {
  status: '',
  count: 0,
  type: '',
  pageCount: 25,
  pageStart: 0
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_LSES_SUCCESS:
      let { results, ...meta } = action.payload;
      return { ...state, count: meta.count };
    case UPDATE_UTILITY_META:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
