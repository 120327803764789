import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotData } from 'plotly.js';
import Plotly from '../../../custom-plotly.js';
import moment from 'moment';
import { fromJS, Map } from 'immutable';

//Material UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Settings from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//ESAP imports
import { AssumptionsInternal, ScenarioInternal } from '../../../types';
// import ScenarioTechBadges from '../../Scenarios/ScenarioTechBadges';
import { StoreState } from '../../../reducers';
import { createLoadingSelector } from '../../../selectors';
import FinancialPerfSettings from './FinancialPerfSettings';
import { exportCSVFile } from '../../../utility/General';
import ScenarioTechBadges from '../ScenarioTechBadges';
import { assertIsDefined } from '../../../utils/assertions';

const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles((theme: any) => ({
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  root: {
    width: '100%'
  },
  toolbar: {
    backgroundColor: theme.palette.esap.blueDim,
    color: '#fff',
    '& button': {
      color: 'white'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  message: {
    padding: theme.spacing(3)
  },
  formHeaderText: {
    margin: '3px',
    textTransform: 'uppercase',
    fontWeight: 500,
    color: '#353535',
    borderBottom: '1px dashed #b8b8b8',
    paddingBottom: 6
  },
  chartConfig: {
    background: '#e2ecf8',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    width: 'auto'
    //background: '#e2ecf8',
    // padding: theme.spacing(2)
  },
  dateChip: {
    fontSize: '.7rem',
    height: '20px',
    color: (theme.palette as any).esap.greenLight,
    letterSpacing: '0',
    lineHeight: '.7rem',
    backgroundColor: 'transparent',
    '& .MuiChip-label': {
      paddingLeft: 6,
      paddingRight: 6
    }
  },
  switch: {
    '& .MuiSwitch-root': {
      width: 54,
      height: 34
    },
    '& .MuiSwitch-thumb': {
      width: 15,
      height: 15
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '0.9rem'
    }
  }
}));

interface Props {
  assumptions: AssumptionsInternal;
  statusQuoTitle: string;
  statusQuoResult: any;
  scenarioResult: any;
  scenarioTitle: string;
  discountRate: { demand: number; energy: number };
  scenario: ScenarioInternal;
}

var layout: Map<any, any> = fromJS({
  legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
  // height: 280,
  margin: {
    t: 20,
    l: 60,
    r: 60,
    b: 80
  },
  xaxis: {
    tickangle: -45,
    zeroline: false
  },
  yaxis: {
    title: 'Demand Savings ($)',
    side: 'left',
    zeroline: false
  },
  yaxis2: {
    overlaying: 'y',
    title: 'Energy Savings ($)',
    side: 'right',
    zeroline: false
  }
});

const FinancialPerformanceTab: React.FC<Props> = ({
  assumptions,
  statusQuoTitle,
  statusQuoResult,
  scenarioResult,
  scenarioTitle,
  discountRate,
  scenario
}) => {
  const classes = useStyles();

  // TODO : change the action name later
  const loadingSelector = createLoadingSelector(['GET_']);
  const loading = useSelector(state => loadingSelector(state as StoreState));

  const [data, setData] = useState<Partial<PlotData>[]>([]);
  const [processed, setProcessed] = useState(false);

  const [openConfig, setOpenConfig] = useState(false);
  const [cumulSavings, setCumulSavings] = useState(false);
  const [totalSavings, setTotalSavings] = useState(false);

  // build data
  useEffect(() => {
    layout = layout
      .setIn(['yaxis2', 'visible'], !totalSavings)
      .setIn(['yaxis', 'title'], totalSavings ? 'Total Savings ($)' : 'Demand Savings ($)');

    const buildData = (scenarioResults: any, statusQuoResults: any) => {
      const dataParts: any[] = [];
      let proposalMonths = statusQuoResults['Months'];
      let scenarioEnergySavings = 0;
      let scenarioDemandSavings = 0;

      proposalMonths.forEach((month: string, index: number) => {
        const sqBaselineDemand = statusQuoResults['Demand Cost Total'][index];
        const sqBaselineEnergy = statusQuoResults['Energy Cost Total'][index];
        if (cumulSavings) {
          scenarioDemandSavings += sqBaselineDemand - scenarioResults['Demand Cost Total'][index];
          scenarioEnergySavings += sqBaselineEnergy - scenarioResults['Energy Cost Total'][index];
        } else {
          scenarioDemandSavings = sqBaselineDemand - scenarioResults['Demand Cost Total'][index];
          scenarioEnergySavings = sqBaselineEnergy - scenarioResults['Energy Cost Total'][index];
        }

        const entry = {
          month: month,
          demandSavings: scenarioDemandSavings,
          energySavings: scenarioEnergySavings,
          totalSavings: scenarioDemandSavings + scenarioEnergySavings
        };

        dataParts.push(entry);
      });

      let data: Partial<PlotData>[] = [];

      if (totalSavings) {
        data.push({
          // yaxis: 'y1',
          type: 'scatter',
          name: 'Scenario Total Savings',
          x: dataParts.map((x: any) => moment(moment(x.month).valueOf()).format('YYYY-MM-DD')),
          y: dataParts.map((x: any) => x.totalSavings || 0)
        } as Partial<PlotData>);
      } else {
        data.push({
          // yaxis: 'y1',
          type: 'scatter',
          name: 'Scenario Demand Savings',
          x: dataParts.map((x: any) => moment(moment(x.month).valueOf()).format('YYYY-MM-DD')),
          y: dataParts.map((x: any) => x.demandSavings || 0)
        } as Partial<PlotData>);

        data.push({
          yaxis: 'y2',
          type: 'scatter',
          name: 'Scenario Energy Savings',
          x: dataParts.map((x: any) => moment(moment(x.month).valueOf()).format('YYYY-MM-DD')),
          y: dataParts.map((x: any) => x.energySavings || 0)
        } as Partial<PlotData>);
      }

      setData(data);
      setProcessed(true);
    };
    if (statusQuoResult && Object.keys(statusQuoResult).length > 0) {
      buildData(scenarioResult, statusQuoResult);
    }
  }, [scenarioResult, statusQuoResult, cumulSavings, totalSavings, statusQuoTitle]);

  const buildCSVData = (scenarioResults: any, statusQuoResults: any) => {
    const dataParts: any[] = [];
    let proposalMonths: string[] = statusQuoResults['Months'];
    let scenarioEnergySavings = 0;
    let scenarioDemandSavings = 0;

    proposalMonths.forEach((date: string, index: number) => {
      const sqBaselineDemand = statusQuoResults['Demand Cost Total'][index];
      const sqBaselineEnergy = statusQuoResults['Energy Cost Total'][index];

      scenarioDemandSavings = sqBaselineDemand - scenarioResults['Demand Cost Total'][index];
      scenarioEnergySavings = sqBaselineEnergy - scenarioResults['Energy Cost Total'][index];

      // [startdate, month, year, demand saving, energy saving]
      const dateRegex = /(\d\d)\/(\d\d)\/(\d\d\d\d)/;
      // const [_, month, _, year] = date.match(dateRegex);
      const dateMatch = date.match(dateRegex);

      assertIsDefined(dateMatch);

      const entry = [
        date,
        dateMatch[1],
        dateMatch[3],
        scenarioDemandSavings.toFixed(2),
        scenarioEnergySavings.toFixed(2)
      ];

      dataParts.push(entry);
    });
    return dataParts;
  };

  const handleDownload = () => {
    const {
      energyEscalation,
      demandEscalation,
      numberOfYears,
      batteryEnergyCapacityDegradation,
      batteryPowerCapacityDegradation,
      consumptionEscalation
    } = assumptions;

    const settings = [
      ['Before Scenario', '', statusQuoTitle, '', ''],
      ['After Scenario', '', scenarioTitle, '', ''],
      ['Project Term (Years)', '', numberOfYears, '', ''],
      ['Energy Escalation (%)', '', energyEscalation, '', ''],
      ['Demand Escalation (%)', '', demandEscalation, '', ''],
      ['Battery Capacity Degradation (%)', '', batteryEnergyCapacityDegradation, '', ''],
      ['Battery Power Degradation (%)', '', batteryPowerCapacityDegradation, '', ''],
      ['Load Escalation (%)', '', consumptionEscalation, '', ''],
      ['Perfect/ Realtime', '', 'N/A', '', ''],
      ['Demand Savings Haircut (%)', '', discountRate.demand * 100, '', ''],
      ['Energy Savings Haircut (%)', '', discountRate.energy * 100, '', ''],
      ['', '', '', '', '']
    ];
    const subHeaders = ['Start Date', 'Month', 'Year', 'Total Demand Savings ($)', 'Total Energy Savings ($)'];

    const data = buildCSVData(scenarioResult, statusQuoResult);

    let downloadData = [...settings, subHeaders, ...data];

    exportCSVFile([], downloadData, `Financial Performance - ${scenarioTitle}`);
  };
  return (
    <>
      <Paper className={classes.root}>
        {loading && (
          <div style={{ textAlign: 'center', padding: 25 }}>
            <CircularProgress color="secondary" size={50} />
          </div>
        )}
        {!loading && (
          <>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
                Financial Performance
              </Typography>
              <Tooltip title="Download Financial Performance">
                <IconButton aria-label="Download Financial Performanc" onClick={handleDownload}>
                  <FileIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Financial Performance AssumptionsInternal">
                <IconButton aria-label="Financial Performance Assumptions" onClick={() => setOpenConfig(!openConfig)}>
                  <Settings />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </>
        )}
        {processed && (
          <>
            <Box className={classes.chartConfig} data-testid="chart-config">
              <Box className={classes.formHeaderText}>
                Configuration Options
                <span style={{ float: 'right' }}>
                  <ScenarioTechBadges scenario={scenario} />{' '}
                  <Chip className={classes.dateChip} label={`Data Start: ${scenarioResult['Months'][0]}`} />
                  <Chip
                    className={classes.dateChip}
                    label={`Data End: ${scenarioResult['Months'][scenarioResult['Months'].length - 1]}`}
                  />
                </span>
              </Box>
              <FormControlLabel
                className={classes.switch}
                control={
                  <Switch checked={cumulSavings} onChange={() => setCumulSavings(!cumulSavings)} name="cumulSavings" />
                }
                label="Cumulative Cash Flow"
              />
              <FormControlLabel
                className={classes.switch}
                control={
                  <Switch checked={totalSavings} onChange={() => setTotalSavings(!totalSavings)} name="totalSavings" />
                }
                label="Total Savings"
              />
            </Box>
            <div style={{ height: 350 }}>
              <Plot
                style={{ width: '100%' }}
                useResizeHandler={true}
                data={data}
                layout={layout.toJS()}
                config={{ displayModeBar: false, responsive: true }}
              />
            </div>
          </>
        )}
      </Paper>
      <FinancialPerfSettings
        open={openConfig}
        handleCloseConfig={() => setOpenConfig(false)}
        assumptions={assumptions}
        statusQuoName={statusQuoTitle}
        scenarioName={scenarioTitle}
        discountRate={discountRate}
      />
    </>
  );
};

export default FinancialPerformanceTab;
