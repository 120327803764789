import { AxiosError } from 'axios';
import { PlotData } from 'plotly.js';
import { useQuery } from 'react-query';
import { getForecast } from '../actions';
// import { ForecastResponse } from '../types';

export interface ForecastResponse {
  result: {
    datetime: number[];
    forecasted: number[];
    actual_load: number[];
    overall_score: number;
    scores_by_hour: number[];
  };
}

export interface ForecastProcessed {
  data: Partial<PlotData>[];
  overall_summary: number;
  hourly_summary: number[];
  forecast: number[];
  load: number[];
}

export function useGetForecast(proposalId: string) {
  return useQuery<ForecastResponse, AxiosError, ForecastProcessed>(
    ['Forecast', proposalId],
    () => getForecast(proposalId),
    {
      enabled: !!proposalId,
      staleTime: 30000,
      select: response => {
        const { datetime, forecasted, actual_load, scores_by_hour, overall_score } = response.result;
        let data: Partial<PlotData>[] = [];
        if (actual_load && forecasted && datetime) {
          data = [
            {
              type: 'scatter',
              mode: 'lines',
              name: 'Load',
              x: datetime,
              y: actual_load,
              line: { color: '#000' }
            },
            {
              type: 'scatter',
              mode: 'lines',
              name: 'Forecast',
              x: datetime,
              y: forecasted,
              line: { color: 'green' }
            }
          ];
        }
        return {
          data,
          hourly_summary: scores_by_hour,
          overall_summary: overall_score,
          forecast: forecasted,
          load: actual_load
        };
      }
    }
  );
}
