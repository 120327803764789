import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { List, fromJS } from 'immutable';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// REACT IMPORTS
// import { PORTFOLIO_INPUT_FORM_CHANGE, updatePortfolio, createPortfolio } from '../../../actions/portfolios';
import AccessControl from '../../../utility/AccessControl';
import { PortfolioMeter, PortfolioType, Portfolio, ScenarioInternal } from '../../../types';
import PortfolioMetersAssignNemA from './PortfolioMetersAssignNemA';
import PortfolioMetersAssignGeneral from './PortfolioMetersAssignGeneral';
import { StoreState } from '../../../reducers';
// import { getAllProposals } from '../../../actions';
import { createPortfolioMeters, UPDATE_PORTFOLIO_METER } from '../../../actions/portfolios';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  addProposal: {
    marginLeft: 20,
    marginBottom: 20
  }
}));

interface IPortfolioMetersContainerProps {
  portfolio?: Portfolio;
  setHasChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setHaveMetersChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setWasSaved?: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext?: any;
}

const PortfolioMetersContainer: React.FunctionComponent<IPortfolioMetersContainerProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const isDetail = /portfolios\/\w+/.test(location.pathname);

  // if used in detail, take portfolio from props
  // if not, we are in creation mode so use newPortfolio
  let portfolio = useSelector((state: StoreState) => state.newPortfolio);

  if (props.portfolio && Object.keys(props.portfolio).length > 0) {
    portfolio = props.portfolio;
  }

  const isComplete = (metersArray: PortfolioMeter[]) => {
    return metersArray && metersArray.length > 0 && metersArray[0].proposal_id && metersArray[0].scenario_id;
  };

  const hasChanged = (changed: boolean): void => {
    if (props.setHasChanged) {
      props.setHasChanged(changed);
    }
  };

  const haveMetersChanged = (changed: boolean): void => {
    if (props.setHaveMetersChanged) {
      props.setHaveMetersChanged(changed);
    }
  };

  const wasSaved = (bool: boolean) => {
    if (props.setWasSaved) {
      props.setWasSaved(bool);
    }
  };

  const handlePortfolioMetersSave = (): void => {
    // should be only available for the creation part
    // as Fab is hidden in Portfolio Detail
    if (portfolio && portfolio.public_id) {
      const portfolioMeters = [...portfolio.portfolio_meters].map(meter => ({
        ...meter,
        portfolio_id: portfolio.public_id
      }));
      dispatch(createPortfolioMeters(portfolio.public_id, portfolioMeters));
      hasChanged(false);
      haveMetersChanged(false);
      wasSaved(true);
      if (props.handleNext) {
        props.handleNext();
      }
    } else {
      console.error('Cant get the proposal public id to save meters');
    }
  };

  const handleTogglePrimary = (proposalId: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    const updatedMeters = fromJS(portfolio.portfolio_meters).map((m: Map<keyof PortfolioMeter, any>) =>
      m.set('is_primary', m.get('proposal_id') == proposalId ? true : false)
    );
    dispatch({ type: UPDATE_PORTFOLIO_METER, payload: updatedMeters.toJS() });
    hasChanged(true);
    haveMetersChanged(true);
    wasSaved(false);
  };

  const handleProposalChange = (previousProposalId: string) => (e: {
    id?: string;
    scenarios?: ScenarioInternal[];
    name: string;
  }) => {
    if (e?.id && e?.scenarios && e.name) {
      const updatedMeter = portfolio.portfolio_meters.find(m => m.proposal_id === previousProposalId);
      const updatedMeterIndex = portfolio.portfolio_meters.findIndex(m => m.proposal_id === previousProposalId);
      if (updatedMeter && updatedMeterIndex != undefined) {
        const metersCopy = List(portfolio.portfolio_meters).set(updatedMeterIndex, {
          ...updatedMeter,
          proposal_id: e.id,
          scenarios: e?.scenarios?.filter(s => s.active),
          proposal_name: e.name
        });
        dispatch({ type: UPDATE_PORTFOLIO_METER, payload: metersCopy.toJS() });
        hasChanged(true);
        haveMetersChanged(true);
        wasSaved(false);
      }
    }
  };

  const updatePortfolioMeter = (prop: keyof PortfolioMeter) => (proposalId: string) => ({ value }: any) => {
    const updatedMeter = portfolio.portfolio_meters.find(m => m.proposal_id === proposalId);
    const updatedMeterIndex = portfolio.portfolio_meters.findIndex(m => m.proposal_id === proposalId);
    if (updatedMeter && updatedMeterIndex != undefined) {
      const metersCopy = List(portfolio.portfolio_meters).set(updatedMeterIndex, {
        ...updatedMeter,
        [prop]: value
      });
      dispatch({ type: UPDATE_PORTFOLIO_METER, payload: metersCopy.toJS() });
      hasChanged(true);
      haveMetersChanged(true);
      wasSaved(false);
    }
  };
  const handleScenarioChange = updatePortfolioMeter('scenario_id');
  const handleStatusQuoChange = updatePortfolioMeter('status_quo_id');

  const handleDeleteMeter = (proposalId: string) => () => {
    const updatedMeterIndex = portfolio.portfolio_meters.findIndex(m => m.proposal_id === proposalId);
    const metersCopy = List(portfolio.portfolio_meters).delete(updatedMeterIndex);
    dispatch({ type: UPDATE_PORTFOLIO_METER, payload: metersCopy.toJS() });
    hasChanged(true);
    haveMetersChanged(true);
    wasSaved(false);
  };

  const handleAddNewMeter = () => {
    const newMeter = {
      scenario_id: '',
      is_primary: false,
      proposal_id: '',
      portfolio_id: '',
      status_quo_id: undefined,
      scenarios: [] as ScenarioInternal[]
    };
    const metersCopy = List(portfolio.portfolio_meters).push(newMeter);
    dispatch({ type: UPDATE_PORTFOLIO_METER, payload: metersCopy.toJS() });
    hasChanged(true);
    haveMetersChanged(true);
    wasSaved(false);
  };

  const getTotalCost = (meter: PortfolioMeter): number => {
    let energyCosts = 0;
    let demandCosts = 0;

    if (meter?.scenario_id && meter?.scenarios.length > 0) {
      const selectedScenario = meter.scenarios.find(s => s.public_id === meter.scenario_id);

      if (selectedScenario && selectedScenario.results) {
        energyCosts = selectedScenario.results['Energy Cost Total'].reduce((total: number, num: number) => {
          return total + num;
        }, 0);
        demandCosts = selectedScenario.results['Demand Cost Total'].reduce((total: number, num: number) => {
          return total + num;
        }, 0);
      }
    }
    return energyCosts + demandCosts;
  };

  const getTotalSavings = (meter: PortfolioMeter): number => {
    let energySavings = 0;
    let demandSavings = 0;

    if (meter?.proposal_id && meter?.scenario_id && meter.scenarios.length > 0) {
      const selectedScenario = meter.scenarios.find(s => s.public_id === meter.scenario_id);

      const selectedStatusQuo = meter.scenarios.find(s => s.public_id === meter.status_quo_id);

      if (selectedScenario && selectedScenario.results && selectedStatusQuo && selectedStatusQuo.results) {
        const statusQuoEnergyCosts = selectedStatusQuo.results['Energy Cost Total'].reduce(
          (total: number, num: number) => {
            return total + num;
          },
          0
        );
        const statusQuoDemandCosts = selectedStatusQuo.results['Demand Cost Total'].reduce(
          (total: number, num: number) => {
            return total + num;
          },
          0
        );
        const scenarioEnergyCosts = selectedScenario.results['Energy Cost Total'].reduce(
          (total: number, num: number) => {
            return total + num;
          },
          0
        );
        const scenarioDemandCosts = selectedScenario.results['Demand Cost Total'].reduce(
          (total: number, num: number) => {
            return total + num;
          },
          0
        );

        energySavings = statusQuoEnergyCosts - scenarioEnergyCosts;
        demandSavings = statusQuoDemandCosts - scenarioDemandCosts;
      }
    }
    return energySavings + demandSavings;
  };

  const renderMeterAssign = (type: PortfolioType) => {
    switch (type) {
      case 'NEMA':
        return (
          <PortfolioMetersAssignNemA
            meters={portfolio.portfolio_meters}
            handleTogglePrimary={handleTogglePrimary}
            handleProposalChange={handleProposalChange}
            handleScenarioChange={handleScenarioChange}
            handleStatusQuoChange={handleStatusQuoChange}
            handleDeleteMeter={handleDeleteMeter}
            getTotalCost={getTotalCost}
            getTotalSavings={getTotalSavings}
          />
        );
      case 'GENERAL':
        return (
          <PortfolioMetersAssignGeneral
            meters={portfolio.portfolio_meters}
            handleTogglePrimary={handleTogglePrimary}
            handleProposalChange={handleProposalChange}
            handleScenarioChange={handleScenarioChange}
            handleStatusQuoChange={handleStatusQuoChange}
            handleDeleteMeter={handleDeleteMeter}
            getTotalCost={getTotalCost}
            getTotalSavings={getTotalSavings}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {!isDetail && (
        <AccessControl requiredPermissions={['editor', 'admin']}>
          <div className="button-container">
            <Fab
              disabled={!isComplete(portfolio.portfolio_meters)}
              size="small"
              color="secondary"
              aria-label="Save"
              className="button-white"
              onClick={handlePortfolioMetersSave}
            >
              <Save />
            </Fab>
          </div>
        </AccessControl>
      )}
      <Paper className={classes.root}>
        {!isDetail && (
          <Toolbar className={classes.toolbar}>
            <Typography color="inherit" variant="h6">
              {props.portfolio && Object.keys(props.portfolio).length > 0
                ? `Portfolio - ${props.portfolio.name}`
                : 'Portfolio Meters Selection'}
            </Typography>
          </Toolbar>
        )}
        {renderMeterAssign(portfolio.portfolio_type)}
        <Grid style={{ padding: 16 }}>
          <Button className={classes.addProposal} variant="contained" onClick={handleAddNewMeter}>
            + Add Proposal
          </Button>
        </Grid>
      </Paper>
    </div>
  );
};

export default PortfolioMetersContainer;
