import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  stepContainer: {
    margin: 20
  }
});

interface WizardStepProps {
  label: string;
  handleNext?: any;
}

const WizardStep: React.FC<WizardStepProps> = props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div style={{ minHeight: '300px' }}>
        <div style={{ marginTop: '25px', margin: '0 auto', maxWidth: 1500 }}>
          <div className={classes.stepContainer}>
            {React.isValidElement(props.children) &&
              React.cloneElement(React.Children.only(props.children), { handleNext: props.handleNext })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WizardStep;
