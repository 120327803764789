import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    overflowX: 'auto'
  },
  cell: {
    fontSize: '0.825rem'
  },
  table: {},
  topRowCells: {}
}));

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

interface Props {
  selected: any;
  sizing_recommendations: [string, any][];
  handleRowClick: any;
}

const ProposalSizingRecommendations: React.FC<Props> = ({ selected, sizing_recommendations, handleRowClick }) => {
  const classes = useStyles();

  const isSizingSelected = (sizing: any) => {
    const indexResults = selected.findIndex((selectedSizing: any) => {
      return selectedSizing === sizing;
    });
    return indexResults !== -1;
  };

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table} aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell className={classes.topRowCells} padding="default">
              Power Rating
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Energy Rating
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Total Cost
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Battery Cost
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Demand Cost
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Demand Savings
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Energy Cost
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Energy Savings
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Total Savings
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Savings $/kWh
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Degradation Rate
            </TableCell>
            <TableCell className={classes.topRowCells} padding="default">
              Degradation Cost
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(sizing_recommendations) &&
            sizing_recommendations.map((battery_item: any, index: number) => {
              const isSelected = isSizingSelected(battery_item[0]);
              return (
                <TableRow
                  hover
                  onClick={handleRowClick(battery_item[0])}
                  style={{ cursor: 'pointer' }}
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isSelected} />
                  </TableCell>
                  <TableCell component="th" scope="row" padding="default" className={classes.cell}>
                    {battery_item[0].split('__')[0]}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {battery_item[0].split('__')[1]}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].cost)}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].battery_costs)}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].demand_charges)}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].demand_savings)}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].energy_charges)}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].energy_savings)}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].savings)}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].savings_per_kwh)}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {parseFloat(battery_item[1].degradation_rate).toFixed(2) + '%'}
                  </TableCell>
                  <TableCell className={classes.cell} padding="default">
                    {formatter.format(battery_item[1].degradation_total_cost)}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProposalSizingRecommendations;
