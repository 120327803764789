import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { fromJS } from 'immutable';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import Refresh from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

// REACT IMPORTS
import { StoreState } from '../../reducers';
import {
  getPortfolio,
  PORTFOLIO_INPUT_FORM_CHANGE,
  updatePortfolio,
  runPortfolio,
  duplicatePortfolio,
  deletePortfolio,
  UPDATE_PORTFOLIO_SUCCESS
} from '../../actions/portfolios';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import PortfolioMetersContainer from '../../components/Portfolios/Detail/PortfolioMetersContainer';
import PortfolioResultsContainer from '../../components/Portfolios/Detail/PortfolioResultsContainer';
import PortfolioGeneralForm from '../../components/Portfolios/Detail/PortfolioGeneralForm';
import AccessControl from '../../utility/AccessControl';
import ConfirmPopUp from '../../components/Common/ConfirmPopUp/ConfirmPopUp';
import ErrorDialog from '../../components/Common/ErrorDialog';
import { createLoadingSelector } from '../../selectors';
import { PortfolioResults } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  spacer: {
    flex: '1 1 auto'
  },
  loadingContainer: {
    textAlign: 'center',
    padding: 25
  },
  runPortfolioButton: {
    background: (theme.palette as any).esap.greenLight,
    color: '#fff',
    marginRight: 8,
    '&:hover': {
      background: (theme.palette as any).esap.greenDark
    }
  },
  deletePortfolioButton: {
    background: (theme.palette as any).esap.red,
    color: '#fff',
    marginRight: 8,
    '&:hover': {
      background: (theme.palette as any).esap.redDark
    }
  }
}));

// TODO: refactor the state using something like this
// type PrimaryState = 'Loading' | 'Error' | 'Success_NoResults' | 'Success_GotResults' | 'Success_GotError';
// type SecondaryState = 'Idle' | 'Saved' | 'Changed';
// const [primaryState, setPrimaryState] = useState<PrimaryState>('Loading');
// const [secondaryState, setSecondaryState] = useState<SecondaryState>('Idle');

type Props = RouteComponentProps;

const PortfolioDetail: React.FC<Props> = ({ history, location, match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const breadcrumbsTrail = useSelector((state: StoreState) => state.breadcrumbsTrail);
  const portfolio = useSelector((state: StoreState) => state.portfolio);

  const loadingSelector = createLoadingSelector(['GET_PORTFOLIO']);
  const isLoading = useSelector(loadingSelector);

  const [tab, setTab] = useState(0);

  // state
  const [hasChanged, setHasChanged] = useState(false);
  const [haveMetersChanged, sethaveMetersChanged] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);

  // validation, error handling
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validation, setValidation] = useState<string[]>([]);

  const [hasResults, setHasResults] = useState(false);

  useEffect(() => {
    setHasResults((portfolio && portfolio.results && Object.keys(portfolio.results).length > 0) as boolean);
  }, [portfolio, portfolio.results]);

  // initialize data
  useEffect(() => {
    setErrorMessage('');
    dispatch(getPortfolio((match.params as any).id));
    return () => {
      // reset/clear Portfolio when unmounting
      dispatch({ type: UPDATE_PORTFOLIO_SUCCESS, payload: {} });
    };
  }, [getPortfolio, (match.params as any).id]);

  // initilaize breadcrumbs
  const { name } = portfolio;
  useEffect(() => {
    if (
      breadcrumbsTrail[breadcrumbsTrail.length - 1] &&
      breadcrumbsTrail[breadcrumbsTrail.length - 1].link &&
      !breadcrumbsTrail[breadcrumbsTrail.length - 1].link.includes('/customers/')
    ) {
      dispatch(resetBreadcrumb());
    }
    dispatch(pushBreadcrumb('Portfolios', '/portfolios'));
    dispatch(pushBreadcrumb(name, location.pathname));
    // eslint-disable-next-line
  }, [location.pathname, name]);

  useEffect(() => {
    if (
      !isLoading &&
      portfolio &&
      portfolio?.results &&
      Object.keys(portfolio.results).length > 0 &&
      (portfolio.results as PortfolioResults).error
    ) {
      setErrorMessage((portfolio.results as PortfolioResults).error);
    }
  }, [portfolio, isLoading]);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    if ((hasChanged || haveMetersChanged) && !wasSaved) {
      setSnackBarOpen(true);
    } else {
      setTab(newValue);
    }
  };

  const handlePortfolioChange = (event: any) => {
    setHasChanged(true);
    setWasSaved(false);
    const payload = { [event.target.name]: event.target.value };
    dispatch({ type: PORTFOLIO_INPUT_FORM_CHANGE, payload });
  };

  const handlePortfolioSave = (): void => {
    if (haveMetersChanged) {
      const updatedPortfolio = fromJS(portfolio).set('results', {});
      dispatch(updatePortfolio(updatedPortfolio.toJS()));
    } else {
      dispatch(updatePortfolio(portfolio));
    }
    setWasSaved(true);
  };

  const handleRefreshPortfolio = () => {
    if ((match?.params as any)?.id) {
      dispatch(getPortfolio((match.params as any).id));
    }
  };

  const handleConfirmClose = (shouldRun: boolean) => () => {
    if (shouldRun && portfolio?.public_id) {
      validatePortfolio();
    }
    setIsConfirmDialog(false);
  };

  const validatePortfolio = () => {
    // TODO: create validation
    const messages: string[] = [];
    if (!portfolio.name) {
      messages.push('A portfolio must  have a name.');
    }
    if (!portfolio.portfolio_meters || !portfolio.portfolio_meters) {
      messages.push('You need to assign proposals/meters to this portfolio.');
    } else {
      portfolio.portfolio_meters.forEach(meter => {
        if (!meter.scenario_id) {
          messages.push(`You must choose a scenario for proposal ${meter.proposal_name || meter.proposal_id}`);
        }
      });
      if (portfolio.portfolio_meters.every(meter => !meter.is_primary)) {
        messages.push(`There should should be one meter set as primary.`);
      }
      if (portfolio.portfolio_meters.length < 2) {
        messages.push(`There should be at least one primary and one beneficiary meter.`);
      }
      // check if primary meter has solar
      const primary = portfolio.portfolio_meters.find(m => m.is_primary);
      if (primary) {
        const primary_scenario = primary.scenarios.find(s => s.public_id == primary.scenario_id);
        if (primary_scenario) {
          if (!primary_scenario.assumptions.isPV) {
            messages.push(`Primary scenario must use solar.`);
          }
        }
      }
    }
    handleRunPortfolio(messages);
  };

  const handleRunPortfolio = (messages: string[]) => {
    if (messages.length) {
      setValidation(messages);
    } else {
      setValidation([]);

      dispatch(runPortfolio(portfolio.public_id));
      setHasChanged(false);
      setWasSaved(false);
      sethaveMetersChanged(false);
    }
  };

  const handlePortfolioDelete = () => () => {
    dispatch(deletePortfolio(portfolio.public_id));
    history.push('/portfolios');
  };

  const handleCloseError = () => {
    setErrorMessage('');
  };

  const handleConfirmRun = () => {
    setIsConfirmDialog(true);
  };

  const handleCopyPortfolio = () => {
    dispatch(duplicatePortfolio(portfolio.public_id));
    history.push('/portfolios');
  };

  return (
    <>
      {isLoading && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {!isLoading && (
        <>
          <AccessControl requiredPermissions={['editor', 'admin']}>
            <div className="button-container">
              <>
                {!hasResults && (
                  <Tooltip title="Refresh Portfolio">
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="Refresh"
                      className="button-white"
                      onClick={handleRefreshPortfolio}
                    >
                      <Refresh />
                    </Fab>
                  </Tooltip>
                )}
                <Tooltip title="Delete Portfolio">
                  <Fab
                    color="secondary"
                    size="small"
                    aria-label="Delete Portfolio"
                    className={classes.deletePortfolioButton}
                    onClick={() => setIsConfirmDelete(true)}
                  >
                    <Delete />
                  </Fab>
                </Tooltip>
                <Tooltip title="Save Portfolio">
                  <span>
                    <Fab
                      disabled={!hasChanged || wasSaved}
                      size="small"
                      color="secondary"
                      aria-label="Save Portfolio"
                      className="button-white"
                      onClick={handlePortfolioSave}
                    >
                      <Save />
                    </Fab>
                  </span>
                </Tooltip>
                <Tooltip title="Copy Portfolio">
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="copy-portfolio"
                    data-testid="copy-portfolio"
                    className="button-white"
                    onClick={handleCopyPortfolio}
                  >
                    <OpenInNew />
                  </Fab>
                </Tooltip>
                {haveMetersChanged && wasSaved && (
                  <Tooltip title="Run Portfolio">
                    <Fab
                      aria-label="Run Portfolio"
                      size="small"
                      className={classes.runPortfolioButton}
                      onClick={handleConfirmRun}
                    >
                      <CheckCircle />
                    </Fab>
                  </Tooltip>
                )}
              </>
            </div>
          </AccessControl>
          <AppBar position="static" color="default">
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="General" />
              <Tab label="Meters" />
            </Tabs>
          </AppBar>
          <Paper className={classes.root}>
            {tab === 0 && (
              <div>
                <PortfolioGeneralForm handlePortfolioChange={handlePortfolioChange} portfolio={portfolio} />
              </div>
            )}
            {tab === 1 && (
              <div>
                <PortfolioMetersContainer
                  portfolio={portfolio}
                  setHasChanged={setHasChanged}
                  setHaveMetersChanged={sethaveMetersChanged}
                  setWasSaved={setWasSaved}
                />
                {portfolio &&
                  portfolio.results &&
                  Object.keys(portfolio.results).length > 1 &&
                  portfolio.results.error.length == 0 && <PortfolioResultsContainer portfolio={portfolio} />}
              </div>
            )}
          </Paper>
          <Dialog
            open={isConfirmDialog}
            onClose={handleConfirmClose(false)}
            aria-labelledby="confirm-run-portfolio"
            aria-describedby="confirm before running the portfolio"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to run this portfolio now?</DialogTitle>
            <DialogActions>
              <Button onClick={handleConfirmClose(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmClose(true)} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <ConfirmPopUp
            open={isConfirmDelete}
            message="Are you sure that you want to delete this portfolio?"
            onConfirmClose={handlePortfolioDelete}
          />
          <Snackbar
            open={snackBarOpen}
            autoHideDuration={5000}
            onClose={() => setSnackBarOpen(false)}
            message={<span id="message-id">Please save your changes first.</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" onClick={() => setSnackBarOpen(false)}>
                <CloseIcon />
              </IconButton>
            ]}
          />
          <ErrorDialog
            open={validation.length > 0}
            errorMsg={
              <>
                <h3>Some required data is missing or incorrect.</h3>
                <ul>
                  {validation.map(warning => {
                    return <li key={warning}>{warning}</li>;
                  })}
                </ul>
              </>
            }
            onClose={() => setValidation([])}
          />

          {tab === 1 && (
            <ErrorDialog
              open={Boolean(errorMessage)}
              errorMsg={`Error while processing the porfolio:
          ${errorMessage}`}
              onClose={handleCloseError}
            />
          )}
        </>
      )}
    </>
  );
};

export default PortfolioDetail;
