import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SingleProfile from '../../components/Explore/SingleProfile';
import { summarizeAggregateByMonths } from '../../utility/Explore';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const seasons = [
  { seasonKeys: [3, 4, 5], display: 'Spring' },
  { seasonKeys: [6, 7, 8], display: 'Summer' },
  { seasonKeys: [9, 10, 11], display: 'Fall' },
  { seasonKeys: [12, 1, 2], display: 'Winter' }
];

const styles = theme => ({});

class SeasonalProfiles extends React.Component {
  state = {
    weekType: 'weekday'
  };

  handleChangeWeekType = () => {
    let update = 'weekday';
    if (this.state.weekType === 'weekday') {
      update = 'weekend';
    }
    this.setState({
      weekType: update
    });
  };

  render() {
    return (
      <div>
        <Grid container style={{ marginTop: 16 }} spacing={3}>
          {seasons.map(item => (
            <Grid item xs={6} key={item.display}>
              <SingleProfile
                title={item.display}
                data={summarizeAggregateByMonths(this.props.summary, item.seasonKeys, this.state.weekType)}
              >
                <FormControlLabel
                  control={
                    <Switch
                      value={this.state.weekType === 'weekend'}
                      color="primary"
                      onChange={this.handleChangeWeekType}
                    />
                  }
                  label="Weekend"
                />
              </SingleProfile>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

SeasonalProfiles.propTypes = {
  classes: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired
};

export default withStyles(styles)(SeasonalProfiles);
