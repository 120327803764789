import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import EventIcon from '@material-ui/icons/Event';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// REACT IMPORTS
import { generateIntraDayProfileGrid } from '../../../utility/Explore';
import ProfileTable from '../../Explore/ProfileTable';
import { AssumptionsInternal } from '../../../types';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  sidenav: {
    backgroundColor: '#EEEEEE',
    width: `calc(100%)`,
    [theme.breakpoints.up('md')]: {
      width: `calc(50%)`
    }
  },
  flex: {
    flex: 1
  },
  button: {
    color: '#fff',
    border: '1px solid #fff',
    marginRight: 10
  }
}));

interface IProps {
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleGeneralAssumptionChange: (event: any) => void;
  setIsComplete: any;
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
}

type FromAssumptionsProps = Pick<
  AssumptionsInternal,
  | 'isIslanding'
  | 'curtailCost'
  | 'isBESSRidethrough'
  | 'isOutageOnlyGenset'
  | 'fuelCost'
  | 'pGenMin'
  | 'isGenset'
  | 'hasGridOutages'
  | 'maxGensetStarts'
  | 'isMaxGensetStartsInDay'
  | 'isMinGensestDurationThreshold'
  | 'minGensetDurationThreshold'
  | 'pGenMax'
  | 'criticalLoad'
  | 'criticalLoadMaxValue'
  | 'hasCriticalLoadMaxValue'
  | 'gridOutageDates'
  | 'gridOutageIntervalData'
  | 'gensetEfficiencyItems'
  | 'isBatt'
  | 'outagesBattCyclesLimit'
  | 'forceChargeBeforeOutages'
  | 'simulationOutageOnly'
  | 'outageStartSOC'
  | 'isOutageOnlyGenset'
>;

const initialState = {
  start: moment(),
  end: moment()
};

// for selected data set
const initialProfileDataState = {
  start: moment(),
  end: moment(),
  data: []
};

const initialEfficiencyState = {
  efficiency: '',
  fuelAmount: ''
};

const MicrogridConfig: React.FC<FromAssumptionsProps & IProps> = props => {
  const classes = useStyles();
  const [outage, setOutage] = useState<{ start: Moment; end: Moment }>(initialState);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [profileData, setProfileData] = useState<any>(initialProfileDataState);
  const [efficiencyItem, setEfficiencyItem] = useState<any>(initialEfficiencyState);
  const [datesValidation, setDatesValidation] = useState({ isError: false, messageStart: '', messageEnd: '' });
  const [efficiencyErrorMessage, setEfficiencyErrorMessage] = useState('');

  useEffect(() => {
    const hasFuelCost = props.isGenset ? Boolean(props.fuelCost) : true;
    const gensetWithoutEfficiency = props.isGenset ? props.gensetEfficiencyItems.length === 0 : false;
    const complete = hasFuelCost && !gensetWithoutEfficiency;
    props.setIsComplete(complete);
  }, [props.fuelCost, props.isGenset, props.gensetEfficiencyItems]);

  const handleAdd = () => {
    const outages = props.gridOutageDates && props.gridOutageDates.length > 0 ? props.gridOutageDates : [];
    props.handleGeneralAssumptionChange({
      target: {
        name: 'gridOutageDates',
        value: [
          ...outages,
          {
            start: moment(outage.start).format('MM/DD/YYYY HH:mm'),
            end: moment(outage.end).format('MM/DD/YYYY HH:mm')
          }
        ]
      }
    });
  };

  const handleAddEfficiencyItem = () => {
    if (!efficiencyItem.efficiency || !efficiencyItem.fuelAmount) {
      setEfficiencyErrorMessage('Both efficiency and fuel amount must be defined.');
      return;
    }
    const items =
      props.gensetEfficiencyItems && props.gensetEfficiencyItems.length > 0 ? props.gensetEfficiencyItems : [];

    const updatedInfo = [
      ...items,
      {
        efficiency: efficiencyItem.efficiency,
        fuelAmount: efficiencyItem.fuelAmount
      }
    ];
    props.handleGeneralAssumptionChange({
      target: {
        name: 'gensetEfficiencyItems',
        value: updatedInfo.sort((a: any, b: any) => +a.efficiency - +b.efficiency)
      }
    });
    setEfficiencyItem(initialEfficiencyState);
  };

  const handleDeleteEfficiency = (index: number) => {
    if (props.gensetEfficiencyItems && props.gensetEfficiencyItems.length > 0) {
      const updatedEfficiencies = [...props.gensetEfficiencyItems];
      const value = [...updatedEfficiencies.slice(0, index), ...updatedEfficiencies.slice(index + 1)];
      props.handleGeneralAssumptionChange({
        target: {
          name: 'gensetEfficiencyItems',
          value
        }
      });
    }
  };

  const handleOutageIntervalData = (index: number) => {
    if (props.gridOutageDates && props.gridOutageDates.length > 0) {
      const outageDates = props.gridOutageDates[index];
      const outageKey = outageDates.start + '__' + outageDates.end;
      let outageData = [];
      if (props.gridOutageIntervalData && props.gridOutageIntervalData[outageKey]) {
        outageData = props.gridOutageIntervalData[outageKey];
      }
      const gridData = generateIntraDayProfileGrid(
        4,
        moment(outageDates.start, 'MM/DD/YYYY HH:mm'),
        moment(outageDates.end, 'MM/DD/YYYY HH:mm'),
        outageData
      );
      setProfileData({
        start: outageDates.start,
        end: outageDates.end,
        data: gridData
      });
      setOpenDrawer(true);
    }
  };

  const handleDateDelete = (index: number) => {
    if (props.gridOutageDates && props.gridOutageDates.length > 0) {
      const updatedOutages = [...props.gridOutageDates];
      const value = [...updatedOutages.slice(0, index), ...updatedOutages.slice(index + 1)];
      props.handleGeneralAssumptionChange({
        target: {
          name: 'gridOutageDates',
          value
        }
      });
    }
  };

  const handleDataSave = (changes: any) => {
    const outageKey = profileData.start + '__' + profileData.end;
    const newData = { ...props.gridOutageIntervalData };
    const profileDataCopy = { ...profileData };
    const gridData = profileDataCopy.data;
    // eslint-disable-next-line
    for (let [row, column, , newValue] of changes) {
      gridData[row][column] = newValue;
    }
    newData[outageKey] = gridData.map((item: any[]) => item[2]);
    props.handleGeneralAssumptionChange({
      target: {
        name: 'gridOutageIntervalData',
        value: newData
      }
    });
  };

  const validateDates = () => {
    const { start, end } = outage;

    if (!moment(start, 'MM-DD-YYYY hh:mm A', true).isValid()) {
      setDatesValidation({ ...datesValidation, isError: true, messageStart: 'Invalid Date Format.' });
    }

    if (!moment(end, 'MM-DD-YYYY hh:mm A', true).isValid()) {
      setDatesValidation({ ...datesValidation, isError: true, messageEnd: 'Invalid Date Format.' });
    }

    if (start.valueOf() > end.valueOf()) {
      setDatesValidation({
        ...datesValidation,
        isError: true,
        messageStart: 'Start date should be prior to end date.'
      });
    }
  };

  const handleDateChange = (name: string) => (date: MaterialUiPickersDate) => {
    setOutage({ ...outage, [name]: date });
    setDatesValidation({ isError: false, messageStart: '', messageEnd: '' });
  };

  const handleEfficiencyItemChange = (prop: 'efficiency' | 'fuelAmount') => (e: any) => {
    setEfficiencyItem({ ...efficiencyItem, [prop]: e.target.value });
    setEfficiencyErrorMessage('');
  };

  const handleToggleUseGenSet = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    props.handleToggle(event, checked);
    if (!props.isOutageOnlyGenset) {
      props.handleAssumptionUpdateByKey('isOutageOnlyGenset')(true);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Drawer
        classes={{ paper: classes.sidenav }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <section style={{ padding: 20, textAlign: 'right' }}>
          <Button variant="contained" color="secondary" style={{ color: '#fff' }} onClick={() => setOpenDrawer(false)}>
            Close
          </Button>
        </section>
        <ProfileTable onUpdateData={handleDataSave} data={profileData.data} />
      </Drawer>

      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={props.handleToggle} checked={props.hasGridOutages} name="hasGridOutages" />}
        label="Simulate Grid Outages"
      />
      {props.hasGridOutages && (
        <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
          <Grid container alignItems="center">
            <Grid item md={3} xs={12}>
              <KeyboardDateTimePicker
                onFocus={() => {}}
                onBlur={validateDates}
                autoOk
                error={datesValidation.isError}
                helperText={datesValidation.messageStart}
                onError={console.log}
                label="Start Date"
                value={outage.start}
                style={{ color: '#fff', marginRight: 16 }}
                onChange={handleDateChange('start')}
                format="MM-DD-YYYY hh:mm A"
                minutesStep={15}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <KeyboardDateTimePicker
                onFocus={() => {}}
                onBlur={validateDates}
                autoOk
                label="End Date"
                value={outage.end}
                error={datesValidation.isError}
                helperText={datesValidation.messageEnd}
                onError={console.log}
                minDate={outage.start}
                format="MM-DD-YYYY hh:mm A"
                style={{ color: '#fff', marginRight: 16 }}
                onChange={handleDateChange('end')}
                minutesStep={15}
              />
            </Grid>
            <Grid md={5} xs={12}>
              <Button color="primary" variant="contained" onClick={handleAdd} disabled={datesValidation.isError}>
                Add
              </Button>
            </Grid>
          </Grid>
          {props.gridOutageDates && props.gridOutageDates.length > 0 && (
            <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee', marginTop: 16 }}>
              <List dense={true} style={{ maxWidth: 500 }}>
                {props.gridOutageDates.map((date: { start: string; end: string }, index: number) => {
                  const { start, end } = date;
                  const dataKey = start + '__' + end;
                  const iconColor = props.gridOutageIntervalData[dataKey] ? 'green' : 'inherit';
                  const tooltipText = props.gridOutageIntervalData[dataKey]
                    ? 'This outage has a critical load override entered'
                    : 'Outage Range';
                  return (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <Tooltip title={tooltipText}>
                          <EventIcon style={{ color: iconColor }} />
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <div>
                            {moment(start).format('MM-DD-YYYY hh:mm a')} - {moment(end).format('MM-DD-YYYY hh:mm a')}
                          </div>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Delete" onClick={() => handleDateDelete(index)}>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="Delete" onClick={() => handleOutageIntervalData(index)}>
                          <ListIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              {props.gridOutageDates && props.gridOutageDates.length == 1 && (
                <div>
                  <FormControlLabel
                    className={classes.block}
                    control={
                      <Switch
                        onChange={props.handleToggle}
                        checked={props.simulationOutageOnly}
                        name="simulationOutageOnly"
                      />
                    }
                    label="Only Simulate This Outage"
                  />
                  {props.isBatt && props.simulationOutageOnly && (
                    <TextField
                      label="Battery Outage Starting State of Charge"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                      key="outageStartSOC"
                      name="outageStartSOC"
                      value={props.outageStartSOC}
                      onChange={props.handleGeneralAssumptionChange}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      margin="normal"
                    />
                  )}
                </div>
              )}
            </div>
          )}

          <TextField
            label="Critical Load"
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            key="criticalLoad"
            name="criticalLoad"
            style={{ marginTop: 16 }}
            value={props.criticalLoad}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />

          <FormControlLabel
            className={classes.block}
            control={
              <Switch
                onChange={props.handleToggle}
                checked={props.hasCriticalLoadMaxValue}
                name="hasCriticalLoadMaxValue"
              />
            }
            label="Set Maximum Critical Load"
          />

          {props.hasCriticalLoadMaxValue && (
            <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
              <TextField
                label="Critical Load Max Value"
                InputProps={{
                  startAdornment: <InputAdornment position="start">kW</InputAdornment>
                }}
                key="criticalLoadMaxValue"
                name="criticalLoadMaxValue"
                style={{ marginTop: 16 }}
                value={props.criticalLoadMaxValue}
                onChange={props.handleGeneralAssumptionChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
            </div>
          )}

          <TextField
            label="Load Curtailment cost (USD/kWh)"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            key="curtailCost"
            name="curtailCost"
            value={props.curtailCost}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />

          {props.isBatt && (
            <div>
              {props.outagesBattCyclesLimit != undefined && (
                <TextField
                  label="Battery Cycles Limit"
                  key="outagesBattCyclesLimit"
                  name="outagesBattCyclesLimit"
                  value={props.outagesBattCyclesLimit}
                  onChange={props.handleGeneralAssumptionChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  margin="normal"
                  type="number"
                  error={!(props.outagesBattCyclesLimit > 0)}
                  helperText={!(props.outagesBattCyclesLimit > 0) && 'It should be greater than zero.'}
                />
              )}
              <FormControlLabel
                className={classes.block}
                control={
                  <Switch onChange={props.handleToggle} checked={props.isBESSRidethrough} name="isBESSRidethrough" />
                }
                label="Scale Battery Capacity for Critial Load"
              />
              {/* <FormControlLabel
                className={classes.block}
                control={
                  <Switch
                    onChange={props.handleToggle}
                    checked={props.forceChargeBeforeOutages}
                    name="forceChargeBeforeOutages"
                  />
                }
                label="Force Battery to Charge 100% before Outages"
              /> */}
            </div>
          )}
        </div>
      )}
      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={handleToggleUseGenSet} checked={props.isGenset} name="isGenset" />}
        label="Use Genset"
      />
      {props.isGenset && (
        <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
          <FormControlLabel
            className={classes.block}
            control={
              <Switch
                onChange={props.handleToggle}
                checked={props.isMaxGensetStartsInDay}
                name="isMaxGensetStartsInDay"
              />
            }
            label="Set Maximum Starts in a Day"
          />

          {props.isMaxGensetStartsInDay && (
            <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
              <TextField
                label="Maximum starts in a day"
                key="maxGensetStarts"
                name="maxGensetStarts"
                value={props.maxGensetStarts}
                onChange={props.handleGeneralAssumptionChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
            </div>
          )}

          <FormControlLabel
            className={classes.block}
            control={
              <Switch
                onChange={props.handleToggle}
                checked={props.isMinGensestDurationThreshold}
                name="isMinGensestDurationThreshold"
              />
            }
            label="Set Minimum Duration Threshold (minutes)"
          />

          {props.isMinGensestDurationThreshold && (
            <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
              <TextField
                label="Minimum Duration Threshold (minutes)"
                key="minGensetDurationThreshold"
                name="minGensetDurationThreshold"
                value={props.minGensetDurationThreshold}
                onChange={props.handleGeneralAssumptionChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
              />
            </div>
          )}

          <FormControlLabel
            className={classes.block}
            control={<Switch checked={props.isOutageOnlyGenset} name="isOutageOnlyGenset" />}
            label="Only Use In Outages"
          />

          <TextField
            label="Minimum Genset Power in kW"
            key="pGenMin"
            name="pGenMin"
            value={props.pGenMin}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Maximum Genset Power in kW"
            key="pGenMax"
            name="pGenMax"
            value={props.pGenMax}
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Fuel Cost (USD/gal)"
            key="fuelCost"
            value={props.fuelCost}
            name="fuelCost"
            onChange={props.handleGeneralAssumptionChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
            error={!props.fuelCost}
            helperText={!props.fuelCost && 'Should be greater than zero'}
            type="number"
          />

          <Typography variant="h6" style={{ marginTop: 32, marginBottom: 16 }}>
            Genset Efficiencies (required):
          </Typography>

          <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Efficiency (%)"
                  type="number"
                  key="efficiency"
                  name="efficiency"
                  value={efficiencyItem.efficiency}
                  onChange={handleEfficiencyItemChange('efficiency')}
                  InputLabelProps={{ shrink: true }}
                  style={{ color: '#fff', paddingRight: 16 }}
                  fullWidth
                  margin="normal"
                  helperText={efficiencyErrorMessage}
                  error={efficiencyErrorMessage.length > 0}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Fuel (Gallons / Hour)"
                  key="fuelAmount"
                  type="number"
                  name="fuelAmount"
                  value={efficiencyItem.fuelAmount}
                  onChange={handleEfficiencyItemChange('fuelAmount')}
                  InputLabelProps={{ shrink: true }}
                  style={{ color: '#fff', paddingRight: 16 }}
                  fullWidth
                  margin="normal"
                  error={efficiencyErrorMessage.length > 0}
                  helperText={efficiencyErrorMessage}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button color="primary" variant="contained" onClick={handleAddEfficiencyItem}>
                  Add
                </Button>
              </Grid>
            </Grid>
            <List dense={true} style={{ maxWidth: 500 }}>
              {props.gensetEfficiencyItems &&
                props.gensetEfficiencyItems.length > 0 &&
                props.gensetEfficiencyItems.map((item: any, index: number) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText primary={item.efficiency + '% | ' + item.fuelAmount + ' gal/hr'} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Delete" onClick={() => handleDeleteEfficiency(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </div>
      )}
    </div>
  );
};

export default MicrogridConfig;
