import React, { useState, useEffect } from 'react';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
// REACT IMPORTS
import { Portfolio } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    paddingTop: theme.spacing(3)
  },
  flex: {
    flex: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 0 25px 0',
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700,
    maxWidth: 1000,
    border: '1px solid rgb(234, 234, 234)',
    '& td, & th': {
      padding: '0 6px',
      textAlign: 'center',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      fontSize: '11px',
      color: '#333'
    },
    '& tr': {
      height: 32
    },
    '& tr>td:first-child, & tr:first-child>th:first-child': {
      fontWeight: '500',
      color: '#17173c',
      verticalAlign: 'middle',
      backgroundColor: '#ebf6ff'
    },
    '& tr:first-child th': {
      whiteSpace: 'normal',
      fontWeight: '500',
      color: '#111',
      backgroundColor: '#e8f1ee',
      lineHeight: '1.2em',
      verticalAlign: 'top',
      padding: '9px',
      fontSize: '11px'
    }
  },
  statusQuoHeader: {
    backgroundColor: '#ccc',
    textAlign: 'center',
    color: '#000'
  },
  scenarioHeader: {
    backgroundColor: '#0071FF',
    textAlign: 'center',
    color: '#fff'
  },
  savingsHeader: {
    backgroundColor: '#333',
    textAlign: 'center',
    color: '#fff'
  },
  highlight: {
    backgroundColor: 'rgba(255, 255, 0, 0.5)',
    fontWeight: 'bold'
  }
}));

interface IProps {
  portfolio: Portfolio;
}

const PortfolioProformaTableNemA: React.FC<IProps> = ({ portfolio }) => {
  const [data, setData] = useState({
    solarEnergySavings: 0,
    solarDemandSavings: 0,
    storageEnergySavings: 0,
    storageDemandSavings: 0,
    tariffEnergySavings: 0,
    tariffDemandSavings: 0
  });
  const classes = useStyles();

  useEffect(() => {
    if (Array.isArray(portfolio.results.portfolio_meters) && portfolio.results.portfolio_meters.length > 0) {
      const solarEnergySavings = portfolio.results.portfolio_meters.reduce((total, meter) => {
        return (
          total +
          (meter.results.solar_energy_savings_sum ?? 0) +
          (meter.results.solar_nem_revenue_sum ?? meter.results.nem_credit_revenue_sum ?? 0)
        );
      }, 0);
      const solarDemandSavings = portfolio.results.portfolio_meters.reduce(
        (total, meter) => total + (meter.results.solar_demand_savings_sum ?? 0),
        0
      );

      const storageEnergySavings = portfolio.results.portfolio_meters.reduce(
        (total, meter) =>
          total + (meter.results.battery_energy_savings_sum ?? 0) + (meter.results.battery_nem_revenue_sum ?? 0),
        0
      );

      const storageDemandSavings = portfolio.results.portfolio_meters.reduce(
        (total, meter) => total + (meter.results.battery_demand_savings_sum ?? 0),
        0
      );

      const tariffEnergySavings = portfolio.results.portfolio_meters.reduce(
        (total, meter) => total + (meter.results?.tariff_energy_savings_sum ?? 0),
        0
      );

      const tariffDemandSavings = portfolio.results.portfolio_meters.reduce(
        (total, meter) => total + (meter.results?.tariff_demand_savings_sum ?? 0),
        0
      );

      setData({
        solarEnergySavings,
        solarDemandSavings,
        storageEnergySavings,
        storageDemandSavings,
        tariffEnergySavings,
        tariffDemandSavings
      });
    }
  }, [portfolio]);

  const formatRevenue = (value: number | string): string => {
    if (value == undefined || (typeof value === 'number' && isNaN(value))) return '-';
    if (typeof value == 'string') return value;
    return Math.round(value).toLocaleString('en-US');
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} padding="default">
          <TableHead>
            <TableRow>
              <TableCell>PROFORMA INPUTS</TableCell>
              <TableCell>Energy Savings</TableCell>
              <TableCell>Demand Savings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Solar Results</TableCell>
              <TableCell>{formatRevenue(data.solarEnergySavings)}</TableCell>
              <TableCell>{formatRevenue(data.solarDemandSavings)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Storage Results</TableCell>
              <TableCell>{formatRevenue(data.storageEnergySavings)}</TableCell>
              <TableCell>{formatRevenue(data.storageDemandSavings)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tariff Switch Results</TableCell>
              <TableCell>{formatRevenue(data.tariffEnergySavings)}</TableCell>
              <TableCell>{formatRevenue(data.tariffDemandSavings)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PortfolioProformaTableNemA;
