import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateCleanPeakStandardOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];

  chartOptions.push({
    key: 'Num CPEC Produced',
    label: 'Num CPEC Produced (MWh)',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });

  chartOptions.push({
    key: 'Average kW Discharged CPS',
    label: 'Average kW Discharged CPS',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Average kWh Discharged CPS',
    label: 'Average kWh Discharged CPS',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'CPS_CREDIT_VALUE',
    label: 'CPS Credit Value ($)',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });

  return chartOptions;
};
