import { AxiosError } from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { getLoadSensitivityAnalysis, resetLoadSensitivityAnalysis, createLoadSensitivityAnalysis } from '../actions';
import { Lsa } from '../types';

interface LSAPayload {
  proposalId: string;
  scenarioId: string;
  cases: { isPeakMagnitude: boolean; isPeakFrequency: boolean; isPeakDuration: boolean };
}

export function useLSAAnalysis({ proposalId, scenarioId }: { proposalId: string; scenarioId: string }) {
  return useQuery<Lsa, AxiosError>(
    ['LSAAnalysis', proposalId, scenarioId],
    () => getLoadSensitivityAnalysis(proposalId, scenarioId),
    { enabled: !!proposalId && !!scenarioId }
  );
}

export function useLSAAnalysisReset({ proposalId, scenarioId }: { proposalId: string; scenarioId: string }) {
  const queryclient = useQueryClient();
  return useMutation<string, AxiosError, { proposalId: string; scenarioId: string }, any>(
    'LSAAnalysisReset',
    value => resetLoadSensitivityAnalysis(value),
    {
      onSuccess: () => {
        queryclient.invalidateQueries(['LSAAnalysis', proposalId, scenarioId]);
      },
      retry: 3
    }
  );
}

export function useCreateLSA() {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, LSAPayload, string>(values => createLoadSensitivityAnalysis(values), {
    retry: 2,
    onSuccess: () => {
      queryClient.invalidateQueries('LSAAnalysis');
    }
  });
}
