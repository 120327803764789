import React from 'react';
import moment from 'moment';

// MATERIAL UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { InsertDriveFile } from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSelector, useDispatch } from 'react-redux';
import ForecastTimeSeries from './ForecastTimeSeries';
// import ForecastSummaryTable from './ForecastSummaryTable';
import { trainForecasting } from '../../actions/explore';
import { StoreState } from '../../reducers';
import { exportCSVFile } from '../../utility/General';
import { ForecastStatus } from '../../types';
import { assertUnreachable } from '../../utils/assertions';
import { useGetForecast } from '../../queries/forecast';
import { getProposal } from '../../actions';

const ForecastSummary: React.FC = () => {
  const dispatch = useDispatch();

  const proposal = useSelector((state: StoreState) => state.proposal);

  const { data: forecastingInfo, status, error } = useGetForecast(proposal.public_id);

  const handleCSVExport = () => {
    if (status === 'success' && forecastingInfo) {
      let headers = ['Timestamp', 'Local Datetime', 'Actual Load', 'ForecastedLoad'];
      const { load, forecast } = forecastingInfo;
      let downloadData: [number, string, number, number][] = [];
      if (load && forecast) {
        load.forEach((timestamp: number, index: number) => {
          const row: [number, string, number, number] = [
            timestamp,
            moment(timestamp).format('YYYY-MM-DD HH:mm z'),
            load[index],
            forecast[index]
          ];
          downloadData.push(row);
        });
      }
      exportCSVFile(headers, downloadData, `Load Forecast-${proposal.name}`);
    }
  };

  const renderToolbarButtons = (forecastStatus: ForecastStatus) => {
    switch (forecastStatus) {
      case 'complete':
        return (
          <>
            {status === 'success' && forecastingInfo?.overall_summary ? (
              <>
                <p>RMSE: {forecastingInfo!.overall_summary.toFixed(2)} kWh </p>
                <Tooltip title="Download CSV">
                  <IconButton onClick={handleCSVExport} style={{ marginLeft: 16 }}>
                    <InsertDriveFile />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </>
        );
      case 'error':
      case 'not run':
        return (
          <Button
            variant="contained"
            onClick={() =>
              dispatch(
                trainForecasting(proposal.public_id, {
                  onSuccess: () => {
                    dispatch(getProposal(proposal.public_id));
                  }
                })
              )
            }
            size="small"
          >
            Generate Forecast
          </Button>
        );
      case undefined:
      case null:
      case 'training':
        return null;

      default:
        assertUnreachable(forecastStatus);
        break;
    }
  };

  return (
    <div>
      <Paper>
        <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
          <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
            Forecasting Overview
          </Typography>
          {renderToolbarButtons(proposal?.forecasting_status)}
        </Toolbar>

        <div>
          {(forecastStatus => {
            switch (forecastStatus) {
              case null:
              case undefined:
                return (
                  <Alert severity="error">
                    <AlertTitle>Not available</AlertTitle>
                    Forecasting is not available for this proposal.
                  </Alert>
                );
              case 'training':
                return (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Alert severity="info">
                        <AlertTitle>Check back soon</AlertTitle>
                        Forecasting Model is currently being trained.
                      </Alert>
                    </Grid>
                  </Grid>
                );
              case 'not run':
                return null;
              case 'complete':
                switch (status) {
                  case 'idle':
                    return null;
                  case 'loading':
                    return (
                      <div style={{ textAlign: 'center', padding: 25 }}>
                        <CircularProgress color="secondary" size={50} />
                      </div>
                    );
                  case 'error':
                    return (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        There was an error while fetching this forecast —{' '}
                        <strong>{`${error ? error.message! : ''}`}</strong>
                      </Alert>
                    );
                  case 'success':
                    return (
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <ForecastTimeSeries data={forecastingInfo!.data} />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <ForecastSummaryTable summary={forecastingInfo!.hourly_summary} />
                        </Grid> */}
                      </Grid>
                    );
                  default:
                    assertUnreachable(status);
                }
                break;
              case 'error':
                return (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Error while fetching the forecast —{' '}
                    <strong>Please try again or reach out for technical support if the error persists.</strong>
                  </Alert>
                );
              default:
                assertUnreachable(forecastStatus);
            }
          })(proposal?.forecasting_status)}
        </div>
      </Paper>
    </div>
  );
};

export default ForecastSummary;
