import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AssumptionsInternal,
  TariffOverview,
  Technologies,
  ScenarioResults,
  OperationsData,
  ResourceOverviewChartOption as ChartOption
} from '../../../types';
import * as R from 'ramda';
import moment from 'moment';

// MATERIAL UI IMPORTS
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Settings from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
// import DeleteForever from '@material-ui/icons/DeleteForever';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import SwapVertIcon from '@material-ui/icons/SwapVert';
// import Edit from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// OTHER IMPORTS
import { getTOUBands } from '../../../utility/TOU';
import { getGAEventsDropdown } from '../../../actions/events';
import { exportCSVFile } from '../../../utility/General';
import { applyLayout } from './utils/layoutConfig';
import ChartSettings from './ChartSettings';
import AccessControl from '../../../utility/AccessControl';
import PeakEventChart from './PeakEventChart';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../../custom-plotly.js';
import { StoreState } from '../../../reducers';
import { Layout, PlotData, Annotations, Config, Shape } from 'plotly.js';
import { assertIsDefined, assertIsTypedArray, isNumber } from '../../../utils/assertions';
import { useAnnotations, useGAEvents, useChartConfig, useMaxPeaks } from './hooks';
const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    top: '45%',
    left: '43%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2)
    //outline: "none"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  gaEventSelect: {
    marginBottom: 12,
    marginRight: 8,
    marginLeft: 8,
    minWidth: 140
  },
  modeIcon: {
    marginRight: 8,
    marginLeft: 8
  }
}));

interface IState {
  months: string[];
  data: Partial<PlotData>[];
  selected: string;
  open: boolean;
  chartOptions: ChartOption[];
  editChartOptions: ChartOption[];
  customRangeMin: string;
  customRangeMax: string;
  outageSelected: { start: string; end: string };
}

interface IProps {
  isSQ: boolean;
  selectedYear: any;
  selectedMonths: any;
  assumptions: AssumptionsInternal;
  statusQuoResults: ScenarioResults;
  scenarioData: Record<string, OperationsData>;
  scenarioResults: ScenarioResults;
  energyCapacity: number;
  technologies: Technologies;
  frequency: '15min' | '60min';
  tariffOverview: TariffOverview | null;
  isGA: boolean;
}

const ResourceOverviewChart: React.FC<IProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const events = useSelector((state: StoreState) => state.events);

  const [state, setState] = useState<IState>({
    months: [],
    data: [],
    selected: '',
    open: false,
    chartOptions: [],
    editChartOptions: [],
    customRangeMin: '',
    customRangeMax: '',
    outageSelected: { start: '', end: '' }
  });

  const { annotations, newAnnotation, changeAnnotation, closeAnnotation, displayAnnotation } = useAnnotations(
    state.selected
  );

  const { GAEvents, GAEventSelected, GAEventsPredicted, changeGAEventSelected } = useGAEvents(
    state.selected,
    events,
    props.assumptions.isGA,
    props.scenarioData
  );

  const { chartConfig, setChartConfig, toggleChartConfig } = useChartConfig();

  const { maxPeaks } = useMaxPeaks(
    props.statusQuoResults,
    props.scenarioResults,
    state.data,
    props.scenarioData,
    state.selected
  );

  useEffect(() => {
    const months = Object.keys(props.scenarioData).map(dateKey => props.scenarioData[dateKey].month);
    const chartOptions = buildChartOptions();
    const data = buildMonthlyData(months[0], false, chartOptions);

    dispatch(getGAEventsDropdown());
    setState(prevState => ({
      ...prevState,
      months,
      data,
      chartOptions,
      editChartOptions: R.clone(chartOptions),
      selected: months[0]
    }));
  }, [props.scenarioData]);

  useEffect(() => {
    if (GAEventSelected && typeof GAEventSelected === 'string') {
      setState(prevState => ({ ...prevState, selected: GAEventSelected.slice(0, 7) }));
    }
  }, [GAEventSelected]);

  const handleValueChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleShowChartItem = (item: ChartOption) => () => {
    const updatedEditChartOptions = state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    if (updateIndex > -1) {
      updatedEditChartOptions[updateIndex].include = !updatedEditChartOptions[updateIndex].include;
    }
    setState({ ...state, editChartOptions: updatedEditChartOptions });
  };

  const handleStackChartItem = (item: ChartOption) => () => {
    const updatedEditChartOptions = state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    if (updateIndex > -1) {
      updatedEditChartOptions[updateIndex].stacked = !updatedEditChartOptions[updateIndex].stacked;
    }
    setState({ ...state, editChartOptions: updatedEditChartOptions });
  };

  const handleChartLineType = (item: ChartOption) => () => {
    const updatedEditChartOptions = state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    updatedEditChartOptions[updateIndex].fill = updatedEditChartOptions[updateIndex].fill ? null : 'tozeroy';
    setState({ ...state, editChartOptions: updatedEditChartOptions });
  };

  const handleChartTextUpdate = (item: ChartOption) => (event: any) => {
    const updatedEditChartOptions = state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    updatedEditChartOptions[updateIndex][event.target.name] = event.target.value;
    setState({ ...state, editChartOptions: updatedEditChartOptions });
  };

  const handleColorChangeComplete = (selectedColor: string, selectedIndex: number) => {
    const updatedEditChartOptions = state.editChartOptions.slice(0);
    updatedEditChartOptions[selectedIndex]['color'] = selectedColor;
    updatedEditChartOptions[selectedIndex]['fillcolor'] = selectedColor + '45';
    setState({ ...state, editChartOptions: updatedEditChartOptions });
  };

  const handleMoveItem = (upDirection: boolean, item: ChartOption) => () => {
    const updatedEditChartOptions = state.editChartOptions.slice(0);
    const updateIndex = updatedEditChartOptions.findIndex(currentItem => item.name === currentItem.name);
    if (updateIndex > 0 && upDirection) {
      const tempOptionHolder = updatedEditChartOptions.splice(updateIndex, 1)[0];
      updatedEditChartOptions.splice(updateIndex - 1, 0, tempOptionHolder);
    } else if (updateIndex !== updatedEditChartOptions.length - 1 && !upDirection) {
      const tempOptionHolder = updatedEditChartOptions.splice(updateIndex, 1)[0];
      updatedEditChartOptions.splice(updateIndex + 1, 0, tempOptionHolder);
    }
    setState({ ...state, editChartOptions: updatedEditChartOptions });
  };

  const buildChartOptions = (): ChartOption[] => {
    const chartOptions: ChartOption[] = [
      {
        fill: null,
        name: 'Current Demand',
        label: 'Current Demand',
        color: '#222222',
        fillcolor: '34,34,34',
        include: true,
        opacity: 1,
        stacked: false
      }
    ];
    if (props.technologies && props.technologies.isPV && props.technologies.isBatt) {
      chartOptions.push({
        fill: 'tozeroy',
        name: 'Load Net Solar',
        label: 'Net Demand Solar',
        color: '#fe5815',
        fillcolor: '254, 88, 21',
        include: true,
        opacity: 0.5,
        stacked: false
      });
    }
    chartOptions.push({
      fill: 'tozeroy',
      name: 'Net Demand',
      label: 'Net Demand',
      color: '#005bbb',
      fillcolor: '0, 91, 187',
      include: true,
      opacity: 0.5,
      stacked: false
    });

    if (props.technologies && props.technologies.isBatt) {
      chartOptions.push({
        fill: null,
        name: 'Battery SOC',
        label: 'Battery SOC',
        color: '#f00',
        fillcolor: '255, 0, 0',
        include: false,
        opacity: 1,
        stacked: false
      });
    }

    if (props.technologies && props.technologies.isEV) {
      chartOptions.push({
        fill: null,
        name: 'EV Power',
        label: 'EV Power',
        color: '#800080',
        fillcolor: '255, 160, 47',
        include: true,
        opacity: 1,
        stacked: false
      });
    }
    if (props.technologies && props.technologies.isGenset) {
      chartOptions.push({
        fill: null,
        name: 'Genset Power',
        label: 'Genset Power',
        color: '#DCDC2B',
        fillcolor: '255, 160, 47',
        include: true,
        opacity: 1,
        stacked: false
      });
      chartOptions.push({
        fill: null,
        name: 'Islanded Load',
        label: 'Islanded Load',
        color: '#2b95dc',
        fillcolor: '255, 160, 47',
        include: true,
        opacity: 1,
        stacked: false
      });
    }
    if (props.technologies && props.technologies.isBatt) {
      if (props.assumptions.hasGridOutages) {
        chartOptions.push({
          fill: 'tozeroy',
          name: 'Battery Charge',
          label: 'Battery Charge',
          color: '#376B20',
          fillcolor: '55, 107, 32',
          include: true,
          opacity: 0.5,
          stacked: false
        });
        chartOptions.push({
          fill: 'tozeroy',
          name: 'Battery Discharge',
          label: 'Battery Discharge',
          color: '#7DAE69',
          fillcolor: '125, 174, 105',
          include: true,
          opacity: 0.5,
          stacked: false
        });
      } else {
        chartOptions.push({
          fill: 'tozeroy',
          name: 'Battery Power',
          label: 'Battery Power',
          color: '#509E2F',
          fillcolor: '80, 158, 47',
          include: true,
          opacity: 0.5,
          stacked: false
        });
      }
    }
    if (props.technologies && props.technologies.isIslanding) {
      chartOptions.push({
        fill: null,
        name: 'Load Curtailment',
        label: 'Load Curtailment',
        color: 'red',
        fillcolor: '255, 160, 47',
        include: true,
        opacity: 1,
        stacked: false
      });
    }
    if (props.technologies && (props.technologies.isPV || props.assumptions.hasExistingSolar)) {
      chartOptions.push({
        fill: null,
        name: 'PV Power',
        label: 'PV Power',
        color: '#ffa02f',
        fillcolor: '255, 160, 47',
        include: true,
        opacity: 1,
        stacked: false
      });
      chartOptions.push({
        fill: null,
        name: 'PV Curtailed',
        label: 'PV Curtailment',
        color: '#FFC0CB',
        fillcolor: '255, 192, 203',
        include: false,
        opacity: 1,
        stacked: false
      });
      if (props.assumptions.hasGridOutages) {
        chartOptions.push({
          fill: null,
          name: 'PV Used Power',
          label: 'PV Used Power',
          color: '#ffa02f',
          fillcolor: '255, 160, 47',
          include: false,
          opacity: 1,
          stacked: false
        });
      }
    }

    chartOptions.push({
      fill: 'tozeroy',
      name: 'Net Export',
      label: 'Net Export',
      color: '#800080',
      fillcolor: '128, 0, 128',
      include: false,
      opacity: 0.7,
      stacked: false
    });
    return chartOptions;
  };

  const handleClose = () => {
    setState({ ...state, open: false, editChartOptions: R.clone(state.chartOptions) });
  };

  const handleSuccessClose = () => {
    let isLast = false;
    if (state.months.findIndex(month => month === state.selected) === state.months.length - 1) {
      isLast = true;
    }
    const data = buildMonthlyData(state.selected, isLast, state.editChartOptions);

    setState({ ...state, data: data, chartOptions: R.clone(state.editChartOptions), open: false });
  };

  const handleOpen = () => {
    setState({ ...state, open: true });
  };

  const handleChangeOutage = (event: any) => {
    const { value } = event.target;

    const outageSelected = props.assumptions.gridOutageDates.find(el => el.start === value);

    const month = state.months.find(month => month.includes(moment(outageSelected.start).format('YYYY-MM')));
    assertIsDefined(month, "Can't match the month selection with an existing outage.");
    let isLast = false;
    if (state.months.findIndex(m => m === month) === state.months.length - 1) {
      isLast = true;
    }

    const updatedOptions = applyLayout('microgrid', state.editChartOptions, buildChartOptions);

    let data = buildMonthlyData(month, isLast, updatedOptions);

    // handle case where the outage spread over two months
    if (!moment(outageSelected.start).isSame(outageSelected.end, 'month')) {
      const nextMonth = state.months.find(month => month.includes(moment(outageSelected.end).format('YYYY-MM')));
      assertIsDefined(nextMonth, "Can't match the month selection with the outage end date.");

      const nexMonthData = buildMonthlyData(nextMonth, isLast, state.chartOptions);
      nexMonthData.forEach((el, index) => {
        if (data[index]?.x && data[index]?.y) {
          const updatedXS = [...data[index].x!, ...el.x!];
          const updatedYS = [...data[index].y!, ...el.y!];
          assertIsTypedArray(updatedXS, isNumber);
          assertIsTypedArray(updatedYS, isNumber);
          data[index].x = updatedXS;
          data[index].y = updatedYS;
        }
      });
    }
    setState({
      ...state,
      outageSelected,
      data,
      selected: month,
      chartOptions: updatedOptions,
      editChartOptions: updatedOptions
    });

    setChartConfig({ ...chartConfig, flipChargeDischarge: true, chartLayout: 'microgrid', showPeak: false });
  };

  const handleChange = (event: any) => {
    let isLast = false;
    if (state.months.findIndex(month => month === event.target.value) === state.months.length - 1) {
      isLast = true;
    }
    const { name, value } = event.target;
    const newLayout = chartConfig.chartLayout === 'microgrid' ? 'none' : chartConfig.chartLayout;

    const updatedOptions = applyLayout(newLayout, state.editChartOptions, buildChartOptions);

    const data = buildMonthlyData(value, isLast, updatedOptions);

    let updatedState = {
      ...state,
      [name]: value,
      data,
      chartOptions: updatedOptions,
      editChartOptions: updatedOptions
    };
    setChartConfig({ ...chartConfig, chartLayout: newLayout, flipChargeDischarge: false });

    if (name === 'selected') {
      updatedState = { ...updatedState, outageSelected: { start: '', end: '' } };
    }
    setState(updatedState);
  };

  // key format will be: 'yyyy-mm' for monthly  or 'yyyy-mm-dd' for billing cycle
  const buildMonthlyData = (key: string, isLast: boolean, chartOptions: ChartOption[]): Partial<PlotData>[] => {
    const keyIndex = Object.keys(props.scenarioData).findIndex(dataKey => dataKey === key);
    let step = props.frequency == '60min' ? 1 : 4;
    const daysInMonth = props.scenarioData[key].vecLoad.length / (24 * step);
    const keyParts = key.split('-');
    if (keyParts[1] === '0') {
      keyParts[1] = '12';
    }
    assertIsDefined(props.scenarioResults);
    const scenarioMonthlyData = props.scenarioData[key];
    const startDate = moment(props.scenarioResults['Months'][keyIndex], 'MM/DD/YYYY').format('YYYY-MM-DD');
    let currentDate;

    let minuteStepSize = 15;
    if (props.frequency == '60min') {
      minuteStepSize = 60;
    }
    if (daysInMonth * 24 * step === scenarioMonthlyData.vecLoad.length || isLast) {
      currentDate = moment(startDate);
    } else {
      const stepsForwardFromStart = daysInMonth * 24 * step - scenarioMonthlyData.vecLoad.length;
      currentDate = moment(startDate).add(minuteStepSize * stepsForwardFromStart, 'minutes');
    }
    const x: number[] = [];
    for (let i = 0; i < scenarioMonthlyData.vecLoad.length; i++) {
      x.push(currentDate.valueOf());
      currentDate.add(minuteStepSize, 'minutes');
    }
    const data: Partial<PlotData>[] = [];

    chartOptions.forEach(option => {
      if (option.include) {
        const baseData: Partial<PlotData> = {
          visible: true,
          hoverinfo: chartConfig.hideHoverInfo ? 'none' : 'all',
          type: 'scatter',
          mode: 'lines',
          name: option.label,
          x: x,
          y: buildChartDataStream(option.name, scenarioMonthlyData),
          line: { color: option.color, width: 1 }
        };
        if (option.fill) {
          baseData.fill = option.fill;
          baseData.fillcolor = 'rgba(' + option.fillcolor + ',' + option.opacity + ')';
        }
        if (option.stacked) {
          baseData.stackgroup = 'stacked';
          baseData.fill = 'tonexty';
        }
        if (option.name === 'Battery SOC') {
          baseData.yaxis = 'y2';
          baseData.line = { ...baseData.line, dash: 'dot' };
        }
        data.push(baseData);
      }
    });
    return data.reverse();
  };

  const buildChartDataStream = (key: string, scenarioMonthlyData: OperationsData) => {
    switch (key) {
      case 'Current Demand':
        return scenarioMonthlyData?.vecLoad ?? [];
      case 'PV Power':
        return scenarioMonthlyData?.vecPInv ?? [];
      case 'PV Used Power':
        return scenarioMonthlyData?.vecPInv && scenarioMonthlyData?.pv_curtail
          ? scenarioMonthlyData.vecPInv.flatMap(value1 =>
              (scenarioMonthlyData.pv_curtail as number[]).map(value2 => value1 - value2)
            )
          : [];
      case 'Genset Power':
        return scenarioMonthlyData?.vecPGen ?? [];
      case 'Load Curtailment':
        return scenarioMonthlyData?.vecPCurtail ?? [];
      case 'PV Curtailed':
        return scenarioMonthlyData?.pv_curtail ?? [];
      case 'EV Power':
        return scenarioMonthlyData?.vecPev ? scenarioMonthlyData.vecPev.map(value => -1 * value) : [];
      case 'Load Net Solar':
        return scenarioMonthlyData?.vecLoad && scenarioMonthlyData?.vecPInv
          ? scenarioMonthlyData.vecLoad.map((value, index) => +value - +scenarioMonthlyData.vecPInv[index])
          : [];
      case 'Battery Power':
        return scenarioMonthlyData?.vecPBatt
          ? scenarioMonthlyData.vecPBatt.map(value => (chartConfig.flipChargeDischarge ? value : -1 * value))
          : [];
      case 'Battery Charge':
        return scenarioMonthlyData?.vecPBatt
          ? scenarioMonthlyData.vecPBatt
              .map(value => (value <= 0 ? value : 0))
              .map((value, index) => (chartConfig.flipChargeDischarge ? value : -1 * value))
          : [];
      case 'Battery Discharge':
        return scenarioMonthlyData.vecPBatt
          ? scenarioMonthlyData.vecPBatt
              .map(value => (value >= 0 ? value : 0))
              .map((value, index) => (chartConfig.flipChargeDischarge ? value : -1 * value))
          : [];
      case 'Net Demand':
        return scenarioMonthlyData?.vecPNetIn
          ? scenarioMonthlyData.vecPNetIn.map((value, index) =>
              scenarioMonthlyData.vecPNetOut ? +value - +scenarioMonthlyData.vecPNetOut[index] : +value
            )
          : [];
      case 'Net Export':
        return scenarioMonthlyData?.vecPNetOut ? scenarioMonthlyData.vecPNetOut.map(value => -1 * value) : [];
      case 'Battery SOC':
        return scenarioMonthlyData?.vecEnerBatt && props?.energyCapacity != 0
          ? scenarioMonthlyData.vecEnerBatt.map(battValue => battValue / props.energyCapacity)
          : [];
      case 'Islanded Load':
        return scenarioMonthlyData?.vecLoad.map(
          (buildingLoad, i) =>
            +buildingLoad -
            (scenarioMonthlyData?.vecPGen[i] ?? 0) -
            (scenarioMonthlyData?.vecPInv[i] ?? 0) -
            (scenarioMonthlyData?.vecPBatt[i] > 0 ? scenarioMonthlyData?.vecPBatt[i] : 0)
        );
      default:
        return [];
    }
  };

  const buildLayout = (): Partial<Layout> => {
    if (!state.data || state.data.length === 0) return {};

    if (GAEventSelected && GAEventSelected.length > 0) {
      return buildGAEventLayout();
    } else if (state.outageSelected && state.outageSelected.start.length > 0 && state.outageSelected.end.length > 0) {
      return buildOutageEventLayout();
    } else {
      return buildMonthlyLayout();
    }
  };

  const buildGAEventLayout = (): Partial<Layout> => {
    if (GAEventSelected && GAEventSelected.length > 0) {
      const start = `${GAEventSelected} 0:00`;
      const end = `${GAEventSelected} 23:59`;

      const layout = buildMonthlyLayout();

      assertIsDefined(layout.xaxis);

      layout.xaxis.autorange = false;
      layout.xaxis.range = [start, end];

      return layout;
    }
    return {};
  };

  const buildOutageEventLayout = (): Partial<Layout> => {
    if (state.outageSelected && state.outageSelected.start.length > 0) {
      const { start, end } = state.outageSelected;

      const layout = buildMonthlyLayout();

      assertIsDefined(layout.xaxis);

      layout.xaxis.autorange = false;

      layout.xaxis.range = [moment(start).valueOf(), moment(end).valueOf()];

      return layout;
    }
    return {};
  };

  const buildMonthlyLayout = (): Partial<Layout> => {
    assertIsDefined(state.data[0]?.x);
    const rangeStart = state.data[0].x[0];
    const rangeEnd = state.data[0].x[state.data[0].x.length - 1];
    const onPeakShapes: Partial<Shape>[] = [];
    const partialPeakShapes: Partial<Shape>[] = [];
    if (chartConfig.showTOU && props.tariffOverview && props.tariffOverview.seasons) {
      const onPeakBands = getTOUBands(props.tariffOverview.seasons, rangeStart, rangeEnd, 'ON_PEAK');
      onPeakBands.forEach(bands => {
        bands.forEach(band => {
          onPeakShapes.push({
            type: 'rect',
            xref: 'x',
            yref: 'paper',
            x0: band.start,
            y0: 0,
            x1: band.end,
            y1: 1,
            fillcolor: '#bbb',
            opacity: chartConfig.TOUOpacity,
            layer: 'below',
            line: {
              width: 0
            }
          });
        });
      });

      const partialPeakBands = getTOUBands(props.tariffOverview.seasons, rangeStart, rangeEnd, 'PARTIAL_PEAK');
      partialPeakBands.forEach(bands => {
        bands.forEach(band => {
          partialPeakShapes.push({
            type: 'rect',
            xref: 'x',
            yref: 'paper',
            x0: band.start,
            y0: 0,
            x1: band.end,
            y1: 1,
            fillcolor: '#ddd',
            opacity: chartConfig.TOUOpacity,
            layer: 'below',
            line: {
              width: 0
            }
          });
        });
      });
    }

    let currentAnnotations: Partial<Annotations>[];
    if (chartConfig.showAnnotations) {
      currentAnnotations = annotations;
    } else {
      currentAnnotations = [];
    }
    if (chartConfig.showPeaksAfter && !props.isSQ) {
      if (moment(maxPeaks.maxNCAfter.x).isValid()) {
        currentAnnotations.push({
          x: maxPeaks.maxNCAfter.x,
          y: maxPeaks.maxNCAfter.y,
          xref: 'x',
          yref: 'y',
          text: 'NC Peak Demand After',
          showarrow: true,
          arrowhead: 7,
          ax: 0,
          ay: -30
        });
      }
      if (moment(maxPeaks.maxOnPeakAfter.x).isValid()) {
        currentAnnotations.push({
          x: maxPeaks.maxOnPeakAfter.x,
          y: maxPeaks.maxOnPeakAfter.y,
          xref: 'x',
          yref: 'y',
          text: 'Peak Demand After',
          showarrow: true,
          arrowhead: 7,
          ax: 0,
          ay: -10
        });
      }
    }
    if (chartConfig.showPeaksBefore) {
      if (moment(maxPeaks.maxNCBefore.x).isValid()) {
        currentAnnotations.push({
          x: maxPeaks.maxNCBefore.x,
          y: maxPeaks.maxNCBefore.y,
          xref: 'x',
          yref: 'y',
          text: 'NC Peak Demand Before',
          showarrow: true,
          arrowhead: 7,
          ax: 0,
          ay: -40
        });
      }
      if (moment(maxPeaks.maxOnPeakBefore.x).isValid()) {
        currentAnnotations.push({
          x: maxPeaks.maxOnPeakBefore.x,
          y: maxPeaks.maxOnPeakBefore.y,
          xref: 'x',
          yref: 'y',
          text: 'Peak Demand Before',
          showarrow: true,
          arrowhead: 7,
          ax: 0,
          ay: -20
        });
      }
    }
    const layout: Partial<Layout> = {
      autosize: true,
      // hovermode: annotationMode === 'write' ? 'closest' : 'x',
      hovermode: 'closest',
      legend: { orientation: 'h' },
      margin: {
        t: 20,
        b: 20
      },
      annotations: currentAnnotations,
      shapes: [...onPeakShapes, ...partialPeakShapes],
      xaxis: {
        autorange: true,
        range: [rangeStart, rangeEnd],
        rangeselector: {
          buttons: [
            {
              count: 1,
              label: '1d',
              step: 'day',
              stepmode: 'backward'
            },
            {
              count: 5,
              label: '5d',
              step: 'day',
              stepmode: 'backward'
            },
            {
              count: 7,
              label: '1w',
              step: 'day',
              stepmode: 'backward'
            },
            { step: 'all' }
          ]
        },
        type: 'date',
        hoverformat: '%a %b %e, %Y %H:%M'
      },
      yaxis: {
        autorange: state.customRangeMin && state.customRangeMax ? false : true,
        type: 'linear',
        title: 'Power (kW)'
      },
      yaxis2: {
        overlaying: 'y',
        side: 'right',
        range: [0, 1]
      }
    };

    if (state.customRangeMin && state.customRangeMax && layout.yaxis) {
      layout.yaxis.range = [+state.customRangeMin, +state.customRangeMax];
    }

    return layout;
  };

  const configPlotly: Partial<Config> = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian'
    ],
    displaylogo: false
  };

  // generate the range dates from the data

  const getCSVHeader = (label?: string) => {
    switch (label) {
      case 'Current Demand':
        return 'Building Load';
      case 'PV Power':
        return 'PV';
      case 'Net Demand':
        return 'Net Load';
      case 'Net Demand Solar':
        return 'Net Load Solar';
      case undefined:
        return 'Undefined';
      default:
        return label;
    }
  };

  const handleDownload = () => {
    let headers = state.data
      .filter(item => item?.visible)
      .map(item => item.name)
      .map(getCSVHeader);
    headers.unshift('Timestamp');
    let downloadData: Array<[string, ...number[]]> = [];

    assertIsDefined(state.data[0].x);
    assertIsTypedArray(state.data[0].x, isNumber);
    state.data[0].x.forEach((date: number, index: number) => {
      const row: [string, ...number[]] = [moment(date).format('YYYY-MM-DD HH:mm')];
      state.data
        .filter(item => item?.visible)
        .forEach(item => {
          assertIsDefined(item?.y);
          if (item.name === 'Battery Power') {
            row.push(-1 * (item.y[index] as number));
          } else {
            row.push(item.y[index] as number);
          }
        });
      downloadData.push(row);
    });

    exportCSVFile(headers, downloadData, 'Monthly Resource Overview ');
  };

  const handleToggle = (e: any) => {
    const name = e.target.name;
    setState({ ...state, [name]: !state[name] });
  };

  // const toggleAnnotationMode = () => {
  //   const mode = annotationMode === 'read' ? 'write' : 'read';
  //   setAnnotationMode(mode);
  // };

  // const handleAddAnnotation = (e: Readonly<PlotMouseEvent>) => {
  //   addAnnotation(e);
  // };

  const handleChangeLayout = (e: any) => {
    const newLayout = e.target.value;
    const updatedOptions = applyLayout(newLayout, state.editChartOptions, buildChartOptions);

    setState({ ...state, editChartOptions: updatedOptions });

    setChartConfig({ ...chartConfig, flipChargeDischarge: newLayout === 'microgrid', chartLayout: newLayout });
  };

  return (
    <Paper>
      <Dialog onClose={closeAnnotation} aria-labelledby="simple-dialog-title" open={newAnnotation.isOpen}>
        <DialogTitle id="simple-dialog-title">Add a note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="annotation"
            label="Add A Note"
            value={newAnnotation.text}
            onChange={changeAnnotation}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={displayAnnotation} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Toolbar style={{ borderBottom: '1px solid #e5e5e5' }}>
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
          Resource overview
        </Typography>
        {state && state.chartOptions.some(item => item.stacked) && (
          <Tooltip title="Stacked Mode ON">
            <HorizontalSplitIcon className={classes.modeIcon} fontSize="small" color="disabled" />
          </Tooltip>
        )}
        {chartConfig.flipChargeDischarge && (
          <Tooltip title="Battery Power Flipped">
            <SwapVertIcon className={classes.modeIcon} fontSize="small" color="disabled" />
          </Tooltip>
        )}
        {props.assumptions.hasGridOutages && (
          <FormControl className={classes.gaEventSelect}>
            <InputLabel id="select-outages-event-labell">Select Outage {'  '}</InputLabel>
            <Select
              labelId="select-outages-event-labell"
              value={state.outageSelected.start}
              onChange={handleChangeOutage}
              inputProps={{
                name: 'outageSelected'
              }}
            >
              {props.assumptions.gridOutageDates?.length > 0 &&
                props.assumptions.gridOutageDates.map((event, index) => (
                  <MenuItem key={event.start} value={event.start}>{`${event.start} - ${event.end}`}</MenuItem>
                ))}
            </Select>
          </FormControl>
          // </Tooltip>
        )}

        {props.isGA && (
          // <Tooltip
          //   title="Actual events are highlighted, the rest are predicted events"
          // >
          <FormControl className={classes.gaEventSelect}>
            <InputLabel id="select-ga-event-label">Select GA Event{'  '}</InputLabel>
            <Select
              labelId="select-ga-event-label"
              value={GAEventSelected}
              onChange={changeGAEventSelected}
              inputProps={{
                name: 'GAEventSelected'
              }}
            >
              {GAEventsPredicted.map(event => {
                return GAEvents.indexOf(event) > -1 ? (
                  <MenuItem
                    key={event}
                    style={{ fontWeight: 'bold', backgroundColor: 'rgba(255,255,0,0.4)' }}
                    value={event}
                  >
                    {event}
                  </MenuItem>
                ) : (
                  <MenuItem value={event}>{event}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          // </Tooltip>
        )}
        <AccessControl requiredPermissions={['editor', 'admin']}>
          <>
            {/* {annotations.length > 0 && (
              <Tooltip title="Delete Annotations">
                <IconButton aria-label="Delete All Annotations" onClick={resetAnnotations}>
                  <DeleteForever />
                </IconButton>
              </Tooltip>
            )} */}
            {/* Couldnt figure out of to make the onClick work dynamically on Plot
            after refactor to FC, so I am enabling annotationm mode ON by default */}
            {/* <Tooltip title="Toggle Annotation Mode">
              <IconButton
                aria-label="Toggle Annotation Mode"
                onClick={toggleAnnotationMode}
                color={annotationMode === 'write' ? 'primary' : 'default'}
              >
                <Edit />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Settings">
              <IconButton aria-label="Chart Settings" onClick={handleOpen} data-testid="summary-chart-settings">
                <Settings />
              </IconButton>
            </Tooltip>
          </>
        </AccessControl>
        <Tooltip title="Download CSV">
          <IconButton aria-label="Download CSV" onClick={handleDownload}>
            <InsertDriveFile />
          </IconButton>
        </Tooltip>
        <FormControl>
          <Select
            value={state.selected}
            onChange={handleChange}
            inputProps={{
              name: 'selected'
            }}
          >
            {state.months.map(month => {
              return (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Toolbar>
      <Plot
        style={{ width: '100%', height: '740px' }}
        useResizeHandler={true}
        data={state.data}
        layout={buildLayout()}
        config={configPlotly}
        // onClick={handleAddAnnotation}
      />
      {chartConfig.showPeakEventCharts && (
        <div>
          <PeakEventChart />
          <PeakEventChart />
        </div>
      )}

      <ChartSettings
        open={state.open}
        handleClose={handleClose}
        editChartOptions={state.editChartOptions}
        toggleChartConfig={toggleChartConfig}
        handleColorChangeComplete={handleColorChangeComplete}
        handleMoveItem={handleMoveItem}
        handleChartTextUpdate={handleChartTextUpdate}
        handleShowChartItem={handleShowChartItem}
        handleChartLineType={handleChartLineType}
        showTOU={chartConfig.showTOU}
        handleToggle={handleToggle}
        showPeaksBefore={chartConfig.showPeaksBefore}
        showPeaksAfter={chartConfig.showPeaksAfter}
        showAnnotations={chartConfig.showAnnotations}
        hideHoverInfo={chartConfig.hideHoverInfo}
        TOUOpacity={chartConfig.TOUOpacity}
        handleValueChange={handleValueChange}
        customRangeMin={state.customRangeMin}
        customRangeMax={state.customRangeMax}
        handleSuccessClose={handleSuccessClose}
        handleStackChartItem={handleStackChartItem}
        flipChargeDischarge={chartConfig.flipChargeDischarge}
        chartLayout={chartConfig.chartLayout}
        handleChangeLayout={handleChangeLayout}
      />
    </Paper>
  );
};

export default ResourceOverviewChart;
