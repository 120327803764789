import React, { useState } from 'react';
import TOUGridDetail from './TOUGridDetail';
import GridLegend from './GridLegend';
import { ESAPTariff } from '../../../types';
import Grid from '@material-ui/core/Grid';
import { PeriodDetail } from './TariffDetailTOU';

interface ITariffDetailTOUWeekdaysProps {
  tariff: Readonly<ESAPTariff>;
  periods: Readonly<PeriodDetail[]>;
  matrix: Readonly<number[][]>;
}

const TariffDetailTOUWeekdays: React.FC<ITariffDetailTOUWeekdaysProps> = ({ tariff, matrix, periods }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodDetail | undefined>(undefined);

  const isEV =
    tariff &&
    tariff?.ev_subscriptions &&
    tariff?.ev_subscriptions?.length > 0 &&
    tariff?.overage_charges &&
    Object.keys(tariff.overage_charges).length > 1
      ? true
      : false;

  return (
    <Grid container spacing={5}>
      <Grid item>
        <TOUGridDetail matrix={matrix} periods={periods} setSelected={setSelectedPeriod} />
      </Grid>
      <Grid item>
        <GridLegend selected={selectedPeriod} isEV={isEV} />
      </Grid>
    </Grid>
  );
};

export default TariffDetailTOUWeekdays;
