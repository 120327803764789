import { GET_FAVORITES_SUCCESS, ADD_FAVORITES_SUCCESS, DELETE_FAVORITES_SUCCESS } from '../../actions';
import { Favorite } from '../../types';
const INITIAL_STATE = {
  proposal_scenarios: [] as Favorite[],
  proposals: [] as Favorite[]
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_FAVORITES_SUCCESS:
      return { ...action.payload };
    case ADD_FAVORITES_SUCCESS:
      switch (action.payload.type) {
        case 'proposal':
          return { ...state, proposals: [...state.proposals, action.payload.newFavorite] };
        default:
          return state;
      }
    case DELETE_FAVORITES_SUCCESS:
      switch (action.payload.type) {
        case 'proposal':
          const currentProposals = state.proposals.filter(proposal => proposal.public_id !== action.payload.id);
          return { ...state, proposals: [...currentProposals] };
        default:
          return state;
      }
    default:
      return state;
  }
}
