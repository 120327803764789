import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';

// MATERIAL UI
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import SlowMotionVideo from '@material-ui/icons/SlowMotionVideo';
import Queue from '@material-ui/icons/Queue';
import { CircularProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

// REACT IMPORTS
import { PortfolioMeter } from '../../../types';
import { createLoadingSelector } from '../../../selectors';
import { StoreState } from '../../../reducers';
import CurrencyDisplay from '../../../utility/CurrencyDisplay';
import { getProposalsOptions } from '../../../actions/portfolios';
import { getProposal } from '../../../actions/proposals';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3)
  },
  root: {
    //width: '100%',
    minWidth: 700,
    marginTop: theme.spacing(3)
  },
  table: {
    border: '1px solid #e5e5e5',
    '& th': {
      fontFamily: 'Roboto !important',
      fontSize: '.7rem !important',
      whiteSpace: 'normal !important'
    },
    '& td': {
      borderRight: '1px solid #f1f1f1'
    },
    '& tr:hover': {
      backgroundColor: '#f1f1f1'
    },
    '& td, & th': {
      paddingLeft: 12,
      paddingRight: 12,
      textAlign: 'center',
      borderRight: '1px solid #e5e5e5',
      letterSpacing: '.5px'
    }
  },
  complete: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.greenDark
  },
  error: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.red
  },
  warning: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.yellowOrange
  },
  percentage: {
    fontWeight: 600,
    fontSize: '.7rem',
    letterSpacing: '-.5px'
  }
}));

// PARENT COMPONENT THAT IS EXPORTED
interface IPortfolioMetersAssignProps {
  meters: PortfolioMeter[];
  handleTogglePrimary: any;
  handleProposalChange: any;
  handleScenarioChange: any;
  handleStatusQuoChange: any;
  handleDeleteMeter: any;
  getTotalSavings: any;
  getTotalCost: any;
}

const PortfolioMetersAssignGeneral: React.FunctionComponent<IPortfolioMetersAssignProps> = ({
  meters,
  handleTogglePrimary,
  handleProposalChange,
  handleScenarioChange,
  handleStatusQuoChange,
  handleDeleteMeter,
  getTotalSavings,
  getTotalCost
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div data-testid="portfolio-list-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Primary</TableCell>
              <TableCell>Proposal</TableCell>
              <TableCell>Status Quo</TableCell>
              <TableCell>ScenarioInternal</TableCell>
              <TableCell>Customer Bill ($)</TableCell>
              <TableCell>Total Savings ($)</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meters.map((meter, index) => (
              <RenderRow
                key={index}
                index={index}
                meter={meter}
                handleTogglePrimary={handleTogglePrimary}
                handleProposalChange={handleProposalChange}
                handleScenarioChange={handleScenarioChange}
                handleStatusQuoChange={handleStatusQuoChange}
                handleDeleteMeter={handleDeleteMeter}
                getTotalSavings={getTotalSavings}
                getTotalCost={getTotalCost}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

// COMPONENT THAT RENDERS TABLE ROW WITH SELECTS

interface RenderRowProps {
  index: number;
  meter: PortfolioMeter;
  handleTogglePrimary(index: number): any;
  handleProposalChange(index: number): any;
  handleScenarioChange(index: number): any;
  handleStatusQuoChange(index: number): any;
  handleDeleteMeter(index: number): any;
  getTotalSavings: any;
  getTotalCost: any;
}

const RenderRow: React.FC<RenderRowProps> = ({
  meter,
  index,
  handleTogglePrimary,
  handleProposalChange,
  handleScenarioChange,
  handleStatusQuoChange,
  handleDeleteMeter,
  getTotalCost,
  getTotalSavings
}) => {
  const loadingSelector = createLoadingSelector(['GET_ALL_PROPOSALS']);
  const isLoading = useSelector((state: StoreState) => loadingSelector(state));

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          aria-label="Is a Primary meter"
          name="is_primary"
          checked={meter.is_primary}
          onChange={handleTogglePrimary(index)}
          inputProps={{ 'aria-label': 'primary meter' }}
        />
      </TableCell>
      <TableCell>
        {isLoading && <CircularProgress size={16} />}
        {!isLoading && <SelectPortfolioProposal meter={meter} handleProposalChange={handleProposalChange(index)} />}
      </TableCell>
      <TableCell>
        <SelectPortfolioStatusQuo index={index} meter={meter} handleStatusQuoChange={handleStatusQuoChange(index)} />
      </TableCell>
      <TableCell>
        <SelectPortfolioScenario index={index} meter={meter} handleScenarioChange={handleScenarioChange(index)} />
      </TableCell>
      <TableCell>
        <CurrencyDisplay value={getTotalCost(meter)} />
      </TableCell>
      <TableCell>
        <CurrencyDisplay value={getTotalSavings(meter)} />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleDeleteMeter(index)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

// SELECT FOR PROPOSAL

interface SelectPortfolioProposalProps {
  meter: PortfolioMeter;
  handleProposalChange: any;
}
const SelectPortfolioProposal: React.FC<SelectPortfolioProposalProps> = ({ meter, handleProposalChange }) => {
  // const proposals = useSelector((state: StoreState) => state.proposals);
  // const options =
  //   proposals && proposals.length > 0
  //     ? proposals.map(p => {
  //         return {
  //           value: p.public_id,
  //           label: p.name
  //         };
  //       })
  //     : undefined;

  // const filterOptions = options ? createFilterOptions({ options }) : undefined;

  const getOptions = (search: string) => {
    return getProposalsOptions(search);
  };

  return (
    <Select
      async
      labelKey="label"
      loadOptions={getOptions}
      style={{ width: 300 }}
      name="portfolio-proposal"
      data-testid="portfolio-proposal-select"
      value={meter.proposal_id}
      clearable
      // options={options}
      multi={false}
      // filterOptions={filterOptions}
      onChange={handleProposalChange}
    />
  );
};

// SELECT FOR SCENARIO

type Options = { value: string | undefined; label: string }[] | undefined;

interface SelectPortfolioScenarioProps {
  index: number;
  meter: PortfolioMeter;
  handleScenarioChange: any;
}
const SelectPortfolioScenario: React.FC<SelectPortfolioScenarioProps> = ({ index, meter, handleScenarioChange }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Options>();
  const classes = useStyles();
  const proposal = useSelector((state: StoreState) => state.proposal);

  useEffect(() => {
    dispatch(getProposal(meter.proposal_id));
  }, [meter.proposal_id]);

  //TODO: Is loading

  useEffect(() => {
    const options =
      meter && meter.scenarios
        ? meter.scenarios.map(s => {
            return {
              value: s.public_id,
              label: s.name,
              icon: getIcon(classes, { error: s.error, status: s.status, active: s.active, completed: s.run_completed })
            };
          })
        : undefined;

    setOptions(options);
  }, [meter.proposal_id, proposal]);

  const filterOptions = options ? createFilterOptions({ options }) : undefined;

  return meter.proposal_id ? (
    <Select
      style={{ minWidth: 240 }}
      name="portfolio-scenario"
      data-testid="portfolio-scenario-select"
      value={meter.scenario_id}
      clearable={false}
      options={options}
      multi={false}
      filterOptions={filterOptions}
      onChange={handleScenarioChange}
      optionRenderer={OptionWithIcon}
    />
  ) : (
    <span>Select a proposal first.</span>
  );
};

// SELECT FOR STATUS QUO

interface SelectPortfolioStatusQuoProps {
  index: number;
  meter: PortfolioMeter;
  handleStatusQuoChange: any;
}
const SelectPortfolioStatusQuo: React.FC<SelectPortfolioStatusQuoProps> = ({ index, meter, handleStatusQuoChange }) => {
  const [options, setOptions] = useState<Options>();
  const classes = useStyles();

  useEffect(() => {
    const options =
      meter.scenarios.length > 0
        ? meter.scenarios.map(s => {
            return {
              value: s.public_id,
              label: s.name,
              icon: getIcon(classes, { error: s.error, status: s.status, active: s.active, completed: s.run_completed })
            };
          })
        : undefined;

    setOptions(options);
  }, [meter.proposal_id]);

  const filterOptions = options ? createFilterOptions({ options }) : undefined;

  return meter.proposal_id ? (
    <Select
      style={{ minWidth: 240 }}
      name="portfolio-statusquo"
      data-testid="portfolio-statusquo-select"
      value={meter.status_quo_id}
      clearable={false}
      options={options}
      multi={false}
      filterOptions={filterOptions}
      onChange={handleStatusQuoChange}
      optionRenderer={OptionWithIcon}
    />
  ) : (
    <span>Select a proposal first.</span>
  );
};

const OptionWithIcon = ({
  focusedOption,
  focusedOptionIndex,
  focusOption,
  key,
  labelKey,
  option,
  options,
  selectValue,
  style,
  valueArray,
  valueKey
}: any) => {
  const classNames = ['iconOption'];

  if (option === focusedOption) {
    classNames.push('iconOptionFocused');
  }
  if (valueArray.indexOf(option) >= 0) {
    classNames.push('iconOptionSelected');
  }

  return (
    <div
      className={classNames.join(' ')}
      onMouseEnter={() => focusOption(option)}
      style={style}
      onClick={() => selectValue(option)}
    >
      <span className="optionIcon">{option.icon}</span>
      <label className="optionLabel">{option.label}</label>
    </div>
  );
};

const getStatusStyle = (
  classes: any,
  {
    error,
    status,
    active,
    completed
  }: {
    error: string | null | undefined;
    status?: string | null;
    active: boolean;
    completed: string | undefined;
  }
) => {
  if (status === 'Complete' && !error) {
    return classes.complete;
  }
  if (active && error != '' && error !== null) {
    return classes.error;
  }
  if (active && !completed) {
    return classes.warning;
  }
  return classes.complete;
};

const getIcon = (
  classes: any,
  {
    error,
    status,
    active,
    completed
  }: {
    error: string | null | undefined;
    status?: string | null;
    active: boolean;
    completed: string | undefined;
  }
) => (
  <div className={getStatusStyle(classes, { error, status, active, completed })}>
    {error && <ErrorIcon />}

    {!error && active && !completed && !status && <SlowMotionVideo />}
    {status && (
      <span>
        {status === 'Complete' && !error && <Check className={classes.complete} />}
        {!error && status === 'Queued' && (
          <span className={classes.percentage}>
            <Queue />
          </span>
        )}
        {!error && status !== 'Complete' && status !== 'Queued' && <span className={classes.percentage}>{status}</span>}
      </span>
    )}
  </div>
);

export default PortfolioMetersAssignGeneral;
