import { colors } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '20px',
      height: '100vh',
      backgroundColor: '#eaeaea',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
      marginBottom: '16px',
      paddingBottom: '16px',
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '20px',
    },
    body: {
      width: '100%',
    },
    list: {
      marginRight: '10px',
    },
    detailHeader: {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    viewer: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      width: '100%',
      height: '80%',
      marginBottom: '8px',
    },
    actions: {
      padding: '4px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: '20px',
    },
    chips: {
      display: 'flex',
      overflowX: 'auto',
    },
    my0: {
      marginTop: 0,
      marginBottom: 0,
    },
    px8: {
      paddingRight: '16px',
      paddingLeft: '16px',
    },
    heading3: {
      marginBottom: '8px',
      fontSize: '18px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    alignCenter: {
      alignItems: 'center',
      marginLeft: 'auto',
    },
    textLeft: {
      textAlign: 'left',
    },
    findTariff: {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    wFull: {
      width: '100%',
    },
    sidenav: {
      backgroundColor: colors.common.white,
      width: 'calc(40%)',
      height: '100%',
      [theme.breakpoints.up('lg')]: {
        width: `calc(30%)`
      },
      [theme.breakpoints.up('xl')]: {
        width: `calc(25%)`
      },
    },
    tariffInput: {
      width: '30%',
    },
  })
);

export {
  useStyles,
}
