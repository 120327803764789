import React, { useEffect } from 'react';
import tooltip from '../../../tooltips/assumptions/strategies';

// MATERIAL UI IMPORTS
// import Select from '@material-ui/core/Select';
// import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
// import FindInPage from '@material-ui/icons/FindInPage';
// import Button from '@material-ui/core/Button';
import TextFieldTooltip from '../../Common/TextFieldTooltip';
import TextField from '@material-ui/core/TextField';
import { AssumptionsInternal, Market } from '../../../types';
// import VerticalWhiteSpace from '../../Common/VerticalWhiteSpace';

// OTHER IMPORTS
// import IconTooltip from '../../Common/IconTooltip';
interface NetMeteringISONEProps {
  market: Market;
  exportCost: number;
  exportValue: number;
  useExportCost: boolean;
  handleGeneralAssumptionChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  handlePopulateNBC: () => void;
  altBillUtility: 'NATIONAL_GRID' | 'EVERSOURCE_EAST' | 'EVERSOURCE_WEST' | '';
  altBillLoadZone: 'NEMA' | 'SEMA' | 'WCMA' | '';
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const NetMeteringISONE: React.FC<NetMeteringISONEProps> = ({
  exportCost,
  exportValue,
  useExportCost,
  handleGeneralAssumptionChange,
  handleAssumptionUpdateByKey,
  handleToggle,
  market
}) => {
  useEffect(() => {
    if (useExportCost) {
      // if using exportCost then reset exportValue
      handleAssumptionUpdateByKey('exportValue')(0);
    } else {
      // if using exportValue then reset exportCost
      handleAssumptionUpdateByKey('exportCost')(0);
    }
  }, [useExportCost]);

  return (
    <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee' }}>
      <div>
        <FormControlLabel
          control={<Switch onChange={handleToggle} checked={useExportCost} name="useExportCost" />}
          label="Use Export Cost"
        />
        {useExportCost && (
          <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee', marginBottom: 8 }}>
            <TextFieldTooltip
              title={tooltip.exportCost}
              type="number"
              label="Penalty to Retail Rate"
              startAdornment={<InputAdornment position="start">$/kWh</InputAdornment>}
              key="exportCost"
              name="exportCost"
              value={exportCost}
              onChange={handleGeneralAssumptionChange}
              fullWidth
            />
          </div>
        )}
      </div>
      <div>
        <FormControlLabel
          control={<Switch onChange={handleToggle} checked={!useExportCost} name="useExportCost" />}
          label="Use Export Value"
        />
        {!useExportCost && (
          <div style={{ paddingLeft: 25, borderLeft: '10px solid #eee', marginBottom: 8 }}>
            <TextField
              style={{ marginTop: 16 }}
              type="number"
              label="Value of Export"
              InputProps={{
                startAdornment: <InputAdornment position="start">$/kWh</InputAdornment>
              }}
              key="exportValue"
              name="exportValue"
              value={exportValue}
              onChange={handleGeneralAssumptionChange}
              fullWidth
            />
          </div>
        )}
      </div>

      {/* TODO: If this is still commented in Jan, 2022, remove from source */}
      {/* <VerticalWhiteSpace /> */}
      {/* <Button disabled={true} variant="contained" size="small" onClick={handlePopulateNBC}> */}
      {/* <FindInPage /> */}
      {/* <> */}
      {/* <span>LOOKUP ALTERNATE BILL CREDIT VALUE</span> */}
      {/* <IconTooltip title={tooltip.NBCharges} iconSize="small" iconSizeSmall={12} /> */}
      {/* </> */}
      {/* </Button> */}
      {/* <FormControl fullWidth style={{ marginTop: 16 }}> */}
      {/* <InputLabel htmlFor="select-alt-bill-utility">Select Utility</InputLabel> */}
      {/* <Select */}
      {/* value={altBillUtility} */}
      {/* name="altBillUtility" */}
      {/* onChange={handleGeneralAssumptionChange} */}
      {/* input={<Input id="select-alt-bill-utility" />} */}
      {/* > */}
      {/* <MenuItem key="NATIONAL_GRID" value="NATIONAL_GRID"> */}
      {/* National Grid */}
      {/* </MenuItem> */}
      {/* <MenuItem key="EVERSOURCE_EAST" value="EVERSOURCE_EAST"> */}
      {/* Eversource East */}
      {/* </MenuItem> */}
      {/* <MenuItem key="EVERSOURCE_WEST" value="EVERSOURCE_WEST"> */}
      {/* Eversource West */}
      {/* </MenuItem> */}
      {/* </Select> */}
      {/* </FormControl> */}
      {/* <FormControl fullWidth style={{ marginTop: 16 }}> */}
      {/* <InputLabel htmlFor="select-load-zone">Select Load Zone</InputLabel> */}
      {/* <Select */}
      {/* value={altBillLoadZone} */}
      {/* name="altBillLoadZone" */}
      {/* onChange={handleGeneralAssumptionChange} */}
      {/* input={<Input id="select-load-zone" />} */}
      {/* > */}
      {/* <MenuItem key="NEMA" value="NEMA"> */}
      {/* NEMA */}
      {/* </MenuItem> */}
      {/* <MenuItem key="SEMA" value="SEMA"> */}
      {/* SEMA */}
      {/* </MenuItem> */}
      {/* <MenuItem key="WCMA" value="WCMA"> */}
      {/* WCMA */}
      {/* </MenuItem> */}
      {/* </Select> */}
      {/* </FormControl> */}
    </div>
  );
};

export default NetMeteringISONE;
