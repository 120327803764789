import { GET_TARIFF_BY_ID_SUCCESS, BROADCAST_UPDATE_TARIFF, RESET_TARIFF } from '../../actions/tariffs';
import { GET_MANUALTARIFF_SUCCESS, RESET_MANUALTARIFF, UPDATE_MANUALTARIFF_SUCCESS } from '../../actions/manualtariffs';
import { GET_PROPOSAL_TARIFF_SUCCESS } from '../../actions/proposal_tariffs';

const INITIAL_STATE = {};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_TARIFF:
    case RESET_MANUALTARIFF:
      return INITIAL_STATE;
    case GET_PROPOSAL_TARIFF_SUCCESS:
    case GET_TARIFF_BY_ID_SUCCESS:
    case GET_MANUALTARIFF_SUCCESS:
    case BROADCAST_UPDATE_TARIFF:
    case UPDATE_MANUALTARIFF_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
