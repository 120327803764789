import React from 'react';
import Typography from '@material-ui/core/Typography';
import { PeriodDetail } from './TariffDetailTOU';

interface GridLegendProps {
  selected?: PeriodDetail;
  isEV: boolean;
}

const GridLegend: React.FC<GridLegendProps> = ({ selected, isEV }) => {
  if (!selected) return null;
  return (
    <>
      <div style={{ width: 230 }}>
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 8,
            backgroundColor: selected ? selected.color : '#fff',
            border: '1px solid grey',
            marginTop: 56
          }}
        >
          {selected ? selected.index : ''}
        </div>
        <Typography variant="h6" color="inherit" style={{ marginBottom: 8, marginTop: 16 }}>
          {selected ? selected.name : ''}
        </Typography>
        <hr />
        <Typography variant="subtitle1">
          {'Energy Charges ($/kWh):  '} <br />
          <b>{selected ? selected.energyCharges : '--'}</b>
        </Typography>
        {!isEV && (
          <>
            <Typography variant="subtitle1">
              {'Demand Charges ($/kW):  '}
              <br />
              <b>{selected ? selected.demandCharges : '--'}</b>
            </Typography>
            <Typography variant="subtitle1">
              {'NC Demand Charges ($/kW):  '}
              <br />
              <b>{selected ? selected.ncDemandCharges : '--'}</b>
            </Typography>
          </>
        )}
      </div>
    </>
  );
};

export default GridLegend;
