import React from 'react';
import { AssumptionsInternal, Market } from '../../../types';

// MATERIAL UI IMPORTS
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

// REACT IMPORTS
// import EditEventsDialog from '../../Data/EditEventsDialog';

const useStyles = makeStyles(theme => ({
  block: {
    display: 'flex',
    marginTop: theme.spacing(2)
  }
}));

interface NEISOAssumptionsProps {
  selectedNEISOUtility: 'EVERSOURCE_ENERGY' | 'NATIONAL_GRID' | '' | 'UNITED_ILLUMINATING';
  useConnectedSolutionsDR: boolean;
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleGeneralAssumptionChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  useDailyDispatchSummer: boolean;
  useTargetedDispatchSummer: boolean;
  useTargetedDispatchWinter: boolean;
  useNEISOCustomEvents: boolean;
  neisoCustomEvents: string[];
  useCleanPeakStandard: boolean;
  isWinterDispatchCleanPeak: boolean;
  isSpringDispatchCleanPeak: boolean;
  isSummerDispatchCleanPeak: boolean;
  isFallDispatchCleanPeak: boolean;
  useCustomCPSCredit: boolean;
  customCPSCredit: number;
  useAltBillCreditRateExport: boolean;
  altBillUtility?: 'NATIONAL_GRID' | 'EVERSOURCE_EAST' | 'EVERSOURCE_WEST' | '';
  altBillLoadZone?: 'NEMA' | 'SEMA' | 'WCMA' | '';
  handleAssumptionUpdateByKey: (key: keyof AssumptionsInternal) => (value: any) => void;
  handleMultiUpdate: (assumptions: Partial<AssumptionsInternal>) => void;
  market: Market;
}

const NEISOAssumptions: React.FC<NEISOAssumptionsProps> = props => {
  const classes = useStyles();
  // const [customEventsDialogOpen, setCustomEventsDialogOpen] = useState(false);

  const handleToggleSummerDispatch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    // prevent the two summer dispatch to be ON at the same time
    const { name } = event.target;

    if (name === 'useDailyDispatchSummer') {
      if (checked) {
        props.handleAssumptionUpdateByKey('useTargetedDispatchSummer')(!checked);
      }
      props.handleAssumptionUpdateByKey(name as keyof AssumptionsInternal)(checked);
    } else if (name === 'useTargetedDispatchSummer') {
      if (checked) {
        props.handleAssumptionUpdateByKey('useDailyDispatchSummer')(!checked);
      }
      props.handleAssumptionUpdateByKey(name as keyof AssumptionsInternal)(checked);
    }
    props.handleAssumptionUpdateByKey(name as keyof AssumptionsInternal)(checked);
  };

  const handleToggleConnectedSolutions = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      props.handleMultiUpdate({
        useDailyDispatchSummer: true,
        useTargetedDispatchSummer: false,
        useConnectedSolutionsDR: true
      });
    } else {
      props.handleToggle(event, checked);
    }
  };

  const handleToggleCPS = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      props.handleMultiUpdate({
        useCleanPeakStandard: true,
        isSummerDispatchCleanPeak: true,
        isFallDispatchCleanPeak: true,
        isWinterDispatchCleanPeak: true,
        isSpringDispatchCleanPeak: true
      });
    } else {
      props.handleToggle(event, checked);
    }
  };
  return (
    <div>
      <div>
        <FormControlLabel
          className={classes.block}
          control={
            <Switch
              onChange={handleToggleConnectedSolutions}
              name="useConnectedSolutionsDR"
              checked={props.useConnectedSolutionsDR}
            />
          }
          label="Use Connected Solutions DR"
        />

        {props.useConnectedSolutionsDR && (
          <div
            style={{
              paddingLeft: 25,
              borderLeft: '10px solid #eee'
            }}
          >
            <FormControl fullWidth style={{ marginTop: 16 }}>
              <InputLabel htmlFor="select-ghg-region">Select Utility</InputLabel>
              <Select
                value={props.selectedNEISOUtility}
                name="selectedNEISOUtility"
                onChange={props.handleGeneralAssumptionChange}
                input={<Input id="select-ghg-region" />}
              >
                <MenuItem key="EVERSOURCE_ENERGY" value="EVERSOURCE_ENERGY">
                  Eversource Energy
                </MenuItem>
                {props.market === 'ISONE_MA' && (
                  <MenuItem key="NATIONAL_GRID" value="NATIONAL_GRID">
                    National Grid 
                  </MenuItem>
                )}
                {props.market === 'ISONE_CT' && (
                  <MenuItem key="UNITED_ILLUMINATING" value="UNITED_ILLUMINATING">
                    United Illuminating
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControlLabel
              className={classes.block}
              control={
                <Switch
                  onChange={handleToggleSummerDispatch}
                  name="useDailyDispatchSummer"
                  checked={props.useDailyDispatchSummer}
                />
              }
              label={
                <>
                  <span>Daily Dispatch Summer</span>
                  {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                </>
              }
            />
            <FormControlLabel
              className={classes.block}
              control={
                <Switch
                  onChange={handleToggleSummerDispatch}
                  name="useTargetedDispatchSummer"
                  checked={props.useTargetedDispatchSummer}
                />
              }
              label={
                <>
                  <span>Targeted Dispatch Summer</span>
                  {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                </>
              }
            />
            <FormControlLabel
              className={classes.block}
              control={
                <Switch
                  onChange={props.handleToggle}
                  name="useTargetedDispatchWinter"
                  checked={props.useTargetedDispatchWinter}
                />
              }
              label={
                <>
                  <span>Targeted Dispatch Winter</span>
                  {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                </>
              }
            />
            {/* <FormControlLabel */}
            {/* className={classes.block} */}
            {/* control={ */}
            {/* <Switch */}
            {/* onChange={props.handleToggle} */}
            {/* name="useNEISOCustomEvents" */}
            {/* checked={props.useNEISOCustomEvents} */}
            {/* /> */}
            {/* } */}
            {/* label={ */}
            {/* <> */}
            {/* <span>Enable Custom Events</span> */}
            {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
            {/* </> */}
            {/* } */}
            {/* /> */}
            {/* {props.useNEISOCustomEvents && (
              <div
                style={{
                  paddingLeft: 25,
                  borderLeft: '10px solid #eee'
                }}
              >
                <Button
                  style={{
                    margin: '16px 0'
                  }}
                  className="button-white"
                  variant="contained"
                  color="primary"
                  onClick={() => setCustomEventsDialogOpen(!customEventsDialogOpen)}
                >
                  Edit Event Days
                </Button>
                <EditEventsDialog
                  open={customEventsDialogOpen}
                  onClose={() => setCustomEventsDialogOpen(false)}
                  eventDates={[]}
                  customEvents={props.neisoCustomEvents!}
                  handleAssumptionUpdateByKey={props.handleAssumptionUpdateByKey}
                  eventType="ISONE"
                  assumptionKey="neisoCustomEvents"
                />
              </div>
            )} */}
          </div>
        )}
      </div>
      {props.market !== 'ISONE_CT' && (
        <div>
          <FormControlLabel
            className={classes.block}
            control={
              <Switch onChange={handleToggleCPS} name="useCleanPeakStandard" checked={props.useCleanPeakStandard} />
            }
            label={
              <>
                <span>Use Clean Peak Standard</span>
                {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
              </>
            }
          />
          {props.useCleanPeakStandard && (
            <div
              style={{
                paddingLeft: 25,
                borderLeft: '10px solid #eee'
              }}
            >
              <FormControlLabel
                className={classes.block}
                control={
                  <Switch
                    onChange={props.handleToggle}
                    name="isWinterDispatchCleanPeak"
                    checked={props.isWinterDispatchCleanPeak}
                  />
                }
                label={
                  <>
                    <span>Winter Dispatch</span>
                    {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                  </>
                }
              />
              <FormControlLabel
                className={classes.block}
                control={
                  <Switch
                    onChange={props.handleToggle}
                    name="isSpringDispatchCleanPeak"
                    checked={props.isSpringDispatchCleanPeak}
                  />
                }
                label={
                  <>
                    <span>Spring Dispatch</span>
                    {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                  </>
                }
              />
              <FormControlLabel
                className={classes.block}
                control={
                  <Switch
                    onChange={props.handleToggle}
                    name="isSummerDispatchCleanPeak"
                    checked={props.isSummerDispatchCleanPeak}
                  />
                }
                label={
                  <>
                    <span>Summer Dispatch</span>
                    {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                  </>
                }
              />
              <FormControlLabel
                className={classes.block}
                control={
                  <Switch
                    onChange={props.handleToggle}
                    name="isFallDispatchCleanPeak"
                    checked={props.isFallDispatchCleanPeak}
                  />
                }
                label={
                  <>
                    <span>Fall Dispatch</span>
                    {/* <IconTooltip title={help.usePDP} iconSize="small" iconSizeSmall={12} /> */}
                  </>
                }
              />
              <FormControlLabel
                className={classes.block}
                control={
                  <Switch onChange={props.handleToggle} name="useCustomCPSCredit" checked={props.useCustomCPSCredit} />
                }
                label={
                  <>
                    <span>Use Custom CPS Credit</span>
                  </>
                }
              />
              {props.useCustomCPSCredit && (
                <TextField
                  label="Custom CPS Credit"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$/kWh</InputAdornment>
                  }}
                  key="customCPSCredit"
                  name="customCPSCredit"
                  value={props.customCPSCredit}
                  onChange={props.handleGeneralAssumptionChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  margin="normal"
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NEISOAssumptions;
