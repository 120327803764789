import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getProcessingQueue, removeJob } from '../actions/queue';
import { Queues } from '../types';
import { AxiosError } from 'axios';

export const useProcessingQueue = () =>
  useQuery<Queues, Error>('processingQueue', getProcessingQueue, {
    refetchInterval: 10000
  });

export const useRemoveJob = () => {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string, any>(id => removeJob(id), {
    retry: 3,
    onSuccess: () => {
      queryClient.invalidateQueries('processingQueue');
    }
  });
};
