import {
  UPDATE_EXPLORE_PAGE_INFORMATION,
  UPDATE_EXPLORE_PAGE_BILLING_DISPLAY_INFORMATION,
  UPDATE_EXPLORE_PAGE_INFORMATION_SILENTLY
} from '../../actions/explore';
import { DataType, ExploreDetailsMeta } from '../../types';
import { GET_METER_INTERVAL_SUCCESS } from '../../actions/utilityapi';
import { GET_PROPOSAL_DATA_SUCCESS, UPLOAD_ALL_DATA_FILES_SUCCESS } from '../../actions/proposals';
import moment from 'moment';

const INITIAL_STATE: ExploreDetailsMeta = {
  startDate: moment().subtract(1, 'y').add(1, 'd').startOf('day'),
  endDate: moment(),
  solarFrequency: 1, // 1 hour
  loadFrequency: 4, // 15 minutes
  lmpRTFrequency: 1, // 1 hour
  lmpDAFrequency: 1, // 1 hour
  ev1Frequency: 4,
  ev2Frequency: 4,
  mefFrequency: 4, // 15 minutes
  solarScaleRating: 1,
  solarLog: '',
  weekType: 'weekday',
  showMonthly: false,
  showSeasonal: false,
  showTOU: false,
  showPeak: false,
  selectedMonth: '0',
  monthSummary: null,
  touMonthSummary: null,
  currentMonthlyData: null,
  summary: {},
  solarSummary: {},
  monthSolarSummary: {},
  touSummary: {},
  fromHour: 16,
  toHour: 21,
  months: [],
  billingDisplay: {
    selectedTariff: null,
    selectedScenario: 'Base Load'
  },
  unSavedChanges: false
};

export default function exploreDetailsMetaReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case UPDATE_EXPLORE_PAGE_INFORMATION:
      const update = { ...state, ...action.payload, unSavedChanges: true };
      return update;
    case UPDATE_EXPLORE_PAGE_INFORMATION_SILENTLY:
      return { ...state, ...action.payload };
    case UPLOAD_ALL_DATA_FILES_SUCCESS:
      return { ...state, unSavedChanges: false };
    case UPDATE_EXPLORE_PAGE_BILLING_DISPLAY_INFORMATION:
      const updatedDisplayOptions = {
        ...state.billingDisplay,
        ...action.payload
      };
      return { ...state, billingDisplay: updatedDisplayOptions };
    case GET_METER_INTERVAL_SUCCESS:
      if (action.payload && action.payload.length > 0) {
        const newStartDateComponents = action.payload[0][0].split('/');

        const startDate = newStartDateComponents[2] + '-' + newStartDateComponents[0] + '-' + newStartDateComponents[1];

        const newEndDateComponents = action.payload[action.payload.length - 1][0].split('/');

        const endDate = newEndDateComponents[2] + '-' + newEndDateComponents[0] + '-' + newEndDateComponents[1];

        return {
          ...state,
          startDate: moment(startDate),
          endDate: moment(endDate)
        };
      }
      return state;
    case GET_PROPOSAL_DATA_SUCCESS:
      if (action.payload) {
        // find load data because it has to be included whereas other data streams are optional
        let startDate = state.startDate;
        let endDate = state.endDate;
        let loadFrequency = state.loadFrequency;
        let solarFrequency = state.solarFrequency;
        let lmpDAFrequency = state.lmpDAFrequency;
        let lmpRTFrequency = state.lmpRTFrequency;
        let ev1Frequency = state.ev1Frequency;
        let ev2Frequency = state.ev2Frequency;

        let solarScaleRating = state.solarScaleRating;

        if (action.payload.type === DataType.load) {
          startDate = moment(action.payload.data[0][0]);
          endDate = moment(action.payload.data[action.payload.data.length - 1][0]);
          const intervalStart = `${action.payload?.data[0][0]} ${action.payload?.data[0][1]}`;
          const intervalEnd = `${action.payload?.data[1][0]} ${action.payload?.data[1][1]}`;

          if (moment(intervalEnd).diff(moment(intervalStart)) == 60 * 60 * 1000) {
            loadFrequency = 1;
          } else {
            loadFrequency = 4;
          }
        }
        if (action.payload.type === DataType.solar) {
          solarScaleRating = action.payload.scaler;
          const intervalStart = `${action.payload?.data[0][0]} ${action.payload?.data[0][1]}`;
          const intervalEnd = `${action.payload?.data[1][0]} ${action.payload?.data[1][1]}`;

          if (moment(intervalEnd).diff(moment(intervalStart)) == 15 * 60 * 1000) {
            solarFrequency = 4;
          } else {
            solarFrequency = 1;
          }
        }
        if (action.payload.type === DataType.lmpDa) {
          const intervalStart = `${action.payload?.data[0][0]} ${action.payload?.data[0][1]}`;
          const intervalEnd = `${action.payload?.data[1][0]} ${action.payload?.data[1][1]}`;

          if (moment(intervalEnd).diff(moment(intervalStart)) == 15 * 60 * 1000) {
            lmpDAFrequency = 4;
          } else {
            lmpDAFrequency = 1;
          }
        }
        if (action.payload.type === DataType.lmpRt) {
          const intervalStart = `${action.payload?.data[0][0]} ${action.payload?.data[0][1]}`;
          const intervalEnd = `${action.payload?.data[1][0]} ${action.payload?.data[1][1]}`;

          if (moment(intervalEnd).diff(moment(intervalStart)) == 15 * 60 * 1000) {
            lmpRTFrequency = 4;
          } else {
            lmpRTFrequency = 1;
          }
        }
        if (action.payload.type === DataType.ev1) {
          const intervalStart = `${action.payload?.data[0][0]} ${action.payload?.data[0][1]}`;
          const intervalEnd = `${action.payload?.data[1][0]} ${action.payload?.data[1][1]}`;

          if (moment(intervalEnd).diff(moment(intervalStart)) == 15 * 60 * 1000) {
            ev1Frequency = 4;
          } else {
            ev1Frequency = 1;
          }
        }
        if (action.payload.type === DataType.ev2) {
          const intervalStart = `${action.payload?.data[0][0]} ${action.payload?.data[0][1]}`;
          const intervalEnd = `${action.payload?.data[1][0]} ${action.payload?.data[1][1]}`;

          if (moment(intervalEnd).diff(moment(intervalStart)) == 15 * 60 * 1000) {
            ev2Frequency = 4;
          } else {
            ev2Frequency = 1;
          }
        }
        if (startDate && endDate) {
          return {
            ...state,
            startDate,
            endDate,
            solarScaleRating,
            solarFrequency,
            loadFrequency,
            lmpDAFrequency,
            lmpRTFrequency,
            ev1Frequency,
            ev2Frequency,
            unSavedChanges: false
          };
        } else {
          return state;
        }
      }
      return state;

    default:
      return state;
  }
}
