import React, { useState, useEffect } from 'react';
import moment from 'moment';

// MATERIAL UI IMPORTS
import { colors } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TariffFilters as TariffFiltersType } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'inherit',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      padding: '20px 35px'
    },
    menu: {
      minWidth: 200
    },
    item: {
      padding: 20
    },
    optionsContainer: {
      marginTop: '30px'
    },
    bold: {
      fontWeight: 'bold',
      color: colors.common.black,
    },
    filtersHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    typeContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterType: {
      marginRight: '15px',
      display: 'block',
      position: 'relative',
      color: colors.grey[500],
      cursor: 'pointer',

      '&::after': {
        content: '"|"',
        transform: 'translateX(10px)',
        position: 'absolute',
        right: '0',
      },

      '&:last-child::after': {
        content: '""',
      }
    },
    actions: {
      padding: 20,
      textAlign: 'right',
      marginTop: 'auto',
    },
    submit: {
      marginLeft: '30px',
      width: '150px',
    },
    fullWidth: {
      width: '100%',
    }
  })
);

interface TariffFiltersProps {
  toggleFilterDrawer: () => void;
  handleFilterUpdate: (f: TariffFiltersType) => void;
  filters: TariffFiltersType;
}

const INITIAL_STATE: TariffFiltersType = {
  zipCode: '',
  country: 'US',
  lseId: '',
  effectiveOn: null,
  search: '',
  monthlyConsumptionAverage: '',
  annualMaxDemand: '',
  timeOfUse: false,
  tiered: false,
  contracted: false,
  netMetering: false,
  residential: false,
  general: true,
  specialUse: false,
  tariffTypeTariff: true,
  tariffTypeRider: false,
  tariffTypeProposed: false,
  pageStart: 0,
  pageCount: 25,
  count: 0
};

const TariffFilters = ({ toggleFilterDrawer, handleFilterUpdate, filters }: TariffFiltersProps) => {
  const styles = useStyles();
  const [tariffFilters, setTariffFilters] = useState<TariffFiltersType>(INITIAL_STATE);

  useEffect(() => {
    if (filters && Object.keys(filters).length) {
      setTariffFilters(filters);
    }
  }, [filters]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTariffFilters({ ...tariffFilters, [event.target.name]: event.target.value });
  };

  const handleCheckChange = (name: keyof TariffFiltersType) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setTariffFilters({ ...tariffFilters, [name]: checked });
  };

  const handleDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    setTariffFilters({ ...tariffFilters, effectiveOn: moment(date).format('YYYY-MM-DD') });
  };

  // NOTE: Commented since at this moment only `general` is needed
  // const handleOnClickCustomerClass = (event: React.MouseEvent<HTMLSpanElement>) => {
  //   const element = event.target as HTMLInputElement;
  //   const elementName = element.getAttribute('data-name') as keyof TariffFiltersType;

  //   // TODO: When needed iterate over residential, general, specialUse and set all to false
  //   setTariffFilters({ ...tariffFilters, [elementName]: !!tariffFilters[elementName] });
  // };

  const handleUpdate = () => {
    handleFilterUpdate(tariffFilters);
    toggleFilterDrawer();
  };

  return (
    <Paper className={styles.root}>
      <section className={styles.container}>
        <div className={styles.filtersHeader}>
          <Typography variant="h5" className={styles.bold}>
            Tariff Filters
          </Typography>
          {/* <div className={styles.typeContainer}>
            <span className={styles.filterType} data-name="residential" onClick={handleOnClickCustomerClass}>Residential</span>
            <span className={styles.filterType} data-name="general" onClick={handleOnClickCustomerClass}>General</span>
            <span className={styles.filterType} data-name="specialUse" onClick={handleOnClickCustomerClass}>Special Use</span>
          </div> */}
        </div>
        <div>
          <TextField
            id="country"
            name="country"
            value={tariffFilters.country}
            onChange={handleChange}
            select
            label="Country"
            InputLabelProps={{ shrink: true }}
            fullWidth
            SelectProps={{
              MenuProps: {
                className: styles.menu
              }
            }}
            margin="normal"
          >
            <MenuItem value="US">USA</MenuItem>
            <MenuItem value="CA">Canada</MenuItem>
          </TextField>
          <TextField
            id="zipCode"
            name="zipCode"
            label="ZIP Code"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={tariffFilters.zipCode}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            id="lseId"
            name="lseId"
            value={tariffFilters.lseId}
            onChange={handleChange}
            select
            label="Energy Provider"
            InputLabelProps={{ shrink: true }}
            fullWidth
            SelectProps={{
              MenuProps: {
                className: styles.menu
              }
            }}
            margin="normal"
          >
            <MenuItem value="1071">SDGE</MenuItem>
            <MenuItem value="734">PGE</MenuItem>
            <MenuItem value="1228">SCE</MenuItem>
          </TextField>

          <KeyboardDatePicker
              className={styles.fullWidth}
              autoOk
              id="effectiveOn"
              name="effectiveOn"
              label="Effective On"
              value={tariffFilters.effectiveOn}
              format="YYYY-MM-DD"
              onChange={handleDateChange}
            />

          <div className={styles.optionsContainer}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={styles.bold}>Tariff Type</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="tariffTypeTariff"
                      checked={tariffFilters.tariffTypeTariff}
                      onChange={handleCheckChange('tariffTypeTariff')}
                    />
                  }
                  label="Tariff"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="tariffTypeProposed"
                      checked={tariffFilters.tariffTypeProposed}
                      onChange={handleCheckChange('tariffTypeProposed')}
                    />
                  }
                  label="Proposed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="tariffTypeRider"
                      checked={tariffFilters.tariffTypeRider}
                      onChange={handleCheckChange('tariffTypeRider')}
                    />
                  }
                  label="Rider"
                />
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </section>
      <section className={styles.actions}>
        <Button color="inherit" onClick={toggleFilterDrawer}>
          Cancel
        </Button>
        <Button className={styles.submit} onClick={handleUpdate} variant="contained" color="primary">
          Update
        </Button>
      </section>
    </Paper>
  );
};

export default TariffFilters;
