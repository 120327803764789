import React from 'react';
import moment from 'moment';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { ESAPTariff } from '../../../types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createLoadingSelector } from '../../../selectors';
import { useSelector } from 'react-redux';

type ColumnData = { id: keyof ESAPTariff; numeric: boolean; disablePadding: boolean; label: string };
const columnData: ColumnData[] = [
  { id: 'lse_name', numeric: false, disablePadding: true, label: 'Utility' },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Name'
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Code'
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Type'
  },
  {
    id: 'effective_date',
    numeric: false,
    disablePadding: false,
    label: 'Effective Date'
  }
];

const columnDataManualTariffs: ColumnData[] = [
  { id: 'lse_name', numeric: false, disablePadding: true, label: 'Utility' },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Name'
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Code'
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tariff Type'
  },
  {
    id: 'effective_date',
    numeric: false,
    disablePadding: false,
    label: 'Effective Date'
  },
  {
    id: 'created_on',
    numeric: false,
    disablePadding: false,
    label: 'Created'
  },
  {
    id: 'created_user',
    numeric: false,
    disablePadding: false,
    label: 'Created By'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    overflowX: 'auto'
  },
  cell: {
    fontSize: '0.825rem'
  }
}));

function desc<T>(a: T, b: T, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

type Order = 'asc' | 'desc';

function getSorting(order: Order, orderBy: string): (a: ESAPTariff, b: ESAPTariff) => number {
  return order === 'desc' ? (a, b) => desc<ESAPTariff>(a, b, orderBy) : (a, b) => -desc<ESAPTariff>(a, b, orderBy);
}

interface Props {
  handleSort: any;
  tariffs: ESAPTariff[];
  orderBy: string;
  order: Order;
  viewManualTariffs: boolean;
  selected: any[];
  handleRowClick(tariff: ESAPTariff): any;
  onRequestSort(event: React.MouseEvent<unknown>, property: string): any; //replace string by keyof OrderBY
}

const TariffSelectList: React.FC<Props> = props => {
  const { tariffs, orderBy, order, viewManualTariffs, selected, handleRowClick, onRequestSort } = props;

  const loadingSelector = createLoadingSelector(['GET_TARIFFS']);
  const isLoading = useSelector(loadingSelector);

  const classes = useStyles();
  const dataSchema = viewManualTariffs ? columnDataManualTariffs : columnData;

  const isSelected = (tariff: ESAPTariff): boolean => {
    const indexResults = selected.findIndex(selectedTariff => {
      return tariff.is_global
        ? selectedTariff.source_id === tariff.public_id
        : selectedTariff.source_id + selectedTariff.effective_date === tariff.source_id + tariff.effective_date;
    });
    return indexResults !== -1;
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    // instead of string do keyof Data
    onRequestSort(event, property);
  };

  return (
    <div className={classes.tableWrapper}>
      {isLoading && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {!isLoading && (
        <>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {dataSchema.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      //numeric={column.numeric ? column.numeric : false}
                      padding="default"
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                        {/* {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null} */}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort<ESAPTariff>(tariffs, getSorting(order, orderBy)).map((tariff, index) => {
                const isTariffSelected = isSelected(tariff);
                return (
                  <TableRow
                    hover
                    onClick={event => handleRowClick(tariff)}
                    style={{ cursor: 'pointer' }}
                    role="checkbox"
                    aria-checked={isTariffSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isTariffSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isTariffSelected} />
                    </TableCell>
                    <TableCell scope="row" padding="default" className={classes.cell}>
                      {tariff.lse_name}
                    </TableCell>
                    <TableCell className={classes.cell} padding="default">
                      {tariff.name}
                    </TableCell>
                    <TableCell className={classes.cell} padding="default">
                      {tariff.code}
                    </TableCell>
                    <TableCell className={classes.cell} padding="default">
                      {tariff.type}
                    </TableCell>
                    <TableCell className={classes.cell} padding="default">
                      {tariff.effective_date}
                    </TableCell>
                    {viewManualTariffs && (
                      <>
                        <TableCell className={classes.cell} padding="default">
                          {moment(tariff.created_on).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell className={classes.cell} padding="default">
                          {tariff.created_user}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};

export default TariffSelectList;
