import React from 'react';
import moment from 'moment';
import { Annotations, PlotMouseEvent, PlotData } from 'plotly.js';
import { OperationsData, ScenarioResults } from '../../../../types';
import { ChartLayout } from '../utils/layoutConfig';
import { Events } from '../../../../reducers';
import { assertIsDefined, assertIsTypedArray, isNumber } from '../../../../utils/assertions';
import { defaultTo, zip } from 'ramda';

export function useAnnotations(selectedMonth: string) {
  const [annotations, setAnnotations] = React.useState<Partial<Annotations>[]>([]);
  const [newAnnotation, setNewAnnotation] = React.useState({ text: '', x: 0, y: 0, isOpen: false });
  const [annotationMode, setAnnotationMode] = React.useState<'read' | 'write'>('read');

  // remove annotations if we change of billing period
  React.useEffect(() => {
    resetAnnotations();
  }, [selectedMonth]);

  const resetAnnotations = () => {
    setAnnotations([]);
  };

  const addAnnotation = (event: Readonly<PlotMouseEvent>) => {
    const point = event.points[0];
    if (typeof point.x !== 'string' || typeof point.y !== 'number') {
      throw new TypeError('Couldnt find the coordinate for the annotation');
    }
    setNewAnnotation({ x: moment(point.x as string).valueOf(), y: point.y as number, text: '', isOpen: true });
  };

  const changeAnnotation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAnnotation({ ...newAnnotation, text: e.target.value });
  };

  const closeAnnotation = () => {
    setNewAnnotation({ ...newAnnotation, text: '', isOpen: false });
  };

  const displayAnnotation = () => {
    const annotation: Partial<Annotations> = {
      visible: true,
      text: newAnnotation.text,
      x: newAnnotation.x,
      y: newAnnotation.y,
      bgcolor: 'rgba(255, 255, 255, 0.8)',
      bordercolor: 'darkGrey',
      arrowcolor: 'black',
      borderwidth: 1
    };

    setNewAnnotation({ ...newAnnotation, isOpen: false });
    setAnnotations([...annotations, annotation]);
  };

  return {
    annotations,
    newAnnotation,
    addAnnotation,
    changeAnnotation,
    closeAnnotation,
    displayAnnotation,
    resetAnnotations,
    annotationMode,
    setAnnotationMode
  };
}

export function useGAEvents(
  selectedMonth: string,
  events: Events,
  isGA: boolean,
  scenarioData: Record<string, OperationsData>
) {
  const [GAEventSelected, setGAEventSelected] = React.useState<string | null>(null);
  const [GAEvents, setGAEvents] = React.useState<string[]>([]);
  const [GAEventsPredicted, setGAEventsPredicted] = React.useState<string[]>([]);

  React.useEffect(() => {
    resetGAEventSelected();
  }, [selectedMonth]);

  React.useEffect(() => {
    if (isGA && events?.GAEvents && events?.GAEvents.length > 0) {
      // TODO: its broken, nothings happening when a GA event is selected
      const GAEvents_ = events.GAEvents
        ? events.GAEvents.map(event => event.toString().slice(0, 10)).filter(isGAEventInScenarioData)
        : [];
      const GAEventsPredicted_ = events.GAEventsPredicted
        ? events.GAEventsPredicted.map(event => event.toString().slice(0, 10)).filter(isGAEventInScenarioData)
        : [];
      setGAEventsPredicted(GAEventsPredicted_);
      setGAEvents(GAEvents_);
    }
  }, [events.GAEvents]);

  const isGAEventInScenarioData = (GAEvent: string) => {
    const datesData = Object.keys(scenarioData);
    return datesData.indexOf(GAEvent.slice(0, 7)) > -1;
  };

  const changeGAEventSelected = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (typeof e.target.value === 'string') {
      setGAEventSelected(e.target.value);
    }
  };
  const resetGAEventSelected = () => {
    setGAEventSelected(null);
  };

  return { GAEvents, GAEventSelected, GAEventsPredicted, changeGAEventSelected };
}

export function useChartConfig() {
  const [chartConfig, setChartConfig] = React.useState<{
    chartLayout: ChartLayout;
    TOUOpacity: number;
    showPeakEventCharts: boolean;
    showTOU: boolean;
    showPeak: boolean;
    showPeaksBefore: boolean;
    showPeaksAfter: boolean;
    showAnnotations: boolean;
    flipChargeDischarge: boolean;
    hideHoverInfo: boolean;
    hoverInfo: boolean;
  }>({
    chartLayout: 'none',
    TOUOpacity: 0.4,
    showPeakEventCharts: false,
    showTOU: true,
    showPeak: true,
    showPeaksBefore: true,
    showPeaksAfter: true,
    showAnnotations: true,
    flipChargeDischarge: false,
    hideHoverInfo: false,
    hoverInfo: false
  });

  const toggleChartConfig = (event: any) => {
    const { name, checked } = event.target;
    setChartConfig({ ...chartConfig, [name]: checked });
  };
  return { chartConfig, setChartConfig, toggleChartConfig };
}

export function useMaxPeaks(
  statusQuoResults: ScenarioResults,
  scenarioResults: ScenarioResults,
  stateData: Partial<PlotData>[],
  scenarioData: Record<string, OperationsData>,
  monthSelected: string
) {
  assertIsDefined(scenarioResults);
  assertIsDefined(statusQuoResults);
  const months = Object.keys(scenarioData).map(dateKey => scenarioData[dateKey].month);
  const [maxPeaks, setMaxPeaks] = React.useState({
    maxNCBefore: {
      x: 0,
      y: 0
    },
    maxOnPeakBefore: {
      x: 0,
      y: 0
    },
    maxNCAfter: {
      x: 0,
      y: 0
    },
    maxOnPeakAfter: {
      x: 0,
      y: 0
    }
  });

  // yearMonth :: YYYY-MM or YYYY-MM-DD fro billing cycles
  const getMaxPeaks = (yearMonth: string) => {
    const keyIndex = scenarioResults['Months'].findIndex(month => {
      return yearMonth.length === 7
        ? moment(month, 'MM/DD/YYYY').format('YYYY-MM') === moment(yearMonth).format('YYYY-MM')
        : moment(month, 'MM/DD/YYYY').format('YYYY-MM-DD') === moment(yearMonth).format('YYYY-MM-DD');
    });
    const max = {
      maxNCBefore: {
        x: getMaxTimestamp('Before-Demand Max NC', statusQuoResults['Demand Max NC'][keyIndex], keyIndex),
        y: statusQuoResults['Demand Max NC'][keyIndex]
      },
      maxOnPeakBefore: {
        x: getMaxTimestamp('Before-Demand Max Peak', statusQuoResults['Demand Max Peak'][keyIndex], keyIndex),
        y: statusQuoResults['Demand Max Peak'][keyIndex]
      },
      maxNCAfter: {
        x: getMaxTimestamp('After-Demand Max NC', scenarioResults['Demand Max NC'][keyIndex], keyIndex),
        y: scenarioResults['Demand Max NC'][keyIndex]
      },
      maxOnPeakAfter: {
        x: getMaxTimestamp('After-Demand Max Peak', scenarioResults['Demand Max Peak'][keyIndex], keyIndex),
        y: getKW('After-Demand Max Peak', keyIndex) // needs a special processing for Option S tariff which Max Peak Demand is actually an average
      }
    };
    return max;
  };

  const selectResults = (type: string): [NonNullable<ScenarioResults>, string, string] => {
    const [prefix, peak] = type.split('-');
    return [prefix === 'Before' ? statusQuoResults : scenarioResults, prefix, peak];
  };

  const getMaxTimestamp = (type: string, target: number, index: number): number => {
    const [results, prefix, peak] = selectResults(type);
    try {
      if (results[`${peak} Datetime`]) {
        return moment(results[`${peak} Datetime`][index]).valueOf();
      } else if (stateData && stateData.length > 0) {
        // for older scenarios that dont have Peak Datetime in results
        const dataStreamName = prefix === 'Before' ? 'Current Demand' : 'Net Demand';
        const data = stateData.find(plot => plot.name === dataStreamName);
        assertIsDefined(data);

        assertIsTypedArray(data.y, isNumber);
        assertIsTypedArray(data.x, isNumber);

        const index = data.y.findIndex(demand => demand.toFixed(1) == target.toFixed(1));
        if (index < 0) throw new Error('Cant find max peak index.');
        return data.x[index];
      } else {
        return moment(scenarioResults.Months[index]).valueOf();
      }
    } catch {
      return moment(scenarioResults.Months[index]).valueOf();
    }
  };

  // look up the peak demand from the 'Max Peak Demand Datetime ' if present
  // specially useful for Option S tariffs where the 'Demand Max Peak' returned
  // by the optimizer is different, a daily average.
  const getKW = (type: string, index: number): number => {
    const [results, _, peak] = selectResults(type);
    if (!results[`${peak} Datetime`]) return results[peak][index];

    const peakDatetime = moment(results[`${peak} Datetime`][index]).valueOf();

    // first look for a Net demand Solar
    let netDemandPlot = stateData.find(data => data.name === 'Net Demand Solar');

    // if no solar then the net load is the Net Demand Plot
    if (!netDemandPlot) {
      netDemandPlot = stateData.find(data => data.name === 'Net Demand');
    }

    // Fallback if not stateDate undefined (chart not plotted yet)
    if (!netDemandPlot) return 0;

    const [, peakKW] = defaultTo([0, 0])(
      zip(netDemandPlot.x as number[], netDemandPlot.y as number[]).find(tuple => tuple[0] === peakDatetime)
    );

    return peakKW;
  };
  React.useEffect(() => {
    setMaxPeaks(getMaxPeaks(months[0]));
  }, [scenarioData]);

  React.useEffect(() => {
    if (stateData) {
      setMaxPeaks(getMaxPeaks(monthSelected));
    }
  }, [stateData, monthSelected]);

  return { maxPeaks };
}
