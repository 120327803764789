import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { calculateNCTotal, calculateTotal } from '../../../utility/Tariff';

import { TariffOverview } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    tariffPresentation: {
      width: '100%',
      minWidth: '1000px',
      display: 'flex',
      margin: '0 10px 20px',
      boxShadow: 'none'
    },
    tariffPresentationContainer: {
      width: '100%'
    },
    tariffColumn: {
      width: '15%',
      height: '100%',
      backgroundColor: '#001a70',
      color: 'white',
      float: 'left',
      display: 'flex',
      padding: '12px',
      alignItems: 'center',
      fontSize: '1.4em'
    },
    tableColumn: {
      width: '80%',
      float: 'left'
    },
    seasonsContainer: {
      width: '85%',
      float: 'left'
    },
    seasonContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      '&:nth-of-type(even)': {
        backgroundColor: '#cdcfd8'
      },
      '&:nth-of-type(odd)': {
        backgroundColor: '#dce5ec'
      }
    },
    seasonColumn: {
      width: '20%',
      height: '100%',
      float: 'left',
      margin: 'auto 0',
      paddingLeft: '20px'
    },
    breakdownTableColumn: {
      width: '25%',
      height: '30px',
      textAlign: 'left',
      fontSize: '1em',
      padding: '0',
      border: 'none'
    },
    input: {
      fontSize: '.8em',
      textTransform: 'uppercase'
    },
    tariffinput: {
      color: 'white'
    }
  })
);

interface Props {
  tariffOverview: TariffOverview;
  seasonNames: string[];
  tariffName: string;
}

const TariffPresentation: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.tariffPresentation}>
      <div className={classes.tariffPresentationContainer}>
        <div className={classes.tariffColumn}>
          <TextField
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.tariffinput
              }
            }}
            defaultValue={props.tariffName}
            multiline
          />
        </div>
        <div className={classes.seasonsContainer}>
          {props.seasonNames.map(seasonName => {
            const season = props.tariffOverview.seasons[seasonName];
            const baseAdjustmentEnergy = props.tariffOverview.consumptionBaseAdjustment;
            //const baseAdjustmentDemand = this.props.overview.demandBaseCharges;
            const touPeriodNames = Object.keys(season).filter(
              item => item !== 'overview' && item !== 'NON_COINCIDENTAL'
            );
            const seasonDisplayName =
              props.tariffOverview.seasons[seasonName].overview &&
              props.tariffOverview.seasons[seasonName].overview.name
                ? props.tariffOverview.seasons[seasonName].overview.name
                : seasonName;

            return (
              <div className={classes.seasonContainer}>
                <div className={classes.seasonColumn}>
                  <TextField
                    defaultValue={seasonDisplayName}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input
                      }
                    }}
                  />
                </div>
                <div className={classes.tableColumn}>
                  {touPeriodNames.map(touName => {
                    return (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.breakdownTableColumn}>
                              <div>
                                <TextField
                                  InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                      input: classes.input
                                    }
                                  }}
                                  defaultValue={touName.replace('_', ' ').replace('_', ' ')}
                                />
                              </div>
                            </TableCell>
                            <TableCell className={classes.breakdownTableColumn}>
                              <div style={{ fontWeight: 500 }}>
                                ${' '}
                                {parseFloat(
                                  calculateTotal(season[touName], 'CONSUMPTION_BASED', baseAdjustmentEnergy).toFixed(5)
                                )}{' '}
                                / kWh
                              </div>
                            </TableCell>
                            <TableCell className={classes.breakdownTableColumn}>
                              <div>
                                <TextField
                                  InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                      input: classes.input
                                    }
                                  }}
                                  defaultValue={touName.replace('_', ' ').replace('_', ' ')}
                                />
                              </div>
                            </TableCell>
                            <TableCell className={classes.breakdownTableColumn}>
                              <div style={{ fontWeight: 500 }}>
                                $ {parseFloat(calculateTotal(season[touName], 'DEMAND_BASED').toFixed(5))} / kW
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    );
                  })}
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.breakdownTableColumn}>
                          <div></div>
                        </TableCell>
                        <TableCell className={classes.breakdownTableColumn}>
                          <div></div>
                        </TableCell>
                        <TableCell className={classes.breakdownTableColumn}>
                          <div>
                            <TextField
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.input
                                }
                              }}
                              defaultValue="NON COINCIDENTAL"
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.breakdownTableColumn}>
                          <div style={{ fontWeight: 500 }}>
                            $ {parseFloat(calculateNCTotal(season, props.tariffOverview?.demandBaseCharges).toFixed(5))}{' '}
                            / kW
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Paper>
  );
};

export default TariffPresentation;
