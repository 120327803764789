import { setIn } from 'immutable';
import { CREATE_UTILITY_INFO_SUCCESS, RESET_UTILITY_INFO, UTILITY_INPUT_FORM_CHANGE } from '../../actions/utilities';
// import { UtilityData } from '../../types';

const INITIAL_STATE = {
  utilityDetails: {},
  calendars: [],
  utilityInfo: {
    pdp_information: [],
    bip_information: []
  }
};

export default function utilityReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case RESET_UTILITY_INFO:
      return INITIAL_STATE;
    case CREATE_UTILITY_INFO_SUCCESS:
      return { ...state, utilityInfo: action.payload };
    case 'PDP_FORM_CHANGE':
      const newState = setIn(state, ['utilityInfo', 'pdp_information'], action.payload);
      return newState;
    case 'BIP_FORM_CHANGE':
      return setIn(state, ['utilityInfo', 'bip_information'], action.payload);
    case UTILITY_INPUT_FORM_CHANGE:
      return Object.assign(state, action.payload);
    default:
      return state;
  }
}
