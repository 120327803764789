import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import auth0Client from '../utility/Auth';
import { parseQuery } from '../utility/General';

class Callback extends Component {
  async componentDidMount() {
    const queryParams = parseQuery(window.location.search);
    if (queryParams.access_token && queryParams.expires_in) {
      auth0Client.handleTestAuthentication(queryParams.access_token, queryParams.id_token, queryParams.expires_in);
      this.props.history.replace(localStorage.getItem('redirect_url') || '/');
    } else {
      try {
        await auth0Client.handleAuthentication();
        this.props.history.replace(localStorage.getItem('redirect_url') || '/');
      } catch (error) {
        console.error('Error while authenticating: ' + error);
      }
    }
  }

  render() {
    return <p>Logging into ESAP...</p>;
  }
}

export default withRouter(Callback);
