import auth0client from './Auth';

export type Permission = 'viewer' | 'editor' | 'admin';

export const isAdmin = (): boolean => {
  const userRoles: Permission[] = auth0client.getRoles();
  return userRoles && Array.isArray(userRoles) ? userRoles.includes('admin') : false;
};

const checkPermissions = (requiredPermissions: Permission[], editorHasPermission: boolean): boolean => {
  const userRoles: Permission[] = auth0client.getRoles();
  if (requiredPermissions.length === 0) {
    return true;
  }
  if (requiredPermissions.includes('editor')) {
    // editorHasPermission default to true except specified differently (ie proposal.is_editable)
    return userRoles.includes('editor') && editorHasPermission;
  }
  return userRoles.some(permission => requiredPermissions.includes(permission));
};

type AccessControlProps = {
  requiredPermissions: Permission[];
  children?: JSX.Element;
  renderNoAccess: () => JSX.Element;
  disableTextInput: boolean;
};

const defaultProps = {
  disableTextInput: false,
  editorHasPermission: true,
  requiredPermissions: [] as Permission[],
  renderNoAccess: () => <div />
};

type Props = AccessControlProps & typeof defaultProps;

const AccessControl = ({ requiredPermissions, children, renderNoAccess, editorHasPermission }: Props): JSX.Element => {
  const permitted = checkPermissions(requiredPermissions, editorHasPermission);

  if (permitted && children) {
    return children;
  }

  return renderNoAccess();
};

AccessControl.defaultProps = defaultProps;

export default AccessControl;
