import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import { useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

// types and interfaces
import { ProposalInternal, ESAPTariff } from '../../types';
import { EquipmentValues, Field, Template } from '../../components/Utilities/Templates/Template';

import { CAISOTemplateFactory, TemplateFactory } from '../../components/Utilities/Templates/TemplateFactory';

// import { getTariff } from '../../actions/tariffs';

interface Props {
  open: boolean;
  handleClose: () => void;
  tariffs: ESAPTariff[];
  proposal: ProposalInternal;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 40
    },
    block: {
      marginTop: theme.spacing(2)
    }
  })
);

// type Action = { type: keyof IState; payload: any };

// const reducer = (state: IState, action: Action): IState => {
//   switch (action.type) {
//     case ScenarioKeys.standardStatusQuo:
//       return { ...state, [ScenarioKeys.standardStatusQuo]: action.payload };
//     case ScenarioKeys.solarTariffStatusQuo:
//       return { ...state, [ScenarioKeys.solarTariffStatusQuo]: action.payload };
//     case ScenarioKeys.solarOnly:
//       return { ...state, [ScenarioKeys.solarOnly]: action.payload };
//     case ScenarioKeys.solarStorageForced:
//       return { ...state, [ScenarioKeys.solarStorageForced]: action.payload };
//     case ScenarioKeys.solarStorageNonForced:
//       return { ...state, [ScenarioKeys.solarStorageNonForced]: action.payload };
//     case 'reset':
//       return action.payload;
//     default:
//       throw new Error();
//   }
// };
// interface IState {
//   reset: string;
//   [ScenarioKeys.standardStatusQuo]: string;
//   [ScenarioKeys.solarTariffStatusQuo]: string;
//   [ScenarioKeys.solarOnly]: string;
//   [ScenarioKeys.solarStorageForced]: string;
//   [ScenarioKeys.solarStorageNonForced]: string;
// }

const CreateNewTemplateContainer: React.FC<Props> = props => {
  const { open, handleClose, tariffs, proposal } = props;
  const classes = useStyles();

  // const dispatch = useDispatch();

  const templateLabels = ['', 'Solar + Storage CAISO', 'Solar + Storage CAISO - different tariff'];

  const [templateSelection, setTemplateSelection] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState<Template>();

  const [requiredFields, setRequiredFields] = useState<Field[]>([]);

  const initialValues: EquipmentValues = {
    power: '',
    capacity: '',
    pInstPV: '',
    tariffStatusQuo: '',
    tariffSolar: ''
  };

  const [equipmentValues, setEquipmentValues] = useState<EquipmentValues>(initialValues);

  // const handleMenuChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
  //   dispatch({ type: event.target.name as keyof IState, payload: event.target.value as string });
  // };

  // const handlePopulateNBC = (tariffId: string): void => {
  //   let currentTariff = findSelectedTariff(tariffId);
  //   if (currentTariff && currentTariff.is_manual === false) {
  //     dispatch(getTariff(currentTariff.source_id, currentTariff.effective_date, currentTariff.applicability_values));
  //   }
  // };

  // // on each page load
  // useEffect(() => {
  //   // temporary fix for typescript not to crash
  //   handlePopulateNBC('1234');
  // }, [handlePopulateNBC]);

  //when the selected template is updated, get the template fields
  useEffect(() => {
    let templateFactory: TemplateFactory | undefined;

    if (templateSelection === '') {
      setRequiredFields([]);
    } else if (templateSelection === 'Solar + Storage CAISO') {
      templateFactory = new CAISOTemplateFactory('solar+storage');
    } else if (templateSelection === 'Solar + Storage CAISO - different tariff') {
      templateFactory = new CAISOTemplateFactory('solar+storage-different_tariff');
    }

    if (templateFactory) {
      const template = templateFactory.init();
      setCurrentTemplate(template);

      const requiredFields = template.getFields();
      setRequiredFields(requiredFields);
    }
  }, [templateSelection]);

  const handleEquipmentChange = (event: React.ChangeEvent<{ name?: string; value: any }>): void => {
    if (event.target.name) {
      setEquipmentValues({
        ...equipmentValues,
        [event.target.name]: event.target.value
      });
    }
  };

  // const findSelectedTariff = (tariffId: string): ESAPTariff | undefined => {
  //   return tariffs.find(tariff => {
  //     return tariff.public_id === tariffId;
  //   });
  // };
  // TODO: Fix any here
  const handleTemplateSelection = (event: React.ChangeEvent<{ name?: string; value: any }>): void => {
    // const newSelection = templates.find(el => el.label === event.target.value);
    setTemplateSelection(event.target.value);
  };

  const handleBuildTariffSelect = (requiredFields: Field[]) => {
    return requiredFields
      .filter(field => field.assumption_key === 'tariffId')
      .map((field, index) => {
        return (
          <div key={field.variable_key} style={{ marginBottom: 8 }}>
            <FormControl key={field.variable_key}>
              <InputLabel htmlFor={`select-tariff-${field.variable_key}`}>Select {field.display_name}</InputLabel>
              <Select
                style={{ width: 240, marginBottom: 8 }}
                value={equipmentValues[field.variable_key]}
                onChange={handleEquipmentChange}
                input={<Input id={`select-tariff-${field.variable_key}`} name={field.variable_key} />}
              >
                {tariffs &&
                  tariffs.map(tariff => (
                    <MenuItem key={tariff.public_id} value={tariff.public_id}>
                      {tariff.code} - Effective: {tariff.effective_date}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        );
      });
  };

  const handleBuildFields = (requiredFields: Field[]) => {
    return requiredFields
      .filter(field => field.assumption_key !== 'tariffId')
      .map(field => {
        return (
          <div>
            <TextField
              label={field.display_name}
              key={field.variable_key}
              name={field.assumption_key}
              value={equipmentValues[field.variable_key]}
              onChange={handleEquipmentChange}
              InputLabelProps={{ shrink: true }}
              margin="normal"
            />
          </div>
        );
      });
  };

  const handleBlankTemplateCreation = () => {
    if (currentTemplate) {
      currentTemplate.createBlankTemplate(proposal.public_id, currentTemplate);
    }
    props.handleClose();
    setEquipmentValues(initialValues);
  };
  const handleScenariosCreation = () => {
    if (currentTemplate) {
      currentTemplate.generateScenarios(proposal.public_id, equipmentValues, currentTemplate);
    }
    props.handleClose();
    setEquipmentValues(initialValues);
  };

  const handleCancel = () => {
    props.handleClose();
    setEquipmentValues(initialValues);
  };

  return (
    <Drawer
      anchor="right"
      aria-labelledby="Create Template Modal"
      aria-describedby="Create a Template from scratch or assign already existing scenarios"
      open={open}
      onClose={handleClose}
    >
      <div className={classes.container}>
        <DialogTitle id="simple-dialog-title"> Create New Template</DialogTitle>
        <div className={classes.block}>
          <FormControl>
            <InputLabel htmlFor="select-template">Select Template</InputLabel>
            <Select
              style={{ width: 240 }}
              value={templateSelection}
              onChange={handleTemplateSelection}
              inputProps={{
                name: 'label',
                id: 'select-template'
              }}
            >
              {templateLabels.map(template => (
                <MenuItem key={template} value={template}>
                  {template}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.block}>
          <Typography variant="h6" gutterBottom>
            Tariff
          </Typography>
          {handleBuildTariffSelect(requiredFields)}
        </div>
        <div className={classes.block}>
          <Typography variant="h6" gutterBottom>
            Equipment
          </Typography>
          <>{handleBuildFields(requiredFields)}</>
        </div>
        <DialogActions>
          <Button
            onClick={handleBlankTemplateCreation}
            disabled={!Boolean(templateSelection)}
            color="secondary"
            aria-label="create blank template"
          >
            Blank Template
          </Button>
          <Button onClick={handleCancel} color="primary" aria-label="cancel">
            Cancel
          </Button>
          <Button onClick={handleScenariosCreation} color="primary" autoFocus aria-label="confirm">
            Create
          </Button>
        </DialogActions>
      </div>
    </Drawer>
  );
};

export default CreateNewTemplateContainer;
