import React, { useState, useEffect } from 'react';
import Plotly from '../../../custom-plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Layout, PlotData } from 'plotly.js';

// Material UI
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// React Imports
import { PortfolioResults, Portfolio, ResultPortfolioMeter } from '../../../types';

const Plot = createPlotlyComponent(Plotly);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  toolbar: {
    borderBottom: '1px solid #e5e5e5'
  },
  flex: {
    flex: 1
  }
}));

const layout: Partial<Layout> = {
  legend: { orientation: 'h', xanchor: 'center', y: 1.2, x: 0.5 },
  margin: {
    t: 20,
    l: 60,
    r: 20,
    b: 80
  },
  xaxis: {
    title: 'Start Date',
    tickangle: -45,
    tickfont: {
      size: 8
    }
  },
  yaxis: {
    title: '$'
  }
};

interface IProps {
  portfolio: Portfolio;
}

const PortfolioResultsChartNemA: React.FC<IProps> = ({ portfolio }) => {
  const classes = useStyles();
  const [data, setData] = useState<Partial<PlotData>[]>([]);

  useEffect(() => {
    if ((portfolio?.results as PortfolioResults)?.portfolio_meters) {
      const buildData = (res: PortfolioResults): Partial<PlotData>[] => {
        const data: Partial<PlotData>[] = [];
        (res.portfolio_meters as ResultPortfolioMeter[]).forEach(meter => {
          const refMeter = portfolio.portfolio_meters.find(m => m.proposal_id == meter.proposal_id);
          const proposalName = refMeter?.proposal_name ? refMeter.proposal_name : 'NO_NAME';
          const meterData: Partial<PlotData> = {
            type: 'scatter',
            stackgroup: 'nem_savings',
            name: proposalName || '',
            x: meter.results['Months'],
            y: meter.nem_monthly
          };
          data.push(meterData);
        });

        return data;
      };
      setData(buildData(portfolio.results));
    }
  }, [portfolio.results]);
  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          Energy Savings
        </Typography>
      </Toolbar>
      <div style={{ height: 350 }}>
        <Plot
          style={{ width: '100%' }}
          useResizeHandler={true}
          data={data}
          layout={layout}
          config={{ displayModeBar: false, responsive: true }}
        />
      </div>
    </Paper>
  );
};

export default PortfolioResultsChartNemA;
