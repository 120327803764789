import { UPDATE_PORTFOLIOS_LIST_FILTERS, RESET_PORTFOLIOS_LIST_FILTERS } from '../../actions/portfolios';
const INITIAL_STATE = {
  user: '',
  tariff: [],
  createdBy: '',
  customer: '',
  showArchived: false,
  search: ''
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case UPDATE_PORTFOLIOS_LIST_FILTERS:
      return { ...state, ...action.payload };
    case RESET_PORTFOLIOS_LIST_FILTERS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
