import axios from 'axios';
import { Queues } from '../types';
const ESAP_API_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export async function getProcessingQueue(): Promise<Queues> {
  return axios.get<Queues>(`${ESAP_API_URL}/proposal/jobs`).then(res => res.data);
}

export async function removeJob(jobId: string): Promise<string> {
  return axios.delete(`${ESAP_API_URL}/proposal/jobs/${jobId}`).then(res => res.data);
}
