import React from 'react';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';

// MATERIAL UI
import { makeStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

// REACT IMPORTS
import { Portfolio, Customer } from '../../../types';
// import { StoreState } from '../../../reducers';

interface IPortfolioGeneralFormProps {
  handlePortfolioChange: any;
  handleCustomerChange?: any;
  portfolio: Portfolio;
  customers?: Customer[];
  customer?: Customer;
  isEdit?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3)
  },
  item: {
    paddingRight: theme.spacing(4)
    // marginLeft: theme.spacing(2)
  },
  typeContainer: {
    display: 'flex',
    background: '#e2ecf8',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    marginLeft: 0,
    paddingLeft: 0,
    width: 'auto',
    justifyContent: 'center'
  }
}));

const PortfolioGeneralForm: React.FunctionComponent<IPortfolioGeneralFormProps> = props => {
  const { portfolio, customer, customers, handlePortfolioChange, handleCustomerChange } = props;
  const classes = useStyles();

  // const isNew = !props.portfolio.public_id;

  const options =
    customers && customer
      ? customers.map(c => {
          return {
            value: c.public_id,
            label: c.name
          };
        })
      : undefined;

  const filterOptions = options ? createFilterOptions({ options }) : undefined;

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className={classes.typeContainer}>
          <div>
            <InputLabel id="portfolio-type-select-label">Portfolio Type</InputLabel>
            <MUISelect
              labelId="portfolio-type-select-label"
              id="portfolio-type-select"
              value={portfolio.portfolio_type || 'NEMA'}
              onChange={handlePortfolioChange}
              name="portfolio_type"
            >
              {/* <MenuItem value="GENERAL">General</MenuItem> */}
              <MenuItem value="NEMA">NEM-A</MenuItem>
            </MUISelect>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <TextField
            id="name"
            name="name"
            required
            InputProps={{
              autoFocus: true,
              required: true
              // readOnly: isEdit || isNew ? false : true
            }}
            label="Portfolio Name"
            value={portfolio.name || ''}
            onChange={handlePortfolioChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.item}>
          <TextField
            id="description"
            name="description"
            value={portfolio.description || ''}
            onChange={handlePortfolioChange}
            label="Description"
            InputLabelProps={{ shrink: true }}
            fullWidth
            // InputProps={{ readOnly: isEdit || isNew ? false : true }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.item}>
          <TextField
            id="status"
            name="status"
            value={portfolio.status || ''}
            onChange={handlePortfolioChange}
            label="Status"
            InputLabelProps={{ shrink: true }}
            fullWidth
            // InputProps={{ readOnly: isEdit || isNew ? false : true }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.item}>
          <TextField
            id="notes"
            name="notes"
            label="Notes"
            value={portfolio.notes || ''}
            onChange={handlePortfolioChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
            multiline
            // InputProps={{ readOnly: isEdit || isNew ? false : true }}
            margin="normal"
          />
        </Grid>
        {customers && (
          <Grid item xs={12} sm={12} md={12} className={classes.item}>
            <label htmlFor="customer">Customer</label>
            <Select
              name="customer"
              id="customer"
              value={portfolio.customer_id || ''}
              clearable={false}
              options={options}
              multi={false}
              filterOptions={filterOptions}
              onChange={handleCustomerChange}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PortfolioGeneralForm;
