import { IProps } from '../ScenarioMonthlyResultsTable';
import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateNetMeteringOptions = (props: IProps): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  if (props.statusQuo && props.statusQuo.assumptions.isNetMetering) {
    chartOptions.push({
      key: 'Before Energy Revenues Net Metering',
      label: 'Before Energy Revenues Net Metering',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
    chartOptions.push({
      key: 'Before Energy Revenues Net Metering On Peak',
      label: 'Before Energy Revenues Net Metering On Peak',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
    chartOptions.push({
      key: 'Before Energy Revenues Net Metering MidPeak',
      label: 'Before Energy Revenues Net Metering MidPeak',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
    chartOptions.push({
      key: 'Before Energy Revenues Net Metering OffPeak',
      label: 'Before Energy Revenues Net Metering OffPeak',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
    chartOptions.push({
      key: 'Before Net Metering Credit Balance',
      label: 'Before Net Metering Credit Balance',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
    chartOptions.push({
      key: 'Before Net Metering Credit Change',
      label: 'Before Net Metering Credit Change',
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
  }
  if (props.scenario && props.scenario.assumptions.isNetMetering) {
    chartOptions.push({
      key: 'After Energy Revenues Net Metering',
      label: 'After Energy Revenues Net Metering',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
    chartOptions.push({
      key: 'After Energy Revenues Net Metering On Peak',
      label: 'After Energy Revenues Net Metering On Peak',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
    chartOptions.push({
      key: 'After Energy Revenues Net Metering MidPeak',
      label: 'After Energy Revenues Net Metering MidPeak',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
    chartOptions.push({
      key: 'After Energy Revenues Net Metering OffPeak',
      label: 'After Energy Revenues Net Metering OffPeak',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
    chartOptions.push({
      key: 'After Net Metering Credit Balance',
      label: 'After Net Metering Credit Balance',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
    chartOptions.push({
      key: 'After Net Metering Credit Change',
      label: 'After Net Metering Credit Change',
      include: true,
      highlight: false,
      category: 'Scenario'
    });
  }
  return chartOptions;
};
