import { GET_SIMPLE_LSE_SUCCESS } from '../../actions/utilities';

const INITIAL_STATE = [];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SIMPLE_LSE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
