import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { calculateTotal, calculateNCTotal } from '../../../utility/Tariff';
import { NBCharges, Season } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3)
    },
    container: {
      padding: 20
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    }
  })
);

interface SeasonDemandProps {
  season: Season;
  baseCharges: NBCharges[];
}

const SeasonDemand: React.FC<SeasonDemandProps> = ({ season, baseCharges }) => {
  const classes = useStyles();

  const touPeriodNames = Object.keys(season).filter(item => item !== 'overview');
  const hasNC = touPeriodNames.indexOf('NON_COINCIDENTAL') > -1;
  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>{season.overview.name}</Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            {!hasNC && <TableCell>Non-Coincidental</TableCell>}
            {touPeriodNames.map(name => {
              return <TableCell key={name}>{name.replace('_', ' ')}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {!hasNC && <TableCell>$ {parseFloat(calculateNCTotal(season, baseCharges).toFixed(5))} / kW</TableCell>}
            {touPeriodNames.map(name => {
              return (
                <TableCell key={name}>
                  $ {parseFloat(calculateTotal(season[name], 'DEMAND_BASED').toFixed(5))} / kW
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SeasonDemand;
