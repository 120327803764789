export const PUSH_BREADCRUMB = 'PUSH_BREADCRUMB';
export const POP_BREADCRUMB = 'POP_BREADCRUMB';
export const RESET_BREADCRUMB = 'RESET_BREADCRUMB';

export function pushBreadcrumb(label, link) {
  return dispatch => {
    dispatch({ type: PUSH_BREADCRUMB, payload: { label, link } });
  };
}

export function popBreadcrumb() {
  return { type: POP_BREADCRUMB };
}

export function resetBreadcrumb() {
  return { type: RESET_BREADCRUMB };
}
