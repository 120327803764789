import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

//REACT IMPORTS
import TariffPresentation from './TariffPresentation';
import { createLoadingSelector } from '../../../selectors';
import ErrorDialog from '../../Common/ErrorDialog';
import { StoreState } from '../../../reducers';
import { ESAPTariff } from '../../../types';
import { ProposalDetailContext } from '../../Proposals/Detail/ProposalDetailContextProvider';

// MATERIAL UI IMPORT
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { calculateTotal, calculateNCTotal } from '../../../utility/Tariff';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import Settings from '@material-ui/icons/Settings';
import VideoLabel from '@material-ui/icons/VideoLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%'
  },
  toolbar: {
    backgroundColor: theme.palette.esap.blueDim,
    color: '#fff',
    '& button': {
      color: 'white'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  icon: {
    fill: '#fff'
  }
}));

interface TariffSummaryProps {
  tariff: ESAPTariff;
}

const TariffSummary: React.FC<TariffSummaryProps> = ({ tariff }) => {
  const { isMultiYear, validYears, selectedYears } = useContext(ProposalDetailContext);

  const { overview, name, code, effective_date } = tariff;

  const tariffName = `${name}\n${code}`;

  const [state, setState] = useState({
    selected: overview ? Object.keys(overview.seasons).filter(item => item !== 'overview') : [],
    seasonNames: [] as string[],
    open: false,
    openTariffPresentation: false,
    errorGettingTariff: false
  });

  const loadingSelector = createLoadingSelector(['GET_TARIFF_BY_ID', 'GET_MANUALTARIFF']);
  const isLoading = useSelector((state: StoreState) => loadingSelector(state));

  const classes = useStyles();

  const [selectedTariffYear, setSelectedTariffYear] = useState('');

  useEffect(() => {
    if (selectedYears.start != '0') {
      setSelectedTariffYear(selectedYears.start);
    } else if (validYears && validYears.length > 0) {
      setSelectedTariffYear(validYears[0]);
    }
  }, [validYears, selectedYears.start]);

  useEffect(() => {
    if (!overview) {
      setState(state => ({ ...state, errorGettingTariff: true }));
    } else {
      let selected = Object.keys(overview.seasons).filter(item => item !== 'overview');
      setState(state => ({ ...state, selected }));
    }
    if (overview && overview.seasons) {
      let seasonNames = Object.keys(overview.seasons).filter(
        item => item !== 'overview' && state.selected.indexOf(item) > -1
      );
      setState(state => ({ ...state, seasonNames }));
    }
  }, [overview]);

  const handleOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleOpenTariffPresentation = () => {
    setState({ ...state, openTariffPresentation: true });
  };

  const handleCloseTariffPresentation = () => {
    setState({ ...state, openTariffPresentation: false });
  };

  const handleCheck = (event: any) => {
    let updatedSelectList: string[] = ([] as string[]).concat(state.selected);
    let index = state.selected.indexOf(event.target.name);
    if (index > -1) {
      updatedSelectList.splice(index, 1);
    } else {
      updatedSelectList.push(event.target.name);
    }
    setState({ ...state, selected: updatedSelectList });
  };

  const handleCloseError = () => {
    setState({ ...state, errorGettingTariff: false });
  };

  const escalationRate = (rate: number) => (cost: number) => {
    if (!isMultiYear) return cost;
    const indexYear = validYears.findIndex(year => year === selectedTariffYear);
    if (indexYear < 1) return cost;
    for (let i = 0; i < indexYear; i++) {
      cost = cost + (cost * rate) / 100;
    }
    return cost;
  };

  const energyEscalationRate = escalationRate(2);
  const demandEscalationRate = escalationRate(4);

  return (
    <Paper className={classes.root}>
      {isLoading && (
        <div style={{ textAlign: 'center', padding: 25 }}>
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
      {!isLoading && !overview && (
        <>
          <div style={{ textAlign: 'center' }}>Problem accessing the tariff..</div>
          <ErrorDialog
            open={state.errorGettingTariff}
            errorMsg="There is a problem accessing the tariff, contact your admin if the issue persists."
            onClose={handleCloseError}
          />
        </>
      )}
      {!isLoading && overview && (
        <>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h5" color="inherit" style={{ flex: 2 }}>
              {code}
            </Typography>
            <Typography variant="h6" color="inherit" style={{ flex: 2 }}>
              {name}
            </Typography>
            <Typography variant="body1" color="inherit" style={{ marginRight: 24 }}>
              Effective: {effective_date}
            </Typography>

            {isMultiYear && (
              <FormControl style={{ color: 'white' }}>
                <Select
                  style={{ backgroundColor: '#fff', paddingLeft: 8, marginRight: 24 }}
                  labelId="select-year-tariff-label"
                  value={selectedTariffYear}
                  onChange={(e: any) => setSelectedTariffYear(e.target.value)}
                >
                  {validYears.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <IconButton aria-label="Tariff Presentation" onClick={handleOpenTariffPresentation}>
              <VideoLabel />
            </IconButton>
            <Tooltip title="Tariff Presentation">
              <IconButton aria-label="Chart Settings" onClick={handleOpen}>
                <Settings />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Time of Use</TableCell>
                <TableCell>Season</TableCell>
                <TableCell>Energy Charge</TableCell>
                <TableCell>Demand Charge</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.seasonNames.map(seasonName => {
                const season = overview.seasons[seasonName];
                // const baseAdjustmentEnergy = overview.consumptionBaseAdjustment;
                const baseAdjustmentDemand = overview.demandBaseCharges;
                const touPeriodNames = Object.keys(season).filter(
                  item => item !== 'overview' && item !== 'NON_COINCIDENTAL'
                );
                return (
                  <React.Fragment key={`${seasonName}-${season}`}>
                    {touPeriodNames.map(touName => {
                      return (
                        <TableRow key={touName}>
                          <TableCell>{touName.replace('_', ' ').replace('_', ' ')}</TableCell>
                          <TableCell>{season?.overview?.name || '-'}</TableCell>
                          <TableCell>
                            $ {energyEscalationRate(calculateTotal(season[touName], 'CONSUMPTION_BASED')).toFixed(2)} /
                            kWh
                          </TableCell>
                          <TableCell>
                            $ {demandEscalationRate(calculateTotal(season[touName], 'DEMAND_BASED')).toFixed(2)} / kW
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell>NON COINCIDENTAL</TableCell>
                      <TableCell>{season?.overview?.name || '-'}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        $ {demandEscalationRate(calculateNCTotal(season, baseAdjustmentDemand)).toFixed(2)} / kW
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
      <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
        <DialogTitle id="form-dialog-title">Chart Settings</DialogTitle>
        <DialogContent>
          {overview &&
            overview.seasons &&
            Object.keys(overview.seasons)
              .filter(item => item !== 'overview')
              .map((name, i) => {
                return (
                  <div key={i}>
                    <Checkbox name={name} checked={state.selected.indexOf(name) > -1} onClick={handleCheck} />{' '}
                    {overview.seasons[name].overview?.name || name}
                  </div>
                );
              })}
        </DialogContent>
      </Dialog>
      <Dialog
        open={state.openTariffPresentation}
        onClose={handleCloseTariffPresentation}
        aria-labelledby="tariff-presentation"
        maxWidth="xl"
      >
        <DialogTitle id="tariff-presentation">Tariff Presentation</DialogTitle>
        <DialogContent>
          <TariffPresentation tariffOverview={overview} seasonNames={state.seasonNames} tariffName={tariffName} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTariffPresentation} color="primary" aria-label="cancel">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default TariffSummary;
