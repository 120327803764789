import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// REACT IMPORTS
import { addExploreTariff, UPDATE_EXPLORE_PAGE_INFORMATION } from '../../actions/explore';
import MonthlyTimeSeries from './MonthlyTimeSeries';
import ForecastSummary from './ForecastSummary';
import LoadSummary from './LoadSummary';
import SolarSummary from './SolarSummary';
import { rollupData, rollupSolar, getTopDayPeaks, getTopNPeaks } from '../../utility/Explore';
import { interpolate, aggregateData } from '../../utility/General';
import ExploreTariffCreateContainer from './ExploreTariffCreateContainer';
import { getCalculatedBill } from '../../actions/tariffs';
import SeasonalProfiles from './SeasonalProfiles';
import LoadPeakSummaryTable from './LoadPeakSummaryTable';
import { StoreState } from '../../reducers';
import { hours } from '../../utility/General';
import MonthlyProfiles from './MonthlyProfiles';

// MATERIAL UI IMPORTS
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Data } from 'plotly.js';
import { ESAPTariff } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 24
    },
    card: {
      margin: 10,
      cursor: 'pointer'
    },
    actions: {
      display: 'flex'
    },
    flex: {
      flex: 1
    },
    button: {
      margin: 5,
      float: 'right'
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    buttonContainer: {
      position: 'absolute',
      top: 12,
      right: theme.spacing(2),
      zIndex: 10000
    },
    buttonAbsolute: {
      color: '#fff',
      marginRight: 8
    }
  })
);

const AnalysisOverview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loadProfile = useSelector((state: StoreState) => state.loadProfile);
  const solarProfile = useSelector((state: StoreState) => state.solarProfile);
  // const loadSummary = useSelector((state: StoreState) => state.loadSummary);
  const exploreDetailsMetaInfo = useSelector((state: StoreState) => state.exploreDetailsMetaInfo);
  const tariffs = useSelector((state: StoreState) => state.exploreTariffs);

  const [state, setState] = useState({ showTariff: false, showConfig: false });

  const buildMonthlyData = (monthIndex: string, summary: any, solarSummary?: any): Data[] => {
    if (summary && summary[monthIndex] && Object.keys(summary[monthIndex]).length > 0) {
      // get data slicing index of current month
      const is15minLoad = exploreDetailsMetaInfo?.loadFrequency === 4;
      const is1hrSolar = exploreDetailsMetaInfo?.solarFrequency === 1;

      let startIndex = summary[monthIndex].startDataIndex;
      let endIndex = summary[monthIndex].endDataIndex;
      let monthData = loadProfile.slice(startIndex, endIndex + 1);
      let startDate = moment(monthData[0][0], 'MM/DD/YYYY');
      const x: number[] = [];
      const y: number[] = [];
      for (let i = 0; i < monthData.length; i++) {
        x.push(startDate.valueOf());
        y.push(+monthData[i][2]);
        startDate.add(is15minLoad ? 15 : 60, 'minutes');
      }
      const data: Data[] = [
        {
          type: 'scatter',
          mode: 'lines',
          name: 'Load',
          fill: 'tozeroy',
          x: x,
          y: y,
          line: { color: 'rgba(0, 0, 0, .6)', width: 1 }
        }
      ];

      if (solarSummary && solarSummary[monthIndex] && Object.keys(solarSummary[monthIndex]).length > 0) {
        let solarScaleRating = exploreDetailsMetaInfo.solarScaleRating;
        if (solarScaleRating === 0) {
          solarScaleRating = 1;
        }
        let solarStart = solarSummary[monthIndex].startDataIndex;
        let solarEnd = solarSummary[monthIndex].endDataIndex;
        let solarMonthData = solarProfile.slice(solarStart, solarEnd + 1);
        let solarStartDate = moment(solarMonthData[0][0], 'MM/DD/YYYY');
        let hasSolar = false;

        if (!is15minLoad && !is1hrSolar) {
          const hourlyAverage = aggregateData(4);
          solarMonthData = hourlyAverage(solarMonthData) as [string, string, number][];
        }

        const solar_x: number[] = [];
        const solar_y: number[] = [];
        const solar_net_load_y: number[] = [];
        for (let i = 0; i < solarMonthData.length - 1; i++) {
          if (!hasSolar && +solarMonthData[i][2] > 0) {
            hasSolar = true;
          }

          if (is1hrSolar) {
            if (is15minLoad) {
              // 1hr solar vs 15min load data
              // convert from more than 15 minute frequency to 15 minutes
              let points = interpolate(5, +solarMonthData[i][2], +solarMonthData[i + 1][2]);
              for (let j = 0; j < points.length - 1; j++) {
                solar_x.push(solarStartDate.valueOf());
                solar_y.push(points[j]);
                solar_net_load_y.push(y[solar_y.length - 1] - points[j]);
                solarStartDate.add(15, 'minutes');
              }
            } else {
              // 1hr solar vs 1hr load data
              solar_x.push(solarStartDate.valueOf());
              solar_y.push(solarMonthData[i][2]);
              solar_net_load_y.push(y[solar_y.length - 1] - solarMonthData[i][2]);
              solarStartDate.add(60, 'minutes');
            }
          } else {
            if (is15minLoad) {
              // 15min solar vs 15min load
              solar_x.push(solarStartDate.valueOf());
              solar_y.push(solarMonthData[i][2]);
              solar_net_load_y.push(y[solar_y.length - 1] - solarMonthData[i][2]);
              solarStartDate.add(15, 'minutes');
            } else {
              // 15min solar vs 1hr load
              // convert from more than 15 minute frequency to 15 minutes
              solar_x.push(solarStartDate.valueOf());
              solar_y.push(solarMonthData[i][2]);
              solar_net_load_y.push(y[solar_y.length - 1] - solarMonthData[i][2]);
              solarStartDate.add(60, 'minutes');
            }
          }
        }
        if (hasSolar) {
          data.push({
            type: 'scatter',
            mode: 'lines',
            name: 'Solar',
            fill: 'tozeroy',
            x: solar_x,
            y: solar_y,
            line: { color: 'rgba(255, 165, 0, .6)', width: 1 }
          });
          data.push({
            type: 'scatter',
            mode: 'lines',
            name: 'Net Load',
            fill: 'tozeroy',
            x: solar_x,
            y: solar_net_load_y,
            line: { color: 'rgba(0, 113, 255, .6)', width: 1 }
          });
        }
        return data;
      }
    }
    return [] as Data[];
  };

  useEffect(() => {
    let summary = rollupData(loadProfile);
    let months = Object.keys(summary).filter(item => item !== 'summary');
    let solarSummary = rollupSolar(solarProfile, exploreDetailsMetaInfo.solarFrequency);
    let touSummary = rollupData(loadProfile, exploreDetailsMetaInfo.fromHour, exploreDetailsMetaInfo.toHour);

    dispatch({
      type: UPDATE_EXPLORE_PAGE_INFORMATION,
      payload: {
        months: months,
        currentMonthlyData: buildMonthlyData(months[0], summary, solarSummary),
        monthSummary: summary[months[0]],
        touMonthSummary: touSummary[months[0]],
        summary: summary,
        solarSummary: solarSummary,
        monthSolarSummary: solarSummary[months[0]],
        touSummary: touSummary,
        selectedMonth: months[0]
      }
    });
  }, []);

  const handleMonthDateChange = (updatedMonthIndex: string) => {
    dispatch({
      type: UPDATE_EXPLORE_PAGE_INFORMATION,
      payload: {
        selectedMonth: updatedMonthIndex,
        currentMonthlyData: buildMonthlyData(
          updatedMonthIndex,
          exploreDetailsMetaInfo.summary,
          exploreDetailsMetaInfo.solarSummary
        ),
        monthSummary: exploreDetailsMetaInfo.summary[updatedMonthIndex],
        touMonthSummary: exploreDetailsMetaInfo.touSummary[updatedMonthIndex],
        monthSolarSummary: exploreDetailsMetaInfo.solarSummary[updatedMonthIndex]
      }
    });
  };

  const handleHourChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    let fromHour: number, toHour: number;
    if (event.target.name === 'fromHour') {
      fromHour = parseInt(event.target.value as string);
      toHour = exploreDetailsMetaInfo.toHour;
    } else {
      fromHour = exploreDetailsMetaInfo.fromHour;
      toHour = parseInt(event.target.value as string);
    }
    let updatedSummaryRollup = rollupData(loadProfile, fromHour, toHour);
    dispatch({
      type: UPDATE_EXPLORE_PAGE_INFORMATION,
      payload: {
        [event.target.name as string]: event.target.value,
        monthSummary: exploreDetailsMetaInfo.summary[exploreDetailsMetaInfo.selectedMonth],
        touMonthSummary: updatedSummaryRollup[exploreDetailsMetaInfo.selectedMonth],
        touSummary: updatedSummaryRollup
      }
    });
  };

  const handleTariffSave = (tariff: ESAPTariff) => {
    if (!tariffs || tariffs.length === 0) {
      let endDate = moment(exploreDetailsMetaInfo.startDate).add(1, 'years');
      if (tariff && tariff.applicability_values && Object.keys(tariff.applicability_values).length > 0) {
        const config: any = {
          fromDateTime: exploreDetailsMetaInfo.startDate.format(),
          toDateTime: moment(endDate).format(),
          tariffEffectiveOn: moment(tariff.effective_date).format(),
          detailLevel: 'CHARGE_TYPE_AND_TOU',
          fields: 'ext',
          groupBy: 'MONTH',
          billingPeriod: false,
          bundleRates: false,
          minimums: true,
          propertyInputs: [
            {
              keyName: 'consumption',
              fromDateTime: exploreDetailsMetaInfo.startDate.format(),
              dataSeries: loadProfile.map(item => item[2] / 4),
              duration: 900000,
              unit: 'kWh'
            }
          ],
          masterTariffId: tariff.source_id
          //tariffEffectiveOn: "2019-01-01T00:00:00-08:00"
        };
        Object.keys(tariff.applicability_values).forEach(key => {
          const dataValue = tariff?.applicability_values && tariff.applicability_values[key];
          if (dataValue) {
            config.propertyInputs.push({
              keyName: key,
              dataValue
            });
          }
        });
        dispatch(getCalculatedBill(config, 'Base Load', (tariff.tariff_id as number).toString()));
        const updatedMetaInfo = { ...exploreDetailsMetaInfo };
        updatedMetaInfo.billingDisplay = {
          ...exploreDetailsMetaInfo.billingDisplay,
          selectedTariff: tariff.tariff_id
        };
        dispatch({ type: UPDATE_EXPLORE_PAGE_INFORMATION, payload: updatedMetaInfo });
      }
    }
    dispatch(addExploreTariff(tariff));
    setState({ ...state, showTariff: false });
  };

  return (
    <div>
      {exploreDetailsMetaInfo && exploreDetailsMetaInfo.currentMonthlyData && (
        <div className={classes.container}>
          {state.showTariff && <ExploreTariffCreateContainer handleSave={handleTariffSave} />}
          {!state.showTariff && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MonthlyTimeSeries
                  data={exploreDetailsMetaInfo.currentMonthlyData}
                  months={exploreDetailsMetaInfo.months}
                  selectedMonth={exploreDetailsMetaInfo.selectedMonth}
                  handleDateChange={handleMonthDateChange}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadSummary
                  annualSummary={exploreDetailsMetaInfo.summary.summary}
                  monthSummary={exploreDetailsMetaInfo.monthSummary}
                  touSummary={exploreDetailsMetaInfo.touSummary.summary}
                  touMonthSummary={exploreDetailsMetaInfo.touMonthSummary}
                />
              </Grid>
              {exploreDetailsMetaInfo.showTOU && (
                <Grid item xs={12}>
                  <div>
                    <TextField
                      id="fromHour"
                      name="fromHour"
                      label="Hour Start"
                      select
                      value={exploreDetailsMetaInfo.fromHour}
                      onChange={handleHourChange}
                      margin="dense"
                    >
                      {hours.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      id="toHour"
                      name="toHour"
                      label="Hour End"
                      select
                      value={exploreDetailsMetaInfo.toHour}
                      onChange={handleHourChange}
                      margin="dense"
                    >
                      {hours.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <LoadSummary
                    annualSummary={exploreDetailsMetaInfo.touSummary.summary}
                    monthSummary={exploreDetailsMetaInfo.touMonthSummary}
                    touSummary={exploreDetailsMetaInfo.touSummary.summary}
                    touMonthSummary={exploreDetailsMetaInfo.touMonthSummary}
                    titlePrefix={'TOU'}
                  />
                </Grid>
              )}
              {Object.keys(exploreDetailsMetaInfo.solarSummary).length > 0 && (
                <Grid item xs={12}>
                  <SolarSummary
                    annualTotalLoad={exploreDetailsMetaInfo.summary.summary.totalEnergy}
                    monthlyTotalLoad={exploreDetailsMetaInfo.monthSummary.totalEnergy}
                    solarSummary={exploreDetailsMetaInfo.solarSummary.summary}
                    monthlySolarSummary={exploreDetailsMetaInfo.monthSolarSummary}
                    solarFrequency={exploreDetailsMetaInfo.solarFrequency}
                  />
                </Grid>
              )}
              {exploreDetailsMetaInfo.showPeak && (
                <Grid item xs={12}>
                  <LoadPeakSummaryTable
                    monthTopPeaks={getTopNPeaks(loadProfile, 5)}
                    monthTopDayPeaks={getTopDayPeaks(loadProfile, 5)}
                  />
                </Grid>
              )}

              {exploreDetailsMetaInfo.showPeak && exploreDetailsMetaInfo.showTOU && (
                <Grid item xs={12}>
                  <h4>TOU Peak Summary</h4>
                  <LoadPeakSummaryTable
                    monthTopPeaks={getTopNPeaks(
                      loadProfile,
                      5,
                      exploreDetailsMetaInfo.fromHour,
                      exploreDetailsMetaInfo.toHour
                    )}
                    monthTopDayPeaks={getTopDayPeaks(
                      loadProfile,
                      5,
                      exploreDetailsMetaInfo.fromHour,
                      exploreDetailsMetaInfo.toHour
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ForecastSummary />
              </Grid>
              {exploreDetailsMetaInfo.showSeasonal && <SeasonalProfiles summary={exploreDetailsMetaInfo.summary} />}

              {exploreDetailsMetaInfo.showMonthly && <MonthlyProfiles summary={exploreDetailsMetaInfo.summary} />}
            </Grid>
          )}
        </div>
      )}
    </div>
  );
};

export default AnalysisOverview;
