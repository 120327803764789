import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Save from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AssumptionsDetail from '../../Assumptions/Detail/AssumptionsDetail';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  updateProposalScenario,
  createProposalScenario,
  resetProposalScenarioResults
} from '../../../actions/scenarios';
import { assumptionDefaults } from '../../../utility/Assumption';
import AccessControl from '../../../utility/AccessControl';
import { createSuccessSelector, createLoadingSelector } from '../../../selectors';
import { ProposalInternal, AssumptionsInternal, ScenarioInternal, EVShiftInternal, TemplateEV } from '../../../types';
import { assertIsDefined, assertIsMonth } from '../../../utils/assertions';
import { RESET_SUCCESS_FLAGS } from '../../../actions/status';
import { evProfilesTemplates } from '../../../data/ev-profiles-templates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    flex: {
      flex: 1
    },
    toolbar: {
      //borderBottom: '1px solid #ccc'
      backgroundColor: (theme.palette as any).esap.blueDim,
      color: '#fff'
    },
    toolbarSecondary: {
      backgroundColor: '#fff',
      borderBottom: '1px solid #e5e5e5'
    },
    save: {
      color: 'white'
    }
  })
);

interface AssumptionsContainerProps {
  proposal: ProposalInternal;
  scenario: ScenarioInternal;
  mode: string;
}
const ScenarioAssumptionContainer: React.FC<AssumptionsContainerProps> = ({ scenario, proposal, mode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    scenarioAssumptions: assumptionDefaults,
    name: '',
    hasChanged: false,
    hasNameChanged: false,
    alertOpen: false,
    hasSaved: false,
    openCompleteDialog: false,
    isComplete: true // defaults to true is easier before is complete is implemented for all assumptions
  });

  const successSelector = createSuccessSelector(['UPDATE_PROPOSAL_SCENARIO']);
  const updateLoadingSelector = createLoadingSelector(['UPDATE_PROPOSAL_SCENARIO']);

  const isSuccess = useSelector(successSelector);
  const loadingUpdate = useSelector(updateLoadingSelector);

  // initialize assumptions and name
  useEffect(() => {
    let scenarioAssumptions: AssumptionsInternal;
    if (scenario && scenario.assumptions) {
      scenarioAssumptions = { ...scenario.assumptions };
    } else {
      scenarioAssumptions = { ...assumptionDefaults };
    }
    setState(prevState => ({
      ...prevState,
      scenarioAssumptions: scenarioAssumptions,
      name: scenario ? scenario.name : ''
    }));
  }, [scenario]);

  useEffect(() => {
    setState(prevState => ({ ...prevState, hasSaved: isSuccess }));
  }, [isSuccess]);

  const handleGeneralAssumptionChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    const name = event.target.name;
    assertIsDefined(name);
    let value = event.target.value;

    setState(current => ({
      ...current,
      scenarioAssumptions: {
        ...current.scenarioAssumptions,
        [name]: value
      },
      hasChanged: true
    }));
  };

  // const isNumeric = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);

  const handleEVShiftAssumptionsChange = (id: number, assumptions?: Partial<EVShiftInternal>) => (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    event?.stopPropagation?.();

    const targetShift = state.scenarioAssumptions.evShifts.find(t => t.id === id);
    assertIsDefined(targetShift);

    const updatedData = assumptions ? assumptions : { [event.target.name!]: event.target.value };

    const updatedShift = { ...targetShift, ...updatedData };

    const updatedEVShifts = [...state.scenarioAssumptions.evShifts.filter(t => t.id !== id), updatedShift].sort(
      (a, b) => a.id - b.id
    );

    const updatedAssumptions = { ...state.scenarioAssumptions, evShifts: updatedEVShifts };

    setState(state => ({
      ...state,
      scenarioAssumptions: updatedAssumptions,
      hasChanged: true
    }));
  };

  const handleAddEVShift = () => {
    const maxIndex = state.scenarioAssumptions.evShifts.reduce((acc, curr) => {
      return Math.max(acc, curr.id);
    }, 0);

    const newShift: EVShiftInternal = {
      id: maxIndex + 1,
      gamma: 0.9,
      evArrivalRange: [8, 10],
      muChargingPrd: 6,
      muChargingPrdStdDeviation: 0.6,
      Smax: 30,
      evseUtilisationRate: 0,
      isOvernight: false
    };

    const updatedEVShifts = [...state.scenarioAssumptions.evShifts, newShift];

    const updatedAssumptions = { ...state.scenarioAssumptions, evShifts: updatedEVShifts };

    setState({
      ...state,
      scenarioAssumptions: updatedAssumptions,
      hasChanged: true
    });
  };

  const handleDeleteEVShift = (id: number) => () => {
    const updatedEVShifts = state.scenarioAssumptions.evShifts.filter(t => t.id !== id);

    const updatedAssumptions = { ...state.scenarioAssumptions, evShifts: updatedEVShifts };

    setState({
      ...state,
      scenarioAssumptions: updatedAssumptions,
      hasChanged: true
    });
  };

  const handleMultiUpdate = (updatedInfo: Partial<AssumptionsInternal>): void => {
    const updatedAssumptions = {
      ...state.scenarioAssumptions,
      ...updatedInfo
    };
    setState({
      ...state,
      scenarioAssumptions: updatedAssumptions,
      hasChanged: true
    });
  };

  const handleYearlyCycleAssumptionChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    assertIsMonth(event.target.name);
    const updatedYearlyCycles = [
      ...state.scenarioAssumptions.yearlyCycles.slice(0, index),
      { month: event.target.name, cycles: value },
      ...state.scenarioAssumptions.yearlyCycles.slice(index + 1)
    ];
    const updatedScenario = {
      ...state.scenarioAssumptions,
      yearlyCycles: updatedYearlyCycles
    };
    setState({ ...state, scenarioAssumptions: updatedScenario, hasChanged: true });
  };

  const handleMonthlyBidAssumptionChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    assertIsMonth(event.target.name);
    const updatedMonthlyBid = [
      ...state.scenarioAssumptions.monthlyBids.slice(0, index),
      { month: event.target.name, bids: value },
      ...state.scenarioAssumptions.monthlyBids.slice(index + 1)
    ];
    const updatedScenario = {
      ...state.scenarioAssumptions,
      monthlyBids: updatedMonthlyBid
    };
    setState({ ...state, scenarioAssumptions: updatedScenario, hasChanged: true });
  };

  const handleAssumptionUpdateByKey = useCallback(
    (key: keyof AssumptionsInternal) => (value: any) => {
      setState(state => ({
        ...state,
        scenarioAssumptions: { ...state.scenarioAssumptions, [key]: value },
        hasChanged: true
      }));
    },
    []
  );

  const handleChangeEVProfileTemplate = (template: TemplateEV) => (event: React.MouseEvent<HTMLButtonElement>) => {
    if (evProfilesTemplates[template] === undefined) {
      throw new Error('EV profile template key is not a valid one, got:' + template);
    }
    const updatedAssumptions = evProfilesTemplates[template];
    handleMultiUpdate({ ...updatedAssumptions, evTemplate: template });
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedAssumptions = {
      ...state.scenarioAssumptions,
      [event.target.name]: !state.scenarioAssumptions[event.target.name]
    };
    setState({
      ...state,
      scenarioAssumptions: updatedAssumptions,
      hasChanged: true
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, name: event.target.value, hasNameChanged: true });
  };

  const handleClickOpen = () => {
    setState({ ...state, alertOpen: true });
  };

  const handleClose = () => {
    setState({ ...state, alertOpen: false });
  };

  const handleSaveDecision = () => {
    if (!state.isComplete) {
      setState({ ...state, openCompleteDialog: true });
      return;
    }
    if (state.hasChanged && scenario.results) {
      handleClickOpen();
    } else {
      handleSave();
    }
  };

  const handleSave = () => {
    // build scenario assumption object
    let updateAssumption = {
      ...assumptionDefaults,
      ...state.scenarioAssumptions
    };
    let updatedScenario = {
      ...scenario,
      proposal_id: proposal.public_id,
      name: state.name,
      assumptions: updateAssumption,
      active: true
    };
    if (proposal && proposal.proposal_scenarios && proposal.proposal_scenarios.length === 0) {
      updatedScenario.is_status_quo = true;
    }
    if (updatedScenario.public_id) {
      if (updatedScenario.results && state.hasChanged) {
        dispatch(resetProposalScenarioResults(updatedScenario, proposal.public_id));
      }
      dispatch(updateProposalScenario(updatedScenario, proposal.public_id));
    } else {
      dispatch(createProposalScenario(updatedScenario, proposal.public_id));
    }
    if (state.alertOpen) {
      setState({ ...state, alertOpen: false });
    }
  };

  const hideMessage = () => {
    setState({ ...state, hasSaved: false });
    dispatch({ type: RESET_SUCCESS_FLAGS });
  };

  const handleSetIsComplete = (isComplete: boolean) => {
    setState({ ...state, isComplete });
  };

  const handleCloseCompleteDialog = () => {
    setState({ ...state, openCompleteDialog: false });
  };

  const disableSave = () => {
    const { tariffId, isBehindMeter } = state.scenarioAssumptions;
    return (isBehindMeter && !tariffId) || !state.name;
  };

  return (
    <Paper className={classes.root}>
      {(mode === 'create' || state.hasChanged) && (
        <div className="button-container">
          <AccessControl requiredPermissions={['editor', 'admin']}>
            <Fab
              disabled={disableSave()}
              size="small"
              color="secondary"
              aria-label="Save"
              className="button-white"
              onClick={handleSave}
            >
              <Save />
            </Fab>
          </AccessControl>
        </div>
      )}
      <Toolbar className={classes.toolbar}>
        <Typography color="inherit" variant="h6" className={classes.flex}>
          Scenario assumptions
        </Typography>
        {(state.hasChanged || state.hasNameChanged) && mode != 'create' && (
          <IconButton size="small" aria-label="Save assumptions" onClick={handleSaveDecision} className={classes.save}>
            {loadingUpdate ? (
              <div style={{ textAlign: 'center', padding: 0 }}>
                <CircularProgress color="secondary" size={18} />
              </div>
            ) : (
              <Save />
            )}
          </IconButton>
        )}
      </Toolbar>
      <Toolbar className={classes.toolbarSecondary}>
        <Typography color="inherit" variant="h6" className={classes.flex}>
          <TextField
            key="name"
            name="name"
            placeholder="** Enter Scenario Name...."
            InputProps={{
              disableUnderline: true,
              autoFocus: true,
              required: true
            }}
            value={state.name}
            onChange={handleNameChange}
            fullWidth
            margin="none"
            data-testid="scenarioName"
          />
        </Typography>
      </Toolbar>
      <AssumptionsDetail
        proposal={proposal}
        handleYearlyCycleAssumptionChange={handleYearlyCycleAssumptionChange}
        handleMonthlyBidAssumptionChange={handleMonthlyBidAssumptionChange}
        assumptions={state.scenarioAssumptions}
        handleGeneralAssumptionChange={handleGeneralAssumptionChange}
        handleEVShiftAssumptionsChange={handleEVShiftAssumptionsChange}
        handleMultiUpdate={handleMultiUpdate}
        handleAssumptionUpdateByKey={handleAssumptionUpdateByKey}
        handleToggle={handleToggle}
        selectedTariffs={proposal.proposal_tariffs}
        editStatusQuo={true}
        setIsComplete={handleSetIsComplete}
        scenario={scenario}
        handleAddEVShift={handleAddEVShift}
        handleDeleteEVShift={handleDeleteEVShift}
        handleChangeEVProfileTemplate={handleChangeEVProfileTemplate}
      />
      {/* Confirm that save has been successful */}
      <Snackbar open={state.hasSaved} autoHideDuration={5000} onClose={hideMessage}>
        <Alert severity="success" elevation={6} variant="filled">
          <span id="message-id">Scenario Saved Successfully</span>
        </Alert>
      </Snackbar>
      {/* Confirm you want to save to updated scenario */}
      <Dialog
        open={state.alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Scenario Save'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to save these changes? It will reset the results and you will have to rerun this
            scenario.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for uncomplete forms*/}
      <Dialog
        open={state.openCompleteDialog}
        onClose={handleCloseCompleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Some required data is missing.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please fill in all required inputs before saving.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCompleteDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ScenarioAssumptionContainer;
