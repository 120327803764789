import React, { useState, useEffect, useMemo, useContext } from 'react';
import { connect } from 'react-redux';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

// Material UI
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Star from '@material-ui/icons/Star';
import Settings from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Fab from '@material-ui/core/Fab';

// React Imports
import MOERHeatmap from '../../components/Scenarios/Charts/MOERHeatmap';
import PDRBidByHour from '../../components/Scenarios/Charts/PDRBidByHour';
import ScenarioDemandChargeComparison from '../../components/Scenarios/Charts/ScenarioDemandChargeComparison';
import ScenarioEnergyChargeComparison from '../../components/Scenarios/Charts/ScenarioEnergyChargeComparison';
import ConsumptionGenerationChart from '../../components/Scenarios/Charts/ConsumptionGenerationChart';
import ScenarioMonthlyCycles from '../../components/Scenarios/Charts/ScenarioMonthlyCycles';
import DemandChargeTotalPie from '../../components/Scenarios/Charts/DemandChargeTotalPie';
import ResourceOverviewChart from '../../components/Scenarios/Charts/ResourceOverviewChart';
import ScenarioRibbon from '../../components/Scenarios/Charts/ScenarioRibbon';
import ScenarioAssumptionContainer from '../../components/Scenarios/Detail/ScenarioAssumptionContainer';
import { updateProposalSelectedScenarios, getProposal } from '../../actions/proposals';
import TariffSummary from '../../components/Scenarios/Charts/TariffSummary';
import { exportCSVFile } from '../../utility/General';
import ErrorBoundary from '../../components/Common/ErrorBoundary/ErrorBoundary';
import { popBreadcrumb, pushBreadcrumb } from '../../actions/breadcrumbs';
import { selectProposalScenario } from '../../actions/scenarios';
import AccessControl from '../../utility/AccessControl';
import { broadcastUpdateTariff } from '../../actions';
import ScenarioMonthlyResultsTable from '../../components/Scenarios/Detail/ScenarioMonthlyResultsTable';
import FinancialPerformanceTab from '../../components/Scenarios/Charts/FinancialPerformanceTab';
import { ProposalDetailContext } from '../../components/Proposals/Detail/ProposalDetailContextProvider';
import MultiYearSelector from '../../components/Scenarios/Detail/MultiYearSelector';
import { StoreState } from '../../reducers';
import { ScenarioInternal, ProposalInternal, MonthlyOperationsData, ESAPTariff, ScenarioResults } from '../../types';
import ErrorDialog from '../../components/Common/ErrorDialog';
import LoadSensitivityAnalysisContainer from '../../components/Scenarios/Detail/LSA/LSAContainer';
import ScenarioNotePad from '../../components/Scenarios/Detail/NotePad/ScenarioNotePad';
import { assertIsDefined } from '../../utils/assertions';
import { useGetOperationData } from '../../queries/operationsData';
import RemoteDataRenderer from '../../components/Common/RemoteDataRenderer';

const moment = extendMoment(Moment);

const MAX_NB_OF_DATA_ITEMS = 800;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  content: {
    padding: theme.spacing(3)
  },
  item: {
    marginTop: 8
  },
  button: {
    color: '#fff',
    marginRight: 8
  },
  selectedButton: {
    color: '#fff',
    marginRight: 8,
    background: 'green',
    '&:hover': {
      background: 'green !important'
    }
  },
  selectYear: {
    marginBottom: theme.spacing(3),
    padding: 4
  }
}));

interface ScenarioDetailProps {
  location: any;
  match: any;
  pushBreadcrumb: any;
  popBreadcrumb: any;
  getProposal: any;
  proposal: ProposalInternal;
  scenario: ScenarioInternal;
  breadcrumbsTrail: any;
  tariff: ESAPTariff;
  selectProposalScenario: (scenario: ScenarioInternal) => void;
  updateProposalSelectedScenarios: (proposalId: string, selectedScenarios: string[]) => void;
  broadcastUpdateTariff: any;
}

type TabsType = 'overview' | 'assumptions' | 'tariff' | 'lsa' | 'fp' | 'notes';

const ScenarioDetail: React.FC<ScenarioDetailProps> = props => {
  const {
    location,
    match,
    pushBreadcrumb,
    popBreadcrumb,
    getProposal,
    proposal,
    scenario,
    breadcrumbsTrail,
    tariff,
    selectProposalScenario,
    updateProposalSelectedScenarios,
    broadcastUpdateTariff
  } = props;

  const classes = useStyles(props);

  const {
    isMultiYear,
    setIsMultiYear,
    selectedYears,
    setValidMonths,
    selectedMonths,
    setValidYears,
    setSelectedMonths,
    setSelectedYears
  } = useContext(ProposalDetailContext);

  const [value, setValue] = useState<TabsType>('overview');
  const [selectedBaseScenarioId, setSelectedBaseScenarioId] = useState('0');
  const [open, setOpen] = useState(false);
  const [discountRate, setDiscountRate] = useState({ demand: 1, energy: 1 });
  const [newDiscountRate, setNewDiscountRate] = useState({
    demand: 1,
    energy: 1
  });
  const [isSelected, setIsSelected] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [dialogErrorMessage, setDialogErrorMessage] = useState('');

  // fetch scenario operations data
  const {
    data: operationsDataScenario,
    status: operationsDataScenarioStatus,
    error: operationsDataScenarioError
  } = useGetOperationData(proposal?.public_id, scenario?.public_id);

  useEffect(() => {
    if (!proposal.public_id || proposal.public_id.length === 0) {
      getProposal(match.params.proposalId);
    }
  }, [match.params.proposalId, getProposal, proposal.public_id]);

  // initializes  breadcrumbs
  const isComingFromProposals = useMemo(() => {
    return (
      breadcrumbsTrail[breadcrumbsTrail.length - 1] &&
      breadcrumbsTrail[breadcrumbsTrail.length - 1].link &&
      breadcrumbsTrail[breadcrumbsTrail.length - 1].link.includes('/proposals/') &&
      !breadcrumbsTrail[breadcrumbsTrail.length - 1].link.includes('/scenarios/')
    );
  }, [breadcrumbsTrail]);

  useEffect(() => {
    if (proposal.name && scenario.name) {
      if (!breadcrumbsTrail || breadcrumbsTrail.length === 0) {
        pushBreadcrumb('Proposals', '/proposals');
        pushBreadcrumb(proposal.name, `/proposals/${proposal.public_id}`);
        pushBreadcrumb(scenario.name, location.pathname);
      } else {
        if (!isComingFromProposals) {
          popBreadcrumb();
        }
        pushBreadcrumb(proposal.name, `/proposals/${proposal.public_id}`);
        pushBreadcrumb(scenario.name, location.pathname);
      }
    }
  }, [
    isComingFromProposals,
    location.pathname,
    popBreadcrumb,
    pushBreadcrumb,
    scenario.name,
    proposal.public_id,
    proposal.name
  ]);

  useEffect(() => {
    const id = scenario?.assumptions?.tariffId;
    let tariff;
    if (props.proposal?.proposal_tariffs?.length && id) {
      tariff = props.proposal.proposal_tariffs.find(tariff => {
        return tariff.public_id === id;
      });
      if (tariff) {
        broadcastUpdateTariff(tariff);
      }
    }
  }, [scenario]);

  // check if its a multiyear scenario in the assumptions and update context
  useEffect(() => {
    if (scenario?.results && Object.keys(scenario.results).length > 0) {
      setIsMultiYear(scenario.assumptions.isMultiyear || false);
    }
  }, [scenario]);

  // initialize the start/end dates if multiyears and coming from a direct link to scenario
  useEffect(() => {
    if (isMultiYear && selectedYears && selectedYears.start == '0' && scenario && scenario?.results) {
      const months = scenario.results['Months'];
      setValidMonths(months);
      const dateRegex = /(\d\d)\/(\d\d)\/(\d\d\d\d)/;
      let years: string[] = [];
      months.forEach(function collectUniqueYears(month) {
        const dateMatch = month.match(dateRegex);
        if (dateMatch) {
          const year = dateMatch[3];
          if (year && !years.includes(year)) {
            years.push(year);
          }
        }
      });
      // initialize mutliyear months to the first months in results
      setSelectedMonths({
        start: parseInt(months[0].slice(0, 2)) - 1,
        end: parseInt(months[11].slice(0, 2)) - 1
      });
      setSelectedYears({
        start: months[0].slice(6),
        end: months[11].slice(6)
      });
      setValidYears(years);
    }
  }, [scenario, isMultiYear]);

  /* check if a scenario has been selected and change icon color accordingly */
  useEffect(() => {
    if (proposal.selected_scenarios && proposal.selected_scenarios.includes(scenario.public_id)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [proposal.selected_scenarios, scenario.public_id]);

  /* select a different scenario if scenario url changes */
  useEffect(() => {
    if (proposal && proposal?.proposal_scenarios) {
      selectProposalScenario(proposal.proposal_scenarios.filter(el => el.public_id === match.params.id)[0]);
    }
  }, [match.params.id, proposal.proposal_scenarios, selectProposalScenario, proposal.public_id]);

  // const findSelectedTariff = (tariffId: string): ESAPTariff | undefined => {
  //   if (proposal?.proposal_tariffs) {
  //     return proposal.proposal_tariffs.find(tariff => {
  //       return tariff.public_id === tariffId;
  //     });
  //   }
  //   return;
  // };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const getStatusQuo = (): ScenarioInternal | undefined => {
    if (!proposal || !proposal.proposal_scenarios || proposal.proposal_scenarios.length === 0) return undefined;
    const sq =
      selectedBaseScenarioId === '0'
        ? proposal.proposal_scenarios.find(scenario => scenario.is_status_quo)
        : proposal.proposal_scenarios.find(scenario => scenario.public_id === selectedBaseScenarioId);
    assertIsDefined(sq, 'Could not find the status quo. Got: ' + sq);
    return sq;
  };

  // fetch operations data for the status quo for further GHG reductions calcs
  const { data: operationsDataSQ, refetch: refetchOperationDataSQ } = useGetOperationData(
    proposal?.public_id,
    getStatusQuo()?.public_id
  );

  useEffect(() => {
    refetchOperationDataSQ();
  }, [getStatusQuo()?.public_id]);

  const handleClose = () => {
    setOpen(false);
    setDiscountRate(discountRate);
  };

  const handleDiscountRateUpdate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    const updatedDiscountRate = { ...newDiscountRate, [name]: value };
    setNewDiscountRate(updatedDiscountRate);
  };

  const handleApplyDiscount = () => {
    // let updatedDiscountRate = discountRate;
    // if (discountRateEdit !== updatedDiscountRate) {
    //   updatedDiscountRate = Math.max(0, Math.min(1, discountRateEdit));
    // }
    if (
      Number(newDiscountRate.demand) >= 0 &&
      Number(newDiscountRate.demand) <= 1 &&
      Number(newDiscountRate.energy) >= 0 &&
      Number(newDiscountRate.energy <= 1)
    ) {
      setErrorText('');
      setDiscountRate({
        demand: Number(newDiscountRate.demand),
        energy: Number(newDiscountRate.energy)
      });
      setOpen(false);
    } else {
      setErrorText('Perfect knowledge should be a value between 0 and 1.');
    }
  };

  const handleComparisonCaseChange = (event: React.ChangeEvent<any>) => {
    setSelectedBaseScenarioId(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: TabsType) => {
    setValue(newValue);
  };

  const handleSelection = () => {
    setIsSelected(!isSelected);
    let newSelection;
    if (proposal.selected_scenarios && proposal.selected_scenarios.includes(scenario.public_id)) {
      newSelection = proposal.selected_scenarios.filter((id: string) => id !== scenario.public_id);
    } else {
      if (proposal.selected_scenarios) {
        newSelection = [...proposal.selected_scenarios, scenario.public_id];
      } else {
        newSelection = [scenario.public_id];
      }
    }
    updateProposalSelectedScenarios(proposal.public_id, newSelection);
  };

  const handleSettingsClick = () => {
    setOpen(true);
  };

  const generateTechnologyFlags = () => {
    const technologies = {
      isPV: false,
      isBatt: false,
      isGenset: false,
      isEV: false,
      isIslanding: false,
      hasExistingSolar: false
    };
    if (!scenario || !scenario.assumptions) {
      return technologies;
    }
    const assumptions = scenario.assumptions;
    if (assumptions.isPV) {
      technologies.isPV = true;
    }
    if (assumptions.hasExistingSolar) {
      technologies.hasExistingSolar = true;
    }
    if (assumptions.isEV) {
      technologies.isEV = true;
    }
    if (assumptions.isBatt) {
      technologies.isBatt = true;
    }
    if (assumptions.isGenset) {
      technologies.isGenset = true;
    }
    if (assumptions.isIslanding || assumptions.hasGridOutages) {
      technologies.isIslanding = true;
    }
    return technologies;
  };

  const slicedOperationsData = (
    scenario: ScenarioInternal,
    data: MonthlyOperationsData | undefined
  ): MonthlyOperationsData => {
    if (!data) return {} as MonthlyOperationsData;
    if (!scenario.assumptions.isMultiyear) return data;
    const start = moment(`${selectedYears.start}-${selectedMonths.start + 1}`, 'YYYY-MM');
    const end = moment(`${selectedYears.end}-${selectedMonths.end + 1}`, 'YYYY-MM');
    const range = moment.range(start, end);
    return Object.keys(data).reduce((acc, curr) => {
      if (moment(curr).within(range)) {
        acc[curr] = data[curr];
      }
      return acc;
    }, {});
  };

  const sliceResults = (scenario: ScenarioInternal): ScenarioResults | null => {
    if (!scenario.results) return null;
    if (!scenario.assumptions.isMultiyear) return scenario.results;

    let slicedResults = scenario.results;
    const monthStart =
      (selectedMonths.start + 1).toString().length === 1
        ? '0' + (selectedMonths.start + 1).toString()
        : (selectedMonths.start + 1).toString();
    const monthEnd =
      (selectedMonths.end + 1).toString().length === 1
        ? '0' + (selectedMonths.end + 1).toString()
        : (selectedMonths.end + 1).toString();

    const startDate = `${monthStart}/01/${selectedYears.start}`;
    const endDate = `${monthEnd}/01/${selectedYears.end}`;

    let yearStartIndex = scenario.results['Months'].findIndex(el => el === startDate);
    let yearEndIndex = scenario.results['Months'].findIndex(el => el === endDate);

    Object.keys(scenario.results).forEach(item => {
      if (scenario && scenario?.results) {
        slicedResults[item] = scenario.results[item].slice(yearStartIndex, yearEndIndex + 1);
      }
    });

    return slicedResults;
  };

  const calculateSQTotals = (key: string): number => {
    if (!getStatusQuo()) return 0;
    const baseResults = sliceResults(getStatusQuo()!);
    if (!baseResults) {
      return 0;
    }
    return calculateTotal(baseResults, key);
  };

  const calculateScenarioTotals = (key: string): number => {
    const discount = key.includes('Demand') ? discountRate.demand : discountRate.energy;
    let calculatedScenarioTotal = calculateTotal(sliceResults(scenario), key);
    let calculatedStatusQuoTotal = calculateSQTotals(key);
    let difference = calculatedStatusQuoTotal - calculatedScenarioTotal;
    let adjustmentValue = difference * (1 - discount);
    return calculatedScenarioTotal + adjustmentValue;
  };

  const calculateTotal = (data: any, key: string): number => {
    return Array.isArray(data[key])
      ? data[key].reduce((total: any, item: any) => {
          return total + +item;
        }, 0)
      : 0;
  };

  const getCSVHeader = (label: string): string => {
    const columns = {
      vecEnerBatt: 'Battery Energy (kWh)',
      vecLoad: 'Building Load (kW)',
      vecPBatt: 'Battery Power  (kW)',
      vecPInv: 'PV  (kW)',
      vecPNetIn: 'Net Load  (kW)',
      vecPNetOut: 'Export  (kW)',
      vecPev: 'EV Load  (kW)',
      timestamp: 'Timestamp',
      pv_curtail: 'Solar Curtailment  (kW)',
      season: 'Season',
      touPeriod: 'TOU Period',
      energyTariff: 'Energy Tariff ($/kWh)',
      energyCost: 'Energy Cost ($)',
      demandTariff: 'Demand Tariff ($/kW)',
      demandTariffNC: 'Demand Tariff NC ($/kW)',
      exportValue: 'Export Value ($/kWh)',
      exportEnergyValue: 'Export Energy Value ($)',
      demandCostNC: 'Demand Cost NC ($)',
      demandCost: 'Demand Cost ($)',
      totalCost: 'Total Cost ($)',
      vecPBattRA: 'Resource Adequacy  (kW)',
      raBest: 'RA Best',
      raOptimBest: 'RA Optimization Best',
      raStatusQuo: 'RA Status Quo',
      baseline: 'RA Baseline',
      raEligible: 'RA Eligible',
      mef: 'GHG Emissions Factor (kg CO2eq/kWh)',
      ghgEmissions: 'GHG Emissions (kg CO2eq)',
      ghgBattEmissionsImpact: 'Battery Emissions Impact (kg CO2eq/kWh)'
    };
    return columns[label] || label;
  };
  const handleCloseErrorDialog = () => {
    setDialogErrorMessage('');
  };
  const handleDownload = () => {
    if (operationsDataScenarioStatus !== 'success' || !operationsDataScenario) {
      setDialogErrorMessage(
        'Sorry, no operations data available to download. Please contact site admin if problem persists.'
      );
    } else {
      const data = {
        timestamp: [] as string[],
        vecEnerBatt: [],
        vecLoad: [],
        vecPBatt: [],
        vecPInv: [] as number[],
        vecPNetIn: [],
        vecPNetOut: [],
        pv_curtail: [],
        vecPev: [] as number[],
        season: [],
        touPeriod: [],
        energyTariff: [],
        energyCost: [],
        demandTariff: [],
        demandTariffNC: [],
        exportValue: [],
        exportEnergyValue: [],
        demandCost: [],
        demandCostNC: [],
        totalCost: [],
        raBest: [],
        raOptimBest: [],
        raStatusQuo: [],
        vecPBattRA: [],
        baseline: [],
        raEligible: [],
        mef: []
      };
      // derived Data handles data that has to be calculated on the frontend

      type DerivedData = {
        ghgEmissions?: number[];
        ghgBattEmissionsImpact?: number[];
      };

      const derivedData: DerivedData = {};

      if (scenario.assumptions.calculateGHGEmissions) {
        derivedData.ghgEmissions = [];
        derivedData.ghgBattEmissionsImpact = [];
      }

      Object.keys(operationsDataScenario).forEach(monthDataKey => {
        // if more than 1000 data points per month its 15min interval data, else its 1hr
        // it works but its quite an arbitrary max nb of items, logic can be improved later
        // maybe by adding an interval prop on the scenario object
        const monthData = operationsDataScenario[monthDataKey];

        const is15min = monthData.vecLoad.length > MAX_NB_OF_DATA_ITEMS;
        let startDate;

        if (monthDataKey.split('-').length > 2) {
          startDate = moment(monthDataKey, 'YYYY-MM-DD');
        } else {
          const [year, month] = monthDataKey.split('-');
          startDate = moment(`${month}/01/${year}`, 'MM/DD/YYYY');
        }
        for (let i = 0; i < monthData.vecPBatt.length; i++) {
          data.timestamp.push(startDate.format('MM/DD/YYYY HH:mm'));
          startDate.add(is15min ? 15 : 60, 'minutes');
        }

        Object.keys(data).forEach(key => {
          if (monthData[key]) {
            if (key === 'vecPev') {
              // flip the ev profile sign for better readability
              data['vecPev'] = data['vecPev'].concat(monthData['vecPev'].map(el => el * -1));
            } else {
              data[key] = data[key].concat(monthData[key]);
            }
          }
        });

        Object.keys(derivedData).forEach(key => {
          switch (key) {
            case 'ghgEmissions':
              derivedData['ghgEmissions'] = derivedData['ghgEmissions']!.concat(
                monthData['vecPNetIn'].map((load, i) => (load / 4) * monthData.mef[i])
              );
              break;
            case 'ghgBattEmissionsImpact':
              derivedData['ghgBattEmissionsImpact'] = derivedData['ghgBattEmissionsImpact']!.concat(
                monthData['vecPBatt'].map((load, i) => (load / 4) * monthData.mef[i])
              );
              break;
            default:
              break;
          }
        });
      });

      // A bit of download cleanup. If any key doesn't have results for RA or EVs,
      // we don't want to include it in the download
      const filterKeys = ['raBest', 'raOptimBest', 'raStatusQuo', 'vecPBattRA', 'baseline', 'raEligible', 'vecPev'];
      filterKeys.forEach(key => {
        if (data[key].length === 0) {
          delete data[key];
        }
      });

      const allData = { ...data, ...derivedData };

      let headers = Object.keys(allData).map(getCSVHeader);

      const downloadData = allData['vecLoad'].map((_, index) => {
        return Object.keys(allData).map(row => (allData[row].length > 0 ? allData[row][index] : ''));
      });

      exportCSVFile(headers, downloadData, scenario.name);
    }
  };

  const technologies = generateTechnologyFlags();

  const statusQuoName =
    selectedBaseScenarioId === '0'
      ? 'Status Quo'
      : proposal?.proposal_scenarios.find(s => s.public_id === selectedBaseScenarioId.toString())?.name;

  return (
    <div className={classes.root}>
      <ErrorDialog
        open={dialogErrorMessage.length > 0}
        onClose={handleCloseErrorDialog}
        errorMsg={dialogErrorMessage}
      />

      <div className="button-container">
        compared with
        <Select
          value={selectedBaseScenarioId}
          onChange={handleComparisonCaseChange}
          style={{
            fontSize: '1em',
            marginLeft: 5,
            marginBottom: 12,
            marginRight: 12
          }}
          inputProps={{
            name: 'selectedBaseScenarioId',
            id: 'selectedBaseScenarioId'
          }}
        >
          <MenuItem value={0}>
            <em>Status Quo</em>
          </MenuItem>
          {proposal &&
            proposal.proposal_scenarios &&
            proposal.proposal_scenarios
              .filter(s => s.public_id !== scenario.public_id && s.results && s.active && !s.is_status_quo)
              .map((s, index) => {
                return (
                  <MenuItem value={s.public_id} key={s.public_id}>
                    {s.name}
                  </MenuItem>
                );
              })}
        </Select>
        <Tooltip title="Download Operations Data">
          <Fab
            size="small"
            color="primary"
            aria-label="Download Operations Data"
            className={classes.button}
            onClick={handleDownload}
          >
            <InsertDriveFile />
          </Fab>
        </Tooltip>
        <AccessControl requiredPermissions={['editor', 'admin']}>
          <>
            <Tooltip title="Mark as Selected Scenario">
              <Fab
                size="small"
                color="primary"
                className={isSelected ? classes.selectedButton : classes.button}
                onClick={handleSelection}
              >
                <Star />
              </Fab>
            </Tooltip>
            <Tooltip title="Scenario Overview Settings">
              <Fab
                size="small"
                color="primary"
                aria-label="Scenario Overview Settings"
                className="button-white"
                onClick={handleSettingsClick}
              >
                <Settings />
              </Fab>
            </Tooltip>
          </>
        </AccessControl>
      </div>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
          <Tab label="Overview" value="overview" />
          <Tab label="Assumptions" value="assumptions" data-testid="assumptions-tab" />
          <Tab label="Tariff" value="tariff" />
          <Tab label="LSA" value="lsa" />
          {props?.scenario?.assumptions?.isMultiyear && (
            <Tab label="Financial Performance" data-testId="financialPerformanceTab" value="fp" />
          )}
          <Tab label="Notes" value="notes" />
          {/* TODO: it is going to be a problem to generate dynamically tabs this way if more that one,... when we'll want
          to match them with a tab index */}
        </Tabs>
      </AppBar>
      {value === 'overview' && (
        <div className={classes.content}>
          {isMultiYear && <MultiYearSelector />}
          {!scenario.results && (
            <Card>
              <CardContent>
                <Typography variant="h5">Waiting on results</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  This scenario does not currently have results processed yet. Please check back soon.
                </Typography>
              </CardContent>
            </Card>
          )}

          {scenario.results && getStatusQuo() && (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} className={classes.item}>
                  <ScenarioRibbon
                    statusQuoResult={sliceResults(getStatusQuo()!)}
                    scenarioResult={sliceResults(scenario)}
                    discountRate={discountRate}
                  />
                </Grid>
              </Grid>
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} className={classes.item}>
                    <ScenarioMonthlyResultsTable
                      statusQuoTitle={statusQuoName || 'N/A'}
                      statusQuoResult={sliceResults(getStatusQuo()!)}
                      scenarioResult={sliceResults(scenario)}
                      scenarioTitle={scenario.name || ''}
                      discountRate={discountRate}
                      statusQuo={getStatusQuo()!}
                      scenario={scenario}
                      scenarios={proposal.proposal_scenarios}
                      selectedBaseScenarioId={selectedBaseScenarioId}
                      scenarioOperationsData={slicedOperationsData(scenario, operationsDataScenario)}
                      statusQuoOperationsData={operationsDataSQ}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                    <ErrorBoundary message="Something went wrong, can't display the resource overview chart.">
                      <RemoteDataRenderer
                        data={operationsDataScenario}
                        status={operationsDataScenarioStatus}
                        customErrorMsg="Monthly operation data is missing"
                        error={operationsDataScenarioError}
                        onSuccess={operationsData => (
                          <ResourceOverviewChart
                            isSQ={scenario?.is_status_quo as boolean}
                            isGA={scenario.assumptions.isGA}
                            selectedYear={selectedYears.start}
                            selectedMonths={selectedMonths}
                            assumptions={scenario.assumptions}
                            statusQuoResults={sliceResults(getStatusQuo()!)}
                            scenarioResults={sliceResults(scenario)}
                            scenarioData={slicedOperationsData(scenario, operationsData!)}
                            energyCapacity={+scenario.assumptions.capacity}
                            technologies={technologies}
                            frequency={
                              scenario.assumptions.selectedOptimizationInterval
                                ? scenario.assumptions.selectedOptimizationInterval
                                : '15min'
                            }
                            tariffOverview={tariff ? tariff.overview : null}
                          />
                        )}
                      />
                    </ErrorBoundary>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={6} className={classes.item}>
                    <ScenarioDemandChargeComparison
                      statusQuoData={sliceResults(getStatusQuo()!)}
                      scenarioData={sliceResults(scenario)}
                      discountRate={discountRate.demand}
                      scenarioName={scenario.name}
                      statusQuoName={statusQuoName || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} className={classes.item}>
                    <ScenarioEnergyChargeComparison
                      statusQuoData={sliceResults(getStatusQuo()!)}
                      scenarioData={sliceResults(scenario)}
                      discountRate={discountRate.energy}
                      scenarioName={scenario.name}
                      statusQuoName={statusQuoName || ''}
                    />
                  </Grid>
                </Grid>
                {technologies.isPV && (
                  <RemoteDataRenderer
                    data={operationsDataScenario}
                    status={operationsDataScenarioStatus}
                    customErrorMsg="Monthly operation data is missing"
                    error={operationsDataScenarioError}
                    spinnerSize={25}
                    onSuccess={operationsData => (
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                          <ConsumptionGenerationChart
                            monthlyOperationsData={slicedOperationsData(scenario, operationsData!)}
                          />
                        </Grid>
                      </Grid>
                    )}
                  />
                )}
                {scenario.assumptions.calculateGHGEmissions && (
                  <RemoteDataRenderer
                    data={operationsDataScenario}
                    status={operationsDataScenarioStatus}
                    customErrorMsg="Monthly operation data is missing"
                    error={operationsDataScenarioError}
                    spinnerSize={25}
                    onSuccess={operationsData => (
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                          <MOERHeatmap monthlyOperationsData={slicedOperationsData(scenario, operationsData!)} />
                        </Grid>
                      </Grid>
                    )}
                  />
                )}
                {operationsDataScenario && operationsDataScenario[Object.keys(operationsDataScenario)[0]].pdr && (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                      <PDRBidByHour monthlyOperationsData={slicedOperationsData(scenario, operationsDataScenario)} />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={3}>
                  {technologies.isBatt && (
                    <Grid item xs={12} sm={12} md={12} lg={6} className={classes.item}>
                      <ScenarioMonthlyCycles scenarioData={sliceResults(scenario)} />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={technologies.isBatt ? 3 : 6} className={classes.item}>
                    <DemandChargeTotalPie
                      discountRate={{ energy: 1, demand: 1 }}
                      demandTotal={calculateSQTotals('Demand Cost Total')}
                      energyTotal={calculateSQTotals('Energy Cost Total')}
                      title={statusQuoName + ' - Demand vs Energy'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={technologies.isBatt ? 3 : 6} className={classes.item}>
                    <DemandChargeTotalPie
                      discountRate={discountRate}
                      demandTotal={calculateScenarioTotals('Demand Cost Total')}
                      energyTotal={calculateScenarioTotals('Energy Cost Total')}
                      title={scenario.name + ' - Demand vs Energy'}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
      )}

      {value === 'assumptions' && (
        <div style={{ padding: 8 * 3 }}>
          <ScenarioAssumptionContainer scenario={scenario} proposal={proposal} mode="edit" />
        </div>
      )}

      {value === 'tariff' && <div className={classes.content}>{tariff && <TariffSummary tariff={tariff} />}</div>}

      {value === 'lsa' && getStatusQuo() && (
        <div style={{ padding: 8 * 3 }}>
          <LoadSensitivityAnalysisContainer
            proposalId={proposal.public_id}
            scenarioId={scenario.public_id}
            statusQuoResult={sliceResults(getStatusQuo()!)}
            scenarioResult={sliceResults(scenario)}
            discountRate={discountRate}
          />
        </div>
      )}

      {value === 'fp' && props?.scenario?.assumptions?.isMultiyear && (
        <div className={classes.content}>
          <FinancialPerformanceTab
            assumptions={props.scenario.assumptions}
            statusQuoTitle={statusQuoName || 'N/A'}
            statusQuoResult={getStatusQuo()?.results}
            scenarioResult={scenario.results}
            scenarioTitle={scenario.name}
            discountRate={discountRate}
            scenario={props.scenario}
          />
        </div>
      )}

      {value === 'notes' && (
        <div style={{ padding: 8 * 3 }}>
          <ScenarioNotePad scenario={props.scenario} proposalId={proposal.public_id} />
        </div>
      )}

      <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Scenario Result Settings</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the discount from perfect knowledge. This will by applied to the scenario results.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="demand"
              onChange={handleDiscountRateUpdate}
              value={newDiscountRate.demand}
              label="Demand Perfect Knowledge Discount"
              fullWidth
              helperText={errorText}
              error={errorText.length > 0}
            />
            <TextField
              margin="dense"
              name="energy"
              onChange={handleDiscountRateUpdate}
              value={newDiscountRate.energy}
              label="Energy Perfect Knowledge Discount"
              fullWidth
              helperText={errorText}
              error={errorText.length > 0}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleApplyDiscount} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    proposal: state.proposal,
    tariff: state.tariff,
    scenario: state.scenario,
    breadcrumbsTrail: state.breadcrumbsTrail
  };
};

export default connect(mapStateToProps, {
  updateProposalSelectedScenarios,
  getProposal,
  pushBreadcrumb,
  popBreadcrumb,
  selectProposalScenario,
  broadcastUpdateTariff
})(ScenarioDetail);
