import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getEscalationRates, ERatesResponse, buildEscalationRates } from '../actions';

type FinalTables = {
  finalEnergyTable: any[][];
  finalDemandTable: any[][];
};

export function useEscalationRate({
  masterTariffId,
  currentApplicabilityValues
}: {
  masterTariffId: string;
  currentApplicabilityValues: Record<string, string>;
}) {
  return useQuery<ERatesResponse, AxiosError, FinalTables, any>(
    ['EscalationRates', masterTariffId, currentApplicabilityValues],
    () => getEscalationRates(masterTariffId, currentApplicabilityValues),
    { enabled: false, select: res => buildEscalationRates(res, currentApplicabilityValues) }
  );
}
