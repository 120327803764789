import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListSearchInput from '../../Table/ListSearchInput';
import BaseTable from '../../Table/BaseTable';
import { stableSort, getSorting } from '../../../utility/Table';
import { customerColumnSchema } from '../../../utility/Customer';
import { Delete } from '@material-ui/icons';

const style = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  spacer: {
    flex: '1 1 auto'
  },
  loadingContainer: {
    textAlign: 'center',
    padding: 25
  }
});

class CustomerList extends React.Component {
  state = {
    search: '',
    orderBy: 'created_on',
    order: 'desc',
    page: 0,
    rowsPerPage: 25
  };

  handleDeleteAction = proposal => event => {
    event.stopPropagation();
  };

  proposalRowActionSchema = [
    {
      key: 'delete',
      icon: Delete,
      actionHandler: this.handleDeleteAction,
      label: 'Delete Customer'
    }
  ];

  handleRowClick = customer => () => {
    if (this.props.handleRowClick) {
      this.props.handleRowClick(customer);
    } else {
      this.props.history.push('/customers/' + customer.public_id);
    }
  };

  filterBySearch = item => {
    if (!this.state.search) {
      return true;
    }
    const search = this.state.search;
    if (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
      return true;
    }
    return false;
  };

  handleSearchChange = event => {
    this.setState({
      search: event.target.value,
      page: 0
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getActiveDataSlice = data => {
    return stableSort(data, getSorting(this.state.order, this.state.orderBy)).slice(
      this.state.page * this.state.rowsPerPage,
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
    );
  };

  render() {
    const { classes, title, customers } = this.props;
    const filteredData = customers.filter(this.filterBySearch);
    return (
      <Paper>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            {title ? title : 'Customers'}
          </Typography>
          <div className={classes.spacer} />
          <ListSearchInput handleSearchChange={this.handleSearchChange} search={this.state.search} />
        </Toolbar>
        {this.props.loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" size={50} />
          </div>
        )}

        {!this.props.loading && (
          <BaseTable
            data={this.getActiveDataSlice(filteredData)}
            onRowClick={this.handleRowClick}
            onRequestSort={this.handleRequestSort}
            columnSchema={customerColumnSchema}
            order={this.state.order}
            orderBy={this.state.orderBy}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            dataCount={filteredData.length}
            isSelected={this.props.isSelected}
            selected={this.props.selected}
          />
        )}
      </Paper>
    );
  }
}
CustomerList.defaultProps = {
  title: ''
};

CustomerList.propTypes = {
  classes: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool,
  handleRowClick: PropTypes.func,
  isSelected: PropTypes.func,
  selected: PropTypes.array
};

export default withStyles(style)(CustomerList);
