import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateMicrogridInformationOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before Genset Runtime',
    label: 'Before Genset Runtime',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Genset Runtime',
    label: 'After Genset Runtime',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Genset Average Efficiency',
    label: 'Before Genset Average Efficiency',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Genset Average Efficiency',
    label: 'After Genset Average Efficiency',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Genset Average Fuel Rate',
    label: 'Before Genset Average Fuel Rate',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Genset Average Fuel Rate',
    label: 'After Genset Average Fuel Rate',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Genset Fuel Consumption',
    label: 'Before Genset Fuel Consumption',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Genset Fuel Consumption',
    label: 'After Genset Fuel Consumption',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before Genset Fuel Cost',
    label: 'Before Genset Fuel Cost',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Genset Fuel Cost',
    label: 'After Genset Fuel Cost',
    include: true,
    highlight: false,
    category: 'Scenario'
  });

  chartOptions.push({
    key: 'Before Genset kWh',
    label: 'Before Genset kWh',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Genset kWh',
    label: 'After Genset kWh',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Islanded Battery Cycles',
    label: 'Before Islanded Battery Cycles',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Islanded Battery Cycles',
    label: 'After Islanded Battery Cycles',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Load Curtailment',
    label: 'Before Load Curtailment',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Load Curtailment',
    label: 'After Load Curtailment',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Solar Curtailment During Outages',
    label: 'Before Solar Curtailment During Outages',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Solar Curtailment During Outages',
    label: 'After Solar Curtailment During Outages',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  return chartOptions;
};
