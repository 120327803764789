import { fromJS } from 'immutable';
import { Period, TariffMetadata, OverageCharges } from './TariffFactoryContext';
import { touRateTemplate } from '../../../utility/TariffTemplate';
import { ESAPTariff, RateSchedule, TOUPeriod, SeasonOverview, Seasons } from '../../../types';
import { ChargesType } from './RatePeriodsEditor';
import { set, remove } from 'immutable';

export class TariffBuilder {
  seasons: Readonly<Seasons> = {};
  constructor(
    public readonly matrix: number[][],
    public readonly periods: { energy: Period[]; demand: Period[]; subscriptions: Period[] },
    public readonly metadata: TariffMetadata,
    public readonly overageCharges: OverageCharges | undefined
  ) {}

  arraysMatch(arr1: number[], arr2: number[]): boolean {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;
    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    // Otherwise, return true
    return true;
  }

  deleteEmptyTOUs() {
    Object.keys(this.seasons).forEach(season => {
      Object.keys(season).forEach(tou => {
        if (this.seasons[season][tou] === []) {
          delete this.seasons[season][tou];
        }
      });
    });
  }

  generateTariffWithMetadata(): ESAPTariff {
    const newTariff = touRateTemplate;
    newTariff.overview.seasons = this.seasons;
    newTariff.lse_id = parseInt(this.metadata.lse_id) || 0;
    newTariff.lse_name = this.metadata.lse_name || 'Esap Created';
    newTariff.name = this.metadata.name;
    newTariff.description = this.metadata.description;
    newTariff.code = this.metadata.code;
    return newTariff;
  }

  updateGeneralTariff(baseTariff: ESAPTariff): ESAPTariff {
    this.generateSeasons(this.matrix);
    this.replaceExistingSeasons(baseTariff);
    this.generateEnergyRates();
    this.generateDemandRates();
    this.deleteEmptyTOUs();
    const newTariff = this.generateTariffWithMetadata();
    newTariff.ev_subscriptions = [];
    newTariff.overage_charges = { charges: '0', type: 'kW' };

    return newTariff;
  }

  generateGeneralTariff(): ESAPTariff {
    this.generateSeasons(this.matrix);
    this.generateEnergyRates();
    this.generateDemandRates();
    this.deleteEmptyTOUs();
    const newTariff = this.generateTariffWithMetadata();
    newTariff.ev_subscriptions = [];
    newTariff.overage_charges = { charges: '0', type: 'kW' };

    return newTariff;
  }

  generateEVTariff(): ESAPTariff {
    this.generateSeasons(this.matrix);
    this.generateEnergyRates();
    this.deleteEmptyTOUs();

    const newTariff = this.generateTariffWithMetadata();

    // Add subscriptions data

    if (this.periods?.subscriptions && this.periods?.subscriptions.length > 0 && this.overageCharges) {
      newTariff.ev_subscriptions = this.periods.subscriptions;
      newTariff.overage_charges = this.overageCharges;
    }

    return newTariff;
  }

  generateSeasons(matrix: number[][]): void {
    let seasonIndex = 1;
    let isSeasonOpen = false;

    for (let i = 0; i < matrix.length; i++) {
      if (!isSeasonOpen) {
        this.seasons = set(this.seasons, `season${seasonIndex}`, {
          overview: {
            fromDay: 1,
            fromMonth: i + 1,
            name: `season${seasonIndex}`
          } as SeasonOverview
        });
        isSeasonOpen = true;
      }

      if (i < 11) {
        if (!this.arraysMatch(matrix[i], matrix[i + 1])) {
          if (isSeasonOpen) {
            let dateOffset = 24 * 60 * 60 * 1000;
            let month = i + 1;
            let toDay = new Date(new Date(2018, month, 1).getTime() - dateOffset).getDate();
            this.seasons[`season${seasonIndex}`].overview.toDay = toDay;
            this.seasons[`season${seasonIndex}`].overview.toMonth = i + 1;
            this.generateEnergyPeriods(seasonIndex, matrix[i]);
            this.generateDemandPeriods(seasonIndex, matrix[i]);
            seasonIndex++;
            isSeasonOpen = false;
          }
        }
      }
    }
    // close the last season if open
    if (isSeasonOpen) {
      // Calculate end date
      this.seasons[`season${seasonIndex}`].overview.toDay = 31;
      this.seasons[`season${seasonIndex}`].overview.toMonth = 12;
      this.generateEnergyPeriods(seasonIndex, matrix[11]);
      this.generateDemandPeriods(seasonIndex, matrix[11]);
      isSeasonOpen = false;
    }
    // merge the 2 winter seasons if same
    if (this.arraysMatch(matrix[0], matrix[11]) && Object.keys(this.seasons).length > 1) {
      this.seasons['season1'].overview.fromDay = this.seasons[`season${seasonIndex}`].overview.fromDay;
      this.seasons['season1'].overview.fromMonth = this.seasons[`season${seasonIndex}`].overview.fromMonth;
      this.seasons = remove(this.seasons, `season${seasonIndex}`);
    }
  }

  replaceExistingSeasons(baseTariff: ESAPTariff): void {
    const baseSeasons = fromJS(baseTariff.overview.seasons);
    let seasons = fromJS(this.seasons);

    for (let season of seasons.keys()) {
      for (let baseSeason of baseSeasons.keys()) {
        if (seasons.get(season).equals(baseSeasons.get(baseSeason))) {
          seasons = seasons.remove(season).set(baseSeason, baseSeasons.get(baseSeason));
        }
      }
    }
    this.seasons = seasons.toJS();
  }

  generateEnergyRates(): void {
    Object.keys(this.seasons).forEach(seasonName => {
      Object.keys(this.seasons[seasonName]).forEach(periodKey => {
        // get the period name
        const period = this.periods.energy.find(period => period.index.toString() === periodKey);
        let energyPeriodsHolder;
        if (
          this.seasons[seasonName][periodKey].energyPeriods &&
          this.seasons[seasonName][periodKey].energyPeriods.length
        ) {
          energyPeriodsHolder = this.seasons[seasonName][periodKey].energyPeriods;
        }

        if (period?.TOU) {
          const periodName = period.TOU.toString();
          delete this.seasons[seasonName][periodKey];
          if (!this.seasons[seasonName][periodName]) {
            this.seasons[seasonName][periodName] = [];
          }

          if (energyPeriodsHolder && energyPeriodsHolder[0] && energyPeriodsHolder[0].energyCharges > 0) {
            this.seasons[seasonName][periodName].push({
              name: `${seasonName} ${periodName} - Energy Charges`,
              amount: energyPeriodsHolder[0].energyCharges,
              touType: periodName,
              type: 'CONSUMPTION_BASED',
              periods: energyPeriodsHolder
            } as TOUPeriod);
          }
        }
      });
    });
  }

  generateDemandRates(): void {
    if (this.periods.demand.every(period => period.charges === '0')) return;
    Object.keys(this.seasons).forEach(seasonName => {
      Object.keys(this.seasons[seasonName]).forEach(periodKey => {
        // get the period name
        const period = this.periods.demand.find(period => period.index === parseInt(periodKey) - 100);
        let demandPeriodsHolder;

        if (
          this.seasons[seasonName][periodKey].demandPeriods &&
          this.seasons[seasonName][periodKey].demandPeriods.length
        ) {
          demandPeriodsHolder = this.seasons[seasonName][periodKey].demandPeriods;
        }

        if (period?.TOU) {
          const periodName = period.TOU.toString();
          delete this.seasons[seasonName][periodKey];
          if (!this.seasons[seasonName][periodName]) {
            this.seasons[seasonName][periodName] = [];
          }

          if (demandPeriodsHolder && demandPeriodsHolder[0]) {
            // TODO problem here: the period name is not gonna be the same as the energy one
            if (period?.name) {
              this.seasons[seasonName][periodName].push({
                name: `${seasonName} ${periodName} - Demand Charges`,
                amount: demandPeriodsHolder[0].demandCharges,
                touType: periodName,
                type: 'DEMAND_BASED',
                periods: demandPeriodsHolder
              } as TOUPeriod);
            }
          }
        }
      });
    });
  }

  generateEnergyPeriods(seasonIndex: number, month: number[]): void {
    let rateScheduleReference: Partial<RateSchedule> | null = null;
    // generate periods (ON_PEAK etc ) from combined matrix
    // iterate over each month
    // 1/ parse energy weekday
    for (let i = 0; i < 24; i++) {
      const period = this.periods.energy.find(period => period.index === month[i]);

      const periodIndex = period ? period.index : null;
      if (periodIndex) {
        if (!rateScheduleReference && period?.charges) {
          rateScheduleReference = this.initializeTOU(i, false, 'energy', period.charges);
        }
        if (month[i] !== month[i + 1] || i === 23) {
          this.closeTOU(rateScheduleReference, i, false, 'energy');
          if (!this.seasons[`season${seasonIndex}`][periodIndex]) {
            this.seasons[`season${seasonIndex}`][periodIndex] = {
              energyPeriods: [],
              demandPeriods: []
            };
          }
          this.seasons[`season${seasonIndex}`][periodIndex].energyPeriods.push(
            Object.assign({}, rateScheduleReference)
          );
          rateScheduleReference = null;
        }
      }
    }

    // energy weekend
    for (let i = 24; i < 48; i++) {
      const period = this.periods.energy.find(period => period.index === month[i]);
      const periodIndex = period ? period.index : null;

      if (periodIndex) {
        if (!rateScheduleReference && period?.charges) {
          rateScheduleReference = this.initializeTOU(i, true, 'energy', period.charges);
        }
        if (month[i] !== month[i + 1] || i === 47) {
          this.closeTOU(rateScheduleReference, i, true, 'energy');
          if (!this.seasons[`season${seasonIndex}`][periodIndex]) {
            this.seasons[`season${seasonIndex}`][periodIndex] = {
              energyPeriods: [],
              demandPeriods: []
            };
          }
          this.seasons[`season${seasonIndex}`][periodIndex].energyPeriods.push(
            Object.assign({}, rateScheduleReference)
          );
          rateScheduleReference = null;
        }
      }
    }
  }

  generateDemandPeriods(seasonIndex: number, month: number[]): void {
    if (this.periods.demand.every(period => period.charges == '0')) return;

    let rateScheduleReference: Partial<RateSchedule> | null = null;
    // generate periods (ON_PEAK etc ) from combined matrix
    // iterate over each month
    // 1/ parse demand weekday
    for (let i = 48; i < 72; i++) {
      const period = this.periods.demand.find(period => period.index === month[i] && period.charges != '0');
      const periodIndex = period ? period.index + 100 : null;
      if (periodIndex) {
        if (!rateScheduleReference && period?.charges) {
          rateScheduleReference = this.initializeTOU(i, false, 'demand', period?.charges);
        }

        if (month[i] !== month[i + 1] || i === 71) {
          this.closeTOU(rateScheduleReference, i, false, 'demand');
          if (!this.seasons[`season${seasonIndex}`][periodIndex]) {
            this.seasons[`season${seasonIndex}`][periodIndex] = {
              demandPeriods: []
            };
          }
          this.seasons[`season${seasonIndex}`][periodIndex].demandPeriods.push(
            Object.assign({}, rateScheduleReference)
          );
          rateScheduleReference = null;
        }
      }
    }

    // demand weekend
    for (let i = 72; i < 96; i++) {
      const period = this.periods.demand.find(period => period.index === month[i] && period.charges != '0');
      const periodIndex = period ? period.index + 100 : null;

      if (periodIndex) {
        if (!rateScheduleReference && period?.charges) {
          rateScheduleReference = this.initializeTOU(i, true, 'demand', period.charges);
        }
        if (month[i] !== month[i + 1] || i === 95) {
          this.closeTOU(rateScheduleReference, i, true, 'demand');
          if (!this.seasons[`season${seasonIndex}`][periodIndex]) {
            this.seasons[`season${seasonIndex}`][periodIndex] = {
              periods: []
            };
          }
          this.seasons[`season${seasonIndex}`][periodIndex].demandPeriods.push(
            Object.assign({}, rateScheduleReference)
          );
          rateScheduleReference = null;
        }
      }
    }
  }

  private closeTOU(period: any, i: number, isWeekend: boolean, type: ChargesType) {
    if (i === 23 || i === 47 || i === 71 || i === 95) {
      period.toHour = 0;
    } else {
      if (type === 'energy' && isWeekend) {
        period.toHour = i - 23;
      } else if (type === 'demand' && !isWeekend) {
        period.toHour = i - 47;
      } else if (type === 'demand' && isWeekend) {
        period.toHour = i - 71;
      } else {
        period.toHour = i + 1;
      }
    }
    period.toMinute = 0;
  }

  private initializeTOU(i: number, isWeekend: boolean, type: ChargesType, charges: string): Partial<RateSchedule> {
    let tou: Partial<RateSchedule> = {};

    let fromHour;
    if (type === 'energy' && isWeekend) {
      fromHour = i - 24;
    } else if (type === 'demand' && !isWeekend) {
      fromHour = i - 48;
    } else if (type === 'demand' && isWeekend) {
      fromHour = i - 72;
    } else {
      fromHour = i;
    }
    if (type === 'energy') {
      tou.energyCharges = parseFloat(charges);
    }
    if (type === 'demand') {
      tou.demandCharges = parseFloat(charges);
    }

    tou.fromHour = fromHour;
    tou.fromDayOfWeek = isWeekend ? 5 : 0;
    tou.toDayOfWeek = isWeekend ? 6 : 4;
    tou.fromMinute = 0;
    return tou;
  }
}
