import axios from 'axios';
// import { appConfig } from '../config';

export const GET_GLOBAL_SETTINGS_REQUEST = 'GET_GLOBAL_SETTINGS_REQUEST';
export const GET_GLOBAL_SETTINGS_SUCCESS = 'GET_GLOBAL_SETTINGS_SUCCESS';
export const GET_GLOBAL_SETTINGS_ERROR = 'GET_GLOBAL_SETTINGS_ERROR';

export const GET_GLOBAL_SETTING_BY_KEY_REQUEST = 'GET_GLOBAL_SETTING_BY_KEY_REQUEST';
export const GET_GLOBAL_SETTING_BY_KEY_SUCCESS = 'GET_GLOBAL_SETTING_BY_KEY_SUCCESS';
export const GET_GLOBAL_SETTING_BY_KEY_ERROR = 'GET_GLOBAL_SETTING_BY_KEY_ERROR';

export const RESET_GLOBAL_SETTINGS = 'RESET_GLOBAL_SETTINGS';

export const CREATE_GLOBAL_SETTINGS_REQUEST = 'CREATE_GLOBAL_SETTINGS_REQUEST';
export const CREATE_GLOBAL_SETTINGS_SUCCESS = 'CREATE_GLOBAL_SETTINGS_SUCCESS';
export const CREATE_GLOBAL_SETTINGS_ERROR = 'CREATE_GLOBAL_SETTINGS_ERROR';

export const UPDATE_GLOBAL_SETTINGS_REQUEST = 'UPDATE_GLOBAL_SETTINGS_REQUEST';
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS';
export const UPDATE_GLOBAL_SETTINGS_ERROR = 'UPDATE_GLOBAL_SETTINGS_ERROR';

export const DELETE_GLOBAL_SETTINGS_REQUEST = 'DELETE_GLOBAL_SETTINGS_REQUEST';
export const DELETE_GLOBAL_SETTINGS_SUCCESS = 'DELETE_GLOBAL_SETTINGS_SUCCESS';
export const DELETE_GLOBAL_SETTINGS_ERROR = 'DELETE_GLOBAL_SETTINGS_ERROR';

export const GLOBAL_SETTINGS_INPUT_FORM_CHANGE = 'UTILITY_INPUT_FORM_CHANGE';

const ROOT_URL = window.REACT_APP_API_BASE_URL_V2;

export function getGlobalSettingByKey(key, options) {
  return dispatch => {
    dispatch({ type: GET_GLOBAL_SETTING_BY_KEY_REQUEST });
    axios
      .get(`${ROOT_URL}/settings/global?key=${key}`)
      .then(res => {
        const setting = res.data;
        dispatch({ type: GET_GLOBAL_SETTING_BY_KEY_SUCCESS, payload: setting });
        if (options?.onSuccess) {
          options.onSuccess(setting);
        }
      })
      .catch(function (error) {
        dispatch({ type: GET_GLOBAL_SETTING_BY_KEY_ERROR, payload: error });
      });
  };
}

export function getGlobalSettings() {
  return dispatch => {
    dispatch({ type: GET_GLOBAL_SETTINGS_REQUEST });
    axios
      .get(`${ROOT_URL}/settings/global/`)
      .then(res => {
        const setting = res.data;
        dispatch({ type: GET_GLOBAL_SETTINGS_SUCCESS, payload: setting });
      })
      .catch(function (error) {
        dispatch({ type: GET_GLOBAL_SETTINGS_ERROR, payload: error });
      });
  };
}

export function createGlobalSetting(setting) {
  return dispatch => {
    dispatch({ type: CREATE_GLOBAL_SETTINGS_REQUEST });
    axios
      .post(`${ROOT_URL}/settings/global?key=${setting.key}`, setting)
      .then(res => {
        const setting = res.data;
        dispatch({ type: CREATE_GLOBAL_SETTINGS_SUCCESS, payload: setting });
      })
      .catch(function (error) {
        dispatch({ type: CREATE_GLOBAL_SETTINGS_ERROR, payload: error });
      });
  };
}

export function updateGlobalSetting(setting, options) {
  return dispatch => {
    dispatch({ type: UPDATE_GLOBAL_SETTINGS_REQUEST });
    axios
      .put(`${ROOT_URL}/settings/global?key=${setting.key}`, setting)
      .then(res => {
        const setting = res.data;
        dispatch({ type: UPDATE_GLOBAL_SETTINGS_SUCCESS, payload: setting });
        if (options.onSuccess) {
          options.onSuccess();
        }
      })
      .catch(function (error) {
        if (options.onError) {
          options.onError(error.message);
        }
        dispatch({ type: UPDATE_GLOBAL_SETTINGS_ERROR, payload: error });
      });
  };
}

export function deleteGlobalSetting(key) {
  return dispatch => {
    dispatch({ type: DELETE_GLOBAL_SETTINGS_REQUEST });
    axios
      .delete(`${ROOT_URL}/settings/global?key=${key}`)
      .then(res => {
        dispatch({ type: DELETE_GLOBAL_SETTINGS_SUCCESS, payload: key });
      })
      .catch(function (error) {
        dispatch({ type: DELETE_GLOBAL_SETTINGS_ERROR, payload: error });
      });
  };
}

export function resetGlobalSetting() {
  return dispatch => {
    dispatch({ type: RESET_GLOBAL_SETTINGS });
  };
}

export function handleInputFormChange(updateData) {
  return dispatch => {
    dispatch({
      type: GLOBAL_SETTINGS_INPUT_FORM_CHANGE,
      payload: updateData
    });
  };
}
