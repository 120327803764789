import axios from 'axios';
import { rollupData } from '../utility/Explore';

export const ANALYZE_LOAD_PROFILE = 'ANALYZE_LOAD_PROFILE';
export const ANALYZE_SOLAR_PROFILE = 'ANALYZE_SOLAR_PROFILE';
export const RUN_CLUSTERING_REQUEST = 'RUN_CLUSTERING_REQUEST';
export const RUN_CLUSTERING_SUCCESS = 'RUN_CLUSTERING_SUCCESS';
export const RUN_CLUSTERING_ERROR = 'RUN_CLUSTERING_ERROR';
export const RUN_FORECASTING_REQUEST = 'RUN_FORECASTING_REQUEST';
export const RUN_FORECASTING_SUCCESS = 'RUN_FORECASTING_SUCCESS';
export const RUN_FORECASTING_ERROR = 'RUN_FORECASTING_ERROR';
export const GET_FORECASTING_STATUS_REQUEST = 'GET_FORECASTING_STATUS_REQUEST';
export const GET_FORECASTING_STATUS_SUCCESS = 'GET_FORECASTING_STATUS_SUCCESS';
export const GET_FORECASTING_STATUS_ERROR = 'GET_FORECASTING_STATUS_ERROR';
export const RESET_FORECASTING = 'RESET_FORECASTING';
export const RUN_BILLING_REQUEST = 'RUN_BILLING_REQUEST';
export const RUN_BILLING_SUCCESS = 'RUN_BILLING_SUCCESS';
export const RUN_BILLING_ERROR = 'RUN_BILLING_ERROR';

// New
export const UPDATE_EXPLORE_PAGE_INFORMATION = 'UPDATE_EXPLORE_PAGE_INFORMATION';
export const UPDATE_EXPLORE_PAGE_INFORMATION_SILENTLY = 'UPDATE_EXPLORE_PAGE_INFORMATION_SILENTLY';
export const UPDATE_EXPLORE_PAGE_BILLING_DISPLAY_INFORMATION = 'UPDATE_EXPLORE_PAGE_BILLING_DISPLAY_INFORMATION';

export const ADD_EXPLORE_TARIFFS = 'ADD_EXPLORE_TARIFFS';
export const REMOVE_EXPLORE_TARIFFS = 'REMOVE_EXPLORE_TARIFFS';

export const ADD_EXPLORE_SCENARIOS = 'ADD_EXPLORE_SCENARIOS';
export const REMOVE_EXPLORE_SCENARIOS = 'REMOVE_EXPLORE_SCENARIOS';

const analysisAPI = 'http://127.0.0.1:8080';
const ROOT_URL = window.REACT_APP_API_BASE_URL_V2;

export function analyzeLoadProfile(data) {
  return dispatch => {
    dispatch({
      type: ANALYZE_LOAD_PROFILE,
      payload: rollupData(data)
    });
  };
}

export function analyzeSolarProfile(data) {
  return dispatch => {
    dispatch({
      type: ANALYZE_SOLAR_PROFILE,
      payload: data
    });
  };
}

export function runClustering(proposalId) {
  return dispatch => {
    dispatch({ type: RUN_CLUSTERING_REQUEST });
    axios
      .get(`${analysisAPI}/clustering/${proposalId}`)
      .then(res => {
        const resultData = res.data;
        dispatch({ type: RUN_CLUSTERING_SUCCESS, payload: resultData });
      })
      .catch(function (error) {
        dispatch({ type: RUN_CLUSTERING_ERROR, payload: error, error: true });
      });
  };
}

export function resetForecasting() {
  return dispatch => {
    dispatch({ type: RESET_FORECASTING });
  };
}

export function trainForecasting(proposalId, options) {
  return dispatch => {
    dispatch({ type: GET_FORECASTING_STATUS_REQUEST });
    axios
      .post(`${ROOT_URL}/proposal/${proposalId}/forecast/rnn_neural_network/train`, {})
      .then(() => {
        dispatch({ type: GET_FORECASTING_STATUS_SUCCESS, payload: { status: 'training' } });
        if (options?.onSuccess) {
          options?.onSuccess();
        }
      })
      .catch(function (error) {
        console.error('error');
        dispatch({ type: GET_FORECASTING_STATUS_ERROR, payload: error });
      });
  };
}

export function getForecast(proposalId) {
  return axios.get(`${ROOT_URL}/proposal/${proposalId}/forecast/rnn_neural_network/result`).then(res => res.data);
}

export function updateExplorePageInformation(data) {
  return dispatch => {
    dispatch({
      type: UPDATE_EXPLORE_PAGE_INFORMATION,
      payload: data
    });
  };
}

export function updateExplorePageInformationSilently(data) {
  return dispatch => {
    dispatch({
      type: UPDATE_EXPLORE_PAGE_INFORMATION_SILENTLY,
      payload: data
    });
  };
}

export function addExploreTariff(data) {
  return dispatch => {
    dispatch({
      type: ADD_EXPLORE_TARIFFS,
      payload: data
    });
  };
}

export function removeExploreTariff(data) {
  return dispatch => {
    dispatch({
      type: REMOVE_EXPLORE_TARIFFS,
      payload: data
    });
  };
}

export function addExploreScenarios(data) {
  return dispatch => {
    dispatch({
      type: ADD_EXPLORE_SCENARIOS,
      payload: data
    });
  };
}

export function removeExploreScenarios(data) {
  return dispatch => {
    dispatch({
      type: REMOVE_EXPLORE_SCENARIOS,
      payload: data
    });
  };
}
