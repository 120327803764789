import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Description from '@material-ui/icons/Description';
import Edit from '@material-ui/icons/Edit';
import Drawer from '@material-ui/core/Drawer';
import TablePagination from '@material-ui/core/TablePagination';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TariffSelectList from './TariffSelectList';
import ListSearchInput from '../../Table/ListSearchInput';
import TariffFilters from '../Detail/TariffFilters';
import TariffSearchParams from '../Detail/TariffSearchParams';
import { ESAPTariff, ProposalInternal, Customer } from '../../../types';

const style = (theme: Theme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    flex: {
      flex: 1
    },
    marginTop: {
      marginTop: theme.spacing(3)
    },
    loadingContainer: {
      textAlign: 'center',
      padding: 25
    },
    white: {
      color: '#fff',
      marginRight: 10
    },
    sidenav: {
      backgroundColor: '#EEEEEE',
      width: `calc(100%)`,
      [theme.breakpoints.up('md')]: {
        width: `calc(50%)`
      }
    }
  });
type Order = 'asc' | 'desc';

interface IProps {
  proposal?: ProposalInternal;
  classes: any;
  tariffs: ESAPTariff[];
  manualTariffs: ESAPTariff[];
  selected: any[];
  handleTariffSelect: any;
  filters: any;
  updateTariffFilters: any;
  handleTariffSearchByText: any;
  title?: string;
  handleFTMToggle?: any;
  isFTM?: boolean;
  isSkipStep?: boolean;
  customer?: Customer;
}

interface IState {
  search: string;
  order: Order;
  orderBy: string; // TODO: get the keys from column schema
  rowsPerPage: number;
  isFilterOpen: boolean;
  viewManualTariffs: boolean;
}

class TariffSelectListContainer extends React.Component<IProps, IState> {
  timeout = 0;

  state = {
    search: '',
    order: 'asc' as Order,
    orderBy: 'tariffId',
    rowsPerPage: 25,
    isFilterOpen: false,
    viewManualTariffs: false
  };

  handleChangePage = (event: any, page: number) => {
    this.props.updateTariffFilters({
      ...this.props.filters,
      pageStart: page
    });
  };

  // handleFTMToggle = () => {
  //   this.props.handleFTMToggle();
  // };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilterDrawer = () => {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
  };

  handleSort = (event: any, property: any) => {
    const orderBy = property;
    let order: 'asc' | 'desc' = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleRowClick = (tariff: ESAPTariff) => {
    this.props.handleTariffSelect(tariff, this.state.viewManualTariffs);
  };

  handleSearchChange = (event: any) => {
    var searchText = event.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.props.handleTariffSearchByText(searchText);
    }, 500);

    this.setState({
      search: event.target.value
    });
    this.props.updateTariffFilters({
      ...this.props.filters,
      pageStart: 0
    });
  };

  toggleViewManualTariffs = () => {
    this.setState({
      viewManualTariffs: !this.state.viewManualTariffs
    });
  };

  filterBySearch = (item: ESAPTariff) => {
    if (!this.state.search) {
      return true;
    }
    const search = this.state.search;
    if (
      (item.name && item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
      (item.code && item.code.toLowerCase().indexOf(search.toLowerCase()) > -1)
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { classes, title, tariffs, manualTariffs } = this.props;

    const filteredTariffs = this.state.viewManualTariffs ? manualTariffs.filter(this.filterBySearch) : tariffs;
    return (
      <div>
        <TariffSearchParams filters={this.props.filters} handleFilterDelete={this.props.updateTariffFilters} />
        <Paper className={classes.marginTop}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {title ? title : 'Select Tariffs'}
            </Typography>
            <div className={classes.spacer} />
            <FormControlLabel
              control={
                <Switch onChange={this.props.handleFTMToggle} checked={this.props.isSkipStep} name="isSkipStep" />
              }
              label="Skip Tariff Selection"
            />
            <ListSearchInput handleSearchChange={this.handleSearchChange} search={this.state.search} />
            {this.state.viewManualTariffs && (
              <Tooltip title="View Tariffs">
                <IconButton
                  onClick={this.toggleViewManualTariffs}
                  size="small"
                  className={classes.white}
                  aria-label="User Created"
                >
                  <Description />
                </IconButton>
              </Tooltip>
            )}
            {!this.state.viewManualTariffs && (
              <Tooltip title="View User Created Tariffs">
                <IconButton
                  onClick={this.toggleViewManualTariffs}
                  size="small"
                  className={classes.white}
                  aria-label="User Created"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Filter list">
              <IconButton onClick={this.toggleFilterDrawer} size="small" className={classes.white} aria-label="Filter">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          {!this.props.isFTM && (
            <div>
              <TariffSelectList
                onRequestSort={this.handleSort}
                viewManualTariffs={this.state.viewManualTariffs}
                selected={this.props.selected}
                tariffs={filteredTariffs}
                handleRowClick={this.handleRowClick}
                handleSort={this.handleSort}
                orderBy={this.state.orderBy}
                order={this.state.order}
              />
              <TablePagination
                component="div"
                count={this.props.filters.count}
                rowsPerPage={this.props.filters.pageCount}
                page={this.props.filters.pageStart}
                rowsPerPageOptions={[25]}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          )}

          <Drawer
            classes={{ paper: classes.sidenav }}
            anchor="right"
            open={this.state.isFilterOpen}
            onClose={this.toggleFilterDrawer}
          >
            <TariffFilters
              toggleFilterDrawer={this.toggleFilterDrawer}
              handleFilterUpdate={this.props.updateTariffFilters}
              filters={this.props.filters}
            />
          </Drawer>
        </Paper>
      </div>
    );
  }
}

export default withStyles(style)(TariffSelectListContainer);
