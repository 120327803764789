import React, { useEffect, useState, useReducer } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TariffDetailTOUWeekdays from './TariffDetailTOUWeekdays';
import { ESAPTariff, TOUTypes, TOUPeriod, Season } from '../../../types';
import { calculateTotal } from '../../../utility/Tariff';
import { Repeat, fromJS, Range, Record as IRecord, Map, remove } from 'immutable';
import { compose } from 'ramda';

interface ITariffDetailTOUProps {
  tariff: Readonly<ESAPTariff>;
}

const getColor = (i: number): string => {
  const colors = [
    '#C7D5DD',
    '#9DC6DD',
    '#3D7A9E',
    '#E1CA96',
    '#FE938C',
    '#D7F171',
    '#B5EF8A',
    '#7FC29B',
    '#BBBDF6',
    '#817E9F',
    '#8AEA92',
    '#D4E09B',
    '#F6F4D2',
    '#636940',
    '#7B5E7B',
    '#A0DDFF',
    '#758ECD',
    '#FA9F42',
    '#CCDBDC',
    '#80CED7',
    '#6CAE75',
    '#EAC8CA',
    '#F2D5F8'
  ];
  return i < colors.length ? colors[i - 1] : '#FFFFFF';
};

export interface ScheduleMatrix {
  weekdaySchedule: Readonly<number[][]>;
  weekendSchedule: Readonly<number[][]>;
}

// create array of array filled with 1
let initialMatrix: Readonly<number[][]> = Repeat(Repeat(1, 24), 12).toJS();

export interface MatrixAction {
  type: 'WEEKDAY' | 'WEEKEND';
  matrix: number[][];
}

const matrixReducer = (state: ScheduleMatrix, action: MatrixAction) => {
  switch (action.type) {
    case 'WEEKDAY':
      return { ...state, weekdaySchedule: action.matrix };
    case 'WEEKEND':
      return { ...state, weekendSchedule: action.matrix };
    default:
      return state;
  }
};

export interface TariffMetaAction {
  type: string;
  payload: any;
}

const initialStateMatrix: ScheduleMatrix = {
  weekdaySchedule: initialMatrix.map(arr => arr.slice()),
  weekendSchedule: initialMatrix.map(arr => arr.slice())
};

export interface PeriodDetail {
  name: string;
  index: number;
  TOU: TOUTypes;
  energyCharges?: string;
  demandCharges?: string;
  ncDemandCharges?: string;
  type: 'DEMAND_BASED' | 'CONSUMPTION_BASED';
  color: string;
  months?: {
    from: number;
    to: number;
  };
  daysAndHours: {
    fromHour: number;
    toHour: number;
    fromDayOfWeek: number;
    toDayOfWeek: number;
  }[];
}

const TariffDetailTOU: React.FunctionComponent<ITariffDetailTOUProps> = ({ tariff }) => {
  const [reducedPeriods, setReducedPeriods] = useState<PeriodDetail[]>([]);
  const [tariffParsed, setTariffParsed] = useState(false);

  const [matrix, dispatchMatrix] = useReducer(matrixReducer, initialStateMatrix);

  // Factory for immutable periods records
  const makePeriodDetail = IRecord<PeriodDetail>({
    name: '',
    index: 0,
    TOU: TOUTypes.ON_PEAK,
    type: 'CONSUMPTION_BASED',
    color: '#fff',
    energyCharges: '0',
    demandCharges: '0',
    ncDemandCharges: '0',
    daysAndHours: [],
    months: { from: 0, to: 0 }
  });

  useEffect(() => {
    setTariffParsed(false);

    const buildRange = (from: number | undefined, to: number | undefined): number[] => {
      if (!from || !to) return [];
      if (from == to) return [from];
      if (from < to) {
        return Range(from, to + 1).toJS();
      } else {
        return Range(from, 13)
          .concat(Range(1, to + 1))
          .toJS();
      }
    };
    // combine demand and energy charges in one period object
    const reducePeriods = (splitPeriods: { energy: PeriodDetail[]; demand: PeriodDetail[] }) => {
      splitPeriods = fromJS(splitPeriods).toJS();
      splitPeriods.energy.forEach(energyPeriod => {
        let energyMonthsRange = buildRange(energyPeriod?.months?.from, energyPeriod?.months?.to);
        // initialize demand charges to 0
        energyPeriod.demandCharges = '0';
        splitPeriods.demand.forEach(demandPeriod => {
          let demandMonthsRange = buildRange(demandPeriod?.months?.from, demandPeriod?.months?.to);

          // check first if energy period and demand period have months in common
          if (demandMonthsRange.some(month => energyMonthsRange.includes(month))) {
            if (energyPeriod?.demandCharges && !(parseFloat(energyPeriod?.demandCharges) > 0)) {
              // next look for similar TOUs
              if (energyPeriod.TOU == demandPeriod.TOU) {
                energyPeriod.demandCharges = demandPeriod.demandCharges;
              }
            }
          }
        });
      });
      return splitPeriods.energy;
    };

    const generateMatrixFromTariffPeriods = (periods: { energy: PeriodDetail[]; demand: PeriodDetail[] }) => {
      // create array of array filled with 1
      const matrix: Readonly<number[][]> = Repeat(Repeat(1, 96), 12).toJS();

      if (tariff && Object.keys(tariff).length) {
        periods.energy.forEach(period => {
          if (period && period?.daysAndHours && period?.months?.to && period?.months?.from) {
            period.daysAndHours.forEach(subPeriod => {
              let startColumn = subPeriod.fromHour;
              let endColumn = subPeriod.toHour == 0 ? 24 : subPeriod.toHour;

              let weekendToProcess;

              // period is for weekdays and weekends
              if (subPeriod.fromDayOfWeek === 0 && subPeriod.toDayOfWeek === 6) {
                const duplicate = { ...subPeriod, fromDayOfWeek: 5 };
                // duplicate the period and restrict it to the weekend
                weekendToProcess = duplicate;
                // restrict the current period to be just weekedays
                subPeriod.toDayOfWeek = 4;
              }

              if (subPeriod.fromDayOfWeek === 5 && subPeriod.toDayOfWeek === 6) {
                startColumn += 24;
                endColumn += 24;
              }

              if (period?.months?.to && period.months.to >= period.months.from) {
                for (let month = period.months.from; month < period.months.to + 1; month++) {
                  for (let hour = startColumn; hour < endColumn; hour++) {
                    matrix[month - 1][hour] = period.index;
                  }
                }
              } else {
                // case where the season is winter and start at the end of the year and stops at the beginning of the year
                // Split it in two parts
                if (period?.months?.from && period?.months?.to) {
                  for (let month = 1; month < period.months.to + 1; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }

                  for (let month = period.months.from; month < 13; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }
                }
              }

              // now parse the weekend part of this period if it exists
              if (weekendToProcess) {
                let startColumn = subPeriod.fromHour + 24;
                let endColumn = subPeriod.toHour == 0 ? 48 : subPeriod.toHour + 24;
                if (period?.months?.to && period.months.to >= period.months.from) {
                  for (let month = period.months.from; month < period.months.to + 1; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }
                } else {
                  // case where the season is winter and start at the end of the year and stops at the beginning of the year
                  // Split it in two parts
                  if (period?.months?.from && period?.months?.to) {
                    for (let month = 1; month < period.months.to + 1; month++) {
                      for (let hour = startColumn; hour < endColumn; hour++) {
                        matrix[month - 1][hour] = period.index;
                      }
                    }

                    for (let month = period.months.from; month < 13; month++) {
                      for (let hour = startColumn; hour < endColumn; hour++) {
                        matrix[month - 1][hour] = period.index;
                      }
                    }
                  }
                }
              }
            });
          }
        });
        periods.demand.forEach(period => {
          if (period && period?.daysAndHours && period?.months?.to && period?.months?.from) {
            period.daysAndHours.forEach(subPeriod => {
              let startColumn = subPeriod.fromHour + 48;
              let endColumn = subPeriod.toHour == 0 ? 72 : subPeriod.toHour + 48;

              let weekendToProcess;

              // period is for weekdays and weekends
              if (subPeriod.fromDayOfWeek === 0 && subPeriod.toDayOfWeek === 6) {
                const duplicate = { ...subPeriod, fromDayOfWeek: 5 };
                // duplicate the period and restrict it to the weekend
                weekendToProcess = duplicate;
                // restrict the current period to be just weekedays
                subPeriod.toDayOfWeek = 4;
              }

              if (subPeriod.fromDayOfWeek === 5 && subPeriod.toDayOfWeek === 6) {
                startColumn += 72;
                endColumn += 72;
              }

              if (period?.months?.to && period.months.to >= period.months.from) {
                for (let month = period.months.from; month < period.months.to + 1; month++) {
                  for (let hour = startColumn; hour < endColumn; hour++) {
                    matrix[month - 1][hour] = period.index;
                  }
                }
              } else {
                // case where the season is winter and start at the end of the year and stops at the beginning of the year
                // Split it in two parts
                if (period?.months?.from && period?.months?.to) {
                  for (let month = 1; month < period.months.to + 1; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }

                  for (let month = period.months.from; month < 13; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }
                }
              }

              // now parse the weekend part of this period if it exists
              if (weekendToProcess) {
                let startColumn = subPeriod.fromHour + 72;
                let endColumn = subPeriod.toHour == 0 ? 96 : subPeriod.toHour + 72;
                if (period?.months?.to && period.months.to >= period.months.from) {
                  for (let month = period.months.from; month < period.months.to + 1; month++) {
                    for (let hour = startColumn; hour < endColumn; hour++) {
                      matrix[month - 1][hour] = period.index;
                    }
                  }
                } else {
                  // case where the season is winter and start at the end of the year and stops at the beginning of the year
                  // Split it in two parts
                  if (period?.months?.from && period?.months?.to) {
                    for (let month = 1; month < period.months.to + 1; month++) {
                      for (let hour = startColumn; hour < endColumn; hour++) {
                        matrix[month - 1][hour] = period.index;
                      }
                    }

                    for (let month = period.months.from; month < 13; month++) {
                      for (let hour = startColumn; hour < endColumn; hour++) {
                        matrix[month - 1][hour] = period.index;
                      }
                    }
                  }
                }
              }
            });
          }
        });
      }

      const weekdaySchedule = matrix.map(month => month.slice(0, 24));
      const weekendSchedule = matrix.map(month => month.slice(24, 48));
      dispatchMatrix({ type: 'WEEKDAY', matrix: weekdaySchedule });
      dispatchMatrix({ type: 'WEEKEND', matrix: weekendSchedule });
    };

    const mergeSimilarSeasons = (seasons: Record<string, Season>): Record<string, Season> => {
      const seasonsKeys = Object.keys(seasons);
      let seasonsMap = fromJS(seasons) as Map<string, any>;

      for (let i = 0; i < seasonsKeys.length; i++) {
        for (let j = i + 1; j < seasonsKeys.length; j++) {
          if (seasonsMap.has(seasonsKeys[i]) && seasonsMap.has(seasonsKeys[j])) {
            if (seasonsMap.getIn([seasonsKeys[i], 'overview']).equals(seasonsMap.getIn([seasonsKeys[j], 'overview']))) {
              const mergedSeasons = seasonsMap
                .get(seasonsKeys[i], Map())
                .mergeDeep(seasonsMap.get(seasonsKeys[j], Map()));
              seasonsMap = seasonsMap.set(seasonsKeys[i], mergedSeasons).delete(seasonsKeys[j]);
            }
          }
        }
      }

      return seasonsMap.toJS() as Record<string, Season>;
    };

    // check if season b includes season a or not
    const isParent = (b: Season, a: Season): boolean => {
      const fromB = b.overview.fromMonth!;
      const toB = b.overview.toMonth!;

      const fromA = a.overview.fromMonth!;
      const toA = a.overview.toMonth!;

      if (fromB <= toB && fromA <= toA) {
        return fromA >= fromB && toA <= toB;
      }
      if (fromB >= toB && fromA >= toA) {
        return fromA >= fromB && toA <= toB;
      }

      if (fromB >= toB && fromA <= toA) {
        return (fromA >= fromB && toA >= toB) || (fromA <= fromB && toA <= toB);
      }

      return false;
    };

    const copyNCChargesFromParent = (seasons: Record<string, Season>): Record<string, Season> => {
      const res: Record<string, Season> = {};
      for (let seasonA in seasons) {
        for (let seasonB in remove(seasons, seasonA)) {
          if (seasons.hasOwnProperty(seasonA) && seasons.hasOwnProperty(seasonB)) {
            if (
              isParent(seasons[seasonB], seasons[seasonA]) &&
              !!seasons[seasonB]['NON_COINCIDENTAL'] &&
              !seasons[seasonA]['NON_COINCIDENTAL']
            ) {
              res[seasonA] = { ...seasons[seasonA], NON_COINCIDENTAL: seasons[seasonB]['NON_COINCIDENTAL'] };
            }
          }
        }
      }

      const stuff = { ...seasons, ...res };

      return stuff;
    };

    const getSeasonFlatRate = (season: Season): { seasonEnergyFlatRate: number; seasonDemandFlatRate: number } => {
      let seasonEnergyFlatRate = 0;
      let seasonDemandFlatRate = 0;

      if (season['FLAT_RATE'] && Array.isArray(season['FLAT_RATE'])) {
        season['FLAT_RATE'].forEach(rate => {
          if (rate.type === 'CONSUMPTION_BASED') {
            seasonEnergyFlatRate += rate.amount;
          }
          if (rate.type === 'DEMAND_BASED') {
            seasonDemandFlatRate += rate.amount;
          }
        });
      }
      return { seasonEnergyFlatRate, seasonDemandFlatRate };
    };

    const getNCCharges = (tariff: ESAPTariff, seasons: Record<string, Season>, season: string): string => {
      return seasons[season]?.NON_COINCIDENTAL && Array.isArray(seasons[season]?.NON_COINCIDENTAL)
        ? (seasons[season].NON_COINCIDENTAL as TOUPeriod[])
            .reduce((acc: number, curr: any) => {
              acc += curr.amount;
              return acc;
            }, 0)
            .toFixed(5)
        : tariff?.overview?.demandBaseAdjustment
        ? tariff.overview.demandBaseAdjustment.toFixed(5)
        : 'N/A';
    };

    const initializePeriods = (tariff: ESAPTariff) => {
      const newPeriods: {
        energy: PeriodDetail[];
        demand: PeriodDetail[];
      } = {
        energy: [],
        demand: []
      };
      if (tariff?.overview?.seasons) {
        const seasons = compose(copyNCChargesFromParent, mergeSimilarSeasons)(tariff.overview.seasons);

        let index = 1;
        Object.keys(seasons).forEach(season => {
          const ncCharges = getNCCharges(tariff, seasons, season);

          const { seasonEnergyFlatRate, seasonDemandFlatRate } = getSeasonFlatRate(seasons[season]);

          Object.keys(seasons[season]).forEach(period => {
            if (period !== 'overview' && period !== 'NON_COINCIDENTAL') {
              let energyPeriodRate = makePeriodDetail({});
              // UPDATE ENERGY RATES
              energyPeriodRate = energyPeriodRate
                .set('index', index)
                .set('name', `${seasons[season].overview.name} ${period}`)
                .set('TOU', period as TOUTypes)
                .set('color', getColor(index))
                .set(
                  'energyCharges',
                  (
                    calculateTotal(
                      seasons[season][period],
                      'CONSUMPTION_BASED',
                      tariff.is_global || tariff.is_manual ? 0 : tariff?.overview?.consumptionBaseAdjustment
                    ) + seasonEnergyFlatRate
                  ).toFixed(5)
                )
                .set('months', {
                  from: seasons[season].overview.fromMonth as number,
                  to: seasons[season].overview.toMonth as number
                })
                .set('ncDemandCharges', ncCharges);

              if (energyPeriodRate.energyCharges != '0') {
                energyPeriodRate = energyPeriodRate.set(
                  'daysAndHours',
                  seasons[season][period].reduce((acc: any, rate: any) => {
                    if (rate.type === 'CONSUMPTION_BASED' && rate?.periods) {
                      acc.push(...rate.periods);
                    }
                    return acc;
                  }, [])
                );

                // check if period starts one day and finish another
                energyPeriodRate.daysAndHours.forEach(period => {
                  if (period.toHour == 0) {
                    period.toHour = 24;
                  }
                  if (period.fromHour > period.toHour) {
                    const tempPeriod = { ...period };
                    period.toHour = 24;
                    tempPeriod.fromHour = 0;
                    energyPeriodRate = energyPeriodRate.set('daysAndHours', [
                      ...energyPeriodRate.daysAndHours,
                      tempPeriod
                    ]);
                  }
                });
                newPeriods.energy.push(energyPeriodRate.toJS());
              }

              // UPDATE DEMAND RATES
              let demandPeriodRate = makePeriodDetail({
                index: index + 1,
                name: `${seasons[season].overview.name} ${period}`,
                TOU: period as TOUTypes,
                color: getColor(index + 1),
                demandCharges: (
                  calculateTotal(
                    seasons[season][period],
                    'DEMAND_BASED',
                    tariff.is_global || tariff.is_manual ? 0 : tariff?.overview?.demandBaseAdjustment
                  ) + seasonDemandFlatRate
                ).toFixed(5),
                months: {
                  from: seasons[season].overview.fromMonth as number,
                  to: seasons[season].overview.toMonth as number
                }
              });

              if (demandPeriodRate.demandCharges != '0') {
                demandPeriodRate = demandPeriodRate.set(
                  'daysAndHours',
                  seasons[season][period].reduce((acc: any, rate: any) => {
                    if (rate.type === 'DEMAND_BASED' && rate?.periods) {
                      acc.push(...rate.periods);
                    }
                    return acc;
                  }, [])
                );
                // check if period starts one day and finish another
                demandPeriodRate.daysAndHours.forEach(period => {
                  if (period.toHour == 0) {
                    period.toHour = 24;
                  }
                  if (period.fromHour > period.toHour) {
                    const tempPeriod = { ...period };
                    period.toHour = 24;
                    tempPeriod.fromHour = 0;
                    demandPeriodRate = demandPeriodRate.set('daysAndHours', [
                      ...demandPeriodRate.daysAndHours,
                      tempPeriod
                    ]);
                  }
                });
                newPeriods.demand.push(demandPeriodRate.toJS());
              }
              index++;
            }
          });
        });
      }
      return newPeriods;
    };

    const periods = initializePeriods(tariff);
    const fusionedPeriods = reducePeriods(periods);
    setReducedPeriods(fusionedPeriods);
    // setPeriods(periods);

    generateMatrixFromTariffPeriods(periods);
    setTariffParsed(true);
  }, [tariff]);
  return (
    <>
      {!tariffParsed && (
        <Typography variant="h5" style={{ margin: 16 }}>
          Parsing tariff...
        </Typography>
      )}
      {tariffParsed && (
        <>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Weekdays</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: 'flex', justifyContent: 'center' }}>
              <TariffDetailTOUWeekdays tariff={tariff} periods={reducedPeriods} matrix={matrix.weekdaySchedule} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Weekend</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: 'flex', justifyContent: 'center' }}>
              <TariffDetailTOUWeekdays tariff={tariff} periods={reducedPeriods} matrix={matrix.weekendSchedule} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </>
      )}
    </>
  );
};

export default TariffDetailTOU;
