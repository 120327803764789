import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateEnergyUsageComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before Energy Use Total',
    label: 'Before Energy Use Total',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Use Total',
    label: 'After Energy Use Total',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Energy Use Peak',
    label: 'Before Energy Use Peak',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Use Peak',
    label: 'After Energy Use Peak',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Energy Use MidPeak',
    label: 'Before Energy Use MidPeak',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Use MidPeak',
    label: 'After Energy Use MidPeak',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Energy Use OffPeak',
    label: 'Before Energy Use OffPeak',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Energy Use OffPeak',
    label: 'After Energy Use OffPeak',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  return chartOptions;
};
