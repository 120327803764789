import { extraKeys, keys, MonthlyTableOption } from './MonthlySummaryOptionsConfig';
import { MonthlyTableKey } from '../../../../types';

export const buildChartOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];

  keys.forEach(key => {
    chartOptions.push({
      key: `Before ${key}` as MonthlyTableKey,
      label: `Before ${key}`,
      include: true,
      highlight: false,
      category: 'Status Quo'
    });
  });

  extraKeys.forEach(key => {
    chartOptions.push({
      key: `Before ${key}` as MonthlyTableKey,
      label: `Before ${key}`,
      include: false,
      highlight: false,
      category: 'Extra Information'
    });
  });

  keys.forEach(key => {
    chartOptions.push({
      key: `After ${key}` as MonthlyTableKey,
      label: `After ${key}`,
      include: true,
      highlight: false,
      category: 'Scenario'
    });
  });

  extraKeys.forEach(key => {
    chartOptions.push({
      key: `After ${key}` as MonthlyTableKey,
      label: `After ${key}`,
      include: false,
      highlight: false,
      category: 'Extra Information'
    });
  });
  chartOptions.push({
    key: 'Demand Reduction NC' as MonthlyTableKey,
    label: 'Demand Reduction NC',
    include: true,
    highlight: false,
    category: 'Totals'
  });
  chartOptions.push({
    key: 'Demand Reduction Peak' as MonthlyTableKey,
    label: 'Demand Reduction Peak',
    include: true,
    highlight: false,
    category: 'Totals'
  });

  chartOptions.push({
    key: 'Total Demand Savings' as MonthlyTableKey,
    label: 'Total Demand Savings',
    include: true,
    highlight: false,
    category: 'Totals'
  });
  chartOptions.push({
    key: 'Total Energy Savings' as MonthlyTableKey,
    label: 'Total Energy Savings',
    include: true,
    highlight: false,
    category: 'Totals'
  });
  chartOptions.push({
    key: 'Total Savings' as MonthlyTableKey,
    label: 'Total Savings',
    include: true,
    highlight: false,
    category: 'Totals'
  });

  return chartOptions;
};
