import {
  GET_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_SUCCESS,
  RESET_CUSTOMER,
  CUSTOMER_INPUT_FORM_CHANGE
} from '../../actions/customers';
import { GET_ALL_CUSTOMER_PROPOSALS_SUCCESS } from '../../actions/proposals';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CUSTOMER_SUCCESS:
      return action.payload;
    case CREATE_CUSTOMER_SUCCESS:
      return state;
    case UPDATE_CUSTOMER_SUCCESS:
      return action.payload;
    case DELETE_CUSTOMER_SUCCESS:
      return INITIAL_STATE;
    case CUSTOMER_INPUT_FORM_CHANGE:
      return { ...state, ...action.payload };
    case GET_ALL_CUSTOMER_PROPOSALS_SUCCESS:
      return { ...state, proposals: action.payload };
    case RESET_CUSTOMER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
