import React, { useEffect } from 'react';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';

import { useSelector, useDispatch } from 'react-redux';
import { getSimplifiedLSEs } from '../../../actions';
import { StoreState } from '../../../reducers';

interface LSESelectorProps {
  value: any;
  handleLSEChange: any;
}

const LSESelector: React.FC<LSESelectorProps> = React.memo(({ value, handleLSEChange }) => {
  const dispatch = useDispatch();
  const utilities = useSelector((state: StoreState) => state.simpleUtilityList);

  useEffect(() => {
    if (!utilities || utilities.length == 0) {
      dispatch(getSimplifiedLSEs());
    }
  }, [utilities, dispatch]);

  const options = React.useMemo(
    () =>
      utilities.length > 0
        ? [{ lseId: 0, name: 'Esap Created' }, ...utilities].map((utility: any) => {
            return {
              value: utility.lseId,
              label: utility.name
            };
          })
        : undefined,
    [utilities]
  );

  const filterOptions = options ? createFilterOptions({ options }) : undefined;

  return (
    <>
      <label>LSE</label>
      <Select
        name="lseId"
        id="lseId-select-options"
        value={value}
        clearable={false}
        options={options}
        filterOptions={filterOptions}
        onChange={handleLSEChange}
      />
    </>
  );
});

export default LSESelector;
