import { UPDATE_TARIFF_FILTERS, RESET_TARIFF_FILTERS, GET_TARIFFS_SUCCESS } from '../../actions/tariffs';

const INITIAL_STATE = {
  zipCode: '',
  country: 'US',
  lseId: '',
  effectiveOn: null,
  search: '',
  monthlyConsumptionAverage: '',
  annualMaxDemand: '',
  timeOfUse: false,
  tiered: false,
  contracted: false,
  netMetering: false,
  residential: false,
  general: true,
  specialUse: false,
  tariffTypeTariff: true,
  tariffTypeRider: false,
  tariffTypeProposed: false,
  pageStart: 0,
  pageCount: 25,
  count: 0
};

export default function tariffFilterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_TARIFF_FILTERS:
      return { ...state, ...action.payload };
    case RESET_TARIFF_FILTERS:
      return INITIAL_STATE;
    case GET_TARIFFS_SUCCESS:
      return { ...state, count: action.payload.count };
    default:
      return state;
  }
}
