import moment from 'moment';
import { STORE_SOLAR_PROFILE, RESET_PROFILE_DATA } from '../../actions/profiledata';
import { UploadDataFileActionTypes } from '../../actions/uploadDataFile';
import { generateProfileGrid } from '../../utility/Explore';
import { GET_PROPOSAL_DATA_SUCCESS } from '../../actions/proposals';

const INITIAL_STATE = generateProfileGrid(1);

export default function solarProfileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STORE_SOLAR_PROFILE:
      return action.payload;
    case GET_PROPOSAL_DATA_SUCCESS:
      if (action.payload && action.payload.type === 'prodPV') {
        return action.payload.data;
      }
      return state;
    case RESET_PROFILE_DATA:
      return generateProfileGrid(1);
    case UploadDataFileActionTypes.UPLOAD_SOLAR_DATA_FILE_SUCCESS:
    case UploadDataFileActionTypes.UPLOAD_HELIOSCOPE_DATA_FILE_SUCCESS:
      const newStartDateTime = moment(action.payload.start);
      const newEndDateTime = moment(action.payload.start).add(1, 'y').subtract(1, 'd').endOf('day');
      let frequency = action.payload.frequency;
      if (frequency == '15min') {
        frequency = 4;
      } else {
        frequency = 1;
      }
      return generateProfileGrid(frequency, newStartDateTime, newEndDateTime, action.payload.data);
    default:
      return state;
  }
}
