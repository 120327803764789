import { useEffect, useState, useRef, MutableRefObject } from 'react'

export function useLazyDisplay () {
  const element:MutableRefObject<any> = useRef<any>(null);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(function () {
    const observer = new window.IntersectionObserver(function (entries) {
      const { isIntersecting } = entries[0]

      if (isIntersecting) {
        setVisible(true)
        observer.disconnect()
      }
    })

    observer.observe(element.current)
  }, [element])

  return [visible, element]
}
