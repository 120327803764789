import { MonthlyTableOption } from './MonthlySummaryOptionsConfig';

export const generateBatteryComparisonOptions = (): MonthlyTableOption[] => {
  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before Cycles',
    label: 'Before Cycles',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Cycles',
    label: 'After Cycles',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Average SOC',
    label: 'Before Average SOC',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Average SOC',
    label: 'After Average SOC',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Degradation Rate',
    label: 'Before Degradation Rate',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Degradation Rate',
    label: 'After Degradation Rate',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Effective CP Rate',
    label: 'Before Effective CP Rate',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Effective CP Rate',
    label: 'After Effective CP Rate',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  return chartOptions;
};
