import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { pushBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import ProcessingQueueList from '../../components/ProcessingQueue/ProcessingQueueList';

const useStyles = makeStyles((theme: Theme) => ({
  pad: {
    padding: theme.spacing(3)
  }
}));

const ProcessingQueuePage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(resetBreadcrumb());
    dispatch(pushBreadcrumb('Jobs Queue', '/jobs-queue'));
  }, []);

  return (
    <div className={classes.pad}>
      <ProcessingQueueList />
    </div>
  );
};

export default ProcessingQueuePage;
