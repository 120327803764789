import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { TariffMetadata } from './TariffFactoryContext';
import Toolbar from '@material-ui/core/Toolbar';
import TariffCreationPanels from './TariffCreationPanels';
import TariffDescriptionForm from './TariffDescriptionForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },
    toolbar2: {
      flex: 1,
      borderTop: '1px solid #e5e5e5',
      borderBottom: '1px solid #e5e5e5',
      marginBottom: theme.spacing(4)
    },
    flex: {
      flex: 1
    },
    block: {
      width: '100%',
      flex: 1,
      marginBottom: theme.spacing(10),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    textField: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(1)
    },
    subtitle: {
      // marginBottom: theme.spacing(3)
      flex: 1
    },
    dense: {
      marginTop: 19
    },
    heading: {
      fontSize: theme.typography.pxToRem(24)
      // fontWeight: theme.typography.fontWeightRegular
    },
    addButton: {
      // marginLeft: theme.spacing(12),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    expansionPanel: {
      width: '100%'
    },
    expansionPanelHeader: {
      backgroundColor: 'rgba(0,26,113,0.17)'
    },
    LSEPicker: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(2)
    }
  })
);

interface GenerateSeasonsProps {
  state: TariffMetadata;
  handleChange: (
    name: 'code' | 'name' | 'description' | 'lse_id'
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  handleLSEChange(value: any): any;
  isEV: boolean;
}

const GenerateSeasons: React.FC<GenerateSeasonsProps> = ({ handleNext, isEV }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <Toolbar className={classes.toolbar2}>
          <Typography variant="h6" className={classes.subtitle}>
            Tariff Description
          </Typography>
        </Toolbar>
        <TariffDescriptionForm />
      </div>
      <div className={classes.block}>
        <TariffCreationPanels isEV={isEV} />
        <Button variant="contained" onClick={handleNext} color="primary" size="large" className={classes.addButton}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default GenerateSeasons;
