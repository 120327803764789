import { useQuery } from 'react-query';
import { MonthlyOperationsData } from '../types';
import { AxiosError } from 'axios';
import { getScenarioOperationsDataShort } from '../actions';

export const useGetOperationData = (proposalId?: string, scenarioId?: string) =>
  useQuery<MonthlyOperationsData, AxiosError>(
    ['monthlyOperationsData', proposalId, scenarioId],
    () => getScenarioOperationsDataShort(proposalId!, scenarioId!),
    {
      refetchOnWindowFocus: false,
      enabled: !!scenarioId && !!proposalId
    }
  );
