import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 20
  },
  card: {
    marginBottom: 10
  },
  table: {}
});

class LoadPeakSummaryTable extends React.Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5" style={{ marginBottom: 15, textAlign: 'center' }}>
                  Peak Day Summary
                </Typography>
                <Table className={classes.table} padding="dense">
                  <TableBody>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>kW</TableCell>
                    </TableRow>
                    {this.props.monthTopDayPeaks.map(peakInfo => {
                      return (
                        <TableRow>
                          <TableCell>{peakInfo[0]}</TableCell>
                          <TableCell>{peakInfo[1]}</TableCell>
                          <TableCell>{peakInfo[2]}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5" style={{ marginBottom: 15, textAlign: 'center' }}>
                  Peak Summary
                </Typography>
                <Table className={classes.table} padding="dense">
                  <TableBody>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>kW</TableCell>
                    </TableRow>
                    {this.props.monthTopPeaks.map(peakInfo => {
                      return (
                        <TableRow>
                          <TableCell>{peakInfo[0]}</TableCell>
                          <TableCell>{peakInfo[1]}</TableCell>
                          <TableCell>{peakInfo[2]}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

LoadPeakSummaryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  monthTopPeaks: PropTypes.array.isRequired,
  monthTopDayPeaks: PropTypes.array.isRequired
};

export default withStyles(styles)(LoadPeakSummaryTable);
