import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Replay';
import TOUSelectionContainer from './TOUSelectionContainer';
import RatePeriodsEditor from './RatePeriodsEditor';
import { TariffFactoryContext } from './TariffFactoryContext';

const useStyles = makeStyles((theme: Theme) => ({
  centerItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  block: {
    width: '100%',
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  subtitle: {
    // marginBottom: theme.spacing(3)
    flex: 1
  },
  toolbar2: {
    flex: 1,
    borderTop: '1px solid #e5e5e5',
    borderBottom: '1px solid #e5e5e5',
    marginBottom: theme.spacing(4)
  }
}));
interface IProps {
  isEV: boolean;
}
// TODO updateEnergyWeekdayMatrix for demand
const EnergyChargesCreate: React.FC<IProps> = ({ isEV }) => {
  const classes = useStyles();

  const {
    periods,
    handleAddPeriodRate,
    handleCopyWeekdays,
    handleClearSelection,
    dispatchMatrix,
    matrix,
    copyScheduleToDemandCharges,
    handleChangeInput,
    handleChangeTOU,
    handleDeletePeriod
  } = useContext(TariffFactoryContext);

  const updateWeekdayMatrix = (matrix: number[][]) => {
    dispatchMatrix({ type: 'ENERGY_WEEKDAY', matrix });
  };
  const updateWeekendMatrix = (matrix: number[][]) => {
    dispatchMatrix({ type: 'ENERGY_WEEKEND', matrix });
  };
  return (
    <>
      <div className={classes.centerItem}>
        <RatePeriodsEditor
          aria-label="energy-rates-editor"
          type="energy"
          periods={periods.energy}
          handleChangeInput={handleChangeInput}
          handleDeletePeriod={handleDeletePeriod}
          handleChangeTOU={handleChangeTOU}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button style={{ marginTop: 24 }} variant="contained" onClick={() => handleAddPeriodRate('energy')}>
            Add Period Rate
          </Button>
        </div>

        <div className={classes.block}>
          <Toolbar className={classes.toolbar2}>
            <Typography variant="h6" className={classes.subtitle}>
              Week days
            </Typography>
            <Tooltip title="Clear Grid">
              <IconButton onClick={() => handleClearSelection(1)}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <TOUSelectionContainer
            matrix={matrix.energyWeekdaySchedule}
            periods={periods.energy}
            updateMatrix={updateWeekdayMatrix}
          />
        </div>
        <div className={classes.block}>
          <Toolbar className={classes.toolbar2}>
            <Typography variant="h6" className={classes.subtitle}>
              Weekends
            </Typography>
            <Button variant="outlined" onClick={() => handleCopyWeekdays('energy')}>
              Copy Week Days
            </Button>
            <Tooltip title="Clear Grid">
              <IconButton onClick={() => handleClearSelection(2)}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <TOUSelectionContainer
            periods={periods.energy}
            matrix={matrix.energyWeekendSchedule}
            updateMatrix={updateWeekendMatrix}
          />
          {!isEV && (
            <Button style={{ marginTop: 32, marginLeft: 24 }} variant="contained" onClick={copyScheduleToDemandCharges}>
              Copy Schedule To Demand Charges
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default EnergyChargesCreate;
