import React, { useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';
import { useSelector, useDispatch } from 'react-redux';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';

// MATERIAL UI
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import SlowMotionVideo from '@material-ui/icons/SlowMotionVideo';
import Queue from '@material-ui/icons/Queue';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

// REACT IMPORTS
import { PortfolioMeter, ScenarioInternal, ProposalInternal } from '../../../types';
import { createLoadingSelector } from '../../../selectors';
import { StoreState } from '../../../reducers';
import CurrencyDisplay from '../../../utility/CurrencyDisplay';
import { getProposalsOptions } from '../../../actions/portfolios';
import { getProposal } from '../../../actions/proposals';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3)
  },
  root: {
    //width: '100%',
    minWidth: 700,
    marginTop: theme.spacing(3)
  },
  flex: {
    flex: 1
  },
  table: {
    border: '1px solid #e5e5e5',
    '& th': {
      fontFamily: 'Roboto !important',
      fontSize: '.7rem !important',
      whiteSpace: 'normal !important'
    },
    '& td': {
      borderRight: '1px solid #f1f1f1'
    },
    '& tr:hover': {
      backgroundColor: '#f1f1f1'
    },
    '& td, & th': {
      paddingLeft: 12,
      paddingRight: 12,
      textAlign: 'center',
      borderRight: '1px solid #e5e5e5',
      letterSpacing: '.5px'
    }
  },
  complete: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.greenDark
  },
  error: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.red
  },
  warning: {
    color: 'white !important',
    backgroundColor: (theme.palette as any).esap.yellowOrange
  },
  percentage: {
    fontWeight: 600,
    fontSize: '.7rem',
    letterSpacing: '-.5px'
  },
  tableContainer: {}
}));

interface MeterUpdaters {
  handleTogglePrimary: (proposalId: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleProposalChange: (
    proposalId: string
  ) => (e: { id?: string; scenarios?: ScenarioInternal[]; name: string }) => void;
  handleScenarioChange: (proposalId: string) => ({ value }: any) => void;
  handleStatusQuoChange: (proposalId: string) => ({ value }: any) => void;
  handleDeleteMeter: (proposalId: string) => () => void;
}
// PARENT COMPONENT THAT IS EXPORTED
interface PortfolioMetersAssignProps extends MeterUpdaters {
  meters: PortfolioMeter[];
  getTotalSavings: any;
  getTotalCost: any;
}

const PortfolioMetersAssignNemA: React.FunctionComponent<PortfolioMetersAssignProps> = ({
  meters,
  handleTogglePrimary,
  handleProposalChange,
  handleScenarioChange,
  handleStatusQuoChange,
  handleDeleteMeter,
  getTotalSavings,
  getTotalCost
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {/* <div data-testid="portfolio-list-container" className={classes.tableContainer}> */}
      <Toolbar style={{ borderBottom: '1px solid rgb(229, 229, 229)' }}>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          Pre-NEM Bill
        </Typography>
      </Toolbar>
      {/* <TableContainer> */}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">Primary</TableCell>
            <TableCell>Proposal</TableCell>
            <TableCell>Status Quo</TableCell>
            <TableCell>Scenario for Portfolio</TableCell>
            <TableCell>Customer Bill ($)</TableCell>
            <TableCell>Total Savings ($)</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meters
            .filter(m => m.is_primary)
            .map(meter => (
              <RenderRow
                key={meter.proposal_id}
                meter={meter}
                handleTogglePrimary={handleTogglePrimary}
                handleProposalChange={handleProposalChange}
                handleScenarioChange={handleScenarioChange}
                handleStatusQuoChange={handleStatusQuoChange}
                handleDeleteMeter={handleDeleteMeter}
                getTotalSavings={getTotalSavings}
                getTotalCost={getTotalCost}
              />
            ))}
          {meters
            .filter(m => !m.is_primary)
            .map(meter => (
              <RenderRow
                key={meter.proposal_id}
                meter={meter}
                handleTogglePrimary={handleTogglePrimary}
                handleProposalChange={handleProposalChange}
                handleScenarioChange={handleScenarioChange}
                handleStatusQuoChange={handleStatusQuoChange}
                handleDeleteMeter={handleDeleteMeter}
                getTotalSavings={getTotalSavings}
                getTotalCost={getTotalCost}
              />
            ))}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
    </Paper>
  );
};

// COMPONENT THAT RENDERS TABLE ROW WITH SELECTS

interface RenderRowProps extends MeterUpdaters {
  meter: PortfolioMeter;
  getTotalSavings: any;
  getTotalCost: any;
}

const RenderRow: React.FC<RenderRowProps> = ({
  meter,
  handleTogglePrimary,
  handleProposalChange,
  handleScenarioChange,
  handleStatusQuoChange,
  handleDeleteMeter,
  getTotalCost,
  getTotalSavings
}) => {
  const loadingSelector = createLoadingSelector(['GET_ALL_PROPOSALS']);
  const isLoading = useSelector((state: StoreState) => loadingSelector(state));

  return (
    <TableRow style={{ overflow: 'visible' }}>
      <TableCell padding="checkbox">
        <Checkbox
          aria-label="Is a Primary meter"
          name="is_primary"
          checked={meter.is_primary}
          onChange={handleTogglePrimary(meter.proposal_id)}
          inputProps={{ 'aria-label': 'primary meter' }}
        />
      </TableCell>
      <TableCell>
        {isLoading && <CircularProgress size={16} />}
        {!isLoading && (
          <SelectPortfolioProposal meter={meter} handleProposalChange={handleProposalChange(meter.proposal_id)} />
        )}
      </TableCell>
      <TableCell>
        <SelectPortfolioStatusQuo meter={meter} handleStatusQuoChange={handleStatusQuoChange} />
      </TableCell>
      <TableCell>
        <SelectPortfolioScenario meter={meter} handleScenarioChange={handleScenarioChange} />
      </TableCell>
      <TableCell>
        <CurrencyDisplay value={getTotalCost(meter)} />
      </TableCell>
      <TableCell>
        <CurrencyDisplay value={getTotalSavings(meter)} />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleDeleteMeter(meter.proposal_id)} arial-label="Delete Meter">
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

// SELECT FOR PROPOSAL

interface SelectPortfolioProposalProps {
  meter: PortfolioMeter;
  handleProposalChange: any;
}
type ProposalOption = { id: string; name: string; scenarios: ScenarioInternal[] };

const SelectPortfolioProposal: React.FC<SelectPortfolioProposalProps> = ({ meter, handleProposalChange }) => {
  const [proposalsOptions, setProposalsOptions] = useState<ProposalOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ProposalOption>({ id: '', name: '', scenarios: [] });

  // initial the proposal if one already present on the meter
  useEffect(() => {
    if (meter && meter.proposal_id && meter.proposal_name && meter.scenarios) {
      setSelectedOption({ id: meter.proposal_id, name: meter.proposal_name, scenarios: meter.scenarios });
    }
  }, [meter]);

  const getOptions = (search: string) => {
    return getProposalsOptions(search)
      .then(res => {
        const options = (res.data.proposals as ProposalInternal[]).map(p => ({
          id: p.public_id,
          name: p.name,
          scenarios: p.proposal_scenarios
        }));
        setProposalsOptions(options);
        return { options };
      })
      .catch(err => {
        console.error('Error fetching proposals in the portfolios select search', err);

        return { options: [] };
      });
  };

  const getOptionsThrottled = useCallback(throttle(getOptions, 2000), []);

  const goToProposal = (id: string) => () => {
    const win = window.open(`/proposals/${id}`, '_blank');
    win?.focus();
  };

  const handleProposalSelection = (option: any) => {
    setSelectedOption(option);
    handleProposalChange(option);
  };

  return (
    <Select
      async
      loadOptions={getOptionsThrottled}
      options={proposalsOptions}
      style={{ width: 300 }}
      name="portfolio-proposal"
      data-testid="portfolio-proposal-select"
      value={selectedOption}
      valueKey="id"
      labelKey="name"
      clearable
      // options={options}
      multi={false}
      // filterOptions={filterOptions}
      onChange={handleProposalSelection}
      onValueClick={goToProposal(meter.proposal_id)}
    />
  );
};

// SELECT FOR SCENARIO

type Options = { value: string | undefined; label: string }[] | undefined;

interface SelectPortfolioScenarioProps {
  meter: PortfolioMeter;
  handleScenarioChange: (proposalId: string) => ({ value }: any) => void;
}
const SelectPortfolioScenario: React.FC<SelectPortfolioScenarioProps> = ({ meter, handleScenarioChange }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Options>();
  const classes = useStyles();
  const proposal = useSelector((state: StoreState) => state.proposal);

  useEffect(() => {
    dispatch(getProposal(meter.proposal_id));
  }, [meter.proposal_id, dispatch]);

  //TODO: Is loading

  useEffect(() => {
    const options =
      meter && meter.scenarios && meter.scenarios.length > 0
        ? meter.scenarios.map(s => {
            return {
              value: s.public_id,
              label: s.name,
              icon: getIcon(classes, { error: s.error, status: s.status, active: s.active, completed: s.run_completed })
            };
          })
        : undefined;

    setOptions(options);
  }, [meter, proposal, classes]);

  const filterOptions = options ? createFilterOptions({ options }) : undefined;

  return meter.proposal_id ? (
    <Select
      style={{ minWidth: 240 }}
      name="portfolio-scenario"
      data-testid="portfolio-scenario-select"
      value={meter.scenario_id}
      clearable={false}
      options={options}
      multi={false}
      filterOptions={filterOptions}
      onChange={handleScenarioChange(meter.proposal_id)}
      optionRenderer={OptionWithIcon}
    />
  ) : (
    <span>Select a proposal first.</span>
  );
};

// SELECT FOR STATUS QUO

interface SelectPortfolioStatusQuoProps {
  meter: PortfolioMeter;
  handleStatusQuoChange: (proposalId: string) => ({ value }: any) => void;
}
const SelectPortfolioStatusQuo: React.FC<SelectPortfolioStatusQuoProps> = ({ meter, handleStatusQuoChange }) => {
  const [options, setOptions] = useState<Options>();
  const classes = useStyles();

  useEffect(() => {
    const options =
      meter && meter.scenarios && meter.scenarios.length > 0
        ? meter.scenarios.map(s => {
            return {
              value: s.public_id,
              label: s.name,
              icon: getIcon(classes, { error: s.error, status: s.status, active: s.active, completed: s.run_completed })
            };
          })
        : undefined;

    setOptions(options);
  }, [meter, classes]);

  const filterOptions = options ? createFilterOptions({ options }) : undefined;

  return meter.proposal_id ? (
    <Select
      style={{ minWidth: 240 }}
      name="portfolio-statusquo"
      data-testid="portfolio-statusquo-select"
      value={meter.status_quo_id}
      clearable={false}
      options={options}
      multi={false}
      filterOptions={filterOptions}
      onChange={handleStatusQuoChange(meter.proposal_id)}
      optionRenderer={OptionWithIcon}
    />
  ) : (
    <span>Select a proposal first.</span>
  );
};

const OptionWithIcon = ({
  focusedOption,
  focusedOptionIndex,
  focusOption,
  key,
  labelKey,
  option,
  options,
  selectValue,
  style,
  valueArray,
  valueKey
}: any) => {
  const classNames = ['iconOption'];

  if (option === focusedOption) {
    classNames.push('iconOptionFocused');
  }
  if (valueArray.indexOf(option) >= 0) {
    classNames.push('iconOptionSelected');
  }

  return (
    <div
      key={key}
      className={classNames.join(' ')}
      onMouseEnter={() => focusOption(option)}
      style={style}
      onClick={() => selectValue(option)}
    >
      <span className="optionIcon">{option.icon}</span>
      <label className="optionLabel">{option.label}</label>
    </div>
  );
};

const getStatusStyle = (
  classes: any,
  {
    error,
    status,
    active,
    completed
  }: {
    error: string | null | undefined;
    status?: string | null;
    active: boolean;
    completed: string | undefined;
  }
) => {
  if (status === 'Complete' && !error) {
    return classes.complete;
  }
  if (active && error != '' && error !== null) {
    return classes.error;
  }
  if (active && !completed) {
    return classes.warning;
  }
  return classes.complete;
};

const getIcon = (
  classes: any,
  {
    error,
    status,
    active,
    completed
  }: {
    error: string | null | undefined;
    status?: string | null;
    active: boolean;
    completed: string | undefined;
  }
) => (
  <div className={getStatusStyle(classes, { error, status, active, completed })}>
    {error && <ErrorIcon />}

    {!error && active && !completed && !status && <SlowMotionVideo />}
    {status && (
      <span>
        {status === 'Complete' && !error && <Check className={classes.complete} />}
        {!error && status === 'Queued' && (
          <span className={classes.percentage}>
            <Queue />
          </span>
        )}
        {!error && status !== 'Complete' && status !== 'Queued' && <span className={classes.percentage}>{status}</span>}
      </span>
    )}
  </div>
);

export default PortfolioMetersAssignNemA;
