import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Delete from '@material-ui/icons/Delete';
import ConfirmPopUp from '../Common/ConfirmPopUp/ConfirmPopUp';
import Paper from '@material-ui/core/Paper';
import { Template } from '../Utilities/Templates/Template';
import AccessControl from '../../utility/AccessControl';
import {
  calculateTotalDemandSavings,
  calculateTotalEnergySavings,
  calculateSolarFirming,
  calculateTOUDemandSavings,
  calculateNCDemandSavings
} from '../../utility/Savings';
import { ProposalInternal } from '../../types';
import EditTemplateModal from './EditTemplateModal';

import { deleteTemplate } from '../../actions/templates';

const TableCellHead = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: '#ffffff',
      backgroundColor: '#595959 !important',
      padding: '10px 15px'
    }
  })
)(TableCell);

const StyledTableHead = withStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'linear-gradient(47deg, rgba(66,66,66,1) 30%, rgba(97,97,97,1) 100%)',
      color: 'white'
    }
  })
)(TableRow);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#f5f5f5'
      }
    }
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    table: {
      minWidth: 450,
      marginBottom: theme.spacing(3)
    },
    flex: {
      flex: 1
    },
    toolbar: {},
    labelCell: {
      width: 400
    },
    red: {
      fill: 'red'
    },
    discountField: {
      maxWidth: theme.spacing(14),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  })
);

// interface TemplateScenarios {
//   statusQuo?: ScenarioInternal | null;
//   statusQuoSolarTariff?: ScenarioInternal | null;
//   solarOnly?: ScenarioInternal | null;
//   forcechargeStorage?: ScenarioInternal | null;
//   nonForcechargeStorage?: ScenarioInternal | null;
// }

export interface ResultMatrixCAISOOptionRProps {
  template: Template;
  proposal: ProposalInternal;
}

interface IState {
  solarEnergySavings: number;
  solarDemandSavings: number;
  solarTariffSwitchSavings: number;
  SQTariffSwitchSavings: number;
  standardForceChargeDemandSavingsNC: number;
  standardForceChargeDemandSavingsTOU: number;
  standardForceChargeEnergySavings: number;
  standardNonForceChargeEnergySavings: number;
  standardNonForceChargeDemandSavingsTOU: number;
  standardNonForceChargeDemandSavingsNC: number;
  solarForceChargeDemandSavingsNC: number;
  solarForceChargeDemandSavingsTOU: number;
  solarForceChargeEnergySavings: number;
  solarNonForceChargeEnergySavings: number;
  solarNonForceChargeDemandSavingsTOU: number;
  solarNonForceChargeDemandSavingsNC: number;
  totalSolarSavings: number;
  totalStorageSavings: number;
  solarFirmingDemandSavings: number;
}
interface DiscountRate {
  solarEnergy: number;
  solarDemand: number;
  solarTariffSwitch: number;
  storageEnergy: number;
  storageDemandTOU: number;
  storageDemandNC: number;
  solarFirming: number;
}

const ResultMatrixCAISOOptionR: React.SFC<ResultMatrixCAISOOptionRProps> = ({ template, proposal }) => {
  //const [discountRate] = useState({ demand: 1, energy: 1 });
  const [isOpen, setIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();

  const [state, setState] = useState<IState>({
    solarEnergySavings: 0,
    solarDemandSavings: 0,
    solarTariffSwitchSavings: 0,
    SQTariffSwitchSavings: 0,
    standardForceChargeDemandSavingsNC: 0,
    standardForceChargeDemandSavingsTOU: 0,
    standardForceChargeEnergySavings: 0,
    standardNonForceChargeEnergySavings: 0,
    standardNonForceChargeDemandSavingsTOU: 0,
    standardNonForceChargeDemandSavingsNC: 0,
    solarForceChargeDemandSavingsNC: 0,
    solarForceChargeDemandSavingsTOU: 0,
    solarForceChargeEnergySavings: 0,
    solarNonForceChargeEnergySavings: 0,
    solarNonForceChargeDemandSavingsTOU: 0,
    solarNonForceChargeDemandSavingsNC: 0,
    totalSolarSavings: 0,
    totalStorageSavings: 0,
    solarFirmingDemandSavings: 0
  });

  const [discountRate, setDiscountRate] = useState<DiscountRate>({
    solarEnergy: 100,
    solarDemand: 50,
    solarTariffSwitch: 100,
    storageEnergy: 90,
    storageDemandTOU: 78,
    storageDemandNC: 78,
    solarFirming: 100
  });

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const mapScenarioLabel = {
    standardStatusQuo: 'Status Quo - Standard Tariff',
    solarTariffStatusQuo: 'Status Quo - Solar Tariff',
    solarOnlyStandardTariff: 'Solar Only - Standard Tariff',
    solarOnlySolarTariff: 'Solar Only - Solar Tariff',
    solarStorageNonForced: 'Non-Force Solar + Storage - Solar Tariff',
    solarStorageForced: 'Force Solar + Storage - Solar Tariff',
    standardStorageNonForced: 'Non-Force Solar + Storage - Standard Tariff',
    standardStorageForced: 'Force Solar + Storage - Standard Tariff'
  };

  // get template scenarios
  const statusQuoTemplate = template.scenario_templates.find(el => el.key === 'standardStatusQuo');
  const statusQuoOptionR = template.scenario_templates.find(el => el.key === 'solarTariffStatusQuo');
  const solarOnlyStandardTemplate = template.scenario_templates.find(el => el.key === 'solarOnlyStandardTariff');
  const solarOnlySolarTemplate = template.scenario_templates.find(el => el.key === 'solarOnlySolarTariff');
  const standardForcechargeTemplate = template.scenario_templates.find(el => el.key === 'standardStorageForced');
  const standardNonForcechargeTemplate = template.scenario_templates.find(el => el.key === 'standardStorageNonForced');
  const solarForcechargeTemplate = template.scenario_templates.find(el => el.key === 'solarStorageForced');
  const solarNonForcechargeTemplate = template.scenario_templates.find(el => el.key === 'solarStorageNonForced');

  useEffect(() => {
    // match them with the correct proposal scenario
    const statusQuo =
      statusQuoTemplate &&
      statusQuoTemplate.reference_id &&
      proposal.proposal_scenarios.find(scenario => scenario.public_id === statusQuoTemplate.reference_id);
    const statusQuoSolarTariff =
      statusQuoOptionR &&
      statusQuoOptionR.reference_id &&
      proposal.proposal_scenarios.find(scenario => scenario.public_id === statusQuoOptionR.reference_id);
    const solarOnlyStandard =
      solarOnlyStandardTemplate &&
      solarOnlyStandardTemplate.reference_id &&
      proposal.proposal_scenarios.find(scenario => scenario.public_id === solarOnlyStandardTemplate.reference_id);
    const solarOnlySolar =
      solarOnlySolarTemplate &&
      solarOnlySolarTemplate.reference_id &&
      proposal.proposal_scenarios.find(scenario => scenario.public_id === solarOnlySolarTemplate.reference_id);
    const standardForcechargeStorage =
      standardForcechargeTemplate &&
      standardForcechargeTemplate.reference_id &&
      proposal.proposal_scenarios.find(scenario => scenario.public_id === standardForcechargeTemplate.reference_id);
    const standardNonForcechargeStorage =
      standardNonForcechargeTemplate &&
      standardNonForcechargeTemplate.reference_id &&
      proposal.proposal_scenarios.find(scenario => scenario.public_id === standardNonForcechargeTemplate.reference_id);
    const solarForcechargeStorage =
      solarForcechargeTemplate &&
      solarForcechargeTemplate.reference_id &&
      proposal.proposal_scenarios.find(scenario => scenario.public_id === solarForcechargeTemplate.reference_id);
    const solarNonForcechargeStorage =
      solarNonForcechargeTemplate &&
      solarNonForcechargeTemplate.reference_id &&
      proposal.proposal_scenarios.find(scenario => scenario.public_id === solarNonForcechargeTemplate.reference_id);

    // update state with savings values
    if (
      statusQuo &&
      statusQuo.results &&
      Object.keys(statusQuo.results).length > 0 &&
      statusQuoSolarTariff &&
      statusQuoSolarTariff.results &&
      Object.keys(statusQuoSolarTariff.results).length > 0 &&
      solarOnlyStandard &&
      solarOnlyStandard.results &&
      Object.keys(solarOnlyStandard.results).length > 0 &&
      solarOnlySolar &&
      solarOnlySolar.results &&
      Object.keys(solarOnlySolar.results).length > 0 &&
      standardForcechargeStorage &&
      standardForcechargeStorage.results &&
      Object.keys(standardForcechargeStorage.results).length > 0 &&
      standardNonForcechargeStorage &&
      standardNonForcechargeStorage.results &&
      Object.keys(standardNonForcechargeStorage.results).length > 0 &&
      solarForcechargeStorage &&
      solarForcechargeStorage.results &&
      Object.keys(solarForcechargeStorage.results).length > 0 &&
      solarNonForcechargeStorage &&
      solarNonForcechargeStorage.results &&
      Object.keys(solarNonForcechargeStorage.results).length > 0
    ) {
      const solarEnergySavings = calculateTotalEnergySavings(statusQuo.results, solarOnlySolar.results);
      const solarDemandSavings = calculateTotalDemandSavings(statusQuoSolarTariff.results, solarOnlySolar.results);
      const SQTariffSwitchSavings = calculateTotalDemandSavings(statusQuo.results, statusQuoSolarTariff.results);
      const solarTariffSwitchSavings = calculateTotalDemandSavings(solarOnlyStandard.results, solarOnlySolar.results);
      // standard tariff storage + solar savings
      const standardForceChargeEnergySavings = calculateTotalEnergySavings(
        solarOnlyStandard.results,
        standardForcechargeStorage.results
      );
      const standardNonForceChargeEnergySavings = calculateTotalEnergySavings(
        solarOnlyStandard.results,
        standardNonForcechargeStorage.results
      );
      const standardForceChargeDemandSavingsTOU = calculateTOUDemandSavings(
        solarOnlyStandard.results,
        standardForcechargeStorage.results
      );
      const standardNonForceChargeDemandSavingsTOU = calculateTOUDemandSavings(
        solarOnlyStandard.results,
        standardNonForcechargeStorage.results
      );
      const standardForceChargeDemandSavingsNC = calculateNCDemandSavings(
        solarOnlyStandard.results,
        standardForcechargeStorage.results
      );
      const standardNonForceChargeDemandSavingsNC = calculateNCDemandSavings(
        solarOnlyStandard.results,
        standardNonForcechargeStorage.results
      );
      // solar tariff storage + solar savings
      const solarForceChargeEnergySavings = calculateTotalEnergySavings(
        solarOnlySolar.results,
        solarForcechargeStorage.results
      );
      const solarNonForceChargeEnergySavings = calculateTotalEnergySavings(
        solarOnlySolar.results,
        solarNonForcechargeStorage.results
      );
      const solarForceChargeDemandSavingsTOU = calculateTOUDemandSavings(
        solarOnlySolar.results,
        solarForcechargeStorage.results
      );
      const solarNonForceChargeDemandSavingsTOU = calculateTOUDemandSavings(
        solarOnlySolar.results,
        solarNonForcechargeStorage.results
      );
      const solarForceChargeDemandSavingsNC = calculateNCDemandSavings(
        solarOnlySolar.results,
        solarForcechargeStorage.results
      );
      const solarNonForceChargeDemandSavingsNC = calculateNCDemandSavings(
        solarOnlySolar.results,
        solarNonForcechargeStorage.results
      );
      // end
      const solarFirmingDemandSavings = calculateSolarFirming(solarDemandSavings, discountRate.solarDemand);

      const totalSolarSavings =
        (solarEnergySavings * discountRate.solarEnergy) / 100 + (solarDemandSavings * discountRate.solarDemand) / 100;
      const totalStorageSavings =
        (solarForceChargeEnergySavings * discountRate.storageEnergy) / 100 +
        (solarForceChargeDemandSavingsTOU * discountRate.storageDemandTOU) / 100 +
        (solarForceChargeDemandSavingsNC * discountRate.storageDemandNC) / 100;

      setState({
        solarEnergySavings,
        solarTariffSwitchSavings,
        SQTariffSwitchSavings,
        solarDemandSavings,
        standardForceChargeDemandSavingsNC,
        standardForceChargeDemandSavingsTOU,
        standardForceChargeEnergySavings,
        standardNonForceChargeEnergySavings,
        standardNonForceChargeDemandSavingsTOU,
        standardNonForceChargeDemandSavingsNC,
        solarForceChargeDemandSavingsNC,
        solarForceChargeDemandSavingsTOU,
        solarForceChargeEnergySavings,
        solarNonForceChargeEnergySavings,
        solarNonForceChargeDemandSavingsTOU,
        solarNonForceChargeDemandSavingsNC,
        totalSolarSavings,
        totalStorageSavings,
        solarFirmingDemandSavings
      });
    }
  }, [
    discountRate,
    standardForcechargeTemplate,
    standardNonForcechargeTemplate,
    solarForcechargeTemplate,
    solarNonForcechargeTemplate,
    proposal.proposal_scenarios,
    solarOnlySolarTemplate,
    solarOnlyStandardTemplate,
    statusQuoOptionR,
    statusQuoTemplate
  ]);

  const getPercentage = (savings: number, totalSavings: number, discountRate: number = 1): number => {
    const percent = totalSavings > 0 ? Math.round((savings * discountRate * 100) / totalSavings) : 0;
    if (percent > 100) return 100;
    if (percent < 0) return 0;
    return percent;
  };

  const handleChangeDiscountRate = (name: keyof DiscountRate) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountRate({ ...discountRate, [name]: event.target.value });
  };

  const monthlySavingsRows = [
    {
      label: 'Solar Energy Savings',
      discount: discountRate.solarEnergy,
      savings: ((state.solarEnergySavings * (discountRate.solarEnergy / 100)) / 12).toFixed(2)
    },
    {
      label: 'SQ Tariff Switch Demand Savings',
      discount: discountRate.solarTariffSwitch,
      savings: ((state.SQTariffSwitchSavings * (discountRate.solarTariffSwitch / 100)) / 12).toFixed(2)
    },
    {
      label: 'Solar Tariff Switch Demand Savings',
      discount: discountRate.solarTariffSwitch,
      savings: ((state.solarTariffSwitchSavings * (discountRate.solarTariffSwitch / 100)) / 12).toFixed(2)
    },
    // {
    //   label: 'kW Demand Savings',
    //   savings: Math.round(state.solarDemandSavings / 12)
    // },
    // {
    //   label: 'Solar Portion of kW Demand Savings',
    //   savings: discountRate.solarDemand
    // },
    {
      label: 'Solar kW Demand Savings',
      discount: discountRate.solarDemand,
      savings: ((state.solarDemandSavings * (discountRate.solarDemand / 100)) / 12).toFixed(2)
    },
    {
      label: 'Force Demand Savings NC - Standard Tariff',
      discount: discountRate.storageDemandNC,
      savings: ((state.standardForceChargeDemandSavingsNC * (discountRate.storageDemandNC / 100)) / 12).toFixed(2)
    },
    {
      label: 'Non-Force Demand Savings NC - Standard Tariff',
      discount: discountRate.storageDemandNC,
      savings: ((state.standardNonForceChargeDemandSavingsNC * (discountRate.storageDemandNC / 100)) / 12).toFixed(2)
    },
    {
      label: 'Force Demand Savings TOU - Standard Tariff',
      discount: discountRate.storageDemandTOU,
      savings: ((state.standardForceChargeDemandSavingsTOU * (discountRate.storageDemandTOU / 100)) / 12).toFixed(2)
    },
    {
      label: 'Non-Force Demand Savings TOU - Standard Tariff',
      discount: discountRate.storageDemandTOU,
      savings: ((state.standardNonForceChargeDemandSavingsTOU * (discountRate.storageDemandTOU / 100)) / 12).toFixed(2)
    },
    {
      label: 'Force Energy Savings - Standard Tariff',
      discount: discountRate.storageEnergy,
      savings: ((state.standardForceChargeEnergySavings * (discountRate.storageEnergy / 100)) / 12).toFixed(2)
    },
    {
      label: 'Non-Force Energy Savings - Standard Tariff',
      discount: discountRate.storageEnergy,
      savings: ((state.standardNonForceChargeEnergySavings * (discountRate.storageEnergy / 100)) / 12).toFixed(2)
    },
    {
      label: 'Force Demand Savings NC - Solar Tariff',
      discount: discountRate.storageDemandNC,
      savings: ((state.solarForceChargeDemandSavingsNC * (discountRate.storageDemandNC / 100)) / 12).toFixed(2)
    },
    {
      label: 'Non-Force Demand Savings NC - Solar Tariff',
      discount: discountRate.storageDemandNC,
      savings: ((state.solarNonForceChargeDemandSavingsNC * (discountRate.storageDemandNC / 100)) / 12).toFixed(2)
    },
    {
      label: 'Force Demand Savings TOU - Solar Tariff',
      discount: discountRate.storageDemandTOU,
      savings: ((state.solarForceChargeDemandSavingsTOU * (discountRate.storageDemandTOU / 100)) / 12).toFixed(2)
    },
    {
      label: 'Non-Force Demand Savings TOU - Solar Tariff',
      discount: discountRate.storageDemandTOU,
      savings: ((state.solarNonForceChargeDemandSavingsTOU * (discountRate.storageDemandTOU / 100)) / 12).toFixed(2)
    },
    {
      label: 'Force Energy Savings - Solar Tariff',
      discount: discountRate.storageEnergy,
      savings: ((state.solarForceChargeEnergySavings * (discountRate.storageEnergy / 100)) / 12).toFixed(2)
    },
    {
      label: 'Non-Force Energy Savings - Solar Tariff',
      discount: discountRate.storageEnergy,
      savings: ((state.solarNonForceChargeEnergySavings * (discountRate.storageEnergy / 100)) / 12).toFixed(2)
    },
    {
      label: 'Solar Firming',
      discount: discountRate.solarFirming,
      savings: ((state.solarFirmingDemandSavings * (discountRate.solarFirming / 100)) / 12).toFixed(2)
    }
  ];

  const solarSavingsRows = [
    {
      label: 'Energy',
      savings: state.solarEnergySavings,
      percentPerfect: discountRate.solarEnergy,
      net: state.solarEnergySavings * (discountRate.solarEnergy / 100),
      percent: getPercentage(state.solarEnergySavings, state.totalSolarSavings, discountRate.solarEnergy / 100)
    },
    {
      label: 'Demand',
      savings: state.solarDemandSavings,
      percentPerfect: discountRate.solarDemand,
      net: state.solarDemandSavings * (discountRate.solarDemand / 100),
      percent: getPercentage(state.solarDemandSavings, state.totalSolarSavings, discountRate.solarDemand / 100)
    },
    {
      label: 'SQ Tariff Switch Demand',
      savings: state.SQTariffSwitchSavings,
      percentPerfect: discountRate.solarTariffSwitch,
      net: state.SQTariffSwitchSavings * (discountRate.solarTariffSwitch / 100),
      percent: getPercentage(state.SQTariffSwitchSavings, state.totalSolarSavings, discountRate.solarTariffSwitch / 100)
    }
  ];

  const storageSavingRows = [
    {
      label: 'Forced Storage Energy Savings',
      savings: state.solarForceChargeEnergySavings,
      percentPerfect: discountRate.storageEnergy,
      net: Math.round(state.solarForceChargeEnergySavings * (discountRate.storageEnergy / 100)),
      percent: getPercentage(
        state.solarForceChargeEnergySavings,
        state.totalStorageSavings,
        discountRate.storageEnergy / 100
      )
    },
    {
      label: 'Forced Storage Demand Savings NC',
      savings: state.solarForceChargeDemandSavingsNC,
      percentPerfect: discountRate.storageDemandNC,
      net: Math.round(state.solarForceChargeDemandSavingsNC * (discountRate.storageDemandNC / 100)),
      percent: getPercentage(
        state.solarForceChargeDemandSavingsNC,
        state.totalStorageSavings,
        discountRate.storageDemandNC / 100
      )
    },
    {
      label: 'Forced Storage Demand Savings TOU',
      savings: state.solarForceChargeDemandSavingsTOU,
      percentPerfect: discountRate.storageDemandTOU,
      net: Math.round(state.solarForceChargeDemandSavingsTOU * (discountRate.storageDemandTOU / 100)),
      percent: getPercentage(
        state.solarForceChargeDemandSavingsTOU,
        state.totalStorageSavings,
        discountRate.storageDemandTOU / 100
      )
    },
    // {
    //   label: 'Non-Forced Storage Energy Savings',
    //   savings: state.nonForceChargeEnergySavings,
    //   percentPerfect: discountRate.storageEnergy,
    //   net: Math.round(state.nonForceChargeEnergySavings * (discountRate.storageEnergy / 100)),
    //   percent: getPercentage(
    //     state.nonForceChargeEnergySavings,
    //     state.totalStorageSavings,
    //     discountRate.storageEnergy / 100
    //   )
    // },
    // {
    //   label: 'Non-Forced Storage Demand Savings',
    //   savings: state.nonForceChargeDemandSavings,
    //   percentPerfect: discountRate.storageDemand,
    //   net: Math.round(state.nonForceChargeDemandSavings * (discountRate.storageDemand / 100)),
    //   percent: getPercentage(
    //     state.nonForceChargeDemandSavings,
    //     state.totalStorageSavings,
    //     discountRate.storageDemand / 100
    //   )
    // },
    {
      label: 'Solar Firming',
      savings: Math.round(state.solarFirmingDemandSavings),
      percentPerfect: discountRate.solarFirming,
      net: Math.round(state.solarFirmingDemandSavings * (discountRate.solarFirming / 100)),
      percent: getPercentage(
        state.solarFirmingDemandSavings,
        state.totalStorageSavings,
        discountRate.solarFirming / 100
      )
    }
  ];

  const totalSavingsRows = [
    {
      label: 'Solar Savings',
      net: Math.round(state.totalSolarSavings),
      percent: getPercentage(state.totalSolarSavings, state.totalSolarSavings + state.totalStorageSavings)
    },
    {
      label: 'Storage Savings',
      net: Math.round(state.totalStorageSavings),
      percent: getPercentage(state.totalStorageSavings, state.totalSolarSavings + state.totalStorageSavings)
    },
    {
      label: 'Total Savings',
      net: Math.round(state.totalSolarSavings + state.totalStorageSavings),
      percent: 100
    }
  ];

  const toggleOpenEdit = () => {
    setIsEditOpen(!isEditOpen);
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
  };

  const handleOpenConfirm = () => {
    setConfirmDelete(true);
  };

  const handleTemplateDelete = (success: boolean) => (): void => {
    if (success) {
      dispatch(deleteTemplate(proposal.public_id, template.public_id as string));
    }
    setConfirmDelete(false);
  };

  const getScenarioName = (scenarioId: string): string => {
    const scenario = proposal && proposal.proposal_scenarios.find(scenario => scenario.public_id === scenarioId);
    if (scenario) {
      return scenario.name;
    }
    return 'None';
  };

  const getTariffCode = (scenarioId: string): string => {
    const scenario = proposal && proposal.proposal_scenarios.find(scenario => scenario.public_id === scenarioId);

    if (scenario && scenario.assumptions && scenario.assumptions.tariffId) {
      const tariff =
        proposal && proposal.proposal_tariffs.find(tariff => tariff.public_id === scenario.assumptions.tariffId);

      if (tariff && tariff.code) {
        return tariff.code;
      }
    }
    return 'N/A';
  };

  const isTemplateProcessed = (): boolean => {
    return !Object.keys(state).every(saving => state[saving] === 0);
  };

  const renderDiscountRates = () => {
    return (
      <ExpansionPanel style={{ width: '100%' }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="discount-content" id="discount-header">
          <Typography>Discounts from perfect knowledge</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container item xs={12} justify="space-evenly">
            <Grid item>
              <TextField
                id="solar-energy-discount"
                label="Solar Energy %"
                type="number"
                className={classes.discountField}
                value={discountRate.solarEnergy}
                onChange={handleChangeDiscountRate('solarEnergy')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item>
              <TextField
                id="solar-demand-discount"
                label="Solar Demand %"
                type="number"
                className={classes.discountField}
                value={discountRate.solarDemand}
                onChange={handleChangeDiscountRate('solarDemand')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item>
              <TextField
                id="solar-tariff-switch"
                label="Tariff Switch %"
                type="number"
                className={classes.discountField}
                value={discountRate.solarTariffSwitch}
                onChange={handleChangeDiscountRate('solarTariffSwitch')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item>
              <TextField
                id="storage-energy-discount"
                label="Storage Energy %"
                type="number"
                className={classes.discountField}
                value={discountRate.storageEnergy}
                onChange={handleChangeDiscountRate('storageEnergy')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item>
              <TextField
                id="storage-demand-discount-nc"
                label="Storage Demand NC %"
                type="number"
                className={classes.discountField}
                value={discountRate.storageDemandNC}
                onChange={handleChangeDiscountRate('storageDemandNC')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item>
              <TextField
                id="storage-demand-discount-tou"
                label="Storage Demand TOU %"
                type="number"
                className={classes.discountField}
                value={discountRate.storageDemandTOU}
                onChange={handleChangeDiscountRate('storageDemandTOU')}
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item>
              <TextField
                id="solar-firming-discount"
                label="Storage Firming Eff. %"
                type="number"
                className={classes.discountField}
                value={discountRate.solarFirming}
                onChange={handleChangeDiscountRate('solarFirming')}
                margin="normal"
                variant="filled"
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  const renderScenariosMapping = () => {
    return (
      <ExpansionPanel style={{ width: '100%' }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="mapping-content" id="mapping-header">
          <Typography>Template Scenarios Mapping</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid item xs={12}>
            <Table className={classes.table} size="small">
              <TableHead>
                <StyledTableHead>
                  <TableCellHead>Template Scenario</TableCellHead>
                  <TableCellHead align="right">Tariff Code</TableCellHead>
                  <TableCellHead align="right">Proposal Scenario</TableCellHead>
                </StyledTableHead>
              </TableHead>
              <TableBody>
                {template.scenario_templates.map(scenario => (
                  <StyledTableRow key={scenario.name}>
                    <TableCell component="th" scope="row" className={classes.labelCell}>
                      {mapScenarioLabel[scenario.key] + ' :'}
                    </TableCell>
                    <TableCell align="right">
                      {scenario.reference_id ? getTariffCode(scenario.reference_id) : 'N/A'}
                    </TableCell>
                    <TableCell align="right">
                      {scenario.reference_id ? getScenarioName(scenario.reference_id) : 'None'}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };
  const renderMonthlySavings = () => {
    return (
      <ExpansionPanel style={{ width: '100%' }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="monthly-content" id="monthly-header">
          <Typography>Result Matrix Monthly Savings</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid item xs={12}>
            <Table className={classes.table} size="small">
              <TableHead>
                <StyledTableHead>
                  <TableCellHead>Solar and Storage Monthly Savings</TableCellHead>
                  <TableCellHead align="right">Discount from perfect knowledge (%)</TableCellHead>
                  <TableCellHead align="right">Savings ($)</TableCellHead>
                </StyledTableHead>
              </TableHead>
              <TableBody>
                {monthlySavingsRows.map(row => (
                  <StyledTableRow key={row.label}>
                    <TableCell component="th" scope="row" className={classes.labelCell}>
                      {row.label}
                    </TableCell>
                    <TableCell align="right">{row.discount}</TableCell>
                    <TableCell align="right">{row.savings}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  const renderAnnualSavings = () => {
    return (
      <ExpansionPanel style={{ width: '100%' }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="sav-tech-content" id="sav-tech-header">
          <Typography>Annual Savings</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            <Grid item xs={12}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <StyledTableHead>
                    <TableCellHead>Solar Savings</TableCellHead>
                    <TableCellHead align="right">($)</TableCellHead>
                    <TableCellHead align="right">% of perfect</TableCellHead>
                    <TableCellHead align="right">Net($)</TableCellHead>
                    <TableCellHead align="right">Percentage</TableCellHead>
                  </StyledTableHead>
                </TableHead>
                <TableBody>
                  {solarSavingsRows.map(row => (
                    <StyledTableRow key={row.label}>
                      <TableCell component="th" scope="row" className={classes.labelCell}>
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.savings}</TableCell>
                      <TableCell align="right">{row.percentPerfect}</TableCell>
                      <TableCell align="right">{row.net}</TableCell>
                      <TableCell align="right">{row.percent}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <StyledTableHead>
                    <TableCellHead>Storage Savings</TableCellHead>
                    <TableCellHead align="right">($)</TableCellHead>
                    <TableCellHead align="right">% of perfect</TableCellHead>
                    <TableCellHead align="right">Net($)</TableCellHead>
                    <TableCellHead align="right">Percentage</TableCellHead>
                  </StyledTableHead>
                </TableHead>
                <TableBody>
                  {storageSavingRows.map(row => (
                    <StyledTableRow key={row.label}>
                      <TableCell component="th" scope="row" className={classes.labelCell}>
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.savings}</TableCell>
                      <TableCell align="right">{row.percentPerfect}</TableCell>
                      <TableCell align="right">{row.net}</TableCell>
                      <TableCell align="right">{row.percent}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <StyledTableHead>
                    <TableCellHead>Total Savings</TableCellHead>
                    <TableCellHead align="right">Net Yr-1 ($)</TableCellHead>
                    <TableCellHead align="right">Percentage</TableCellHead>
                  </StyledTableHead>
                </TableHead>
                <TableBody>
                  {totalSavingsRows.map(row => (
                    <StyledTableRow key={row.label}>
                      <TableCell component="th" scope="row" className={classes.labelCell}>
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.net}</TableCell>
                      <TableCell align="right">{row.percent}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <EditTemplateModal
          open={isEditOpen}
          handleEditClose={handleEditClose}
          template={template}
          proposal={proposal}
        />
        <Toolbar className={classes.toolbar}>
          <Typography color="inherit" variant="h6" className={classes.flex}>
            Template: {template.name}
          </Typography>
          {!isTemplateProcessed() && <Typography variant="overline">Template still processing...</Typography>}
          <AccessControl requiredPermissions={['admin', 'editor']}>
            <>
              <Tooltip title="Delete Template" placement="bottom" enterDelay={300}>
                <IconButton onClick={handleOpenConfirm}>
                  <Delete className={classes.red} />
                </IconButton>
              </Tooltip>
              <Button color="primary" onClick={toggleOpenEdit} style={{ marginRight: 16 }}>
                Edit
              </Button>
            </>
          </AccessControl>
          {isTemplateProcessed() && (
            <>
              {!isOpen && (
                <IconButton onClick={toggleOpen}>
                  <ArrowDown />
                </IconButton>
              )}
              {isOpen && (
                <IconButton onClick={toggleOpen}>
                  <ArrowUp />
                </IconButton>
              )}
            </>
          )}
        </Toolbar>
        {isOpen && (
          <>
            <Grid container spacing={2}>
              {renderDiscountRates()}
              {renderScenariosMapping()}
              {renderAnnualSavings()}
              {renderMonthlySavings()}
            </Grid>
          </>
        )}
      </Paper>
      <ConfirmPopUp
        open={confirmDelete}
        onConfirmClose={handleTemplateDelete}
        message="Are you sure that you want to delete this template?"
      />
    </>
  );
};

export default ResultMatrixCAISOOptionR;
