import React, { useLayoutEffect, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getAllUsers } from '../../actions/users';
import { pushBreadcrumb, popBreadcrumb, resetBreadcrumb } from '../../actions/breadcrumbs';
import { createLoadingSelector } from '../../selectors';
import UserList from '../../components/User/List/UserList';

const styles = theme => ({
  padding: {
    padding: theme.spacing(3)
  }
});

const UserListPage = props => {
  const { resetBreadcrumb, pushBreadcrumb, users, getAllUsers } = props;

  useLayoutEffect(() => {
    resetBreadcrumb();
    pushBreadcrumb('Users', '/users');
    // TODO: fix the dependencies under
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let ignore = false;
    if (!users || users.length === 0) {
      if (!ignore) {
        getAllUsers();
      }
    }
    return () => {
      ignore = true;
    };
  }, [getAllUsers, users]);

  return (
    <div className={props.classes.padding}>
      <UserList users={props.users} title="Users" loading={props.isLoading} />
    </div>
  );
};

const loadingSelector = createLoadingSelector(['GET_ALL_USERS']);

const mapStateToProps = state => {
  return {
    users: state.users,
    isLoading: loadingSelector(state)
  };
};

export default connect(mapStateToProps, {
  getAllUsers,
  pushBreadcrumb,
  popBreadcrumb,
  resetBreadcrumb
})(withStyles(styles)(UserListPage));
