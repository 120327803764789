const tooltip = {
  selectHorizon:
    'The optimization horizon determines how far into the future the battery has information for. Standard is a single month for perfect knowledge.',
  selectOptimInterval:
    'The optimization interval determines how granularly the battery will operate. 15-minute level is standard. For complex solves hourly level may be used to speed-up ESAP results.',
  isBTM:
    '"Behind-the-meter" operation directs the battery to focus on offsetting customer bill charges related to the above selected tariff. Turning this option off instead results in the battery optimizing around real-time and day-ahead LMPs.',
  useEnergyArbitrage:
    'Energy Arbitrage is a service provided by a storage unit when the difference between energy prices is large enough to justify losses associated with charging and discharging. The energy component of a customer bill’s can be minimized by purchasing energy when the energy prices are low, this energy is discharged when prices are higher.',
  usePeakShaving:
    'Peak shaving is a Behind-The-Meter service used to minimize a customer’s demand charges representing the power component of a customer’s bill. These charges are dictated by the maximum power or peak electricity usage of a site. Costs depend on the energy distribution company and the customer’s utility plan. Peaks are typically measured on a 15-minute interval.',
  useOptionsS:
    'Option-S is a tariff alternative that changes the typical monthly level demand charge to a daily demand charge. This tariff option is specific to projects that include storage and helps de-risk peak shaving operations. ',
  usePDP:
    "Peak Day Pricing or Critical Peak Pricing is an optional demand response program for utility customers. Customers are given reduced tariff charges in exchange for participating in 'event days' where they are required to minimize their energy use during discrete periods. Storage projects can help customers participate in the program as accidental usage during an event results in punitive charges to the customer.",
  useBIP:
    'BIP is a demand response program that provides customers credit on their bill every month equal to their months average peak usage - Firm Service Level (CRL) x BIP Credit level determined by their voltage level. They receive this credit regardless of whether an event is called or not. The nature of the program means it typically has a negative impact on battery savings to the customer.',
  crl:
    'The customers CRL can be found on their utility bill. Customers pay a flat $/kW rate to exempt a certain amount of demand from PDP/CPP event days, allowing them to continue operating critical-loads without wearing punitive event day energy charges.',
  enableCustomEvents: 'Custom events list can be found by navigating to Utilities > Related IOU > PDP Tab.',
  selectPDPInfo:
    'This dropdown driven by above tariff selection. Ensure appropriate matching interconnection voltage is selected.',
  useNetMetering:
    'The Net Energy Metering (NEM) program allows customers with  behind-the-meter solar installations to export surplus energy back to the grid. Customers receive a payment for exported energy in the form of NEM credits on their utility bill. NEM credit value is set by the tariff rate time-of-use period in which the customer was exporting energy.',
  exportCost:
    'Non-bypassable charges (NBCs) are charges the customer must pay on each kWh of usage even if they procure energy from an outside supplier or self-generate. For NEM, this reduces the value of exported energy by roughly $0.02/kWh. For commercial customers, NBCs include four charges: Public Purpose Programs, Nuclear Decommissioning, Competition Transition Charge, and DWR Bond.',
  NBCharges:
    'Imports the NBC $/kWh charge from the selected tariff, populating the "Export Cost USD per kWh" section. If the charge does not update, default the "Export Cost USD per kWh" to $0.02/kWh',
  limitNetworkExport:
    "The maximum power that the customer's total project can export back into the grid at any one time. This is important for energy storage projects in which the total system export cannot exceed the kW AC inverter nameplate of the solar installation."
};

export default tooltip;