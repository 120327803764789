import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import ProposalGeneralForm from '../Detail/ProposalGeneralForm';
import { updateProposal, createProposal, handleInputFormChangeProposal } from '../../../actions/proposals';
import AccessControl from '../../../utility/AccessControl';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  }
});

class ProposalGeneralContainer extends React.Component {
  isComplete = () => this.props.proposal.name;

  handleProposalChange = event => {
    const update = { [event.target.name]: event.target.value };
    this.props.handleInputFormChangeProposal(update);
  };

  handleProposalSave = () => {
    if (this.props.proposal.public_id) {
      this.props.updateProposal(this.props.proposal);
    } else {
      // TODO: Clean up reducer so this type of selector isn't needed or make this into a select function that maps props
      const selectProposal = (({ name, description, notes, status }) => ({ name, description, notes, status }))(
        this.props.proposal
      );
      this.props.createProposal(selectProposal);
    }
    if (this.props.handleNext) {
      this.props.handleNext();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <AccessControl requiredPermissions={['editor', 'admin']}>
          <div className="button-container">
            <Fab
              data-testid="save-button"
              disabled={!this.isComplete()}
              size="small"
              color="secondary"
              aria-label="Save"
              className="button-white"
              onClick={this.handleProposalSave}
            >
              <Save />
            </Fab>
          </div>
        </AccessControl>
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography color="inherit" variant="h6">
              Proposal Details
            </Typography>
          </Toolbar>
          <ProposalGeneralForm handleProposalChange={this.handleProposalChange} proposal={this.props.proposal} />
        </Paper>
      </div>
    );
  }
}

ProposalGeneralContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  handleNext: PropTypes.func
};

const mapStateToProps = state => {
  return {
    proposal: state.newProposal
  };
};

export default connect(mapStateToProps, { createProposal, updateProposal, handleInputFormChangeProposal })(
  withStyles(styles)(ProposalGeneralContainer)
);
