import React from 'react';
import PropTypes from 'prop-types';
//import Plot from 'react-plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../custom-plotly.js';
const Plot = createPlotlyComponent(Plotly);

export default class ForecastTimeSeries extends React.Component {
  buildLayout = () => {
    const rangeStart = this.props.data[0].x[0];
    const rangeEnd = this.props.data[0].x[this.props.data[0].x.length - 1];
    const layout = {
      autosize: true,
      displaylogo: false,
      legend: { orientation: 'h' },
      height: 400,
      margin: {
        l: 40,
        r: 40,
        t: 40,
        b: 40
      },
      xaxis: {
        autorange: true,
        range: [rangeStart, rangeEnd],
        rangeselector: {
          buttons: [
            {
              count: 1,
              label: '1d',
              step: 'day',
              stepmode: 'backward'
            },
            {
              count: 7,
              label: '1w',
              step: 'day',
              stepmode: 'backward',
              active: true
            },
            { step: 'all' }
          ]
        },
        // rangeslider: { range: [rangeStart, rangeEnd] },
        type: 'date'
      },
      yaxis: {
        autorange: true,
        // range: [86.8700008333, 138.870004167],
        type: 'linear'
        // hoverformat: ".2r"
      }
    };

    return layout;
  };

  config = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian'
    ],
    displaylogo: false
  };

  render() {
    return (
      <Plot
        style={{ width: '100%' }}
        useResizeHandler={true}
        data={this.props.data}
        layout={this.buildLayout()}
        config={this.config}
      />
    );
  }
}

ForecastTimeSeries.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
};
