import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ScenarioAssumptionContainer from '../../components/Scenarios/Detail/ScenarioAssumptionContainer';
import { resetProposalScenario, duplicateProposalScenario, resetRedirect } from '../../actions/scenarios';
import { updateProposal, resetProposal, getProposal } from '../../actions/proposals';
import { popBreadcrumb, pushBreadcrumb } from '../../actions/breadcrumbs';
import { createLoadingSelector } from '../../selectors';

const styles = theme => ({
  root: {
    width: '100%'
  },
  content: {
    padding: theme.spacing(3)
  },
  item: {
    paddingRight: 15,
    marginTop: theme.spacing(3)
  },
  center: {
    textAlign: 'center'
  },
  runButton: {
    margin: 10
  }
});

class CreateScenario extends React.Component {
  componentDidMount() {
    this.props.pushBreadcrumb('Create Scenario', this.props.location.pathname);
    this.props.resetRedirect();
  }

  componentDidUpdate = prevProps => {
    if (this.props.scenario.redirect !== prevProps.scenario.redirect) {
      this.props.scenario.redirect && this.props.history.push(`/proposals/${this.props.proposal.public_id}`);
    }
  };

  componentWillUnmount() {
    this.props.resetProposalScenario();
  }

  handleBack = () => {
    // !this.props.isLoading && this.props.history.push(`/proposals/${this.props.proposal.public_id}`);
    //this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div style={{ minHeight: '300px' }}>
          <div style={{ padding: 8 * 3 }}>
            <ScenarioAssumptionContainer scenario={this.props.scenario} proposal={this.props.proposal} mode="create" />
          </div>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['GET_PROPOSAL, GET_PROPOSAL_SCENARIO']);

const mapStateToProps = state => {
  return {
    proposal: state.proposal,
    scenario: state.newScenario,
    isLoading: loadingSelector(state)
  };
};

CreateScenario.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  resetProposal,
  resetProposalScenario,
  updateProposal,
  duplicateProposalScenario,
  pushBreadcrumb,
  popBreadcrumb,
  getProposal,
  resetRedirect
})(withStyles(styles)(CreateScenario));
