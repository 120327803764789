import axios from 'axios';
import { Template, ScenarioTemplate } from '../components/Utilities/Templates/Template';
import { ScenarioExternal, ScenarioInternal } from '../types';

import { Dispatch } from 'redux';
import { StoreState } from '../reducers';
import { convertExternalScenarioAssumptions, convertInternalScenarioAssumptions } from '../utils/assumptions';
// import { appConfig } from '../config';

export const SAVE_TEMPLATE_REQUEST = 'SAVE_TEMPLATE_REQUEST';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_ERROR = 'SAVE_TEMPLATE_ERROR';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';

export const UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_ERROR = 'UPDATE_TEMPLATE_ERROR';

export const UPDATE_TEMPLATE_SCENARIO_REQUEST = 'UPDATE_TEMPLATE_SCENARIO_REQUEST';
export const UPDATE_TEMPLATE_SCENARIO_SUCCESS = 'UPDATE_TEMPLATE_SCENARIO_SUCCESS';
export const UPDATE_TEMPLATE_SCENARIO_ERROR = 'UPDATE_TEMPLATE_SCENARIO_ERROR';

export const UPDATE_CURRENT_TEMPLATE_SUCCESS = 'UPDATE_CURRENT_TEMPLATE_SUCCESS';

export const UPDATE_SCENARIOS_IDS = 'UPDATE_SCENARIOS_IDS';

export const CREATE_TEMPLATE_SCENARIOS_SUCCESS = 'CREATE_TEMPLATE_SCENARIOS_SUCCESS';
export const CREATE_TEMPLATE_SCENARIOS_ERROR = 'CREATE_TEMPLATE_SCENARIOS_ERROR';
export const CREATE_TEMPLATE_SCENARIOS_REQUEST = 'CREATE_TEMPLATE_SCENARIOS_REQUEST';

export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';

const ROOT_URL = (window as any).REACT_APP_API_BASE_URL_V2;

export const createTemplateScenarios = (
  templateScenarios: ScenarioInternal[],
  proposal_public_id: string,
  currentTemplate: Template
) => {
  return (dispatch: Dispatch, getState: StoreState) => {
    dispatch({ type: CREATE_TEMPLATE_SCENARIOS_REQUEST });
    return axios
      .post<ScenarioExternal[]>(
        `${ROOT_URL}/proposal/${proposal_public_id}/scenario`,
        templateScenarios.map(s => convertInternalScenarioAssumptions(s))
      )
      .then(res => {
        // res data is an array of scenarios
        const scenarios = res.data.map(s => convertExternalScenarioAssumptions(s));
        dispatch({
          type: CREATE_TEMPLATE_SCENARIOS_SUCCESS,
          payload: scenarios
        });

        const newTemplate = {
          name: currentTemplate.name,
          description: currentTemplate.description,
          scenario_templates: currentTemplate.scenario_templates,
          fields: currentTemplate.fields,
          constants: currentTemplate.constants
        };

        scenarios.forEach(scenario => {
          const templateScenario = newTemplate.scenario_templates.find(
            templateScenario =>
              templateScenario.name.replace(/\s-\s\d+\s(kW)\s\/\/\s\d+\s(kWh)/g, '').trim() ===
              scenario.name.replace(/\s-\s\d+\s(kW)\s\/\/\s\d+\s(kWh)/g, '').trim()
          );
          if (templateScenario) {
            templateScenario.reference_id = scenario.public_id;
          }
        });

        dispatch({
          type: UPDATE_CURRENT_TEMPLATE_SUCCESS,
          payload: newTemplate
        });
      })
      .catch(function (error) {
        dispatch({
          type: CREATE_TEMPLATE_SCENARIOS_ERROR,
          payload: error,
          error: true
        });
      });
  };
};

export function setTemplateInStore(template: Template) {
  return { type: SET_CURRENT_TEMPLATE, payload: template };
}

export function saveTemplate(proposal_public_id: string, template: Template) {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    //const { template } = await getState();

    dispatch({ type: SAVE_TEMPLATE_REQUEST });
    return axios
      .post(`${ROOT_URL}/proposal/${proposal_public_id}/template`, template)
      .then(res => {
        const template = res.data;
        dispatch({
          type: SAVE_TEMPLATE_SUCCESS,
          payload: template
        });
      })
      .catch(function (error) {
        dispatch({
          type: SAVE_TEMPLATE_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export const createTemplateScenariosAndSave = (
  templateScenarios: ScenarioInternal[],
  proposal_public_id: string,
  currentTemplate: Template
) => {
  return (dispatch: Dispatch, getState: () => StoreState) => {
    return dispatch<any>(createTemplateScenarios(templateScenarios, proposal_public_id, currentTemplate)).then(() => {
      return dispatch<any>(saveTemplate(proposal_public_id, currentTemplate));
    });
  };
};

export function updateTemplate(template: Template, proposal_public_id: string) {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    dispatch({ type: UPDATE_TEMPLATE_REQUEST });
    return axios
      .put(`${ROOT_URL}/proposal/${proposal_public_id}/template`, template)
      .then(res => {
        const template = res.data;
        dispatch({
          type: UPDATE_TEMPLATE_SUCCESS,
          payload: template
        });
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_TEMPLATE_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function updateTemplateScenarios(data: ScenarioTemplate[], proposal_public_id: string, template_id: string) {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    dispatch({ type: UPDATE_TEMPLATE_SCENARIO_REQUEST });
    return axios
      .put(`${ROOT_URL}/proposal/${proposal_public_id}/template/${template_id}/scenario`, data)
      .then(res => {
        const templates = res.data;
        dispatch({
          type: UPDATE_TEMPLATE_SCENARIO_SUCCESS,
          payload: templates
        });
      })
      .catch(function (error) {
        dispatch({
          type: UPDATE_TEMPLATE_SCENARIO_ERROR,
          payload: error,
          error: true
        });
      });
  };
}

export function deleteTemplate(proposalId: string, templateId: string) {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    dispatch({ type: DELETE_TEMPLATE_REQUEST });
    return axios
      .delete(`${ROOT_URL}/proposal/${proposalId}/template/${templateId}`)
      .then(res => {
        const templates = res.data;
        dispatch({
          type: DELETE_TEMPLATE_SUCCESS,
          payload: templates
        });
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_TEMPLATE_ERROR,
          payload: error,
          error: true
        });
      });
  };
}
