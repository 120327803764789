import React from 'react';
import { AssumptionsInternal } from '../../../types';

// MATERIAL UI IMPORTS
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import tooltip from '../../../tooltips/assumptions/pv';
import TextFieldTooltip from '../../Common/TextFieldTooltip';
import IconTooltip from '../../Common/IconTooltip';
import InputLabelTooltip from '../../Common/InputLabelTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    block: {
      display: 'flex',
      marginTop: theme.spacing(2)
    }
  })
);
interface PVConfigProps extends AssumptionsInternal {
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleGeneralAssumptionChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

const PVConfig: React.FC<PVConfigProps> = props => {
  const classes = useStyles();
  const { isRealtime } = props;
  return (
    <div style={{ width: '100%' }}>
      <FormControlLabel
        className={classes.block}
        control={<Switch onChange={props.handleToggle} checked={props.isPV} name="isPV" />}
        label="Use PV"
      />
      {props.isPV && (
        <div>
          <TextFieldTooltip
            title={tooltip.maxHourlySolarProd}
            label="Max Hourly Solar Production"
            startAdornment={<InputAdornment position="start">kW</InputAdornment>}
            key="pInstPV"
            value={props.pInstPV}
            name="pInstPV"
            onChange={props.handleGeneralAssumptionChange}
            fullWidth
          />
          <FormControlLabel
            className={classes.block}
            style={{ marginTop: 0, marginBottom: 16, color: 'rgba(0,0,0,0.54)' }}
            control={
              <Switch
                size="small"
                onChange={props.handleToggle}
                checked={props.pInstPVAutoUpdate}
                name="pInstPVAutoUpdate"
              />
            }
            label={
              <>
                <span>Allow Max PV auto-update</span>
                <IconTooltip title={tooltip.allowMaxAutoUpdate} iconSize="small" iconSizeSmall={12} />
              </>
            }
          />
          {!isRealtime && (
            <TextField
              type="number"
              label="DC Nameplate Capacity (optional)"
              InputProps={{
                startAdornment: <InputAdornment position="start">kW</InputAdornment>
              }}
              key="pvACNameplateCapacity"
              name="pvACNameplateCapacity"
              value={props.pvACNameplateCapacity}
              onChange={props.handleGeneralAssumptionChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="normal"
            />
          )}
          <TextFieldTooltip
            title={tooltip.totalPVEfficiency}
            label="Total PV Efficiency"
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
            key="rendTot"
            name="rendTot"
            value={props.rendTot}
            onChange={props.handleGeneralAssumptionChange}
            fullWidth
          />
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <InputLabelTooltip
              htmlFor="selectInterpolationType"
              title={tooltip.interpolationType}
              label="Interpolation Type"
            />
            <Select
              value={props.pvInterpolationType}
              name="pvInterpolationType"
              onChange={props.handleGeneralAssumptionChange}
              input={<Input id="selectInterpolationType" />}
            >
              <MenuItem key="linear" value="linear">
                Linear Interpolation
              </MenuItem>
              <MenuItem key="preserveHourly" value="preserveHourly">
                Preserve Hourly Sums
              </MenuItem>
            </Select>
          </FormControl>
          {props.isPV && props.isBatt && (
            <TextFieldTooltip
              title={tooltip.percentEnergyFromSolar}
              label="Percentage of the Energy Charged from Solar"
              startAdornment={<InputAdornment position="start">%</InputAdornment>}
              key="percentFromPV"
              name="percentFromPV"
              value={props.percentFromPV}
              onChange={props.handleGeneralAssumptionChange}
              fullWidth
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PVConfig;
