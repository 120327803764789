import { MonthlyTableKey } from '../../../../types';

import { MonthlyTableOption, keys, extraKeys } from './MonthlySummaryOptionsConfig';

export const generateDemandChargeComparisonOptions = (): MonthlyTableOption[] => {
  const templateAdded = ['Demand Cost NC', 'Demand Cost Peak', 'Demand Cost MidPeak', 'Demand Cost Total'];

  const chartOptions: MonthlyTableOption[] = [
    {
      key: 'Months',
      label: 'Month',
      include: true,
      highlight: false,
      category: ''
    }
  ];
  chartOptions.push({
    key: 'Before Demand Cost NC',
    label: 'Before Demand Cost NC',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Demand Cost NC',
    label: 'After Demand Cost NC',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Demand Cost Peak',
    label: 'Before Demand Cost Peak',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Demand Cost Peak',
    label: 'After Demand Cost Peak',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Demand Cost MidPeak',
    label: 'Before Demand Cost MidPeak',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Demand Cost MidPeak',
    label: 'After Demand Cost MidPeak',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Before Demand Cost Total',
    label: 'Before Demand Cost Total',
    include: true,
    highlight: false,
    category: 'Status Quo'
  });
  chartOptions.push({
    key: 'After Demand Cost Total',
    label: 'After Demand Cost Total',
    include: true,
    highlight: false,
    category: 'Scenario'
  });
  chartOptions.push({
    key: 'Total Demand Savings',
    label: 'Total Demand Savings',
    include: true,
    highlight: false,
    category: 'Totals'
  });

  chartOptions.push({
    key: 'Total Energy Savings',
    label: 'Total Energy Savings',
    include: false,
    highlight: false,
    category: 'Totals'
  });
  chartOptions.push({
    key: 'Total Savings',
    label: 'Total Savings',
    include: false,
    highlight: false,
    category: 'Totals'
  });

  keys.forEach(key => {
    if (templateAdded.indexOf(key) === -1) {
      chartOptions.push({
        key: `Before ${key}` as MonthlyTableKey,
        label: `Before ${key}`,
        include: false,
        highlight: false,
        category: 'Status Quo'
      });
    }
  });

  keys.forEach(key => {
    if (templateAdded.indexOf(key) === -1) {
      chartOptions.push({
        key: `After ${key}` as MonthlyTableKey,
        label: `After ${key}`,
        include: false,
        highlight: false,
        category: 'Scenario'
      });
    }
  });

  extraKeys.forEach(key => {
    chartOptions.push({
      key: key,
      label: key,
      include: false,
      highlight: false,
      category: 'Extra Information'
    });
  });

  return chartOptions;
};
