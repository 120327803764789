import React, { useState, useEffect } from 'react';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import Select from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import { getAllCustomers, getUsedTariffs, getAllUsers } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from '../../../reducers';
import { createLoadingSelector } from '../../../selectors';

const useStyles = makeStyles({
  root: {
    width: '400px',
    display: 'flex'
  },
  closeGutter: {
    '-webkit-box-align': 'center',
    'align-items': 'center',
    'box-sizing': 'border-box',
    color: 'rgb(66, 82, 110)',
    display: 'flex',
    'flex-shrink': '0',
    'flex-direction': 'column',
    height: '100vh',
    'padding-bottom': '16px',
    'padding-top': '24px',
    width: '64px'
  },
  content: {
    marginTop: 24,
    flex: '1 1 0%',
    overflow: 'auto',
    paddingRight: 20
  },
  menu: {
    minWidth: 200
  },
  select: {},
  item: {
    padding: '10px 0'
  }
});

interface FilterProps {
  toggleFiltersDrawer(): any;
  filters: any;
  updatePortfoliosListFilters(filters: any): void;
}

const PortfoliosListFilters: React.FC<FilterProps> = ({
  toggleFiltersDrawer,
  updatePortfoliosListFilters,
  filters
}) => {
  // only update the reducer filters when confirm filter changes
  const dispatch = useDispatch();
  const classes = useStyles();

  // Redux
  const customers = useSelector((state: StoreState) => state.customers);
  const tariffs = useSelector((state: StoreState) => state.usedTariffs);
  const users = useSelector((state: StoreState) => state.users);

  // Local State
  const [state, setState] = useState({ ...filters });

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getUsedTariffs());
    if (!customers || customers.length == 0) {
      dispatch(getAllCustomers());
    }
  }, [dispatch, customers]);

  const loadingSelector = createLoadingSelector(['GET_ALL_CUSTOMERS', 'GET_ALL_USERS', 'GET_USED_TARIFFS']);
  const isLoading = useSelector((state: StoreState) => loadingSelector(state));

  const customerOptions = customers
    ? customers.map(c => {
        return {
          value: c.public_id,
          label: c.name
        };
      })
    : undefined;

  const filterCustomerOptions = customerOptions ? createFilterOptions({ options: customerOptions }) : undefined;

  const tariffOptions =
    tariffs && tariffs.length
      ? tariffs.map(tariff => {
          return {
            value: tariff,
            label: tariff
          };
        })
      : undefined;

  const filterTariffOptions = customerOptions ? createFilterOptions({ options: tariffOptions }) : undefined;

  const usersOptions =
    users && users.length
      ? users.map((user: any) => {
          return {
            value: user.auth0_id,
            label: user.nickname
          };
        })
      : undefined;

  const filterUsersOptions = usersOptions ? createFilterOptions({ options: usersOptions }) : undefined;

  const handleCheckChange = (name: string) => (event: any) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleUpdate = () => {
    toggleFiltersDrawer();
    updatePortfoliosListFilters(state);
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.closeGutter}>
        <IconButton onClick={toggleFiltersDrawer} style={{ color: '#333' }}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.content}>
        <Typography variant="h6" style={{ padding: '7px 0 15px 0' }}>
          Portfolios List Filters
        </Typography>
        {isLoading && (
          <div style={{ textAlign: 'center', padding: 40 }}>
            <CircularProgress color="secondary" size={30} />
          </div>
        )}
        {!isLoading && (
          <Grid container>
            <Grid item xs={12} className={classes.item}>
              <label htmlFor="users">Users</label>
              <Select
                name="users"
                id="users-select-options"
                className={classes.select}
                value={state.user}
                clearable
                options={usersOptions}
                filterOptions={filterUsersOptions}
                onChange={user => setState({ ...state, user })}
                aria-label="Select User"
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      aria-label="Show only archived portfolios"
                      value="showArchived"
                      checked={state.showArchived}
                      onChange={handleCheckChange('showArchived')}
                    />
                  }
                  label="Show Archived"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} className={classes.item}>
              <label htmlFor="tariff">Tariffs</label>
              <Select
                name="tariff"
                id="tariffs-select-options"
                className={classes.select}
                value={state.tariff}
                clearable
                options={tariffOptions}
                multi
                filterOptions={filterTariffOptions}
                onChange={tariff => setState({ ...state, tariff })}
                aria-label="Select Tariffs to filter"
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <label htmlFor="customer">Customer</label>
              <Select
                name="customer"
                id="customer"
                className={classes.select}
                value={state.customer}
                clearable
                options={customerOptions}
                multi={false}
                filterOptions={filterCustomerOptions}
                onChange={customer => setState({ ...state, customer })}
                aria-label="Select Customer to filter"
              />
            </Grid>
          </Grid>
        )}
        <section style={{ padding: 20, textAlign: 'right' }}>
          <Button variant="contained" onClick={toggleFiltersDrawer} style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button onClick={handleUpdate} variant="contained" color="primary" aria-label="Update Portfolios List">
            Update
          </Button>
        </section>
      </Box>
    </Paper>
  );
};

export default PortfoliosListFilters;
